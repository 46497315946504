import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../../../../utils';
import { GLOBAL_CONFIG, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import GroupRequestItem from './GroupRequestItem';
import _ from 'lodash';

let groupObj = null;

const GroupRequestList = inject(
    'globalStore',
    'groupStore',
    'userStore'
)(
    observer((props) => {
        const { eventData } = props;
        const { loadingGroup, totalGroupRq, listGroupRequest, doGetListGroupRequest, doApproveAll } = props.groupStore;
        const { userProfile } = props.userStore;

        const isShowLoadMoreRq = listGroupRequest == null ? false : listGroupRequest.length < totalGroupRq;

        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {
            getListGroupRequest();
        }, []);

        const { t } = useTranslation();

        const getListGroupRequest = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                gejsts: GROUP_JOIN_STATUS.REQUESTING //Đang chờ duyệt
            };
            doGetListGroupRequest(params, isLoadMore);
        };

        function getListIDGroupRequest() {
            let list = [];
            _.forEach(listGroupRequest, (item) => {
                list.push(item.grid);
            });
            return list;
        }

        async function onApproveAllGroup() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                grids: getListIDGroupRequest()
            };
            let result = await doApproveAll(params);
            if (result == true) {
                eventData.revgrcnt -= getListIDGroupRequest().length;
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                getListGroupRequest();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const getGroupRqListMore = () => {
            getListGroupRequest(true);
        };

        const onActionGroup = () => {
            getListGroupRequest();
        }

        function LoadMoreRq() {
            if (isShowLoadMoreRq) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupRqListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function ApproveAllButton() {
            if (totalGroupRq > 0) {
                if (loadingGroup) return <button className="btn btn-40 btn-blue btn-approve-all loading"></button>;
                else
                    return (
                        <>
                            <button className="btn btn-40 btn-blue btn-approve-all" onClick={() => onApproveAllGroup()}>
                                {t('team.managegroup.approve_all')}
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="manage-groups">
                <li class="active">
                    <div class="pending-approval__title">
                        <h5 class="title">
                            {t('team.managegroup.group_not_approve')}
                            <span class="fw-normal ms-2">({formatNumber(totalGroupRq, true)})</span>
                        </h5>
                        <ApproveAllButton />
                    </div>

                    <div class="manage-box__sub">
                        <div class="list-group">
                            {listGroupRequest &&
                                listGroupRequest.map((item) => {
                                    return (
                                        <GroupRequestItem
                                            data={item}
                                            key={item.id}
                                            onActionGroup={onActionGroup}
                                            eventData={eventData}
                                        />
                                    );
                                })}
                        </div>

                        <LoadMoreRq />
                    </div>
                </li>
            </div>
        );
    })
);

export default GroupRequestList;
