import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FEED_TYPE, SOCIAL_TAB, SOCIAL_TYPE, ACTIVITY_TYPE } from '../../../../../helpers/ConstDefined';
import {
    ActivityItem,
    DialogSocial,
    DialogViewImage,
    DialogDeleteActivity,
    DialogEditActivity
} from '../../components';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

import DialogComplained from '../DialogComplained';
import DialogReport from '../DialogReport';
import DialogLockComplain from '../DialogLockComplain';

let activity;

const ListActivity = inject(
    'userStore',
    'userProfileStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID, teamID, profileData } = props;
        const { listFeedUser, loading, totalFeedUser, doGetListFeedUser } = props.userProfileStore;
        const { userProfile } = props.userStore;

        const [openDialogViewImage, setOpenDialogViewImage] = React.useState(false);

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const { appVersion, doResetImageFeed } = props.globalStore;
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);

        const [openDialogDeleteActivity, setOpenDialogDeleteActivity] = React.useState(false);
        const [openDialogEditActivity, setOpenDialogEditActivity] = React.useState(false);
        const [openDialogComplained, setOpenDialogComplained] = React.useState(false);
        const [openDialogReport, setOpenDialogReport] = React.useState(false);
        const [openDialogLockComplain, setOpenDialogLockComplain] = React.useState(false);

        useEffect(() => {
            getUserFeed();
        }, []);

        const { t } = useTranslation();

        const isShowLoadMore = listFeedUser == null ? false : listFeedUser.length < totalFeedUser;

        const handleOpenDialogDeleteActivity = (flag, act) => {
            activity = act;
            setOpenDialogDeleteActivity(flag);
        };

        const onViewImage = (act) => {
            activity = act;
            setOpenDialogViewImage(true);
        };

        const onEditActivity = (act) => {
            activity = act;
            setOpenDialogEditActivity(true);
        };

        const handleOpenDialogSocial = (flag) => {
            setOpenDialogSocial(flag);
        };

        function getUserFeed(isLoadMore) {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                feed: FEED_TYPE.YOUR_ACTIVITY,
                evid: eventID,
                value: profileData.id || profileData.uid
            };
            doGetListFeedUser(params, isLoadMore);
        }

        function onViewDialogSocial(act, type) {
            activity = act;
            onOpenDialogSocial(type);
        }

        const onViewReport = (act) => {
            activity = act;
            setOpenDialogReport(true);
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getUserFeed(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div class="yours--activity">
                <div class="activity-list">
                    <ul>
                        {listFeedUser != null &&
                            listFeedUser.map((item) => (
                                <ActivityItem
                                    key={item.atid}
                                    data={item}
                                    eventID={eventID}
                                    teamID={teamID}
                                    onViewDialogSocial={onViewDialogSocial}
                                    onViewReport={onViewReport}
                                    onViewReported={() => setOpenDialogComplained(true)}
                                    onViewLockComplain={() => setOpenDialogLockComplain(true)}
                                    onViewComplained={() => setOpenDialogComplained(true)}
                                    onDelete={() => handleOpenDialogDeleteActivity(true, item)}
                                    onViewFeedDetail={() => onViewFeedDetail(item, true)}
                                    onViewImage={onViewImage}
                                    onEdit={onEditActivity}></ActivityItem>
                            ))}
                    </ul>
                    <LoadMore />
                </div>

                {activity && openDialogDeleteActivity ? (
                    <DialogDeleteActivity
                        openDialogDeleteActivity={openDialogDeleteActivity}
                        data={activity}
                        type={ACTIVITY_TYPE.VALID}
                        onClose={() => setOpenDialogDeleteActivity(false)}
                    />
                ) : null}
                {activity && openDialogEditActivity ? (
                    <DialogEditActivity
                        data={activity}
                        openDialogEditActivity={openDialogEditActivity}
                        onClose={() => setOpenDialogEditActivity(false)}
                    />
                ) : null}
                {openDialogReport == true ? (
                    <DialogReport
                        openDialogReport={openDialogReport}
                        onViewReported={() => setOpenDialogComplained(true)}
                        data={activity}
                        eventID={eventID}
                        onClose={() => setOpenDialogReport(false)}
                    />
                ) : null}
                {openDialogComplained == true ? (
                    <DialogComplained
                        openDialogComplained={openDialogComplained}
                        type={ACTIVITY_TYPE.REPORT}
                        onClose={() => setOpenDialogComplained(false)}
                    />
                ) : null}
                {openDialogLockComplain == true ? (
                    <DialogLockComplain
                        openDialogLockComplain={openDialogLockComplain}
                        type={ACTIVITY_TYPE.REPORT}
                        onClose={() => setOpenDialogLockComplain(false)}
                    />
                ) : null}

                {openDialogSocial ? (
                    <DialogSocial
                        openDialogSocial={openDialogSocial}
                        onClose={() => handleOpenDialogSocial(false)}
                        data={activity}
                        type={SOCIAL_TYPE.FEED}
                        tab={tabSocial}
                    />
                ) : null}
                {activity && openDialogViewImage ? (
                    <DialogViewImage
                        data={activity}
                        openDialogViewImage={openDialogViewImage}
                        onClose={() => setOpenDialogViewImage(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default ListActivity;
