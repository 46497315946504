import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const Banner = inject(
    'authStore',
    'globalStore',
    'newsStore'
)(
    observer((props) => {
        const { language } = props.globalStore;
        const { listBanner } = props.newsStore;

        function BannerItem(props) {
            const { data } = props;
            return (
                <div className="swiper-slide">
                    <img className="lazyload" data-src="images/header-slide/header1.jpg" alt="" />
                    <div className="header-info">
                        <a className="header-title" href="#">
                            {data[`title_${language}`]}
                        </a>
                        <p className="header-description">{data[`desc_${language}`]}</p>
                    </div>
                    {data[`button_${language}`] != '' ?
                    <Link className="btn-blue" to={data[`button_link`]}>
                        <span>{data[`button_${language}`]}</span>
                        <i className="icon icon-arrow"></i>
                    </Link> : null}
                </div>
            );
        }

        return (
            <section className="section section--1 ">
                <div className="swiper-container header-swiper">
                    <div className="swiper-wrapper ">
                        {listBanner && listBanner.map((item) => {
                            return <BannerItem data = {item} key={item.id}/>
                        })}
                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-prev head-back"></div>
                    <div className="swiper-button-next head-next"></div>
                </div>
            </section>
        );
    })
);

Banner.propTypes = {
    className: PropTypes.string
};

export default Banner;
