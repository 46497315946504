import React from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimeFromSecond, getImageResource, getTimestamp } from '../../../../../utils';
import {
    SOCIAL_TAB,
    LIKE_TYPE,
    SOCIAL_TYPE,
    ACTIVITY_TYPE,
    FEED_REPORT_STATUS,
    EVENT_JOIN_STATUS
} from '../../../../../helpers/ConstDefined';
import { buildAvgPace, buildDistance } from '../../../../../utils/paceCalc';
import { humanTime, srcTypeImage, sportTypeImage } from '../../../../../helpers/ConvertHelper';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { API } from '../../../../../helpers/api/APIDefined';
import Image from 'react-graceful-image';

const ActivityItem = inject(
    'globalStore',
    'userStore',
    'postStore',
    'newsStore',
    'eventStore'
)(
    observer((props) => {
        const { data, eventID, teamID, type } = props;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { doLike } = props.postStore;
        const { listErrorActivity } = props.newsStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const [] = React.useState(data.photos);

        const isComplained = () => {
            if (data.complained) return true;
            if (_.isEmpty(data.evrp) || _.isEmpty(data.evrp[eventID])) return false;

            if (
                data.evrp[eventID].rpsts == FEED_REPORT_STATUS.COMPLAINING ||
                data.evrp[eventID].rpsts == FEED_REPORT_STATUS.COMPLAINED
            )
                return true;
            else return false;
        };

        const isReported = data.reported;

        const { t } = useTranslation();

        const imgMap = data.img.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg');

        function PhotoItem(props) {
            let { photo, index } = props;
            if (index < 6)
                return (
                    <li>
                        <Image noLazyLoad={true} src={getImageResource(photo, null, null, true)} alt="" />
                    </li>
                );
            else if (index == 6)
                return (
                    <li class="more">
                        <span class="num">+{data.photos.length - 6}</span>
                        <Image noLazyLoad={true} src={getImageResource(photo, null, null, true)} alt="" />
                    </li>
                );
            else return null;
        }

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const onViewLike = () => {
            props.onViewDialogSocial(data, SOCIAL_TAB.LIKE);
        };

        const onViewComment = () => {
            props.onViewDialogSocial(data, SOCIAL_TAB.COMMENT);
        };

        const onLikeFeed = async (data) => {
            const lktp = data.lktp == LIKE_TYPE.NONE ? LIKE_TYPE.LIKE : LIKE_TYPE.NONE;
            const params = {
                uid: userProfile && userProfile.uid,
                atid: data.atid,
                lktp
            };
            let result = await doLike(params, SOCIAL_TYPE.FEED);
            if (result && result != false) {
                data.lkls = result.lkls;
                data.lktp = lktp;
                data.lkcnt = result.lkcnt;
            }
        };

        function addDefaultSrc(e) {
            e.target.src = '../../../../img/feed_default.jpg';
        }

        function LikeStatus() {
            let status;
            if (data.lkcnt == 0) return null;

            if (data.lktp != 0) {
                if (data.lkcnt == 1) return t('global.only_you_like_stt');
                else {
                    status = t('global.you_other_like_stt').replace('[like]', data.lkcnt - 1);
                    return status;
                }
            } else return `${data.lkcnt} ${t('global.other_like_stt')}`;
        }

        function ErrorDesc() {
            const obj = data.event[eventID];
            if (obj) {
                let err = _.find(listErrorActivity, (item) => {
                    return item.id == obj.sts;
                });
                if (err)
                    return (
                        <div class="fail-notify">
                            <span class="mess">{err[`desc_${language}`]}</span> {t('global.view_details_at')}{' '}
                            <Link to={`/rules?evid=${eventID}`}>{t('global.rule_event')}</Link>
                        </div>
                    );
                else return null;
            } else return null;
        }

        const sendComplain = () => {
            if (currentEvent.cpsts == 1) {
                //đã qua thoi gian khóa
                if (Math.floor(Date.now()) > currentEvent.cptm) {
                    if (isComplained() == true) props.onViewComplained();
                    else props.onViewComplain(data);
                } else props.onViewLockComplain();
            } else if (isComplained() == true) props.onViewComplained();
            else props.onViewComplain(data);
        };

        const sendReport = () => {
            if (currentEvent.rpsts == 1) {
                //đã qua thoi gian khóa
                if (Math.floor(Date.now()) > currentEvent.rptm) {
                    if (isReported) props.onViewReported();
                    else props.onViewReport(data);
                } else props.onViewLockComplain();
            } else if (isReported) props.onViewReported();
            else props.onViewReport(data);
        };

        return (
            <>
                <li>
                    {userProfile && userProfile.uid == data.uid ? (
                        <div class="uiselector setting-more">
                            {type == ACTIVITY_TYPE.INVALID ? (
                                <div class="action" onClick={() => sendComplain()}>
                                    <a href="javascript:void(0);" class="complain-link">
                                        {t('global.complain')}
                                    </a>
                                </div>
                            ) : null}

                            <p
                                class="setting-more-btn"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"></p>
                            <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                <li onClick={() => props.onEdit(data)}>
                                    <span class="ico edit"></span> {t('global.edit')}
                                </li>
                                <li onClick={() => props.onDelete(data)}>
                                    <span class="ico del"></span> {t('global.delete')}
                                </li>
                            </ul>
                        </div>
                    ) : (type != ACTIVITY_TYPE.ALL && teamID && currentEvent && currentEvent.tmid == teamID) ||
                      (type != ACTIVITY_TYPE.ALL &&
                          currentEvent.evid == eventID &&
                          currentEvent.jsts != EVENT_JOIN_STATUS.NONE &&
                          currentEvent.jsts != EVENT_JOIN_STATUS.REQUESTING) ? (
                        <div class="uiselector setting-more">
                            <div class="action" onClick={() => sendReport()}>
                                <a href="javascript:void(0);" class="complain-link">
                                    {t('global.report')}
                                </a>
                            </div>
                        </div>
                    ) : null}
                    <div class="info">
                        <div class="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </div>
                        <div class="details">
                            <p class="name">{data.name}</p>
                            <div class={`date ${sportTypeImage(data.sport)}`}>
                                {humanTime(data.betm, language)}
                                {srcTypeImage(data.src) ? (
                                    <p class="img">
                                        <img src={srcTypeImage(data.src)} alt="" />
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div class="time-desc">{data.title}</div>
                    <div class="mess">{data.desc}</div>
                    {type == ACTIVITY_TYPE.INVALID ? <ErrorDesc /> : null}
                    <div class="img-list" onClick={() => props.onViewImage(data)}>
                        <ul>
                            {data.photos &&
                                data.photos.length > 0 &&
                                data.photos.map((item, index) => {
                                    return <PhotoItem key={item} photo={item} index={index} />;
                                })}
                        </ul>
                    </div>
                    <ul class="run-details">
                        <li>
                            <p class="lb">{t('global.time')}</p>
                            <p class="txt">{convertTimeFromSecond(data.mvtm)}</p>
                        </li>
                        <li>
                            <p class="lb">{t('global.speed')}</p>
                            <p class="txt">{buildAvgPace(data.sport, data.mvtm, data.dis)}</p>
                        </li>
                        <li>
                            <p class="lb">{t('global.distince')}</p>
                            <p class="txt">{buildDistance(data.sport, data.dis, true)}</p>
                        </li>
                    </ul>
                    <div class="road-map">
                        <Link to={`feed-detail?evid=${eventID}&atid=${data.atid}`}>
                            <img
                                src={imgMap}
                                onError={addDefaultSrc}
                                alt=""
                                style={{ width: '100%', display: 'block' }}
                            />
                        </Link>
                    </div>
                    <div class="account-group">
                        <ul class="avatars">
                            {data.lkls.map((item) => {
                                return (
                                    <li key={item.uid}>
                                        <img src={getImageResource(item.ava, true, true)} alt="" />
                                    </li>
                                );
                            })}
                        </ul>
                        <p class="txt" onClick={() => onViewLike()}>
                            {data.lkcnt ? <LikeStatus /> : null}
                        </p>
                    </div>
                    <div class="action-row">
                        <ul>
                            <li>
                                <button
                                    className={data.lktp != 0 ? 'like active' : 'like'}
                                    onClick={() => onLikeFeed(data)}></button>
                            </li>
                            <li>
                                <button className="mess" onClick={() => onViewComment()}>
                                    <span className="num">{data.cmcnt}</span>
                                </button>
                            </li>
                            <li>
                                <CopyToClipboard
                                    text={API.GLOBAL.SITE_DOMAIN + `feed-detail?evid=${eventID}&atid=${data.atid}`}
                                    onCopy={() => onCopySuccess()}>
                                    <button class="share"></button>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </div>
                </li>
            </>
        );
    })
);

export default ActivityItem;
