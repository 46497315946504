import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEY } from '../../../../helpers/api/APIDefined';
import { Link as RouterLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getImageResource, stripedHtml } from 'utils';
import { Redirect } from 'react-router';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const queryString = require('query-string');

const MENU_LANGUAGE = [
    {
        id: 'en',
        title: 'EN'
    },
    {
        id: 'vi',
        title: 'VI'
    }
];

const Topbar = inject(
    'authStore',
    'globalStore',
    'newsStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { history } = props;
        const { language, doChangeLanguage } = props.globalStore;
        const { doGetListSponsors, doGetListNews2021, doGetListBanner, doGetListPeopleSay } = props.newsStore;
        const { appVersion, doGetAppVersion } = props.globalStore;
        const { currentEvent, doGetEventData } = props.eventStore;
        const { userProfile, doClearUserProfile } = props.userStore;
        const { doLogout } = props.authStore;

        const { t, i18n } = useTranslation();

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const closeMenu = () => {
            setAnchorEl(null);
        };

        useEffect(() => {
            if (!appVersion) getAppVersion();
            doGetListNews2021();
            doGetListSponsors();
            doGetListPeopleSay();
            doGetListBanner();
        }, []);

        const handleLogout = () => {
            doClearUserProfile();
            doLogout();
            setAnchorEl(null);
        };

        function getAppVersion() {
            let params = {
                data: {}
            };
            doGetAppVersion(params);
        }

        const onChangeLanguage = (data) => {
            i18n.changeLanguage(data.id);
            localStorage.setItem(KEY.USER_LANGUAGE, data.id);
            doChangeLanguage(data.id);
        };

        function MenuLanguage(props) {
            const { data } = props;
            const css = data.id == language ? 'btn-choose-language active' : 'btn-choose-language';
            return (
                <a className={css} style={{ cursor: 'pointer' }} onClick={() => onChangeLanguage(data)}>
                    {data.title}
                </a>
            );
        }

        return (
            <div className="outer outer--floating">
                <div className="inner">
                    <section id="floating" className="section section--floating floating">
                        <div className="section__content floating__content">
                            <div className="nav-top setPosTop ">
                                <div className="container-top">
                                    <a className="mobile btn-hambuger">
                                        <i className="icon icon-hambuger"></i>
                                        <span className="hidden">hambuger</span>
                                    </a>
                                    <a className="logo" href={`/event`}>
                                        <img className="lazyload desktop" data-src="images/logo.png" alt="" />
                                        <img className="lazyload mobile" data-src="images/logo-mb.png" alt="" />
                                    </a>

                                    <ul className="list-nav">
                                        <li className="language">
                                            {MENU_LANGUAGE.map((item) => {
                                                return <MenuLanguage data={item} key={item.id} />;
                                            })}
                                        </li>
                                    </ul>

                                    {userProfile != null && appVersion != null && !_.isEmpty(userProfile) ? (
                                        <div class="logged">
                                            <a href="javascript:void(0);" class="account">
                                                <div class="avatar">
                                                    <img src={getImageResource(userProfile.ava, true, true)} alt="" />
                                                </div>
                                                <div class="name" onClick={handleOpenMenu}>
                                                    <span class="txt">{userProfile.name}</span>
                                                </div>
                                            </a>
                                            <Menu
                                                id="customized-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseMenu}>
                                                {userProfile ? (
                                                    <MenuItem onClick={closeMenu}>
                                                        <RouterLink
                                                            to={`/user-profile?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&uid=${userProfile.uid}`}
                                                            className="dropdown-item">
                                                            <i className="account"></i> {t('global.personal')}
                                                        </RouterLink>
                                                    </MenuItem>
                                                ) : null}

                                                <MenuItem onClick={closeMenu}>
                                                    <RouterLink
                                                        to={`/settings?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`}
                                                        className="dropdown-item">
                                                        <i className="fa fa-cog"></i> {t('global.setting')}
                                                    </RouterLink>
                                                </MenuItem>

                                                <MenuItem onClick={handleLogout}>
                                                    <RouterLink
                                                        to={`/info?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`}
                                                        className="dropdown-item">
                                                        <i className="fa fa-power-off"></i> {t('login.logout')}
                                                    </RouterLink>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    ) : (
                                        <div className="user-function">
                                            <RouterLink to="/login" className="btn-login btn-white">
                                                {t('login.login')}
                                            </RouterLink>
                                            <RouterLink to="/register" className="btn-signin btn-white">
                                                {t('login.register')}
                                            </RouterLink>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    })
);

export default Topbar;
