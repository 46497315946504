import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_STATUS_TYPE, EVENT_HOME_TYPE } from '../../../../helpers/ConstDefined';
import EventItem from '../EventItem';
import { useTranslation } from 'react-i18next';

const HomeEvent = inject(
    'userEventStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { navigation } = props;
        const {
            loading,
            eventListDone,
            totalEventDone,
            totalEventYet,
            totalEventReady,
            eventListYet,
            eventListReady,
            doGetListEvent
        } = props.eventStore;
        const { doSetTabEventHome, doSetEventStatusFilter } = props.userEventStore;

        const { t, i18n } = useTranslation();

        const { listEventSuggest, totalSuggestEvent, doGetLisSuggest } = props.userEventStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            getListEvent(EVENT_STATUS_TYPE.DONE);
            getListEvent(EVENT_STATUS_TYPE.YET);
            getListEvent(EVENT_STATUS_TYPE.READY);
        }, [userProfile]);

        useEffect(() => {
            getListEventSuggest();
        }, [userProfile]);

        const viewAll = (val) => {
            //open page all event for search
            doSetTabEventHome(EVENT_HOME_TYPE.ALL_EVENT);

            //storage current status event for reset filter
            global.EVENT_STATUS_RESET = val;
            doSetEventStatusFilter(val); //suggest, done, ready...
        };

        const getListEvent = (sts) => {
            const params = {
                uid: userProfile && userProfile.uid,
                sts,
                size: 4
            };
            doGetListEvent(params);
        };

        const getListEventSuggest = () => {
            if (!userProfile) return;
            let params = {
                uid: userProfile && userProfile.uid
            };
            doGetLisSuggest(params);
        };

        return (
            <div class="users-event-wrap">
                {userProfile && listEventSuggest && listEventSuggest.length > 0 ? (
                    <div class="users-event-box">
                        <div class="users-event-box-header">
                            <h3 class="title">{t('ue.suggest')}</h3>
                        </div>
                        <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                            {listEventSuggest &&
                                listEventSuggest.slice(0, 4).map((item) => {
                                    return (
                                        <EventItem
                                            data={item}
                                            key={item.evid}
                                            navigation={navigation}
                                            isSuggest={true}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                ) : null}

                {totalEventReady > 0 ? (
                    <div class="users-event-box">
                        <div class="users-event-box-header">
                            <h3 class="title">
                                {t('ue.ready')} ({totalEventReady})
                            </h3>
                            <a class="view-all" href="#" onClick={() => viewAll(EVENT_STATUS_TYPE.READY)}>
                                {t('global.all')}
                            </a>
                        </div>

                        <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                            {eventListReady &&
                                eventListReady.map((item) => {
                                    return <EventItem data={item} key={item.evid} navigation={navigation} />;
                                })}
                        </div>
                    </div>
                ) : null}

                {totalEventYet > 0 ? (
                    <div class="users-event-box">
                        <div class="users-event-box-header">
                            <h3 class="title">
                                {t('ue.soon')} ({totalEventYet})
                            </h3>
                            <a class="view-all" href="#" onClick={() => viewAll(EVENT_STATUS_TYPE.YET)}>
                                {t('global.all')}
                            </a>
                        </div>

                        <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                            {eventListYet &&
                                eventListYet.map((item) => {
                                    return <EventItem data={item} key={item.evid} navigation={navigation} />;
                                })}
                        </div>
                    </div>
                ) : null}

                {totalEventDone > 0 ? (
                    <div class="users-event-box">
                        <div class="users-event-box-header">
                            <h3 class="title">
                                {t('ue.finished')} ({totalEventDone})
                            </h3>
                            <a class="view-all" href="#" onClick={() => viewAll(EVENT_STATUS_TYPE.DONE)}>
                                {t('global.all')}
                            </a>
                        </div>

                        <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                            {eventListDone &&
                                eventListDone.map((item) => {
                                    return (
                                        <EventItem
                                            data={item}
                                            key={item.evid}
                                            navigation={navigation}
                                            finished={true}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    })
);

export default HomeEvent;
