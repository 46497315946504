import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { TeamItem, DialogSelectTeam, DialogViewTeam } from '../../components';
import TeamListToolbar from './TeamListToolbar';
import HeaderInfo from './HeaderInfo';
import TeamCategory from './TeamCategory';
import { stripedHtml, getTimestamp, log } from '../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

let teamObj;

const TeamList = inject(
    'authStore',
    'teamStore',
    'eventStore',
    'userStore',
    'leaderboardStore',
    'globalStore'
)(
    observer((props) => {
        const { eventID, navigation } = props;
        const [openDialogSelectTeam, setOpenDialogSelectTeam] = React.useState(false);

        const { loadingTeam, listTeam, teamName, sportType, tabTeamType, totalTeam, doGetListTeam } = props.teamStore;
        const { appVersion } = props.globalStore;
        const { userProfile } = props.userStore;

        const isShowLoadMore = listTeam == null ? false : listTeam.length < totalTeam;

        const { t } = useTranslation();

        const handleOpenDialogSelectTeam = (flag, team) => {
            setOpenDialogSelectTeam(flag);
            teamObj = team;
        };

        useEffect(() => {
            getListTeam();
            window.scrollTo(0, 0);
        }, []);

        const onChangeTab = () => {
        };

        function getListTeam(isLoadMore) {
            let params = {
                size: appVersion && appVersion.pgsz,
                sport: sportType && sportType.id,
                name: teamName,
                lgid: tabTeamType,
                sort: 7
            };
            doGetListTeam(params, isLoadMore);
        }

        function searchTeam(params) {
            const data = {
                sport: params.sport,
                name: params.name,
                lgid: params.lgid,
                sort: 7
            };
            doGetListTeam(data);
        }

        async function onViewTeamDetail(team) {
            navigation.push(`team-details?evid=${eventID}&tmid=` + team.tmid);
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingTeam)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListTeam(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="teams-tab">
                <HeaderInfo />
                <div className="teams-list">
                    <TeamListToolbar onChangeTab={onChangeTab} onSearchTeam={searchTeam} eventID={eventID} />

                    <div className="teams-list--content">
                        <TeamCategory onChangeTab={onChangeTab} eventID={eventID} />

                        <div className="tabs-content tabs-type-content">
                            <ul>
                                <li className="active">
                                    <div className="list">
                                        <ul>
                                            {listTeam != null &&
                                                listTeam.map((item) => (
                                                    <TeamItem
                                                        key={item.tmid}
                                                        onSelect={() => handleOpenDialogSelectTeam(true, item)}
                                                        onViewDetail={() => onViewTeamDetail(item)}
                                                        data={toJS(item)}></TeamItem>
                                                ))}
                                        </ul>
                                        {listTeam && listTeam.length == 0 ? (
                                            <p style={{ textAlign: 'center' }}>{t('global.no_data')}</p>
                                        ) : null}
                                        <LoadMore />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {teamObj ? (
                    <DialogSelectTeam
                        openDialogSelectTeam={openDialogSelectTeam}
                        onJoinTeamSuccess={props.onJoinTeamSuccess}
                        teamData={teamObj}
                        eventID={eventID}
                        onClose={() => handleOpenDialogSelectTeam(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default TeamList;
