import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml, formatNumber, formatDistanceKM, getTypeName } from '../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../helpers/ConstDefined';

const ChoiseSODialog = inject(
    'userStore',
    'globalStore',
    'eventStore'
)(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);
        const { openDialogChoiseSO } = props;
        const { currentEvent, doUpdateFund, doSetEventFund } = props.eventStore;
        const { userProfile } = props.userStore;

        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function onChoiseSO() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: currentEvent.evid,
                fuid: data.fuid
            }
            let result = await doUpdateFund(params);
            if (result != null) {
                doSetEventFund(data.fuid); //cập nhật fuid trong currentEvent 
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
            onCloseDialog();
        }

        const onCloseDialog = () => {
            props.onClose();
        };

        return (
            <Dialog open={openDialogChoiseSO}>
                <div
                    class="uprace-popup"
                    id="choose-organization"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{display: 'block'}}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w420">
                            <button type="button" class="popup-close" data-dismiss="modal" aria-label="Close" onClick={() => onCloseDialog()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div class="popup-body">
                                <div class="popup-team-notify">
                                    <p class="logo">
                                        <img src={data.ava} alt="" />
                                    </p>
                                    <p class="name">{t('global.select_so')}</p>
                                    <div class="txt">
                                        <p>
                                            {t('global.select_so_confirm')}
                                        </p>
                                    </div>
                                    <div class="popup-btns col-2 w120">
                                        <button class="btn-44 blue" onClick={() => onChoiseSO()}>{t('global.sure')}</button>
                                        <button class="btn-44 cancel" onClick={() => onCloseDialog()}>{t('global.seaagain')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default ChoiseSODialog;
