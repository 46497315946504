import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const CloseConfirmDialog = inject(
    'groupStore',
    'postStore',
    'userStore',
    'globalStore',
    'eventStore',
    'teamManageStore'
)(
    observer((props) => {
        const { openDialogCloseConfirm } = props;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        async function onCancelPost() {
            props.onClose();
            props.onCalcelPost()
        }

        return (
            <Dialog open={openDialogCloseConfirm}>
                <div
                    class="uprace-popup has-title in"
                    style={{ display: 'block' }}
                    id="cancel-post"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel">
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <div class="uprace-popup in-popup" style={{ display: 'block' }}>
                                <div class="popup-dialog">
                                    <div class="popup-content w380">
                                        <button
                                            type="button"
                                            class="popup-close"
                                            onClick={() => props.onClose()}></button>
                                        <div class="popup-body">
                                            <div class="popup-team-notify small">
                                                <p class="name">{t('eu.cancel_edit_post_title')}</p>
                                                <div class="txt">
                                                    <p>{t('eu.cancel_edit_post_msg')}</p>
                                                </div>
                                                <div class="popup-btns col-2 w140">
                                                    <button class="btn-44 blue" onClick={() => props.onClose()}>
                                                        {t('global.continous_edit')}
                                                    </button>
                                                    <button class="btn-44 cancel" onClick={() => onCancelPost()}>
                                                        {t('global.destroy')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CloseConfirmDialog;
