import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const NewsItem = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { data } = props;
        const { language } = props.globalStore;

        useEffect(() => {}, []);

        const css = data.hot == 1 ? 'news-item news-item--big flex' : 'news-item';
        return (
            <div className={css}>
                <RouterLink
                    className="news-item--thumb"
                    to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}>
                    <img className="lazyload" data-src={data.image} alt="" />
                </RouterLink>
                <div className="news-item--info">
                    <RouterLink
                        to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}
                        className="news-item--title"
                        href="#">
                        {data[`title_${language}`]}
                    </RouterLink>
                    {data.hot == 1 ? (
                        <a className="news-item--des" href="#">
                            {data[`desc_${language}`]}
                        </a>
                    ) : null}
                    <p className="news-item--date">{data.date}</p>
                </div>
            </div>
        );
    })
);

export default NewsItem;
