import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIBody, requestOAuth } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { LOGIN_TYPE } from '../helpers/ConstDefined';
import { log } from '../utils';

export default class ConnectStore {
    @observable stravaUserInfo = {};
    @observable listConnection = null;
    @observable stravaConnection = null;
    @observable garminConnection = null;

    @action.bound
    async doGetAuthCodeStrava(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(API.DEVICE_CONNECT.STRAVA_AUTHORIZE, params, HTTP_METHOD.POST)
            .then((resp) => {
                console.log(resp);
            })
            .catch((error) => {});
        return result;
    }

    @action.bound
    async doGetStravaToken(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(API.DEVICE_CONNECT.STRAVA_GET_TOKEN, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp && resp.access_token) {
                    console.log(resp);
                    result = true;
                    const openToken = {
                        access_token: resp.access_token,
                        token_type: resp.token_type,
                        refresh_token: resp.refresh_token,
                        expires_at: resp.expires_at
                    };
                    this.stravaUserInfo.openToken = openToken;
                    this.stravaUserInfo.name = resp.athlete.firstname + ' ' + resp.athlete.lastname;
                    this.stravaUserInfo.openId = resp.athlete.id;
                    this.stravaUserInfo.img = resp.athlete.profile != null ? resp.athlete.profile : null;
                    //localStorage.setItem(KEY.STRAVA_USER, JSON.stringify(this.stravaUserInfo));
                } else result = false;
            })
            .catch((error) => {
                alert(error);
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetGarminToken(params, headers) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.DEVICE_CONNECT.GARMIN_GET_TOKEN, params, HTTP_METHOD.POST, headers)
            .then((resp) => {
                console.log(resp);
                if (resp.code == API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = resp.data;
                } else {
                    this.loading = false;
                    result = null;
                }
            })
            .catch((error) => {
                log(error);
                result = null;
            });
        return result;
    }

    @action.bound
    async doStravaConnectAPI(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.DEVICE_CONNECT.STRAVA_CONNECT_API, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                log(error);
                result = null;
            });
        return result;
    }

    @action.bound
    async doGarminConnectAPI(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.DEVICE_CONNECT.GARMIN_CONNECT_API, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = resp;
                }
            })
            .catch((error) => {
                log(error);
                result = null;
            });
        return result;
    }

    @action.bound
    async doStravaDisconnectAPI(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.DEVICE_CONNECT.STRAVA_CONNECT_API, params, HTTP_METHOD.DELETE)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    if (params.data.openType == LOGIN_TYPE.STRAVA) this.stravaConnection = null;
                    else if (params.data.openType == LOGIN_TYPE.GARMIN) this.garminConnection = null;
                    result = resp;
                } else {
                    this.loading = false;
                    result = null;
                }
            })
            .catch((error) => {});
        return result;
    }

    @action.bound
    async doResyncStravaAPI(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.DEVICE_CONNECT.STRAVA_CONNECT_API, params, HTTP_METHOD.PUT)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = resp;
                } else {
                    this.loading = false;
                    result = null;
                }
            })
            .catch((error) => {});
        return result;
    }

    @action.bound
    async doGetConnectionStatus(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(API.DEVICE_CONNECT.STRAVA_CONNECT_API, params, HTTP_METHOD.GET)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.listConnection = resp.data;
                    this.stravaConnection = _.find(this.listConnection, (item) => {
                        return item.openType == LOGIN_TYPE.STRAVA;
                    });
                    this.garminConnection = _.find(this.listConnection, (item) => {
                        return item.openType == LOGIN_TYPE.GARMIN;
                    });
                    log(this.stravaConnection);
                    log(this.garminConnection);
                }
            })
            .catch((error) => {});
        return result;
    }
}
