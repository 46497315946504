import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';


const CompetitorChallenge = inject('authStore')(
    observer((props) => {

        useEffect(() => {}, []);

        return (
            <div></div>
        );
    })
);

export default CompetitorChallenge;
