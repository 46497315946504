import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const BottomStats = inject('authStore')(
    observer((props) => {
        const { feedDetail } = props;

        const { t } = useTranslation();

        return (
            <ul class="calo-list">
                <li>
                    <p class="lb">{t('global.height1')}</p>
                    <p class="num">{feedDetail.elev} m</p>
                </li>
                <li>
                    <p class="lb">{t('global.calore')}</p>
                    <p class="num">{feedDetail.calo} cal</p>
                </li>
                <li>
                    <p class="lb">{t('global.avg_bpm')}</p>
                    <p class="num">{feedDetail.bpm} bpm</p>
                </li>
                <li>
                    <p class="lb">{t('global.avg_spm')}</p>
                    <p class="num">{feedDetail.spm} spm</p>
                </li>
            </ul>
        );
    })
);

export default BottomStats;
