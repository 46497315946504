import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_HOME_TYPE, EVENT_STATUS_TYPE } from '../../helpers/ConstDefined';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CreateEventChoiseTypeDialog from './components/CreateEventChoiseTypeDialog';
import { stripedHtml } from 'utils';

const Toolbar = inject(
    'userEventStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { navigation } = props;
        const {
            tabEventHome,
            filterName,
            filterEventStatus,
            filterOrder,
            filterEventType,
            filterEventOffical,
            doSetTabEventHome,
            doGetListEventFilter,
            doSetEventNameFilter,
            doResetFilterEvent
        } = props.userEventStore;
        const [openDialogCreateEventChoiseType, setOpenDialogCreateEventChoiseType] = useState(false);
        const { userProfile } = props.userStore;

        const { t } = useTranslation();

        const getListFilterEvent = () => {
            let params = {
                uid: userProfile && userProfile.uid,
                name: filterName,
                sts: filterEventStatus,
                sort: 3,
                order: filterOrder,
                offical: filterEventOffical
            };
            doGetListEventFilter(params);
        };

        const TABS = [
            {
                id: 0,
                title: 'ue.all_event'
            },
            {
                id: 1,
                title: 'ue.your_event'
            }
        ];

        const onChangeTab = (tab) => {
            doSetTabEventHome(tab);
            doSetEventNameFilter('');
            doResetFilterEvent();
        };

        const handleChangeName = (val) => {
            doSetEventNameFilter(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                if (tabEventHome != EVENT_HOME_TYPE.ALL_EVENT) doSetTabEventHome(EVENT_HOME_TYPE.ALL_EVENT);
                else getListFilterEvent();
            }
        };

        function TabItem(props) {
            const { data } = props;
            let cssClass;
            if (tabEventHome != EVENT_HOME_TYPE.HOME_EVENT && tabEventHome != EVENT_HOME_TYPE.YOUR_EVENT) cssClass = '';
            else cssClass = data.id == tabEventHome ? 'active' : '';

            if (data.id == 1 && !userProfile) return null; //hidden your event tab if not login

            return (
                <li class={cssClass} onClick={() => onChangeTab(data.id)}>
                    <a href="javascript:void(0);">{t(data.title)}</a>
                </li>
            );
        }

        const displayFilter =
            filterEventStatus == EVENT_STATUS_TYPE.SUGGEST || tabEventHome == EVENT_HOME_TYPE.YOUR_EVENT
                ? 'none'
                : 'flex';

        return (
            <>
                <div class="users-event--tabs-menu">
                    <ul>
                        {TABS.map((item) => {
                            return <TabItem data={item} key={item.id} />;
                        })}
                    </ul>

                    {userProfile ? (
                        <div class="right" style={{ display: 'none' }}>
                            <button
                                class="btn-40 btn-blue btn-icon-add"
                                onClick={() => setOpenDialogCreateEventChoiseType(true)}>
                                <span class="txt">{t('ue.create_event')}</span>
                            </button>
                        </div>
                    ) : null}
                </div>

                <div class="users-event-search" style={{ display: displayFilter }}>
                    <div class="keyword">
                        <input
                            type="text"
                            placeholder={t('global.search')}
                            value={filterName}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                    </div>

                    <div class="filter notify">
                        <button class="btn-40" data-toggle="offcanvas" data-target="#filterEvent">
                            {t('ue.filter')}
                        </button>
                    </div>
                </div>

                {openDialogCreateEventChoiseType ? (
                    <CreateEventChoiseTypeDialog
                        navigation={navigation}
                        openDialogCreateEventChoiseType={openDialogCreateEventChoiseType}
                        onClose={() => setOpenDialogCreateEventChoiseType(false)}
                    />
                ) : null}
            </>
        );
    })
);

export default Toolbar;
