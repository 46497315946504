import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, colors, Badge } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { inject, observer } from 'mobx-react';
import * as Utils from '../../../../../../utils';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#EEEEEE',
        borderRadius: 20
    },
    media: {
        paddingTop: theme.spacing(2),
        height: 60,
        textAlign: 'center',
        '& > img': {
            height: '100%',
            width: 'auto'
        }
    },
    content: {
        padding: theme.spacing(1, 2)
    },
    actions: {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center'
    },
    cartIcon: {
        fontSize: 50,
        color: '#8BC34A'
    }
}));

const UpgradePlan = inject('cartStore')(
    observer(props => {
        const { className, ...rest } = props;

        const classes = useStyles();

        const { listCart, totalAmount } = props.cartStore;

        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.media}>
                    <Badge badgeContent={listCart.length} color="error">
                        <ShoppingCartIcon
                            className={classes.cartIcon}></ShoppingCartIcon>
                    </Badge>
                </div>
                <div className={classes.content}>
                    <Typography align="center" gutterBottom variant="h6">
                        Giỏ hàng của bạn
                    </Typography>
                    <Typography align="center" variant="body2">
                        {listCart.length} sản phẩm
                    </Typography>
                    <Typography align="center" variant="body2">
                        Tổng tiền: {Utils.formatCurrency(totalAmount, '0,0')}{' '}
                        VNĐ
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <RouterLink to="/cart">
                        <Button color="primary" variant="contained">
                            Chi tiết
                        </Button>
                    </RouterLink>
                </div>
            </div>
        );
    })
);

UpgradePlan.propTypes = {
    className: PropTypes.string
};

export default UpgradePlan;
