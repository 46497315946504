import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { SPORT_TYPE_DIALOG } from '../../../../data/master';
import { SPORT_TYPE } from '../../../../helpers/ConstDefined';
import { stripedHtml } from '../../../../utils';
import { TEAM_MSG } from '../../../../helpers/MsgDefined';

const MENU_CLUB = [
    { id: SPORT_TYPE.COMPANY, name: 'Doanh nghiệp' },
    { id: SPORT_TYPE.CLUB, name: 'Câu lạc bộ' },
    { id: SPORT_TYPE.SCHOOL, name: 'Trường học' }
];

const DialogCreateTeam = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { openDialogCreateTeam, showJoinTeam } = props;
        const { doCreateTeam } = props.teamStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar, doUploadFile } = props.globalStore;

        const [sportTab, setSportTab] = React.useState(2);
        const [clubTab, setClubTab] = React.useState(SPORT_TYPE.COMPANY);
        const [name, setName] = React.useState('');
        const [desc, setDesc] = React.useState('');
        const [avaTeam, setAvaTeam] = React.useState(null);
        const [avaTeamLocal, setAvaTeamLocal] = React.useState(null);
        const [coverTeam, setCoverTeam] = React.useState(null);
        const [coverTeamLocal, setCoverTeamLocal] = React.useState('../../../img/banner-profile-team.png');

        function validInput() {
            let hasError = false;
            if (!coverTeam || coverTeam.length == null) {
                setMessageSnackbar(TEAM_MSG.INVALID_INPUT.COVER);
                hasError = true;
            } else if (avaTeam == null) {
                setMessageSnackbar(TEAM_MSG.INVALID_INPUT.AVATAR);
                hasError = true;
            } else if (!name || name.length.trim() == 0) {
                setMessageSnackbar(TEAM_MSG.INVALID_INPUT.NAME);
                hasError = true;
            } else if (!name || name.length.trim() == 0) {
                setMessageSnackbar(TEAM_MSG.INVALID_INPUT.DESC);
                hasError = true;
            }
            if (hasError) {
                setSnackbarType('error');
                onOpenSnackbar();
            }
            return hasError;
        }

        const handleChangeDesc = (val) => {
            if(val.length  > 1500) return;
            setDesc(val);
        }

        async function createTeam() {
            if (!validInput()) {
                const params = {
                    uid: userProfile.uid,
                    name,
                    desc,
                    ava: avaTeam,
                    cover: coverTeam,
                    lgid: clubTab,
                    sport: sportTab
                };

                const result = await doCreateTeam(params);
                if (result != null) {
                    setMessageSnackbar(TEAM_MSG.CREATE_TEAM_SUCCESS);
                    setSnackbarType('success');
                    onOpenSnackbar();
                    userProfile.tmls.push(result);
                    props.onClose();
                    //userProfile.tmls.push()
                } else {
                    setMessageSnackbar(TEAM_MSG.CREATE_TEAM_FAIL);
                    setSnackbarType('error');
                    onOpenSnackbar();
                }
            }
        }

        const onCloseDialog = () => {
            setAvaTeam(null);
            setAvaTeamLocal(null);
            setCoverTeam(null);
            setName('');
            setDesc('');
            props.onClose();
        };

        async function uploadAvaTeam(e) {
            uploadFileServer(e.target.files[0], 0);
        }

        async function uploadCoverTeam(e) {
            uploadFileServer(e.target.files[0], 2);
        }

        async function uploadFileServer(file, type) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: type
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                if (type == 0) {
                    //upload ava
                    setAvaTeam(result);
                    setAvaTeamLocal(URL.createObjectURL(file));
                } else if (type == 2) {
                    //upload cover
                    setCoverTeam(result);
                    setCoverTeamLocal(URL.createObjectURL(file));
                }
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function MenuSportItem(props) {
            const { category } = props;
            const cssClass =
                category.id == sportTab
                    ? 'btn btn-outline-primary custom-btn-sport active'
                    : 'btn btn-outline-primary custom-btn-sport inactive';

            return (
                <button type="button" className={cssClass} onClick={() => setSportTab(category.id)}>
                    {category.img != null ? (
                        <span>
                            <img className="img-nav" src={category.img} alt="" />
                        </span>
                    ) : null}
                    {category.name}
                </button>
            );
        }

        function MenuClubItem(props) {
            const { category } = props;
            const cssClass =
                category.id == clubTab
                    ? 'btn btn-outline-primary btn-sport type-custom active'
                    : 'btn btn-outline-primary btn-sport type-custom inactive';

            return (
                <button type="button" className={cssClass} onClick={() => setClubTab(category.id)}>
                    {category.name}
                </button>
            );
        }

        return (
            <Modal show={openDialogCreateTeam} onHide={onCloseDialog}>
                <div role="dialog" aria-modal="true" className="show">
                    <div className="custom-modal modal-owner-edit" role="dialog" aria-hidden="true">
                        <div className="custom-modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Tạo đội mới</h3>
                                    <button
                                        type="button"
                                        className="close custom-close"
                                        data-dismiss="modal"
                                        onClick={onCloseDialog}
                                        aria-label="Close">
                                        <img src="../../../img/icon-close-2.png" alt="image" />
                                    </button>
                                </div>
                                <form action="">
                                    <div className="modal-body content">
                                        <div
                                            className="banner-modal-edit"
                                            style={{ backgroundImage: `url(${coverTeamLocal})` }}>
                                            <img src={coverTeamLocal} alt="Banner Create Challenge" />
                                            <div className="edit-image">
                                                <label className="custom-file-upload">
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={uploadCoverTeam}
                                                    />
                                                    <img src="../../../img/icon-camera.svg" alt="image" />
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className="profile-img"
                                            style={{ backgroundImage: `url(${avaTeamLocal})` }}>
                                            {avaTeamLocal != null ? <img src={avaTeamLocal} alt="image" /> : null}
                                        </div>
                                        <div className="action-change-img">
                                            <label className="custom-file-upload">
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={uploadAvaTeam}
                                                />
                                                <img src="../../../img/icon-camera.svg" alt="image" />
                                            </label>
                                        </div>
                                        <div className="content-edit">
                                            <div className="input-group mb-3 custom-input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon3">
                                                        Tên đội:
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    value={name}
                                                    onChange={(text) => setName(stripedHtml(text.target.value))}
                                                    className="form-control input-prepend"
                                                    id="basic-url"
                                                    aria-describedby="basic-addon3"
                                                />
                                            </div>

                                            <textarea
                                                className="description-team"
                                                value={desc}
                                                onChange={(text) => handleChangeDesc(stripedHtml(text.target.value))}></textarea>

                                            <div className="count-character">
                                                <span className="count-text">{desc.length}</span>
                                                <span className="total">/1500 ký tự</span>
                                            </div>
                                            <div className="sports-type">
                                                <div className="row-fluid">
                                                    <div className="pull-left">
                                                        <strong>Thể thao</strong>
                                                    </div>
                                                    <div className="pull-right">
                                                        {SPORT_TYPE_DIALOG.map((cat) => (
                                                            <MenuSportItem key={cat.id} category={cat} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sports-type">
                                                <div className="row-fluid">
                                                    <div className="pull-left">
                                                        <strong>Loại đội</strong>
                                                    </div>
                                                    <div className="pull-right">
                                                        {MENU_CLUB.map((cat) => (
                                                            <MenuClubItem key={cat.id} category={cat} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer modal-footer-custom">
                                        <div className="wrapper-btn custom-wrapper-btn">
                                            <button
                                                onClick={onCloseDialog}
                                                type="button"
                                                className="btn btn-outline-primary"
                                                data-dismiss="modal">
                                                Hủy
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => createTeam()}>
                                                Tạo đội
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogCreateTeam;
