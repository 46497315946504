import React from 'react';
import { inject, observer } from 'mobx-react';
import { SOCIAL_TAB, SPORT_TYPE_ACTIVITY, SOCIAL_TYPE } from '../../../../helpers/ConstDefined';
import { DialogSocial } from '../../../YourInfo/components';
import { convertShortDurationText, getImageResource } from '../../../../utils';
import { buildAvgPace, buildDistance } from '../../../../utils/paceCalc';
import { humanTime } from '../../../../helpers/ConvertHelper';
import BottomStats from './BottomStats';
import BottomStatsSwim from './BottomStatsSwim';
import BottomStatsRide from './BottomStatsRide';
import BottomStatsWalk from './BottomStatsWalk';
import { DialogViewImage } from '../../../YourInfo/components';
import { useTranslation } from 'react-i18next';
import { MemberName } from 'components/MemberName';

const DetailSummary = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { userProfile, feedDetail } = props.userStore;
        const { doResetImageFeed } = props.globalStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);

        const [openDialogDeleteActivity, setOpenDialogDeleteActivity] = React.useState(false);
        const [openDialogEditActivity, setOpenDialogEditActivity] = React.useState(false);
        const [openDialogViewImage, setOpenDialogViewImage] = React.useState(false);
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);
        const [icon, setIcon] = React.useState('run');

        const { t } = useTranslation();

        const handleOpenDialogEditActivity = (flag) => {
            doResetImageFeed(); //reset image photo of feed current
            setOpenDialogEditActivity(flag);
        };

        const handleOpenDialogViewImage = (flag) => {
            setOpenDialogViewImage(flag);
        };

        const handleOpenDialogDeleteActivity = (flag) => {
            setOpenDialogDeleteActivity(flag);
        };

        const handleOpenDialogSocial = (flag) => {
            setOpenDialogSocial(flag);
        };

        function onViewDialogSocial(type) {
            setOpenDialogSocial(true);
            setTabSocial(type);
        }

        function PhotoItem(props) {
            let { photo, index } = props;
            if (index < 6)
                return (
                    <li>
                        <a href="javascript:void(0);">
                            <img src={getImageResource(photo, null, null, true)} alt="" />
                        </a>
                    </li>
                );
            else if (index == 6)
                return (
                    <li class="more">
                        <a href="javascript:void(0);">
                            <span class="num">+{feedDetail.photos.length - 6}</span>
                            <img src={getImageResource(photo, null, null, true)} alt="" />
                        </a>
                    </li>
                );
            else return null;
        }

        function LikeStatus() {
            let status;
            if (feedDetail.lkcnt == 0) return null;

            if (feedDetail.lktp != 0) {
                if (feedDetail.lkcnt == 1) return t('global.only_you_like_stt');
                else {
                    status = t('global.you_other_like_stt').replace('[like]', feedDetail.lkcnt - 1);
                    return status;
                }
            } else return `${feedDetail.lkcnt} ${t('global.other_like_stt')}`;
        }

        function RenderBottom() {
            if (feedDetail.sport == SPORT_TYPE_ACTIVITY.RUNNING) {
                setIcon('run');
                return <BottomStats feedDetail={feedDetail} />;
            } else if (feedDetail.sport == SPORT_TYPE_ACTIVITY.SWIMMING) {
                setIcon('swim');
                return <BottomStatsSwim feedDetail={feedDetail} />;
            } else if (feedDetail.sport == SPORT_TYPE_ACTIVITY.CYCLING) {
                setIcon('bike');
                return <BottomStatsRide feedDetail={feedDetail} />;
            } else if (feedDetail.sport == SPORT_TYPE_ACTIVITY.WALKING) {
                setIcon('walk');
                return <BottomStatsWalk feedDetail={feedDetail} />;
            } else {
                setIcon('trialthon');
                return <BottomStatsSwim feedDetail={feedDetail} />;
            }
        }

        if (feedDetail != null)
            return (
                <div class="user-info-box">
                    <div class="l-col">
                        <div class="avatar-row">
                            <p class="avatar">
                                <img src={getImageResource(feedDetail.ava, true, true)} alt="" />
                            </p>
                            <div class="details">
                                <p class="name">
                                    {MemberName(feedDetail)} <span class="team">- </span>
                                </p>
                                <div class={`date ${icon}`}>
                                    <p class="txt"> {humanTime(feedDetail.betm, language)}</p>
                                    <p class="img">
                                        <img src="../../images/profile/garmin.png" alt="" />
                                    </p>
                                </div>
                                <div class="time-desc">{feedDetail.title}</div>
                                <div class="mess">{feedDetail.desc}</div>
                            </div>
                        </div>
                        <div class="img-list" onClick={() => setOpenDialogViewImage(true)}>
                            <ul>
                                {feedDetail.photos &&
                                    feedDetail.photos.length > 0 &&
                                    feedDetail.photos.map((item, index) => {
                                        return <PhotoItem key={item} photo={item} index={index} />;
                                    })}
                            </ul>
                        </div>
                        <div class="account-group">
                            <div class="l-acc">
                                <ul class="avatars">
                                    {feedDetail.lkls.map((item) => {
                                        return (
                                            <li key={item.uid}>
                                                <img src={getImageResource(item.ava, true, true)} alt="" />
                                            </li>
                                        );
                                    })}
                                </ul>
                                <p class="txt" onClick={() => onViewDialogSocial(SOCIAL_TAB.LIKE)}>
                                    <a href="javascript:void(0);">{feedDetail.lkcnt ? <LikeStatus /> : null}</a>
                                </p>
                            </div>
                            {feedDetail.cmcnt > 0 ? (
                                <div class="r-acc" onClick={() => onViewDialogSocial(SOCIAL_TAB.COMMENT)}>
                                    <a href="javascript:void(0);">
                                        {feedDetail.cmcnt} {t('global.comment')}
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div class="r-col">
                        <ul class="km-list">
                            <li>
                                <div class="km-row">
                                    <p class="lb">{t('global.distince')}</p>
                                    <p class="km">{buildDistance(feedDetail.sport, feedDetail.dis)}</p>
                                </div>
                            </li>
                            <li>
                                <div class="km-row">
                                    <p class="lb">{t('global.avg_speed')}</p>
                                    <p class="km">{buildAvgPace(feedDetail.sport, feedDetail.dur, feedDetail.dis)}</p>
                                </div>
                                <div class="km-row">
                                    <p class="lb">{t('global.moving_time')}</p>
                                    <p class="km">{convertShortDurationText(feedDetail.mvtm, true)}</p>
                                </div>
                            </li>
                            <li>
                                <div class="km-row">
                                    <p class="lb">{t('global.total_time')}</p>
                                    <p class="km">{convertShortDurationText(feedDetail.dur, true)}</p>
                                </div>
                                <div class="km-row">
                                    <p class="lb">{t('global.avg_moving_time')}</p>
                                    <p class="km">{buildAvgPace(feedDetail.sport, feedDetail.mvtm, feedDetail.dis)}</p>
                                </div>
                            </li>
                        </ul>
                        <RenderBottom />
                    </div>

                    {openDialogSocial ? (
                        <DialogSocial
                            openDialogSocial={openDialogSocial}
                            onClose={() => handleOpenDialogSocial(false)}
                            data={feedDetail}
                            type={SOCIAL_TYPE.FEED}
                            tab={tabSocial}
                        />
                    ) : null}

                    {feedDetail && openDialogViewImage ? (
                        <DialogViewImage
                            data={feedDetail}
                            openDialogViewImage={openDialogViewImage}
                            onClose={() => setOpenDialogViewImage(false)}
                        />
                    ) : null}
                </div>
            );
    })
);

export default DetailSummary;
