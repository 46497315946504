import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml } from '../../../../../utils';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';
import { isAccountDeleted, MemberCode } from 'components/MemberName';

const ChoiseLeaderDialog = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID, teamID } = props;

        const { t } = useTranslation();

        const MemberName = (data) => {
            if (isAccountDeleted(data)) return <span style={{ color: '#919EAB' }}>{t('global.deleted_account')}</span>;
            return <span className="name">{data.name}</span>;
        };

        useEffect(() => {
            getMemberGroup();
        }, []);

        const { openDialogChoiseLeader } = props;
        const { userProfile } = props.userStore;
        const { loadingGroup, listMemberChoiseLeader, totalRecordChoiseLeader, doGetListMember } = props.groupStore;

        const isShowLoadMore =
            listMemberChoiseLeader == null ? false : listMemberChoiseLeader.length < totalRecordChoiseLeader;

        const [name, setName] = React.useState('');

        const getMemberGroup = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                grid: data.grid,
                name,
                tmid: teamID
            };
            doGetListMember(params, isLoadMore);
        };

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getMemberGroup();
            }
        };

        const onSelect = (data) => {
            props.onShowConfirm(data);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberGroup(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <Dialog open={openDialogChoiseLeader}>
                <div
                    className="uprace-popup has-title in"
                    id="choose-new-leader"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w800">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('team.group.leavedl.choise_leader.title')}</h2>
                            <div className="popup-body">
                                <div className="popup-manage-group choose-leader">
                                    <div className="members-joined">
                                        <div
                                            className="notify-box red"
                                            dangerouslySetInnerHTML={{
                                                __html: t('team.group.leavedl.choise_leader.desc')
                                            }}></div>
                                        <div className="m-search">
                                            <button className="del-btn"></button>
                                            <input
                                                type="text"
                                                placeholder={t('global.search_by_name')}
                                                value={name}
                                                onKeyDown={handleKeyDown}
                                                onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                            />
                                        </div>
                                        <div class="m-list-scroll">
                                            {!listMemberChoiseLeader || listMemberChoiseLeader.length == 0 ? (
                                                <div style={{ textAlign: 'center' }}>{t('eu.search_empty')}</div>
                                            ) : null}

                                            <ul className="m-list">
                                                {listMemberChoiseLeader &&
                                                    listMemberChoiseLeader.map((item) => {
                                                        if (item.uid != userProfile.uid)
                                                            return (
                                                                <li>
                                                                    <div className="item">
                                                                        <button
                                                                            className="btn-32 blue"
                                                                            onClick={() => onSelect(item)}>
                                                                            {t('global.select')}
                                                                        </button>
                                                                        <div className="user">
                                                                            <p className="avatar">
                                                                                <img
                                                                                    src={getImageResource(
                                                                                        item.ava,
                                                                                        true
                                                                                    )}
                                                                                    alt=""
                                                                                />
                                                                            </p>
                                                                            <div className="details">
                                                                                <p className="name">
                                                                                    {MemberName(item)}
                                                                                </p>
                                                                                <p className="code">
                                                                                    {MemberCode(item)}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        else return null;
                                                    })}
                                            </ul>
                                        </div>
                                        <LoadMore />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default ChoiseLeaderDialog;
