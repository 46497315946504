import React from 'react';
import { inject, observer } from 'mobx-react';
import { FOLLOW_STT } from '../../../../helpers/ConstDefined';
import { getImageResource } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberName, MemberAddress } from 'components/MemberName';

const UserItem = inject(
    'authStore',
    'userStore',
    'userProfileStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID } = props;
        const { userProfile, userProfileOther } = props.userStore;
        const { doFollow } = props.userProfileStore;

        const { t } = useTranslation();

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
            if (result == true) {
                if (userProfile.uid == userProfileOther.uid) {
                    if (action == FOLLOW_STT.UNFOLLOW) userProfileOther.ficnt -= 1;
                    else if (action == FOLLOW_STT.FOLLOW) userProfileOther.ficnt += 1;
                }
            }
        }

        return (
            <li>
                {data.fists == 2 ? (
                    userProfile.uid != data.uid ? (
                        <button class="btn-32 blue-light" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                            {t('global.unfollow')}
                        </button>
                    ) : null
                ) : userProfile.uid != data.uid ? (
                    <button class="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                        {t('global.follow')}
                    </button>
                ) : null}
                <Link to={`/user-profile?evid=${eventID}&uid=${data.uid}`}>
                    <p class="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div class="details">
                        <p class="name">{MemberName(data)}</p>
                        <p class="addr">{MemberAddress(data)}</p>
                    </div>
                </Link>
            </li>
        );
    })
);

export default UserItem;
