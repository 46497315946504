import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { convertShortDuration, formatNumber } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const DetailDistanceSwim = inject('authStore')(
    observer((props) => {
        const { feedDetail } = props;

        const { t } = useTranslation();

        function LapItem(props) {
            const { data } = props;
            return (
                <li>
                    <div class="a-col lap">{data.index}</div>
                    <div class="a-col road">{formatNumber(data.distance)} m</div>
                    <div class="a-col time">{convertShortDuration(data.time, true)}</div>
                    <div class="a-col pace">{convertShortDuration(data.pace, true)}</div>
                </li>
            );
        }
        return (
            <div class="achievements-list">
                <ul class="title">
                    <li>Lap</li>
                    <li>{t('global.distince')}</li>
                    <li>{t('global.time')}</li>
                    <li>Pace (min/100m)</li>
                </ul>
                <ul class="list">
                    {feedDetail &&
                        feedDetail.laps.map((item) => {
                            return <LapItem key={item.index} data={toJS(item)} />;
                        })}
                </ul>
            </div>
        );
    })
);

export default DetailDistanceSwim;
