import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import DialogCreateChallenge from '../DialogCreateChallenge';

const NotChallenge = inject('authStore')(
    observer((props) => {
        const { eventID } = props;
        const [openDialogCreateChallenge, setOpenDialogCreateChallenge] = React.useState(false);

        useEffect(() => {}, []);

        const handleOpenDialogCreateChallenge = (flag) => {
            setOpenDialogCreateChallenge(flag);
        };
        
        return (
            <>
                <div className="banner-challenges">
                    <div className="row ">
                        <div className="col-md-4 ">
                            <div className="wrapper-content">
                                <p className="">
                                    Mỗi vận động viên có thể tạo Thử thách cá nhân cho bản thân mình trong UpRace 2020
                                    bằng cách tự cam kết các mục tiêu bao gồm:
                                </p>

                                <div className="font-weight-bold">
                                    <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tổng số Kilomet (Km) chạy</p>
                                    <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Số ngày chạy</p>
                                    <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quãng đường dài nhất trong 1 lần chạy</p>
                                </div>

                                <p className="">
                                    Vận động viên (VĐV) có thể chia sẻ cho bạn bè thử thách cá nhân của mình, đồng thời
                                    có thể xem thử thách cá nhân của VĐV khác.
                                </p>
                                <div className="div-button" onClick={() => handleOpenDialogCreateChallenge(true)}>
                                    <a className="btn btn-danger btn-create-challenge">
                                        Tạo thử thách cá nhân
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 ">
                            <div className="background-challenges">
                                <img src="../../../img/background-challenges.png" alt="banner-challenges" />
                            </div>
                        </div>
                    </div>
                </div>
                <DialogCreateChallenge
                    openDialogCreateChallenge={openDialogCreateChallenge}
                    challengeDetail={null}
                    eventID={eventID}
                    onClose={() => handleOpenDialogCreateChallenge(false)}
                />
            </>
        );
    })
);

export default NotChallenge;
