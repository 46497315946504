import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { STATS_TYPE, JOIN_TEAM_STT, SPORT_TYPE_ACTIVITY } from '../../../../../helpers/ConstDefined';
import { formatDistanceKM, getTypeName, formatNumber, getImageResource } from '../../../../../utils';
import { sportTypeImage } from '../../../../../helpers/ConvertHelper';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../../data/master';
import LeaveTeamConfirmDialog from './LeaveTeamConfirmDialog';
import EditTeamDialog from '../EditTeamDialog';

const TeamInfo = inject(
    'authStore',
    'teamStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { eventID, teamID, navigation } = props;
        const { currentTeam, doGetTeamDetail } = props.teamStore;
        const { userProfile, joinStatus, doSetJoinStatus, doSetRoleUser } = props.userStore;
        const { currentEvent, eventTeamStats, doGetEventStats } = props.eventStore;

        const [openDialogLeaveTeamConfirm, setOpenDialogLeaveTeamConfirm] = React.useState(false);
        const [openDialogEditTeam, setOpenDialogEditTeam] = React.useState(false);

        useEffect(() => {
            getTeamEventStats();
            getTeamDetail();
        }, [userProfile]);

        const { t } = useTranslation();

        async function getTeamDetail() {
            let data = {
                uid: userProfile && userProfile.uid,
                tmid: teamID
            };
            let result = await doGetTeamDetail(data);
            if (!result) {
                navigation.push('/');
            }
        }

        function getTeamEventStats() {
            let data = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: STATS_TYPE.TEAM, // 0: event: 1: league, 2: team, 2: fund
                value: teamID
            };
            doGetEventStats(data, STATS_TYPE.TEAM);
        }

        if (eventTeamStats != null && currentTeam != null) {
            let role = null;

            if (userProfile == null || currentTeam.jsts == JOIN_TEAM_STT.NOT_JOIN) {
                role = {
                    id: JOIN_TEAM_STT.NOT_JOIN,
                    name: 'Not join'
                };
            } else if (userProfile != null) {
                if (userProfile.uid == currentTeam.owner) {
                    role = {
                        id: JOIN_TEAM_STT.OWNER,
                        name: 'Owner'
                    };
                } else if (currentTeam.jsts == JOIN_TEAM_STT.ADMIN) {
                    role = {
                        id: JOIN_TEAM_STT.OWNER,
                        name: 'Admin'
                    };
                } else if (currentTeam.jsts == JOIN_TEAM_STT.MEMBER) {
                    role = {
                        id: JOIN_TEAM_STT.MEMBER,
                        name: 'Member'
                    };
                }
            }
            doSetRoleUser(role); // cập nhật role của user trên currentTeam
            doSetJoinStatus(currentTeam.jsts);

            const onChangeTeam = () => {
                setOpenDialogLeaveTeamConfirm(true);
            };

            const showEdit =
                (userProfile && userProfile.uid == currentTeam.owner) || currentTeam.jsts == JOIN_TEAM_STT.ADMIN;

            return (
                <div className="team-top-info">
                    <div className="bn-block">
                        <p className="bn">
                            <img src={getImageResource(currentTeam.cover)} alt="" />
                        </p>
                        <div className="logo">
                            {showEdit ? (
                                <button class="edit-btn gray" onClick={() => setOpenDialogEditTeam(true)}></button>
                            ) : null}
                            <p className="logo-inside">
                                <img src={getImageResource(currentTeam.ava, true)} alt="" />
                            </p>
                        </div>
                    </div>
                    <div className="info-block">
                        {showEdit ? (
                            <p className="name edit">
                                <Link to={`team-profile?evid=${eventID}&tmid=${teamID}`}>{currentTeam.name}</Link>
                                <button className="edit-btn gray" onClick={() => setOpenDialogEditTeam(true)}></button>
                            </p>
                        ) : (
                            <p className="name">
                                <Link to={`team-profile?evid=${eventID}&tmid=${teamID}`}>{currentTeam.name}</Link>
                            </p>
                        )}
                        <p className={`type ${sportTypeImage(currentTeam.sport)}`}>{t(getTypeName(TEAM_TYPE, currentTeam.lgid))}</p>

                        {currentEvent && currentTeam && currentEvent.tmid == currentTeam.tmid ? (
                            <p className="joined-status" onClick={onChangeTeam}>
                                <a href="javascript:void(0);">{t('global.group_status.joined')}</a>
                            </p>
                        ) : null}
                        <ul className="statis-block">
                            <li className="km">
                                <p className="ico"></p>
                                <p className="num">{formatDistanceKM(eventTeamStats.dis, true)}</p>
                                <p className="lb">{t('global.number_km')}</p>
                            </li>
                            <li className="level">
                                <p className="ico"></p>
                                <p className="num">{formatNumber(eventTeamStats.rank)}</p>
                                <p className="lb">{t('global.rank_current')}</p>
                            </li>
                            <li className="members">
                                <p className="ico"></p>
                                <p className="num">{formatNumber(eventTeamStats.mem)}</p>
                                <p className="lb">{t('global.member1')}</p>
                            </li>
                        </ul>
                    </div>

                    {currentTeam ? (
                        <LeaveTeamConfirmDialog
                            openDialogLeaveTeamConfirm={openDialogLeaveTeamConfirm}
                            onClose={() => setOpenDialogLeaveTeamConfirm(false)}
                            teamData={currentTeam}
                            eventID={eventID}
                        />
                    ) : null}

                    {currentTeam && openDialogEditTeam ? (
                        <EditTeamDialog
                            openDialogEditTeam={openDialogEditTeam}
                            onClose={() => setOpenDialogEditTeam(false)}
                            eventID={eventID}></EditTeamDialog>
                    ) : null}
                </div>
            );
        } else return null;
    })
);

export default TeamInfo;
