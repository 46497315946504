import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { EVENT_STATUS_TYPE, EVENT_TYPE } from 'helpers/ConstDefined';

const CreateEventChoiseTypeDialog = inject(
    'userStore',
    'userEventStore'
)(
    observer((props) => {
        const { navigation } = props;
        const { doSetEventCreateType } = props.userEventStore;

        useEffect(() => {}, []);

        const { openDialogCreateEventChoiseType } = props;

        const { t } = useTranslation();

        const choiseTypeEvent = (type) => {
            if (type != EVENT_TYPE.CHALLENGE) return;
            doSetEventCreateType(type);
            navigation.push('/create-event');
        };

        return (
            <Dialog open={openDialogCreateEventChoiseType}>
                <div
                    class="uprace-popup has-title"
                    id="create-event"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w800">
                            <div class="popup-header">
                                <h4 class="title">{t('ue.create_event_title')}</h4>
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="popup-body">
                                <ul class="list-group">
                                    <li class="list-group-item active" onClick={() => choiseTypeEvent(EVENT_TYPE.CHALLENGE)}>
                                        <div class="d-flex align-items-lg-center flex-column flex-sm-row">
                                            <div class="flex-shrink-0">
                                                <div class="icon-item challenges"></div>
                                            </div>
                                            <div class="flex-grow-0">
                                                <div class="title">{t('ue.challen')}</div>
                                                <div class="txt">{t('ue.challen_desc')}</div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <span class="icon-arrow_thick"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item" onClick={() => choiseTypeEvent(EVENT_TYPE.VITURL_RACE)}>
                                        <div class="d-flex align-items-lg-center flex-column flex-sm-row g-2">
                                            <div class="flex-shrink-0">
                                                <img
                                                    src="../../images/icons/virtual_race.svg"
                                                    alt="virtual race"
                                                    class="icon"
                                                />
                                            </div>
                                            <div class="flex-grow-0 ms-3">
                                                <div class="title title__disabled">Virtual race</div>
                                                <div>{t('ue.soon_func')}</div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <span class="icon-arrow_thick"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="popup-footer justify-content-end">
                                <button
                                    class="btn-40 btn-outline-blue btn-close"
                                    data-dismiss="modal"
                                    onClick={props.onClose}>
                                    {t('global.destroy')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CreateEventChoiseTypeDialog;
