import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import RankNavigation from '../RankTable/RankNavigation';
import DialogRankUser from '../DialogRankUser';
import DialogEditLeaderboard from '../DialogEditLeaderboard';
import DialogDeleteLeaderboard from '../DialogDeleteLeaderboard';
import DialogConfirmLeave from '../DialogConfirmLeave';
import CustomRankItem from './CustomRankItem';
import { getImageResource } from '../../../../utils';

import _ from 'lodash';
import { toJS } from 'mobx';

let currentRank;

const CustomRank = inject(
    'authStore',
    'userStore',
    'userLeaderboardStore',
    'globalStore'
)(
    observer((props) => {
        const { navigation, eventID } = props;
        const { userProfile, doRemoveUserLeaderboard } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const childRef = useRef();
        const childRef1 = useRef();

        const [openDialogRankUser, setOpenDialogRankUser] = React.useState(false);
        const [openDialogConfirmLeave, setOpenDialogConfirmLeave] = React.useState(false);
        const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
        const [openDialogEditLeaderboard, setOpenDialogEditLeaderboard] = React.useState(false);
        const {
            currentUserLeaderboard,
            doGetDetailLeaderboard,
            doSetUserLeaderboard,
            doLeaveUserLeaderboard
        } = props.userLeaderboardStore;

        const onChangeTab = () => {};

        const handleOpenDialogRankUser = () => {
            setOpenDialogRankUser(true);
        };

        const handleOpenDialogDelete = () => {
            setOpenDialogDelete(true);
        };

        const handleOpenDialogConfirmLeave = () => {
            setOpenDialogConfirmLeave(true);
        };

        const handleOpenDialogEditLeaderboard = () => {
            setOpenDialogEditLeaderboard(true);
        };

        function onAddUserLeaderboard() {
            doSetUserLeaderboard(null);
            childRef1.current.initData(null);
            handleOpenDialogEditLeaderboard();
        }

        async function onViewUserLeaderboard(data) {
            let params = {
                uid: userProfile.uid,
                rkid: data.rkid
            };
            let result = await doGetDetailLeaderboard(params);
            if (result == true) {
                childRef.current.initData();
                handleOpenDialogRankUser();
            }
            else if(result == 124) {
                setMessageSnackbar('Bảng xếp hạng đã bị xóa bởi người tạo.');
                setSnackbarType('error');
                onOpenSnackbar();
                doRemoveUserLeaderboard(data.rkid);
            }
        }

        async function onDeleteUserLeaderboard(data) {
            currentRank = data;
            handleOpenDialogDelete();
        }

        async function onEditUserLeaderboard() {
            childRef1.current.initData(true);
            handleOpenDialogEditLeaderboard();
        }

        const handleConfirmLeaveLeaderboard = (data) => {
            currentRank = data;
            setOpenDialogConfirmLeave(true);
        }

        async function onLeaveUserLeaderboard(data) {
            let rkid = !data ? currentUserLeaderboard.rkid : data.rkid;
            let params = {
                uid: userProfile.uid,
                rkid
            };
            let result = await doLeaveUserLeaderboard(params);
            if (result) {
                doRemoveUserLeaderboard(rkid);
                setMessageSnackbar('Rời bảng xếp hạng thành công');
                setSnackbarType('success');
            } else {
                setMessageSnackbar('Rời bảng xếp hạng thất bại.');
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        if (userProfile != null && !_.isEmpty(userProfile)) {
            let list = _.sortBy(userProfile.rkls, (item) => {
                return item.rkid;
            }).reverse();

            list = _.filter(userProfile.rkls,(item) => {
                return item.rksrc == eventID;
            });

            return (
                <div className="container">
                    <div className="wrapper-table">
                        <RankNavigation onChangeTab={onChangeTab} />

                        <div
                            className="tab-content nav-content wrapper-list-member fade show active"
                            id="customize-leaderboard"
                            role="tabpanel"
                            aria-labelledby="customize-leaderboard-tab">
                            {list.length == 0 ? (
                                <div className="clearfix">
                                    <div className="text-center">
                                        <div className="text-center">
                                            <h5 className="title">
                                                Bảng xếp hạng tự tạo giúp bạn dễ dàng theo dõi bạn bè của bạn trong sự
                                                kiện. Bạn chưa nằm trong bảng xếp hạng cá nhân nào. Hãy thử
                                                tạo bảng xếp hạng cá nhân của mình nhé.
                                            </h5>
                                            <button
                                                className="btn btn-primary btn-search"
                                                onClick={() => onAddUserLeaderboard()}>
                                                Tạo bảng xếp hạng
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="clearfix">
                                        <div className="pull-left">
                                            <h4 className="title">
                                                Có {list.length} bảng xếp hạng bạn đang tham gia
                                            </h4>
                                        </div>
                                        <div className="pull-right">
                                            <button
                                                className="btn btn-primary btn-search"
                                                onClick={() => onAddUserLeaderboard()}>
                                                Tạo bảng xếp hạng
                                            </button>
                                        </div>
                                    </div>
                                    <div className="table-responsive custom-table custom-leaderboard-customize">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <p>Tên bảng xếp hạng</p>
                                                    </th>
                                                    <th>
                                                        <p>Người tạo</p>
                                                    </th>
                                                    <th>
                                                        <p>Số thành viên</p>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {list.map((item) => {
                                                    return (
                                                        <CustomRankItem
                                                            data={toJS(item)}
                                                            onView={() => onViewUserLeaderboard(item)}
                                                            onDelete={() => onDeleteUserLeaderboard(item)}
                                                            onLeave={() => handleConfirmLeaveLeaderboard(item)}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {currentUserLeaderboard ? (
                        <DialogRankUser
                            ref={childRef}
                            openDialogRankUser={openDialogRankUser}
                            onEditUserLeaderboard={onEditUserLeaderboard}
                            onLeaveUserLeaderboard={onLeaveUserLeaderboard}
                            navigation={navigation}
                            eventID={eventID}
                            onClose={() => setOpenDialogRankUser(false)}
                        />
                    ) : null}

                    {userProfile ? <DialogEditLeaderboard
                        ref={childRef1}
                        eventID={eventID}
                        openDialogEditLeaderboard={openDialogEditLeaderboard}
                        onClose={() => setOpenDialogEditLeaderboard(false)}
                    /> : null}

                    <DialogDeleteLeaderboard
                        openDialogDelete={openDialogDelete}
                        data={currentRank}
                        onClose={() => setOpenDialogDelete(false)}
                    />

                    {currentRank ? <DialogConfirmLeave
                        openDialogConfirmLeave={openDialogConfirmLeave}
                        data={currentRank}
                        onClose={() => setOpenDialogConfirmLeave(false)}
                    /> : null}
                </div>
            );
        } else
            return (
                <div className="container">
                    <div className="wrapper-table">
                        <RankNavigation onChangeTab={onChangeTab} />

                        <div className="tab-content nav-content">
                            <Link to="/login">
                                <div className="text-center">
                                    <button className="btn btn-primary">Đăng nhập để tạo BXH của bạn</button>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            );
    })
);

export default CustomRank;
