import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Topbar, Footer } from './components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Main = inject(
    'eventStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { children } = props;

        const [openSidebar, setOpenSidebar] = useState(false);
        const { messageSnackbar, onCloseSnackbar, openSnackbar, snackbarType } = props.globalStore;

        const navigationMain = (tab) => {};

        const { t } = useTranslation();

        return (
            <>
                <Helmet>
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css"
                    />
                    <script src="https://img.zing.vn/products/uprace/skin-2021/dist/main/main.js"></script>
                </Helmet>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={onCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={1500}>
                    <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>
                <div className="outer">
                    <div id="scrollContent" className="inner scrollContent scrollSwipe">
                        <main>{children}</main>
                    </div>
                </div>
                <p id="back-top" className="back-top">
                    <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                </p>
                <Topbar history={children} navigationMain={(tab) => navigationMain(tab)} />
            </>
        );
    })
);

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
