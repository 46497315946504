import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const NotJoinTeam = inject('authStore')(
    observer((props) => {
        const { eventID } = props;
        
        useEffect(() => {}, []);

        return (
            <div className="info-team">
                <div className="wrapper-content wrapper-content-custom">
                    <div className="intro">
                        <div className="team-head">
                            <img src="../../../img/banner-left-unlogin.png" alt="image" />
                        </div>
                        <div className="wrapper-introduce-unlogin">
                            <p className="title-description">Chọn đội để tham gia thi đấu</p>
                            <p className="content-description">
                                Bạn được chọn 1 đội để tham gia thi đấu. Sau khi chọn đội, thành tích của
                                bạn sẽ được cộng vào tổng thành tích của Đội. Bạn không được chuyển đội kể từ thời điểm
                                cuộc thi bắt đầu.
                            </p>
                        </div>
                    </div>
                    <div className="wrapper-join-activity">
                        <div className="form-group">
                            <Link className="btn btn-confirm btn-danger" to={'/team?evid=' + eventID}>
                                Tham gia đội
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default NotJoinTeam;
