import { observable, action } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';
import { toJS } from 'mobx';

export default class UserProfileStore {
    @observable loading = false;

    @observable listFollower = [];
    @observable totalFollower = 0;
    @observable pageFollower;

    @observable listFollowing = [];
    @observable totalFollowing = 0;
    @observable pageFollowing;

    @observable reportType = 0;
    @observable sportType = 0;
    @observable textDuration = '';

    @observable eventUserStats = null;
    @observable eventUserData = null;
    @observable listSOUser = [];

    @observable listFeedUser;
    @observable totalFeedUser;
    @observable pageFeedUser;

    @action.bound
    doChangeSelectedStatusUserFollower(id) {
        const obj = _.find(this.listFollower, (item) => {
            return item.uid == id;
        });
        if (obj) {
            if (!obj.hasOwnProperty('selected')) obj.selected = true;
            else obj.selected = !obj.selected;
        }
    }

    @action.bound
    doChangeSelectedStatusUserFollowerMulti(list) {
        const obj = _.filter(this.listFollower, (item1) => {
            return _.some(list, (item2) => {
                return item2.uid == item1.uid;
            });
        });
        _.forEach(obj, (item) => {
            item.selected = true;
        });
    }

    @action.bound
    doSetTextDuration(text) {
        this.textDuration = text;
    }

    @action.bound
    doSetReportType(val) {
        this.reportType = val;
    }

    @action.bound
    doSetSportType(val) {
        this.sportType = val;
    }

    //get list user follower
    @action.bound
    async doGetListUserFollower(params, isLoadMore) {
        let result = null;
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageFollower += 1;
            params.from = params.size * this.pageFollower;
        } else {
            this.pageFollower = 0;
            params.from = 0;
        }

        await requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    if (!isLoadMore) this.listFollower = [];
                    this.totalFollower = resp.data.total;
                    if (isLoadMore) this.listFollower = this.listFollower.concat(resp.data.list);
                    else this.listFollower = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get list user following
    @action.bound
    async doGetListUserFollowing(params, isLoadMore) {
        let result = null;
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageFollowing += 1;
            params.from = params.size * this.pageFollowing;
        } else {
            this.pageFollowing = 0;
            params.from = 0;
        }

        await requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    if (!isLoadMore) this.listFollowing = [];
                    this.totalFollowing = resp.data.total;
                    if (isLoadMore) this.listFollowing = this.listFollowing.concat(resp.data.list);
                    else this.listFollowing = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = false;
            });
        return result;
    }

    //do follow, unfollow
    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    //update local
                    const obj = _.find(this.listFollower, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj) obj.fists = resp.data.fists;

                    const obj1 = _.find(this.listFollowing, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetEventStats(params) {
        this.loading = true; //show progress
        let result = null;
        this.listLeaderboard = [];
        await requestAPIV2(API.EVENT.STATISTICS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.eventUserStats = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doGetEventUserData(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.EVENT.GET_DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    _.each(resp.data.fund, (val, key) => {
                        this.listSOUser.push(val);
                    });
                    this.eventUserData = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get list feed of user, friend or team
    @action.bound
    doGetListFeedUser(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageFeedUser += 1;
            params.from = params.size * this.pageFeedUser;
        } else {
            this.pageFeedUser = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    this.totalFeedUser = resp.data.total;
                    if (isLoadMore) this.listFeedUser = this.listFeedUser.concat(resp.data.list);
                    else this.listFeedUser = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                this.loading = false;
            });
    }
}
