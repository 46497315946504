import { observable, action } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody, requestAPIBodyNotAuth } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';

export default class AuthStore {
    @observable user = {
        gender: 1
    };
    @observable loading = false;
    @observable captcha = null;
    @observable certLink = null;

    @action.bound
    async doLogin(params, extra) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBodyNotAuth(API.AUTHEN.LOGIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code == API_STATUS.NEED_INFO) {
                    params.uid = resp.userId;
                    params.name = extra.name;
                    params.emailText = extra.email;
                    params.openId = extra.openId;
                    params.gender = 0;
                    localStorage.setItem(KEY.CURRENT_USER, JSON.stringify(resp));
                    this.user = params;
                    this.loading = false;
                    result = API_STATUS.NEED_INFO;
                } else if (resp.code == API_STATUS.FETCH_SUCCESS) {
                    localStorage.setItem(KEY.CURRENT_USER, JSON.stringify(resp));
                    this.user = resp;
                    result = resp;
                } else {
                    this.loading = false;
                    if(resp.code) result = resp.code;
                    else result = null;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doRegister(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.AUTHEN.REGISTER, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_SUCCESS || resp.code == API_STATUS.NEED_INFO) {
                    this.loading = false;
                    localStorage.setItem(KEY.CURRENT_USER, JSON.stringify(resp));
                    this.user.uid = resp.userId;
                    result = resp.code;
                } else {
                    this.loading = false;
                    result = resp.code;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doVerify(params, token) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.AUTHEN.VERIFY, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetCaptcha(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(API.AUTHEN.CAPTCHA, params, HTTP_METHOD.GET)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.captcha = resp;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doForgotPass(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.AUTHEN.FORGOT_PASSWORD, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetUserCert(params) {
        let result = null;
        this.loading = true; //show progress
        this.certLink = null;
        
        await requestAPIBody(API.AUTHEN.CERT, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    this.certLink = resp.certLink;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doLogout() {
        localStorage.removeItem(KEY.CURRENT_USER);
        this.user = {
            email: '',
            gender: 0
        };
    }
}
