import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GLOBAL_CONFIG } from '../../../helpers/ConstDefined';
var decode = require('decode-html');

const RulesShort = inject(
    'eventStore',
    'globalStore'
)(
    observer((props) => {
        const { currentEvent } = props.eventStore;
        const { language } = props.globalStore;

        const { t } = useTranslation();

        const content = language == 'en' ? currentEvent.wdescen : currentEvent.wdesc;

        return (
            <div class="info--about-event">
                <h2 class="info--title">{t('global.info_event')}</h2>
                <div class="rules-box">
                    <h3 class="rules-title">{t('global.rule_event1')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: content && decode(content) }}></div>
                    <div class="btn-row">
                        <Link to={`/event/rule/details?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2022_ID}`}>
                            <button class="btn-40 blue">{t('global.view_detail_rule')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    })
);

export default RulesShort;
