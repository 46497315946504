import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const SponsorCoItem = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { data } = props;
        const { language } = props.globalStore;
        useEffect(() => {}, []);

        if (data.active == true)
            return (
                <div className="sponsor">
                    <img alt="" data-src={data.logo} className=" lazyload" />
                    <div className="sponsor--description">
                        <p className="sponsor--description-title color-blue bold">{data.name}</p>
                        <p className="sponsor--description-name">
                            {data[`desc_${language}`]}
                        </p>
                    </div>
                </div>
            );
        else return null;
    })
);

export default SponsorCoItem;
