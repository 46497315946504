import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const PrivacyPolicy = inject('globalStore')(
    observer((props) => {
        const { language, doSetTabMainTop } = props.globalStore;
        useEffect(() => {
            doSetTabMainTop(null);
            window.scrollTo(0, 0);
        }, []);

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        function PolicyUprace() {
            if (language == 'en') return <PolicyUpraceEN />;
            else return <PolicyUpraceVI />;
        }

        function PolicyUpraceEN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>CHÍNH SÁCH VỀ QUYỀN RIÊNG TƯ CỦA NGƯỜI SỬ DỤNG UPRACE</p>
                    </div>

                    <ul class="list">
                        <p>Phạm vi, mục đích và nguyên tắc thu thập thông tin</p>
                        <div class="list-desc">
                            <h4 className="title">1. PHẠM VI THU THẬP</h4>
                            <ul className="dot-list">
                                <li>
                                    - Việc cung cấp thông tin cá nhân của thành viên được thực hiện chủ yếu trực tiếp
                                    trên Ứng dụng UPRACE trong quá trình thành viên đăng ký tài khoản và tương tác với
                                    UPRACE (Ví dụ, UPRACE sử dụng "cookies" giống như nhiều website khác để ghi nhận một
                                    số loại thông tin khi trình duyệt web của thành viên truy cập vào UPRACE hoặc các
                                    quảng cáo và các nội dung khác được hiển thị trên UPRACE, hoặc về UPRACE trên các
                                    website khác..) Các thông tin thu thập chủ yếu bao gồm: Họ tên, ngày tháng năm sinh,
                                    địa chỉ, số điện thoại, email, thông tin đăng nhập tài khoản (tên đăng nhập, địa chỉ
                                    đăng nhập,...).
                                </li>
                                <li>
                                    - UPRACE lưu trữ những thông tin như tên, địa chỉ email và mật khẩu để bạn có thể
                                    đăng nhập. Đồng thời, những thông tin này giúp chúng tôi xác minh tài khoản của bạn
                                    nếu bạn gọi điện đến bộ phận hỗ trợ khách hàng.
                                </li>
                                <li>
                                    - Ngoài ra, khi tải Ứng dụng UPRACE, Ứng dụng sẽ yêu cầu người dùng cho phép truy
                                    cập thêm những thông tin trên thiết bị di động để cung cấp 1 số tính năng nâng cao.
                                    Các thông tin dự kiến bao gồm nhưng không giới hạn các nội dung sau: vị trí, quãng
                                    đường di chuyển ...; Sau khi nhận được thông báo, Khách hàng được quyền lựa chọn
                                    việc cho phép hay không cho phép thu thập thông qua cơ chế của Ứng dụng.
                                </li>
                            </ul>

                            <h4 className="title">2. MỤC ĐÍCH THU THẬP</h4>
                            <p>UPRACE thu thập thông tin cá nhân nhằm phục vụ cho các mục đích sau:</p>
                            <ul className="dot-list">
                                <li>
                                    <b>Duy Trì Tài Khoản:</b> để tạo và duy trì tài khoản của thành viên, bao gồm cả các
                                    chương trình thành viên thân thiết hoặc các chương trình thưởng đi kèm với tài khoản
                                    của thành viên;
                                </li>
                                <li>
                                    <b>Dịch Vụ Chăm Sóc Thành viên:</b> để nhận và phản hồi cho các yêu cầu, khiếu nại
                                    và phản hồi của thành viên;
                                </li>
                                <li>
                                    <b>Cá Nhân Hóa:</b> UPRACE có thể tổ hợp dữ liệu được thu thập để có một cái nhìn
                                    hoàn chỉnh hơn về từng thành viên và từ đó cho phép chúng tôi phục vụ tốt hơn với sự
                                    cá nhân hóa mạnh hơn ở các khía cạnh, bao gồm nhưng không giới hạn: (i) để cải thiện
                                    và cá nhân hóa trải nghiệm của thành viên trên Ứng dụng UPRACE (ii) để cải thiện các
                                    tiện ích, dịch vụ, điều chỉnh chúng phù hợp với các nhu cầu được cá thể hóa và đi
                                    đến những ý tưởng dịch vụ mới (iii) để phục vụ thành viên với những giới thiệu,
                                    quảng cáo được điều chỉnh phù hợp với sự quan tâm của thành viên.
                                </li>
                                <li>
                                    <b>An Ninh:</b> cho các mục đích ngăn ngừa các hoạt động phá hủy tài khoản người
                                    dùng của thành viên hoặc các hoạt động giả mạo thành viên.
                                </li>
                                <li>
                                    <b>Theo yêu cầu của pháp luật:</b> tùy quy định của pháp luật vào từng thời điểm,
                                    UPRACE có thể thu thập, lưu trữ và cung cấp theo yêu cầu của cơ quan nhà nước có
                                    thẩm quyền.
                                </li>
                            </ul>

                            <h4 className="title">3. NGUYÊN TẮC THU THẬP VÀ QUẢN LÝ THÔNG TIN</h4>
                            <ul className="dot-list">
                                <li>
                                    - Thông tin cá nhân của thành viên trên Ứng dụng UPRACE được UPRACE cam kết bảo mật
                                    tuyệt đối theo chính sách bảo vệ thông tin cá nhân của UPRACE, phù hợp với quy định
                                    của Luật Bảo về quyền lợi người tiêu dùng. Việc thu thập và sử dụng thông tin của
                                    mỗi Thành viên chỉ được thực hiện khi có sự đồng ý của thành viên đó trừ những
                                    trường hợp pháp luật có quy định khác. Thành viên có quyền cung cấp thông tin cá
                                    nhân cho UPRACE và có thể thay đổi quyết định đó vào bất cứ lúc nào.
                                </li>
                                <li>
                                    - Mọi thông tin cá nhân do thành viên cung cấp sẽ được lưu giữ bởi UPRACE. Nhân viên
                                    và Đối tác của UPRACE trong quá trình thực hiện các mục đích nêu phía trên Điều này
                                    có thể tiếp cận với thông tin của thành viên. Những chủ thể này có trách nhiệm giữ
                                    bí mật và chỉ được phép sử dụng thông tin của thành viên cho mục đích được chỉ định,
                                    không sử dụng cho mục đích của riêng họ (kể cả tiếp thị trực tiếp) trừ khi được
                                    thành viên đồng ý.
                                </li>
                                <li>
                                    - Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ
                                    liệu cá nhân thành viên, UPRACE sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức
                                    năng điều tra xử lý kịp thời và thông báo cho thành viên được biết.
                                </li>
                                <li>
                                    - Trách nhiệm của thành viên trong quá trình cung cấp và quản lý thông tin:
                                    <ul className="dot-list">
                                        <p>
                                            + Đảm bảo tính xác thực, đầy đủ, chính xác, và cập nhật thường xuyên đối với
                                            các thông tin cung cấp cho Ứng dụng UPRACE và chịu trách nhiệm về tính pháp
                                            lý của những thông tin đó. UPRACE không chịu trách nhiệm cũng như không giải
                                            quyết mọi khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét
                                            thấy thông tin cá nhân thành viên đó cung cấp không chính xác.
                                        </p>
                                        <p>
                                            + Bảo mật thông tin và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng
                                            ký, mật khẩu và hộp thư điện tử của mình.
                                        </p>
                                        <p>
                                            + Thông báo kịp thời cho UPRACE về những hành vi sử dụng trái phép, lạm
                                            dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có
                                            biện pháp giải quyết phù hợp.
                                        </p>
                                    </ul>
                                </li>
                            </ul>
                            <h4 className="title">4. PHẠM VI SỬ DỤNG THÔNG TIN</h4>
                            <p>Ứng dụng UPRACE sử dụng thông tin thành viên cung cấp để:</p>
                            <ul className="dot-list">
                                <li>- Xác thực năng lực của thành viên; </li>
                                <li>
                                    - Gửi các thông báo về các hoạt động trao đổi thông tin giữa các Thành viên và Ứng
                                    dụng UPRACE;{' '}
                                </li>
                                <li>
                                    - Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của Thành viên hoặc các hoạt
                                    động giả mạo Thành viên;{' '}
                                </li>
                                <li>
                                    - Tiến hành các hoạt động tra cứu và phân tích người dùng nhằm nâng cao chất lượng
                                    dịch vụ, quản lý và bảo vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu
                                    suất dịch vụ do UPRACE cung ứng và cải thiện sản phẩm, dịch vụ của UPRACE.{' '}
                                </li>
                                <li>- Liên lạc và giải quyết với thành viên trong những trường hợp đặc biệt; </li>
                                <li>
                                    - Không sử dụng thông tin cá nhân của thành viên ngoài mục đích xác nhận và liên hệ
                                    có liên quan đến giao dịch tại Ứng dụng UPRACE;{' '}
                                </li>
                            </ul>

                            <h4 className="title">5. THÔNG TIN ĐỊA ĐIỂM</h4>
                            <p>
                                Chúng tôi thu thập và xử lý thông tin vị trí khi bạn đăng ký và sử dụng dịch vụ. Chúng
                                tôi không theo dõi vị trí thiết bị của bạn khi bạn không sử dụng UPRACE. Nhưng để cung
                                cấp dịch vụ cốt lõi của UPRACE, chúng tôi cần theo dõi vị trí thiết bị của bạn khi sử
                                dụng UPRACE khi được sự cho phép của bạn. Bạn có thể ngừng việc theo dõi thiết bị bất cứ
                                lúc nào bằng cách cài đặt trên thiết bị của mình.
                            </p>

                            <h4 className="title">6. THỜI GIAN LƯU TRỮ THÔNG TIN</h4>
                            <p>
                                Thông tin cá nhân của thành viên sẽ được lưu trữ trên Ứng dụng cho đến khi thành viên có
                                yêu cầu hủy bỏ hoặc thành viên tự đăng nhập và thực hiện hủy bỏ. Trong mọi trường hợp,
                                thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của UPRACE theo thời hạn được
                                quy định tại các Pháp luật có liên quan.
                            </p>

                            <h4 className="title">
                                7. NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ ĐƯỢC TIẾP CẬN VỚI NHỮNG THÔNG TIN ĐÓ
                            </h4>
                            <p>
                                Thông tin về thành viên là một phần dữ liệu quan trọng để UPRACE có thể khai thác và cải
                                thiện trải nghiệm của thành viên. Và hoạt động kinh doanh của UPRACE không bao gồm việc
                                bán các thông tin đấy cho bên thứ ba. Chúng tôi chỉ cung cấp thông tin thành viên cho
                                các bên được liệt kê dưới đây, hoặc cho bên thứ ba nhằm đảm bảo quyền lợi của thành viên
                                theo những cam kết bảo mật của UPRACE.
                            </p>
                            <ul className="dot-list">
                                <li>
                                    - Nhân viên của UPRACE: Trong quá trình vận hành, Nhân viên UPRACE có thể tiếp cận
                                    với thông tin của Thành viên. Những chủ thể này có trách nhiệm giữ bí mật và chỉ
                                    được pháp sử dụng thông tin của thành viên cho mục đích được chỉ định.
                                </li>
                                <li>
                                    - Đối Tác: UPRACE liên kết chặt chẽ với rất nhiều đối tác. Khi một bên thứ ba liên
                                    quan với các giao dịch của thành viên, UPRACE sẽ chia sẻ những thông tin thành viên
                                    liên quan đến giao dịch đó cho bên thứ ba tương ứng.
                                </li>
                                <li>
                                    - Nhà cung cấp dịch vụ độc lập: UPRACE sử dụng những tổ chức và cá nhân khác để thay
                                    mặt UPRACE thực hiện một số chức năng, như cung cấp dịch vụ thanh toán qua thẻ... Họ
                                    có thể tiếp cận những thông tin cá nhân cần thiết để hoàn thành công việc của họ,
                                    nhưng không được quyền sử dụng các thông tin cá nhân đó để phục vụ cho mục đích
                                    khác.
                                </li>
                                <li>
                                    - Bảo vệ UPRACE và các đối tác khác: UPRACE sẽ cung cấp các thông tin tài khoản và
                                    các thông tin cá nhân trong trường hợp UPRACE tin rằng sự cung cấp thông tin này
                                    không vi phạm pháp luật; bị bắt buộc hoặc phù hợp với các Thỏa thuận sử dụng và các
                                    thỏa thuận khác; hoặc bảo vệ quyền, tài sản, và sự an toàn của UPRACE, các thành
                                    viên khác, và các đối tượng khác. Sự cung cấp này có thể bao gồm việc trao đổi thông
                                    tin với các công ty, tổ chức nhằm chống lại những gian lận và giảm thiểu rủi ro. Tuy
                                    nhiên, sự cung cấp này không bao gồm việc bán, cho thuê, chia sẻ, hoặc công bố các
                                    thông tin cá nhân vì mục đích thương mại.
                                </li>
                            </ul>
                            <p>
                                Một số trường hợp khác với những trường hợp được đề cập ở trên, thành viên sẽ nhận được
                                thông báo về việc thông tin của bạn có thể được chia sẻ cho bên thứ ba, và bạn có quyền
                                lựa chọn cho phép sự chia sẻ đó hay không.
                            </p>

                            <h4 className="title">
                                8. CÁCH CHÚNG TÔI SỬ DỤNG THÔNG TIN THU THẬP ĐƯỢC VÀ CĂN CỨ PHÁP LÝ ĐỂ XỬ LÝ THÔNG TIN
                            </h4>
                            <p>
                                Mục đích chính của việc sử dụng thông tin chúng tôi thu thập và lưu giữ là để cung cấp
                                các Dịch vụ của chúng tôi mà bạn yêu cầu và để cải thiện trải nghiệm chơi hoạt động của
                                bạn, nhưng ngoài ra còn có các mục đích sử dụng khác. Việc sử dụng mà theo đó chúng tôi
                                thu thập và lưu giữ thông tin của bạn bao gồm:
                            </p>
                            <ul className="dot-list">
                                <li>
                                    - Để cải thiện và tối ưu hóa các Dịch vụ và trải nghiệm người chơi của chúng tôi;
                                </li>
                                <li>
                                    - Để tạo tài khoản hoạt động của bạn và cho phép bạn chơi các hoạt động của chúng
                                    tôi;{' '}
                                </li>
                                <li>- Để cung cấp hỗ trợ kỹ thuật và trả lời các thắc mắc của người chơi; </li>
                                <li>
                                    - Để tuân thủ các nghĩa vụ pháp lý của chúng tôi, giải quyết bất kỳ tranh chấp nào
                                    có thể nảy sinh giữa chúng tôi với bạn hoặc với những người chơi khác.{' '}
                                </li>
                            </ul>

                            <h4 className="title">
                                9. CÁCH TRUY CẬP, CẬP NHẬT VÀ YÊU CẦU XOÁ THÔNG TIN BẠN ĐÃ CUNG CẤP CHO CHÚNG TÔI BẰNG
                                CÁCH NÀO?
                            </h4>
                            <p>
                                <b>(a)</b> Nếu bạn đã đăng ký một tài khoản với chúng tôi, cá nhân bạn có thể truy cập
                                và/hoặc sửa dữ liệu cá nhân của bạn mà chúng tôi đang lưu giữ hoặc kiểm soát thông qua
                                Thiết Lập Tài Khoản trong Hoạt động hoặc trên trang web của hoạt động. Nếu bạn chưa đăng
                                ký tài khoản với chúng tôi hoặc không tìm thấy chỗ để truy cập, cá nhân bạn có thể yêu
                                cầu truy cập và/hoặc sửa dữ liệu cá nhân của bạn mà chúng tôi đang lưu giữ hoặc kiểm
                                soát bằng cách gửi yêu cầu bằng văn bản cho chúng tôi.
                            </p>
                            <p>
                                <b>(b)</b> Trường hợp bạn yêu cầu xoá thông tin cá nhân của bạn mà chúng tôi đang lưu
                                trữ thì chúng tôi có thể cần phải chấm dứt quan hệ hiện tại của bạn và/hoặc hợp đồng của
                                bạn với chúng tôi; có nghĩa là chúng tôi sẽ không thể tiếp tục cung cấp Các Dịch Vụ cho
                                bạn. Theo quy định của pháp luật Việt Nam, chúng tôi buộc phải lưu trữ thông tin của bạn
                                tối thiểu sáu (6) tháng kể từ thời điểm bạn ngừng sử dụng hoạt động hoặc yêu cầu xóa
                                thông tin tài khoản (đối với tài khoản ZingID, thời gian lưu trữ yêu cầu là tối thiểu
                                hai (2) năm kể từ thời điểm bạn ngừng sử dụng dịch vụ); vì vậy, thông tin của bạn sẽ chỉ
                                được xóa (theo yêu cầu của bạn) sau khi kết thúc thời gian mà chúng tôi buộc phải lưu
                                trữ thông tin theo quy định của pháp luật.
                            </p>
                            <p>
                                <b>(c)</b> Chúng tôi sẽ cần có đủ thông tin từ bạn để xác định danh tính của bạn cũng
                                như bản chất yêu cầu của bạn để có thể giải quyết yêu cầu. Do đó, vui lòng gửi yêu cầu
                                bằng văn bản của bạn bằng cách gửi email cho nhân viên Quản lý dữ liệu của chúng tôi tại
                                địa chỉ email vng.game.publishing@gmail.com để được hỗ trợ.
                            </p>

                            <h4 className="title">
                                10. PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU CÁ NHÂN CỦA MÌNH
                            </h4>
                            <ul className="dot-list">
                                <li>
                                    - Thành viên có quyền cập nhật, kiểm tra, điều chỉnh hoặc hủy bỏ thông tin cá nhân
                                    của mình theo các phương thức mà UPRACE hỗ trợ tại thời điểm phát sinh nhu cầu cập
                                    nhật thông tin hoặc hướng dẫn của UPRACE tại thời điểm thực hiện cập nhật.
                                </li>
                                <li>
                                    - Khi có bất kỳ thay đổi thông tin nào, Thành viên có trách nhiệm thông báo cho
                                    UPRACE việc thay đổi trước ngày áp dụng thay đổi.
                                </li>
                            </ul>
                            <h4 className="title">11. ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN CÁ NHÂN</h4>
                            <ul className="dot-list">
                                <li>Công ty Cổ phần dịch vụ mạng Vi Na. </li>
                                <li>Trụ sở chính: Z06, Đường số 13, Phường Tân Thuận Đông, Q7, HCM. </li>
                                <li>Email: support@Uprace.org - Hotline: 1900 54 54 36. </li>
                            </ul>
                            <h4 className="title">12. THÔNG BÁO VÀ SỬA ĐỔI</h4>
                            <p>
                                - Khi thành viên truy cập vào UPRACE, sự truy cập của thành viên và bất kỳ vấn đề phát
                                sinh nào liên quan đến sự bảo mật đều tuân theo các điều khoản trong Chính sách bảo mật
                                này và Thỏa thuận sử dụng, bao gồm giới hạn thiệt hại, cách giải quyết các vấn đề phát
                                sinh, và việc áp dụng luật Việt Nam. Nếu thành có bất kỳ câu hỏi nào liên quan đến sự
                                bảo mật tại UPRACE, vui lòng liên lạc với UPRACE thông qua điện thoại 1900 545 436. Do
                                UPRACE liên tục cải thiện dịch vụ và sản phẩm để phục vụ thành viên tốt hơn, nên Chính
                                sách bảo mật và Thỏa thuận sử dụng sẽ thường xuyên được thay đổi và cập nhật. UPRACE có
                                thể email thông báo cho thành viên về những thay đổi trong những chính sách. Tuy nhiên,
                                UPRACE cũng khuyến khích thành viên thường xuyên theo dõi trên website của UPRACE để cập
                                nhật với những thay đổi trong chính sách của chúng tôi. Trừ trường hợp có văn bản với
                                nội dung khác, Chính sách bảo mật hiện tại của chúng tôi được áp dụng cho tất cả những
                                thông tin mà chúng tôi có về bạn và tài khoản của bạn.
                            </p>
                            <p>
                                - Ngoài các quy định ở trên, Thành viên có thể vào phần FAQ để tham khảo một số câu hỏi
                                tiêu biểu liên quan đến việc bảo mật thông tin trên UPRACE
                            </p>
                        </div>
                    </ul>
                </section>
            );
        }

        function PolicyUpraceVI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>CHÍNH SÁCH VỀ QUYỀN RIÊNG TƯ CỦA NGƯỜI SỬ DỤNG UPRACE</p>
                    </div>

                    <ul class="list">
                        <p>Phạm vi, mục đích và nguyên tắc thu thập thông tin</p>
                        <div class="list-desc">
                            <h4 className="title">1. PHẠM VI THU THẬP</h4>
                            <ul className="dot-list">
                                <li>
                                    - Việc cung cấp thông tin cá nhân của thành viên được thực hiện chủ yếu trực tiếp
                                    trên Ứng dụng UPRACE trong quá trình thành viên đăng ký tài khoản và tương tác với
                                    UPRACE (Ví dụ, UPRACE sử dụng "cookies" giống như nhiều website khác để ghi nhận một
                                    số loại thông tin khi trình duyệt web của thành viên truy cập vào UPRACE hoặc các
                                    quảng cáo và các nội dung khác được hiển thị trên UPRACE, hoặc về UPRACE trên các
                                    website khác..) Các thông tin thu thập chủ yếu bao gồm: Họ tên, ngày tháng năm sinh,
                                    địa chỉ, số điện thoại, email, thông tin đăng nhập tài khoản (tên đăng nhập, địa chỉ
                                    đăng nhập,...).
                                </li>
                                <li>
                                    - UPRACE lưu trữ những thông tin như tên, địa chỉ email và mật khẩu để bạn có thể
                                    đăng nhập. Đồng thời, những thông tin này giúp chúng tôi xác minh tài khoản của bạn
                                    nếu bạn gọi điện đến bộ phận hỗ trợ khách hàng.
                                </li>
                                <li>
                                    - Ngoài ra, khi tải Ứng dụng UPRACE, Ứng dụng sẽ yêu cầu người dùng cho phép truy
                                    cập thêm những thông tin trên thiết bị di động để cung cấp 1 số tính năng nâng cao.
                                    Các thông tin dự kiến bao gồm nhưng không giới hạn các nội dung sau: vị trí, quãng
                                    đường di chuyển ...; Sau khi nhận được thông báo, Khách hàng được quyền lựa chọn
                                    việc cho phép hay không cho phép thu thập thông qua cơ chế của Ứng dụng.
                                </li>
                            </ul>

                            <h4 className="title">2. MỤC ĐÍCH THU THẬP</h4>
                            <p>UPRACE thu thập thông tin cá nhân nhằm phục vụ cho các mục đích sau:</p>
                            <ul className="dot-list">
                                <li>
                                    <b>Duy Trì Tài Khoản:</b> để tạo và duy trì tài khoản của thành viên, bao gồm cả các
                                    chương trình thành viên thân thiết hoặc các chương trình thưởng đi kèm với tài khoản
                                    của thành viên;
                                </li>
                                <li>
                                    <b>Dịch Vụ Chăm Sóc Thành viên:</b> để nhận và phản hồi cho các yêu cầu, khiếu nại
                                    và phản hồi của thành viên;
                                </li>
                                <li>
                                    <b>Cá Nhân Hóa:</b> UPRACE có thể tổ hợp dữ liệu được thu thập để có một cái nhìn
                                    hoàn chỉnh hơn về từng thành viên và từ đó cho phép chúng tôi phục vụ tốt hơn với sự
                                    cá nhân hóa mạnh hơn ở các khía cạnh, bao gồm nhưng không giới hạn: (i) để cải thiện
                                    và cá nhân hóa trải nghiệm của thành viên trên Ứng dụng UPRACE (ii) để cải thiện các
                                    tiện ích, dịch vụ, điều chỉnh chúng phù hợp với các nhu cầu được cá thể hóa và đi
                                    đến những ý tưởng dịch vụ mới (iii) để phục vụ thành viên với những giới thiệu,
                                    quảng cáo được điều chỉnh phù hợp với sự quan tâm của thành viên.
                                </li>
                                <li>
                                    <b>An Ninh:</b> cho các mục đích ngăn ngừa các hoạt động phá hủy tài khoản người
                                    dùng của thành viên hoặc các hoạt động giả mạo thành viên.
                                </li>
                                <li>
                                    <b>Theo yêu cầu của pháp luật:</b> tùy quy định của pháp luật vào từng thời điểm,
                                    UPRACE có thể thu thập, lưu trữ và cung cấp theo yêu cầu của cơ quan nhà nước có
                                    thẩm quyền.
                                </li>
                            </ul>

                            <h4 className="title">3. NGUYÊN TẮC THU THẬP VÀ QUẢN LÝ THÔNG TIN</h4>
                            <ul className="dot-list">
                                <li>
                                    - Thông tin cá nhân của thành viên trên Ứng dụng UPRACE được UPRACE cam kết bảo mật
                                    tuyệt đối theo chính sách bảo vệ thông tin cá nhân của UPRACE, phù hợp với quy định
                                    của Luật Bảo về quyền lợi người tiêu dùng. Việc thu thập và sử dụng thông tin của
                                    mỗi Thành viên chỉ được thực hiện khi có sự đồng ý của thành viên đó trừ những
                                    trường hợp pháp luật có quy định khác. Thành viên có quyền cung cấp thông tin cá
                                    nhân cho UPRACE và có thể thay đổi quyết định đó vào bất cứ lúc nào.
                                </li>
                                <li>
                                    - Mọi thông tin cá nhân do thành viên cung cấp sẽ được lưu giữ bởi UPRACE. Nhân viên
                                    và Đối tác của UPRACE trong quá trình thực hiện các mục đích nêu phía trên Điều này
                                    có thể tiếp cận với thông tin của thành viên. Những chủ thể này có trách nhiệm giữ
                                    bí mật và chỉ được phép sử dụng thông tin của thành viên cho mục đích được chỉ định,
                                    không sử dụng cho mục đích của riêng họ (kể cả tiếp thị trực tiếp) trừ khi được
                                    thành viên đồng ý.
                                </li>
                                <li>
                                    - Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ
                                    liệu cá nhân thành viên, UPRACE sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức
                                    năng điều tra xử lý kịp thời và thông báo cho thành viên được biết.
                                </li>
                                <li>
                                    - Trách nhiệm của thành viên trong quá trình cung cấp và quản lý thông tin:
                                    <ul className="dot-list">
                                        <p>
                                            + Đảm bảo tính xác thực, đầy đủ, chính xác, và cập nhật thường xuyên đối với
                                            các thông tin cung cấp cho Ứng dụng UPRACE và chịu trách nhiệm về tính pháp
                                            lý của những thông tin đó. UPRACE không chịu trách nhiệm cũng như không giải
                                            quyết mọi khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét
                                            thấy thông tin cá nhân thành viên đó cung cấp không chính xác.
                                        </p>
                                        <p>
                                            + Bảo mật thông tin và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng
                                            ký, mật khẩu và hộp thư điện tử của mình.
                                        </p>
                                        <p>
                                            + Thông báo kịp thời cho UPRACE về những hành vi sử dụng trái phép, lạm
                                            dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có
                                            biện pháp giải quyết phù hợp.
                                        </p>
                                    </ul>
                                </li>
                            </ul>
                            <h4 className="title">4. PHẠM VI SỬ DỤNG THÔNG TIN</h4>
                            <p>Ứng dụng UPRACE sử dụng thông tin thành viên cung cấp để:</p>
                            <ul className="dot-list">
                                <li>- Xác thực năng lực của thành viên; </li>
                                <li>
                                    - Gửi các thông báo về các hoạt động trao đổi thông tin giữa các Thành viên và Ứng
                                    dụng UPRACE;{' '}
                                </li>
                                <li>
                                    - Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của Thành viên hoặc các hoạt
                                    động giả mạo Thành viên;{' '}
                                </li>
                                <li>
                                    - Tiến hành các hoạt động tra cứu và phân tích người dùng nhằm nâng cao chất lượng
                                    dịch vụ, quản lý và bảo vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu
                                    suất dịch vụ do UPRACE cung ứng và cải thiện sản phẩm, dịch vụ của UPRACE.{' '}
                                </li>
                                <li>- Liên lạc và giải quyết với thành viên trong những trường hợp đặc biệt; </li>
                                <li>
                                    - Không sử dụng thông tin cá nhân của thành viên ngoài mục đích xác nhận và liên hệ
                                    có liên quan đến giao dịch tại Ứng dụng UPRACE;{' '}
                                </li>
                            </ul>

                            <h4 className="title">5. THÔNG TIN ĐỊA ĐIỂM</h4>
                            <p>
                                Chúng tôi thu thập và xử lý thông tin vị trí khi bạn đăng ký và sử dụng dịch vụ. Chúng
                                tôi không theo dõi vị trí thiết bị của bạn khi bạn không sử dụng UPRACE. Nhưng để cung
                                cấp dịch vụ cốt lõi của UPRACE, chúng tôi cần theo dõi vị trí thiết bị của bạn khi sử
                                dụng UPRACE khi được sự cho phép của bạn. Bạn có thể ngừng việc theo dõi thiết bị bất cứ
                                lúc nào bằng cách cài đặt trên thiết bị của mình.
                            </p>

                            <h4 className="title">6. THỜI GIAN LƯU TRỮ THÔNG TIN</h4>
                            <p>
                                Thông tin cá nhân của thành viên sẽ được lưu trữ trên Ứng dụng cho đến khi thành viên có
                                yêu cầu hủy bỏ hoặc thành viên tự đăng nhập và thực hiện hủy bỏ. Trong mọi trường hợp,
                                thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của UPRACE theo thời hạn được
                                quy định tại các Pháp luật có liên quan.
                            </p>

                            <h4 className="title">
                                7. NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ ĐƯỢC TIẾP CẬN VỚI NHỮNG THÔNG TIN ĐÓ
                            </h4>
                            <p>
                                Thông tin về thành viên là một phần dữ liệu quan trọng để UPRACE có thể khai thác và cải
                                thiện trải nghiệm của thành viên. Và hoạt động kinh doanh của UPRACE không bao gồm việc
                                bán các thông tin đấy cho bên thứ ba. Chúng tôi chỉ cung cấp thông tin thành viên cho
                                các bên được liệt kê dưới đây, hoặc cho bên thứ ba nhằm đảm bảo quyền lợi của thành viên
                                theo những cam kết bảo mật của UPRACE.
                            </p>
                            <ul className="dot-list">
                                <li>
                                    - Nhân viên của UPRACE: Trong quá trình vận hành, Nhân viên UPRACE có thể tiếp cận
                                    với thông tin của Thành viên. Những chủ thể này có trách nhiệm giữ bí mật và chỉ
                                    được pháp sử dụng thông tin của thành viên cho mục đích được chỉ định.
                                </li>
                                <li>
                                    - Đối Tác: UPRACE liên kết chặt chẽ với rất nhiều đối tác. Khi một bên thứ ba liên
                                    quan với các giao dịch của thành viên, UPRACE sẽ chia sẻ những thông tin thành viên
                                    liên quan đến giao dịch đó cho bên thứ ba tương ứng.
                                </li>
                                <li>
                                    - Nhà cung cấp dịch vụ độc lập: UPRACE sử dụng những tổ chức và cá nhân khác để thay
                                    mặt UPRACE thực hiện một số chức năng, như cung cấp dịch vụ thanh toán qua thẻ... Họ
                                    có thể tiếp cận những thông tin cá nhân cần thiết để hoàn thành công việc của họ,
                                    nhưng không được quyền sử dụng các thông tin cá nhân đó để phục vụ cho mục đích
                                    khác.
                                </li>
                                <li>
                                    - Bảo vệ UPRACE và các đối tác khác: UPRACE sẽ cung cấp các thông tin tài khoản và
                                    các thông tin cá nhân trong trường hợp UPRACE tin rằng sự cung cấp thông tin này
                                    không vi phạm pháp luật; bị bắt buộc hoặc phù hợp với các Thỏa thuận sử dụng và các
                                    thỏa thuận khác; hoặc bảo vệ quyền, tài sản, và sự an toàn của UPRACE, các thành
                                    viên khác, và các đối tượng khác. Sự cung cấp này có thể bao gồm việc trao đổi thông
                                    tin với các công ty, tổ chức nhằm chống lại những gian lận và giảm thiểu rủi ro. Tuy
                                    nhiên, sự cung cấp này không bao gồm việc bán, cho thuê, chia sẻ, hoặc công bố các
                                    thông tin cá nhân vì mục đích thương mại.
                                </li>
                            </ul>
                            <p>
                                Một số trường hợp khác với những trường hợp được đề cập ở trên, thành viên sẽ nhận được
                                thông báo về việc thông tin của bạn có thể được chia sẻ cho bên thứ ba, và bạn có quyền
                                lựa chọn cho phép sự chia sẻ đó hay không.
                            </p>

                            <h4 className="title">
                                8. CÁCH CHÚNG TÔI SỬ DỤNG THÔNG TIN THU THẬP ĐƯỢC VÀ CĂN CỨ PHÁP LÝ ĐỂ XỬ LÝ THÔNG TIN
                            </h4>
                            <p>
                                Mục đích chính của việc sử dụng thông tin chúng tôi thu thập và lưu giữ là để cung cấp
                                các Dịch vụ của chúng tôi mà bạn yêu cầu và để cải thiện trải nghiệm chơi hoạt động của
                                bạn, nhưng ngoài ra còn có các mục đích sử dụng khác. Việc sử dụng mà theo đó chúng tôi
                                thu thập và lưu giữ thông tin của bạn bao gồm:
                            </p>
                            <ul className="dot-list">
                                <li>
                                    - Để cải thiện và tối ưu hóa các Dịch vụ và trải nghiệm người chơi của chúng tôi;
                                </li>
                                <li>
                                    - Để tạo tài khoản hoạt động của bạn và cho phép bạn chơi các hoạt động của chúng
                                    tôi;{' '}
                                </li>
                                <li>- Để cung cấp hỗ trợ kỹ thuật và trả lời các thắc mắc của người chơi; </li>
                                <li>
                                    - Để tuân thủ các nghĩa vụ pháp lý của chúng tôi, giải quyết bất kỳ tranh chấp nào
                                    có thể nảy sinh giữa chúng tôi với bạn hoặc với những người chơi khác.{' '}
                                </li>
                            </ul>

                            <h4 className="title">
                                9. CÁCH TRUY CẬP, CẬP NHẬT VÀ YÊU CẦU XOÁ THÔNG TIN BẠN ĐÃ CUNG CẤP CHO CHÚNG TÔI BẰNG
                                CÁCH NÀO?
                            </h4>
                            <p>
                                <b>(a)</b> Nếu bạn đã đăng ký một tài khoản với chúng tôi, cá nhân bạn có thể truy cập
                                và/hoặc sửa dữ liệu cá nhân của bạn mà chúng tôi đang lưu giữ hoặc kiểm soát thông qua
                                Thiết Lập Tài Khoản trong Hoạt động hoặc trên trang web của hoạt động. Nếu bạn chưa đăng
                                ký tài khoản với chúng tôi hoặc không tìm thấy chỗ để truy cập, cá nhân bạn có thể yêu
                                cầu truy cập và/hoặc sửa dữ liệu cá nhân của bạn mà chúng tôi đang lưu giữ hoặc kiểm
                                soát bằng cách gửi yêu cầu bằng văn bản cho chúng tôi.
                            </p>
                            <p>
                                <b>(b)</b> Trường hợp bạn yêu cầu xoá thông tin cá nhân của bạn mà chúng tôi đang lưu
                                trữ thì chúng tôi có thể cần phải chấm dứt quan hệ hiện tại của bạn và/hoặc hợp đồng của
                                bạn với chúng tôi; có nghĩa là chúng tôi sẽ không thể tiếp tục cung cấp Các Dịch Vụ cho
                                bạn. Theo quy định của pháp luật Việt Nam, chúng tôi buộc phải lưu trữ thông tin của bạn
                                tối thiểu sáu (6) tháng kể từ thời điểm bạn ngừng sử dụng hoạt động hoặc yêu cầu xóa
                                thông tin tài khoản (đối với tài khoản ZingID, thời gian lưu trữ yêu cầu là tối thiểu
                                hai (2) năm kể từ thời điểm bạn ngừng sử dụng dịch vụ); vì vậy, thông tin của bạn sẽ chỉ
                                được xóa (theo yêu cầu của bạn) sau khi kết thúc thời gian mà chúng tôi buộc phải lưu
                                trữ thông tin theo quy định của pháp luật.
                            </p>
                            <p>
                                <b>(c)</b> Chúng tôi sẽ cần có đủ thông tin từ bạn để xác định danh tính của bạn cũng
                                như bản chất yêu cầu của bạn để có thể giải quyết yêu cầu. Do đó, vui lòng gửi yêu cầu
                                bằng văn bản của bạn bằng cách gửi email cho nhân viên Quản lý dữ liệu của chúng tôi tại
                                địa chỉ email vng.game.publishing@gmail.com để được hỗ trợ.
                            </p>

                            <h4 className="title">
                                10. PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU CÁ NHÂN CỦA MÌNH
                            </h4>
                            <ul className="dot-list">
                                <li>
                                    - Thành viên có quyền cập nhật, kiểm tra, điều chỉnh hoặc hủy bỏ thông tin cá nhân
                                    của mình theo các phương thức mà UPRACE hỗ trợ tại thời điểm phát sinh nhu cầu cập
                                    nhật thông tin hoặc hướng dẫn của UPRACE tại thời điểm thực hiện cập nhật.
                                </li>
                                <li>
                                    - Khi có bất kỳ thay đổi thông tin nào, Thành viên có trách nhiệm thông báo cho
                                    UPRACE việc thay đổi trước ngày áp dụng thay đổi.
                                </li>
                            </ul>
                            <h4 className="title">11. ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ THÔNG TIN CÁ NHÂN</h4>
                            <ul className="dot-list">
                                <li>Công ty Cổ phần dịch vụ mạng Vi Na. </li>
                                <li>Trụ sở chính: Z06, Đường số 13, Phường Tân Thuận Đông, Q7, HCM. </li>
                                <li>Email: support@Uprace.org - Hotline: 1900 54 54 36. </li>
                            </ul>
                            <h4 className="title">12. THÔNG BÁO VÀ SỬA ĐỔI</h4>
                            <p>
                                - Khi thành viên truy cập vào UPRACE, sự truy cập của thành viên và bất kỳ vấn đề phát
                                sinh nào liên quan đến sự bảo mật đều tuân theo các điều khoản trong Chính sách bảo mật
                                này và Thỏa thuận sử dụng, bao gồm giới hạn thiệt hại, cách giải quyết các vấn đề phát
                                sinh, và việc áp dụng luật Việt Nam. Nếu thành có bất kỳ câu hỏi nào liên quan đến sự
                                bảo mật tại UPRACE, vui lòng liên lạc với UPRACE thông qua điện thoại 1900 545 436. Do
                                UPRACE liên tục cải thiện dịch vụ và sản phẩm để phục vụ thành viên tốt hơn, nên Chính
                                sách bảo mật và Thỏa thuận sử dụng sẽ thường xuyên được thay đổi và cập nhật. UPRACE có
                                thể email thông báo cho thành viên về những thay đổi trong những chính sách. Tuy nhiên,
                                UPRACE cũng khuyến khích thành viên thường xuyên theo dõi trên website của UPRACE để cập
                                nhật với những thay đổi trong chính sách của chúng tôi. Trừ trường hợp có văn bản với
                                nội dung khác, Chính sách bảo mật hiện tại của chúng tôi được áp dụng cho tất cả những
                                thông tin mà chúng tôi có về bạn và tài khoản của bạn.
                            </p>
                            <p>
                                - Ngoài các quy định ở trên, Thành viên có thể vào phần FAQ để tham khảo một số câu hỏi
                                tiêu biểu liên quan đến việc bảo mật thông tin trên UPRACE
                            </p>
                        </div>
                    </ul>
                </section>
            );
        }

        return <PolicyUprace />;
    })
);

export default PrivacyPolicy;
