import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DialogConfirmConnect = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { title, message, openDialogConfirmConnect } = props;
        const { t } = useTranslation();
        return (
            <Dialog open={openDialogConfirmConnect} onClose={props.onClose}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={props.onClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <p className="name">{t(title)}</p>

                                    <div className="txt">
                                        <p>{t(message)}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onAgree}>
                                            {t('global.ok')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogConfirmConnect;
