import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getImageResource, formatNumber } from '../../../../../utils';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

const SocialOrganizationEvent = inject('eventStore','globalStore')(
    observer((props) => {
        const { data } = props;
        const { listSO, doSetListSO } = props.eventStore;
        const { language } = props.globalStore;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        function RenderItem(props) {
            const { data, donate } = props;
            const content = language == 'vi' ? data.desc : data.descen;

            return (
                <li data-tip data-for={`tt${data.fuid}`}>
                    <p class="logo">
                        <img src={data.ava} alt="" />
                    </p>
                    <div class="details">
                        <p class="name">{data.name}</p>
                        <p class="money">
                            <span class="num">{formatNumber(donate)}</span> <span class="unit">vnđ</span>
                        </p>
                    </div>
                    <ReactTooltip
                        id={`tt${data.fuid}`}
                        backgroundColor="#fff"
                        border={4}
                        place="top"
                        borderColor="#E0E0E0"
                        className="tooltip-so">
                        <img
                            src={data.ava}
                            style={{ display: 'block', width: 120, marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <div style={{ width: 600, padding: 10 }}>
                            <h4 style={{textAlign: 'center'}}>{data.name}</h4>
                            <p style={{ lineHeight: 2, fontSize: 16, color: '#000' }}>{content}</p>
                            <a href={data.wurl} target='_blank' style={{fontSize: 16}}>{data.wurl}</a>
                            
                        </div>
                    </ReactTooltip>
                </li>
            );
        }

        return (
            <div class="info--tcxh">
                <h2 class="info--title">{t('global.list_so_event_title')}</h2>
                <ul class="tcxh-list">
                    {listSO &&
                        listSO.map((item) => {
                            return <RenderItem data={item} key={item.fuid} donate={item.donate} />;
                        })}
                </ul>
            </div>
        );
    })
);

export default SocialOrganizationEvent;
