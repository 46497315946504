import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';

const FAQ = inject('globalStore')(
    observer((props) => {
        const { language, doSetTabMainTop } = props.globalStore;
        useEffect(() => {
            doSetTabMainTop(4);
            window.scrollTo(0, 0);
        }, []);

        useEffect(() => {
            window.location.href = 'https://faq.uprace.org/';
            initJQuery();
        });

        return <Redirect to={'https://faq.uprace.org/'} />

        function initJQuery() {
            $(document).ready(function() {
                $('.collapsible').on('click', function() {
                    $('.collapsible')
                        .not(this)
                        .removeClass('active');
                    $('.collapsible')
                        .not(this)
                        .next()
                        .css({ display: 'none', 'max-height': null });

                    if (!$(this).hasClass('active')) {
                        $(this).addClass('active');
                        let content = this.nextElementSibling;
                        content.style.display = 'block';
                        content.style.maxHeight = content.scrollHeight + 'px';
                        content.style.opacity = '1';
                    }
                });

                $('.collapsible1').on('click', function() {
                    $('.collapsible1')
                        .not(this)
                        .removeClass('active');
                    $('.collapsible1')
                        .not(this)
                        .next()
                        .css({ display: 'none', 'max-height': null });

                    if (!$(this).hasClass('active')) {
                        $(this).addClass('active');
                        let content = this.nextElementSibling;
                        content.style.display = 'block';
                        content.style.maxHeight = content.scrollHeight + 'px';
                        content.style.opacity = '1';
                    }
                });
            });
        }

        function RenderFAQVI() {
            return (
                <section class="uprace-faqs">
                    <h2 class="page-title">FAQs</h2>

                    <div class="uprace-faqs-page">
                        <h2 class="title">THỂ LỆ & THÔNG TIN SỰ KIỆN</h2>
                        <div class="row-side">
                            <div class="l-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/1_info_4x.png"
                                            alt=""
                                        />
                                        THÔNG TIN SỰ KIỆN
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible active">
                                            UpRace 2021 là sự kiện gì?
                                        </button>
                                        <div class="panel" style={{ maxHeight: 500, display: 'block', opacity: 1 }}>
                                            <p>
                                                UpRace 2021 là một sự kiện chạy bộ cộng đồng MIỄN PHÍ dành cho tất cả
                                                mọi người trên nền tảng web/mobile UpRace. Người tham gia được tự do
                                                chạy vào thời gian và địa điểm tuỳ ý và chủ động ghi lại kết quả chạy
                                                trên các thiết bị di động (điện thoại di động, đồng hồ thể thao…) với
                                                mục đích tích luỹ được quãng đường chạy càng nhiều càng tốt.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Thời gian thi đấu diễn ra bao lâu?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Thời gian thi đấu bắt đầu từ 00 giờ ngày 31/10/2021 đến 23 giờ 59 phút
                                                ngày 21/11/2021
                                            </p>
                                            <p> Giờ Việt Nam (GMT+7) được sử dụng làm múi giờ chuẩn cho sự kiện.</p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Nếu tôi đăng ký sau thời gian giải chạy UpRace bắt đầu thì thế nào? Tôi có
                                            bất lợi gì so với các vận động viên khác không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Nếu VĐV bắt đầu thi đấu sau ngày 31/10/2021 thì thành tích sẽ được ghi
                                                nhận từ ngày bắt đầu thi đấu cho đến hết ngày 21/11/2021, vì vậy số ngày
                                                thi đấu sẽ ít hơn các vận động viên khác.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/2_prize_4x.png"
                                            alt=""
                                        />{' '}
                                        THÔNG TIN GIẢI THƯỞNG
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            Giải thưởng thành tích của Đội được xét theo tiêu chí nào? Giải thưởng là
                                            gì?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                - Mười Đội có tổng thành tích tốt nhất trong mỗi Bảng sẽ nhận giải
                                                thưởng đồng đội.
                                            </p>
                                            <p>
                                                - Giải thưởng đồng đội bao gồm: áo UpRace Winning Team 2021 sẽ trao cho:
                                            </p>
                                            <p>+ Vận động viên đủ điều kiện hoàn thành cuộc thi</p>
                                            <p>+ Và có thành tích nằm trong top của các Đội đoạt giải theo bảng sau</p>

                                            <div className="faq">
                                                <table className="table">
                                                    <tr>
                                                        <th></th>
                                                        <th>Doanh nghiệp</th>
                                                        <th>Câu lạc bộ</th>
                                                        <th>Trường học</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Đội hạng nhất</td>
                                                        <td>Top 300 VĐV</td>
                                                        <td>Top 250 VĐV</td>
                                                        <td>Top 200 VĐV</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Đội hạng nhì</td>
                                                        <td>Top 250 VĐV</td>
                                                        <td>Top 200 VĐV</td>
                                                        <td>Top 150 VĐV</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Đội hạng ba</td>
                                                        <td>Top 200 VĐV</td>
                                                        <td>Top 150 VĐV</td>
                                                        <td>Top 100 VĐV</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Đội hạng 4,5</td>
                                                        <td>Top 150 VĐV</td>
                                                        <td>Top 100 VĐV</td>
                                                        <td>Top 50 VĐV</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Đội hạng 6,7,8,9 và 10</td>
                                                        <td>Top 50 VĐV</td>
                                                        <td>Top 50 VĐV</td>
                                                        <td>Top 50 VĐV</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Tôi hoàn thành cuộc thi có được giải thưởng gì không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Tất cả các VĐV đạt đủ điều kiện hoàn thành cuộc thi là có tối thiểu 12
                                                ngày chạy hợp lệ và tối thiểu 60km sẽ nhận được 01 (một) giấy chứng nhận
                                                hoàn thành cuộc thi (bản điện tử) theo các mốc số km hoàn thành gồm:
                                                60km, 150km, 300km tương ứng với các danh hiệu: Pro Runner, Hero Runner
                                                và Angel Runner.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Đội nào sẽ được thưởng 100 triệu đồng? Và nếu giành giải thưởng này thì số
                                            tiền thưởng sẽ được sử dụng thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Đội có thành tích cao nhất mỗi bảng (Top 1) sẽ nhận được giải thưởng 100
                                                triệu VNĐ, và tiền thưởng này sẽ dùng để quyên góp cho đơn vị thụ hưởng
                                                UpRace 2021 mà đội đó chỉ định.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/3_fund_4x.png"
                                            alt=""
                                        />{' '}
                                        GÂY QUỸ CHO CỘNG ĐỒNG
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            Thành tích chạy của tôi có thể gây quỹ không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Hoàn toàn có thể. Các nhà tài trợ sẽ quyên góp 1000 đồng/1km chạy hợp lệ
                                                của VĐV tham gia.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Các quỹ sẽ thụ hưởng từ UpRace 2021 là ai?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Năm 2021, UpRace kết nối với 4 quỹ cộng đồng có nhiều hoạt động mang lại
                                                lợi ích lớn cho xã hội gồm: GreenViệt, Newborns Vietnam, Operation Smile
                                                và Saigon Children’s Charity.{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Ngoài việc chạy và quyên góp bằng quãng đường cho các quỹ cộng đồng, nếu tôi
                                            muốn quyên góp bằng trực tiếp thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Để quyên góp trực tiếp cho các quỹ cộng đồng, bạn có thể quyên góp qua
                                                kênh Zalo OA của UpRace theo hướng dẫn bên dưới
                                            </p>
                                            <p>
                                                - Bước 1: Mở ứng dụng Zalo nhập từ khóa "UpRace" tại thanh tìm kiếm phía
                                                trên
                                            </p>
                                            <p>- Bước 2: Chọn tab OFFICIAL ACCOUNT sau đó bấm vào UPRACE</p>
                                            <p>- Bước 3: Bấm "Quan tâm" để theo dõi UpRace</p>
                                            <p>- Bước 4: Tìm hiểu thông tin và Quyên góp trực tiếp cho UpRace 2021</p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/4_report_4x.png"
                                            alt=""
                                        />{' '}
                                        KHIẾU NẠI & XỬ PHẠT
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            Nếu muốn khiếu nại kết quả thi đấu thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                BTC và HĐKT chỉ xem xét các khiếu nại có liên quan đến kết quả của các
                                                VĐV nằm trong top 10 của mỗi Đội. Email là kênh tiếp nhận khiếu nại duy
                                                nhất và khiếu nại phải do người đại diện của mỗi đội gửi tới địa chỉ
                                                email của BTC: support@UpRace.org. Quyết định của HĐKT là quyết định cuối
                                                cùng trong mọi khiếu nại phát sinh.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            BTC có biện pháp xử phạt nào đối với các vận động viên gian lận hay không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                BTC có các phương án phòng chống và phát hiện gian lận nếu có, đồng thời
                                                kêu gọi các VĐV thể hiện tinh thần fair-play và tự giác cao nhất. Khi có
                                                bất kỳ hành vi gian lận nào bị phát hiện, VĐV sẽ bị loại khỏi cuộc thi
                                                và không được công nhận toàn bộ thành tích đã đạt được.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="r-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/5_team_4x.png"
                                            alt=""
                                        />{' '}
                                        CÁCH THỨC THI ĐẤU GIỮA CÁC ĐỘI
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            Có bao nhiêu bảng đấu dành cho các đội?
                                        </button>
                                        <div class="panel">
                                            <p>Có 03 (ba) Bảng đấu gồm:</p>
                                            <p>- Bảng 1: Các Đội Doanh nghiệp (Company)</p>
                                            <p>- Bảng 2: Các Đội Câu lạc bộ thể thao (Sports Club)</p>
                                            <p>- Bảng 3: Các Đội Trường học (School)</p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Thành tích được tính như thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Thành tích của mỗi Đội là tổng thành tích hợp lệ của tất cả các VĐV tham
                                                gia thi đấu cho Đội trong sự kiện. Các Đội trong cùng bảng sẽ thi đấu
                                                với nhau.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Sau ngày sự kiện bắt đầu mà Đội của tôi mới được thành lập thì có được tham
                                            gia thi đấu không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Được. Các Đội được thành lập sau thời điểm sự kiện bắt đầu vẫn được tham
                                                gia thi đấu bình thường, miễn là trước khi thời gian UpRace 2021 kết
                                                thúc. Việc thành lập đội trước hay sau ngày bắt đầu chạy chính thức của
                                                UpRace 2021 chỉ ảnh hưởng tới lợi thế cạnh tranh của đội bạn.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Tôi có thể tham gia nhiều đội để thi đấu không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Không. Bạn chỉ có thể chọn duy nhất 01 đội trong số các đội đã tham gia
                                                để thi đấu và tích luỹ điểm cho đội đó.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Nếu tôi muốn ra khỏi đội cũ và gia nhập một đội mới năm nay, tôi phải làm
                                            thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>Bạn không thể chuyển đội trong thời gian sự kiện đang diễn ra.</p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Tôi có thể thay đổi đội sau khi lựa chọn không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Bạn chỉ có thể thay đổi đội trước khi sự kiện bắt đầu. Sau khi sự kiện
                                                bắt đầu (kể từ sau 00 giờ ngày 31/10/2021), bạn sẽ không được thay đổi
                                                đội đã chọn.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Tôi không tham gia bất kỳ đội nào thì thành tích có được ghi nhận không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Được. Nếu bạn không muốn tham gia đội nào, mà vẫn muốn tham gia sự kiện
                                                UpRace để gây quỹ cho cộng động thì thành tích của bạn vẫn được ghi
                                                nhận.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/6_individual_4x.png"
                                            alt=""
                                        />{' '}
                                        CÁCH THỨC THI ĐẤU CÁ NHÂN
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            UpRace 2021 ghi nhận thành tích của tôi bằng cách nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Các VĐV tự ghi lại thành tích mỗi lần chạy của mình bằng thiết bị di
                                                động, bằng các cách:
                                            </p>
                                            <p>- Chức năng RECORD trực tiếp của ứng dụng UpRace trên điện thoại</p>
                                            <p>
                                                - Đồng hồ, vòng tay thông minh GPS có chức năng ghi các cuộc chạy và
                                                đồng bộ kết quả lên Strava hoặc Garmin (2 ứng dụng này phải được kết nối
                                                với UpRace)
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Như thế thế nào mới được xem là một “cuộc chạy hợp lệ”?
                                        </button>
                                        <div class="panel">
                                            Một cuộc chạy hợp lệ được tính khi đạt đủ các điều kiện sau đây:
                                            <p>
                                                - Được ghi nhận hoàn tất trên tài khoản UpRace của VĐV trong vòng 72 giờ
                                                kể từ thời điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối cùng của
                                                sự kiện.
                                            </p>
                                            <p>
                                                - Thể hiện bản đồ chạy rõ ràng, không có dấu hiệu sai số lớn do lỗi bắt
                                                sóng GPS của thiết bị di động. Các cuộc chạy được nhập kết quả thủ công
                                                bằng tay (manual input), hoặc được share bởi người khác, hoặc ghi nhận
                                                cho nhiều tài khoản khác nhau sẽ không được công nhận hợp lệ; Trong
                                                trường hợp cuộc chạy thực hiện trên máy chạy bộ cần được ghi nhận bằng
                                                thiết bị đo chuyên dụng như đồng hồ Garmin và cảm biến đeo chân.
                                            </p>
                                            <p>
                                                - Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều app
                                                sẽ chỉ được ghi nhận là một cuộc chạy và theo thứ tự ưu tiên sau: UpRace
                                                -{'>'} Garmin -{'>'} Strava.
                                            </p>
                                            <p>
                                                - Có cự li chạy (Distance) tối thiểu là 1km. Cự li tối đa không giới
                                                hạn. VĐV được bấm tạm dừng cuộc chạy trong khi chạy với mục đích nghỉ và
                                                phân phối sức;
                                            </p>
                                            <p>
                                                - Có tốc độ di chuyển trung bình (Average Moving Pace) tính theo số phút
                                                hoàn thành 1 km chạy nhanh tối đa là 4:00 phút/km và chậm tối thiểu là
                                                15:00 phút/km. Có tốc độ di chuyển trung bình của tất cả các quãng 1km
                                                (Split) nhanh tối đa 3:30 phút/km.
                                            </p>
                                            <p>
                                                - Các cuộc chạy diễn ra trong những ngày Chủ nhật và ngày đặc biệt
                                                11/11/2021 trong thời gian diễn ra sự kiện phải được hoàn tất trong ngày
                                                (trước 24h00) và kết quả nhân 2 được tính vào thành tích của ngày đó.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Vậy “ngày chạy hợp lệ” (active days) là như thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Là ngày có ít nhất một cuộc chạy hợp lệ. Trong UpRace 2021, mỗi VĐV muốn
                                                được chứng nhận là Hoàn thành cuộc thi UpRace 2021 phải có tối thiểu 12
                                                (mười hai) ngày chạy hợp lệ và tối thiểu 60km.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Làm sao để được nhân đôi thành tích?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                - Những ngày Chủ nhật trong thời gian sự kiện diễn ra, thành tích hợp lệ
                                                của các VĐV trong những ngày này sẽ được nhân đôi.
                                            </p>
                                            <p>
                                                - Ngày đặc biệt 11/11/2021: Nếu vận động viên sử dụng ứng dụng UpRace để
                                                ghi hoạt động trực tiếp thì thành tích sẽ được nhân đôi.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Để được tính là Hoàn thành cuộc thi UpRace 2021 thì tôi cần phải làm gì?
                                        </button>
                                        <div class="panel">
                                            Một VĐV sẽ được chứng nhận là hoàn thành cuộc thi khi đạt đồng thời 02 điều
                                            kiện sau:
                                            <p>- Có số ngày chạy hợp lệ tối thiểu là 12 ngày.</p>
                                            <p>
                                                - Có tổng số quãng đường chạy tối thiểu là 60km (bao gồm cả thành tích
                                                nhân đôi trong các ngày Chủ nhật và ngày 11/11)
                                            </p>
                                            <p>
                                                Lưu ý: Đối với vận động viên không chạy đủ 12 ngày và 60km, thì số km
                                                chạy hoàn thành vẫn được đóng góp cho đội và được nhà tài trợ quyên góp
                                                cho quỹ.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Tôi có thể chạy trên máy chạy bộ để tính thành tích thi đấu không?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Trong trường hợp bạn chạy trên máy chạy bộ, bạn cần có các thiết bị
                                                chuyên dụng để đo thành tích gồm: Thiết bị ghi thành tích là Vòng tay
                                                hoặc Đồng thông minh (ví dụ Garmin) cùng với thiết bị cảm biến tốc độ/
                                                guồng chân đeo chân được kết nối với nhau. Sau khi ghi, thành tích phải
                                                được đồng bộ lên Strava hoặc Garmin (2 ứng dụng này phải được đồng bộ
                                                với UpRace), thành tích của bạn sẽ được ghi nhận trên UpRace.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Thành tích của từng ngày chạy được tính như thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Thời điểm mà bạn bắt đầu cuộc chạy thuộc ngày nào thì thành tích của
                                                cuộc chạy được tính cho ngày đó.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="title">ỨNG DỤNG UPRACE</h2>
                        <div class="row-side">
                            <div class="l-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/7_upraceteam_4x.png"
                                            alt=""
                                        />{' '}
                                        DÀNH CHO VẬN ĐỘNG VIÊN
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible1 active ">
                                            Làm sao để đăng ký tài khoản UpRace?
                                        </button>
                                        <div class="panel" style={{ maxHeight: 500, display: 'block', opacity: 1 }}>
                                            <p>
                                                <strong>Bước 1:</strong> Tải ứng dụng UpRace trên Play Store hoặc Apple
                                                Store
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Mở ứng dụng UpRace hoặc website{' '}
                                                <a href="https://uprace.org">UpRace.org</a>
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Đăng ký
                                            </p>
                                            <p>
                                                - Đăng ký bằng email: Bấm vào "ĐĂNG KÝ"/ nhập thông tin email/ mật khẩu/
                                                tên và đăng ký
                                            </p>
                                            <p>
                                                - Cập nhật thông tin (họ tên, địa chỉ, size áo, ...), đây là thông tin
                                                giúp BTC gửi áo cho bạn nếu đội của bạn chiến thắng ở UpRace 2021
                                            </p>
                                            <p>
                                                Ngoài cách đăng nhập trên ứng dụng, bạn cũng có thể đăng ký trên website{' '}
                                                <a href="https://uprace.org">UpRace.org</a>
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Làm sao để đăng nhập UpRace?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1: </strong>Mở ứng dụng UpRace/ Website UpRace.org
                                            </p>
                                            <p>
                                                <strong>Bước 2: </strong>Đăng nhập
                                            </p>
                                            <p>
                                                - Đăng nhập email: Điền thông tin tài khoản/ mật khẩu đã đăng ký để đăng
                                                nhập
                                            </p>
                                            <p>- Bạn có thể đăng nhập bằng tài khoản Facebook/ Google/ Apple</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Làm sao để tôi có thể tham gia đội trong sự kiện UpRace 2021?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Mở ứng dụng UpRace và đăng nhập
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào tab "SỰ KIỆN" phía dưới
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Bấm vào tab "ĐỘI" phía trên
                                            </p>
                                            <p>
                                                <strong>Bước 4: </strong>Tìm kiếm đội mà bạn muốn tham gia thi đấu bấm
                                                "CHỌN"/ bấm "CHẮC CHẮN" để xác nhận tham gia nhóm
                                            </p>
                                            <p>
                                                Lưu ý: Bạn không thể thay đổi đội mà khi sự kiện UpRace 2021 đã bắt đầu.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn Ghi (record) một hoạt động trên ứng dụng UpRace thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Bấm vào tab "GHI" phía dưới
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào "GO" màu xanh phía dưới để bắt đầu hoạt
                                                động (lưu ý: Nếu GPS của điện thoại bạn chưa sẵn sàng thì nút "GO" sẽ
                                                hiển thị màu xám, bạn sẽ không tiến hành hoạt động được, hãy cài đặt lại
                                                GPS trong cài đặt điện thoại)
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Bấm nút "=" TẠM DỪNG , sau đó bấm "HOÀN THÀNH"
                                                để kết thúc hoạt động (lưu ý: Trong khi hoạt động bạn có thể bấm "TẠM
                                                DỪNG" để nghỉ ngơi sau đó bấm "TIẾP TỤC" để ghi tiếp hoạt động"
                                            </p>
                                            <p>
                                                <strong>Bước 4:</strong> Thêm thông tin cho hoạt động
                                            </p>
                                            <p>- Đặt tiêu đề cho hoạt động</p>
                                            <p>- Chọn loại hoạt động Đi bộ, Chạy bộ hoặc Đạp xe.</p>
                                            <p>- Thêm hình ảnh cho hoạt động</p>
                                            <p>Bước 5: Bấm "LƯU"</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn kết nối ứng dụng UpRace với Strava và Garmin thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Bấm vào tab "CÁ NHÂN" phía dưới
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào biểu tượng "CÀI ĐẶT" hình bánh xe phía
                                                trên góc phải
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Chọn "KẾT NỐI và ỨNG DỤNG"
                                            </p>
                                            <p>
                                                <strong>Bước 4:</strong> Chọn "KẾT NỐI VỚI STRAVA" hoặc "KẾT NỐI VỚI
                                                GARMIN"
                                            </p>
                                            <p>
                                                <strong>Bước 5:</strong> Tại giao diện đăng nhập vào STRAVA hoặc GARMIN,
                                                bạn đăng nhập tài khoản tương ứng của STRAVA và GARMIN
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn chỉnh sửa Thông tin cá nhân thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Bấm vào tab "CÁ NHÂN" phía dưới
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào biểu tượng "CÀI ĐẶT" hình bánh xe phía
                                                trên góc phải
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Chỉnh sửa thông tin
                                            </p>
                                            <p>- Chọn "THIẾT LẬP MẬT KHẨU" để cập nhật mật khẩu mới</p>
                                            <p>- Chọn "THÔNG TIN CÁ NHÂN" để cập nhật thông tin cá nhân</p>
                                            <p>- Chọn "NGÔN NGỮ" để thay đổi ngôn ngữ hiển thị</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi có thể xem Thông tin sự kiện/ Bảng xếp hạng ở đâu?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào tab "SỰ KIỆN" bên dưới, chọn sự kiện
                                                “UPRACE 2021”
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Xem nội dung mong muốn
                                            </p>
                                            <p>
                                                - Vào tab "THÔNG TIN" phía trên để xem Thể lệ sự kiện, Số vận động viên
                                                đã tham gia, số đội tham gia, số km mà UpRace 2021 đã đạt được
                                            </p>
                                            <p>
                                                - Vào tab "BXH" để xem: Xếp hạng 3 bảng thi đấu Doanh nghiệp, Câu lạc
                                                bộ, Trường học (bạn có thể thấy xếp hạng của đội mình tại bảng thi đấu
                                                tương ứng)
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn xem thành tích Cá nhân/ Đội thì xem như thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào tab "SỰ KIỆN" bên dưới
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Xem nội dung mong muốn
                                            </p>
                                            <p>
                                                - Vào tab "BẠN" để xem các hoạt động hợp lệ của cá nhân và các chỉ số
                                                xếp hạng trong đội, tổng quãng đường đã chạy, số ngày đã chạy.
                                            </p>
                                            <p>
                                                - Vào tab "ĐỘI" để xem các chỉ số của đội gồm xếp hạng của đội, số lượng
                                                thành viên, số km đội đã hoàn thành
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn so sánh thành tích với vận động viên bất kỳ thì làm thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab BẠN
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm chọn “Thêm bạn bè để so sánh”
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Tìm kiếm bạn bè muốn so sánh kết quả và bấm
                                                “THÊM” (được thêm tối đa 5 người){' '}
                                            </p>
                                            <p>
                                                <strong>Bước 4:</strong> Bấm “HOÀN THÀNH” và xem thống kê thành tích của
                                                các vận động viên đã chọn
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Tôi muốn tạo nhóm bên trong đội thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm chọn “BXH NHÓM CỦA ĐỘI”
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Bấm “TẠO NHÓM NGAY” (Đặt tên nhóm, mô tả thông
                                                tin nhóm, thêm hình ảnh đại diện cho nhóm và bấm “TẠO NHÓM”)
                                            </p>
                                            <p>
                                                <strong>Bước 4:</strong> Chờ đội trưởng duyệt nhóm của bạn{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Làm sao để tôi báo cáo một hoạt động không hợp lệ?
                                        </button>
                                        <div class="panel">
                                            Tính năng báo cáo hoạt động không hợp lệ chỉ dành cho thành viên trong đội,
                                            bạn hãy thực hiện theo hướng dẫn bên dưới
                                            <p>
                                                <strong> Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI / chọn
                                                THÔNG TIN ĐỘI
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm chọn người bạn muốn báo cáo{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Bấm xem hoạt động của người bạn muốn báo cáo{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 4:</strong> Tìm hoạt động bạn muốn báo cáo và bấm vào nút
                                                BA CHẤM bên cạnh
                                            </p>
                                            <p>
                                                <strong>Bước 5:</strong> Nhập nội dung bạn muốn báo cáo và GỬI{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Hoạt động của tôi bị đội trưởng xóa, tôi muốn khiếu nại thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab BẠN/ chọn XEM
                                                HOẠT ĐỘNG CỦA BẠN{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Tìm hoạt động cần khiếu nại, bấm vào nút BA
                                                CHẤM và chọn KHIẾU NẠI{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Nhập lý do bạn khiếu nại và bấm GỬI{' '}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="r-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/8_upracemanage_4x.png"
                                            alt=""
                                        />{' '}
                                        DÀNH CHO ĐỘI TRƯỞNG
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible1 ">
                                            Tôi muốn gửi thông báo cho toàn bộ thành viên của đội phải làm thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm chọn nút DẤU CỘNG tại dòng THÊM THÔNG BÁO
                                                TỚI CÁC THÀNH VIÊN{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Soạn nội dung thông báo (Tiêu đề, Nội dung và
                                                Hình ảnh)
                                            </p>
                                            <p>
                                                <strong>Bước 4: </strong>Bấm ĐĂNG{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Tôi muốn phân quyền admin cho người khác hỗ trợ quản lý đội thì phải làm thế
                                            nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Ở màn hình chính bấm chọn CÁ NHÂN (góc phải
                                                dưới)
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Tại mục ĐỘI CỦA BẠN chọn đội muốn phân quyền
                                                admin
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Chọn DANH SÁCH THÀNH VIÊN{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 4: </strong>Tìm thành viên muốn phân quyền admin và bấm vào
                                                nút BA CHẤM, sau đó chọn ĐẶT LÀM ADMIN{' '}
                                            </p>
                                            <p>Muốn thu hồi quyền làm admin thì bấm vào tab ADMIN và thu hồi</p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Trong đội tôi có một bạn chạy không trung thực, tôi muốn khóa thành viên này
                                            thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào nút BA CHẤM nằm cùng với dòng chữ “mời
                                                bạn bè” và chọn QUẢN LÝ ĐỘI
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Chọn thành viên và bấm KHÓA/ bấm CHẮC CHẮN để
                                                xác nhận{' '}
                                            </p>

                                            <p>
                                                Nếu muốn mở lại quyền cho thành viên tiếp tục trong đội thì bấm chọn BỎ
                                                KHÓA
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Tôi muốn quản lý các báo cáo và khiếu nại của thành viên đội thì phải làm
                                            thế nào?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào nút BA CHẤM nằm cùng với dòng chữ “mời
                                                bạn bè” và chọn QUẢN LÝ BÁO CÁO/ KHIẾU NẠI{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Chọn tab BÁO CÁO hoặc KHIẾU NẠI để xử lý{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            (Đội) công ty tôi có nhiều bạn tạo nhóm nhỏ để thi đấu nội bộ, tôi muốn xét
                                            duyệt thì phải làm sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào sự kiện UpRace 2021/ chọn tab ĐỘI{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Bấm vào nút BA CHẤM nằm cùng với dòng chữ “mời
                                                bạn bè” và chọn QUẢN LÝ NHÓM{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 3:</strong> Ở tab CHỜ DUYỆT bạn có thể chọn Phê Duyệt hoặc
                                                Từ Chối nhóm sau khi kiểm tra thông tin{' '}
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Tôi muốn theo dõi Đội/ Vận động viên thì phải làm gì?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Bước 1:</strong> Vào tab "TÌM KIẾM" phía dưới{' '}
                                            </p>
                                            <p>
                                                <strong>Bước 2:</strong> Tìm kiếm và theo dõi{' '}
                                            </p>
                                            <p>- Bấm tab "ĐỘI" và tìm kiếm đội muốn theo dõi và bấm "THAM GIA" </p>
                                            <p>
                                                - Bấm tab "VẬN ĐỘNG VIÊN" và tìm kiếm vận động viên muốn theo dõi và bấm
                                                "THEO DÕI"{' '}
                                            </p>
                                            <p>
                                                <strong>Lưu ý:</strong>
                                            </p>
                                            <p>
                                                - Đội tham gia ở mục "TÌM KIẾM" chỉ là đội bạn tham gia để theo dõi
                                                thông tin, không phải đội bạn tham gia trong sự kiện UpRace 2021
                                            </p>
                                            <p>
                                                - Bạn có thể Nhìn thấy hoạt động vận động viên bạn đã theo dõi cũng như
                                                vận động viên thuộc nhóm mà bạn tham gia tại tab "BẢNG TIN"
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Chỉ số quãng đường khi tôi sử dụng ứng dụng UpRace/ Garmin/ Strava để đo
                                            khác nhau, lý do vì sao?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Mỗi một ứng dụng có một thuật toán khác nhau để tính quãng đường khác
                                                nhau dựa trên tín hiệu GPS mà thiết bị thu nhận được. Vì vậy luôn có sai
                                                khác về quãng đường khi đồng thời đo bằng các ứng dụng khác nhau.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        function RenderFAQEN() {
            return (
                <section class="uprace-faqs">
                    <h2 class="page-title">FAQs</h2>

                    <div class="uprace-faqs-page">
                        <h2 class="title">EVENT INFORMATION, RULES & REGULATIONS</h2>
                        <div class="row-side">
                            <div class="l-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/1_info_4x.png"
                                            alt=""
                                        />
                                        EVENT INFORMATION
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible active">
                                            What is UpRace 2021?
                                        </button>
                                        <div class="panel" style={{ maxHeight: 500, display: 'block', opacity: 1 }}>
                                            <p>
                                                UpRace 2021 is a FREE charity running project open to the public on the
                                                UpRace website/mobile platform. Participants are able to pick their own
                                                time and place to run and actively record their results on any available
                                                mobile devices (mobile phones, sports watches, etc.), the ultimate goal
                                                of which is to obtain as long a running distance as possible.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            How long does the race last?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The event begins at 12 a.m. October 31, 2021 to 11:59 p.m. on November
                                                21, 2021.
                                            </p>
                                            <p>
                                                {' '}
                                                Local time in Vietnam (GMT+7) is the standard time zone for the event.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            What happens if I register after UpRace starts? Will I be put at a
                                            disadvantage compared to other participants?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                If a participant starts competing after October 31, 2021, his or her
                                                performance will only be recorded from the start date until the end of
                                                November 21, 2021. The number of days available for competing, hence,
                                                will be significantly fewer than that of other participants.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/2_prize_4x.png"
                                            alt=""
                                        />{' '}
                                        PRIZES
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            What are the criteria for winning the prize for teams? What are the total
                                            prizes?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                - Top ten best teams with the best total in each Board will receive the
                                                team prize.
                                            </p>
                                            <p>- Team prizes are UpRace Winning Team 2021 t-shirts, given to:</p>
                                            <p>+ Participants who meet all the requirements to complete the race</p>
                                            <p>
                                                + And those whose performances rank among the top of winning teams
                                                according to the following table:
                                            </p>

                                            <div className="faq">
                                                <table className="table">
                                                    <tr>
                                                        <th></th>
                                                        <th>Company</th>
                                                        <th>Sports club</th>
                                                        <th>School</th>
                                                    </tr>
                                                    <tr>
                                                        <td>First team</td>
                                                        <td>Top 300 runners</td>
                                                        <td>Top 250 runners</td>
                                                        <td>Top 200 runners</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Second team</td>
                                                        <td>Top 250 runners</td>
                                                        <td>Top 200 runners</td>
                                                        <td>Top 150 runners</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Third team</td>
                                                        <td>Top 200 runners</td>
                                                        <td>Top 150 runners</td>
                                                        <td>Top 100 runners</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fourth, fifth teams</td>
                                                        <td>Top 150 runners</td>
                                                        <td>Top 100 runners</td>
                                                        <td>Top 50 runners</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6th,7th,8th,9th,10th teams</td>
                                                        <td>Top 50 runners</td>
                                                        <td>Top 50 runners</td>
                                                        <td>Top 50 runners</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Will I get any prizes for completing the race?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                All runners who meet the requirements for completing the race - that is,
                                                a minimum of 12 valid running days and a minimum running distance of 60
                                                km, will receive 01 (one) certificate of completion (electronic version)
                                                based on the number of kilometers completed including 60km, 150 km, 300
                                                km, consecutively titled Pro Runner, Hero Runner, and Angel Runner.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Which team will be rewarded 100 million VND? And what would this amount of
                                            money be used towards?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The team with the highest performance in each Group <br></br>(Top 1) will receive
                                                a monetary prize of 100 million VND, which will be donated to the UpRace
                                                2021 beneficiary of the team’s choice.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/3_fund_4x.png"
                                            alt=""
                                        />{' '}
                                        COMMUNITY FUNDRAISING
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            Can my running record fundraise?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Absolutely. Sponsors are committed to donating 1000 VND for every valid
                                                1 kilometer of participating runners.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Who are the beneficiaries of UpRace 2021?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                In 2021, UpRace partners with four social organizations whose services
                                                have brought a major impact on society including GreenViet, Newborns
                                                Vietnam, Operation Smile, and Saigon Children's Charity.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            In addition to running and donating distance to community foundations, can I
                                            donate directly?
                                        </button>
                                        <div class="panel">
                                            <p>You can donate via UpRace's Zalo OA following the below instructions:</p>
                                            <p>- Step 1: Open the Zalo app and enter "UpRace" on the search bar</p>
                                            <p>- Step 2: Select the OFFICIAL ACCOUNT tab and click UPRACE</p>
                                            <p>- Step 3: Click "Interested" to follow UpRace</p>
                                            <p>- Step 4: Read the information and donate directly to UpRace 2021</p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/4_report_4x.png"
                                            alt=""
                                        />{' '}
                                        COMPLAINTS & PENALTIES
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            What should I do if I want to file a complaint regarding my competing
                                            results?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The Organization Committee and Technical Council only review complaints
                                                about the results of runners within the top 10 of each Team. Complaints
                                                must be filed by the Team’s representative via email at
                                                support@UpRace.org. The decision made by the Technical Council is the
                                                final decision to all arising complaints.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Does the Organization Committee have any sanctions against cheating?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The Organization Committee has appropriate protocols in place to prevent
                                                and detect cheating if necessary and at the same time encourage
                                                participants to practice fair play and self-discipline to the highest
                                                level. If any behaviors of cheating are detected, participants will be
                                                disqualified from the race and will not be recognized for any achieved
                                                results.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="r-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/5_team_4x.png"
                                            alt=""
                                        />{' '}
                                        TEAM PARTICIPATION
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            How many groups are there for teams to compete in?
                                        </button>
                                        <div class="panel">
                                            <p>There are 03 (three) groups:</p>
                                            <p>- Group 1: Company</p>
                                            <p>- Group 2: Sports Club</p>
                                            <p>- Group 3: School</p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            How are team results counted?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Each team's result is the sum of the recorded distance of all
                                                participants competing under the team’s name in the event. All teams
                                                within each board will compete against one another.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Will my team still be able to join if the team is established after the
                                            start date?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Yes. Teams formed after the start date are still eligible to compete so
                                                long as they join by the end date of UpRace 2021. Whether your team is
                                                created before or after UpRace 2021’s official start date only affects
                                                your own advantage.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Can I simultaneously compete for multiple teams?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                No. You can only choose 1 team from the competing teams to join and run
                                                for that team.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            What should I do if I want to leave my old team and join a new team?
                                        </button>
                                        <div class="panel">
                                            <p>You are not allowed to switch teams during the course of the event.</p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Can I choose a different team after deciding?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                You can only change your choice of team to join before the event begins.
                                                After the official start date (after 12 a.m., October 31, 2021), you
                                                will no longer be able to make changes to your selected team.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            If I don't join any particular team, will my results still be counted?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Yes. If you do not wish to compete for any team yet still want to join
                                                UpRace to help fundraise for the community, your individual recorded
                                                results will still be recognized.
                                            </p>
                                        </div>
                                    </div>

                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/6_individual_4x.png"
                                            alt=""
                                        />{' '}
                                        INDIVIDUAL PARTICIPATION
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible ">
                                            How does UpRace 2021 record my running results?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Participants record their own distance after each run on any mobile
                                                device via:
                                            </p>
                                            <p>- The RECORD function on the UpRace app</p>
                                            <p>
                                                - Smartwatches and bracelets with GPS that are able to record and sync
                                                running results to Strava or Garmin (these two apps must be connected to
                                                UpRace)
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            What counts as a “valid run”?
                                        </button>
                                        <div class="panel">
                                            A run is considered valid if meeting the following criteria:
                                            <p>
                                                - Is recorded on the participant's UpRace account 72 hours within the
                                                start time and no later than the last day of the event.
                                            </p>
                                            <p>
                                                - Shows a clear running map with no major errors of GPS signals on
                                                mobile devices. Runs that are manually input, shared by runners, or
                                                credited to multiple accounts are considered invalid; Runs completed on
                                                a treadmill must be recorded using special devices such as Garmin
                                                watches and foot sensors.
                                            </p>
                                            <p>
                                                - Overlapping runs due to simultaneous recordings on multiple apps will
                                                only be counted as one run in the following order of precedence: UpRace
                                                -{'>'} Garmin -{'>'} Strava.
                                            </p>
                                            <p>
                                                - Has a minimum distance of 1 km, no limit on a maximum distance. The
                                                participant is allowed to click pause to the run during the run to rest
                                                and regain energy;
                                            </p>
                                            <p>
                                                - Has an average moving pace calculated by the total time in minutes to
                                                complete 1 km as fast as 4 min/km and slow as at least 15 min/km. Has a
                                                minimum average travel speed at every 1 km (Split) of 3.5 min/km.
                                            </p>
                                            <p>
                                                - If takes place on Sundays and special days such as November 11, 2021,
                                                must be completed by the end of the day (within 24 hours); the doubled
                                                distance will be counted towards the overall result of that day.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            What are “active days”?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The date with at least one valid run. In UpRace 2021, participants who
                                                wish to be rewarded with an UpRace 2021 Completion Certificate must have
                                                a minimum of 12 (twelve) valid running days and a minimum of 60 km in
                                                total running distance.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            How can I double my achievements?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                - Valid recorded running distance of all participants is doubled every
                                                Sunday during the course of the event.
                                            </p>
                                            <p>
                                                - Special day (November 11, 2021): All running distance recorded
                                                directly on the UpRace app on this day will be doubled.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            What do I need to do to be recognized as Finished UpRace 2021?
                                        </button>
                                        <div class="panel">
                                            Participants receive certification for completing the race if the following
                                            two conditions are met:
                                            <p>- Have a minimum of 12 valid running days.</p>
                                            <p>
                                                - Have a total distance of at least 60 km (including doubles on
                                                Sundays).
                                            </p>
                                            <p>
                                                Note: Participants who do not run a full 12 days and 60 km can still
                                                donate their completed kilometers to their team and be converted to the
                                                equivalent donation from the sponsors.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            Can I run on the treadmill to increase my distance?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                If you decide to run on a treadmill, you need special devices to measure
                                                your performance such as a smart bracelet or smartwatch (e.g, Garmin)
                                                along with speed/cadence sensors connected to each other. Once recorded,
                                                your result must be synced to Strava or Garmin (2 these apps must be
                                                connected with UpRace) in order to be counted on UpRace.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible">
                                            How is the result of each day calculated?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                The result of each day is counted from whenever you start running to
                                                when you finish on the same day.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 class="title">UPRACE APP</h2>
                        <div class="row-side">
                            <div class="l-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/7_upraceteam_4x.png"
                                            alt=""
                                        />{' '}
                                        FOR RUNNERS
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible1 active ">
                                            How to register for an UpRace account?
                                        </button>
                                        <div class="panel" style={{ maxHeight: 500, display: 'block', opacity: 1 }}>
                                            <p>
                                                <strong>Step 1:</strong> Download the UpRace app on Google Play or App
                                                Store
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Open the UpRace app or website{' '}
                                                <a href="https://uprace.org">UpRace.org</a>
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Sign up
                                            </p>
                                            <p>
                                                - Sign up via email: Click "REGISTER" / Enter your email address
                                                /password /name and sign up
                                            </p>
                                            <p>
                                                - Update your personal information (full name, address, shirt size,
                                                ...). This will provide us the information to send you a t-shirt in case
                                                your team wins at UpRace 2021
                                            </p>
                                            <p>
                                                Besides registering on the app, you can also sign up on our website{' '}
                                                <a href="https://uprace.org">UpRace.org</a>
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to log into my UpRace account?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1: </strong>Open the UpRace app or website{' '}
                                                <a href="https://uprace.org">UpRace.org</a>
                                            </p>
                                            <p>
                                                <strong>Step 2: </strong>Log in
                                            </p>
                                            <p>
                                                - Login using email: Fill in the registered account information/password
                                                to log in
                                            </p>
                                            <p>- You can also log in using your Facebook/Google/Apple account</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to join a team in UpRace 2021?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Open the UpRace app and log into your account
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the "EVENT" tab below
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Click on the "TEAM" tab above
                                            </p>
                                            <p>
                                                <strong>Step 4: </strong>Search for the team you want to join, click
                                                "SELECT" / click "SURE" to confirm your team membership
                                            </p>
                                            <p>Note: You cannot change the team once UpRace 2021 has started.</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to record my activity on the UpRace app?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Click on the "REC" tab at the bottom
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the blue "GO" button to start
                                                recording (Note: If your phone's GPS is not ready, this "GO" button will
                                                be grayed out. You will not be able to proceed with the activity. Please
                                                make sure to reset your GPS settings)
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Press the "=" PAUSE button, then press "DONE"
                                                to end the activity (Note: During the activity, you can press "PAUSE" to
                                                rest. Click "CONTINUE" to resume recording)
                                            </p>
                                            <p>
                                                <strong>Step 4:</strong> Add detailed information to the activity
                                            </p>
                                            <p>- Set a title for the activity</p>
                                            <p>- Choose an activity: Walking, Jogging, or Cycling</p>
                                            <p>- Add an image for the activity</p>
                                            <p>Step 5: Click "SAVE"</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to connect the UpRace app with Strava and Garmin, what should I do?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Click on the "Personal" tab at the bottom
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the wheel icon "SETTINGS" in the upper
                                                right corner
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Select "CONNECT TO APPS"
                                            </p>
                                            <p>
                                                <strong>Step 4:</strong> Select "CONNECT TO STRAVA" or "CONNECT TO
                                                GARMIN"
                                            </p>
                                            <p>
                                                <strong>Step 5:</strong> At the login page of STRAVA or GARMIN, log into
                                                the STRAVA and GARMIN respective account
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            What if I want to correct Personal Information?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Click on the "Personal" tab at the bottom
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the gear icon for "SETTINGS" on the
                                                upper right corner
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Edit your account information
                                            </p>
                                            <p>- Select "SET PASSWORD" to set a new password</p>
                                            <p>- Select "Personal Information" to update your personal information</p>
                                            <p>- Select "Language" to change the display language</p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Where to find the event information/leaderboards?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to the "EVENT" tab at the bottom, select
                                                "UPRACE 2021"
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> See other information of your choice:
                                            </p>
                                            <p>
                                                - Go to "INFORMATION" at the top to view Event rules, Number of
                                                participants, Number of competing teams, Number of kilometers achieved
                                                in UpRace 2021
                                            </p>
                                            <p>
                                                - Go to "LEADER BOARD" to see top teams in all 3 Groups Company, Sports
                                                Club, School (You can also view your team's rank in the respective
                                                board)
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            Where can I find my individual/team results?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to "EVENT" at the bottom
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> See other information of your choice:
                                            </p>
                                            <p>
                                                - Go to "YOU" to view individual qualifying activities and Team rank
                                                statistics, Total distance run, Number of active days.
                                            </p>
                                            <p>
                                                - Go to "TEAM" to see your team's results including Team rank, Members,
                                                Number of completed kilometers
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to compare my performance with that of other participants?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021/ select FRIENDS
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click "Add friends to compare"
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Search for the friends whom you want to compare
                                                results with and click “ADD” (up to 5 people)
                                            </p>
                                            <p>
                                                <strong>Step 4:</strong> Click "DONE" to see the performance statistics
                                                of the selected participants
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to a group within a team?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021/ select TEAM
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on “TEAM’S GROUP RANK”
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Click "CREATE GROUP NOW" (Name the group,
                                                describe the group, add a profile picture and click "CREATE GROUP")
                                            </p>
                                            <p>
                                                <strong>Step 4:</strong> Wait for the team leader to review your group
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            How to report an invalid activity?
                                        </button>
                                        <div class="panel">
                                            Reports of invalid activities are only available to members within each
                                            team. To do so, please follow the instructions below:
                                            <p>
                                                <strong> Step 1:</strong> Go to UpRace 2021/ select TEAM/ select TEAM
                                                INFORMATION
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the person you want to report
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Click to view the activity of the person you
                                                want to report
                                            </p>
                                            <p>
                                                <strong>Step 4:</strong> Find the activity you want to report and click
                                                the THREE DOTS next to it
                                            </p>
                                            <p>
                                                <strong>Step 5:</strong> Enter the information you want to report and
                                                SEND
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1">
                                            My activity was deleted by the leader. How can I file a complaint?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021 / select YOU / select VIEW
                                                YOUR ACTIVITY
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Find the activity that you want to file the
                                                complaint about, click the THREE DOTS and select COMPLAINTS
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Enter the reason for your complaint and click
                                                SEND
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="r-side">
                                <div class="panel-group">
                                    <h2 class="panel-title">
                                        <img
                                            class="lazyload"
                                            data-src="../../images/icon_faq/png_iconx4/8_upracemanage_4x.png"
                                            alt=""
                                        />{' '}
                                        FOR TEAM LEADERS
                                    </h2>

                                    <div class="panel-content">
                                        <button type="button" class="collapsible1 ">
                                            How to send notifications to all my team members?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021 / select TEAM
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click the PLUS button at the SEND NOTIFICATION
                                                TO MEMBERS line
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Compose the announcement (Title, Message, and
                                                Image)
                                            </p>
                                            <p>
                                                <strong>Step 4: </strong>Click POST
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            How to assign admin rights to someone else to help manage the team?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> On the homepage, click PERSONAL (on the bottom
                                                right corner)
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Under YOUR TEAM, select the team you want to
                                                assign admin rights to
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Select MEMBER LIST
                                            </p>
                                            <p>
                                                <strong>Step 4: </strong>Find the member you want to assign admin rights
                                                to, click on the THREE DOTS, and select SET AS ADMIN
                                            </p>
                                            <p>
                                                If you want to remove admin rights, click on the ADMIN tab and remove it
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            In my team, there is a dishonest runner. I want to block their access. How
                                            can I do that?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021 / select TEAM
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the THREE DOTS next to "Invite
                                                friends" and select MANAGE TEAM
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Select the member and click LOCK/ click CONFIRM
                                                to confirm
                                            </p>

                                            <p>If you want to grant them access and team membership, click UNLOCK</p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            How to manage my members’ reports and complaints?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021/ select TEAM
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the THREE DOTS next to "Invite
                                                friends" and select MANAGE REPORT/ COMPLAINTS
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> Select REPORT or COMPLAINTS to begin reviewing
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Many members in my company (team) create small groups for internal
                                            competition. How do I review and approve these groups?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to UpRace 2021 / select TEAM
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Click on the THREE DOTS next to "Invite
                                                friends" and select MANAGE GROUPS
                                            </p>
                                            <p>
                                                <strong>Step 3:</strong> In the PENDING FOR APPROVAL tab, you can select
                                                Approve or Decline for each small group after reviewing its information.
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            How to follow other teams/participants?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                <strong>Step 1:</strong> Go to the "SEARCH" tab at the bottom
                                            </p>
                                            <p>
                                                <strong>Step 2:</strong> Search and follow
                                            </p>
                                            <p>
                                                - Click on the "TEAM" tab and search for the teams you want to follow
                                                then click "JOIN"
                                            </p>
                                            <p>
                                                - Click on "PARTICIPANTS" tab and search for the participants you want
                                                to follow then click "FOLLOW"
                                            </p>
                                            <p>
                                                <strong>Note:</strong>
                                            </p>
                                            <p>
                                                - The teams you join from the "SEARCH" tab are the teams you follow, not
                                                your own team under which you’re competing for UpRace 2021
                                            </p>
                                            <p>
                                                - You can view all activities of the participants you follow as well as
                                                those in the groups you join from the "NEWS" tab
                                            </p>
                                        </div>

                                        <button type="button" class="collapsible1 ">
                                            Why is the distance recorded on the UpRace/ Garmin/ Strava app different?
                                        </button>
                                        <div class="panel">
                                            <p>
                                                Each app has a different algorithm to calculate a user’s running
                                                distance based on the GPS signal received by the device. Hence, there is
                                                always a mismatch in the total distance when recorded simultaneously by
                                                different apps.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        function FAQUprace() {
            if (language == 'en') return <RenderFAQEN />;
            else return <RenderFAQVI />;
        }

        return FAQUprace();
    })
);

export default FAQ;
