import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';
import { formatDate } from '../utils';

export default class LeaderboardStore {
    @observable loading = false;
    @observable listLeaderboard = null;
    @observable listLeaderboardTeam = null;
    @observable listRankTop = null;
    @observable naviTab = {}; //tab navigation
    @observable totalRecord = 0;
    @observable pageIndex = 1;
    @observable nameFilter = '';

    @observable totalTeam = 0;
    @observable pageTeam = 0;

    @action.bound
    doSetNameFilter(val)
    {
        this.nameFilter = val;
    }

    @action.bound
    setNavigationTab(tab) {
        this.naviTab = tab;
    }

    @action.bound
    doResetList() {
        this.listLeaderboard = [];
    }

    //get list leaderboard
    @action.bound
    doGetListLeaderboard(params, isLoadMore) {
        this.loading = true; //show progress
        requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    if (!isLoadMore) this.listLeaderboard = [];
                    this.totalRecord = resp.data.total;
                    if (isLoadMore) this.listLeaderboard =  this.listLeaderboard.concat(resp.data.list);
                    else this.listLeaderboard = resp.data.list;
                    this.loading = false;
                    console.log(toJS(this.listLeaderboard))
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

     //get list leaderboard team
     @action.bound
     doGetListLeaderboardTeam(params, isLoadMore, isSearch) {
         this.loading = true; //show progress

         if (isLoadMore) {
            this.pageTeam += 1;
            params.from = params.size * this.pageTeam;
        } else {
            this.pageTeam = 0;
            params.from = 0;
        }

         requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
             .then((resp) => {
                 if (resp.ercd != API_STATUS.NO_ERROR) {
                     this.loading = false;
                 } else {
                     
                     if (!isLoadMore) this.listLeaderboardTeam = [];
                     this.totalTeam = resp.data.total;
                     if (isLoadMore) this.listLeaderboardTeam =  this.listLeaderboardTeam.concat(resp.data.list);
                     else this.listLeaderboardTeam = resp.data.list;
                     if(!isSearch) this.listRankTop = this.listLeaderboardTeam.slice(0,3);
                     this.loading = false;
                 }
             })
             .catch((error) => {
                 
                 this.loading = false;
             });
     }
}
