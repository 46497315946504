import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TabHeader from './TabHeader';
import { FEED_TYPE, FEED_REPORT_TYPE, ACTIVITY_TYPE } from '../../../../helpers/ConstDefined';
import ActivityItem from './ActivityItem';

const ManagerComplain = inject(
    'reportStore',
    'userStore',
    'globalStore',
    'newsStore'
)(
    observer((props) => {
        const { eventData } = props;
        const {
            loading,
            listReport,
            listComplain,
            totalReport,
            totalComplain,
            doGetListReport,
            doGetListComplain
        } = props.reportStore;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const { listErrorActivity, doGetListErrorActivity } = props.newsStore;

        const [tab, setTab] = useState(0);
        const isShowLoadMoreReport = listReport == null ? false : listReport.length < totalReport;
        const isShowLoadMoreComplain = listComplain == null ? false : listComplain.length < totalComplain;

        useEffect(() => {
            getListReport();
            getListComplain();
            if(!listErrorActivity) doGetListErrorActivity();
        }, []);

        const getListReport = (isLoadMore) => {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.ALL,
                evid: eventData.evid,
                rpsts: FEED_REPORT_TYPE.REPORT
            };
            doGetListReport(params, isLoadMore);
        };

        const getListComplain = (isLoadMore) => {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.ALL,
                evid: eventData.evid,
                rpsts: FEED_REPORT_TYPE.COMPLAIN
            };
            doGetListComplain(params, isLoadMore);
        };

        function LoadMoreReport() {
            if (isShowLoadMoreReport) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListReport(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function LoadMoreComplain() {
            if (isShowLoadMoreComplain) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListComplain(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        const onChangeTab = (val) => {
            setTab(val);
            if (val == 0) getListReport();
            else getListComplain();
        };

        const { t } = useTranslation();

        function RenderReport() {
            return (
                <div class="tabs-v2-content">
                    <ul>
                        <li class="active">
                            <h5 class="title">
                                {t('global.list_act_reported')} <span class="fw-normal ms-2">({totalReport})</span>
                            </h5>
                            <div class="report-note">{t('global.list_act_reported_desc')}</div>
                            <div class="yours-v2__activity">
                                <ul class="activity-list">
                                    {listReport &&
                                        listReport.map((item) => {
                                            return (
                                                <ActivityItem
                                                    data={item}
                                                    key={item.atid}
                                                    type={ACTIVITY_TYPE.REPORT}
                                                    eventID={eventData.evid}
                                                />
                                            );
                                        })}
                                </ul>
                                <LoadMoreReport />
                            </div>
                        </li>
                    </ul>
                </div>
            );
        }

        function RenderComplain() {
            return (
                <div class="tabs-v2-content">
                    <ul>
                        <li class="active">
                            <h5 class="title">
                                {t('global.list_act_complained')} <span class="fw-normal ms-2">({totalComplain})</span>
                            </h5>
                            <div class="report-note">{t('global.list_act_complained_desc')}</div>
                            <div class="yours-v2__activity">
                                <ul class="activity-list fail">
                                    {listComplain &&
                                        listComplain.map((item) => {
                                            return (
                                                <ActivityItem
                                                    data={item}
                                                    key={item.atid}
                                                    type={ACTIVITY_TYPE.COMPLAIN}
                                                    eventID={eventData.evid}
                                                />
                                            );
                                        })}
                                </ul>
                                <LoadMoreComplain />
                            </div>
                        </li>
                    </ul>
                </div>
            );
        }

        return (
            <div class="manage-page">
                <div class="manage-wrap">
                    <div class="manage-box">
                        <div class="manage-box__header">
                            <h4 class="title">{t('team.manage.tab3')}</h4>
                        </div>
                        <div class="manage-box__body">
                            <div class="content">
                                <TabHeader tab={tab} onChangeTab={onChangeTab} />

                                {tab == 1 ? <RenderComplain /> : <RenderReport />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default ManagerComplain;
