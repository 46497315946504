import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const TabHeader = inject('authStore')(
    observer((props) => {
        const { tab } = props;
        useEffect(() => {}, []);
        const { t } = useTranslation();

        const TABS = [
            {
                id: 0,
                title: 'global.report'
            },
            {
                id: 1,
                title: 'global.complain'
            }
        ];

        const onChangeTab = (tab) => {
            props.onChangeTab(tab);
        };

        function TabItem(props) {
            const { data } = props;
            const cssClass = data.id == tab ? 'active' : '';

            return (
                <li class="menu-item" onClick={() => onChangeTab(data.id)}>
                    <a href="javascript:void(0);" class={`menu-link ${cssClass}`}>
                        {t(data.title)}
                    </a>
                </li>
            );
        }

        return (
            <div class="tabs-v2-menu tab-pills justify-content-center">
                <ul class="left">
                    {TABS.map((item) => {
                        return <TabItem data={item} key={item.id} />;
                    })}
                </ul>
            </div>
        );
    })
);

export default TabHeader;
