import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml } from '../../../../utils';
import { GROUP_ACTION } from '../../../../helpers/ConstDefined';

const ConfirmChangeLeaderDialog = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore'
)(
    observer((props) => {
        const { data, openDialogConfirmChangeLeader, navigation } = props;
        const { currentGroup, doActionGroup, doSetStatusJoinGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doSetFunc } = props.teamManageStore;
        const { currentEvent, doSetGroupRequestID, doSetGroupIDEvent } = props.eventStore;

        const { t } = useTranslation();

        useEffect(() => {}, []);

        async function onLeaveGroup() {
            const params = {
                uid: userProfile.uid,
                grid: currentGroup.grid,
                action: GROUP_ACTION.LEAVE
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                //check và remove grid in currentEvent nếu xóa chính nhóm đang join
                if (currentEvent.grid == currentGroup.grid) {
                    doSetGroupRequestID(0);
                    doSetGroupIDEvent(0);
                    doSetStatusJoinGroup(0);
                    currentGroup.mem -= 1;
                    doSetFunc(0); //back lại screen danh sách Group
                }
                if(navigation) navigation.goBack();
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        async function changeLeader() {
            const params = {
                uid: userProfile.uid,
                grid: currentGroup.grid,
                pfid: data.uid,
                action: GROUP_ACTION.CHANGE_OWNER
            };
            let result = await doActionGroup(params);
            if (result == true) {
                onLeaveGroup(); // Rời nhóm sau khi chọn nhóm trưởng thành công
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        let desc = t('team.group.leavedl.choise_leader.desc1');
        desc = desc.replace('[name]', data.name);
        desc = desc.replace('[group]', currentGroup.name);

        return (
            <Dialog open={openDialogConfirmChangeLeader}>
                <div
                    className="uprace-popup has-title in"
                    id="confirm-new-leader"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w800">
                            <div className="uprace-popup in-popup" style={{ display: 'block' }}>
                                <div className="popup-dialog">
                                    <div className="popup-content w380">
                                        <button type="button" className="popup-close" onClick={props.onClose}></button>
                                        <div className="popup-body">
                                            <div className="popup-team-notify small">
                                                <p className="avatar">
                                                    <img src={getImageResource(data.ava, true)} alt="" />
                                                </p>
                                                <p className="name">{t('team.group.leavedl.choise_leader.title1')}</p>
                                                <div className="txt">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: desc
                                                        }}></p>
                                                </div>
                                                <div className="popup-btns col-2 w140">
                                                    <button className="btn-44 blue" onClick={props.onClose}>
                                                        {t('global.seaagain')}
                                                    </button>
                                                    <button className="btn-44 cancel" onClick={() => changeLeader()}>
                                                        {t('global.confirm')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default ConfirmChangeLeaderDialog;
