import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, stripedHtml } from '../../../../utils';
import { GLOBAL_CONFIG, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import GroupJoinItem from './GroupJoinItem';
import DeleteGroupDialog from 'views/TeamDetails/components/GroupDetail/DeleteGroupDialog';
import _ from 'lodash';

let groupObj = null;

const GroupJoinList = inject(
    'globalStore',
    'groupStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { loadingGroup, totalGroupJoin, listGroupJoin, doGetListGroupJoin } = props.groupStore;
        const { userProfile } = props.userStore;

        const isShowLoadMore = listGroupJoin == null ? false : listGroupJoin.length < totalGroupJoin;
        const [search, setSearch] = React.useState('');

        const [openDialogDeleteGroup, setOpenDialogDeleteGroup] = React.useState(false);
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {
            getListGroupJoin();
        }, []);

        const { t } = useTranslation();

        const getListGroupJoin = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                name: search,
                gejsts: GROUP_JOIN_STATUS.JOINED //Đã tham gia
            };
            doGetListGroupJoin(params, isLoadMore);
        };

        async function onDeleteGroup(data) {
            groupObj = data;
            setOpenDialogDeleteGroup(true);
        }

        const getGroupJoinListMore = () => {
            getListGroupJoin(true);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getListGroupJoin();
            }
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupJoinListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                <h5 class="title">
                    {t('team.managegroup.group_join')}{' '}
                    <span class="fw-normal ms-2">({formatNumber(totalGroupJoin, true)})</span>
                </h5>

                <div class="frm-row">
                    <div class="frm-row__item search">
                        <input
                            type="text"
                            placeholder={t('global.search')}
                            value={search}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => setSearch(stripedHtml(text.target.value))}
                        />
                    </div>
                    <button class="btn btn-40 btn-blue" onClick={() => getListGroupJoin()}>
                        {t('global.search')}
                    </button>
                </div>

                <div class="manage-box__sub">
                    <div class="list-group">
                        {listGroupJoin &&
                            listGroupJoin.map((item) => {
                                return <GroupJoinItem data={item} key={item.id} onDeleteGroup={onDeleteGroup} />;
                            })}
                    </div>
                    <LoadMore />
                </div>
                {groupObj ? (
                    <DeleteGroupDialog
                        openDialogDeleteGroup={openDialogDeleteGroup}
                        data={groupObj}
                        onClose={() => setOpenDialogDeleteGroup(false)}
                        eventID={eventID}
                    />
                ) : null}
            </>
        );
    })
);

export default GroupJoinList;
