import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';
import { FOLLOW_STT, USER_EVENT_STATUS } from '../../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import { MemberName, MemberCode } from 'components/MemberName';

const MemberItem = inject(
    'userStore',
    'teamManageStore',
    'teamStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { isOwner, isAdmin, data, eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { doFollow, doLockUser } = props.teamManageStore;

        const { t } = useTranslation();

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        async function lockUser(status) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                evid: eventID,
                tmid: teamID,
                lsts: status
            };
            let result = await doLockUser(params);
        }

        if (isAdmin)
            return (
                <div class="item">
                    {data.fists == 2 ? (
                        userProfile.uid != data.uid ? (
                            <button className="btn-32 blue gray" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                {t('global.unfollow')}
                            </button>
                        ) : null
                    ) : userProfile.uid != data.uid ? (
                        <button className="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                            {t('global.follow')}
                        </button>
                    ) : null}
                    <div className="user">
                        <p className="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </p>
                        <div className="details">
                            <p className="name">{MemberName(data)}</p>
                            <p className="code">{MemberCode(data)}</p>
                        </div>
                    </div>
                </div>
            );
        else if (isOwner)
            return (
                <div className="user owner">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div className="details">
                        <p className="name">{MemberName(data)}</p>
                        <p className="code">{MemberCode(data)}</p>
                    </div>
                </div>
            );
        else
            return (
                <div className="item">
                    {data.fists == 2 ? (
                        userProfile.uid != data.uid ? (
                            <button className="btn-32 blue gray" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                {t('global.unfollow')}
                            </button>
                        ) : null
                    ) : userProfile.uid != data.uid ? (
                        <button className="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                            {t('global.follow')}
                        </button>
                    ) : null}

                    {data.evlsts == USER_EVENT_STATUS.LOCK ? (
                        <button className="lock-btn" onClick={() => lockUser(USER_EVENT_STATUS.UNLOCK)}>
                            {t('global.unlock')}
                        </button>
                    ) : (
                        <button className="lock-btn" onClick={() => lockUser(USER_EVENT_STATUS.LOCK)}>
                            {t('global.lock')}
                        </button>
                    )}

                    <div className="user">
                        <p className="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </p>
                        <div className="details">
                            <p className="name">{MemberName(data)}</p>
                            <p className="code">{MemberCode(data)}</p>
                        </div>
                    </div>
                </div>
            );
    })
);

export default MemberItem;
