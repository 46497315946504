import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';
import { STATS_TYPE, EVENT_LOCK_STATUS } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const YourActivity = inject(
    'authStore',
    'eventStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { eventUserStats, doGetEventStats } = props.eventStore;
        const { userProfile } = props.userStore;
        const { userTeam } = props.teamStore;

        const { t } = useTranslation();
        useEffect(() => {
            if (userProfile) getUserStats();
        }, [userProfile]);

        function getUserStats() {
            let data = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: STATS_TYPE.USER,
                value: userProfile.uid
            };
            doGetEventStats(data, STATS_TYPE.USER);
        }

        if (eventUserStats != null && userProfile != null && userTeam)
            return (
                <>
                    <div class="yours--info">
                        <div class="info-details">
                            <p class="avatar">
                                <img src={getImageResource(userProfile.ava, true, true)} alt="" />
                            </p>
                            <div class="details">
                                <p class="name">{userProfile.name}</p>
                                <p class="company">{userTeam && userTeam.tmnm}</p>
                            </div>
                        </div>
                        <div class="info-level">
                            <div class="box km-box">
                                <p class="num-box">
                                    <span class="num">{formatDistanceKM(eventUserStats.dis)}</span>
                                    <span class="unit">Km</span>
                                </p>
                            </div>
                            <div class="g-box">
                                <div class="box date-box">
                                    <p class="num">{formatNumber(eventUserStats.days)}</p>
                                    <p class="lb">{t('global.day_act')}</p>
                                </div>
                                <div class="box level-box">
                                    <p class="num">{formatNumber(eventUserStats.rank)}</p>
                                    <p class="lb">{t('global.rank_of_team')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {userTeam.lsts == EVENT_LOCK_STATUS.MUTED || userTeam.lsts == EVENT_LOCK_STATUS.BANNED ? (
                        <div class="fail-notify" style={{ paddingTop: 40, textAlign: 'center' }}>
                            <h3 style={{ color: 'red' }}>{t('global.lock_event_msg')}</h3>
                        </div>
                    ) : null}
                </>
            );
        else return null;
    })
);

export default YourActivity;
