import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { inject, observer } from 'mobx-react';
import { PRIVATE_KEY, API_STATUS } from '../../helpers/api/APIDefined';
import { GLOBAL_CONFIG, LOGIN_TYPE } from '../../helpers/ConstDefined';
import { LOGIN_MSG } from '../../helpers/MsgDefined';
import { KEY } from '../../helpers/api/APIDefined';
import { stripedHtml, validEmail } from '../../utils/index';
import md5 from 'blueimp-md5';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = inject(
    'authStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { user, doLogin, doVerify } = props.authStore;
        const { doGetProfileDetail } = props.userStore;
        const {
            doSetMainTab,
            setSnackbarType,
            setMessageSnackbar,
            onOpenSnackbar,
            onCloseSnackbar,
            openSnackbar,
            snackbarType,
            messageSnackbar
        } = props.globalStore;
        const { doGetUserTeam } = props.teamStore;

        const { t } = useTranslation();

        const [email, setEmail] = React.useState('');
        const [password, setPass] = React.useState('');
        //error
        const [errEmail, setErrEmail] = useState(false);
        const [errPass, setErrPass] = useState(false);

        const myRefname = useRef(null);

        useEffect(() => {
            verifyLogin();
            myRefname.current.click();
        }, []);

        const showClearEmail = email && email.length > 0;
        const showClearPass = password && password.length > 0;
        const handleClearEmail = () => {
            setEmail('');
        };
        const handleClearPass = () => {
            setPass('');
        };

        async function verifyLogin() {
            const userLocal = localStorage.getItem(KEY.CURRENT_USER);
            if (userLocal == null || userLocal == 'undefined') return;
            try {
                const userObj = JSON.parse(userLocal);
                const params = {
                    userId: userObj.userId
                };
                let result = await doVerify(params, userObj.accesstoken);
                if (result) getUserProfile(userObj.userId);
                else localStorage.removeItem(KEY.CURRENT_USER);
            } catch (error) {
                localStorage.removeItem(KEY.CURRENT_USER);
                props.history.push('./login');
            }
        }

        function logInFailure(msg) {
            setMessageSnackbar(msg);
            setSnackbarType('error');
            onOpenSnackbar();
        }

        async function responseFacebook(response) {
            console.log(response);
            const params = {
                type: LOGIN_TYPE.FACEBOOK,
                email: response.id,
                password: response.accessToken,
                lang: 'vi'
            };
            const extra = {
                email: response.email,
                name: response.name,
                openId: response.id
            };

            let result = await doLogin(params, extra);
            if (result == API_STATUS.NEED_INFO) {
                props.history.push('/update-profile');
            } else if (result && result.code) {
                getUserProfile(result.userId);
            } else if (result == null || result == 2000) {
                logInFailure(t('eu.login_fail_email_pass'));
            } else logInFailure(t('eu.login_fail'));
        }

        async function loginEmail() {
            if (validInput()) return;

            const params = {
                type: LOGIN_TYPE.EMAIL,
                email,
                password: md5(password),
                lang: 'vi'
            };
            let result = await doLogin(params);

            if (result == API_STATUS.NEED_INFO) {
                props.history.push('/update-profile');
            } else if (result && result.code) {
                getUserProfile(result.userId);
            } else if (result > 0) {
                logInFailure(t('eu.login_fail_email_pass'));
            } else {
                logInFailure(t('eu.login_fail'));
            }
        }

        async function responseGoogle(response) {
            console.log(response);
            try {
                const resp = response.profileObj;
                const params = {
                    type: LOGIN_TYPE.GOOGLE,
                    email: resp.email,
                    password: response.accessToken,
                    lang: 'vi'
                };
                const extra = {
                    email: resp.email,
                    name: resp.name,
                    openId: resp.email
                };
                let result = await doLogin(params, extra);
                if (result == API_STATUS.NEED_INFO) {
                    props.history.push('/update-profile');
                } else if (result && result.code) {
                    getUserProfile(result.userId);
                } else if (result == null) {
                    logInFailure(t('eu.login_fail'));
                }
            } catch (error) {
                console.log(error);
            }
        }

        //get user profile detail
        async function getUserProfile(userId) {
            let data = {
                uid: userId,
                pfid: userId
            };

            let result = await doGetProfileDetail(data);
            if (result != null) {
                doSetMainTab(0);
                doGetUserTeam(result, GLOBAL_CONFIG.EVENT_UPRACE_2021_ID);
                props.history.push(`/event`);
            } else {
                logInFailure(t('eu.login_fail'));
            }
        }

        function validInput() {
            let hasError = false;
            if (!validEmail(email)) {
                setErrEmail(true);
                hasError = true;
            } else setErrEmail(false);

            if (!password || password.length < 6) {
                setErrPass(true);
                hasError = true;
            } else setErrPass(false);
            return hasError;
        }

        return (
            <div class="outer">
                <div id="scrollContent" class="inner scrollContent scrollSwipe">
                    <section class="wrapper-login wrapper-custom-login profile-page  yours">
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            onClose={onCloseSnackbar}
                            open={openSnackbar}
                            autoHideDuration={1500}>
                            <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                                {messageSnackbar}
                            </Alert>
                        </Snackbar>

                        <div class="wrapper-user-card">
                            <div class="wrapper-head">
                                <h3 class="title">{t('login.welcome')}</h3>
                                <div class="wrapper-image-logo">
                                    <img src="../../images/register/logo-login.svg" class="brand_logo" alt="Logo" />
                                </div>
                            </div>

                            <div class="wrapper-content">
                                <div class="btn-group-social">
                                    <FacebookLogin
                                        appId={PRIVATE_KEY.FACEBOOK_APPID}
                                        callback={responseFacebook}
                                        fields="email, name, picture"
                                        autoLoad={false}
                                        disableMobileRedirect={true}
                                        scope="public_profile,email"
                                        render={(renderProps) => (
                                            <button
                                                className="btn btn-lg btn-social "
                                                type="submit"
                                                onClick={renderProps.onClick}>
                                                <span>
                                                    <img src="../../../img/facebook-blue.png" alt="" />
                                                </span>{' '}
                                                {t('login.facebook')}
                                            </button>
                                        )}
                                    />
                                    <GoogleLogin
                                        clientId={PRIVATE_KEY.GOOGLE_CLIENT_ID}
                                        buttonText="Login"
                                        render={(renderProps) => (
                                            <button
                                                className="btn btn-lg btn-social "
                                                type="submit"
                                                onClick={renderProps.onClick}>
                                                <span>
                                                    <img src="../../../img/gmail.png" alt="" />
                                                </span>{' '}
                                                {t('login.google')}
                                            </button>
                                        )}
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </div>

                                <p class="description">{t('login.email')}</p>

                                <form class="form-group-login" name="form1" id="form1">
                                    <div class="wrapper-input">
                                        <div class="form-label-group form-control">
                                            <input
                                                id="inputEmail"
                                                ref={myRefname}
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(text) => setEmail(stripedHtml(text.target.value))}
                                                required
                                                autoFocus={true}
                                                onFocus={(e) =>
                                                    e.currentTarget.setSelectionRange(
                                                        e.currentTarget.value.length,
                                                        e.currentTarget.value.length
                                                    )
                                                }
                                            />
                                            <label htmlFor="inputEmail" class="label-input-custom">
                                                Email
                                            </label>
                                            {showClearEmail ? (
                                                <img
                                                    style={{ cursor: 'pointer' }}
                                                    id="closeEmail"
                                                    src="../../../img/register/close.svg"
                                                    onClick={() => handleClearEmail()}
                                                    className="close-text"
                                                />
                                            ) : null}
                                            {errEmail ? (
                                                <div className="wrapper-error">
                                                    <p className="error-message">{t('global.error.valid_email')}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div class="form-label-group form-control">
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                type="password"
                                                id="password"
                                                className="form-control"
                                                placeholder={t('login.password')}
                                                value={password}
                                                onChange={(text) => setPass(stripedHtml(text.target.value))}
                                                required
                                                //autoFocus
                                            />
                                            <label htmlFor="password" className="label-input-custom">
                                                {t('login.password')}
                                            </label>
                                            {showClearPass ? (
                                                <img
                                                    id="closePass"
                                                    src="../../../img/register/close.svg"
                                                    onClick={() => handleClearPass()}
                                                    className="close-text"
                                                />
                                            ) : null}
                                            {errPass ? (
                                                <div className="wrapper-error">
                                                    <p className="error-message">{t('global.error.valid_pass')}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div className="btn btn-primary btn-confirm" onClick={() => loginEmail()}>
                                            {t('login.login')}
                                        </div>
                                        <Link className="link" to="/forgot-pass">
                                            {t('login.forget_pass')}
                                        </Link>
                                        <Link className="link" to="/register">
                                            {t('login.register')}
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    })
);

export default Login;
