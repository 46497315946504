import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
    YourActivity,
    ListActivity,
    ListActivityInvalid,
    AchievementPersonal,
    AchievementCompare,
    SocialOrganizationUser,
    CertEvent
} from './components';
import { isJWTValid, stripedHtml } from 'utils';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
const queryString = require('query-string');

const YourInfo = inject(
    'eventStore',
    'userStore',
    'globalStore',
    'teamStore',
    'challengeStore'
)(
    observer((props) => {
        const { currentEvent } = props.eventStore;
        const { func, userProfile, doSetFunc } = props.userStore;
        const { doSetMainTab, doSetTabMainTop } = props.globalStore;
        const { userTeam } = props.teamStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);

        useEffect(() => {
            window.scrollTo(0, 0);
            doSetTabMainTop(0);
            doSetFunc(0);
            doSetMainTab(2); //tab you
            if (!isJWTValid()) props.history.push('/login');
        }, []);

        function RenderYour() {
            if (userProfile)
                return (
                    <div class="yours-page">
                        <YourActivity eventID={eventID} />
                        {currentEvent && currentEvent.cert == true ? <CertEvent /> : null}
                        {currentEvent ? <SocialOrganizationUser /> : null}
                        {currentEvent ? <AchievementPersonal eventID={eventID} endDate={currentEvent.entm} /> : null}
                        {currentEvent ? <AchievementCompare eventID={eventID} endDate={currentEvent.entm} /> : null}
                        <ListActivity eventID={eventID} />
                    </div>
                );
            else return null;
        }

        if (func == 0) return <RenderYour />;
        else if (func == 1) return <ListActivityInvalid eventID={eventID} />;
    })
);

export default YourInfo;
