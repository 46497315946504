module.exports = {
    TEAM_MSG: {
        LEAVE_TEAM_SUCCESS: 'Rời đội thành công',
        LEAVE_TEAM_FAIL: 'Rời đội thất bại',
        LEAVE_TEAM_FAIL_OWNER: 'Không thể rời đội vì bạn là chủ đội',
        LEAVE_TEAM_FAIL_EVENT_READY: 'Bạn không thể rời đội trong thời gian sự kiện diễn ra',
        LEAVE_TEAM_FAIL_EVENT_FINISH: 'Bạn không thể rời đội khi sự kiện đã kết thúc',
        LEAVE_TEAM_EVENT_CONFIRM_TEXT: 'Bạn đã chọn đội này để tham gia sự kiện, bạn có muốn tiếp tục rời đội không?',
        JOIN_TEAM_SUCCESS: 'Tham gia đội thành công',
        JOIN_TEAM_FAIL: 'Tham gia đội không thành công',
        CREATE_TEAM_SUCCESS: 'Tạo đội thành công',
        CREATE_TEAM_FAIL: 'Tạo đội thất bại',
        UPDATE_TEAM_SUCCESS: 'Cập nhật đội thành công',
        UPDATE_TEAM_FAIL: 'Cập nhật đội thất bại',
        CHANGE_TEAM_FAIL: 'Thay đổi đội không thành công',
        INVALID_INPUT: {
            COVER: 'Vui lòng chọn hình nền',
            AVATAR: 'Vui lòng chọn hình avatar',
            NAME: 'Vui lòng nhập tên đội',
            DESC: 'Vui lòng nhập mô tả đội của bạn',
        }
    },
    EVENT_MSG: {
        EVENT_READY: 'Sự kiện đang diễn ra',
        EVENT_FINISHED: 'Sự kiện đã kết thúc'
    },
    LOGIN_MSG: {
        USER_NOT_FOUND: 'Email hoặc mật khẩu không chính xác',
        LOGIN_FAIL: 'Đăng nhập không thành công',
        //'Đăng nhập không thành công. Nếu bạn quên mật khẩu, vui lòng bấm "Quên mật khẩu" để lấy lại mật khẩu đăng nhập mới'
    },
    USER_MSG: {
        UPDATE_PROFILE_EVENT_READY:  'Bạn không thể đổi Tên và Giới tính trong thời gian sự kiện diễn ra'
    }
}