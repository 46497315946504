import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { getUUID, getTimestamp } from '../../utils';
import { KEY, API_STATUS, API, HTTP_METHOD } from '../../helpers/api/APIDefined';
import { requestAPIBody, requestAPIV2 } from '../../helpers/api/APIHelper';
import { stripedHtml } from '../../utils';
const queryString = require('query-string');

const RouteWithLayout = inject(
    'authStore',
    'userStore',
    'teamStore'
)(
    observer((props) => {
        const { layout: Layout, component: Component, roles, login, ...rest } = props;
        const { userProfile, doSetUserProfile } = props.userStore;
        const { doGetUserTeam } = props.teamStore;

        const userLocal = localStorage.getItem(KEY.CURRENT_USER);

        let paramsQuery = queryString.parse(props.location.search);
       
        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    //check and valid profile user if router must be login for access
                    //only request api get profile when refresh browser
                   
                    if (userProfile == null) {
                        if (userLocal != null && userLocal != 'undefined') {
                            try {
                                const userObj = JSON.parse(userLocal);
                                let params = {
                                    userId: userObj.userId
                                };
                                requestAPIBody(API.AUTHEN.VERIFY, params, HTTP_METHOD.POST).then((resp) => {
                                    if (resp && resp.code == API_STATUS.FETCH_SUCCESS) {
                                        //get profile
                                        const data = {
                                            uid: userObj.userId,
                                            pfid: userObj.userId
                                        };

                                        requestAPIV2(API.USER.GET_PROFILE, data, HTTP_METHOD.POST)
                                            .then((resp) => {
                                                if (resp.ercd != API_STATUS.NO_ERROR) {
                                                    return (
                                                        <Redirect
                                                            to={{
                                                                pathname: '/login',
                                                                state: { from: props.location }
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    doSetUserProfile(resp.data);
                                                    doGetUserTeam(resp.data, stripedHtml(paramsQuery.evid));
                                                }
                                            })
                                            .catch((error) => {
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/login',
                                                            state: { from: props.location }
                                                        }}
                                                    />
                                                );
                                            });
                                    } else {
                                        return (
                                            <Redirect
                                                to={{
                                                    pathname: '/login',
                                                    state: { from: props.location }
                                                }}
                                            />
                                        );
                                    }
                                });
                            } catch (error) {
                                return (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: props.location }
                                        }}
                                    />
                                );
                            }
                        }
                    }

                    return (
                        <Layout>
                            <Component {...matchProps} />
                        </Layout>
                    );
                }}
            />
        );
    })
);

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default RouteWithLayout;
