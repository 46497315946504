import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FEED_TYPE, SOCIAL_TAB, SOCIAL_TYPE, ACTIVITY_TYPE } from '../../../../helpers/ConstDefined';
import {
    ActivityItem,
    DialogDeleteActivity,
    DialogEditActivity,
    DialogSocial,
    DialogViewImage
} from '../../../YourInfo/components';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils';

let activity;

const ListActivity = inject(
    'authStore',
    'userStore',
    'globalStore',
    'userProfileStore'
)(
    observer((props) => {
        const { listFeed, loadingFeed, totalFeed, userProfile, doGetListFeed } = props.userStore;

        const { profileID, eventID } = props;
        const [openDialogDeleteActivity, setOpenDialogDeleteActivity] = React.useState(false);
        const [openDialogEditActivity, setOpenDialogEditActivity] = React.useState(false);
        const [openDialogViewImage, setOpenDialogViewImage] = React.useState(false);

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const { appVersion } = props.globalStore;
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);
        const isShowLoadMore = listFeed == null ? false : listFeed.length < totalFeed;

        useEffect(() => {
            if (userProfile) getUserFeed();
        }, [userProfile, profileID]);

        const { t } = useTranslation();

        const onViewImage = (act) => {
            activity = act;
            setOpenDialogViewImage(true);
        };

        function onViewDialogSocial(act, type) {
            activity = act;
            onOpenDialogSocial(type);
        }

        const onDeleteActivity = (act) => {
            activity = act;
            setOpenDialogDeleteActivity(true);
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        const onEditActivity = (act) => {
            activity = act;
            setOpenDialogEditActivity(true);
        };

        function getUserFeed(isLoadMore) {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.YOUR_ACTIVITY,
                value: profileID
            };
            doGetListFeed(params, isLoadMore);
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingFeed)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getUserFeed(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div class="profile-wbox activity">
                <h2 class="profile-title">
                    {t('global.act')} <span class="num">({formatNumber(totalFeed)})</span>
                </h2>
                <div class="yours--activity">
                    <div class="activity-list">
                        <ul>
                            {listFeed != null &&
                                listFeed.map((item) => (
                                    <ActivityItem
                                        key={item.atid}
                                        data={item}
                                        eventID={eventID}
                                        type={ACTIVITY_TYPE.ALL}
                                        onViewDialogSocial={onViewDialogSocial}
                                        onDelete={onDeleteActivity}
                                        onViewFeedDetail={() => onViewFeedDetail(item, true)}
                                        onViewImage={onViewImage}
                                        onEdit={onEditActivity}></ActivityItem>
                                ))}
                        </ul>
                        <LoadMore />
                    </div>
                </div>
                {activity && openDialogDeleteActivity ? (
                    <DialogDeleteActivity
                        openDialogDeleteActivity={openDialogDeleteActivity}
                        data={activity}
                        type={ACTIVITY_TYPE.VALID}
                        onClose={() => setOpenDialogDeleteActivity(false)}
                    />
                ) : null}
                {activity && openDialogEditActivity ? (
                    <DialogEditActivity
                        data={activity}
                        openDialogEditActivity={openDialogEditActivity}
                        onClose={() => setOpenDialogEditActivity(false)}
                    />
                ) : null}
                {openDialogSocial ? (
                    <DialogSocial
                        openDialogSocial={openDialogSocial}
                        onClose={() => setOpenDialogSocial(false)}
                        data={activity}
                        type={SOCIAL_TYPE.FEED}
                        tab={tabSocial}
                    />
                ) : null}
                {activity && openDialogViewImage ? (
                    <DialogViewImage
                        data={activity}
                        openDialogViewImage={openDialogViewImage}
                        onClose={() => setOpenDialogViewImage(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default ListActivity;
