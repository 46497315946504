import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Banner = inject('userEventStore')(
    observer((props) => {
        const { listBanner, doGetListBanner } = props.userEventStore;

        useEffect(() => {
            getListBanner();
        }, []);

        const getListBanner = () => {
            const params = {};
            doGetListBanner(params);
        };

        const BannerItem = (props) => {
            const { data } = props;
            return (
                <div>
                    <img src={data.img} />
                </div>
            );
        };

        return (
            <div class="users-event-info" style={{display: 'none'}}>
                <div class="users-event--banner">
                    <Carousel autoPlay showArrows={false} showThumbs={false} infiniteLoop={true}>
                        {listBanner.map((item) => {
                            return <BannerItem data={item} key={item.bnid} />;
                        })}
                    </Carousel>
                </div>
            </div>
        );
    })
);

export default Banner;
