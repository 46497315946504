import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../../../../utils';
import { GLOBAL_CONFIG, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import MemberRequestItem from './MemberRequestItem';
import _ from 'lodash';

let groupObj = null;

const MemberRequestList = inject(
    'userStore',
    'userEventStore',
    'eventStore',
    'globalStore'
)(
    observer((props) => {
        const { eventData } = props;
        const { userProfile } = props.userStore;
        let { loading, listMemberRq, totalMemberRq, doGetListMemberRqEvent, doApproveMemberAll } = props.userEventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const isShowLoadMore = listMemberRq == null ? false : listMemberRq.length < totalMemberRq;

        useEffect(() => {
            getListMemberRequest();
        }, []);

        const { t } = useTranslation();

        const getListMemberRequest = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile.uid,
                revid: eventData.evid
            };
            doGetListMemberRqEvent(params, isLoadMore);
        };

        function getListIDMemberRequest() {
            let list = [];
            _.forEach(listMemberRq, (item) => {
                list.push(item.uid);
            });
            return list;
        }

        async function approveAllMember() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                uids: getListIDMemberRequest()
            };
            let result = await doApproveMemberAll(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                getListMemberRequest();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const getListMemberRequestMore = () => {
            getListMemberRequest(true);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListMemberRequestMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function ApproveAllButton() {
            if (totalMemberRq > 0) {
                if (loading) return <button className="btn btn-40 btn-blue btn-approve-all loading"></button>;
                else
                    return (
                        <>
                            <button
                                className="btn btn-40 btn-blue btn-approve-all"
                                onClick={() => approveAllMember()}>
                                {t('team.managegroup.approve_all')}
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="manage-groups">
                <li class="active">
                    <div class="pending-approval__title">
                        <h5 class="title">
                            {t('eu.list_member_wait_approve')}
                            <span class="fw-normal ms-2">({formatNumber(totalMemberRq, true)})</span>
                        </h5>
                        <ApproveAllButton />
                    </div>

                    <div class="manage-box__sub">
                        <div class="list-group">
                            {listMemberRq &&
                                listMemberRq.map((item) => {
                                    return <MemberRequestItem data={item} key={item.id} eventID={eventData.evid} />;
                                })}
                        </div>

                        <LoadMore />
                    </div>
                </li>
            </div>
        );
    })
);

export default MemberRequestList;
