import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { ACTIVITY_TYPE } from '../helpers/ConstDefined';
import { log, formatDate } from '../utils';
import _ from 'lodash';

export default class ReportStore {
    @observable loading = false;

    @observable listReport = null;
    @observable pageReport;
    @observable totalReport;

    @observable listComplain = null;
    @observable pageComplain;
    @observable totalComplain;

    @observable isViewDetail = null;

    @action.bound
    doSetViewDetail(val)
    {
        this.isViewDetail = val;
    }
    
    //action
    @action.bound
    async doAction(params, type) {
        let result = null;
        await requestAPIV2(API.FEED.ACTION, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    result = true;
                    //update local
                    if (type == ACTIVITY_TYPE.COMPLAIN) {
                        this.totalComplain -= 1;
                        _.remove(this.listComplain, (item) => {
                            return item.atid == params.atid;
                        });
                    } else if (type == ACTIVITY_TYPE.REPORT) {
                        this.totalReport -= 1;
                        _.remove(this.listReport, (item) => {
                            return item.atid == params.atid;
                        });
                    }
                }
            })
            .catch((error) => {
                
                result = null;
            });
        return result;
    }

    @action.bound
    doGetListReport(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageReport += 1;
            params.from = params.size * this.pageReport;
        } else {
            this.pageReport = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    this.totalReport = resp.data.total;
                    if (isLoadMore) this.listReport = this.listReport.concat(resp.data.list);
                    else this.listReport = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    @action.bound
    doGetListComplain(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageComplain += 1;
            params.from = params.size * this.pageComplain;
        } else {
            this.pageComplain = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.totalComplain = resp.data.total;
                    if (isLoadMore) this.listComplain = this.listComplain.concat(resp.data.list);
                    else this.listComplain = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }
}
