import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { TeamList, UserTeamDetail } from './components';
import { isJWTValid, stripedHtml } from 'utils';
const queryString = require('query-string');
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

const UserTeam = inject(
    'authStore',
    'teamStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { userTeam, currentTeam } = props.teamStore;
        const { doSetMainTab, doSetIsTeamSearch } = props.globalStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);

        const handleJoinTeamSuccess = () => {
            //props.history.push('/team');
        };

        useEffect(() => {
            window.scrollTo(0, 0);
            doSetMainTab(1);
            doSetIsTeamSearch(false);
        }, []);

        if (userTeam == null || userTeam.tmid == 0)
            return (
                <>
                    <TeamList
                        onJoinTeamSuccess={() => handleJoinTeamSuccess()}
                        navigation={props.history}
                        eventID={eventID}
                    />
                </>
            );
        else return <Redirect to={`/team-details?evid=${eventID}&tmid=${userTeam.tmid}`} />;
    })
);

export default UserTeam;
