import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ChoiseSODialog from './ChoiseSODialog';
import { toJS } from 'mobx';

let soData;

const SocialOrganizationUser = inject('eventStore')(
    observer((props) => {
        const { data } = props;
        const { listSO } = props.eventStore;

        const [openDialogChoiseSO, setOpenDialogChoiseSO] = React.useState(false);

        useEffect(() => {}, []);
        const { t } = useTranslation();

        function RenderItem(props) {
            const { data } = props;
            return (
                <li>
                    <div class="list-box">
                        <p class="logo">
                            <img src={data.ava} alt="" />
                        </p>
                        <p class="name">{data.name}</p>
                        <p class="btn-row">
                            <button class="btn-40 blue" onClick={() => props.onChoise(data)}>
                                {t('global.select')}
                            </button>
                        </p>
                    </div>
                </li>
            );
        }

        const onChoise = (data) => {
            soData = data; //dư liệu tổ chức xã hội user chọn quyên góp
            setOpenDialogChoiseSO(true);
        };

        return (
            <div class="info--tcxh">
                <h2 class="info--title">{t('landing_page.org.title')}</h2>
                <p class="lb">{t('global.not_choise_so')}</p>
                <p class="note">{t('global.not_choise_so_desc')}</p>
                <ul class="list">
                    {listSO &&
                        listSO.map((item) => {
                            return <RenderItem data={item} key={item.fuid} onChoise={onChoise} />;
                        })}
                </ul>
                {soData ? (
                    <ChoiseSODialog
                        data={soData}
                        openDialogChoiseSO={openDialogChoiseSO}
                        onClose={() => setOpenDialogChoiseSO(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default SocialOrganizationUser;
