import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';
import Image from 'react-graceful-image';

const ImageItem = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { imgPath } = props;

        if (!imgPath || imgPath == '') return null;

        return (
            <div className="img">
                <div className="img-inside">
                    <button className="del-btn" onClick={props.removeImage}></button>
                    <Image src={getImageResource(imgPath, false, false, true)} alt="" />
                </div>
            </div>
        );
    })
);

export default ImageItem;
