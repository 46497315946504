import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../utils';
import { useTranslation } from 'react-i18next';
import { MemberName } from 'components/MemberName';

const GroupRankItem = inject('authStore')(
    observer((props) => {
        const { data, rank } = props;
        const { t } = useTranslation();
        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';

        return (
            <li onClick={() => props.onViewUserEvent(data)}>
                <a href="javascript:void(0);">
                    <div class="level">{data.rank}</div>
                    <div class="logo">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </div>
                    <div class="name-box">
                        <p class="name">{MemberName(data)}</p>
                        {data.diff != 0 ? <p className={cssRankArrow}>{Math.abs(data.diff)}</p> : null}
                    </div>
                    <div class="date">{formatDistanceKM(data.ddis)}</div>
                    <div class="sum">{formatDistanceKM(data.dis)}</div>
                </a>
            </li>
        );
    })
);

export default GroupRankItem;
