import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../helpers/ConstDefined';
import { MemberName, MemberCode } from 'components/MemberName';

const MemberRequestJoinItem = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID, teamID } = props;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        const actionMembrerRequest = (action) => {
            const params = {
                action,
                uid: data.uid
            };
            props.actionMembrerRequest(params);
        };

        return (
            <li>
                <div className="item">
                    <div className="user">
                        <p className="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </p>
                        <div className="details">
                            <p className="name">{MemberName(data)}</p>
                            <p className="code">{MemberCode(data)}</p>
                            <div className="btns-group">
                                <button
                                    className="btn-32 blue-light"
                                    onClick={() => actionMembrerRequest(GROUP_ACTION.ADMIN_REJECT_JOIN)}>
                                    {t('global.reject')}
                                </button>
                                <button
                                    className="btn-32 blue"
                                    onClick={() => actionMembrerRequest(GROUP_ACTION.ADMIN_APPROVE_JOIN)}>
                                    {t('global.approve')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    })
);

export default MemberRequestJoinItem;
