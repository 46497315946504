import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const EmptyGroupDialog = inject('globalStore')(
    observer((props) => {
        let { data, navigation } = props;
        const { openDialogEmptyGroup } = props;

        const { t } = useTranslation();

        const onCreateGroup = () => {
            props.onCreateGroup();
        };

        return (
            <Dialog open={openDialogEmptyGroup}>
                <div
                    class="uprace-popup group-event-challenges-popup has-title in"
                    id="create-group"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <div class="popup-header">
                                <h4 class="title">{t('eu.dl_emptygroup_title')}</h4>
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="popup-body">
                                <div class="group-create-box">
                                    <div class="thumbnail">
                                        <img
                                            src="../../images/imgs/create_group.png"
                                            class="thumbnail-item"
                                            alt="icon"
                                        />
                                    </div>
                                    <div class="txt-title">{t('team.group.no_group')}</div>
                                    <div class="txt">{t('eu.empty_group_ph')}</div>
                                    <button class="btn-40 btn-blue btn-add" onClick={() => onCreateGroup()}>
                                        {t('global.reate_group_now')}
                                    </button>
                                </div>
                            </div>
                            <div class="popup-footer justify-content-end">
                                <button
                                    class="btn-40 btn-outline-blue btn-close"
                                    data-dismiss="modal"
                                    onClick={props.onClose}>
                                    {t('global.destroy')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default EmptyGroupDialog;
