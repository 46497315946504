import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ReasonJoin = inject('authStore')(
    observer((props) => {
        const { data } = props;
        const { t, i18n } = useTranslation();

        useEffect(() => {}, []);

        return (
            <section className="section section--3 ">
                <div className="container">
                    <h1 className=" space-20" dangerouslySetInnerHTML={{ __html: t('landing_page.reason.title') }}></h1>
                    <div className="flex align-item-center benefit">
                        <div className="swiper-container swiper-benefit-thum">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <a className="swiper-slide-thum active" data-target="0">
                                        <p className="bold " dangerouslySetInnerHTML={{ __html: t('landing_page.reason.l1') }}></p>
                                    </a>
                                </div>
                                <div className="swiper-slide">
                                    <a className="swiper-slide-thum" href="#" data-target="1">
                                        <p className="bold " dangerouslySetInnerHTML={{ __html: t('landing_page.reason.l2') }}></p>
                                    </a>
                                </div>
                                <div className="swiper-slide">
                                    <a className="swiper-slide-thum" href="#" data-target="2">
                                        <p className="bold " dangerouslySetInnerHTML={{ __html: t('landing_page.reason.l3') }}></p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-container swiper-benefit">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img alt="" data-src="images/benefit/pic1.jpg" className=" lazyload" />
                                </div>
                                <div className="swiper-slide">
                                    <img alt="" data-src="images/benefit/pic2.jpg" className=" lazyload" />
                                </div>
                                <div className="swiper-slide">
                                    <img alt="" data-src="images/benefit/pic3.jpg" className=" lazyload" />
                                </div>
                                <div className="swiper-slide">
                                    <img alt="" data-src="images/benefit/pic4.jpg" className=" lazyload" />
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination f3-pagination "></div>
                        <div className="swiper-button-prev f3-back "></div>
                        <div className="swiper-button-next f3-next "></div>
                    </div>
                </div>
            </section>
        );
    })
);

export default ReasonJoin;
