import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import ListMemberRequest from './ListMemberRequest';
import ListMemberJoined from './ListMemberJoined';
import TabHeader from './TabHeader';

const ManageMemberGroupDialog = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, openDialogManageMember, eventID, teamID } = props;

        const [tab, setTab] = React.useState(0);

        const { t } = useTranslation();

        const onChangeTab = (tab) => {
            setTab(tab);
        };

        const onCloseDialog = () => {
            setTab(0);
            props.onClose();
        };

        return (
            <Dialog open={openDialogManageMember}>
                <div
                    className="uprace-popup has-title in"
                    id="group-manage-1"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w800">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => onCloseDialog()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('global.manage_member')}</h2>
                            <div className="popup-body">
                                <div className="popup-manage-group">
                                    <TabHeader onChangeTab={onChangeTab} />

                                    <div className="tabs-content g-tabs-content">
                                        <ul>
                                            <li className="active">
                                                {tab == 0 ? (
                                                    <ListMemberRequest data={data} eventID={eventID} teamID={teamID} />
                                                ) : (
                                                    <ListMemberJoined data={data} />
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default ManageMemberGroupDialog;
