import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const OwnerJoin = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore',
)(
    observer((props) => {
        useEffect(() => {}, []);

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        function requestHandleLeaveTeam(){
            props.requestHandleLeaveTeam();
            setAnchorEl(null);
        };

        return (
            <div className="wrapper-action-top">
                <div className="form-control custom-wrapper-btn">
                    <button
                        id="btnJoinTeam"
                        type="button"
                        data-toggle="dropdown"
                        onClick={handleOpenMenu}
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="btn btn-joined btn-action-team">
                        <span>Đã tham gia</span>
                        <i className="fa fa-caret-down icon-custom" aria-hidden="true"></i>
                    </button>
                    <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}>
                        <MenuItem onClick={requestHandleLeaveTeam}>
                            <a className="dropdown-item">
                                <i className="fa fa-power-off"></i> Rời đội
                            </a>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        );
    })
);
export default OwnerJoin;
