import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';
const queryString = require('query-string');

const RuleEvent = inject('globalStore')(
    observer((props) => {
        const { eventID } = props;
        const { language, doSetTabMainTop } = props.globalStore;
        let paramsQuery = queryString.parse(props.location.search);

        useEffect(() => {
            doSetTabMainTop(null);
            window.scrollTo(0, 0);
        }, []);

        function RuleUprace() {
            if (language == 'en') return <RuleUpraceEN />;
            else return <RuleUpraceVI />;
        }

        function RuleUpraceEN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>UPRACE 2022</p>
                        <p>PARTICIPATION REGULATION</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Definition</h3>
                            <div class="list-desc">
                                UpRace 2021 (hereinafter referred to as "event") is a FREE community running event for
                                everyone on the web/mobile UpRace. Participation (hereinafter referred to as "runner")
                                are free to run at arbitrary time and place and actively record the results of running
                                on mobile devices (mobile phones, sports watches ...) with target accumulates as much
                                running distance as possible.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Time for event registration opening:</strong> starting from 00:00 10th
                                        October 2021, runners can continue to register and join Teams during the event.
                                    </li>
                                    <li>
                                        <strong>Competition time:</strong> from from 00:00 31/10/2021 to 23:59
                                        21/11/2021 (22 days)
                                    </li>
                                    <li>
                                        <strong>Vietnamese time (GMT + 7)</strong> is used as the standard time zone for
                                        the event.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Registration</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        Runner downloads the UpRace app on their mobile phones HERE and register an
                                        account. The runners who participated in the 2020 event can log in their existed
                                        account. (Watch tutorial video)
                                    </li>
                                    <li>
                                        Runners click to join the Team that you want to choose. Each runner's account
                                        can only select one Team to compete in the event. The runners are not allowed to
                                        change the Team since the event starts until the event ends.
                                    </li>
                                    <li>
                                        The runners chose to donate funds to a Social Organization of their choice. Each
                                        runner's account can only choose one Social Organization to raise funds for. The
                                        runners are not allowed to change their chosen Social Organization during the
                                        course of the event. There are 4 social organizations for athletes to choose
                                        from including:
                                        <p>- Newborns Vietnam</p>
                                        <p>- Operation Smile Vietnam</p>
                                        <p>- Green Viet</p>
                                        <p>- Saigon Children's Charity</p>
                                    </li>
                                    <li>
                                        The runners, who participate in the event after the event starts, will be able
                                        to join normally.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Team's Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Teams are placed in 3 league tables including:</p>
                                        <p>- Table 1: Company Teams</p>
                                        <p>- Table 2: Sports Club Teams</p>
                                        <p>- Table 3: School Teams</p>
                                    </li>
                                    <li>
                                        Teams in the same Table will compete against each other and rank based on the
                                        total running distance by each team.
                                    </li>
                                    <li>
                                        Teams, which are established after the event starts, still participate in the
                                        competition normally.
                                    </li>
                                    <li>
                                        Each team can create their own groups to compete in an internal ranking. The
                                        competition among groups will also be complied with “Runners competition rules”
                                        under section 5. There will be no UpRace prize for group competition. Watch
                                        tutorial HERE to learn how to create a group and groups ranking.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Runners Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Record achievements:</strong> The runners record their achievements
                                        every runs by mobile device (phone, watch with GPS function ...).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Valid race:</strong> is counted when committing the following
                                            conditions:
                                        </p>
                                        <p>
                                            - Recorded as completed and displayed on the runner' s UpRace account within
                                            72 hours from the activity's start time and no later than the last day of
                                            the event.
                                        </p>
                                        <p>
                                            - Express the map clearly with no sign of critical errors due to the error
                                            of catching GPS signal of mobile devices. The activities which are imported
                                            manual results, either shared by others, or recorded for different accounts
                                            will not be validated; In case of running on treadmils, the result must be
                                            recorded by fitness tracker or smart watch like Garmin; which is able to
                                            synchronize with UpRace.
                                        </p>
                                        <p>
                                            - Activities that are duplicated by recording from multiple devices at a
                                            same time will be counted as one activity. The priority order of activity’s
                                            source will be: 1. UpRace; 2. Garmin; 3. Strava.
                                        </p>
                                        <p>
                                            - There is a minimum distance of 1km. There is an unlimited maximum limit.
                                            Runners are allowed to pause the activity while running with the purpose of
                                            short break;
                                        </p>
                                        <p>
                                            - Average Pace maximum is 4:00 minutes/km and minimum is 15:00 minutes/km.
                                            Split pace of each 1 km must not be faster than 3:30 minutes/km
                                        </p>
                                        <p>
                                            - Runs that take place on Sundays and one special day 11/11/2021 (used
                                            UpRace app) during the event period must be completed before 24:00 on that
                                            day and double-result will be counted on the achievement of that day.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Complete the contest:</strong> A runner will be certified to
                                            complete the contest when both of the following conditions are reached
                                        </p>
                                        <p>- Must achieve a minimum number of 12 valid days, and</p>
                                        <p>
                                            - Must achieve a minimum distance of 60km (including double achievement on
                                            Sundays and on November 11th).
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Valid days:</strong> is the date of at least one valid run according to
                                        the above rule.
                                    </li>
                                    <li>
                                        <strong>Achievement date:</strong> The achievement will be counted on the day
                                        when activity starts.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Prize</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Individual Prizes:</strong>
                                        </p>
                                        <p>
                                            All runners who are qualified to finish the competition will receive a
                                            certificate of completion of the competition (electronic version) according
                                            to the completed km milestones including: 60km, 150km, 300km respectively
                                            with the titles: Pro runner, Hero runner and Angel runner.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Achievement Award for Team:</strong>
                                        </p>
                                        <p>
                                            10 teams with the best overall performance in each league table will receive
                                            the Team reward. The Team reward includes UpRace Winning Team 2020 T-shirts,
                                            which will be given to:
                                        </p>
                                        <p>+ Eligible UpRace’s athletes who complete the competition</p>
                                        <p>+ Members of the winning teams, according to the following table:</p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Company</th>
                                                <th>Sport club</th>
                                                <th>School</th>
                                            </tr>
                                            <tr>
                                                <td>Rank 1</td>
                                                <td>Top 300 runners</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 2</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 3</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 4 and 5</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 6,7,8,9 and 10</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>
                                                In addition, the team with the highest achievement in each group will
                                                receive
                                            </strong>
                                        </p>
                                        <p>
                                            + The prize of VND 100 million is used to donate to 01 UpRace beneficiary
                                            that the team nominates,{' '}
                                        </p>
                                        <p> + Certificate of achievement of the winning team (electronic version)</p>
                                        <p>
                                            {' '}
                                            + Honoring post for the winning team on UpRace fanpage & 1 PR honoring post
                                            for 3 teams together.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Fundraising for the community</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Origin of funds</strong>
                                        </p>
                                        <p>
                                            - From donors: sponsors will donate 1000 VND/1km of valid run from runners.
                                        </p>
                                        <p>
                                            - From runners: The runners are encouraged to donate to their own
                                            achievements or from other runners. The minimum level of donation is VND
                                            1,000/1km of their valid run or of other runners.
                                        </p>
                                        <p>
                                            - Runners are also encouraged to call fans or other runners donate their
                                            running achievement. Note: this is NOT a mandatory condition for runners.
                                        </p>
                                        <p>
                                            - From fans: Fans are encouraged to participate in donating the run
                                            achievement of the runner that they want to cheer. The minimum donation
                                            amount is 1,000 VND/1km.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Use of funds:</strong> All UpRace's funds will be transferred to social
                                        organizations.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Rights of Organizing Committee and Technical Council</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        Organizing Committee's Right: The Organizer has full authority to decide whether
                                        a team and runners are eligible to compete or not, to stop the match and cancel
                                        the results of the team and runners in case of fraud or non-compliance with the
                                        rules contests and the right to adjudicate complaints. The Organizing Committee
                                        has many plans to prevent and detect frauds if any, and call runners to show the
                                        spirit of fairplay and self-awareness. When any fraud is detected, the runner
                                        will be disqualified from the competition and not recognized for all
                                        achievements.
                                    </li>
                                    <li>
                                        Team Administration Board’s Right: Administration board, who are the owners and
                                        moderator, of each team has full authority to decide whether a team member is
                                        eligible to compete or not, to stop and cancel the result of a member in case of
                                        fraud or non-compliance with the rules contest and the right to adjudicate
                                        complaints from team members.
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        function RuleUpraceVI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>UPRACE 2022</p>
                        <p>ĐIỀU LỆ THAM GIA</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Định nghĩa</h3>
                            <div class="list-desc">
                                UpRace 2021 (sau đây gọi tắt là “sự kiện") là một sự kiện chạy bộ cộng đồng MIỄN PHÍ
                                dành cho tất cả mọi người trên nền tảng web/mobile UpRace. Người tham gia (sau đây gọi
                                là vận động viên, viết tắt “VĐV”) được tự do chạy vào thời gian và địa điểm tuỳ ý và chủ
                                động ghi lại kết quả chạy trên các thiết bị di động (điện thoại di động, đồng hồ thể
                                thao…) với mục đích tích luỹ được quãng đường chạy càng nhiều càng tốt.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Thời gian:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Thời gian mở đăng ký sự kiện:</strong> bắt đầu từ 0 giờ ngày 10/10/2021,
                                        VĐV vẫn có thể tiếp tục đăng ký và gia nhập Đội thi trong quá trình sự kiện diễn
                                        ra.
                                    </li>
                                    <li>
                                        <strong>Thời gian thi đấu:</strong> từ 0 giờ ngày 31/10/2021 tới 23 giờ 59 phút
                                        ngày 21/11/2021 (22 ngày).
                                    </li>
                                    <li>
                                        <strong>Giờ Việt Nam (GMT+7)</strong> được sử dụng làm múi giờ chuẩn cho sự kiện
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức đăng ký</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        VĐV tải ứng dụng UpRace trên điện thoại di động hoặc truy cập website:
                                        https://uprace.org và đăng ký tài khoản. Các VĐV đã dự thi năm 2020 có thể đăng
                                        nhập vào tài khoản sẵn có của mình. Các VĐV sau khi đăng ký tài khoản thành công
                                        sẽ được tự động tham gia vào sự kiện UpRace 2021.
                                    </li>
                                    <li>
                                        VĐV bấm tham gia vào một Đội (Team) mà mình lựa chọn. Mỗi tài khoản của VĐV chỉ
                                        được chọn một Đội để thi đấu trong sự kiện. Các VĐV không được phép thay đổi Đội
                                        kể từ thời điểm sự kiện bắt đầu cho tới lúc sự kiện kết thúc.
                                    </li>
                                    <li>
                                        VĐV bấm tham gia gây quỹ cho một Tổ chức xã hội mà mình lựa chọn. Mỗi tài khoản
                                        của VĐV chỉ được chọn một Tổ chức xã hội để gây quỹ. Các VĐV không được phép
                                        thay đổi Tổ chức xã hội mình đã chọn trong quá trình sự kiện diễn ra. Có 4 Tổ
                                        chức xã hội để VĐV lựa chọn bao gồm:
                                        <p>- Newborns Vietnam</p>
                                        <p>- Operation Smile</p>
                                        <p>- Green Việt</p>
                                        <p>- Saigon Children's Charity</p>
                                    </li>
                                    <li>
                                        VĐV tham gia vào sự kiện sau thời điểm sự kiện bắt đầu vẫn được thi đấu bình
                                        thường.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức thi đấu giữa các Đội</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Các đội được xếp vào 3 bảng đấu gồm:</p>
                                        <p>- Bảng 1: gồm các Doanh nghiệp (Company)</p>
                                        <p>- Bảng 2: gồm các Câu lạc bộ thể thao (Sports Club)</p>
                                        <p>- Bảng 3: gồm các Trường học (Schools)</p>
                                    </li>
                                    <li>
                                        Thành tích của mỗi Đội là tổng thành tích hợp lệ của tất cả các VĐV tham gia thi
                                        đấu cho Đội trong sự kiện. Các Đội trong cùng bảng sẽ thi đấu với nhau.
                                    </li>
                                    <li>
                                        Các Đội được thành lập sau thời điểm sự kiện bắt đầu vẫn được tham gia thi đấu
                                        bình thường.
                                    </li>
                                    <li>
                                        Các Đội có thể thành lập các Nhóm để thi đấu nội bộ trong Đội. Các Nhóm trong
                                        Đội cũng sẽ thi đấu theo quy định của UpRace về hoạt động hợp lệ (tại mục 5).
                                        Bảng xếp hạng các Nhóm trong Đội không có giá trị cạnh tranh cho các giải thưởng
                                        đồng đội của UpRace. (Xem hướng dẫn tạo Nhóm và Bảng xếp hạng Nhóm tại ĐÂY)
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức thi đấu đối với các VĐV</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Cách thức ghi lại thành tích:</strong> Các VĐV tự ghi lại thành tích mỗi
                                        lần chạy của mình bằng thiết bị di động (điện thoại, đồng hồ có chức năng GPS…).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Cuộc chạy hợp lệ:</strong> được tính khi đạt đủ các điều kiện sau:
                                        </p>
                                        <p>
                                            - Được ghi nhận hoàn tất trên tài khoản UpRace của VĐV trong vòng 72 giờ kể
                                            từ thời điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối cùng của sự kiện.
                                        </p>
                                        <p>
                                            - Thể hiện bản đồ chạy rõ ràng, không có dấu hiệu sai số lớn do lỗi bắt sóng
                                            GPS của thiết bị di động. Các cuộc chạy được nhập kết quả thủ công bằng tay
                                            (manual input), hoặc được share bởi người khác, hoặc ghi nhận cho nhiều tài
                                            khoản khác nhau sẽ không được công nhận hợp lệ; Trong trường hợp cuộc chạy
                                            thực hiện trên máy chạy bộ cần được ghi nhận bằng thiết bị đo chuyên dụng
                                            như đồng hồ Garmin và cảm biến đeo chân.
                                        </p>
                                        <p>
                                            - Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều app sẽ
                                            chỉ được ghi nhận là một cuộc chạy và theo thứ tự ưu tiên sau: UpRace -{'>'}{' '}
                                            Garmin -{'>'} Strava
                                        </p>
                                        <p>
                                            - Có cự li chạy (Distance) tối thiểu là 1km. Cự li tối đa không giới hạn.
                                            VĐV được bấm tạm dừng cuộc chạy trong khi chạy với mục đích nghỉ và phân
                                            phối sức
                                        </p>
                                        <p>
                                            - Có tốc độ di chuyển trung bình (Average Moving Pace) tính theo số phút
                                            hoàn thành 1 km chạy nhanh tối đa là 4:00 phút/km và chậm tối thiểu là 15:00
                                            phút/km. Có tốc độ di chuyển trung bình của tất cả các quãng 1km (Split)
                                            nhanh tối đa 3:30 phút/km.
                                        </p>
                                        <p>
                                            Các cuộc chạy diễn ra trong những ngày Chủ nhật và ngày đặc biệt 11/11/2021
                                            trong thời gian diễn ra sự kiện phải được hoàn tất trong ngày (trước 24h00)
                                            và kết quả nhân 2 được tính vào thành tích của ngày đó. (Riêng ngày
                                            11/11/2021 kết quả sẽ được nhân 2 khi VĐV sử dụng ứng dụng UpRace để ghi
                                            nhận thành tích)
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Ngày chạy hợp lệ (active days):</strong> là ngày có ít nhất một cuộc
                                            chạy hợp lệ theo điều trên.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Hoàn thành cuộc thi:</strong> Một VĐV sẽ được chứng nhận là hoàn
                                            thành cuộc thi khi đạt đồng thời hai điều kiện sau:
                                        </p>
                                        <p>- Có số ngày chạy hợp lệ tối thiểu là 12 ngày.</p>
                                        <p>
                                            - Có tổng số quãng đường chạy tối thiểu là 60 km (bao gồm cả thành tích nhân
                                            đôi trong các ngày Chủ nhật và ngày 11/11/2021)
                                        </p>
                                        <p>
                                            <strong>Lưu ý:</strong> Đối với vận động viên không chạy đủ 12 ngày và 60
                                            km, thì số km chạy hoàn thành vẫn được đóng góp cho đội và được nhà tài trợ
                                            quyên góp cho tổ chức xã hội.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Ngày ghi nhận thành tích:</strong> Thời điểm bắt đầu cuộc chạy thuộc
                                        ngày nào thì thành tích của cuộc chạy được tính cho ngày đó.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Giải thưởng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Giải thưởng thành tích cá nhân VĐV:</strong>
                                        </p>
                                        <p>
                                            Tất cả các VĐV đạt đủ điều kiện hoàn thành cuộc thi sẽ nhận được một giấy
                                            chứng nhận hoàn thành cuộc thi (bản điện tử) theo các mốc số km hoàn thành
                                            gồm: 60km, 150km, 300km tương ứng với các danh hiệu: Pro Runner, Hero Runner
                                            và Angel Runner.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Giải thưởng thành tích cho Đội:</strong>
                                        </p>
                                        <p>
                                            Mười Đội có tổng thành tích tốt nhất trong mỗi Bảng sẽ nhận giải thưởng đồng
                                            đội.
                                        </p>
                                        <p>Giải thưởng đồng đội bao gồm: áo UpRace Winning Team 2021 sẽ trao cho:</p>
                                        <p>+ Vận động viên đủ điều kiện hoàn thành cuộc thi</p>
                                        <p>+ Và có thành tích nằm trong top của các Đội đoạt giải theo bảng sau:</p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Doanh nghiệp</th>
                                                <th>Câu lạc bộ</th>
                                                <th>Trường học</th>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhất</td>
                                                <td>Top 300 runners</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhì</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng ba</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 4,5</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 6,7,8,9 và 10</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                        </table>
                                        <p>
                                            <strong>
                                                Ngoài ra, đội có thành tích cao nhất mỗi bảng (Top 1) sẽ nhận được:
                                            </strong>
                                            <p>
                                                + Giải thưởng 100 triệu VNĐ dùng để quyên góp cho đơn vị thụ hưởng
                                                UpRace 2021 mà đội đó chỉ định
                                            </p>
                                            <p>+ Giấy chứng nhận thành tích đội thắng cuộc (bản điện tử)</p>
                                            <p>+ Bài vinh danh đội Top 1 mỗi bảng trên fanpage UpRace & 1 bài PR.</p>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Gây quỹ cho cộng đồng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Nguồn quỹ:</strong>
                                        </p>
                                        <p>
                                            - Từ các nhà tài trợ (Sponsors): các nhà tài trợ sẽ quyên góp 1000 đồng/1km
                                            chạy hợp lệ của VĐV
                                        </p>
                                        <p>
                                            - Từ các VĐV: Các VĐV tham gia cuộc thi được khuyến khích quyên góp cho
                                            chính thành tích chạy của mình hoặc của VĐV khác. Mức quyên góp tối thiểu là
                                            1000 đồng/1km chạy hợp lệ của mình hoặc của VĐV khác. VĐV cũng được khuyến
                                            khích kêu gọi các cổ động viên hoặc VĐV khác quyên góp cho thành tích chạy
                                            của mình. Lưu ý: đây KHÔNG phải là điều kiện bắt buộc đối với các VĐV.
                                        </p>
                                        <p>
                                            - Từ các cổ động viên: Các cổ động viên được khuyến khích tham gia quyên góp
                                            cho thành tích chạy của VĐV mà mình muốn cổ vũ. Mức quyên góp tối thiểu là
                                            1000 đồng/1km
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Sử dụng quỹ:</strong> Toàn bộ tiền gây quỹ của UpRace sẽ được chuyển cho
                                        các Tổ chức xã hội.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Quyền của Ban tổ chức (BTC) UpRace và Ban quản trị (BQT) các Đội</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Quyền của BTC UpRace:</strong> BTC có toàn quyền quyết định một Đội và
                                        VĐV có đủ điều kiện thi đấu hay không, quyền dừng thi đấu và huỷ kết quả của Đội
                                        và VĐV trong trường hợp phát hiện hành vi gian lận hoặc không tuân thủ các quy
                                        định của cuộc thi và quyền phân xử đối với các khiếu nại. BTC có các phương án
                                        phòng chống và phát hiện gian lận nếu có, đồng thời kêu gọi các VĐV thể hiện
                                        tinh thần fairplay và tự giác cao nhất. Khi có bất kỳ hành vi gian lận nào bị
                                        phát hiện, VĐV sẽ bị loại khỏi cuộc thi và không được công nhận toàn bộ thành
                                        tích đã đạt được.
                                    </li>
                                    <li>
                                        <strong>Quyền của BQT Đội:</strong> Mỗi đội sẽ có BQL bao gồm Đội trưởng (Owner)
                                        và các Quản trị viên (Moderator) do Đội trưởng chỉ định. BQL đội sẽ có quyền
                                        quyết định VĐV trong đội của mình có đủ điều kiện thi đấu hay không, quyền dừng
                                        thi đấu và huỷ kết quả của VĐV trong Đội của mình trong trường hợp phát hiện các
                                        hành vi gian lận hoặc không tuân thủ các quy định của cuộc thi và quyền phân xử
                                        đối với các khiếu nại của VĐV trong Đội.
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        const RenderContent = () => {
            return <RuleUprace />;
        };

        return <RenderContent />;
    })
);

export default RuleEvent;
