import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import LikeItem from './LikeItem';
import CommentItem from './CommentItem';
import { toJS } from 'mobx';
import { SOCIAL_TAB, GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import InfiniteScroll from 'react-infinite-scroll-component';

const SocialList = inject(
    'authStore',
    'userStore',
    'challengeStore',
    'globalStore'
)(
    observer((props) => {
        const { tabType } = props;
        const {
            currentChallenge,
            listUserLike,
            listComment,
            listUserDisLike,
            totalLike,
            totalDislike,
            totalComment,
            doGetListComment,
            doGetListUserDisLike
        } = props.challengeStore;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const [hasMoreDislike, setHasMoreDislike] = React.useState(true);
        const [hasMoreLike, setHasMoreLike] = React.useState(true);
        const [hasMoreComment, setHasMoreComment] = React.useState(true);

        let [pageDislike, setPageDislike] = React.useState(0);
        let [pageLike, setPageLike] = React.useState(0);
        let [pageComment, setPageComment] = React.useState(0);

        const fetchMoreDataLike = () => {
            if (listUserLike.length >= totalLike) {
                setHasMoreLike(false);
                return;
            }
            setPageLike((pageLike += 1));
            onLoadMoreLike();
        };

        const fetchMoreDataDislike = () => {
            if (listUserDisLike.length >= totalDislike) {
                setHasMoreDislike(false);
                return;
            }
            setPageDislike((pageDislike += 1));
            onLoadMoreDislike();
        };

        const fetchMoreDataComment = () => {
            if (listComment.length >= totalComment) {
                setHasMoreComment(false);
                return;
            }
            setPageComment((pageComment += 1));
            onLoadMoreComment();
        };

        function onLoadMoreLike() {
            let data = {
                tgid: currentChallenge.tgid,
                lktp: 1,
                from: pageDislike * appVersion.pgsz,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListUserDisLike(data, true);
        }

        function onLoadMoreDislike() {
            let data = {
                tgid: currentChallenge.tgid,
                lktp: 8, //8: dislike
                from: pageDislike * appVersion.pgsz,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListUserDisLike(data, true);
        }

        function onLoadMoreComment() {
            let data = {
                tgid: currentChallenge.tgid,
                from: pageComment * appVersion.pgsz,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListComment(data, true);
        }

        if (tabType == SOCIAL_TAB.COMMENT)
            return (
                <div
                    className="tab-pane fade show active nav-comment"
                    id="nav-comment"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab">
                    <InfiniteScroll
                        dataLength={totalComment}
                        next={fetchMoreDataComment}
                        hasMore={hasMoreComment}
                        style={{ overflowX: 'hidden' }}
                        height={500}>
                        <ul>
                            {listComment.map((item) => (
                                <CommentItem key={item.cmid} data={item} />
                            ))}
                        </ul>
                    </InfiniteScroll>
                </div>
            );
        else if (tabType == SOCIAL_TAB.LIKE)
            return (
                <div
                    className="tab-pane fade show active nav-like"
                    id="nav-like"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab">
                    <InfiniteScroll
                        dataLength={listUserLike.length}
                        next={fetchMoreDataLike}
                        hasMore={hasMoreLike}
                        style={{ overflowX: 'hidden' }}
                        height={500}>
                        <ul>
                            {listUserLike.map((item) => (
                                <LikeItem key={item.uid} data={item} />
                            ))}
                        </ul>
                    </InfiniteScroll>
                </div>
            );
        else
            return (
                <div
                    className="tab-pane fade show active nav-like"
                    id="nav-like"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab">
                    <InfiniteScroll
                        dataLength={listUserDisLike.length}
                        next={fetchMoreDataDislike}
                        hasMore={hasMoreDislike}
                        style={{ overflowX: 'hidden' }}
                        height={500}>
                        <ul>
                            {listUserDisLike.map((item) => (
                                <LikeItem key={item.uid} data={item} />
                            ))}
                        </ul>
                    </InfiniteScroll>
                </div>
            );
    })
);

export default SocialList;
