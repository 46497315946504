import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatDistanceKM, getImageResource } from '../../../../utils';
import { MemberName } from 'components/MemberName';

const MemberRankItem = inject(
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore',
    'eventStore'
)(
    observer((props) => {
        const { data, rank, navigation } = props;
        const { currentEvent } = props.eventStore;
        const { doviewUserDetail, doActionGroup } = props.groupStore;
        const { doSetFunc } = props.teamManageStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        async function viewUserDetail() {
            if (userProfile) navigation.push(`user-detail?uid=${data.id}&evid=${currentEvent.evid}`);
            else navigation.push(`/login`);
        }

        function addDefaultSrc(e) {
            e.target.src = '../../../img/ic_avatar.png';
        }

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';

        return (
            <li>
                <a href="javascript:void(0);">
                    <div class="level" onClick={() => viewUserDetail()}>
                        {data.rank}
                    </div>
                    <div class="logo" onClick={() => viewUserDetail()}>
                        <img src={getImageResource(data.ava, true)} onError={addDefaultSrc} alt="" />
                    </div>
                    <div class="name-box" onClick={() => viewUserDetail()}>
                        <p class="name">{MemberName(data)}</p>
                        {data.diff != 0 ? <p class={cssRankArrow}>{Math.abs(data.diff)}</p> : null}
                    </div>
                    <div class="date">{formatDistanceKM(data.ddis, true)}</div>
                    <div class="sum">{formatDistanceKM(data.dis, true)}</div>
                </a>
            </li>
        );
    })
);

export default MemberRankItem;
