import React, { useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDate, getImageResource, stripedHtml } from 'utils';
import moment from 'moment';
import { EVENT_JOIN_STATUS, EVENT_PUBlIC_TYPE, GLOBAL_CONFIG, LEADERBOARD_TYPE } from 'helpers/ConstDefined';
import GroupRankItem from './GroupRankItem';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import GroupRankEmpty from './GroupRankEmpty';
import NotJoinEvent from './NotJoinEvent';

const LeaderboardGroup = inject(
    'eventStore',
    'teamManageStore',
    'userStore',
    'globalStore',
    'groupStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;
        const { loading, totalGroupRank, listGroupRank, doGetLeaderboardGroup } = props.teamManageStore;
        const { groupRank, doGetGroupRank } = props.groupStore;
        const { userProfile } = props.userStore;

        const [search, setSearch] = React.useState('');
        const isShowLoadMore = listGroupRank == null ? false : listGroupRank.length < totalGroupRank;
        const [day, setDay] = React.useState(null);
        const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));

        const isAllowView =
            eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ||
            (eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING);

        const isJoined = eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING;

        const { t } = useTranslation();

        useEffect(() => {
            if (userProfile && eventData.grid != 0) getGroupRank();
        }, [userProfile]);

        useEffect(() => {
            //if (listGroupRank.length > 0) return;
            getLeaderboardGroup();
            getGroupRank();
        }, [eventData.grid]);

        useEffect(() => {
            if (day != null) {
                if (userProfile && eventData.grid != 0) getGroupRank();
                getLeaderboardGroup(); // auto search when day change
            }
        }, [day]);

        async function getGroupRank() {
            if (!userProfile) return;
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.GROUP_EVENT,
                day,
                value: eventData && eventData.grid
            };
            doGetGroupRank(params);
        }

        const getLeaderboardGroup = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.GROUP_EVENT,
                value: 0,
                day,
                name: search
            };
            doGetLeaderboardGroup(params, isLoadMore);
        };

        const getGroupRankListMore = () => {
            getLeaderboardGroup(true);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getLeaderboardGroup();
            }
        };

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);
            setDayText(dayText);
            setDay(day);
        };

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{dayText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupRankListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                {isAllowView ? (
                    <div class="card card-details card-rank">
                        <div class="card-details-body">
                            <div class="frm-row">
                                <div class="frm-row__item search">
                                    <input
                                        type="text"
                                        placeholder={t('global.search')}
                                        value={search}
                                        onKeyDown={handleKeyDown}
                                        onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                    />
                                </div>
                                <div class="frm-row__item">
                                    <button
                                        class="btn btn-40 btn-blue btn-search"
                                        onClick={() => getLeaderboardGroup()}>
                                        {t('global.search')}
                                    </button>
                                </div>
                            </div>

                            {listGroupRank && listGroupRank.length > 0 ? (
                                <div class="teams-list">
                                    <div class="sticky" id="sticker">
                                        <ul class="title-row">
                                            <li class="level">{t('global.rank')}</li>
                                            <li class="name">{t('team.group.createdl.name')}</li>
                                            <li class="date">
                                                <DatePicker
                                                    onChange={handleChangeDate}
                                                    customInput={<CustomInputDate />}
                                                />
                                            </li>
                                            <li class="sum">{t('global.total_km')}</li>
                                        </ul>
                                        <ul class="list">
                                            {eventData.grid != 0 && groupRank && groupRank.rank > 0 && isJoined ? (
                                                <ul className="list" style={{ display: 'block' }}>
                                                    <GroupRankItem data={groupRank} navigation={navigation} />
                                                </ul>
                                            ) : null}
                                        </ul>
                                    </div>
                                    <ul class="list">
                                        {listGroupRank &&
                                            listGroupRank.map((item) => {
                                                return (
                                                    <GroupRankItem data={item} key={item.id} navigation={navigation} />
                                                );
                                            })}
                                    </ul>
                                    <LoadMore />
                                </div>
                            ) : (
                                <GroupRankEmpty />
                            )}
                        </div>
                    </div>
                ) : (
                    <NotJoinEvent />
                )}
            </>
        );

        function RenderContent() {
            return (
                <div class="card card-details card-rank">
                    <div class="card-details-body">
                        <div class="frm-row">
                            <div class="frm-row__item search">
                                <input
                                    type="text"
                                    placeholder={t('global.search')}
                                    value={search}
                                    onKeyDown={handleKeyDown}
                                    onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                />
                            </div>
                            <div class="frm-row__item">
                                <button class="btn btn-40 btn-blue btn-search" onClick={() => getLeaderboardGroup()}>
                                    {t('global.search')}
                                </button>
                            </div>
                        </div>

                        <div class="teams-list">
                            <div class="sticky" id="sticker">
                                <ul class="title-row">
                                    <li class="level">{t('global.rank')}</li>
                                    <li class="name">{t('team.group.createdl.name')}</li>
                                    <li class="date">
                                        <DatePicker onChange={handleChangeDate} customInput={<CustomInputDate />} />
                                    </li>
                                    <li class="sum">{t('global.total_km')}</li>
                                </ul>
                                <ul class="list">
                                    {eventData.grid != 0 && groupRank && groupRank.rank > 0 ? (
                                        <ul className="list" style={{ display: 'block' }}>
                                            <GroupRankItem data={groupRank} navigation={navigation} />
                                        </ul>
                                    ) : null}
                                </ul>
                            </div>
                            <ul class="list">
                                {listGroupRank &&
                                    listGroupRank.map((item) => {
                                        return <GroupRankItem data={item} key={item.id} navigation={navigation} />;
                                    })}
                            </ul>
                            <LoadMore />
                        </div>
                    </div>
                </div>
            );
        }

        if (eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC)
            return <>{listGroupRank.length > 0 ? <RenderContent /> : <GroupRankEmpty />}</>;
        else if (eventData.jsts == EVENT_JOIN_STATUS.NONE || eventData.jsts == EVENT_JOIN_STATUS.REQUESTING)
            return <NotJoinEvent />;
        else if (listGroupRank.length > 0) return <RenderContent />;
        else return <GroupRankEmpty />;
    })
);

export default LeaderboardGroup;
