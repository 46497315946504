import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MemberItem from './MemberItem';
import { formatNumber, stripedHtml } from '../../utils';
import _ from 'lodash';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';
import DeleteMemberDialog from 'views/TeamDetails/components/ManageMemberGroupDialog/DeleteMemberDialog';

let memberData = null;
const ManageMember = inject(
    'userStore',
    'groupStore',
    'eventStore'
)(
    observer((props) => {
        const { t } = useTranslation();

        const { groupData, eventData } = props;
        const { userProfile } = props.userStore;
        const {
            loadingGroup,
            ownerGroup,
            listMemberJoined,
            doSetOwnerGroupInfo,
            totalMemberJoined,
            doGetListMemberJoined
        } = props.groupStore;

        const isShowLoadMore = listMemberJoined == null ? false : listMemberJoined.length < totalMemberJoined;

        const [openDialogDeleteMember, setOpenDialogDeleteMember] = React.useState(false);
        const [search, setSearch] = React.useState('');

        const getListMember = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile.uid,
                grid: groupData.grid,
                name: search
            };
            doGetListMemberJoined(params, isLoadMore);
        };

        const onLoadMoreMember = () => {
            getListMember(true);
        };

        useEffect(() => {
            onLoadData();
        }, []);

        useEffect(() => {
            getOwnerGroup();
        }, [listMemberJoined]);

        function getOwnerGroup() {
            if (!listMemberJoined) return;
            const obj = _.find(listMemberJoined, (item) => {
                return item.uid == groupData.owner;
            });
            if (obj) doSetOwnerGroupInfo(obj);
        }

        const onLoadData = () => {
            getListMember();
        };

        const onDeleteMember = (data) => {
            memberData = data;
            setOpenDialogDeleteMember(true);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                onLoadData();
            }
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => onLoadMoreMember()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div class="manage-wrap">
                <div class="manage-box">
                    <div class="manage-box__header">
                        <h4 class="title">{t('global.manage_member')}</h4>
                    </div>

                    <div class="manage-box__body">
                        <div class="content">
                            <div class="frm-row">
                                <div class="frm-row__item search">
                                    <input
                                        type="text"
                                        placeholder={t('global.search')}
                                        value={search}
                                        onKeyDown={handleKeyDown}
                                        onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                    />
                                </div>
                                <button class="btn btn-40 btn-blue" onClick={() => onLoadData()}>
                                    {t('global.search')}
                                </button>
                            </div>

                            <div class="manage-box__sub">
                                <h5 class="title">{t('global.group_leader')}</h5>

                                {ownerGroup ? (
                                    <MemberItem data={ownerGroup} isOwner={true} eventData={eventData} />
                                ) : null}
                            </div>

                            <div class="manage-box__sub">
                                <h5 class="title">
                                    {t('global.member1')} ({formatNumber(totalMemberJoined)})
                                </h5>
                                <div class="list-group">
                                    {listMemberJoined &&
                                        listMemberJoined.map((item) => {
                                            return (
                                                <div class="list-group-item">
                                                    <MemberItem
                                                        data={item}
                                                        key={item.uid}
                                                        eventData={eventData}
                                                        onDeleteMember={onDeleteMember}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                                <LoadMore />
                            </div>
                        </div>
                    </div>
                </div>
                {openDialogDeleteMember ? (
                    <DeleteMemberDialog
                        openDialogDeleteMember={openDialogDeleteMember}
                        onClose={() => setOpenDialogDeleteMember(false)}
                        data={memberData}></DeleteMemberDialog>
                ) : null}
            </div>
        );
    })
);

export default ManageMember;
