import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ViewCertDialog from './ViewCertDialog';
import _ from 'lodash';

const CertEvent = inject(
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;

        const [openDialogViewCert, setOpenDialogViewCert] = React.useState(false);

        const { t } = useTranslation();

        if (currentEvent && currentEvent.cert)
            return (
                <div class="yours--cetificate">
                    <h2 class="title">{t('global.congrat')}</h2>
                    <p class="txt">{t('global.congrat.desc')}</p>
                    <p class="img">
                        <img src={currentEvent.certurl} alt="" style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}} />
                    </p>
                    <button class="btn-44 blue" onClick={() => setOpenDialogViewCert(true)}>
                        {t('global.view_cert')}
                    </button>

                    <ViewCertDialog
                        openDialogViewCert={openDialogViewCert}
                        eventID={currentEvent.evid}
                        onClose={() => setOpenDialogViewCert(false)}
                    />
                </div>
            );
    })
);

export default CertEvent;
