import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';

const GroupRankEmpty = inject(
    'authStore',
    'eventStore',
    'teamStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { eventID, teamID } = props;
        const { currentEvent } = props.eventStore;
        const { currentTeam } = props.teamStore;

        const { t } = useTranslation();

        return (
            <>
                <div className="groups-empty">
                    <p className="img">
                        <img src="../../images/imgs/no_group.png" alt="" />
                    </p>
                    <p className="title">{t('team.group.no_group')}</p>
                    <p className="note">{t('team.group.no_group_desc')}</p>
                    <div className="btn-row">
                        {currentEvent &&
                        currentEvent.grid == 0 &&
                        currentEvent.rgrid == 0 &&
                        currentTeam.jsts != GROUP_JOIN_STATUS.NONE &&
                        currentTeam.jsts != GROUP_JOIN_STATUS.REQUESTING ? (
                            <button
                                className="btn-40 blue"
                                data-toggle="modal"
                                data-target="#create-group-step1"
                                onClick={props.openDialogCreateGroup}>
                                <span className="btn-txt">{t('global.reate_group_now')}</span>
                            </button>
                        ) : null}
                    </div>
                </div>
            </>
        );
    })
);

export default GroupRankEmpty;
