import React, {useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import UserItem from './UserItem';
import { SOCIAL_TAB } from '../../../../helpers/ConstDefined';
import InfiniteScroll from 'react-infinite-scroll-component';

const FollowList = inject(
    'userProfileStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { tabType, profileID, eventID } = props;
        const { listFollower, listFollowing, totalFollower, totalFollowing } = props.userProfileStore;
        const { doGetListUserFollowing, doGetListUserFollower } = props.userProfileStore;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;

        const [hasMoreFollowing, setHasMoreFollowing] = React.useState(true);
        const [hasMoreFollower, setHasMoreFollower] = React.useState(true);

        const fetchMoreDataFollower = () => {
            if (listFollower.length >= totalFollower) {
                setHasMoreFollower(false);
                return;
            }
            getListFollower(true);
        };

        const fetchMoreDataFollowing = () => {
            if (listFollowing.length >= totalFollowing) {
                setHasMoreFollowing(false);
                return;
            }
            getListFollowing(true);
        };

        function getListFollower(isLoadMore) {
            let data = {
                uid: userProfile.uid,
                size: appVersion.pgsz,
                feid: profileID
            };
            doGetListUserFollower(data, isLoadMore);
        }

        function getListFollowing(isLoadMore) {
            let data = {
                uid: userProfile.uid,
                size: appVersion.pgsz,
                fiid: profileID
            };
            doGetListUserFollowing(data, isLoadMore);
        }

        useEffect(() => {
            getListFollower();
            getListFollowing();
        }, []);

        if (tabType == SOCIAL_TAB.FOLLOWING)
            return (
                <li class="active">
                    <div class="like-list">
                        <InfiniteScroll
                            dataLength={listFollowing.length}
                            next={fetchMoreDataFollowing}
                            hasMore={hasMoreFollowing}
                            style={{ overflowX: 'hidden' }}
                            height={550}>
                            <ul style={{ cursor: 'pointer' }}>
                                {listFollowing.map((item) => (
                                    <UserItem eventID={eventID} key={item.uid} data={item} onClose={props.onClose} />
                                ))}
                            </ul>
                        </InfiniteScroll>
                    </div>
                </li>
            );
        else
            return (
                <li class="active">
                    <div class="like-list">
                        <InfiniteScroll
                            dataLength={listFollower.length}
                            next={fetchMoreDataFollower}
                            hasMore={hasMoreFollower}
                            style={{ overflowX: 'hidden' }}
                            height={550}>
                            <ul style={{ cursor: 'pointer' }}>
                                {listFollower.map((item) => (
                                    <UserItem eventID={eventID} key={item.uid} data={item} onClose={props.onClose} />
                                ))}
                            </ul>
                        </InfiniteScroll>
                    </div>
                </li>
            );
    })
);

export default FollowList;
