import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimestamptoDate, formatDistanceKM, getImageResource } from '../../../../utils/index';
import { humanTime } from '../../../../helpers/ConvertHelper';
import { CHALLENGE_STT } from '../../../../helpers/ConstDefined';
import AvatarItem from '../../../Common/AvatarItem';
import TextTruncate from 'react-text-truncate';
import Truncate from 'react-truncate';

const ChallengeItem = inject('authStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {}, []);

        const imgGender = data != null && data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';
        let imgFinish;
        if (data.sts == CHALLENGE_STT.WIN) imgFinish = '../../../img/finished-ribbon.svg';
        else if (data.sts == CHALLENGE_STT.LOSE) imgFinish = '../../../img/dnf-ribbon.svg';
        else imgFinish = null;

        return (
            <div className="col-4 wrapper-challenge">
                <div className="content">
                    <div className="header-challenge">
                        <div className="row">
                            <div className="col-2">
                                <div className="avatar-challenge" style={{ backgroundImage: `url(${getImageResource(data.ava,true,true)})` }}>
                                    <img className="avatar" src="../../../img/avatar.png" alt="" />
                                    <img className="gender" src={imgGender} alt="" />
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="info-user" onClick={props.onViewProfileUser}>
                                    <p className="name">
                                        {data.name}{' '}
                                        <span className="sub-name">
                                            {data.tmnm.length > 0 ? ` - ${data.tmnm}` : ''}
                                        </span>
                                    </p>
                                    <span className="time-detail">{humanTime(data.crtm)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ribbon-outside-user" onClick={props.onViewChallengeDetail}>
                        {imgFinish != null ? <img src={imgFinish} className="ribbon-img" /> : null}
                        <p className="description-challenge">{data.desc}</p>
                    </div>

                    <div className="linear-challenge" onClick={props.onViewChallengeDetail}>
                        <div className="row">
                            <div className="col-4 wrapper-linear linear-total-distance">
                                <p className="title">Tổng quãng đường</p>
                                <p className="value-distance">
                                    {formatDistanceKM(data.cdis)} <span className="unit">km</span>
                                </p>
                                <p className="title">
                                    Đã chạy: <span className="value-result">{formatDistanceKM(data.dis)}</span>
                                </p>
                            </div>
                            <div className="col-4 wrapper-linear linear-total-day">
                                <p className="title">Tổng số ngày</p>
                                <p className="value-distance">
                                    {data.cdays} <span className="unit"></span>
                                </p>
                                <p className="title">
                                    Ngày: <span className="value-result total-day">{data.days}</span>
                                </p>
                            </div>
                            <div className="col-4 wrapper-linear linear-max-distance">
                                <p className="title">Quãng đg dài nhất</p>
                                <p className="value-distance">
                                    {formatDistanceKM(data.cxdis)} <span className="unit">km</span>
                                </p>
                                <p className="title">
                                    Dài nhất:{' '}
                                    <span className="value-result total-distance">{formatDistanceKM(data.xdis)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="member-reaction">
                    <div className="row ">
                        <div className="col-8 ">
                            <div className="wrapper-like" onClick={props.onViewSocialLike}>
                                <ul className="avatars">
                                    {data.lkls.map((item) => {
                                        return (
                                            <li key={item.uid} className="avatars-item">
                                                <AvatarItem data={item.ava} css="avatars-img" />
                                            </li>
                                        );
                                    })}
                                    {data.lkcnt > 4 ? (
                                        <li className="avatars-item">
                                            <span className="icon-more">...</span>
                                        </li>
                                    ) : null}
                                </ul>
                                {data.lktp != 0 ? (
                                    <p className="number-like">
                                        {data.lkcnt + data.dlkcnt - 1 == 0
                                            ? `Bạn quan tâm thử thách này`
                                            : `Bạn + ${data.lkcnt + data.dlkcnt - 1} người khác quan tâm`}
                                    </p>
                                ) : (
                                    <p className="number-like">{data.lkcnt + data.dlkcnt} người khác quan tâm</p>
                                )}
                            </div>
                        </div>
                        <div className="col-4 ">
                            <div className="wrapper-comments" onClick={props.onViewSocialComment}>
                                <p>
                                    <a>
                                        {data.cmcnt} bình luận{' '}
                                        {data.cmcnt > 0 ? <i className="fa fa-chevron-right next"></i> : null}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default ChallengeItem;
