import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { TEAM_TYPE } from '../../../../data/master';
import { getTypeName, getImageResource } from '../../../../utils';

const DialogLeaveResult = inject('globalStore')(
    observer((props) => {
        const { openDialogLeaveResult, teamData, msg } = props;

        useEffect(() => {}, []);

        return (
            <Modal show={openDialogLeaveResult} onHide={props.onClose}>
                <div className="custom-modal modal-confirm-join-team" role="dialog" aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Bạn muốn rời đội</h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <img src="../../../img/icon-close-2.png" alt="image" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="wrapper-content">
                                    <div
                                        className="wrapper-profile-user-2"
                                        style={{ backgroundImage: `url(${getImageResource(teamData.ava,true)})` }}>
                                        <img src={getImageResource(teamData.ava,true)} alt="image" />
                                    </div>
                                    <p className="team-title">{teamData.name}</p>
                                    <p className="team-description">{getTypeName(TEAM_TYPE, teamData.lgid)}</p>

                                    <p>{teamData.desc}</p>
                                </div>

                                <p className="txt-red">{msg}</p>
                            </div>

                            <div className="modal-footer">
                                <div className="wrapper-btn">
                                    <button type="button" className="btn btn-primary" onClick={props.onClose}>
                                        Đã hiểu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogLeaveResult;
