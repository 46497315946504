import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { STATS_TYPE, GLOBAL_CONFIG, FEED_TYPE, SOCIAL_TAB } from '../../../../helpers/ConstDefined';
import { KEY } from '../../../../helpers/api/APIDefined';
import { formatDistanceKM, getImageResource } from '../../../../utils';
import ActivityItem from './ActivityItem';
import { toJS } from 'mobx';

let eventID;

const DialogFeedUser = inject(
    'authStore',
    'teamStore',
    'eventStore',
    'userStore',
    'globalStore',
    'userProfileStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { openDialogFeedUser, isViewProfile, navigation } = props;
            const { appVersion, doSetSocialTab } = props.globalStore;
            const { currentMember } = props.teamStore;
            const {
                listFeedDialog,
                userProfile,
                doGetListComment,
                doGetListUserLike,
                doSetCurrentFeed,
                doGetListFeedDialog,
                doGetFeedDetail,
                doGetFeedPolyline
            } = props.userStore;
            const { eventUserStats, doGetEventStats } = props.eventStore;
            const { doSetProfileUserID } = props.userProfileStore;

            useImperativeHandle(ref, () => ({
                initData(id) {
                    eventID = id;
                    getFeedUser();
                }
            }));

            function getFeedUser() {
                let data = {
                    uid: userProfile.uid,
                    feed: FEED_TYPE.YOUR_ACTIVITY,
                    evid: eventID,
                    value: currentMember.id
                };
                doGetListFeedDialog(data);
            }

            function onViewSocialLike(act) {
                doSetCurrentFeed(act);
                doSetSocialTab(SOCIAL_TAB.LIKE);
                getListUserLike(act.atid);
                getListComment(act.atid);
                props.onViewSocialLikeFeed();
            }

            function onViewSocialComment(act) {
                doSetCurrentFeed(act);
                doSetSocialTab(SOCIAL_TAB.COMMENT);
                getListUserLike(act.atid);
                getListComment(act.atid);
                props.onViewSocialCommentFeed();
            }

            const handleViewProfileUser = () => {
                if (!isViewProfile) return;
                sessionStorage.setItem(KEY.ID_PROFILE_USER, currentMember.id);
                doSetProfileUserID(currentMember.id);
                props.onViewProfileUser();
            };

            function getListComment(atid) {
                let data = {
                    atid,
                    from: 0,
                    size: appVersion.pgsz,
                    uid: userProfile.uid
                };
                doGetListComment(data);
            }

            function getListUserLike(atid) {
                let data = {
                    atid,
                    from: 0,
                    size: appVersion.pgsz,
                    uid: userProfile.uid
                };
                doGetListUserLike(data);
            }

            const handleViewChallengeUser = (member) => {
                props.onViewChallengeMember(member, eventID);
            };

            async function getFeedDetail(atid) {
                let params = {
                    uid: userProfile.uid,
                    atid
                };
                doGetFeedDetail(params);
            }

            //view feed detail
            async function onViewFeedDetail(feed) {
                doSetCurrentFeed(feed);
                getFeedDetail(feed.atid);
                //get polyline for redirect detail
                let params = {
                    uid: userProfile.uid,
                    atid: feed.atid,
                    type: 1
                };

                doGetFeedPolyline(params);
                navigation.push('/feed-detail');
            }

            const onViewImage = (feed) => {
                props.onViewImage(feed);
            };

            if (currentMember != null)
                return (
                    <Modal show={openDialogFeedUser} onHide={props.onClose}>
                        <div role="dialog" aria-modal="true" className="show">
                            <div className="custom-modal-complex modal-member-event modal-join-team" aria-hidden="true">
                                <div className="custom-modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="wrapper-banner">
                                                <div className="banner background-image-banner">
                                                    <img src="../../../img/background-profile-member.png" alt="image" />
                                                </div>
                                            </div>

                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={props.onClose}>
                                                <img src="../../../img/icon-close-2.png" alt="image" />
                                            </button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="wrapper-content">
                                                <div className="intro">
                                                    <div
                                                        className="profile-img"
                                                        style={{ backgroundImage: `url(${getImageResource(currentMember.ava,true,true)})` }}>
                                                        <img src="../../../img/avatar.png" alt="image" />
                                                    </div>

                                                    <h3 className="title" style={{cursor: "pointer"}} onClick={() => handleViewProfileUser()}>
                                                        {currentMember.name}{' '}
                                                        <a className="action-name">
                                                            <i className="fa fa-angle-right" size="20px"></i>
                                                        </a>
                                                    </h3>
                                                    <p className="team-type">
                                                        {currentMember.ctry} - {currentMember.city}
                                                    </p>
                                                    <div
                                                        className="btn btn-primary"
                                                        onClick={() => handleViewChallengeUser(currentMember)}>
                                                        Xem thử thách cá nhân{' '}
                                                        <i className="fa fa-chevron-right next" size="10"></i>
                                                    </div>
                                                </div>
                                                {eventUserStats != null ? (
                                                    <div className="wrapper-achievements">
                                                        <div className="achievement">
                                                            <img
                                                                src="../../../img/icon-member-blue.svg"
                                                                className="image-icon"
                                                                alt="image"
                                                            />
                                                            <p className="value-achievement">#{eventUserStats.rank}</p>
                                                            <p className="description-value">Xếp hạng trong đội</p>
                                                        </div>

                                                        <div className="achievement">
                                                            <img
                                                                src="../../../img/icon-km-blue.svg"
                                                                className="image-icon"
                                                                alt="image"
                                                            />
                                                            <p className="value-achievement">
                                                                {formatDistanceKM(eventUserStats.dis)} Km
                                                            </p>
                                                            <p className="description-value">Tổng quãng đường</p>
                                                        </div>

                                                        <div className="achievement">
                                                            <img
                                                                src="../../../img/icon-date-blue.svg"
                                                                className="image-icon"
                                                                alt="image"
                                                            />
                                                            <p className="value-achievement">
                                                                {eventUserStats.days} ngày
                                                            </p>
                                                            <p className="description-value">Số ngày hoạt động</p>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <h3 className="title">Các hoạt động hợp lệ</h3>
                                            <div className="wrapper-activity">
                                                {listFeedDialog != null &&
                                                    listFeedDialog.map((item) => (
                                                        <ActivityItem
                                                            key={item.atid}
                                                            data={toJS(item)}
                                                            showInDialog={true}
                                                            onViewFeedDetail={() => onViewFeedDetail(item)}
                                                            onViewImage={() => onViewImage(item)}
                                                            onViewSocialLike={() => onViewSocialLike(item)}
                                                            onViewSocialComment={() =>
                                                                onViewSocialComment(item)
                                                            }></ActivityItem>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            else return null;
        })
    )
);

export default DialogFeedUser;
