import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

const TeamDescription = inject(
    'userStore',
    'teamManageStore',
    'teamStore'
)(
    observer((props) => {
        const { currentTeam } = props.teamStore;
        const { eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { listTop15Mem, doGetTop15Member } = props.teamManageStore;

        useEffect(() => {
            getTopMember();
        }, [userProfile]);

        useEffect(() => {
            $('.action-show-hide').click(showHide);
        }, [currentTeam]);

        // show hide
        function showHide() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        }

        const { t } = useTranslation();

        const getTopMember = () => {
            const params = {
                size: 15,
                uid: userProfile && userProfile.uid,
                tmid: teamID,
                evid: eventID
            };
            doGetTop15Member(params);
        };

        function MemberIcon(props) {
            const { data } = props;
            return (
                <li>
                    <img src={getImageResource(data.ava, true, true)} alt="" />
                </li>
            );
        }

        return (
            <div>
                <div className="gbox users-list">
                    <ul>
                        {listTop15Mem &&
                            listTop15Mem.map((item) => {
                                return <MemberIcon data={item} key={item.uid} />;
                            })}
                    </ul>
                </div>
                <div className="gbox team-desc">
                    <h2 className="title">{t('team.manage.team_desc')}</h2>
                    <button className="seemore action-show-hide"></button>
                    <div className="details">{currentTeam && currentTeam.desc}</div>
                </div>
            </div>
        );
    })
);

export default TeamDescription;
