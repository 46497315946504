import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import GroupRankItem from './GroupRankItem';
import UserGroupRank from './UserGroupRank';
import { getImageResource, stripedHtml } from '../../../../utils';
import { LEADERBOARD_TYPE, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';
import $ from 'jquery';

const GroupRankList = inject(
    'authStore',
    'teamManageStore',
    'userStore',
    'teamStore',
    'eventStore',
    'groupStore'
)(
    observer((props) => {
        const { loading, listGroupRank, totalGroupRank } = props.teamManageStore;
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;
        const { currentTeam } = props.teamStore;
        const { currentGroup, groupRank, doGetGroupRank } = props.groupStore;
        const { eventID, teamID } = props;

        const isShowLoadMore = listGroupRank == null ? false : listGroupRank.length < totalGroupRank;

        const { t } = useTranslation();

        useEffect(() => {
            initSticker();
        }, [listGroupRank]);

        useEffect(() => {
            if (currentEvent && userProfile && currentEvent.grid != 0 && currentEvent.tmid == teamID) getGroupRank();
        }, [userProfile]);

        function initSticker() {
            var wScreen = $(window).width();
            if (wScreen > 1050) {
                $('#g-sticker').sticky({ topSpacing: 144, center: true, className: 'sticker-box' });
            } else {
                $('#g-sticker').sticky({ topSpacing: 98, center: true, className: 'sticker-box' });
            }
        }

        async function getGroupRank() {
            if (!userProfile) return;
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.GROUP_TEAM,
                value: currentEvent && currentEvent.grid,
                tmid: teamID
            };
            doGetGroupRank(params);
        }

        const getGroupRankListMore = () => {
            props.onLoadMoreGroup();
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupRankListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        const showAction =
            currentEvent &&
            currentEvent.grid == 0 &&
            currentEvent.rgrid == 0 &&
            currentTeam.jsts != GROUP_JOIN_STATUS.NONE &&
            currentTeam.jsts != GROUP_JOIN_STATUS.REQUESTING;

        return (
            <>
                <div className={showAction ? 'groups-list has-btn' : 'groups-list'}>
                    <div className="sticky" id="g-sticker">
                        <ul className="title-row">
                            <li className="level">{t('global.rank')}</li>
                            <li className="name">{t('team.group.createdl.name')}</li>
                            <li className="members">{t('global.total_member')}</li>
                            <li className="sum">{t('global.total_km')}</li>
                            {showAction ? <li className="action">{t('global.action')}</li> : null}
                        </ul>
                        {currentEvent.grid != 0 && groupRank && groupRank.rank > 0 ? (
                            <ul className="list" style={{ display: 'block' }}>
                                <GroupRankItem data={groupRank} />
                            </ul>
                        ) : null}
                    </div>
                    <ul className="list">
                        {listGroupRank &&
                            listGroupRank.map((item) => {
                                return <GroupRankItem data={item} key={item.id} showAction={showAction} />;
                            })}
                    </ul>
                    <LoadMore />
                </div>
            </>
        );
    })
);

export default GroupRankList;
