import React from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';

const CustomRankItem = inject(
    'authStore',
    'teamStore',
    'userStore',
    'userLeaderboardStore'
)(
    observer((props) => {
        const { data } = props;
        const { userProfile } = props.userStore;

        function viewUserLeaderboard() {
            props.onView();
        }

        function deleteUserLeaderboard() {
            props.onDelete();
        }

        function leaveLeaderboard() {
            props.onLeave();
        }

        function RenderButton() {
            if (userProfile.uid == data.owner)
                return (
                    <>
                        <button className="btn btn-social-icon " onClick={() => deleteUserLeaderboard()}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </>
                );
            else
                return (
                    <>
                        <button className="btn btn-social-icon " onClick={() => leaveLeaderboard()}>
                            <i className="fa fa-sign-out"></i>
                        </button>
                    </>
                );
        }

        return (
            <tr style={{ cursor: 'pointer' }}>
                <td onClick={props.onView}>
                    <div className="wrapper-leadeaderboard-name">
                        <p>
                            <span className="leaderboard-name">{data.name}</span>
                        </p>
                    </div>
                </td>
                <td onClick={props.onView}>
                    <div className="member">
                        <p>
                            <span className="member-name">{data.ownm}</span>
                        </p>
                    </div>
                </td>{' '}
                <td onClick={props.onView}>{formatNumber(data.mem)}</td> <td>{RenderButton()}</td>
            </tr>
        );
    })
);

export default CustomRankItem;
