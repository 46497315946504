import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { formatDistanceKM, formatDate } from '../../../../../utils';
import { formatDateString } from '../../../../../helpers/ConvertHelper';

const ChartData = inject(
    'globalStore',
    'chartStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { t, i18n } = useTranslation();
        const { listPersonalAchieve, doSetDataUserEvent } = props.chartStore;
        const [chartData, setChartData] = React.useState([]);
        const { language } = props.globalStore;

        const now = formatDate('YYYYMMDD', new Date());

        useEffect(() => {
            initChartData();
            getCurrentDayData();
        }, [listPersonalAchieve]);

        function initChartData() {
            let listData = [];
            _.forEach(listPersonalAchieve, (data) => {
                let listDur = [];
                _.forEach(data.chartData, (item) => {
                    if (item.day <= now) listDur.push(Math.round(item.rdis / 1000));
                });
                listData.push(listDur);
            });
            setChartData(listData);
        }

        function getCurrentDayData() {
            let listData = [];
            let index = _.findIndex(listPersonalAchieve[0].dyls, (item) => {
                return item.day == now;
            });

            index = index != -1 ? index : listPersonalAchieve[0].dyls.length - 1;

            _.forEach(listPersonalAchieve, (data) => {
                let obj = data.chartData[index];
                listData.push(obj);
            });
            doSetDataUserEvent(listData);
        }

        function onSelectDay(day) {
            let listData = [];
            _.forEach(listPersonalAchieve, (data) => {
                let obj = data.chartData[day];
                listData.push(obj);
            });
            doSetDataUserEvent(listData);
        }

        function renderTooltip(day) {
            let listStr = [];
            _.forEach(listPersonalAchieve, (data) => {
                let obj = data.chartData[day];
                const str = obj ? formatDateString(obj.day, language) + ' : ' + formatDistanceKM(obj.rdis) + ' km' : '';
                listStr.push(str);
            });
            return listStr;
        }

        function renderLable() {
            let list = [];
            let index = 1;
            for (let i = 1; i <= listPersonalAchieve[0].dyls.length; i++) {
                if (i % 7 == 0) {
                    list.push(t('global.week') + ' ' + index);
                    index++;
                } else list.push('');
            }
            return list;
        }

        const plugins = [
            {
                afterDraw: ({ chart }) => {
                    // eslint-disable-next-line no-underscore-dangle
                    if (chart.tooltip._active && chart.tooltip._active.length > 0) {
                        // draw vertical line
                        const activePoint = chart.tooltip._active[0];
                        const { ctx } = chart;
                        const { x } = activePoint._view;
                        const topY = activePoint._yScale.top;
                        const bottomY = activePoint._yScale.bottom;

                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#ffab00';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        ];

        const data = (canvas) => {
            const ctx = canvas.getContext('2d');
            const gradient1 = ctx.createLinearGradient(50, 0, 0, 200);
            gradient1.addColorStop(0, '#ffab00');
            gradient1.addColorStop(1, '#fff');

            const gradient2 = ctx.createLinearGradient(50, 0, 0, 200);
            gradient2.addColorStop(0, '#36cfbb');
            gradient2.addColorStop(1, '#fff');

            return {
                labels: renderLable(),
                datasets: [
                    {
                        label: '2022',
                        backgroundColor: gradient1, // Put the gradient here as a fill color
                        borderColor: '#ffab00',
                        borderWidth: 2,
                        pointColor: '#fff',
                        pointStrokeColor: '#ffab00',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: '#ffab00',
                        data: chartData[0],
                        pointBorderColor: '#ffab00',
                        pointBackgroundColor: function(context) {
                            var index = context.dataIndex;
                            var value = context.dataset.data[index];
                            return index == chartData[0].length - 1 ? '#ffab00' : '#fff';
                        },
                        pointBorderWidth: 2,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: '#ffab00',
                        pointHoverBorderColor: '#FFE0B2',
                        pointHoverBorderWidth: 8,
                        pointRadius: function(context) {
                            var index = context.dataIndex;
                            var value = context.dataset.data[index];
                            return index == chartData[0].length - 1 ? 7 : 5;
                        },
                        pointHitRadius: 10
                    },
                    {
                        label: '2021',
                        backgroundColor: gradient2, // Put the gradient here as a fill color
                        borderColor: '#36cfbb',
                        borderWidth: 2,
                        pointColor: '#fff',
                        pointStrokeColor: '#36cfbb',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: '#36cfbb',
                        data: chartData[1],
                        pointBorderColor: '#36cfbb',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: '#36cfbb',
                        pointHoverBorderColor: '#E8F5E9',
                        pointHoverBorderWidth: 8,
                        pointRadius: 5,
                        pointHitRadius: 10
                    }
                ]
            };
        };

        var options = {
            onClick: (e, element) => {
                console.log('click', e);
                console.log('click1', element);

                if (element.length > 0) {
                    var ind = element[0]._index;
                    onSelectDay(ind);
                }
            },
            annotation: {
                annotations: [
                    {
                        drawTime: 'afterDatasetsDraw',
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x-axis-0',
                        value: 3.8,
                        borderWidth: 5,
                        borderColor: 'red',
                        label: {
                            content: 'TODAY',
                            enabled: true,
                            position: 'top'
                        }
                    }
                ]
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            //stepSize: maxVal/5,
                            min: 0
                            //max: 40
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Km'
                        },
                        position: 'bottom'
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        return t('global.km_in_day');
                        //let index  = tooltipItem[0]['index'];
                    },
                    label: function(tooltipItem, data) {},
                    afterBody: function(data) {
                        return renderTooltip(data[0].index);
                    }
                },
                backgroundColor: '#E3F2FD',
                titleFontSize: 16,
                titleFontColor: '#0066ff',
                bodyFontColor: '#000',
                bodyFontSize: 14,
                displayColors: false
            },
            legend: {
                display: false
            },
            responsive: true,
            //option for line instead of curves
            elements: {
                line: {
                    tension: 0
                }
            },
            animation: {
                easing: 'easeInOutBack'
            }
        };

        if (chartData && chartData[0])
            return (
                <div>
                    <Line data={data} options={options} plugins={plugins} height={125} />
                </div>
            );
    })
);

export default ChartData;
