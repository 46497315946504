import React, { useEffect, useState, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { stripedHtml, formatDate } from '../../../../utils';
import { EVENT_LOCK_STATUS, LEADERBOARD_TYPE } from '../../../../helpers/ConstDefined';
import { GENDER_TYPE } from '../../../../data/master';
import RankMemberItem from './RankMemberItem';
import { useTranslation } from 'react-i18next';
import { DialogUserEvent, DialogDeleteAccount } from 'views/YourInfo/components';
import $ from 'jquery';
import { isAccountDeleted } from 'components/MemberName';

let profileData;

const RankMemberList = inject(
    'eventStore',
    'globalStore',
    'teamStore',
    'userStore',
    'teamManageStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { userTeam } = props.teamStore;
        const { appVersion, teamSexTab, doSetTeamSexTab } = props.globalStore;
        const { userProfile, meRank, doGetMeRank } = props.userStore;
        const { currentEvent } = props.eventStore;
        const {
            loading,
            listLeaderboardMember,
            totalLeaderboradMember,
            doGetListLeaderboardMember
        } = props.teamManageStore;

        const { t } = useTranslation();

        //filter params
        const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));
        const [sexLabelFilter, setSexLabelFilter] = useState(GENDER_TYPE[0].title);
        const [name, setName] = React.useState('');
        const [day, setDay] = React.useState(null);
        const isShowLoadMore =
            listLeaderboardMember == null ? false : listLeaderboardMember.length < totalLeaderboradMember;
        const showClearName = name && name.length > 0;

        const [openDialogUserEvent, setOpenDialogUserEvent] = React.useState(false);
        const [openDialogDeleteAccount, setOpenDialogDeleteAccount] = React.useState(false);

        useEffect(() => {
            getLeaderboardMember(teamSexTab);
        }, []);

        useEffect(() => {
            getMeRank();
        }, [userProfile, currentEvent && currentEvent.tmid]);

        useEffect(() => {
            if (day != null) {
                getMeRank();
                searchMember(); // auto search when day change
            }
        }, [day]);

        useEffect(() => {
            initSticky();
        }, []);

        function initSticky() {
            var wScreen = $(window).width();
            if (wScreen > 1050) {
                $('#sticker').sticky({ topSpacing: 144, center: true, className: 'sticker-box' });
            } else {
                $('#sticker').sticky({ topSpacing: 98, center: true, className: 'sticker-box' });
            }
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const resetParamsFilter = () => {
            //reset filter when switch tab
            setName('');
            setDay(null);
        };

        const handleChangeTab = (tab) => {
            doSetTeamSexTab(tab.id);
            getLeaderboardMember(tab.id);
            setSexLabelFilter(tab.title);
            resetParamsFilter();
        };

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);
            setDayText(dayText);
            setDay(day);
        };

        async function getMeRank() {
            if (!userProfile || !userTeam || userTeam.tmid != teamID) return;
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.TEAM,
                day,
                value: userProfile && userProfile.uid,
                tmid: teamID
            };
            doGetMeRank(params);
        }

        function getLeaderboardMember(category, isLoadMore) {
            let data = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.TEAM,
                sex: category,
                value: teamID
            };
            doGetListLeaderboardMember(data, isLoadMore);
        }

        function searchMember() {
            const data = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.TEAM,
                sex: teamSexTab,
                day,
                name,
                value: teamID
            };
            doGetListLeaderboardMember(data);
        }

        const getLeaderboardMemberMore = () => {
            getLeaderboardMember(teamSexTab, true);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchMember();
            }
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getLeaderboardMemberMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function MenuSexItem(props) {
            const { data } = props;

            return <li onClick={() => handleChangeTab(data)}>{t(data.title)}</li>;
        }

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{dayText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        const onViewUserEvent = (user) => {
            profileData = user;

            if (isAccountDeleted(user))
                setOpenDialogDeleteAccount(true);
            else setOpenDialogUserEvent(true);
        };

        const showMeRank = () => {
            if (meRank != null && meRank.length > 0 && userProfile) {
                if (meRank[0].rank > 0)
                    if (userProfile.sex == teamSexTab || teamSexTab == 0) {
                        if (currentEvent && currentEvent.tmid == teamID) return true;
                    }
            }
            return false;
        };

        return (
            <div>
                <h2 className="team-details--title">{t('team.manage.team_leaderboard')}</h2>
                <div className="filter-block">
                    <div className="filter-box">
                        <p className="lb">{t('global.bxh_by')}</p>
                        <div className="uiselector">
                            <p
                                className="uiselector--click"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                {t(sexLabelFilter)}
                            </p>
                            <ul className="uiselector--list" aria-labelledby="list-server-choose">
                                {GENDER_TYPE.map((item) => {
                                    return <MenuSexItem data={item} key={item.id} />;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder={t('global.search_member_name')}
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        <button className="search-btn" onClick={() => searchMember()}>
                            {t('global.search')}
                        </button>
                    </div>
                </div>

                <div className="members-list">
                    <div className="sticky" id="sticker">
                        <ul className="title-row">
                            <li className="level">{t('global.rank')}</li>
                            <li className="name">{t('global.member_name')}</li>
                            <li className="date">
                                <DatePicker onChange={handleChangeDate} customInput={<CustomInputDate />} />
                            </li>
                            <li className="sum">{t('global.total_km')}</li>
                        </ul>
                        <ul className="list">
                            {showMeRank() ? (
                                <RankMemberItem
                                    key={meRank[0].id}
                                    data={meRank[0]}
                                    onViewUserEvent={onViewUserEvent}></RankMemberItem>
                            ) : null}
                        </ul>
                    </div>
                    <ul className="list">
                        {listLeaderboardMember != null &&
                            listLeaderboardMember.map((item, index) => (
                                <RankMemberItem
                                    key={item.id}
                                    data={item}
                                    rank={index + 1}
                                    onViewUserEvent={onViewUserEvent}></RankMemberItem>
                            ))}
                    </ul>
                    <LoadMore />
                </div>

                {profileData ? (
                    <DialogUserEvent
                        openDialogUserEvent={openDialogUserEvent}
                        profileData={profileData}
                        eventID={eventID}
                        teamID={teamID}
                        onClose={() => setOpenDialogUserEvent(false)}
                    />
                ) : null}
                <DialogDeleteAccount
                    openDialogDeleteAccount={openDialogDeleteAccount}
                    onClose={() => setOpenDialogDeleteAccount(false)}
                />
            </div>
        );
    })
);

export default RankMemberList;
