import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, getImageResource } from '../../../../../utils';

const MemberItem = inject('authStore')(
    observer((props) => {
        const { data } = props;
        const rankImg = data.diff > 0 ? '../../../img/arrow-up.png' : '../../../img/arrow-down.png';
        const genderImg = data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';
        const ava = data.ava.replace('.png', '@1x.png').replace('.jpeg', '@1x.jpeg');

        if (data != null)
            return (
                <tr style={{cursor: "pointer"}} onClick={props.onViewProfileUser}>
                    <td>
                        <strong>{data.rank}</strong>
                    </td>
                    <td>
                        {data.diff != 0 ? (
                            <div className="status">
                                <img src={rankImg} alt="" />
                                <span>{Math.abs(data.diff)}</span>
                            </div>
                        ) : null}
                    </td>

                    <td>
                        <div className="member">
                            <div
                                className="pull-left wrapper-avatar-member"
                                style={{ backgroundImage: `url(${getImageResource(data.ava, true, true)})` }}>
                                <img src="../../../img/avatar.png" alt="image" />
                                <span className="gender">
                                    <img className="gender" src={genderImg} alt="image" />
                                </span>
                            </div>
                            <div className="pull-left ">
                                <p>
                                    <span className="member-name">{data.name}</span>
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>{formatDistanceKM(data.ddis)}</td>
                    <td>{formatDistanceKM(data.dis)}</td>
                </tr>
            );
        else return null;
    })
);

export default MemberItem;
