import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatNumber, getImageResource } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const Member1000 = inject(
    'userProfileStore',
    'userStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { currentTeam } = props.teamStore;
        const { userProfile, listTop3User, doGetTop3User } = props.userStore;
       
        useEffect(() => {
            getTop3User();
        }, [currentTeam.jsts]);

        const { t } = useTranslation();

        function getTop3User() {
            let params = {
                uid: userProfile && userProfile.uid,
                tmid: teamID,
                sort: 0, //theo created date
                order: 1,
                size: 3
            };
            doGetTop3User(params);
        }

        function UserItem(props) {
            const { data } = props;
            return (
                <li>
                    <Link to={`user-profile?evid=${eventID}&uid=${data.uid}`}>
                        <p class="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </p>
                        <div class="details">
                            <p class="name">{data.name}</p>
                            <p class="addr">
                                {data.city} - {data.ctry}
                            </p>
                        </div>
                    </Link>
                </li>
            );
        }

        return (
            <>
                <div class="profile-wbox m-list">
                    <h2 class="profile-title">
                       {t('global.member1')} <span class="num">({formatNumber(currentTeam.mem)})</span>
                    </h2>
                    <div class="profile-members-list">
                        <ul>
                        {listTop3User != null &&
                            listTop3User.map((item) => <UserItem key={item.uid} data={item}></UserItem>)}
                        </ul>
                        <div class="btn-row">
                            <a href="#see-all-users" class="seemore-btn">
                                <span>{t('global.seamore')}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
export default Member1000;
