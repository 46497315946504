import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../utils';
import UserProfile from 'views/UserProfile';

const UserSearchItem = inject(
    'userStore',
    'authStore',
    'userLeaderboardStore'
)(
    observer((props) => {
        const { data } = props;
        const { doAddMemberTemp, doAddMember } = props.userLeaderboardStore;
        const { userProfile } = props.userStore;

        useEffect(() => {}, []);

        function addMember() {
            doAddMemberTemp(data);
            doAddMember(data);
        }

        const genderImg = data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';

        return (
            <tr>
                <td>
                    <div className="member">
                        <div
                            className="pull-left wrapper-avatar-member"
                            style={{ backgroundImage: `url('${getImageResource(data.ava, true, true)}')` }}>
                            <img src="../../../img/avatar.png" alt="image" />
                            <span className="gender">
                                <img className="gender" src={genderImg} alt="image" />
                            </span>
                        </div>

                        <div className="pull-left ">
                            <p>
                                <span className="member-name">{data.name}</span>
                            </p>
                        </div>
                    </div>
                </td>
                <td>
                    {data.uid != userProfile.uid ? (
                        <button type="button" className="btn btn-outline-primary btn-follow" onClick={() => addMember()}>
                            Thêm
                        </button>
                    ) : null}
                </td>
            </tr>
        );
    })
);

export default UserSearchItem;
