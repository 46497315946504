import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const NotActivity = inject('userStore')(
    observer((props) => {
        const { doSetFunc } = props.userStore;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        return (
            <div>
                <div class="yours--activity">
                    <div class="title-row">
                        <h2 class="yours--title">
                            {t('global.list_act_valid')} <span class="num">(0)</span>
                        </h2>
                        <a href="javascript:void(0);" onClick={() => doSetFunc(1)} class="seemore">
                            {t('global.list_act_invalid')}
                        </a>
                    </div>
                    <div class="activity-empty">
                        <p class="img">
                            <img src="../../../images/imgs/no_member.png" alt="" />
                        </p>
                        <p class="title">{t('global.not_act_valid')}</p>
                        <p class="note">{t('global.not_act_valid_desc').replace('2021','2022')}</p>
                    </div>
                </div>
                <div class="yours--download-app">
                    <div class="download-app-box">
                        <div class="l-box">
                            <p class="title">{t('global.download_app')}</p>
                            <p class="txt">{t('global.run_with_us')}</p>
                            <div class="app-list">
                                <a href="https://apps.apple.com/us/app/id1476173971" class="app-store"></a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=vn.vng.uprace"
                                    class="gg-play"></a>
                            </div>
                        </div>
                        <div class="qr-code">
                            <img src="../../img/footer/qrcode.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default NotActivity;
