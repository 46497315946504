import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource, convertTimestamptoDate } from 'utils';
import Countdown from 'react-countdown';
import moment from 'moment';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { EVENT_STATUS_TYPE } from 'helpers/ConstDefined';

var startDate, endDate;
const EventHeader = inject('eventStore')(
    observer((props) => {
        let { eventData } = props;

        const { t } = useTranslation();
        const childRef = useRef();

        const Completionist = () => <span>{t('eu.event_end_msg')}</span>;

        useEffect(() => {
            return () => {
                //doDeleteCurrentEvent();
            };
        }, []);

        const d1 = moment(eventData.betm);
        const d2 = moment(eventData.entm);

        startDate = new Date(d1.year(), d1.month(), d1.date(), d1.hour(), d1.minute(), d1.second(), 0);
        endDate = new Date(d2.year(), d2.month(), d2.date(), d2.hour(), d2.minute(), d2.second(), 0);

        const [countDate, setCountDate] = React.useState(startDate);
        const [title, setTitle] = React.useState(t('ue.soon'));
        const [finish, setFinish] = React.useState(false);

        const rendererCountDown = ({ api, days, hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                if (!finish) {
                    setFinish(true);
                    setCountDate(endDate);
                    setTitle(t('ue.ready'));
                    api.start();
                } 
                //eventData.sts = EVENT_STATUS_TYPE.DONE;
                return <Completionist />;
            } else {
                // Render a countdown
                return (
                    <>
                        <div class="title">{title.toUpperCase()}</div>
                        <div class="countdown">
                            <div class="countdown-item countdown-days">
                                <div class="unit">{days.toString().padStart(2, '0')}</div>
                                <div class="lb">{t('global.day')}</div>
                            </div>
                            <div class="countdown-item countdown-hour">
                                <div class="unit">{hours.toString().padStart(2, '0')}</div>
                                <div class="lb">{t('global.hour')}</div>
                            </div>
                            <div class="countdown-item countdown-minute">
                                <div class="unit">{minutes.toString().padStart(2, '0')}</div>
                                <div class="lb">{t('global.minute')}</div>
                            </div>
                            <div class="countdown-item countdown-second">
                                <div class="unit">{seconds.toString().padStart(2, '0')}</div>
                                <div class="lb">{t('global.second')}</div>
                            </div>
                        </div>
                        {finish ? (
                            <div class="start-date">
                                {convertTimestamptoDate(eventData.betm, 'DD/MM/YYYY')} -{' '}
                                {convertTimestamptoDate(eventData.entm, 'DD/MM/YYYY')}
                            </div>
                        ) : null}
                    </>
                );
            }
        };

        return (
            <div class="event-detail-header">
                <div class="info">
                    <div class="info--banner">
                        <img src={getImageResource(eventData.cover)} alt="Banner" class="banner-img" />
                        <div class="banner-overlay">
                            <Countdown ref={childRef} autoStart={true} date={countDate} renderer={rendererCountDown} />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default EventHeader;
