+function ($) {
    'use strict';
    let canvas = $('.offcanvas');
    const body = document.body;
    const html = document.html;

    $('body,html').click(function(e){
        if (canvas.length && canvas.hasClass('show')) {
            $(canvas).removeClass('show');
            $(".offcanvas-backdrop.show").remove();
            $('html').css({overflow: 'auto'});
            body.classList.remove("lock-scrollbar");
            /*html.classList.remove("lock-scrollbar");
            body.classList.remove("lock-scrollbar");*/
        }
    });

    canvas.click(function (e) {
        e.stopPropagation();
    });

    $('[data-toggle="offcanvas"]').on('click', function (e) {
        e.stopPropagation();
        let canvas = $(this).attr("data-target");
        if (canvas) {
            $(canvas).addClass('show');
            $(canvas).after("<div class=\"offcanvas-backdrop show\"></div>");
            $('html').css({overflow: 'hidden'});
            body.classList.add("lock-scrollbar");
            /*html.classList.add("lock-scrollbar");
            body.classList.add("lock-scrollbar");*/
        }
    });

    $('[data-dismiss="offcanvas"]').on('click', function (e) {
        e.stopPropagation();
        let canvas = $(this).closest('.offcanvas');

        if (canvas) {
            $(canvas).removeClass('show');
            $(".offcanvas-backdrop.show").remove();
            $('html').css({overflow: 'auto'});
            body.classList.remove("lock-scrollbar");
            /*html.classList.remove("lock-scrollbar");
            body.classList.remove("lock-scrollbar");*/
        }
    });
}(jQuery);