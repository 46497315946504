import React, { useEffect, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { formatDistanceKM, formatNumber, getImageResource, formatDate } from '../../../../../utils';
import DatePicker from 'react-datepicker';

const MemberTable = inject(
    'globalStore',
    'chartStore',
    'userStore'
)(
    observer((props) => {
        const {
            doSetActiveMember,
            listUserCompare,
            startDate,
            doSetStartDate,
            startDateText,
            doSetStartDateText,
            doGetDisByDayUserCompare
        } = props.chartStore;
        const { t } = useTranslation();

        useEffect(() => {}, []);

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);

            doSetStartDate(val);
            doSetStartDateText(dayText);
            doGetDisByDayUserCompare(day);
        };

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{startDateText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        const onSelectMember = (rank) => {
            doSetActiveMember(rank)
        }

        function MemberItem(props) {
            const { data, color, rank, ddis } = props;
            return (
                <li onClick={() => onSelectMember(rank)}>
                    <a href="javascript:void(0);">
                        <div class="level">{formatNumber(rank)}</div>
                        <div class="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </div>
                        <div class="name-box">
                            <p class="name">{data.name}</p>
                            <p class="color">
                                <span class="dot" style={{ backgroundColor: color }}></span>
                            </p>
                        </div>
                        {ddis ? <div class="date">{formatDistanceKM(ddis.rdis)}</div> : null}
                        <div class="sum">{formatDistanceKM(data.rdis)}</div>
                    </a>
                </li>
            );
        }

        return (
            <div class="user-list">
                <ul class="title-row">
                    <li class="level">{t('global.rank')}</li>
                    <li class="name">{t('global.member_name')}</li>
                    <li class="date">
                        <DatePicker
                            onChange={handleChangeDate}
                            selected={startDate}
                            customInput={<CustomInputDate />}
                        />
                    </li>
                    <li class="sum">{t('global.total_km')}</li>
                </ul>
                <ul class="list">
                    {listUserCompare &&
                        listUserCompare.map((item, i) => {
                            return (
                                <MemberItem
                                    data={item}
                                    key={item.uid}
                                    ddis={item.ddis}
                                    color={item.color}
                                    rank={i + 1}
                                />
                            );
                        })}
                </ul>
            </div>
        );
    })
);

export default MemberTable;
