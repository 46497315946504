import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { stripedHtml } from '../../../../utils';
import md5 from 'blueimp-md5';
import { useTranslation } from 'react-i18next';

const ChangePass = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { navigation } = props;
        const { doLogout } = props.authStore;
        const { userProfile, doChangePass, doClearUserProfile } = props.userStore;
        const { setMessageSnackbar, setSnackbarType, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        const [oldPass, setOldPass] = useState('');
        const [pass, setPass] = useState('');
        const [confirm, setConfirm] = useState('');
        const [msgErr, setMsgErr] = useState('');

        const [errOldPass, setErrOldPass] = useState(false);
        const [errPass, setErrPass] = useState(false);
        const [errConfirm, setErrConfirm] = useState(false);

        const handleChangeOldPass = (val) => {
            setOldPass(val);
        };

        const handleChangePass = (val) => {
            setPass(val);
        };

        const handleChangeConfirm = (val) => {
            setConfirm(val);
        };

        const handleClearOldPass = () => {
            setOldPass('');
        };

        const handleClearPass = () => {
            setPass('');
        };

        const handleClearConfirm = () => {
            setConfirm('');
        };

        //show clear
        const showClearConfirm = confirm && confirm.length > 0;
        const showClearPass = pass && pass.length > 0;
        const showClearOldPass = oldPass && oldPass.length > 0;

        function validInput() {
            let hasError = false;
            if (!oldPass || oldPass.length < 6) {
                setErrOldPass(true);
                hasError = true;
            } else setErrOldPass(false);

            if (!pass || pass.length < 6) {
                setErrPass(true);
                hasError = true;
            } else setErrPass(false);

            if (!confirm || confirm.length < 6) {
                setErrConfirm(true);
                setMsgErr(t('settings.changepass.error.confirm'));
                return true;
            } else setErrConfirm(false);

            if (oldPass == pass) {
                setErrConfirm(true);
                setMsgErr(t('settings.changepass.error.old_new'));
                return true;
            } else setErrConfirm(false);

            if (pass != confirm) {
                setErrConfirm(true);
                setMsgErr(t('settings.changepass.error.pass_confirm'));
                return true;
            } else setErrConfirm(false);

            return hasError;
        }

        function logOut() {
            doClearUserProfile();
            doLogout();
            navigation.push('/login');
        }

        async function changePass() {
            if (!validInput()) {
                setMsgErr('');
                const params = {
                    userId: userProfile.uid,
                    oldpass: md5(oldPass),
                    newpass: md5(pass)
                };
                let result = await doChangePass(params);
                if (result) {
                    setMessageSnackbar(t('settings.changepass.change_success'));
                    setSnackbarType('success');
                    onOpenSnackbar();
                    logOut();
                } else {
                    setMessageSnackbar(t('settings.changepass.change_fail'));
                    setSnackbarType('error');
                    onOpenSnackbar();
                }
            }
        }

        if (userProfile != null)
            return (
                <div class="profile-wbox statistical">
                    <h2 class="profile-title">{t('settings.changepass.change_pass')}</h2>
                    <div class="profile-frm">
                        <div class="frm-block">
                            <div class="frm-row">
                                <p class="lb">{t('settings.changepass.oldpass')}</p>
                                <div class="frm-txt active">
                                    <input
                                        type="text"
                                        placeholder=""
                                        type="password"
                                        value={oldPass}
                                        onChange={(text) => handleChangeOldPass(stripedHtml(text.target.value))}
                                    />
                                    {errOldPass ? (
                                        <p class="error-tag">{t('settings.changepass.error.oldpass')}</p>
                                    ) : null}
                                </div>
                            </div>

                            <div class="frm-row">
                                <p class="lb">{t('settings.changepass.newpass')}</p>
                                <div class="frm-txt active">
                                    <input
                                        type="text"
                                        placeholder=""
                                        type="password"
                                        value={pass}
                                        onChange={(text) => handleChangePass(stripedHtml(text.target.value))}
                                    />
                                    {errPass ? <p class="error-tag">{t('settings.changepass.error.pass')}</p> : null}
                                </div>
                            </div>

                            <div class="frm-row">
                                <p class="lb">{t('settings.changepass.input_newpass_again')}</p>
                                <div class="frm-txt active">
                                    <input
                                        type="text"
                                        placeholder=""
                                        type="password"
                                        value={confirm}
                                        onChange={(text) => handleChangeConfirm(stripedHtml(text.target.value))}
                                    />
                                    {errConfirm ? <p class="error-tag">{msgErr}</p> : null}
                                </div>
                            </div>
                            <div class="frm-row" style={{ justifyContent: 'center' }}>
                                <div class="process-btn">
                                    <button class="btn-40 blue" style={{ width: 260 }} onClick={() => changePass()}>
                                        {t('global.confirm')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return null;
    })
);

export default ChangePass;
