import React from 'react';
import { inject, observer } from 'mobx-react';
import { getTypeName, formatNumber, getImageResource } from '../../../../utils';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';
import { GLOBAL_CONFIG, COMMON_ERROR } from '../../../../helpers/ConstDefined';
import { TEAM_MSG } from '../../../../helpers/MsgDefined';
import _ from 'lodash';

const TeamItem = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data } = props;
        const { userProfile } = props.userStore;
        const { doJoinLeaveTeam } = props.teamStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        async function joinLeaveTeam(action) {
            const params = {
                uid: userProfile.uid,
                tmid: data.tmid,
                action
            };
            let result = await doJoinLeaveTeam(params);
            if (result == COMMON_ERROR.EVENT.EVENT_READY || result == COMMON_ERROR.TEAM.LEAVE_TEAM_EVENT_READY) {
                setMessageSnackbar(TEAM_MSG.LEAVE_TEAM_FAIL_EVENT_READY);
                setSnackbarType('success');
                onOpenSnackbar();
            } else if (result == COMMON_ERROR.TEAM.OWNER_CODE) {
                setMessageSnackbar(TEAM_MSG.LEAVE_TEAM_FAIL_OWNER);
                setSnackbarType('success');
                onOpenSnackbar();
            } else if (result) {
                const msg = action == 0 ? TEAM_MSG.JOIN_TEAM_SUCCESS : TEAM_MSG.LEAVE_TEAM_SUCCESS;
                setMessageSnackbar(msg);
                setSnackbarType('success');
                onOpenSnackbar();
                if (action == 0) userProfile.tmls.push(data);
                else
                    _.remove(userProfile.tmls, (item) => {
                        return item.tmid == data.tmid;
                    });
            } else {
                const msg = action == 0 ? TEAM_MSG.JOIN_TEAM_FAIL : TEAM_MSG.LEAVE_TEAM_FAIL;
                setMessageSnackbar(msg);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const ava =
            data != null && data.ava.length > 0
                ? data.ava.replace('.png', '@1x.png').replace('.jpeg', '@1x.jpeg')
                : '../../../img/ic_avatar.png';

        return (
            <tr>
                <td>
                    <div className="team" onClick={props.onViewDetail}>
                        <div className="pull-left wrapper-avatar-team" style={{ backgroundImage: `url(${getImageResource(data.ava,true)})` }}>
                            <img src={getImageResource(data.ava,true)} alt="" />
                        </div>

                        <div className="pull-left ">
                            <p>
                                <span className="team-name">{data.name}</span>
                            </p>
                            <p>
                                <span className="number-participants">{formatNumber(data.mem)} VDV</span>
                            </p>
                        </div>
                    </div>
                </td>
                <td>
                    <p className="row-table">{getTypeName(TEAM_TYPE, data.lgid)}</p>
                </td>
                <td>
                    <p className="row-table">{getTypeName(SPORT_TYPE, data.sport)}</p>
                </td>
                {data.jsts != 3 ? (
                    <td>
                        <div className="row-table">
                            {data.jsts != 0 ? (
                                <button
                                    className="btn btn-outline-primary btn-join-team"
                                    onClick={() => joinLeaveTeam(1)}>
                                    Đã tham gia{' '}
                                </button>
                            ) : (
                                <button className="btn btn-primary btn-join-team" onClick={() => joinLeaveTeam(0)}>
                                    Tham gia{' '}
                                </button>
                            )}
                        </div>
                    </td>
                ) : (
                    <td></td>
                )}
            </tr>
        );
    })
);

export default TeamItem;
