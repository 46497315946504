import React from 'react';
import { inject, observer } from 'mobx-react';
import { FOLLOW_STT } from '../../../../helpers/ConstDefined';
import { KEY } from '../../../../helpers/api/APIDefined';
import { getImageResource } from '../../../../utils';

const MemberItem = inject(
    'userProfileStore',
    'userStore'
)(
    observer((props) => {
        const { data, navigation } = props;
        const { userProfile, doFollow } = props.userStore;
        const { doSetProfileUserID } = props.userProfileStore;

        const genderImg = null; // data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';
        const ava =
            data != null && data.ava.length > 0
                ? data.ava.replace('.png', '@1x.png').replace('.jpeg', '@1x.jpeg')
                : '../../../img/ic_avatar.png';

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
            if (result) {
            } else {
            }
        }

        const handleViewProfileUser = () => {
            sessionStorage.setItem(KEY.ID_PROFILE_USER, data.uid);
            doSetProfileUserID(data.uid);
            navigation.push('/user-profile');
        };

        if (data != null)
            return (
                <div className="user-group">
                    <div className="row">
                        <div className="col-6 " onClick={() => handleViewProfileUser()}>
                            <div className="profile-users">
                                <div className="row">
                                    <div className="col-2">
                                        <div
                                            className="wrapper-avatar-member"
                                            style={{
                                                backgroundImage: `url(${getImageResource(data.ava, true, true)})`
                                            }}>
                                            <img src="../../img/avatar.png" alt="image" />
                                            {genderImg ? <img className="gender" src={genderImg} alt="image" /> : null}
                                        </div>
                                    </div>
                                    <div className="col-10">
                                        <div className="information-user">
                                            <p className="name-user">{data.name}</p>
                                            <p className="groups-user">
                                                {data.city} - {data.ctry}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 ">
                            <div className="custom-wrapper-btn">
                                <div className="custom-btn-group custom-btn-group-action" role="group">
                                    <button
                                        id="btnGroupDropAction11"
                                        type="button"
                                        className="btn btn-link dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"></button>
                                    {data.fists == 2 ? (
                                        userProfile.uid != data.uid ? (
                                            <button
                                                onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}
                                                type="button"
                                                className="btn btn-outline-primary btn-follow"
                                                style={{ width: 120 }}>
                                                Bỏ theo dõi
                                            </button>
                                        ) : null
                                    ) : userProfile.uid != data.uid ? (
                                        <button
                                            onClick={() => followUser(FOLLOW_STT.FOLLOW)}
                                            type="button"
                                            className="btn btn-primary btn-follow"
                                            style={{ width: 120 }}>
                                            Theo dõi
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return null;
    })
);

export default MemberItem;
