import React, { useEffect, forwardRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LEADERBOARD_TYPE, GROUP_JOIN_STATUS, GLOBAL_CONFIG } from '../../helpers/ConstDefined';
import GroupRankItem from './GroupRankItem';
import { stripedHtml } from '../../utils';
import DatePicker from 'react-datepicker';
import $ from 'jquery';
import { formatDate } from 'helpers/ConvertHelper';
import { GENDER_TYPE } from '../../data/master';

const GroupRankList = inject(
    'authStore',
    'userStore',
    'groupStore',
    'eventStore'
)(
    observer((props) => {
        const { eventData, groupData, navigation } = props;
        const { userProfile } = props.userStore;
        const {
            loadingGroup,
            totalMemberGroup,
            userRank,
            doGetUserRank,
            doClearUserRank,
            listLeaderboard,
            doGetListLeaderboard
        } = props.groupStore;

        //filter
        const [name, setName] = React.useState('');
        const isShowLoadMore = listLeaderboard == null ? false : listLeaderboard.length < totalMemberGroup;
        const cssShowClear = name && name.length > 0 ? 'search-box active' : 'search-box';
        const [day, setDay] = React.useState(null);
        const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));
        const [sexLabelFilter, setSexLabelFilter] = useState(GENDER_TYPE[0].title);
        const [sex, setSex] = React.useState(0);

        const isJoined =
            userProfile && groupData.jsts != GROUP_JOIN_STATUS.NONE && eventData.jsts != GROUP_JOIN_STATUS.REQUESTING;

        useEffect(() => {
            initSticky();
            getLeaderboardMember();
        }, []);

        useEffect(() => {
            //get rank of group when join and clear when leave
            if (isJoined) getUserRank();
            else doClearUserRank();
            getLeaderboardMember();
        }, [groupData.jsts]);

        useEffect(() => {
            if (day != null) {
                getUserRank();
                getLeaderboardMember(); // auto search when day change
            }
        }, [day]);

        const { t } = useTranslation();

        function initSticky() {
            var wScreen = $(window).width();
            if (wScreen > 1050) {
                $('#m-sticker').sticky({ topSpacing: 144, center: true, className: 'sticker-box' });
            } else {
                $('#m-sticker').sticky({ topSpacing: 98, center: true, className: 'sticker-box' });
            }
        }

        async function getUserRank() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.USER_GROUP,
                value: userProfile && userProfile.uid
            };
            doGetUserRank(params);
        }

        function getLeaderboardMember(sex, isLoadMore) {
            let data = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.USER_GROUP,
                value: groupData.grid,
                day,
                sex,
                name
            };
            doGetListLeaderboard(data, isLoadMore);
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getLeaderboardMember();
            }
        };

        const getMemberRankListMore = () => {
            getLeaderboardMember(sex, true);
        };

        const handleChangeTab = (tab) => {
            setSex(tab.id);
            getLeaderboardMember(tab.id);
            setSexLabelFilter(tab.title);
            //resetParamsFilter();
        };

        function MenuSexItem(props) {
            const { data } = props;
            return <li onClick={() => handleChangeTab(data)}>{t(data.title)}</li>;
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberRankListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        const onViewUserEvent = (user) => {
            navigation.push(`user-detail?uid=${user.id}&evid=${eventData.evid}`);
        };

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);
            setDayText(dayText);
            setDay(day);
        };

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{dayText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        return (
            <div class="card card-details card-rank">
                <div class="card-details-header">
                    <div class="left">
                        <h4 class="title">{t('global.leaderboard')}</h4>
                    </div>
                    <div class="right">
                        <div class="filter-box">
                            <div class="uiselector gender">
                                <p
                                    class="uiselector--click"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    {t(sexLabelFilter)}
                                </p>
                                <ul class="uiselector--list" aria-labelledby="list-server-choose">
                                    {GENDER_TYPE.map((item) => {
                                        return <MenuSexItem data={item} key={item.id} />;
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-details-body">
                    <div class="frm-row">
                        <div class="frm-row__item search">
                            <input
                                type="text"
                                placeholder={t('global.search')}
                                value={name}
                                onKeyDown={handleKeyDown}
                                onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                            />
                        </div>
                        <div class="frm-row__item">
                            <button class="btn btn-40 btn-blue btn-search" onClick={() => getLeaderboardMember()}>
                                {t('global.search')}
                            </button>
                        </div>
                    </div>

                    <div class="teams-list">
                        <div class="sticky" id="sticker">
                            <ul class="title-row">
                                <li class="level">{t('global.rank')}</li>
                                <li class="name">{t('global.member_name')}</li>
                                <li class="date">
                                    <DatePicker onChange={handleChangeDate} customInput={<CustomInputDate />} />
                                </li>
                                <li class="sum">{t('global.total_km')}</li>
                            </ul>

                            <ul class="list">
                                {userRank && isJoined ? (
                                    <GroupRankItem onViewUserEvent={onViewUserEvent} data={userRank} />
                                ) : null}
                            </ul>
                        </div>
                        <ul class="list">
                            {listLeaderboard &&
                                listLeaderboard.map((item) => {
                                    return (
                                        <GroupRankItem onViewUserEvent={onViewUserEvent} data={item} key={item.id} />
                                    );
                                })}
                        </ul>
                        <LoadMore />
                    </div>
                </div>
            </div>
        );
    })
);

export default GroupRankList;
