import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
const queryString = require('query-string');
import { ManageTeam, TeamInfo, TeamDetail, ManagePost, ManageGroup, GroupDetail } from './components';
import { DialogSocial } from '../YourInfo/components';
import { stripedHtml } from 'utils';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

const TeamDetails = inject(
    'teamManageStore',
    'globalStore',
    'eventStore',
    'userStore',
    'groupStore',
)(
    observer((props) => {
        const { func } = props.teamManageStore;
        const { doSetMainTab } = props.globalStore;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { doGetGroupDetail } = props.groupStore;

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);

        useEffect(() => {
            window.scrollTo(0, 0);
            doSetMainTab(1);
        });

        useEffect(() => {
            if (currentEvent && currentEvent.grid != 0) getStatusGroupUser();
        }, [currentEvent]);

        async function getStatusGroupUser() {
            const params = {
                uid: userProfile && userProfile.uid,
                grid: currentEvent.grid
            };
            let result = await doGetGroupDetail(params);
            if (result) {
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid || !paramsQuery.tmid) return <Redirect to="/"></Redirect>;
        
        let eventID = stripedHtml(paramsQuery.evid);
        let teamID = stripedHtml(paramsQuery.tmid);

        function RenderContent() {
            let content;
            if (func == 0) content = <TeamDetail eventID={eventID} teamID={teamID} />;
            else if (func == 1) content = <ManageTeam eventID={eventID} teamID={teamID} />;
            else if (func == 2) content = <ManagePost eventID={eventID} teamID={teamID} />;
            else if (func == 3) content = <ManageGroup eventID={eventID} teamID={teamID} />;
            else if (func == 4) content = <GroupDetail eventID={eventID} teamID={teamID} />;
            return (
                <div>
                    <TeamInfo navigation={props.history} onChangeTeam={() => {}} eventID={eventID} teamID={teamID} />
                    {content}
                </div>
            );
        }
        return (
            <>
                <RenderContent />
                <DialogSocial
                    openDialogSocial={openDialogSocial}
                    onClose={() => setOpenDialogSocial(false)}
                    data={1}></DialogSocial>
            </>
        );
    })
);

export default TeamDetails;
