import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { PRIVATE_KEY, API } from '../../../../helpers/api/APIDefined';
import DialogConfirmConnect from '../DialogConfirmConnect';
import SyncIcon from '@material-ui/icons/Sync';
import { useTranslation } from 'react-i18next';

const STRAVA_SCOPE = 'read,activity:read_all';

const DeviceConnect = inject(
    'authStore',
    'connectStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { eventID } = props;
        const {
            stravaConnection,
            garminConnection,
            doGetConnectionStatus,
            doGetGarminToken,
            doStravaDisconnectAPI,
            doResyncStravaAPI
        } = props.connectStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [titleDialog, setTitleDialog] = React.useState('');
        const [messageDialog, setMessageDialog] = React.useState('');
        const [source, setSource] = React.useState('');
        const [isConnect, setIsConnect] = React.useState(false);
        const [openDialogConfirmConnect, setOpenDialogConfirmConnect] = React.useState(false);

        const handleOpenDialogConfirmConnect = (flag) => {
            setOpenDialogConfirmConnect(flag);
        };

        const { t } = useTranslation();
        useEffect(() => {
            if (userProfile != null) {
                getConnectionStatus();
            }
        }, []);

        function getConnectionStatus() {
            const params = {
                userId: userProfile.uid
            };
            doGetConnectionStatus(params);
        }

        function confirmConnect(source, isConnect) {
            const title = isConnect ? 'settings.deviceconnect.connect' : 'settings.deviceconnect.remove_connect';
            const extra = isConnect ? 'settings.deviceconnect.connect' : 'settings.deviceconnect.remove_connect';
            let message = t('settings.deviceconnect.confirm_action');
            message = message.replace('[extra]', t(extra));
            message = message.replace('[source]', source);

            setTitleDialog(title);
            setMessageDialog(message);
            setSource(source);
            setIsConnect(isConnect);
            setOpenDialogConfirmConnect(true);
        }

        const onAgree = () => {
            if (source == 'Strava') {
                if (isConnect) connectStrava();
                else disConnectStrava();
            } else {
                if (isConnect) connectGarmmin();
                else disConnectGarmin();
            }
            setOpenDialogConfirmConnect(false);
        };

        function connectStrava() {
            const URL_REDIRECT = API.DEVICE_CONNECT.URL_REDIRECT + `callback?extra=${userProfile.uid},0,${eventID}`;
            const url = `https://www.strava.com/oauth/authorize?client_id=${PRIVATE_KEY.STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${URL_REDIRECT}&scope=${STRAVA_SCOPE}&approval_prompt=force`;
            window.location.href = url;
        }

        async function reSyncStravaAPI() {
            const params = {
                userId: userProfile.uid,
                data: {
                    openId: stravaConnection.openId,
                    openType: stravaConnection.openType
                }
            };
            let result = await doResyncStravaAPI(params);
            if (!result) {
                setMessageSnackbar(t('settings.deviceconnect.sync_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            } else {
                setMessageSnackbar(t('settings.deviceconnect.sync_processing'));
                setSnackbarType('success');
                onOpenSnackbar();
            }
        }

        async function reSyncGarminAPI() {
            const params = {
                userId: userProfile.uid,
                data: {
                    openId: garminConnection.openId,
                    openType: garminConnection.openType
                }
            };
            let result = await doResyncStravaAPI(params);
            if (!result) {
                setMessageSnackbar(t('settings.deviceconnect.sync_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            } else {
                setMessageSnackbar(t('settings.deviceconnect.sync_processing'));
                setSnackbarType('success');
                onOpenSnackbar();
            }
        }

        async function connectGarmmin() {
            const params = {
                userId: userProfile.uid,
                step: 1
            };
            let result = await doGetGarminToken(params);
            if (result != null && result.length == 2) {
                const URL_REDIRECT =
                    API.DEVICE_CONNECT.URL_REDIRECT + `callback?extra=${userProfile.uid},1,${eventID},${result[1]}`;
                const url = `https://connect.garmin.com/oauthConfirm?oauth_token=${result[0]}&oauth_callback=${URL_REDIRECT}`;
                window.location.href = url;
            } else {
            }
        }

        async function disConnectStrava() {
            const params = {
                userId: userProfile.uid,
                data: {
                    openId: stravaConnection.openId,
                    openType: stravaConnection.openType
                }
            };
            let result = await doStravaDisconnectAPI(params);
            if (!result) {
                setMessageSnackbar(t('settings.deviceconnect.stop_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function disConnectGarmin() {
            const params = {
                userId: userProfile.uid,
                data: {
                    openId: garminConnection.openId,
                    openType: garminConnection.openType
                }
            };
            doStravaDisconnectAPI(params);
        }

        function StravaConnect() {
            if (stravaConnection == null)
                return (
                    <>
                        <a href="javascript:void(0);" onClick={() => confirmConnect('Strava', true)}>
                            <p class="logo">
                                <img src="../../../img/strava.png" alt="" />
                            </p>
                            <div class="details">
                                <p class="title">{t('settings.deviceconnect.connect_with')} Strava</p>
                                <p class="txt">{t('settings.deviceconnect.connect_with_desc')}</p>
                            </div>
                        </a>
                    </>
                );
            else
                return (
                    <>
                        <a href="javascript:void(0);">
                            <p class="logo">
                                <img src="../../../img/strava.png" alt="" />
                            </p>
                            <div class="details">
                                <div onClick={() => confirmConnect('Strava', false)}>
                                    <p class="title">{t('settings.deviceconnect.connecting')} Strava</p>
                                    <p class="txt">
                                        {t('global.account')}: {stravaConnection.openName || ''}
                                    </p>
                                </div>
                                <button
                                    class="btn-40 blue"
                                    style={{ height: 33, width: 200 }}
                                    onClick={() => reSyncStravaAPI()}>
                                    <SyncIcon />
                                    {t('settings.deviceconnect.sync_again')}
                                </button>
                            </div>
                        </a>
                    </>
                );
        }

        function GarminConnect() {
            if (garminConnection == null)
                return (
                    <a href="javascript:void(0);" onClick={() => confirmConnect('Garmin', true)}>
                        <p class="logo">
                            <img src="../../../img/garmin.png" alt="" />
                        </p>
                        <div class="details">
                            <p class="title">{t('settings.deviceconnect.connect_with')} Garmin</p>
                            <p class="txt">{t('settings.deviceconnect.connect_with_desc')}</p>
                        </div>
                    </a>
                );
            else
                return (
                    <a href="javascript:void(0);">
                        <p class="logo">
                            <img src="../../../img/garmin.png" alt="" />
                        </p>
                        <div class="details">
                            <div onClick={() => confirmConnect('Garmin', false)}>
                                <p class="title">{t('settings.deviceconnect.connecting')} Garmin</p>
                                <p class="txt">
                                    {t('global.account')}: {garminConnection.openId || ''}
                                </p>
                            </div>
                            <button
                                class="btn-40 blue"
                                style={{ height: 33, width: 200 }}
                                onClick={() => reSyncGarminAPI()}>
                                <SyncIcon />
                                {t('settings.deviceconnect.sync_again')}
                            </button>
                        </div>
                    </a>
                );
        }

        if (userProfile != null)
            return (
                <div class="profile-wbox connect">
                    <h2 class="profile-title">{t('settings.deviceconnect.title')}</h2>
                    <div class="profile-connect">
                        <p class="connect-title">{t('settings.deviceconnect.connect_activity')}</p>
                        <p class="connect-txt">{t('settings.deviceconnect.connect_activity_desc')}</p>
                        <ul class="connect-list">
                            <li>
                                <GarminConnect />
                            </li>
                            <li></li>
                        </ul>
                    </div>
                    {openDialogConfirmConnect == true ? (
                        <DialogConfirmConnect
                            openDialogConfirmConnect={openDialogConfirmConnect}
                            title={titleDialog}
                            message={messageDialog}
                            onAgree={onAgree}
                            onClose={() => setOpenDialogConfirmConnect(false)}
                        />
                    ) : null}
                </div>
            );
        else return null;
    })
);

export default DeviceConnect;
