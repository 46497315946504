import React, { useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { formatDate, stripedHtml } from '../../../../utils';
import _ from 'lodash';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LEADERBOARD_TYPE, SPORT_TYPE } from '../../../../helpers/ConstDefined';
import TeamRankItem from './TeamRankItem';

const TeamRankList = inject(
    'leaderboardStore',
    'eventStore',
    'globalStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { eventID } = props;
        const {
            loading,
            naviTab,
            totalTeam,
            nameFilter,
            doSetNameFilter,
            listLeaderboardTeam,
            doGetListLeaderboardTeam
        } = props.leaderboardStore;
        const { teamRank, userTeam, doGetTeamRank } = props.teamStore;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const { currentEvent } = props.eventStore;
        const [day, setDay] = React.useState(null);
        const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));

        const isShowLoadMore = listLeaderboardTeam == null ? false : listLeaderboardTeam.length < totalTeam;
        const cssShowClear = nameFilter && nameFilter.length > 0 ? 'search-box active' : 'search-box';

        const { t } = useTranslation();

        useEffect(() => {
            initSticker();
        }, []);

        useEffect(() => {
            if (day != null) {
                getTeamRank();
                searchLeaderboard(); // auto search when day change
            }
        }, [day]);

        useEffect(() => {
            getTeamRank();
        }, [userProfile, currentEvent && currentEvent.tmid]);

        function initSticker() {
            try {
                var wScreen = $(window).width();
                if (wScreen > 1050) {
                    $('#info-sticker').sticky({ topSpacing: 144, center: true, className: 'sticker-box' });
                    /*$(document).on('scroll', function() {
                        var footerScroll = $('.section--footer').position().top - $('.section--footer').outerHeight();
                        if ($(this).scrollTop() >= footerScroll) {
                            $('#info-sticker').fadeOut();
                        } else {
                            $('#info-sticker').fadeIn();
                        }
                    });*/
                } else {
                    $('#info-sticker').sticky({ topSpacing: 98, center: true, className: 'sticker-box' });
                    /*$(document).on('scroll', function() {
                        var hScroll = $('.r-side').position().top - 150;
                        if ($(this).scrollTop() >= hScroll) {
                            $('#info-sticker').fadeOut();
                        } else {
                            $('#info-sticker').fadeIn();
                        }
                    });*/
                }
            } catch (error) {}
        }

        function getLeaderboardTeam(isLoadMore) {
            const data = {
                size: appVersion && appVersion.pgsz,
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                name: nameFilter,
                day,
                value: naviTab.id
            };
            doGetListLeaderboardTeam(data, isLoadMore, true);
        }

        function searchLeaderboard() {
            const data = {
                size: appVersion && appVersion.pgsz,
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                name: nameFilter,
                day,
                value: naviTab.id
            };
            doGetListLeaderboardTeam(data, null, true);
        }

        function resetSearch() {
            doSetNameFilter('');
            const data = {
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                day,
                value: naviTab.id
            };
            doGetListLeaderboardTeam(data, null, true);
        }

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);
            setDayText(dayText);
            setDay(day);
        };

        const handleChangeName = (val) => {
            doSetNameFilter(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchLeaderboard();
            }
        };

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{dayText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        async function getTeamRank() {
            if (!userProfile || !currentEvent || currentEvent.tmid == 0) return;
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE,
                day,
                value: currentEvent && currentEvent.tmid
            };
            doGetTeamRank(params);
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getLeaderboardTeam(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        let totalMsg = t('global.total_team_in_board') + ' ' + t(naviTab.title);
        totalMsg = totalMsg.replace('[team]', totalTeam);

        return (
            <>
                <div class="joined-total">{totalMsg}</div>
                <div class="info-search">
                    <div class={cssShowClear}>
                        <button class="del-btn" onClick={() => resetSearch()}></button>
                        <input
                            type="text"
                            placeholder={t('global.search_tem_in_board') + ' ' + t(naviTab.title)}
                            value={nameFilter}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        <button class="search-btn" onClick={() => searchLeaderboard()}>
                            {t('global.search')}
                        </button>
                    </div>
                    {currentEvent && currentEvent.tmid == 0 ? (
                        <div class="join-to-team">
                            <Link to={`/team?evid=${eventID}`}>
                                <button class="btn-40 blue">
                                    <span class="txt">{t('global.join_team')}</span>
                                </button>
                            </Link>
                        </div>
                    ) : null}
                </div>

                <div class="info-teams-list">
                    <div class="sticky" id="info-sticker">
                        <ul class="title-row">
                            <li class="level">{t('global.rank')}</li>
                            <li class="name">{t('global.team_name')}</li>
                            <li class="date">
                                <DatePicker onChange={handleChangeDate} customInput={<CustomInputDate />} />
                            </li>
                            <li class="sum">{t('global.total_km')}</li>
                        </ul>
                        <ul class="list">
                            {teamRank && teamRank.length > 0 && userProfile && userTeam && userTeam.lgid == naviTab.id ? (
                                <TeamRankItem eventID={eventID} data={teamRank[0]} key={teamRank[0].tmid} />
                            ) : null}
                        </ul>
                    </div>
                    <ul class="list">
                        {listLeaderboardTeam &&
                            listLeaderboardTeam.map((item) => {
                                return <TeamRankItem eventID={eventID} data={item} key={item.tmid} />;
                            })}
                    </ul>
                    <LoadMore />
                </div>
            </>
        );
    })
);

export default TeamRankList;
