import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Topbar, Footer } from './components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Main = inject(
    'eventStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { children } = props;

        const [openSidebar, setOpenSidebar] = useState(false);
        const { messageSnackbar, onCloseSnackbar, openSnackbar, snackbarType } = props.globalStore;
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;
        const { mainTab, doSetMainTab } = props.globalStore;

        const MAIN_MENU = [
            {
                id: 0,
                title: 'global.main_menu.info',
                link: '/2022/info?evid=' + (currentEvent && currentEvent.evid)
            },
            {
                id: 1,
                title: 'global.main_menu.team',
                link:
                    userProfile != null && !_.isEmpty(userProfile)
                        ? '/2022/team?evid=' + (currentEvent && currentEvent.evid)
                        : '/login'
            },
            {
                id: 2,
                title: 'global.main_menu.you',
                link:
                    userProfile != null && !_.isEmpty(userProfile)
                        ? '/2022/you?evid=' + (currentEvent && currentEvent.evid)
                        : '/login'
            }
        ];

        const navigationMain = (tab) => {};

        const { t } = useTranslation();

        function MenuItem(props) {
            const { data } = props;
            const css = data.id == mainTab ? 'active' : '';
            return (
                <li className={css} onClick={() => doSetMainTab(data.id)}>
                    <Link to={data.link}>{t(data.title)}</Link>
                </li>
            );
        }

        return (
            <>
                <Helmet>
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css"
                    />
                    <script src="https://img.zing.vn/products/uprace/skin-2021/dist/main/main.js"></script>
                </Helmet>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={onCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={1500}>
                    <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>
                <div className="outer">
                    <div id="scrollContent" className="inner scrollContent scrollSwipe">
                        <main>
                            <section className="uprace-tabs-block">
                                <div className="uprace-tabs-menu" id="tabs-menu">
                                    <ul>
                                        {MAIN_MENU.map((data) => (
                                            <MenuItem key={data.id} data={data} />
                                        ))}
                                    </ul>
                                </div>
                                <div className="tabs-content uprace-tabs-content">
                                    <ul>
                                        <li className="active">{children}</li>
                                    </ul>
                                </div>
                            </section>

                            <Footer />
                        </main>
                    </div>
                </div>
                <p id="back-top" className="back-top">
                    <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                </p>
                <Topbar history={children} navigationMain={(tab) => navigationMain(tab)} />
            </>
        );
    })
);

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
