module.exports = {
    STATS_TYPE: {
        EVENT: 0,
        LEAGUE: 1,
        TEAM: 2,
        USER: 3,
        GROUP: 6
    },
    EVENT_JOIN_STATUS: {
        NONE: 0,
        REQUESTING: 1,
        MEMBER: 2,
        ADMIN: 3,
        INVITING: 4
    },
    EVENT_FORM_TYPE: {
        ALL : 0,
        GROUP: 1,
        PERSONAL: 2,
    },
    EVENT_HOME_TYPE: {
        HOME_EVENT: 0,
        YOUR_EVENT: 1,
        ALL_EVENT: 2,
        SEARCH_EVENT: 3,    
    },
    EVENT_DETAIL_TAB_TYPE: {
        INFO_TAB: 0,
        LEADERBOARD_GROUP: 1,
        LEADERBOARD_USER: 2,
        MANAGER_MEMBER: 3,
        MANAGER_GROUP: 4,
        MANAGER_COMPLAIN: 5,
    },
    EVENT_MEMBER_SORT_TYPE: {
        PERSONAL: 7,
        START_TIME: 4,
        END_TIME: 6,
        MEMBER: 3
    },
    SORT_VALUE_TYPE: {
        ASC: 0,
        DESC: 1
    },
    REPORT_ACTION: {
        USER_COMPLAIN: 0,
        USER_REPORT_OTHER: 1,
        ADMIN_ACCEPT_COMPLAIN: 2,
        ADMIN_REJECT_COMPLAIN: 3,
        ADMIN_ACCEPT_REPORT: 4,
        ADMIN_REJECT_REPORT: 5,
    },
    FEED_REPORT_TYPE: {
        ALL: 0,
        COMPLAIN: 1,
        REPORT: 2,
        PROCESSED: 3
    },
    FEED_REPORT_STATUS: {
        ALL: 0,
        COMPLAINING: 1,
        REPORTING: 2,
        COMPLAINED: 3,
        REPORTED: 4,
        DONE: 5
    },
    EVENT_STATUS: {
        NONE: 0,
        REGISTERING: 1,
        RUNNING: 2,
        FINISHED: 3,
        PAUSED: 4,
        CANCEL: 5,
        INACTIVE: 6
    },
    FEED_TYPE: {
        YOUR_ACTIVITY: 0,
        FOLLOWING_ACTIVITY: 1,
        TEAM_ACTIVITY: 2,
        ALL: 3
    },
    EVENT_COMPARE_TYPE: {
        USER: 0,
        GROUP: 1,
        TEAM: 2,
    },
    CREATE_POST_TYPE: {
        PERSONAL: 1,
        TEAM: 2,
        GROUP: 3,
        EVENT: 4,
    },
    GROUP_JOIN_STATUS: {
        NONE: 0,
        REQUESTING: 1,
        JOINED: 2,
        ADMIN: 3,
        INVITING: 4
    },
    GROUP_ACTION: {
        JOIN: 0,
        LEAVE: 1,
        USER_REQUEST_JOIN: 2,
        USER_CANCEL_JOIN: 3,
        ADMIN_APPROVE_JOIN: 4,
        ADMIN_REJECT_JOIN: 5,
        OWNER_GIVE_ADMIN: 6,
        OWNER_UNGIVE_ADMIN: 7,
        DELETE_MEMBER: 8,
        CHANGE_OWNER: 9,
        INVITE_MEMBER: 10,
        CANCEL_INVITE: 11,
        MEMBER_ACCEPT_INVITE: 12,
        MEMVER_CANCEL_INVITE: 13,
        MEMBER_CONFIRM_OTP: 14,
        REQUIRE_OTP_VIA_EMAIL: 15,
        REQUIRE_OTP_VIA_SMS: 16,
    },
    SOCIAL_TAB: {
        LIKE: 0,
        DISLIKE: 1,
        COMMENT: 2,
        FOLLOWING: 4,
        FOLLOWER: 5
    },
    LIKE_TYPE: {
        NONE : 0,
        UNLIKE: 1,
        LIKE: 2,
        LOVE: 3,
    },
    SOCIAL_TYPE: {
        POST: 0,
        FEED: 1,
    },
    ACTIVITY_TYPE: {
        ALL: 0,
        VALID: 1,
        INVALID: -1,
        COMPLAIN: 2,
        REPORT: 3,
    },
    LOGIN_TYPE: {
        FACEBOOK: 0,
        GOOGLE: 1,
        STRAVA: 2,
        EMAIL: 3,
        GARMIN: 4,
        APPLE: 5,
    },
    LOGIN_PLATFORM: {
        WEB: 0,
        IOS: 1,
        ANDROID: 2
    },
    CHALLENGE_TYPE: {
        YOU: 0,
        FOLLOWING: 1,
        TEAM: 2,
        ALL: 3
    },
    CHALLENGE_STT: {
        COMMITED: 0,
        DELETED: 1,
        WIN: 2,
        LOSE: 3
    },
    LEADERBOARD_TYPE: {
        LEAGUE: 0,
        TEAM: 1,
        FUND: 2,
        CUSTOM: 3,
        GROUP_TEAM: 4,
        USER_GROUP: 5,
        GROUP_EVENT: 6,
        USER_EVENT: 7
    },
    TEAM_TYPE_VAL: {
        ALL: 0,
        COMPANY: 1,
        CLUB: 2,
        SCHOOL: 3
    },
    GLOBAL_CONFIG: {
        EVENT_UPRACE_2020_ID: 2,
        EVENT_UPRACE_2021_ID: 5,
        EVENT_UPRACE_2022_ID: 6,
        EVENT_UPRACE_2023_ID: 8,
        PAGE_SIZE: 100,
    },
    USER_EVENT_STATUS: {
        NONE: 0,
        UNLOCK: 1,
        LOCK: 2
    },
    SPORT_TYPE_ACTIVITY: {
        ALL: 0,
        CYCLING: 1,
        RUNNING: 2,
        WALKING: 3,
        TRIATHLON: 4,
        SWIMMING: 5,
        OTHER: 6
    },
    GENDER_TYPE: {
        ALL: 0,
        MALE: 1,
        FEMALE: 2
    },
    JOIN_TEAM_STT: {
        NOT_JOIN : 0,
        WAITING: 1,
        MEMBER: 2,
        ADMIN: 3,
        INVITING: 4,
    },
    FOLLOW_STT: {
        FOLLOW: 0,
        UNFOLLOW: 1
    },
    STATISTICS_TYPE: {
        WEEK: 0,
        MONTH: 1,
        YEAR: 2
    },
    MEMBER_SEARCH_TYPE: {
        FOLLOW: 0,
        OTHER: 1,
        ADMIN: 2,
    },
    REGISTER_ERROR: {
        SUCCESS: 1,
        NEED_INFO: 2,
        CAPTCHA_INVALID: 1009,
        USER_EXISTED: 2011,
        USER_EMAIL_IS_USED: 2014
    },
    COMMON_HTML_TYPE: {
        CONFIRM_PASS: 1,
        RULE_EVENT: 2,
        RULE_EVENT_2021: 5,
        COMPANY_INFO: 3,
        SEARUN_EVENT: 4,
    },
    MAPBOX_TYPE: {
        LINE_STRING: "LineString",
        POINT: "Point",
        MULTI_LINE_STRING: 'MultiLineString'
    },
    EVENT_LOCK_STATUS: {
        NONE: 0,
        NORMAL: 1,
        BANNED: 2,
        MUTED: 3,
        DELETED_PENDING: 4,
        DELETED: 5
    },
    EVENT_STATUS_TYPE: {
        ALL: 0,
        YET: 1,
        READY: 2,
        DONE: 3,
        SUGGEST: 4,
    },
    EVENT_OFFICAL_TYPE: {
        ALL: 0,
        UNOFFICAL: 1,
        OFFICAL: 2
    },
    EVENT_TYPE: {
        ALL :0,
        SUPPER_CHALLENGE: 1,
        CHALLENGE : 2,
        VITURL_RACE: 3
    },
    EVENT_PUBlIC_TYPE: {
        PUBLIC: 1,
        PROTECTED: 2,
        PRIVATE: 3
    },
    SPORT_TYPE: {
        ALL: 0,
        COMPANY: 1,
        CLUB: 2,
        SCHOOL: 3
    },
    COMMON_ERROR: {
        SUCCESS: 0,
        OVER_QUOTA: 122,
        EVENT: {
            EVENT_READY: 115,
            EVENT_FINISHED: 116,
            EVENT_NOT_EXIST: 112,
            EVENT_NOT_READER: 113,
        },
        TEAM: {
            OWNER_CODE: 119,
            LEAVE_TEAM_EVENT_READY: 120,
            NOT_EXIST: 106,
        },
        LOGIN: {
            CAPTCHA_INVALID: 1009,
            USER_NOT_FOUND: 2000,
            USER_LOGIN_FAIL: 2017,
        },
        REPORT_ACTIVITY: {
            REVIEW_YOUR_ACT: 84,
            YOUR_REPORTED: 85,
            NOT_PERMISSION: 126,
        }
    }
};
