import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const NewsContent = inject('authStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {}, []);

        return (
            <article className="news-article">
                <div dangerouslySetInnerHTML={{ __html: data }}></div>
            </article>
        );
    })
);

export default NewsContent;
