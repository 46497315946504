import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from 'utils';
import { useTranslation } from 'react-i18next';
import { EVENT_MEMBER_SORT_TYPE } from 'helpers/ConstDefined';

const TabHeader = inject(
    'userStore',
    'userEventStore'
)(
    observer((props) => {
        const {tab} = props;

        const { t } = useTranslation();

        const EVENT_STATUS = [
            {
                id: 1,
                title: 'eu.event_joining'
            },
            {
                id: 2,
                title: 'eu.event_owner'
            },
            {
                id: 3,
                title: 'global.group_status.request'
            },
            {
                id: 4,
                title: 'eu.event_finished'
            }
        ];

        const onChangeTab = (tab) => {
            props.onChangeTab(tab);
        };

        function TabItem(props) {
            const { data } = props;
            const checked = data.id == tab ? true : false;
            console.log('check', checked)
            return (
                <div class="filter-group-item" onClick={() => onChangeTab(data.id)}>
                    <input
                        type="radio"
                        class="btn-check"
                        name="options-time"
                        id="r1"
                        autocomplete="off"
                        checked={checked}
                    />
                    <label class="btn btn-outline-secondary" for="r1">
                        {t(data.title)}
                    </label>
                </div>
            );
        }

        return (
            <div class="filter-group">
                {EVENT_STATUS.map((item) => {
                    return <TabItem data={item} key={item.id} />;
                })}
            </div>
        );
    })
);

export default TabHeader;
