import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FlipDown } from '../../assets/js/flipdown';
import { useTranslation } from 'react-i18next';
import { convertTimestamptoDate } from '../../utils';

let flipdown;
const CountDown = inject(
    'authStore',
    'eventStore'
)(
    observer((props) => {
        const { data } = props;
        const [startDate, setStartDate] = React.useState(null);
        const now = new Date().getTime() / 1000;
        const start = data.betm / 1000;
        const end = data.entm / 1000;

        const { t } = useTranslation();

        useEffect(() => {
            initFlipdown();
        }, []);

        function getDateEvent() {
            let format = 'DD.MM.YYYY';
            let start = convertTimestamptoDate(data.betm, format);
            let end = convertTimestamptoDate(data.entm, format);
            return start + ' - ' + end;
        }

        function initFlipdown() {
            if (end < now) return;

            let date = start <= now ? end : start;
            setStartDate(date);

            flipdown = new FlipDown();
            // Start the countdown
            flipdown.initData(date);
            flipdown.start();
            // Do something when the countdown ends
            flipdown.ifEnded(() => {
                console.log('The countdown has ended!');
            });
        }

        function EventFinish() {
            return (
                <div class="info--banner">
                    <div class="txt-uprace">
                        <img src="../../images/info/bn/uprace.png" alt="" />
                    </div>
                    <div class="date-final">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('global.event_finish_title')
                            }}></p>
                    </div>
                </div>
            );
        }

        function CountDownEvent() {
            return (
                <div class="info--banner">
                    <div id="flipdown" class="flipdown">
                        <div class="lb-block">
                            <div class="lb-item lb-date">{t('global.day')}</div>
                            <div class="lb-item lb-hour">{t('global.hour')}</div>
                            <div class="lb-item lb-minute">{t('global.minute')}</div>
                            <div class="lb-item lb-second">{t('global.second')}</div>
                        </div>
                    </div>
                    <p class="date">{getDateEvent()}</p>
                </div>
            );
        }

        if (end < now) return <EventFinish />;
        else
            return (
                <div class="info--banner">
                    <div id="flipdown" class="flipdown">
                        <div class="lb-block">
                            <div class="lb-item lb-date">{t('global.day')}</div>
                            <div class="lb-item lb-hour">{t('global.hour')}</div>
                            <div class="lb-item lb-minute">{t('global.minute')}</div>
                            <div class="lb-item lb-second">{t('global.second')}</div>
                        </div>
                    </div>
                    <p class="date">{getDateEvent()}</p>
                </div>
            );
    })
);

export default CountDown;
