import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../utils';

const MemberItem = inject(
    'authStore',
    'userStore',
    'userLeaderboardStore'
)(
    observer((props) => {
        const { data } = props;
        const { doRemoveMemberTemp, doRemoveMember } = props.userLeaderboardStore;
        const { userProfile } = props.userStore;

        useEffect(() => {}, []);

        function removeMember() {
            doRemoveMemberTemp(data);
            doRemoveMember(data);
        }

        const genderImg = data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';

        return (
            <tr>
                <td>
                    <div className="member">
                        <div
                            className="pull-left wrapper-avatar-member"
                            style={{ backgroundImage: `url('${getImageResource(data.ava, true, true)}')` }}>
                            <img src="../../../img/avatar.png" alt="image" />
                            <span className="gender">
                                <img className="gender" src={genderImg} alt="image" />
                            </span>
                        </div>

                        <div className="pull-left ">
                            <p className="wrapper-information-user">
                                <span className="member-name">{data.name}</span>
                            </p>
                            <p className="groups-user">{data.tmnm}</p>
                        </div>
                    </div>
                </td>
                <td>
                    {data.uid != userProfile.uid ? (
                        <button className="btn" onClick={() => removeMember()}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    ) : null}
                </td>
            </tr>
        );
    })
);

export default MemberItem;
