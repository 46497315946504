import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { MemberSearch, TeamSearch } from './components';
import { log, isJWTValid } from 'utils';

const MENU = [
    { id: 0, title: 'Đội' },
    { id: 1, title: 'Vận động viên' }
];

const Search = inject(
    'authStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const [tabSearch, setTabSearch] = useState(0);
        const { userProfile } = props.userStore;
        const { doSetTabMainTop, doSetIsTeamSearch } = props.globalStore;

        useEffect(() => {
            if(!isJWTValid()) props.history.push('/login');
            doSetTabMainTop(2);
            doSetIsTeamSearch(true);
        }, []);

        function MenuItem(props) {
            const { category } = props;
            const cssClass = category.id == tabSearch ? 'btn btn-primary active' : 'btn btn-primary inactive';

            return (
                <a className={cssClass} role="button" onClick={() => handleChangeTab(category.id)}>
                    {category.title}
                </a>
            );
        }

        const handleChangeTab = (tab) => {
            setTabSearch(tab);
        };

        const gotoTeamProfile = () => {
            props.history.push('/team-profile');
        };

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        if (userProfile)
            return (
                <div>
                    <section className="navigation-uprace navigation-uprace-search">
                        <div className="wrapper-btn">
                            {MENU.map((cat) => (
                                <MenuItem key={cat.id} category={cat} />
                            ))}
                        </div>
                    </section>
                    <section className="main-content  main-search">
                        <div className="container">
                            <div className="team-member-activate">
                                <div className="row">
                                    {tabSearch == 0 ? (
                                        <TeamSearch gotoTeamProfile={gotoTeamProfile} />
                                    ) : (
                                        <MemberSearch navigation={props.history} />
                                    )}
                                </div>
                            </div>
                            <div className="arrow-back-to-top" onClick={() => scrollTop()}><img src="../../../img/back-to-top.svg" alt="image"/></div>
                        </div>
                    </section>
                </div>
            );
        else return null;
    })
);

export default Search;
