import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import HeaderMain from './HeaderMain';
import GroupRankEmpty from './GroupRankEmpty';
import GroupRankList from './GroupRankList';
import GroupDetail from '../GroupDetail';
import CreateGroupDialog from '../CreateGroupDialog';
import { LEADERBOARD_TYPE } from '../../../../helpers/ConstDefined';

let nameSearch;

const ManageGroup = inject(
    'globalStore',
    'groupStore',
    'userStore',
    'eventStore',
    'teamManageStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { listGroupRank, doGetLeaderboardGroup } = props.teamManageStore;
        const { func, doGetGroupDetail } = props.groupStore;
        const { appVersion } = props.globalStore;

        const [openDialogCreateGroup, setOpenDialogCreateGroup] = React.useState(false);

        useEffect(() => {
            getLeaderboardGroup();
            nameSearch = null;
        }, []);

        const getLeaderboardGroup = (name, isLoadMore) => {
            const params = {
                size: appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.GROUP_TEAM,
                value: teamID,
                name
            };
            doGetLeaderboardGroup(params, isLoadMore);
        };

        const onLoadMoreGroup = () => {
            getLeaderboardGroup(nameSearch, true);
        };

        const onSearchGroup = (name) => {
            nameSearch = name;
            getLeaderboardGroup(name);
        };

        function RenderContent() {
            if (!listGroupRank || listGroupRank.length == 0)
                return (
                    <>
                        <HeaderMain
                            openDialogCreateGroup={() => setOpenDialogCreateGroup(true)}
                            onSearchGroup={onSearchGroup}
                            eventID={eventID}
                            teamID={teamID}
                        />
                        <GroupRankEmpty
                            eventID={eventID}
                            teamID={teamID}
                            openDialogCreateGroup={() => setOpenDialogCreateGroup(true)}
                        />
                    </>
                );
            else if (func == 0)
                return (
                    <>
                        <HeaderMain
                            openDialogCreateGroup={() => setOpenDialogCreateGroup(true)}
                            onSearchGroup={onSearchGroup}
                            eventID={eventID}
                            teamID={teamID}
                        />
                        <GroupRankList onLoadMoreGroup={onLoadMoreGroup} eventID={eventID} teamID={teamID} />
                    </>
                );
            else if (func == 1) return <GroupDetail eventID={eventID} teamID={teamID} />;
        }

        return (
            <div className="groups-block">
                <RenderContent />
                {openDialogCreateGroup == true ? (
                    <CreateGroupDialog
                        openDialogCreateGroup={openDialogCreateGroup}
                        eventID={eventID}
                        teamID={teamID}
                        onClose={() => setOpenDialogCreateGroup(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default ManageGroup;
