import { observable, action } from 'mobx';
import { requestAPIV2 } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, API_STATUS } from '../helpers/api/APIDefined';
import {
    EVENT_HOME_TYPE,
    EVENT_MEMBER_SORT_TYPE,
    EVENT_OFFICAL_TYPE,
    EVENT_STATUS_TYPE,
    EVENT_TYPE,
    SORT_VALUE_TYPE
} from '../helpers/ConstDefined';
import { log } from '../utils';

export default class UserEventStore {
    @observable listBanner = [];
    @observable listEventSuggest = [];
    @observable eventCreateType = null;
    @observable loading = false;

    @observable listEventFilter = [];
    @observable totalEventFilter = 0;
    @observable pageListEventFilter = 0;

    @observable totalSuggestEvent = 0;

    //observable current tab and content home
    @observable tabEventHome = 0; //0 : home event. 1: all event , 2 your event, 3 : search event
    @observable tabEventDetailHome = 0;

    @observable listMemberRq;
    @observable totalMemberRq = 0;
    @observable pageListMemberRq = 0;

    //filter
    @observable filterName = '';
    @observable filterEventStatus = EVENT_STATUS_TYPE.ALL;
    @observable filterOrder = SORT_VALUE_TYPE.DESC;
    @observable filterEventType = EVENT_TYPE.ALL;
    @observable filterEventOffical = EVENT_OFFICAL_TYPE.ALL;

    @action.bound
    doResetFilterEvent() {
        this.filterName = '';
        this.filterEventStatus = EVENT_STATUS_TYPE.ALL;
        this.filterEventType = EVENT_TYPE.ALL;
        this.filterOrder = SORT_VALUE_TYPE.DESC;
        this.filterEventOffical = EVENT_OFFICAL_TYPE.ALL;
    }

    @action.bound
    doSetEventOfficalFilter(val) {
        this.filterEventOffical = val;
    }

    @action.bound
    doSetEventTypeFilter(val) {
        this.filterEventType = val;
    }

    @action.bound
    doSetEventNameFilter(val) {
        this.filterName = val;
    }

    @action.bound
    doSetFilterOrderEvent(val) {
        this.filterOrder = val;
    }

    @action.bound
    doSetEventCreateType(val) {
        this.eventCreateType = val;
    }

    @action.bound
    doSetTabEventHome(val) {
        this.tabEventHome = val;
    }

    @action.bound
    doSetTabEventDetailHome(val) {
        this.tabEventDetailHome = val;
    }

    @action.bound
    doSetEventStatusFilter(val) {
        this.filterEventStatus = val;
    }

    //get list member of team
    @action.bound
    doGetListMemberRqEvent(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageListMemberRq += 1;
            params.from = params.size * this.pageListMemberRq;
        } else {
            this.pageListMemberRq = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    if (!isLoadMore) this.listMemberRq = [];
                    this.totalMemberRq = resp.data.total;
                    if (isLoadMore) this.listMemberRq = this.listMemberRq.concat(resp.data.list);
                    else this.listMemberRq = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list banner event
    @action.bound
    doGetListBanner(params) {
        this.loading = true; //show progress

        requestAPIV2(API.EVENT.LIST_BANNER, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    let result = [];
                    _.each(resp.data.bnls, (val, key) => {
                        result.push(val);
                    });

                    this.listBanner = result;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list banner event
    @action.bound
    doGetLisSuggest(params) {
        this.loading = true; //show progress

        requestAPIV2(API.EVENT.SUGGEST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    this.totalSuggestEvent = resp.data.total;
                    this.listEventSuggest = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list event filter
    @action.bound
    doGetListEventFilter(params, isLoadMore) {
        this.loading = true; //show progress
        const api = this.filterEventStatus == EVENT_STATUS_TYPE.SUGGEST ? API.EVENT.SUGGEST : API.EVENT.GET_LIST;

        if (isLoadMore) {
            this.pageListEventFilter += 1;
            params.from = params.size * this.pageListEventFilter;
        } else {
            this.pageListEventFilter = 0;
            params.from = 0;
        }

        requestAPIV2(api, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    this.totalEventFilter = resp.data.total;
                    if (isLoadMore) this.listEventFilter = this.listEventFilter.concat(resp.data.list);
                    else this.listEventFilter = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //dismiss event
    @action.bound
    doDismissEvent(params) {
        this.loading = true; //show progress

        requestAPIV2(API.EVENT.DISMISS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    _.remove(this.listEventSuggest, (item) => {
                        return item.evid == params.evid;
                    });

                    _.remove(this.listEventFilter, (item) => {
                        return item.evid == params.evid;
                    });

                    this.totalSuggestEvent -= 1;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //create event
    @action.bound
    async doCreateEvent(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp;
                } else {
                    this.loading = false;
                    result = resp; //create event success
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //update event
    @action.bound
    async doUpdateEvent(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp;
                } else {
                    this.loading = false;
                    result = resp; //update event success
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //delete event
    @action.bound
    async doDeleteEvent(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.DELETE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    result = true; //create event success
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //action event
    @action.bound
    async doActionEvent(params) {
        let result = null;
        this.loading = true; //show progress

        await requestAPIV2(API.EVENT.REQUEST_JOIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    result = resp.data;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //approve all member
    @action.bound
    async doApproveMemberAll(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.APPROVE_ALL, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    //update local
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    doApproveMember(uid) {
        const obj = _.find(this.listMemberRq, (item) => {
            return item.uid == uid;
        });
        if (obj) {
            _.remove(this.listMemberRq, (item) => {
                return item.uid == uid;
            });
            this.totalMemberRq -= 1;
        }
    }

    @action.bound
    doRejectMember(uid) {
        const obj = _.find(this.listMemberRq, (item) => {
            return item.uid == uid;
        });
        if (obj) {
            _.remove(this.listMemberRq, (item) => {
                return item.uid == uid;
            });
            this.totalMemberRq -= 1;
        }
    }
}
