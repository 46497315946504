import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, getImageResource } from '../../../../utils';

const GroupJoinItem = inject('authStore')(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);

        return (
            <li>
                <div className="item">
                    <button className="delete-btn" onClick={() => props.onDeleteGroup(data)}></button>
                    <div className="user">
                        <p className="avatar">
                            <img src={getImageResource(data.ava,true)} alt="" />
                        </p>
                        <div className="details">
                            <p className="name">{data.name}</p>
                            <p className="owner-name">{data.ownm}</p>
                            <p className="member">
                                <span className="num">{formatNumber(data.mem)}</span> vđv
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        );
    })
);

export default GroupJoinItem;
