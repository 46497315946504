import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MemberItem from './MemberItem';
import LockUserDialog from './LockUserDialog';
import RegainAdminDialog from './RegainAdminDialog';
import { formatNumber, stripedHtml } from '../../../../utils';
import _ from 'lodash';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

let userLock,
    userRegainAdmin = null;

const MemberJoinList = inject(
    'userStore',
    'teamManageStore',
    'eventStore'
)(
    observer((props) => {
        useEffect(() => {
            onLoadData();
        }, []);

        const { t } = useTranslation();

        const { eventData } = props;
        const { userProfile } = props.userStore;
        let { loading, listAdmin, listMember, totalMember } = props.teamManageStore;
        const { doGetListMemberTeam, doGetListAdminTeam, doAssignAdmin, doRegainAdmin } = props.teamManageStore;

        const isShowLoadMore = listMember == null ? false : listMember.length < totalMember;

        const [openDialogLockUser, setOpenDialogLockUser] = React.useState(false);
        const [openDialogRegainAdmin, setOpenDialogRegainAdmin] = React.useState(false);
        const [search, setSearch] = React.useState('');

        const showConfirmLock = (user) => {
            userLock = user;
            setOpenDialogLockUser(true);
        };

        const showConfirmRegainAdmin = (user) => {
            userRegainAdmin = user;
            setOpenDialogRegainAdmin(true);
        };

        const getListMember = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile.uid,
                evid: eventData.evid,
                name: search,
                evad: -eventData.evid
            };
            doGetListMemberTeam(params, isLoadMore);
        };

        const assignAdminSucc = (user) => {
            doAssignAdmin(user);
        };

        const regainAdminSucc = (user) => {
            doRegainAdmin(user);
        };

        const onLoadMoreMember = () => {
            getListMember(true);
        };

        const getListAdmin = () => {
            const params = {
                uid: userProfile.uid,
                evid: eventData.evid,
                evad: eventData.evid,
                name: search
            };
            doGetListAdminTeam(params);
        };

        const onLoadData = () => {
            getListAdmin();
            getListMember();
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                onLoadData();
            }
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => onLoadMoreMember()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                <div class="manage-box__body">
                    <div class="content">
                        <div class="frm-row">
                            <div class="frm-row__item search">
                                <input
                                    type="text"
                                    placeholder={t('global.search')}
                                    value={search}
                                    onKeyDown={handleKeyDown}
                                    onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                />
                            </div>
                            <button class="btn btn-40 btn-blue" onClick={() => onLoadData()}>
                                {t('global.search')}
                            </button>
                        </div>

                        <div class="manage-box__sub">
                            <h5 class="title">{t('global.role_user.owner')}</h5>
                            {listAdmin &&
                                listAdmin.map((item) => {
                                    if (item.uid == eventData.owner)
                                        return (
                                            <MemberItem
                                                data={item}
                                                key={item.uid}
                                                isOwner={true}
                                                eventData={eventData}
                                            />
                                        );
                                    else return null;
                                })}
                        </div>

                        <div class="manage-box__sub">
                            <h5 class="title">{t('global.role_user.admin')}</h5>
                            <div class="list-group">
                                {listAdmin &&
                                    listAdmin.map((item) => {
                                        if (item.uid != eventData.owner)
                                            return (
                                                <div class="list-group-item">
                                                    <MemberItem
                                                        data={item}
                                                        key={item.uid}
                                                        isAdmin={true}
                                                        showConfirmLock={showConfirmLock}
                                                        showConfirmRegainAdmin={showConfirmRegainAdmin}
                                                        assignAdminSucc={assignAdminSucc}
                                                        regainAdminSucc={regainAdminSucc}
                                                        eventData={eventData}
                                                    />
                                                </div>
                                            );
                                        else return null;
                                    })}
                            </div>
                        </div>

                        <div class="manage-box__sub">
                            <h5 class="title">
                                {t('global.member1')} ({formatNumber(totalMember)})
                            </h5>
                            <div class="list-group">
                                {listMember &&
                                    listMember.map((item) => {
                                        return (
                                            <div class="list-group-item">
                                                <MemberItem
                                                    data={item}
                                                    key={item.uid}
                                                    eventData={eventData}
                                                    showConfirmLock={showConfirmLock}
                                                    showConfirmRegainAdmin={showConfirmRegainAdmin}
                                                    assignAdminSucc={assignAdminSucc}
                                                    regainAdminSucc={regainAdminSucc}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                            <LoadMore />
                        </div>
                    </div>

                    {openDialogLockUser && userLock ? (
                        <LockUserDialog
                            openDialogLockUser={openDialogLockUser}
                            onClose={() => setOpenDialogLockUser(false)}
                            eventID={eventData.evid}
                            userLock={userLock}
                        />
                    ) : null}

                    {openDialogRegainAdmin && userRegainAdmin ? (
                        <RegainAdminDialog
                            openDialogRegainAdmin={openDialogRegainAdmin}
                            onClose={() => setOpenDialogRegainAdmin(false)}
                            eventData={eventData}
                            regainAdminSucc={regainAdminSucc}
                            userRegainAdmin={userRegainAdmin}
                        />
                    ) : null}
                </div>
            </>
        );
    })
);

export default MemberJoinList;
