import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, getImageResource } from '../../../../../utils';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const TeamRankItem = inject(
    'eventStore',
    'teamManageStore'
)(
    observer((props) => {
        const { data, eventID } = props;
        const { doSetFunc, doSetMainTab } = props.teamManageStore;

        useEffect(() => {}, []);

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';

        const resetTabTeamDetail = () => {
            doSetFunc(0);
            doSetMainTab(0);
        }

        return (
            <li onClick={() => resetTabTeamDetail()}>
                <a href='#'>
                    <div class="level">{data.rank}</div>
                    <div class="logo">
                        <img src={getImageResource(data.ava, true)} alt="" />
                    </div>
                    <div class="name-box">
                        <p class="name">{data.name}</p>
                        {data.diff != 0 ? <p className={cssRankArrow}>{Math.abs(data.diff)}</p> : null}
                    </div>
                    <div class="date">{formatDistanceKM(data.ddis)}</div>
                    <div class="sum">{formatDistanceKM(data.dis)}</div>
                </a>
            </li>
        );
    })
);

export default TeamRankItem;
