import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import MemberItem from './MemberItem';
import { formatNumber } from '../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { stripedHtml, getTimestamp } from '../../../../utils';

const MENU = [{ id: 0, title: 'Tất cả' }];

const MemberSearch = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const {
            loadingSearch,
            listUserSearch,
            totalUser,
            doSearchUser,
            pageSearchUser,
            doSetPageIndexSearchUser,
            doIncreasePageSearchUser
        } = props.userStore;

        useEffect(() => {
            getListUser();
        }, []);

        const [tabSearch, setTabSearch] = useState(0);
        const [name, setName] = React.useState('');
        const isShowLoadMore = listUserSearch == null ? false : listUserSearch.length < totalUser;
        const showClearName = name && name.length > 0;

        const handleChangeName = (val) => {
            setName(val);
        };

        function getListUser() {
            let params = {
                uid: userProfile.uid,
                name
            };
            doSearchUser(params);
        }

        function handleResetSearch() {
            setName('');
            let params = {
                uid: userProfile.uid
            };
            doSearchUser(params);
        }

        function searchMemberMore() {
            doIncreasePageSearchUser();
            let params = {
                from: pageSearchUser * appVersion.pgsz,
                size: appVersion.pgsz,
                uid: userProfile.uid,
                name
            };

            doSearchUser(params, true);
        }

        function searchMember() {
            doSetPageIndexSearchUser(1); //reset page index when search
            const params = {
                uid: userProfile.uid,
                name
            };
            doSearchUser(params);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchMember();
            }
        };

        const scrollTop = () => {
            window.scrollTo(0, 0);
        };

        function MenuItem(props) {
            const { category } = props;
            const cssclassName = category.id == tabSearch ? 'nav-link active' : 'nav-link';

            return (
                <div className="col-md-4" onClick={() => setTabSearch(category.id)}>
                    <div className="nav-item  ">
                        <a
                            className={cssclassName}
                            id="all-tab"
                            data-toggle="tab"
                            href="#all"
                            role="tab"
                            aria-controls="all"
                            aria-selected="true">
                            {category.title}
                        </a>
                    </div>
                </div>
            );
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingSearch)
                    return (
                        <div className="load-more">
                            <CircularProgress disableShrink />
                        </div>
                    );
                else
                    return (
                        <>
                            <div className="load-more">
                                <button className="btn btn-primary" onClick={() => searchMemberMore()}>
                                    Xem thêm
                                </button>
                            </div>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                <div className="col-lg-8">
                    <div className="clearfix">
                        <div className="pull-left">
                            {' '}
                            <h3 className="title-rank">Danh sách vận động viên</h3>
                        </div>
                    </div>
                    <section className="nav-rank">
                        <div className="wrapper-table">
                            <div className="row nav-tab" role="tablist">
                                {MENU.map((cat) => (
                                    <MenuItem key={cat.id} category={cat} />
                                ))}
                            </div>

                            <div className="tab-content nav-content">
                                <div
                                    className="tab-pane wrapper-list-member fade show active"
                                    id="all"
                                    role="tabpanel"
                                    aria-labelledby="all-tab">
                                    <div className="wrapper-search">
                                        <div className="form-search-custom" id="search-form">
                                            <div className="input-group input-group-custom">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <img src="../../img/icon-search.png" alt="image" />
                                                    </span>
                                                </div>
                                                <input
                                                    id="search"
                                                    type="text"
                                                    className="form-control form-search"
                                                    value={name}
                                                    onKeyDown={handleKeyDown}
                                                    onChange={(text) =>
                                                        handleChangeName(stripedHtml(text.target.value))
                                                    }
                                                    placeholder="Tìm tên Vận động viên"
                                                />

                                                {showClearName ? (
                                                    <img
                                                        src="../../../img/register/close.svg"
                                                        className="close-text"
                                                        onClick={() => handleResetSearch()}
                                                    />
                                                ) : null}
                                            </div>
                                            <button
                                                className="btn btn-primary btn-search btn-search-custom"
                                                onClick={() => searchMember()}>
                                                Tìm kiếm
                                            </button>
                                        </div>
                                    </div>

                                    <h4 className="title">{formatNumber(totalUser)} Vận động viên</h4>
                                    <div className="list-user-group">
                                        {listUserSearch != null &&
                                            listUserSearch.map((item) => (
                                                <MemberItem
                                                    key={item.uid}
                                                    data={toJS(item)}
                                                    navigation={props.navigation}></MemberItem>
                                            ))}
                                    </div>
                                    {listUserSearch && listUserSearch.length == 0 ? (
                                        <p style={{ textAlign: 'center' }}>Không tìm thấy VĐV theo yêu cầu</p>
                                    ) : null}
                                    <LoadMore />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <h3 className="title">Sự kiện đang tham gia</h3>
                    <div
                        className="background-user-main"
                        style={{ backgroundImage: `url("../../img/upgrade-2020.png")` }}>
                        <img className="opacity-hidden" src="../../img/upgrade-2020.png" alt="image" />
                    </div>
                </div>
            </>
        );
    })
);

export default MemberSearch;
