import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';
import { STATS_TYPE, EVENT_LOCK_STATUS } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const YourActivity = inject(
    'authStore',
    'eventStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID, userID } = props;
        const { eventUserStats, doGetEventStats } = props.eventStore;
        const { userProfile, userProfileOther } = props.userStore;
        const { userTeam } = props.teamStore;

        const { t } = useTranslation();
        
        useEffect(() => {
            getUserStats();
        }, [userProfile]);

        function getUserStats() {
            let data = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: STATS_TYPE.USER,
                value: userID
            };
            doGetEventStats(data, STATS_TYPE.USER);
        }

        if (eventUserStats != null && userProfileOther)
            return (
                <div class="yours-v2-info">
                    <div class="left">
                        <div class="avatar">
                            <img src={getImageResource(userProfileOther.ava, true, true)} class="avatar-item" alt="" />
                        </div>
                        <div class="info">
                            <div class="info__name">{userProfileOther.name}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="box-group">
                            <div class="col">
                                <div class="box box-km">
                                    <div class="info">
                                        <div class="num">{formatDistanceKM(eventUserStats.dis)}</div>
                                        <div class="unit">km</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="h-50 pb-3">
                                    <div class="box box-activity">
                                        <div class="info">
                                            <div class="num">{formatNumber(eventUserStats.days)}</div>
                                            <div class="unit">{t('global.day_act')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="h-50">
                                    <div class="box box-rank">
                                        <div class="info">
                                            <div class="num">{formatNumber(eventUserStats.rank)}</div>
                                            <div class="unit">{t('global.rank_of_team')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return null;
    })
);

export default YourActivity;
