import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml } from 'utils';
import { Redirect } from 'react-router-dom';
import { YourActivity, AchievementPersonal } from './components';
import ListActivity from 'views/YourInfo/components/DialogUserEvent/ListActivity';
import { ListActivityInvalid } from './components';
import NotActivity from './NotActivity';
import { DeleteAccount } from 'views/UserProfile/components';
import { isAccountDeleted } from 'components/MemberName';

const queryString = require('query-string');

const UserEventDetail = inject(
    'eventStore',
    'userStore',
    'userProfileStore'
)(
    observer((props) => {
        const { currentEvent, doGetEventData } = props.eventStore;
        const { loadingProfileOther, userProfile, userProfileOther, doGetProfileDetailOther } = props.userStore;
        const { totalFeedUser } = props.userProfileStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.uid || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let userID = stripedHtml(paramsQuery.uid);
        let eventID = stripedHtml(paramsQuery.evid);

        const [tab, setTab] = useState(0);

        const onBack = () => {
            if (tab == 1 || tab == 2) setTab(0);
            else props.history.goBack();
        };

        useEffect(() => {
            window.scrollTo(0, 0);
            getEventDetail();
            getProfileUserOther();
        }, [userProfile]);
        const { t } = useTranslation();

        const getEventDetail = async () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID
            };
            let result = await doGetEventData(params);
            if (result == null) props.history.push('/');
        };

        async function getProfileUserOther() {
            const params = {
                uid: userProfile && userProfile.uid,
                pfid: userID
            };
            doGetProfileDetailOther(params);
        }

        if (!loadingProfileOther && userProfileOther == null) return <DeleteAccount />;
        if (currentEvent && userProfileOther)
            if (isAccountDeleted(userProfileOther)) return <DeleteAccount />;
            else
                return (
                    <section class="yours-v2-page">
                        <div class="yours-v2-wrap">
                            <a href="javascript:void(0);" class="back-btn" onClick={() => onBack()}>
                                {t('global.back')}
                            </a>

                            {tab == 0 && (
                                <>
                                    <YourActivity eventID={eventID} userID={userID} />

                                    <div class="yours-v2-activities">
                                        <ul class="list-group">
                                            <li class="list-group-item" onClick={() => setTab(1)}>
                                                <a href="javascript:void(0);" class="desc">
                                                    <span class="desc__title">{t('eu.view_act_valid')}</span>
                                                    <span class="desc__see-now">{t('eu.view_now')}</span>
                                                </a>
                                            </li>
                                            {userProfile &&
                                            userProfileOther &&
                                            userProfile.uid == userProfileOther.uid ? (
                                                <li class="list-group-item" onClick={() => setTab(2)}>
                                                    <a href="javascript:void(0);" class="desc">
                                                        <span class="desc__title">{t('eu.view_act_invalid')}</span>
                                                        <span class="desc__see-now">{t('eu.view_now')}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                        </ul>
                                        <AchievementPersonal eventID={eventID} userID={userID} />
                                    </div>
                                </>
                            )}
                            {tab == 1 && <ListActivity eventID={eventID} profileData={userProfileOther} />}
                            {tab == 1 && totalFeedUser == 0 ? <NotActivity /> : null}
                            {tab == 2 && <ListActivityInvalid eventID={eventID} />}
                        </div>
                    </section>
                );
        else return null;
    })
);

export default UserEventDetail;
