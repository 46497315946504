import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const DeleteEventDialog = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        let { data, navigation } = props;
        const { openDialogDeleteEvent } = props;
        const { userProfile } = props.userStore;
        const { doDeleteCurrentEvent } = props.eventStore;
        const { doDeleteEvent } = props.userEventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function deleteEvent() {
            const params = {
                uid: userProfile.uid,
                evid: data.evid
            };
            let result = await doDeleteEvent(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doDeleteCurrentEvent();
                props.onClose();
                navigation.push('/event');
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogDeleteEvent}>
                <div
                    class="uprace-popup uprace-popup--v2"
                    id="remove-event"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content rounded">
                            <div class="popup-body">
                                <div class="icon-warning"></div>
                                <div>
                                    <h5 class="popup-title">{t('eu.delete_event')}</h5>
                                    <div class="popup-text">{t('eu.delete_event_confirm')}</div>
                                    <div class="action">
                                        <button
                                            type="button"
                                            class="btn btn-outline-blue"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => deleteEvent()}>
                                            {t('eu.delete_event')}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-blue"
                                            data-dismiss="modal"
                                            onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DeleteEventDialog;
