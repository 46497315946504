import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { stripedHtml } from 'utils';
import {
    EventHeader,
    TabHeader,
    EventInfo,
    EventInfoTab,
    ManageMember,
    ManagerGroup,
    ManagerComplain,
    LeaderboardGroup,
    LeaderboardUser
} from './components';
import { InviteFriendDialog } from 'views/TeamDetails/components';
import { EVENT_DETAIL_TAB_TYPE } from '../../helpers/ConstDefined';

const queryString = require('query-string');

const EventDetail = inject(
    'eventStore',
    'userStore',
    'userEventStore',
    'groupStore'
)(
    observer((props) => {
        const { doGetGroupDetail } = props.groupStore;
        const { currentEvent, doGetEventData, doDeleteCurrentEvent } = props.eventStore;
        const { tabEventDetailHome, doSetTabEventDetailHome } = props.userEventStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            doDeleteCurrentEvent();
            getEventDetail();
        }, [userProfile]);

        useEffect(() => {
            doSetTabEventDetailHome(0);
            return () => {
                //clear up event detail current for reload
                doDeleteCurrentEvent();
            };
        }, []);

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid) return <Redirect to="/event"></Redirect>;

        const getEventDetail = async () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: stripedHtml(paramsQuery.evid)
            };
            let result = await doGetEventData(params);
            if (result == null) props.history.push('/event');
            else {
                if (result.rgrid > 0 || result.grid > 0) getGroupDetail(result);
            }
        };

        async function getGroupDetail(event) {
            const params = {
                uid: userProfile && userProfile.uid,
                grid: event.rgrid || event.grid
            };
            let result = await doGetGroupDetail(params);
        }

        if (currentEvent)
            return (
                <section class="event-detail-page">
                    <div class="event-detail-wrap">
                        <EventHeader eventData={currentEvent} />
                        <div class="event-detail-tabs">
                            <TabHeader eventData={currentEvent} navigation={props.history} />
                            <div class="tabs--content">
                                <ul>
                                    <li class="active">
                                        {tabEventDetailHome != EVENT_DETAIL_TAB_TYPE.LEADERBOARD_GROUP && tabEventDetailHome != EVENT_DETAIL_TAB_TYPE.LEADERBOARD_USER ? (
                                            <EventInfo eventData={currentEvent} navigation={props.history} />
                                        ) : null}

                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.INFO_TAB && (
                                            <EventInfoTab eventData={currentEvent} navigation={props.history} />
                                        )}
                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.MANAGER_MEMBER && (
                                            <ManageMember eventData={currentEvent} />
                                        )}
                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.MANAGER_GROUP && (
                                            <ManagerGroup eventData={currentEvent} />
                                        )}
                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.MANAGER_COMPLAIN && (
                                            <ManagerComplain eventData={currentEvent} />
                                        )}
                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.LEADERBOARD_GROUP && (
                                            <LeaderboardGroup eventData={currentEvent} navigation={props.history} />
                                        )}
                                        {tabEventDetailHome == EVENT_DETAIL_TAB_TYPE.LEADERBOARD_USER && (
                                            <LeaderboardUser eventData={currentEvent} navigation={props.history} />
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {userProfile ? <InviteFriendDialog eventID={currentEvent.evid} /> : null}
                </section>
            );
        else return null;
    })
);

export default EventDetail;
