'use strict';
import md5 from 'blueimp-md5';
import { v4 as uuidv4 } from 'uuid';
var moment = require('moment');
var duration = require('moment-duration-format');
var numeral = require('numeral');
var jwtDecode = require('jwt-decode');
const base64 = require('base64-js');
const sha256 = require('sha256');
const _ = require('lodash');

import { KEY, PRIVATE_KEY } from '../helpers/api/APIDefined';
import { SPORT_TYPE_ACTIVITY, LOGIN_TYPE } from '../helpers/ConstDefined';

export function checkHttpStatus(response) {
    console.log('x1', response.text());
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        throwAPIError(response.status);
    }
}

export function replaceURLs(message) {
    if(!message) return;
   
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

export function convertShortDurationText(totalSeconds, isFeed) {
    try {
        if (totalSeconds == 0) return 0;
        //return moment.duration(totalSeconds, 's').format('HH:mm:ss');
        if (isFeed) return moment.utc(moment.duration(totalSeconds, 's').asMilliseconds()).format('HH:mm:ss');
        else {
            var dur = moment.duration(totalSeconds, 'seconds');
            return dur.format('HH:mm:ss');
        }
    } catch (error) {
        return null;
    }
}

export function avgPace(type, dur, dis) {
    if (type == SPORT_TYPE_ACTIVITY.WALKING) return dur > 0 && dis > 0 ? dur / (dis / 1000) : null;
    else if (type == SPORT_TYPE_ACTIVITY.SWIMMING) return dur > 0 && dis > 0 ? dur / (dis / 100) : null;
    else return dur > 0 && dis > 0 ? dis / 1000.0 / (dur / 3600.0) : null;
}

export function convertShortDuration(totalSeconds, isZero) {
    try {
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.round(totalSeconds % 60);
        if (seconds == 60) {
            minutes += 1;
            seconds = 0;
        }
        if (isZero) return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        else return minutes.toString() + ':' + seconds.toString().padStart(2, '0');
    } catch (error) {
        return null;
    }
}

export function convertLongDuration(totalSeconds) {
    try {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.round(totalSeconds % 60);
        return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    } catch (error) {
        return null;
    }
}

export function convertLongToPage(totalSeconds) {
    try {
        const hour = Math.floor(totalSeconds / 3600);
        const min = Math.round(totalSeconds % 60);
        return hour.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');
    } catch (error) {
        return null;
    }
}

export function getUUID() {
    return uuidv4();
}

export function convertTimestamptoDate(timestamp, format) {
    //return formatDate('DD-MM-YYYY HH:mm:ss', date);
    var date = new Date(timestamp);
    return formatDate(format, date);
}

export function convertDatetoTimestamp(date, time) {
    const dateStr = moment(date).format('MM/DD/YYYY');
    const timeStr = moment(time).format('HH:mm');
    const fullDate = dateStr + ' ' + timeStr;
    console.log('dmmmmmm', fullDate);
    const dateVal = new Date(fullDate);
    return moment(dateVal).format('x');
}

export function convertTimeToLong(time)
{
    if(!time || time.length == 0) return null;
    const listVal = time.split(":");
    if(!listVal || listVal.length < 2) return null;
    const min = parseInt(listVal[0]);
    const second = parseInt(listVal[1]);
    return min * 60 + second;
}

export function convertTimeFromSecond(sec_num) {
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours == 0) hours = '';
    else if (hours < 10) {
        hours = '0' + hours + 'h ';
    } else hours = hours + 'h ';

    if (minutes == 0) minutes = '00m';
    else if (minutes < 10) {
        minutes = '0' + minutes + 'm ';
    } else minutes = minutes + 'm ';

    if (seconds == 0) seconds = '00s';
    else if (seconds < 10) {
        seconds = '0' + seconds + 's ';
    } else seconds = seconds + 's ';

    if (hours.length > 0) return hours + minutes;
    else return minutes + seconds;
}

export function stripedHtml(val) {
    if (!val) return null;
    val = val.replace(/<[^>]+>/g, '');
    //val = val.replace(/[^a-zA-Z0-9,/ ]/g, '');
    return val;
}

export function validEmail(val) {
    //return val.replace(/^[a-z][a-z0-9_\.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/gm);
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
}

export function stripedHtmlNumber(val) {
    val = val.replace(/<[^>]+>/g, '');
    val = val.replace(/[^0-9]/g, '');
    return val;
}

export function binaryToBase64(data) {
    if (data instanceof ArrayBuffer) {
        data = new Uint8Array(data);
    }
    if (data instanceof Uint8Array) {
        return base64.fromByteArray(data);
    }
    if (!ArrayBuffer.isView(data)) {
        throw new Error('data must be ArrayBuffer or typed array');
    }
    const { buffer, byteOffset, byteLength } = data;
    return base64.fromByteArray(new Uint8Array(buffer, byteOffset, byteLength));
}

export function log(mess, other = null) {
    return console.log(mess, other);
}

export function getTypeName(arr, id) {
    var obj = _.find(arr, (item) => {
        return item.id == id;
    });
    if (obj) return obj.title;
    else return null;
}

export function parseJSON(response) {
    return response.json();
}

export function formatDate(format, date) {
    const result = moment(new Date()).format(format);
    try {
        if (date == undefined || date == null || date == '') return result;
        if (!moment(date).isValid()) return result;
        return moment(new Date(date)).format(format);
    } catch (err) {
        return result;
    }
}

export function parseDate(dateStr) {
    return moment(dateStr).toDate();
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl[0].split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function formatNumber(value) {
    if (value == undefined || value == null || value == '') return 0;
    return numeral(value).format('0,0');
}

export function formatDistanceKM(mValue, round) {
    const km = Math.round((mValue / 1000) * 100) / 100;
    if (round) return numeral(km).format('0,0');
    else return numeral(km).format('0,0.[00]');
}

export function formatDistanceKMFloor(mValue, round) {
    const km = Math.floor(mValue / 1000);
    if (round) return numeral(km).format('0,0');
    else return numeral(km).format('0,0.[00]');
}

export function getTimestamp() {
    return Math.floor(Date.now() / 1000);
}

export function getChecksum(input, ts, key) {
    if (input == undefined || input == null || input == '') return md5(md5(ts) + key);
    else return md5(md5(input + '|' + ts) + key);
}

export function isJWTValid() {
    let user = localStorage.getItem(KEY.CURRENT_USER);
    if (user != 'undefined' && user != null) {
        try {
            const token = JSON.parse(user).accesstoken;
            var decoded = jwtDecode(token);
            var currentTime = new Date().getTime() / 1000;
            if (currentTime > decoded.exp) {
                return false; //expired
            } else return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    } else return false;
}

export function getImageResource(img, avatar, member, post) {
    if (post) {
        return img
            .replace('.png', '@2x.png')
            .replace('.jpeg', '@2x.jpeg')
            .replace('.gif', '@2x.gif');
    } else if (avatar) {
        if (!img || img.length == 0) {
            if (member) return '../img/ic_avatar.png';
            else return '../img/logo/ava_team_default.jpg';
        }
        return img
            .replace('.png', '@2x.png')
            .replace('.jpeg', '@2x.jpeg')
            .replace('.gif', '@2x.gif');
    } else {
        if (!img || img.length == 0) return '../img/banner-profile-team.png';
        return img
            .replace('.png', '@3x.png')
            .replace('.jpeg', '@3x.jpeg')
            .replace('.gif', '@3x.gif');
    }
}

export function getLoginTypeResource(type) {
    if (type == LOGIN_TYPE.FACEBOOK) return '../img/ic_facebook.png';
    else if (type == LOGIN_TYPE.GOOGLE) return '../img/ic_google.png';
    else if (type == LOGIN_TYPE.APPLE) return '../img/ic_apple.png';
    else return '../img/ic_email.png';
}

export function formatCurrency(currency, format) {
    if (currency == undefined || currency == null || currency == '') return 0;
    let result = numeral(currency).format(format);
    return result.toString().replace(/,/g, '.');
}

export function getRequestBody(param) {
    var formBody = [];
    for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
}
