import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml, stripedHtmlNumber, validEmail, formatDate } from '../../utils/index';
import { API } from '../../helpers/api/APIDefined';

let cssTab1, cssTab2, cssTab3;

const JoinNow = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const { doUserFeedback } = props.globalStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t, i18n } = useTranslation();
        const [tab, setTab] = useState(1);

        const [name, setName] = useState('');
        const [phone, setPhone] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');

        //valid error
        const [errEmail, setErrEmail] = useState(false);
        const [errName, setErrName] = useState(false);
        const [errPhone, setErrPhone] = useState(false);
        const [errMess, setErrMess] = useState(false);

        function validInput() {
            let hasError = false;
            if (!validEmail(email)) {
                setErrEmail(true);
                hasError = true;
            } else setErrEmail(false);

            if (!name || name.length < 1) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (!phone || phone.length < 10) {
                setErrPhone(true);
                hasError = true;
            } else setErrPhone(false);

            if (!message || message.length < 1) {
                setErrMess(true);
                hasError = true;
            } else setErrMess(false);

            return hasError;
        }

        function clearInput() {
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');
        }

        async function onSendInfo() {
            if (!validInput()) {
                const params = {
                    name,
                    email,
                    phone,
                    content: message
                };
                const result = await doUserFeedback(params);
                if (result) {
                    setMessageSnackbar(t('landing_page.join.feedback_succ'));
                    setSnackbarType('success');
                    clearInput()
                } else {
                    setMessageSnackbar(t('landing_page.join.feedback_fail'));
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        const handleChangeName = (val) => {
            if (val && val.length > 50) return;
            setName(val);
        };
        const handleChangeEmail = (val) => {
            if (val && val.length > 50) return;
            setEmail(val);
        };
        const handleChangePhone = (val) => {
            if (val && val.length > 20) return;
            setPhone(val);
        };
        const handleChangeMessage = (val) => {
            if (val && val.length > 250) return;
            setMessage(val);
        };

        const onChangeTab = (tab) => {
            setTab(tab.id);
        };

        if (tab == 1) {
            cssTab1 = 'tab-content tab-content--1 active';
            cssTab2 = 'tab-content tab-content--2';
            cssTab3 = 'tab-content tab-content--3';
        } else if (tab == 2) {
            cssTab1 = 'tab-content tab-content--1';
            cssTab2 = 'tab-content tab-content--2 active';
            cssTab3 = 'tab-content tab-content--3';
        } else if (tab == 3) {
            cssTab1 = 'tab-content tab-content--1';
            cssTab2 = 'tab-content tab-content--2';
            cssTab3 = 'tab-content tab-content--3 active';
        }

        const TAB = [
            {
                id: '1',
                title: t('landing_page.join.tab1_header'),
                img: 'images/icons/runner.png',
                img_act: 'images/icons/runner-active.png'
            },
            {
                id: '2',
                title: t('landing_page.join.tab2_header'),
                img: 'images/icons/sponsor.png',
                img_act: 'images/icons/sponsor-active.png'
            },
            {
                id: '3',
                title: t('landing_page.join.tab3_header'),
                img: 'images/icons/donate.png',
                img_act: 'images/icons/donate-active.png'
            }
        ];

        function TabHeader(props) {
            const { data } = props;
            const css =
                data.id == tab
                    ? 'btn-choose-tab flex column align-item-center justify-content-center active'
                    : 'btn-choose-tab flex column align-item-center justify-content-center';

            return (
                <a className={css} style={{ cursor: 'pointer' }} onClick={() => onChangeTab(data)}>
                    <img className="tab-icon--runner lazyload" data-src={data.img} />
                    <img className="tab-icon--runner-active lazyload" data-src={data.img_act} />
                    <span>{data.title}</span>
                </a>
            );
        }

        let linkDown = t('landing_page.join.tab2_content.l4');
        linkDown = linkDown.replace(
            '[link]',
            API.GLOBAL.SITE_DOMAIN + `data/downloads/proposal_uprace_2021_${i18n.language}.pdf`
        );

        return (
            <section className="section section--wrapper ">
                <div className="container">
                    <h1
                        className="text-center space-20"
                        dangerouslySetInnerHTML={{
                            __html: t('landing_page.join.title')
                        }}></h1>
                    <p
                        className="text-center space-80"
                        dangerouslySetInnerHTML={{
                            __html: t('landing_page.join.desc')
                        }}></p>
                    <div className="list-tab flex ">
                        {TAB.map((item) => {
                            return <TabHeader data={item} key={item.id} />;
                        })}
                    </div>
                    <div className={cssTab1}>
                        <div className="frame-content">
                            <div dangerouslySetInnerHTML={{ __html: t('landing_page.join.tab1_content') }}></div>

                            <div className="steps ">
                                <div className="step  flex align-item-center">
                                    <div className="step-left">
                                        <img className="step-icon lazyload" data-src="images/icons/dangky.png" />
                                    </div>
                                    <div className="step-right">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab1_content.l1')
                                            }}></div>
                                    </div>
                                </div>
                                <div className="step flex align-item-center">
                                    <div className="step-left">
                                        <img className="step-icon lazyload" data-src="images/icons/thanhtich.png" />
                                    </div>

                                    <div className="step-right">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab1_content.l2')
                                            }}></div>
                                    </div>
                                </div>

                                <div className="step flex align-item-center">
                                    <div className="step-left">
                                        <img className="step-icon lazyload" data-src="images/icons/reward.png" />
                                    </div>
                                    <div className="step-right">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab1_content.l3')
                                            }}></div>
                                    </div>
                                </div>
                            </div>

                            <div className="frame-fancy" style={{ display: 'block' }}>
                                <img className="lazyload" data-src="images/header-slide/uprace.jpg" alt="" />
                                <a
                                    className="btn-play"
                                    href="https://uprace2.vcdn.vn/uprace2021_event/uprace_tutorial.mp4"
                                    data-fancybox>
                                    <i className="icon icon-play"></i>
                                    <span className="hidden">play</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={cssTab2}>
                        <div className="frame-content">
                            <p
                                className="space-50"
                                dangerouslySetInnerHTML={{ __html: t('landing_page.join.tab2_content') }}></p>
                            <div className="flex contact justify-content-space-between">
                                <a className="flex column align-item-center" href="mailto:sponsor@UpRace.org">
                                    <img className="lazyload space-10" src="images/icons/gm.png" alt="" />
                                    <p
                                        className="text-center color-black-gray bold"
                                        dangerouslySetInnerHTML={{
                                            __html: t('landing_page.join.tab2_content.l1')
                                        }}></p>
                                </a>
                                <a
                                    className="flex column align-item-center"
                                    href="https://www.facebook.com/UpRace.org"
                                    target="_blank">
                                    <img className="lazyload space-10" src="images/icons/fb.png" alt="" />
                                    <p
                                        className="text-center color-black-gray bold"
                                        dangerouslySetInnerHTML={{
                                            __html: t('landing_page.join.tab2_content.l2')
                                        }}></p>
                                </a>
                            </div>

                            <div className="form">
                                <p
                                    className="space-50 bold color-black-gray"
                                    dangerouslySetInnerHTML={{ __html: t('landing_page.join.tab2_content.l3') }}></p>
                                <label htmlFor="name">{t('landing_page.join.input.name')}:</label>
                                <br />
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    maxLength={60}
                                    value={name}
                                    onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                />
                                {errName ? <p className="color-red">{t('global.error.valid_name')}</p> : null}
                                <br />
                                <label htmlFor="email">E-mail:</label>
                                <br />
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    maxLength={50}
                                    value={email}
                                    onChange={(text) => handleChangeEmail(stripedHtml(text.target.value))}
                                />
                                {errEmail ? <p className="color-red">{t('global.error.valid_email')}</p> : null}
                                <br />
                                <label htmlFor="phonenumber">{t('landing_page.join.input.phone')}:</label>
                                <br />
                                <input
                                    type="text"
                                    id="phonenumber"
                                    name="phonenumber"
                                    maxLength={20}
                                    value={phone}
                                    onChange={(text) => handleChangePhone(stripedHtml(text.target.value))}
                                />
                                {errPhone ? <p className="color-red">{t('global.error.valid_phone')}</p> : null}
                                <br />
                                <label htmlFor="message">{t('landing_page.join.input.mess')}:</label>
                                <br />
                                <textarea
                                    type="text"
                                    id="message"
                                    name="message"
                                    maxLength={1500}
                                    value={message}
                                    onChange={(text) => handleChangeMessage(stripedHtml(text.target.value))}></textarea>
                                {errMess ? <p className="color-red">{t('global.error.valid_mess')}</p> : null}

                                <br />
                                <input
                                    className="btn-submit"
                                    type="submit"
                                    value={t('global.send')}
                                    onClick={() => onSendInfo()}
                                />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: linkDown }}></p>
                        </div>
                    </div>
                    <div className={cssTab3}>
                        <div className="frame-content">
                            <p
                                className="space-50 "
                                dangerouslySetInnerHTML={{
                                    __html: t('landing_page.join.tab3_content')
                                }}></p>
                            <div className="flex justify-content-space-between contribute">
                                <div className="guild">
                                    <div className="guild-step flex  align-item-center">
                                        <h1 className="big-text">1</h1>
                                        <p
                                            className="color-black-gray"
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab3_content.l1')
                                            }}></p>
                                    </div>
                                    <div className="guild-step flex  align-item-center">
                                        <h1 className="big-text">2</h1>
                                        <p
                                            className="color-black-gray"
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab3_content.l2')
                                            }}></p>
                                    </div>
                                    <div className="guild-step flex  align-item-center">
                                        <h1 className="big-text">3</h1>
                                        <p
                                            className="color-black-gray"
                                            dangerouslySetInnerHTML={{
                                                __html: t('landing_page.join.tab3_content.l3')
                                            }}></p>
                                    </div>
                                </div>
                                <img className="qr-uprace lazyload" data-src="images/qr/uprace.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    })
);

export default JoinNow;
