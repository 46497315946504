import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Top3NewsItem from './Top3NewsItem';

const Top3News = inject('authStore','newsStore')(
    observer((props) => {
        const { data } = props;
        const { listTop3News } = props.newsStore;

        useEffect(() => {}, []);

        return (
            <div className="news-relatative ">
                <h4>TIN TỨC LIÊN QUAN</h4>
                {listTop3News && listTop3News.map((item) => {
                    return <Top3NewsItem data = {item} key={item.id} />
                })}
            </div>
        );
    })
);

export default Top3News;
