import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';
import { useTranslation } from 'react-i18next';

const FriendItem = inject(
    'globalStore',
    'inviteFriendStore',
    'userStore',
    'userProfileStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { data, eventID } = props;
        const {
            listMemberTemp,
            doAddMemberTemp,
            doAddMember,
            doRemoveMemberTemp,
            doRemoveMember
        } = props.inviteFriendStore;
        const { userProfile, doChangeSelectedStatusUser } = props.userStore;
        const { doChangeSelectedStatusUserFollower } = props.userProfileStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        function addMember() {
            if (listMemberTemp.length >= 5 && !data.selected) {
                setMessageSnackbar(t('global.limit_compare_friends'));
                setSnackbarType('error');
                onOpenSnackbar();
                return;
            }

            doChangeSelectedStatusUser(data.uid);
            doChangeSelectedStatusUserFollower(data.uid);

            if (data.selected) {
                doAddMemberTemp(data);
                doAddMember(data);
                //props.addMemberCompare(data.uid);
            } else {
                doRemoveMemberTemp(data);
                doRemoveMember(data);
                //props.removeMemberCompare(data.uid);
            }
        }

        const css = data.selected && data.selected ? 'active' : '';

        return (
            <li className={css} onClick={() => addMember()}>
                <p className="check">
                    <span className="ico"></span>
                </p>
                <p className="avatar">
                    <img src={getImageResource(data.ava, true, true)} alt="" />
                </p>
                <div className="details">
                    <p className="name">{data.name}</p>
                    <p className="code">{data.code}</p>
                </div>
            </li>
        );
    })
);

export default FriendItem;
