import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import { SOCIAL_TAB } from '../../../../helpers/ConstDefined';
import FollowList from './FollowList';
import { useTranslation } from 'react-i18next';

const DialogFollowerUsers = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { openDialogUserFollower, tab, profileID, eventID } = props;
        const [tabLocal, setTabLocal] = React.useState(tab);

        const { t } = useTranslation();

        const handleChangeTab = (tab) => {
            setTabLocal(tab);
        };

        const Tabs = [
            { id: SOCIAL_TAB.FOLLOWING, title: 'global.you_follow' },
            { id: SOCIAL_TAB.FOLLOWER, title: 'global.follower1' }
        ];

        function MenuItem(props) {
            const { category } = props;
            const cssClass = category.id == tabLocal ? 'active' : '';

            return (
                <li class={cssClass} onClick={() => handleChangeTab(category.id)}>
                    <a href="javascript:void(0);">
                        <span class="txt">{t(category.title)}</span>
                    </a>
                </li>
            );
        }

        return (
            <Dialog open={openDialogUserFollower} onClose={props.onClose}>
                <div
                    class="uprace-popup has-title in"
                    id="follower-list"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => props.onClose()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div class="popup-body">
                                <div class="post-action-popup">
                                    <div class="action-tabs-menu">
                                        <ul>
                                            {Tabs.map((cat) => (
                                                <MenuItem key={cat.id} category={cat} />
                                            ))}
                                        </ul>
                                    </div>
                                    <div class="tabs-content action-tabs-content">
                                        <ul>
                                            <FollowList
                                                key={tabLocal}
                                                profileID={profileID}
                                                eventID={eventID}
                                                tabType={tabLocal}
                                                onClose={props.onClose}
                                            />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogFollowerUsers;
