import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { toJS } from 'mobx';
import SponsorCoItem from './SponsorCoItem';
import SponsorRaceItem from './SponsorRaceItem';
import SponsorOtherItem from './SponsorOtherItem';
import { useTranslation } from 'react-i18next';

const Sponsors = inject('newsStore')(
    observer((props) => {
        const { listSponsors } = props.newsStore;

        useEffect(() => {}, []);
        const { t, i18n } = useTranslation();

        function getData(type) {
            return _.filter(listSponsors, (item) => {
                return item.type == type;
            });
        }

        if (listSponsors != null)
            return (
                <>
                    <h1 className="text-center space-20">{t('landing_page.sponsors.title')}</h1>
                    <p
                        className="text-center space-80"
                        dangerouslySetInnerHTML={{
                            __html: t('landing_page.sponsors.desc')
                        }}></p>
                    <div className="sponsors-list">
                        <div className="sponsors--1 space-80">
                            <p className="sponsors--title color-black-gray space-20 bold text-center">
                                Co-title Sponsor
                            </p>
                            <div className="frame-border frame-border--1 flex wrap justify-content-space-around">
                                {getData(0).map((item) => {
                                    return <SponsorCoItem key={item.id} data={toJS(item)} />;
                                })}
                            </div>
                        </div>
                        <div className="sponsors--2 space-80" style={{display: 'none'}}>
                            <p className="sponsors--title color-black-gray space-20 bold text-center">Race Sponsor</p>
                            <div className="flex wrap justify-content-center">
                                {getData(1).map((item) => {
                                    return <SponsorRaceItem key={item.id} data={toJS(item)} />;
                                })}
                            </div>
                        </div>
                        <div className="sponsors--2 space-80">
                            <p className="sponsors--title color-black-gray space-20 bold text-center">Other Sponsor</p>
                            <div className="flex wrap justify-content-center">
                                {getData(2).map((item) => {
                                    return <SponsorOtherItem key={item.id} data={toJS(item)} />;
                                })}
                            </div>
                        </div>
                        <div className="sponsors--3">
                            <p className="sponsors--title color-black-gray space-20 bold text-center">Donor</p>
                            <div className="flex wrap justify-content-center">
                                {getData(3).map((item) => {
                                    return <SponsorOtherItem key={item.id} data={toJS(item)} />;
                                })}
                            </div>
                        </div>
                    </div>
                </>
            );
        else return null;
    })
);

export default Sponsors;
