import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MemberRequestJoinItem from './MemberRequestJoinItem';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../helpers/ConstDefined';

const ListMemberRequest = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID, teamID } = props;
        const {
            loadingGroup,
            totalMemberRqJoin,
            listMemberRequestJoin,
            doGetListMemberRqJoin,
            doGetListMemberJoined,
            doActionGroup,
            doApproveMember,
            doRejectMember,
            doApproveMemberAll
        } = props.groupStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const isShowLoadMore = listMemberRequestJoin == null ? false : listMemberRequestJoin.length < totalMemberRqJoin;

        useEffect(() => {
            getListMemberRequestJoin();
        }, []);

        const { t } = useTranslation();

        const getListMemberRequestJoin = (isLoadMore) => {
            const params = {
                uid: userProfile.uid,
                tmid: teamID,
                evid: eventID,
                rgrid: data.grid
            };
            doGetListMemberRqJoin(params, isLoadMore);
        };

        const getListMemberJoined = () => {
            const params = {
                uid: userProfile.uid,
                tmid: teamID,
                evid: eventID,
                grid: data.grid
            };
            doGetListMemberJoined(params);
        };

        const getMemberRqMore = () => {
            getListMemberRequestJoin(true);
        };

        async function actionMembrerRequest(pCallback) {
            const params = {
                uid: userProfile.uid,
                grid: data.grid,
                action: pCallback.action,
                pfid: pCallback.uid
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                if (pCallback.action == GROUP_ACTION.ADMIN_APPROVE_JOIN) doApproveMember(pCallback.uid);
                else if (pCallback.action == GROUP_ACTION.ADMIN_REJECT_JOIN) doRejectMember(pCallback.uid);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        function getListIDMemberRequest() {
            let list = [];
            _.forEach(listMemberRequestJoin, (item) => {
                list.push(item.uid);
            });
            return list;
        }

        async function onApproveAllMember() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                grid: data.grid,
                uids: getListIDMemberRequest()
            };
            let result = await doApproveMemberAll(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                getListMemberRequestJoin();
                getListMemberJoined();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberRqMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function ApproveAllButton() {
            if (totalMemberRqJoin > 0) {
                if (loadingGroup) return <button className="btn-44 blue loading"></button>;
                else
                    return (
                        <>
                            <button className="btn-44 blue" onClick={() => onApproveAllMember()}>
                                {t('team.managegroup.approve_all')}
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="group-member-list">
                <div className="m-list-scroll">
                    <ul className="m-list">
                        {listMemberRequestJoin &&
                            listMemberRequestJoin.map((item) => {
                                return (
                                    <MemberRequestJoinItem
                                        actionMembrerRequest={actionMembrerRequest}
                                        data={item}
                                        key={item.uid}
                                    />
                                );
                            })}
                    </ul>
                </div>
                <LoadMore />

                <div className="btn-row">
                    <ApproveAllButton />
                </div>
            </div>
        );
    })
);

export default ListMemberRequest;
