import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
const queryString = require('query-string');
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

import RuleEvent2021EN from './RuleEvent2021EN';
import RuleEvent2021VI from './RuleEvent2021VI';
import RuleEvent2022EN from './RuleEvent2022EN';
import RuleEvent2022VI from './RuleEvent2022VI';
import RuleEvent2023VI from './RuleEvent2023VI';
import RuleEvent2023EN from './RuleEvent2023EN';

const EventRuleDetail = inject('globalStore')(
    observer((props) => {
        let paramsCallback = queryString.parse(props.location.search);
        if (_.isEmpty(paramsCallback) || _.isEmpty(paramsCallback.evid)) return <Redirect to={`/event`}></Redirect>;

        const { language, doChangeLanguage } = props.globalStore;

        let eventId = paramsCallback.evid;
        let lang = paramsCallback.lang;

        useEffect(() => {
            doChangeLanguage(lang);
        },[])

        const RenderContent2021 = () => {
            if (lang == 'vi' || language == 'vi') return <RuleEvent2021VI />;
            else if (lang == 'en' || language == 'en') return <RuleEvent2021EN />;
            else return <RuleEvent2021VI />;
        };

        const RenderContent2022 = () => {
            if (lang == 'vi' || language == 'vi') return <RuleEvent2022VI />;
            else if (lang == 'en' || language == 'en') return <RuleEvent2022EN />;
            else return <RuleEvent2022VI />;
        };

        const RenderContent2023 = () => {
            if (lang == 'vi' || language == 'vi') {
               
                return <RuleEvent2023VI />;
            }
            else if (lang == 'en' || language == 'en') {
              
                return <RuleEvent2023EN />;
            }
            else return <RuleEvent2023VI />;
        };

        const RenderContent = () => {
            if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2021_ID) {
                return <RenderContent2021 />;
            }
            else if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2022_ID) {
                return <RenderContent2022 />;
            }
            else if(eventId == GLOBAL_CONFIG.EVENT_UPRACE_2023_ID) {
                return <RenderContent2023 />;
            }
            else return null;
        };

        return (
            <div>
                <RenderContent />
            </div>
        );
    })
);

export default EventRuleDetail;
