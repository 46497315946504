import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LEADERBOARD_TYPE, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';
import GroupRankItem from './GroupRankItem';
import { stripedHtml } from '../../../../utils';
import { DialogUserEvent } from 'views/YourInfo/components';
import $ from 'jquery';

let profileData;

const GroupRankList = inject(
    'authStore',
    'userStore',
    'groupStore',
    'eventStore'
)(
    observer((props) => {
        const { eventID, teamID, valSearch } = props;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const {
            loadingGroup,
            totalMemberGroup,
            userRank,
            currentGroup,
            doGetUserRank,
            doClearUserRank,
            listLeaderboard
        } = props.groupStore;

        //filter
        const [name, setName] = React.useState(valSearch);
        const isShowLoadMore = listLeaderboard == null ? false : listLeaderboard.length < totalMemberGroup;
        const cssShowClear = name && name.length > 0 ? 'search-box active' : 'search-box';

        const [openDialogUserEvent, setOpenDialogUserEvent] = React.useState(false);

        useEffect(() => {
            initSticky();
        }, []);

        useEffect(() => {
            if (currentEvent && currentEvent.grid != 0 && currentGroup.grid == currentEvent.grid)
                getUserRank(currentEvent.grid);
            else doClearUserRank();
        }, [currentEvent]);

        const { t } = useTranslation();

        function initSticky() {
            var wScreen = $(window).width();
            if (wScreen > 1050) {
                $('#m-sticker').sticky({ topSpacing: 144, center: true, className: 'sticker-box' });
            } else {
                $('#m-sticker').sticky({ topSpacing: 98, center: true, className: 'sticker-box' });
            }
        }

        async function getUserRank() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.USER_GROUP,
                value: userProfile && userProfile.uid,
                tmid: teamID
            };
            doGetUserRank(params);
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                props.onSearchMemberGroup(name);
            }
        };

        const getMemberRankListMore = () => {
            props.onLoadMoreMemberGroup();
        };

        function resetSearch() {
            setName('');
            props.onSearchMemberGroup();
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberRankListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        const onViewUserEvent = (user) => {
            profileData = user;
            setOpenDialogUserEvent(true);
        };

        return (
            <div className="group-details-wbox g-members-list-box">
                <h2 className="title">{t('team.groupdetail.bxh_group')}</h2>

                <div className="group-details-search">
                    <div className={cssShowClear}>
                        <button className="del-btn" onClick={() => resetSearch()}></button>
                        <input
                            type="text"
                            placeholder={t('team.grouplist.search_group')}
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        <button className="search-btn" onClick={() => props.onSearchMemberGroup(name)}>
                            {t('global.search')}
                        </button>
                    </div>
                </div>

                <div className="members-list">
                    <div className="sticky" id="m-sticker">
                        <ul className="title-row">
                            <li className="level">{t('global.rank')}</li>
                            <li className="name">{t('global.member_name')}</li>
                            <li className="code">{t('global.number_act')}</li>
                            <li className="sum">{t('global.total_km')}</li>
                        </ul>
                        <ul className="list">{userRank ? <GroupRankItem onViewUserEvent={onViewUserEvent} data={userRank} /> : null}</ul>
                    </div>
                    <ul className="list">
                        {listLeaderboard &&
                            listLeaderboard.map((item) => {
                                return <GroupRankItem  onViewUserEvent={onViewUserEvent} data={item} key={item.id} />;
                            })}
                    </ul>
                    <LoadMore />
                </div>

                {openDialogUserEvent ? (
                    <DialogUserEvent
                        openDialogUserEvent={openDialogUserEvent}
                        profileData={profileData}
                        eventID={eventID}
                        teamID={teamID}
                        onClose={() => setOpenDialogUserEvent(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default GroupRankList;
