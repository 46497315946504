import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import ImgReview from './ImgReview';
import { useTranslation } from 'react-i18next';

const UploadFile = inject(
    'authStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const {
            arrayImageFeedReal,
            doResetImageFeed,
            doSetImageUploadReal,
            doRemoveImageFeed,
            doUploadFile,
            setSnackbarType,
            setMessageSnackbar,
            onOpenSnackbar
        } = props.globalStore;
        const { data } = props;
        const { userProfile } = props.userStore;

        useEffect(() => {
            doResetImageFeed();
            getPhotoFeed();
        }, []);
        const { t } = useTranslation();

        function getPhotoFeed() {
            if (data != null) {
                data.photos.map((url) => {
                    doSetImageUploadReal(url);
                });
            }
        }

        let fileObj = [];
        function uploadMultipleFiles(e) {
            if (arrayImageFeedReal.length + e.target.files.length > 10) {
                setMessageSnackbar(t('team.post.create.limit_select_img'));
                setSnackbarType('error');
                onOpenSnackbar();
                return;
            }

            fileObj.push(e.target.files);
            for (let i = 0; i < fileObj[0].length; i++) {
                uploadFileServer(fileObj[0][i]);
            }
        }

        async function uploadFileServer(file) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: 1
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                doSetImageUploadReal(result);
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onRemoveImage = (url) => {
            doRemoveImageFeed(url);
        };

        return (
            <div class="imgs-box">
                <div class="imgs-title">
                    <p class="lb">
                        Album <span class="num">({arrayImageFeedReal.length}/10)</span>
                    </p>
                    {arrayImageFeedReal.length < 10 ? (
                        <div class="add-img">
                            <input
                                type="file"
                                accept="image/*"
                                name=""
                                class="file-upload-input"
                                multiple
                                onChange={uploadMultipleFiles}
                            />
                            {t('global.add_image')}
                        </div>
                    ) : null}
                </div>
                <div class="imgs-list">
                    {arrayImageFeedReal.map((url) => (
                        <ImgReview key={url} removeImage={() => onRemoveImage(url)} imgPath={url} />
                    ))}
                </div>
            </div>
        );
    })
);

export default UploadFile;
