import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
const queryString = require('query-string');
import { Redirect } from 'react-router-dom';
import { stripedHtml } from 'utils';
import { COMMON_HTML_TYPE, GLOBAL_CONFIG } from '../../helpers/ConstDefined';
import { ChangePassResult, RuleEvent, CompanyInfo, SeaBankRuleEvent } from './components';

let paramsCallback;

const Common = inject('authStore')(
    observer((props) => {
        paramsCallback = queryString.parse(props.location.search);
        if (_.isEmpty(paramsCallback)) return <Redirect to={`/event`}></Redirect>;

        useEffect(() => {}, []);

        const RenderHTMLRequest = () => {
            if (paramsCallback.type == COMMON_HTML_TYPE.CONFIRM_PASS) {
                return <ChangePassResult token={stripedHtml(paramsCallback.token)} />;
            } else if (paramsCallback.type == COMMON_HTML_TYPE.RULE_EVENT || paramsCallback.type == COMMON_HTML_TYPE.RULE_EVENT_2021) {
                return <RuleEvent lang={stripedHtml(paramsCallback.lang)} type={stripedHtml(paramsCallback.type)} />;
            } else if (paramsCallback.type == COMMON_HTML_TYPE.COMPANY_INFO) {
                return <CompanyInfo id={stripedHtml(paramsCallback.id)} lang={stripedHtml(paramsCallback.lang)} />;
            } else if (paramsCallback.type == COMMON_HTML_TYPE.SEARUN_EVENT) {
                return <SeaBankRuleEvent lang={stripedHtml(paramsCallback.lang)} />;
            }
        };

        return (
            <div>
                <RenderHTMLRequest />
            </div>
        );
    })
);

export default Common;
