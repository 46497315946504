import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FEED_TYPE, FEED_REPORT_TYPE, ACTIVITY_TYPE } from '../../../../../helpers/ConstDefined';
import ActivityItem from './ActivityItem';

const ComplainList = inject(
    'globalStore',
    'reportStore',
    'userStore',
    'newsStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const {
            loading,
            listReport,
            listComplain,
            totalReport,
            totalComplain,
            doGetListReport,
            doGetListComplain
        } = props.reportStore;
        const { appVersion } = props.globalStore;
        const { userProfile } = props.userStore;
        const { listErrorActivity, doGetListErrorActivity } = props.newsStore;

        const isShowLoadMoreReport = listReport == null ? false : listReport.length < totalReport;
        const isShowLoadMoreComplain = listComplain == null ? false : listComplain.length < totalComplain;
        const [tab, setTab] = React.useState(0);

        const { t } = useTranslation();

        useEffect(() => {
            getListReport();
            getListComplain();
            if(!listErrorActivity) doGetListErrorActivity();
        }, []);

        const TABS = [
            {
                id: 0,
                title: 'global.report'
            },
            {
                id: 1,
                title: 'global.complain'
            }
        ];

        const handleChangeTab = (val) => {
            setTab(val);
            if (val == 0) getListReport();
            else getListComplain();
        };

        function TabItem(props) {
            const { data } = props;
            const css = data.id == tab ? 'active' : '';
            return (
                <li className={css} onClick={() => handleChangeTab(data.id)}>
                    <a href="javascript:void(0);">{t(data.title)}</a>
                </li>
            );
        }

        const getListReport = (isLoadMore) => {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.TEAM_ACTIVITY,
                evid: eventID,
                value: teamID,
                rpsts: FEED_REPORT_TYPE.REPORT
            };
            doGetListReport(params, isLoadMore);
        };

        const getListComplain = (isLoadMore) => {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.TEAM_ACTIVITY,
                evid: eventID,
                value: teamID,
                rpsts: FEED_REPORT_TYPE.COMPLAIN
            };
            doGetListComplain(params, isLoadMore);
        };

        function LoadMoreReport() {
            if (isShowLoadMoreReport) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListReport(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function LoadMoreComplain() {
            if (isShowLoadMoreComplain) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListComplain(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function RenderReport() {
            return (
                <li class="active">
                    <h2 class="complain-title">
                        {t('global.list_act_reported')} ({totalReport})
                    </h2>
                    <div class="complain-note">{t('global.list_act_reported_desc')}</div>
                    <div class="yours--activity">
                        <div class="activity-list">
                            <ul>
                            {listReport &&
                                    listReport.map((item) => {
                                        return (
                                            <ActivityItem
                                                data={item}
                                                key={item.atid}
                                                type={ACTIVITY_TYPE.REPORT}
                                                eventID={eventID}
                                            />
                                        );
                                    })}
                            </ul>
                            <LoadMoreReport />
                        </div>
                    </div>
                </li>
            );
        }

        function RenderComplain() {
            return (
                <li class="active">
                    <h2 class="complain-title">
                        {t('global.list_act_complained')} ({totalComplain})
                    </h2>
                    <div class="complain-note">{t('global.list_act_complained_desc')}</div>
                    <div class="yours--activity">
                        <div class="activity-list fail">
                            <ul>
                                {listComplain &&
                                    listComplain.map((item) => {
                                        return (
                                            <ActivityItem
                                                data={item}
                                                key={item.atid}
                                                type={ACTIVITY_TYPE.COMPLAIN}
                                                eventID={eventID}
                                            />
                                        );
                                    })}
                            </ul>
                            <LoadMoreComplain />
                        </div>
                    </div>
                </li>
            );
        }

        return (
            <div class="manage-complain">
                <div class="complain-tabs-menu">
                    <ul>
                        {TABS.map((item) => {
                            return <TabItem data={item} key={item.id} />;
                        })}
                    </ul>
                </div>
                <div class="complain-tabs-ctn tabs-content">
                    <ul>{tab == 1 ? <RenderComplain /> : <RenderReport />}</ul>
                </div>
            </div>
        );
    })
);

export default ComplainList;
