import { fetchJson } from '../helpers/api/APIHelper';
import { observable, action, toJS } from 'mobx';
import { API } from '../helpers/api/APIDefined';

export default class NewsStore {
    @observable listErrorActivity = null;
    @observable listSponsors = null;
    @observable listNews = null;
    @observable listNews2021 = null;
    @observable listCompany = null;
    @observable listPeopleSay = null;
    @observable listTop3News = null;
    @observable listBanner = null;


    @action.bound
    doGetListErrorActivity() {
        if(this.listErrorActivity != null) return;
        fetchJson(API.JSON_LINK.ERROR_ACTIVITY)
            .then((resp) => {
                this.listErrorActivity = resp;
                console.log(resp)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetListBanner() {
        fetchJson(API.JSON_LINK.BANNER)
            .then((resp) => {
                this.listBanner = resp;
                console.log(resp)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetTop3News() {
        let list = _.sortBy(this.listNews2021, (item) => {
            return item.date;
        }).reverse();
        this.listTop3News = _.slice(list,0,3);
    }

    @action.bound
    doGetListPeopleSay() {
        fetchJson(API.JSON_LINK.PEOPLE_SAY)
            .then((resp) => {
                this.listPeopleSay = resp;
                console.log(resp)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetListCompany() {
        fetchJson(API.JSON_LINK.COMPANY)
            .then((resp) => {
                this.listCompany = resp;
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetListSponsors() {
        fetchJson(API.JSON_LINK.LIST_SPONSORS)
            .then((resp) => {
                this.listSponsors = resp;
                console.log('xxxxx', resp)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetListNews() {
        fetchJson(API.JSON_LINK.NEWS)
            .then((resp) => {
                this.listNews = resp;
            })
            .catch((error) => {
                console.log(error)
            });
    }

    @action.bound
    doGetListNews2021() {
        fetchJson(API.JSON_LINK.NEWS_2022)
            .then((resp) => {
                this.listNews2021 = resp;
                this.doGetTop3News();
            })
            .catch((error) => {
                console.log(error)
            });
    }
}
