import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Slider } from '../../../../assets/js/event_detail';
import { GROUP_JOIN_STATUS } from 'helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import { formatNumber, getImageResource } from '../../../../utils';
import { ListGroupDialog } from '..';

const TopGroup = inject(
    'userStore',
    'groupStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;
        const { userProfile } = props.userStore;
        const { listGroupJoin, doGetListGroupJoin } = props.groupStore;

        const [openDialogListGroup, setOpenDialogListGroup] = React.useState(false);
        
        useEffect(() => {
            getTopGroup();
        }, []);

        //restart slider every change list
        useEffect(() => {
            const slider = new Slider();
        }, [listGroupJoin]);

        const { t, i18n } = useTranslation();

        const getTopGroup = (search) => {
            const params = {
                uid: userProfile.uid,
                evid: eventData.evid,
                gejsts: GROUP_JOIN_STATUS.JOINED,
                tmid: 0
            };
            doGetListGroupJoin(params);
        };

        const goToDetail = (grid) => {
            navigation.push(`/group-detail?grid=${grid}&evid=${eventData.evid}`);
        }

        const GroupItem = (props) => {
            const { data } = props;
            return (
                <div class="slider-content__item" onClick={() => goToDetail(data.grid)}>
                    <div class="group-info">
                        <div class="group-info-thumbnail">
                            <img src={getImageResource(data.ava, true)} alt="logo" class="group-info-thumbnail-item" />
                        </div>
                        <div class="group-info-name">{data.name}</div>
                        <div class="group-info-size">
                            <span class="number">{data.mem} </span>
                            <span class="txt">{t('global.member_join').toLowerCase()}</span>
                        </div>
                    </div>
                </div>
            );
        };

        if (listGroupJoin && listGroupJoin.length > 0)
            return (
                <div class="card card-group-challenges">
                    <div class="card-group-challenges-body">
                        <div class="card-title">
                            <h3 class="title">{t('eu.list_group_in_event')}</h3>
                            <a class="view-all" href="#" onClick={() => setOpenDialogListGroup(true)}>
                                {t('global.all')}
                            </a>
                        </div>

                        <div class="slider-controls-outline">
                            <div id="sliderGroupChallenges" class="slider">
                                <div class="slider-content">
                                    <div class="slider-content-wrapper">
                                        {listGroupJoin &&
                                            listGroupJoin.map((item) => {
                                                return <GroupItem data={item} key={item.grid} />;
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div class="slider-controls">
                                <button class="prev-arrow" id="prev-arrow"></button>
                                <button class="btn next-arrow" id="next-arrow"></button>
                            </div>
                        </div>
                    </div>
                    {openDialogListGroup == true ? (
                        <ListGroupDialog
                            openDialogListGroup={openDialogListGroup}
                            eventData={eventData}
                            navigation={navigation}
                            getTopGroup={getTopGroup}
                            onClose={() => setOpenDialogListGroup(false)}
                        />
                    ) : null}
                </div>
            );
        else return null;
    })
);

export default TopGroup;
