import React, { useEffect, forwardRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDate, getImageResource, stripedHtml } from 'utils';
import moment from 'moment';
import { EVENT_JOIN_STATUS, GLOBAL_CONFIG, LEADERBOARD_TYPE, EVENT_PUBlIC_TYPE } from 'helpers/ConstDefined';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import MemberRankItem from './MemberRankItem';
import { GENDER_TYPE } from '../../../../data/master';
import NotJoinEvent from '../LeaderboardGroup/NotJoinEvent';

const LeaderboardUser = inject(
    'eventStore',
    'teamManageStore',
    'userStore',
    'globalStore',
    'groupStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;
        const { loading, totalGroupRank, listGroupRank, doGetLeaderboardGroup } = props.teamManageStore;

        const { groupRank, doGetGroupRank } = props.groupStore;
        const { userProfile } = props.userStore;

        const [sexLabelFilter, setSexLabelFilter] = useState(GENDER_TYPE[0].title);
        const isShowLoadMore = listGroupRank == null ? false : listGroupRank.length < totalGroupRank;
        const [search, setSearch] = React.useState('');
        const [day, setDay] = React.useState(null);
        const [sex, setSex] = React.useState(0);
        const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));
        const isJoined =
            userProfile && eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING;

        const isAllowView =
            eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ||
            (eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING);

        const { t } = useTranslation();

        useEffect(() => {
            getLeaderboardUser();
        }, []);

        useEffect(() => {
            if (day != null) {
                if (isJoined) getMeRank();
                getLeaderboardUser(); // auto search when day change
            }
        }, [day]);

        useEffect(() => {
            if (isJoined) getMeRank();
        }, [userProfile, eventData.jsts]);

        async function getMeRank() {
            if (!userProfile) return;
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.USER_EVENT,
                day,
                value: userProfile.uid
            };
            doGetGroupRank(params);
        }

        const getLeaderboardUser = (sex, isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: LEADERBOARD_TYPE.USER_EVENT,
                value: 0,
                sex,
                name: search,
                day
            };
            doGetLeaderboardGroup(params, isLoadMore);
        };

        const getUserRankListMore = () => {
            getLeaderboardUser(sex, true);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getLeaderboardUser();
            }
        };

        const handleChangeDate = (val) => {
            const format = 'YYYYMMDD';
            const day = formatDate(format, val);
            const dayText = formatDate('DD/MM', val);
            setDayText(dayText);
            setDay(day);
        };

        const handleChangeTab = (tab) => {
            setSex(tab.id);
            getLeaderboardUser(tab.id);
            setSexLabelFilter(tab.title);
            //resetParamsFilter();
        };

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <div className="datepicker" onClick={onClick}>
                <span className="txt">{dayText} (Km)</span>
                <input type="text" id="datepicker" />
            </div>
        ));

        function MenuSexItem(props) {
            const { data } = props;
            return <li onClick={() => handleChangeTab(data)}>{t(data.title)}</li>;
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getUserRankListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                {isAllowView ? (
                    <div class="card card-details card-rank">
                        <div class="card-details-header">
                            <div class="left">
                                <h4 class="title">{t('global.leaderboard')}</h4>
                            </div>
                            <div class="right">
                                <div class="filter-box">
                                    <div class="uiselector gender">
                                        <p
                                            class="uiselector--click"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            {t(sexLabelFilter)}
                                        </p>
                                        <ul class="uiselector--list" aria-labelledby="list-server-choose">
                                            {GENDER_TYPE.map((item) => {
                                                return <MenuSexItem data={item} key={item.id} />;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-details-body">
                            <div class="frm-row">
                                <div class="frm-row__item search">
                                    <input
                                        type="text"
                                        placeholder={t('global.search')}
                                        value={search}
                                        onKeyDown={handleKeyDown}
                                        onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                    />
                                </div>
                                <div class="frm-row__item">
                                    <button class="btn btn-40 btn-blue btn-search" onClick={() => getLeaderboardUser()}>
                                        {t('global.search')}
                                    </button>
                                </div>
                            </div>

                            <div class="teams-list">
                                <div class="sticky" id="sticker">
                                    <ul class="title-row">
                                        <li class="level">{t('global.rank')}</li>
                                        <li class="name">{t('global.member_name')}</li>
                                        <li class="date">
                                            <DatePicker onChange={handleChangeDate} customInput={<CustomInputDate />} />
                                        </li>
                                        <li class="sum">{t('global.total_km')}</li>
                                    </ul>
                                    <ul class="list">
                                        {isJoined && groupRank && groupRank.rank > 0 ? (
                                            <ul className="list" style={{ display: 'block' }}>
                                                <MemberRankItem data={groupRank} navigation={navigation} />
                                            </ul>
                                        ) : null}
                                    </ul>
                                </div>
                                <ul class="list">
                                    {listGroupRank &&
                                        listGroupRank.map((item) => {
                                            return <MemberRankItem data={item} key={item.id} navigation={navigation} />;
                                        })}
                                </ul>
                                <LoadMore />
                            </div>
                        </div>
                    </div>
                ) : <NotJoinEvent />}
            </>
        );
    })
);

export default LeaderboardUser;
