import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { stripedHtml, getUUID } from '../../utils/index';
import { REGISTER_ERROR, LOGIN_PLATFORM, GLOBAL_CONFIG } from '../../helpers/ConstDefined';
import * as moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import md5 from 'blueimp-md5';
import { useTranslation } from 'react-i18next';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignUpConfirm = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { user, captcha, doGetCaptcha, doRegister } = props.authStore;
        const {
            messageSnackbar,
            onCloseSnackbar,
            openSnackbar,
            snackbarType,
            setMessageSnackbar,
            setSnackbarType,
            onOpenSnackbar
        } = props.globalStore;

        const [captchaVal, setCaptchaVal] = useState('');

        const { t } = useTranslation();

        useEffect(() => {
            getCaptcha();
        }, []);

        async function getCaptcha() {
            const params = {
                dvtk: getUUID()
            };
            let result = await doGetCaptcha(params);
            if (!result) {
                setMessageSnackbar(t('signupconfirm.error.get_code_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onChangeEmail = () => {
            props.history.push('/register');
        };

        const handleChangeCaptcha = (captcha) => {
            setCaptchaVal(captcha);
        };

        async function onRegister() {
            if (captcha == null) {
                return;
            }
            const password = user.type == undefined ? md5(user.password) : user.password;
            const email = user.type == undefined ? user.email : user.emailText;

            const params = {
                type: user.type == undefined ? 3 : user.type,
                email,
                password,
                gender: user.gender == 0 ? 1 : 2,
                birthday: moment(user.birthday).format('x'),
                country: user.ctry,
                city: user.city,
                name: user.name,
                weight: user.weight,
                height: user.height,
                lang: 'vi',
                tranId: captcha.tranId,
                captcha: captchaVal,
                openId: user.openId,
                phone: user.phone,
                addr: user.addr,
                shirt: user.shirt
            };

            let result = await doRegister(params);

            if (result == REGISTER_ERROR.SUCCESS) {
                props.history.push(`/event`);
            } else if (result == REGISTER_ERROR.USER_EXISTED) {
                getCaptcha();
                setMessageSnackbar(t('signupconfirm.error.email_exist'));
                setSnackbarType('error');
                onOpenSnackbar();
            } else if (result == REGISTER_ERROR.CAPTCHA_INVALID) {
                setMessageSnackbar(t('signupconfirm.error.code_not_valid'));
                setSnackbarType('error');
                onOpenSnackbar();
            } else if (result == REGISTER_ERROR.NEED_INFO) {
                props.history.push('/update-profile');
            } else {
                setMessageSnackbar(t('signupconfirm.error.register_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        if (user.email && user.email.length > 0)
            return (
                <section className="wrapper-login wrapper-input-code">
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        onClose={onCloseSnackbar}
                        open={openSnackbar}
                        autoHideDuration={1500}>
                        <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                            {messageSnackbar}
                        </Alert>
                    </Snackbar>

                    <div className="wrapper-user-card">
                        <div className="">
                            <img src="../../img/register/group11.svg" className="brand-head" alt="Logo" />
                        </div>

                        <div className="wrapper-content">
                            <div className="text-head-auth">
                                <p className="description">{t('signupconfirm.confirm_email')}</p>
                                <p className="description">
                                    <strong>“{user.email}”</strong>
                                </p>
                                <p
                                    className="description"
                                    dangerouslySetInnerHTML={{
                                        __html: t('signupconfirm.resend_msg')
                                    }}></p>
                            </div>

                            <div className="form-group-code" name="formCode" id="formCode">
                                <div className="row wrapper-input-custom">
                                    <div className="col-md-7">
                                        <div className="form-label-group form-control">
                                            <input
                                                type="text"
                                                id="inputCaptcha"
                                                className="form-control"
                                                placeholder={t('signupconfirm.code')}
                                                value={captchaVal}
                                                onChange={(text) => handleChangeCaptcha(stripedHtml(text.target.value))}
                                                required
                                                autoFocus
                                            />
                                            <label htmlFor="inputCaptcha" className="label-input-custom">
                                                {t('signupconfirm.code')}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-label-group form-control">
                                            <div className="form-control input-refresh">
                                                {captcha != null ? (
                                                    <img
                                                        alt="QR Code"
                                                        className="capcha"
                                                        src={`${captcha.captchaImage}`}
                                                    />
                                                ) : null}
                                                <img
                                                    src="../../img/register/refresh.svg"
                                                    className="close-text refresh"
                                                    onClick={() => getCaptcha()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        onClick={() => onRegister()}
                                        disabled={!captchaVal || captchaVal.length == 0}
                                        className="btn btn-primary btn-confirm">
                                        {' '}
                                        {t('signupconfirm.ok')}
                                    </button>

                                    <a className="link" href="#" onClick={() => onChangeEmail()}>
                                        {t('signupconfirm.change_email')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        else return <Redirect to="/register" />;
    })
);

export default SignUpConfirm;
