import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../../utils';

const UserGroupRank = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { data, rank } = props;

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';
        const cssRankNum = rank < 4 ? 'level num' + rank : 'level';

        return (
            <li>
                <div className="level">{data.rank}</div>
                <div className="glogo">
                    <p className="glogo-inside">
                        <img src={getImageResource(data.ava, true)} alt="" />
                    </p>
                </div>
                <div className="name-box">
                    <p className="g-name">{data.name}</p>
                    <p className="owner-name">{data.ownm}</p>
                </div>
                <div className="members">
                    <p className="m-inside">
                        <span className="num">{formatNumber(data.mem)}</span> VĐV
                    </p>
                </div>
                <div className="sum">{formatDistanceKM(data.dis)}</div>
            </li>
        );
    })
);

export default UserGroupRank;
