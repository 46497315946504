import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PostItem from './PostItem';
import EditPostDialog from '../EditPostDialog';
import DeletePostDialog from './DeletePostDialog';
import { useTranslation } from 'react-i18next';
import { DialogSocial } from '../../../YourInfo/components';
import PostEmpty from './PostEmpty';

import { JOIN_TEAM_STT, GROUP_JOIN_STATUS, SOCIAL_TYPE, SOCIAL_TAB, LIKE_TYPE } from '../../../../../helpers/ConstDefined';

let postItem;

const ManagePost = inject(
    'eventStore',
    'teamManageStore',
    'postStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { doSetFunc } = props.teamManageStore;
        const { currentEvent } = props.eventStore;
        const { currentPost, listPost, doGetListPost, doMarkPost } = props.postStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            getListPost();
        }, []);

        const { t } = useTranslation();

        const [openDialogDeletePost, setOpenDialogDeletePost] = React.useState(false);
        const [openDialogEditPost, setOpenDialogEditPost] = React.useState(false);
        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE); //default tab like

        const getListPost = () => {
            const params = {
                from: 0,
                feed: 2,
                uid: userProfile && userProfile.uid,
                sort: 7, //get bài post ghim lên đầu
                order: 1,
                value: teamID,
                evid: eventID
            };
            doGetListPost(params);
        };

        const onOpenDialogDeletePost = (data) => {
            postItem = data;
            setOpenDialogDeletePost(true);
        };

        const onOpenDialogEditPost = (data) => {
            setOpenDialogEditPost(true);
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        async function onMarkPost(data) {
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid,
                action: data.action
            };
            let result = await doMarkPost(params);
            if (result == true) {
                getListPost(); // cập nhật lại list post
                //window.scrollTo(0,0);
            }
        }

        function onEditPost(post) {
            postItem = post;
        }

        function onViewDialogSocial(post, type) {
            postItem = post;
            onOpenDialogSocial(type);
        }

        function onReloadData() {
            getListPost();
        }

        const showMenu =
            userProfile &&
            currentEvent &&
            currentEvent.tmid == teamID &&
            userProfile.role &&
            userProfile.role.id != JOIN_TEAM_STT.MEMBER;

        return (
            <div className="posts-page">
                <div className="posts-wrap">
                    <a href="javascript:void(0);" onClick={() => doSetFunc(0)} className="back-btn">
                        {t('global.back')}
                    </a>
                    {listPost && listPost.length == 0 ? (
                        <PostEmpty isAdmin={showMenu} eventID={eventID} teamID={teamID} />
                    ) : null}
                    <ul className="posts-list">
                        {listPost &&
                            listPost.map((item) => {
                                return (
                                    <PostItem
                                        showMenu={showMenu}
                                        data={item}
                                        eventID={eventID}
                                        key={item.psid}
                                        onMarkPost={onMarkPost}
                                        onEditPost={onEditPost}
                                        onViewDialogSocial={onViewDialogSocial}
                                        onOpenDialogDeletePost={onOpenDialogDeletePost}
                                        onOpenDialogEditPost={onOpenDialogEditPost}
                                    />
                                );
                            })}
                    </ul>
                </div>
                {currentPost && openDialogEditPost ? (
                    <EditPostDialog
                        data={currentPost}
                        onReload={onReloadData}
                        openDialogEditPost={openDialogEditPost}
                        onClose={() => setOpenDialogEditPost(false)}
                    />
                ) : null}
                {postItem ? (
                    <DeletePostDialog
                        data={postItem}
                        openDialogDeletePost={openDialogDeletePost}
                        onClose={() => setOpenDialogDeletePost(false)}
                    />
                ) : null}
                {openDialogSocial && postItem ? (
                    <DialogSocial
                        data={postItem}
                        type={SOCIAL_TYPE.POST}
                        tab={tabSocial}
                        openDialogSocial={openDialogSocial}
                        onClose={() => setOpenDialogSocial(false)}></DialogSocial>
                ) : null}
            </div>
        );
    })
);

export default ManagePost;
