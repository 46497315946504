import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { humanTime } from '../../../../helpers/ConvertHelper';
import { getImageResource, replaceURLs } from '../../../../utils';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import { USER_ROLE } from '../../../../data/master';
import { SOCIAL_TYPE, SOCIAL_TAB, LIKE_TYPE } from '../../../../helpers/ConstDefined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API } from '../../../../helpers/api/APIDefined';
import Image from 'react-graceful-image';
import { MemberName, MemberCode } from 'components/MemberName';

const PostItem = inject(
    'globalStore',
    'postStore',
    'userStore',
    'teamStore'
)(
    observer((props) => {
        const { userProfile } = props.userStore;
        const { doLike, doGetDetailPostInfo } = props.postStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { data, eventData, showMenu, navigation } = props;

        useEffect(() => {});
        const { t } = useTranslation();

        async function getDetailPost() {
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid
            };
            let result = await doGetDetailPostInfo(params);
            if (result) {
                props.onOpenDialogEditPost();
            }
        }

        function RoleUser(role) {
            const obj = _.find(USER_ROLE, (item) => {
                return item.id == role;
            });
            return t(obj.title);
        }

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        function showDialogDelete() {
            props.onOpenDialogDeletePost(data);
        }

        function onMarkPost(val) {
            const params = {
                psid: data.psid,
                action: val
            };
            props.onMarkPost(params);
        }

        function LikeStatus() {
            let status;
            if (data.lkcnt == 0) return null;

            if (data.lktp != 0) {
                if (data.lkcnt == 1) return t('global.only_you_like_stt');
                else {
                    status = t('global.you_other_like_stt').replace('[like]', data.lkcnt - 1);
                    return status;
                }
            } else return `${data.lkcnt} ${t('global.other_like_stt')}`;
        }

        const onViewLike = () => {
            props.onViewDialogSocial(data, SOCIAL_TAB.LIKE);
        };

        const onViewComment = () => {
            props.onViewDialogSocial(data, SOCIAL_TAB.COMMENT);
        };

        const onLikePost = async (data) => {
            if (!userProfile) {
                navigation.push(`/login`);
                return;
            }

            const lktp = data.lktp == LIKE_TYPE.NONE ? LIKE_TYPE.LIKE : LIKE_TYPE.NONE;
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid,
                lktp
            };
            let result = await doLike(params, SOCIAL_TYPE.POST);
            if (result && result != false) {
                data.lkls = result.lkls;
                data.lktp = lktp;
                data.lkcnt = result.lkcnt;
            }
        };

        return (
            <div class="list-group-item">
                <div class="post-box">
                    {showMenu ? (
                        <div class="uiselector setting-more">
                            <p
                                class="setting-more-btn"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"></p>
                            <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                {data.pin == 1 ? (
                                    <li onClick={() => onMarkPost(1)}>{t('team.post.menu.unpin')}</li>
                                ) : (
                                    <li onClick={() => onMarkPost(0)}>{t('team.post.menu.pin')}</li>
                                )}
                                {data.uid == userProfile.uid ? (
                                    <li onClick={() => getDetailPost()}>{t('team.post.menu.edit')}</li>
                                ) : null}
                                <li onClick={() => showDialogDelete()}>{t('global.delete')}</li>
                            </ul>
                        </div>
                    ) : null}
                    <div class="account">
                        <div class="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" class="avatar-item" />
                        </div>
                        <div class="account-info">
                            <div class="name">{MemberName(data)}</div>
                            <div class="desc">
                                <div class="desc__tag">{RoleUser(data.tmrole)}</div>
                                <div class="desc__date">{humanTime(data.crtm, language)}</div>
                                <div class="dot"></div>
                                {data.pin == 1 ? <button class="desc__stick"></button> : null}
                            </div>
                        </div>
                    </div>
                    <h5 class="title">{data.title}</h5>
                    <div class="details">
                        {data.desc ? (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: replaceURLs(data.desc).replace(/\n/g, '<br />')
                                }}></p>
                        ) : null}
                        {data.photos && data.photos.length > 0 ? (
                            <div className="slide-block">
                                <ul className="slide">
                                    <Carousel autoPlay showArrows={false} showThumbs={false} infiniteLoop={true}>
                                        {data.photos.map((item, index) => {
                                            if (item != '')
                                                return (
                                                    <li className="img-slide" key={index} style={{ display: 'block' }}>
                                                        <Image
                                                            retry={{ count: 10, delay: 2 }}
                                                            noLazyLoad={true}
                                                            src={getImageResource(item, false, false, true)}
                                                            alt=""
                                                        />
                                                    </li>
                                                );
                                            else return null;
                                        })}
                                    </Carousel>
                                </ul>
                            </div>
                        ) : null}
                    </div>

                    <div class="account-group">
                        <div class="avatar-group">
                            {data.lkls.map((item) => {
                                return (
                                    <div class="avatar" key={item.uid}>
                                        <img src={getImageResource(item.ava, true, true)} alt="" />
                                    </div>
                                );
                            })}
                        </div>
                        <div class="txt" onClick={() => onViewLike()}>
                            {' '}
                            {data.lkcnt ? <LikeStatus /> : null}{' '}
                        </div>
                    </div>
                    <div class="action-row">
                        <ul>
                            <li>
                                <button
                                    className={data.lktp != 0 ? 'like active' : 'like'}
                                    onClick={() => onLikePost(data)}></button>
                            </li>

                            <li>
                                <button className="mess" onClick={() => onViewComment()}>
                                    <span className="num">{data.cmcnt}</span>
                                </button>
                            </li>
                            <li>
                                <CopyToClipboard
                                    text={API.GLOBAL.SITE_DOMAIN + `post-detail?evid=${eventData.evid}&psid=${data.psid}`}
                                    onCopy={() => onCopySuccess()}>
                                    <button class="share"></button>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    })
);

export default PostItem;
