import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, getImageResource } from '../../../../utils';
import { GROUP_ACTION} from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const GroupItem = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID } = props;
        const { doJoinGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        async function actionGroup(action) {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                grid: data.grid,
                action
            };
            let result = await doJoinGroup(params);
            if (result) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <li>
                <div className="item">
                    <div className="user">
                        <p className="avatar">
                            <img src={getImageResource(data.ava, true)} alt="" />
                        </p>
                        <div className="details">
                            <p className="name">{data.name}</p>
                            <p className="owner-name">{data.ownm}</p>
                            <div className="btns-group">
                                <button className="btn-32 blue-light" onClick={() => actionGroup(GROUP_ACTION.ADMIN_REJECT_JOIN)}>{t('global.reject')}</button>
                                <button className="btn-32 blue" onClick={() => actionGroup(GROUP_ACTION.ADMIN_APPROVE_JOIN)}>{t('global.approve')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    })
);

export default GroupItem;
