import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { CHALLENGE_STT } from '../../../../../helpers/ConstDefined';
import { Link } from 'react-router-dom';
import { formatDistanceKM, getImageResource } from '../../../../../utils/index';

const ChallengeResult = inject('challengeStore')(
    observer((props) => {
        const { eventID } = props;
        const { challengeDetail } = props.challengeStore;
        const [title, setTitle] = React.useState('');

        useEffect(() => {
            if (challengeDetail.sts == CHALLENGE_STT.WIN) setTitle('Chúc mừng bạn đã hoàn thành thử thách');
            else if (challengeDetail.sts == CHALLENGE_STT.LOSE) setTitle('Chia buồn với bạn chưa hoàn thành thử thách');
        }, []);

        const cover =
            challengeDetail.sts == CHALLENGE_STT.LOSE
                ? '../../../img/banner-dnf.gif'
                : '../../../img/banner-finish.gif';
        const avatar =
            challengeDetail != null && challengeDetail.ava != null && challengeDetail.ava.length > 0
                ? challengeDetail.ava.replace('.png', '@1x.png').replace('.jpeg','@1x.jpeg')
                : '../../../img/ic_avatar.png';

        return (
            <>
                <div className="wrapper-relative-banner">
                    <div className="wrapper-banner-finish" style={{ backgroundImage: `url(${cover})` }}>
                        <img src="../../../img/banner-finish.gif" alt="" />
                    </div>
                </div>
                <div className="wrapper-detail-activate wrapper-detail-activate-custom">
                    <div className="title-congratulations-user">
                        <p className="text-congratulations">{title}</p>
                    </div>
                    <div className="main-wrapper-challenge main-wrapper-challenge-custom">
                        <div className="content">
                            <p className="description-challenge">{challengeDetail.desc}</p>
                            <div className="linear-challenge">
                                <div className="row justify-content-center">
                                    <div className="col-4 wrapper-linear linear-total-distance">
                                        <p className="title">Tổng quãng đường</p>
                                        <p className="value-distance">
                                            {formatDistanceKM(challengeDetail.cdis)} <span className="unit">km</span>
                                        </p>
                                        <p className="title">
                                            Đã chạy:{' '}
                                            <span className="value-result">
                                                {formatDistanceKM(challengeDetail.dis)}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-4 wrapper-linear linear-total-day">
                                        <p className="title">Tổng số ngày</p>
                                        <p className="value-distance">
                                            {challengeDetail.cdays} <span className="unit"></span>
                                        </p>
                                        <p className="title">
                                            Ngày: <span className="value-result total-day">{challengeDetail.days}</span>
                                        </p>
                                    </div>
                                    <div className="col-4 wrapper-linear linear-max-distance">
                                        <p className="title">Quãng đg dài nhất</p>
                                        <p className="value-distance">
                                            {formatDistanceKM(challengeDetail.cxdis)} <span className="unit">km</span>
                                        </p>
                                        <p className="title">
                                            Dài nhất:{' '}
                                            <span className="value-result total-distance">
                                                {formatDistanceKM(challengeDetail.xdis)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="member-reaction">
                            <div className="row ">
                                <div className="col-md-7 ">
                                    <div className="wrapper-like" onClick={props.onViewSocialLike}>
                                        <ul className="avatars">
                                            {challengeDetail.lkls.map((item) => {
                                                return (
                                                    <li key={item.uid} className="avatars-item">
                                                        <img src={getImageResource(item.ava, true,true)} className="avatars-img" />
                                                    </li>
                                                );
                                            })}
                                            {challengeDetail.lkcnt > 4 ? (
                                                <li className="avatars-item">
                                                    <span className="icon-more">...</span>
                                                </li>
                                            ) : null}
                                        </ul>

                                        <p className="number-like">
                                            <a>{challengeDetail.lkcnt} người thích</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-5 ">
                                    <div
                                        className="wrapper-comments"
                                        onClick={props.onViewSocialComment}>
                                        <p>
                                            <a>
                                                {challengeDetail.cmcnt} bình luận{' '}
                                                {challengeDetail.cmcnt > 0 ? (
                                                    <i className="fa fa-chevron-right next"></i>
                                                ) : null}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="action-see-detail action-see-detail-custom">
                        <Link to = {'/challenge?evid=' + eventID}>Xem chi tiết</Link>
                    </div>
                </div>
            </>
        );
    })
);

export default ChallengeResult;
