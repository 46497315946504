import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const Introduction = inject('authStore')(
    observer((props) => {
        const { data } = props;
        const { t, i18n } = useTranslation();

        useEffect(() => {}, []);

        return (
            <section className="section section--2 ">
                <div className="section__background ">
                    <img data-src="images/bg/f2.jpg" alt="" className="desktop lazyload" />
                    <img data-src="images/bg/f2-mb.jpg" alt="" className="mobile lazyload" />
                </div>

                <div className="container">
                    <h1
                        className="text-center space-50"
                        dangerouslySetInnerHTML={{ __html: t('landing_page.intro.title') }}></h1>
                    <p
                        className="text-center space-50"
                        dangerouslySetInnerHTML={{ __html: t('landing_page.intro.desc') }}></p>
                    <div className="achievements flex justify-content-space-between ">
                        <div className="achievement-item">
                        <div dangerouslySetInnerHTML={{ __html: t('landing_page.info.total_year') }}></div>
                        </div>
                        <div className="achievement-item">
                            <div dangerouslySetInnerHTML={{ __html: t('landing_page.info.total_km') }}></div>
                        </div>
                        <div className="achievement-item">
                            <div dangerouslySetInnerHTML={{ __html: t('landing_page.info.total_money') }}></div>
                        </div>
                        <div className="achievement-item">
                            <div dangerouslySetInnerHTML={{ __html: t('landing_page.info.total_people') }}></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    })
);

export default Introduction;
