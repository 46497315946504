import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from '../../../../../utils';
import { GROUP_ACTION } from '../../../../../helpers/ConstDefined';

const CancelJoinGroupDialog = inject(
    'groupStore',
    'postStore',
    'userStore',
    'globalStore',
    'eventStore'
)(
    observer((props) => {
        const { data, navigation } = props;

        useEffect(() => {}, []);

        const { openDialogCancelJoinGroup } = props;
        const { userProfile } = props.userStore;
        const { doActionGroup, doSetStatusJoinGroup } = props.groupStore;
        const { doSetGroupRequestID } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function onCancelJoinGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.grid,
                action: GROUP_ACTION.USER_CANCEL_JOIN
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doSetGroupRequestID(0);
                doSetStatusJoinGroup(0);
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogCancelJoinGroup}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={props.onClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <div className="logo-row">
                                        <p className="glogo">
                                            <img src={getImageResource(data.ava, true)} alt="" />
                                        </p>
                                    </div>
                                    <p className="name">{data.name}</p>
                                    <div className="owner-name-row">
                                        <p className="owner-name">{data.ownm}</p>
                                    </div>
                                    <div className="txt">
                                        <p>{t('team.group.canceljoindl.desc')}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={() => onCancelJoinGroup()}>
                                            {t('global.cancel_request')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CancelJoinGroupDialog;
