import React, { forwardRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import ImageGallery from 'react-image-gallery';
import Image from 'react-graceful-image';
import { useTranslation } from 'react-i18next';

const DialogViewImage = inject(
    'authStore',
    'userStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { data, openDialogViewImage } = props;

            let images = [];
            data.photos.map((item, index) => {
                let image = {
                    original: item.replace('.png', '@3x.png').replace('.jpeg', '@3x.jpeg'),
                    thumbnail: item.replace('.png', '@2x.png').replace('.jpeg', '@2x.jpeg')
                };
                images.push(image);
            });

            const { t } = useTranslation();

            const onCloseDialog = () => {
                props.onClose();
            };

            const renderItem = (item) => {
                return(
                    <>
                        <img src={item.thumbnail} style={{width: 400, height: 520}} />
                    </>
                )
            }

            const renderThumbInner = (item) => {
                return(
                    <>
                        <Image src={item.original} style={{width: 90, height: 100}} />
                    </>
                )
            }

            return (
                <Dialog open={openDialogViewImage}>
                    <div
                        class="uprace-popup has-title in"
                        id="certificate"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div class="popup-dialog" role="document">
                            <div class="popup-content w600">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 class="popup-title"></h2>
                                <div class="popup-body">
                                    <div class="popup-certificate" style={{paddingBottom: 20}}>
                                        <ImageGallery renderItem={renderItem} renderThumbInner={renderThumbInner} showBullets={true} autoPlay={true} items={images} />
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Dialog>
            );
        })
    )
);

export default DialogViewImage;
