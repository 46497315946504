import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { ACTIVITY_TYPE } from '../../../../../helpers/ConstDefined';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';

const DialogLockComplain = inject('eventStore')(
    observer((props) => {
        const { openDialogLockComplain, type } = props;
        const { currentEvent } = props.eventStore;

        const [title, setTitle] = React.useState('');
        const [desc, setDesc] = React.useState('');

        const { t } = useTranslation();

        useEffect(() => {
            initDialog();
        }, [openDialogLockComplain, type]);

        // Renderer callback with condition
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                props.onClose();
                return null;
            } else {
                // Render a countdown
                return (
                    <span>
                        {hours}:{minutes}:{seconds}
                    </span>
                );
            }
        };

        const initDialog = () => {
            if (type == ACTIVITY_TYPE.COMPLAIN) {
                setTitle(t('global.complain_act'));
                setDesc(t('global.complain_act_lock'));
            } else {
                setTitle(t('global.report_act'));
                setDesc(t('global.report_act_lock'));
            }
        };

        const timer = type == ACTIVITY_TYPE.COMPLAIN ? currentEvent.cptm : currentEvent.rptm;

        return (
            <Dialog open={openDialogLockComplain}>
                <div
                    class="uprace-popup has-title in"
                    id="complain"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button type="button" class="popup-close" onClick={() => props.onClose()}></button>
                            <h2 class="popup-title">{title}</h2>
                            <div class="popup-body">
                                <div class="popup-complain">
                                    <div class="complain-scroll">
                                        <div class="complain-notify">
                                            <p class="img">
                                                <img src="../../../images/imgs/locked.png" alt="" />
                                            </p>
                                            <div class="note">
                                                <p>{desc}</p>
                                                <p>
                                                    <span class="grey">{t('global.lock_invalid_dur')}</span>&nbsp;
                                                    <Countdown date={timer} renderer={renderer} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="complain-btn col-1">
                                        <button class="btn-44 blue" onClick={() => props.onClose()}>
                                            {t('global.close')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogLockComplain;
