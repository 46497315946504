import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { formatDistanceKM, formatDate, parseDate } from '../../../../../utils';
import { formatDateString } from '../../../../../helpers/ConvertHelper';
import { toJS } from 'mobx';

const listColor = ['#ef5da8', '#ffab00', '#36cfbb', '#f15b28', '#727f8e'];

const ChartData = inject(
    'globalStore',
    'chartStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { t, i18n } = useTranslation();
        const {
            activeMember,
            listUserCompare,
            doSetStartDate,
            doSetStartDateText,
            doGetDisByDayUserCompare
        } = props.chartStore;
        const [chartData, setChartData] = React.useState([]);
        const [labelData, setLabelData] = React.useState([]);
        const { language } = props.globalStore;

        useEffect(() => {
            initChartData();
        }, [activeMember]);

        function initChartData() {
            let listData = [];
            const now = formatDate('YYYYMMDD', new Date());
            let index = 0;
            _.forEach(listUserCompare, (data) => {
                let listDur = [];
                _.forEach(data.chartData, (item) => {
                    if (item.day <= now) listDur.push(Math.round(item.rdis / 1000));
                });

                let line = {
                    id: index,
                    label: data.name,
                    backgroundColor: '#fff',
                    borderColor: listColor[index],
                    borderWidth: index == activeMember - 1 ? 4 : 2,
                    data: listDur,
                    pointBorderColor: listColor[index],
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 2,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: listColor[index],
                    pointHoverBorderColor: '#FFE0B2',
                    pointHoverBorderWidth: 8,
                    pointRadius: 0,
                    pointHitRadius: 10
                };
                data.color = listColor[index];

                if (index == activeMember - 1) listData.unshift(line);
                else listData.push(line);
                index += 1;
            });
            console.log('listData', toJS(listData));
            setChartData(listData);
        }

        function onSelectDay(index) {
            const day = listUserCompare[0].chartData[index].day;
            const date = parseDate(day);

            const dayText = formatDate('DD/MM', date);

            doSetStartDate(date);
            doSetStartDateText(dayText);
            doGetDisByDayUserCompare(day);
        }

        function renderTooltip(day) {
            let listStr = [];
            _.forEach(listUserCompare, (data) => {
                let obj = data.chartData[day];
                const str = obj ? data.name + ' : ' + formatDistanceKM(obj.rdis) + ' km' : '';
                listStr.push(str);
            });
            return listStr;
        }

        function renderLable() {
            let list = [];
            let index = 1;
            for (let i = 1; i <= listUserCompare[0].dyls.length; i++) {
                if (i % 7 == 0) {
                    list.push(t('global.week') + ' ' + index);
                    index++;
                } else list.push('');
            }
            return list;
        }

        const plugins = [
            {
                afterDraw: ({ chart }) => {
                    // eslint-disable-next-line no-underscore-dangle
                    if (chart.tooltip._active && chart.tooltip._active.length > 0) {
                        // draw vertical line
                        const activePoint = chart.tooltip._active[0];
                        const { ctx } = chart;
                        const { x } = activePoint._view;
                        const topY = activePoint._yScale.top;
                        const bottomY = activePoint._yScale.bottom;

                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#3e64ff';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        ];

        const data = (canvas) => {
            const ctx = canvas.getContext('2d');
            const gradient1 = ctx.createLinearGradient(50, 0, 0, 200);
            gradient1.addColorStop(0, '#ffab00');
            gradient1.addColorStop(1, '#fff');

            const gradient2 = ctx.createLinearGradient(50, 0, 0, 200);
            gradient2.addColorStop(0, '#36cfbb');
            gradient2.addColorStop(1, '#fff');

            return {
                labels: renderLable(),
                datasets: chartData
            };
        };

        var options = {
            onClick: (e, element) => {
                if (element.length > 0) {
                    var ind = element[0]._index;
                    onSelectDay(ind);
                }
            },
            annotation: {
                annotations: [
                    {
                        drawTime: 'afterDatasetsDraw',
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x-axis-0',
                        value: 3.8,
                        borderWidth: 5,
                        borderColor: 'red',
                        label: {
                            content: 'TODAY',
                            enabled: true,
                            position: 'top'
                        }
                    }
                ]
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            //stepSize: maxVal/5,
                            min: 0
                            //max: 40
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Km'
                        },
                        position: 'bottom'
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        let index = tooltipItem[0]['index'];
                        let day = listUserCompare[0].chartData[index].day;

                        //return t('global.km_in_day') + ' ' + formatDateString(day, language);
                        return formatDateString(day, language);
                    },
                    label: function(tooltipItem, data) {},
                    afterBody: function(data) {
                        return renderTooltip(data[0].index);
                    }
                },
                backgroundColor: '#E3F2FD',
                titleFontSize: 16,
                titleFontColor: '#0066ff',
                bodyFontColor: '#000',
                bodyFontSize: 14,
                displayColors: false
            },
            legend: {
                //display: false
            },
            responsive: true,
            //option for line instead of curves
            elements: {
                line: {
                    tension: 0
                }
            },
            animation: {
                easing: 'easeInOutBack'
            }
        };

        if (chartData && chartData[0])
            return (
                <div>
                    <Line data={data} options={options} plugins={plugins} height={125} />
                </div>
            );
    })
);

export default ChartData;
