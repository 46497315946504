import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatDistanceKM, getImageResource } from '../../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../../helpers/ConstDefined';

const GroupRankItem = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore',
    'eventStore',
    'teamStore'
)(
    observer((props) => {
        const { data, rank, showAction } = props;
        const { currentEvent, doSetGroupRequestID } = props.eventStore;
        const { doGetGroupDetail, doActionGroup } = props.groupStore;
        const { doSetFunc } = props.teamManageStore;
        const { userProfile } = props.userStore;
        const { currentTeam } = props.teamStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        async function getGroupDetail() {
            const params = {
                uid: userProfile && userProfile.uid,
                grid: data.id
            };
            let result = await doGetGroupDetail(params);
            if (result) {
                doSetFunc(4); //naviga group detail
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        async function onJoinGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.id,
                action: GROUP_ACTION.USER_REQUEST_JOIN
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doSetGroupRequestID(data.id);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';
        const cssRankNum = rank < 4 ? 'level num' + rank : 'level';

        return (
            <li>
                <a href="#!">
                    <div className="level" onClick={() => getGroupDetail()}>
                        {data.rank}
                    </div>
                    <div className="glogo" onClick={() => getGroupDetail()}>
                        <p className="glogo-inside">
                            <img src={getImageResource(data.ava, true)} alt="" />
                        </p>
                    </div>

                    <div className="name-box" onClick={() => getGroupDetail()}>
                        <p className="g-name">{data.name}</p>
                        <p className="owner-name">{data.ownm}</p>
                    </div>
                    <div className="members" onClick={() => getGroupDetail()}>
                        <p className="m-inside">
                            <span className="num">{data.mem}</span> VĐV
                        </p>
                    </div>
                    <div className="sum">{formatDistanceKM(data.dis, true)}</div>
                    {showAction ? (
                        <div className="action">
                            <button className="btn-40 blue" onClick={() => onJoinGroup()}>
                                {t('global.join')}
                            </button>
                        </div>
                    ) : null}
                </a>
            </li>
        );
    })
);

export default GroupRankItem;
