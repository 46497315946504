import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';

const DialogDeleteLeaderboard = inject(
    'authStore',
    'userStore',
    'globalStore',
    'userLeaderboardStore'
)(
    observer((props) => {
        const { data, openDialogDelete } = props;
        const { userProfile, doRemoveUserLeaderboard } = props.userStore;
        const { loading, doDeleteUserLeaderboard } = props.userLeaderboardStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        async function deleteLeaderboard() {
            const params = {
                uid: userProfile.uid,
                rkid: data.rkid
            };
            let result = await doDeleteUserLeaderboard(params);
            if (result == true) {
                setMessageSnackbar('Xóa bảng xếp hạng thành công');
                setSnackbarType('success');
                onOpenSnackbar();
                props.onClose();
                doRemoveUserLeaderboard(data.rkid);
            } else {
                setMessageSnackbar('Xóa bảng xếp hạng thất bại');
                setSnackbarType('error');
                onOpenSnackbar();
                props.onClose();
            }
        }

        return (
            <Dialog
                open={openDialogDelete}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="custom-modal  modal-confirm-join-team" role="dialog" aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Xoá bảng xếp hạng cá nhân</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src="../../../img/icon-close-2.png" alt="image" onClick={props.onClose} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Bảng xếp hạng này sẽ bị xóa vĩnh viễn và bạn sẽ không thể tìm thấy nó nữa. Bạn có
                                    chắc chắn muốn xóa bảng xếp hạng này?
                                </p>
                            </div>

                            <div className="modal-footer">
                                <div className="wrapper-btn custom-wrapper-btn">
                                    <a type="button" className="btn btn-outline-primary" onClick={props.onClose}>
                                        Bỏ qua
                                    </a>
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="btn btn-primary"
                                        onClick={() => deleteLeaderboard()}>
                                        Xoá
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogDeleteLeaderboard;
