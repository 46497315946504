import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const Banner = inject(
    'authStore',
    'leaderboardStore'
)(
    observer((props) => {
        const { eventID } = props;

        useEffect(() => {}, []);

        if(eventID == 2)
            return (
                <div className="view-rank" style={{backgroundImage: `url('../../../img/banner02.png')`}}>
                    <img src="../../../img/banner02.png" alt="view rank" />
                    <div className="rank-img">
                        
                    </div>
                </div>
            );
        else 
            return (
                <div className="view-rank" style={{backgroundImage: `url('../../../img/banner_bxh_seabank.png')`}}>
                    <img src="../../../img/banner_bxh_seabank.png" alt="view rank" />
                    <div className="rank-img">
                        
                    </div>
                </div>
            );
    })
);

export default Banner;
