import React from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimeFromSecond, getImageResource } from '../../../../../utils';
import { humanTime, sportTypeImage, srcTypeImage } from '../../../../../helpers/ConvertHelper';
import AvatarItem from '../../../../Common/AvatarItem';
import { buildAvgPace, buildDistance } from '../../../../../utils/paceCalc';
import { SPORT_TYPE_ACTIVITY } from '../../../../../helpers/ConstDefined';
import { toJS } from 'mobx';

const ActivityItem = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;

        const imgGender = null; //data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';
        const imgMap = data.img.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg');

        function PhotoItem(props) {
            let { photo, index } = props;
            photo = photo.replace('.png', '@2x.png').replace('.jpeg', '@2x.jpeg');

            if (index < 6)
                return (
                    <div className="image" style={{ backgroundImage: `url(${photo})` }}>
                        <img src={photo} alt="image" />
                    </div>
                );
            else if (index == 6)
                return (
                    <div className="image" style={{ backgroundImage: `url(${photo})` }}>
                        <img src={photo} alt="image" />
                        <div className="more-img">
                            <p>+{data.photos.length - 7}</p>
                        </div>
                    </div>
                );
            else return null;
        }

        function addDefaultSrc(e){
            e.target.src = '../../../img/feed_default.jpg';
        }

        return (
            <div className="activity">
                <div className="head-story">
                    <div className="user-group-information">
                        <div
                            className="wrapper-avatar-member"
                            style={{ backgroundImage: `url(${getImageResource(data.ava, true, true)})` }}>
                            <img className="avatar" src='../../../img/avatar.png' alt="image" />
                            <img className="gender-user" src='../../../img/male.png' alt="image" /> 
                        </div>
                        <div className="info activity-information-user">
                            <p className="name">{data.name}</p>
                            <span className="time-detail">
                                {sportTypeImage(data.sport) ? (
                                    <img className="guys" src={sportTypeImage(data.sport)} alt="image" />
                                ) : null}
                                {humanTime(data.betm)}
                                <img className="img-icon-bitmap" src={srcTypeImage(data.src)} alt="image" />
                            </span>
                        </div>
                    </div>
                    <div className="div-share"></div>
                </div>

                <div className="description-activity" style={{cursor: "pointer"}} onClick={props.onViewFeedDetail}>
                    <p className="title-description">{data.title}</p>
                    <p className="content-description">{data.desc}</p>
                </div>

                <div className="list-images" onClick={props.onViewImage}>
                    {data.photos &&
                        data.photos.length > 0 &&
                        data.photos.map((item, index) => {
                            return <PhotoItem key={item} photo={toJS(item)} index={index} />;
                        })}
                </div>

                <div className="detail-story detail-activity-custom" style={{cursor: "pointer"}} onClick={props.onViewFeedDetail}>
                    <div className="inform-activity">
                        <div className="row">
                            <div className="col-4">
                                <p className="title-mini">Quãng đường</p>
                                <p className="value">{buildDistance(data.sport, data.dis)}</p>
                            </div>
                            <div className="col-4">
                                <p className="title-mini">Tốc độ</p>
                                <p className="value">{buildAvgPace(data.sport, data.mvtm, data.dis)}</p>
                            </div>
                            <div className="col-4">
                                <p className="title-mini">Thời gian</p>
                                <p className="value">{convertTimeFromSecond(data.mvtm)}</p>
                            </div>
                        </div>
                    </div>

                    {data.sport != SPORT_TYPE_ACTIVITY.SWIMMING ? (
                        <div className="img-detail">
                            <div id="demo" className="carousel slide image-story" data-ride="carousel">
                                <img className="map-slide-img" src={imgMap} onError={addDefaultSrc} />
                            </div>
                        </div>
                    ) : null}
                    <div className="member-reaction">
                        <div className="row ">
                            <div className="col-6 ">
                                <div className="wrapper-like" onClick={props.onViewSocialLike}>
                                    <ul className="avatars">
                                        {data.lkls.map((item) => {
                                            return (
                                                <li key={item.uid} className="avatars-item">
                                                    <AvatarItem data={item.ava} css="avatars-img" />
                                                </li>
                                            );
                                        })}
                                        {data.lkcnt > 4 ? (
                                            <li className="avatars-item">
                                                <span className="icon-more">...</span>
                                            </li>
                                        ) : null}
                                    </ul>

                                    <p className="number-like">
                                        <a>{data.lkcnt} người thích</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="wrapper-comments" onClick={props.onViewSocialComment}>
                                    <p>
                                        <a>
                                            {data.cmcnt} bình luận <i className="fa fa-chevron-right next"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default ActivityItem;
