import React, { useState, useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import { stripedHtml, stripedHtmlNumber, validEmail, formatDate } from '../../utils/index';
import { GLOBAL_CONFIG, LOGIN_TYPE } from '../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const SignUp = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { user } = props.authStore;
        const [name, setName] = useState(user.name ? user.name : '');
        const [userEmail, setUserEmail] = useState(user.emailText ? user.emailText : '');
        const [userPass, setUserPass] = useState(user.password ? user.password : '');

        const { t } = useTranslation();

        //error
        const [errEmail, setErrEmail] = useState(false);
        const [errName, setErrName] = useState(false);
        const [errUserPass, setErrUserPass] = useState(false);
        const [agree, setAgree] = useState(true);

        //show clear
        const showClearEmail = userEmail && userEmail.length > 0;
        const showClearPass = userPass && userPass.length > 0;
        const showClearName = name && name.length > 0;

        const readOnlyEmail = false;
        const isShowPass = user.type != LOGIN_TYPE.GOOGLE && user.type != LOGIN_TYPE.FACEBOOK;

        const handleAgreePolicy = () => {
            setAgree(!agree);
        };

        function validInput() {
            let hasError = false;
            if (!readOnlyEmail) {
                if (!validEmail(userEmail)) {
                    setErrEmail(true);
                    hasError = true;
                } else setErrEmail(false);
            }

            if (!name || name.length == 0 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (isShowPass) {
                if (!userPass || userPass.length < 6) {
                    setErrUserPass(true);
                    hasError = true;
                } else setErrUserPass(false);
            }
            return hasError;
        }

        const onDoRegister = () => {
            if (!validInput()) {
                props.history.push('/register-confirm');
                console.log(toJS(user));
            }
        };

        const handleClearEmail = () => {
            setUserEmail('');
        };
        const handleClearPass = () => {
            setUserPass('');
        };
        const handleClearName = () => {
            setName('');
        };

        const handleChangeEmail = (email) => {
            user.email = email;
            user.emailText = email;
            setUserEmail(email);
        };
        const handleChangePass = (pass) => {
            user.password = pass;
            setUserPass(pass);
        };
        const handleChangeName = (val) => {
            user.name = val;
            setName(val);
        };

        useEffect(() => {}, []);

        return (
            <section class="wrapper-login wrapper-register">
                <div class="wrapper-user-card">
                    <div class="wrapper-head">
                        <h3 class="title">{t('global.create_account')}</h3>
                        <div class="wrapper-image-logo">
                            <img src="../../images/register/logo-login.svg" class="brand_logo" alt="Logo" />
                        </div>
                    </div>
                    <div class="wrapper-content">
                        <div class="form-group-login" name="form-forgot" id="form-forgot"></div>
                        <div class=" wrapper-input-custom">
                            <div class="form-label-group form-control">
                                <input
                                    id="inputEmail"
                                    className="form-control"
                                    placeholder="Email"
                                    disabled={readOnlyEmail}
                                    value={userEmail}
                                    onChange={(text) => handleChangeEmail(stripedHtml(text.target.value))}
                                    autoFocus
                                />
                                <label htmlFor="inputEmail" className="label-input-custom">
                                    Email
                                </label>
                                {showClearEmail && !readOnlyEmail ? (
                                    <img
                                        id="closeEmail"
                                        onClick={() => handleClearEmail()}
                                        src="../../img/register/close.svg"
                                        className="close-text"
                                    />
                                ) : null}
                                {errEmail ? (
                                    <div className="wrapper-error">
                                        <p className="error-message">{t('global.error.valid_email')}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div class="form-label-group form-control">
                                <input
                                    type="text"
                                    id="userName"
                                    maxLength={60}
                                    className="form-control"
                                    placeholder={t('global.name')}
                                    value={name}
                                    onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                />
                                <label htmlFor="userName" className="label-input-custom">
                                    {t('global.name')}
                                </label>
                                {showClearName ? (
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src="../../img/register/close.svg"
                                        onClick={() => handleClearName()}
                                        className="close-text"
                                    />
                                ) : null}
                                {errName ? (
                                    <div className="wrapper-error">
                                        <p className="error-message">{t('global.error.valid_name')}</p>
                                    </div>
                                ) : null}
                            </div>
                            {isShowPass ? (
                                <div className="form-label-group form-control">
                                    <input
                                        type="password"
                                        id="password"
                                        maxLength={20}
                                        className="form-control"
                                        placeholder={t('login.password')}
                                        value={userPass}
                                        onChange={(text) => handleChangePass(stripedHtml(text.target.value))}
                                    />
                                    <label htmlFor="password" className="label-input-custom">
                                        {t('login.password')}
                                    </label>
                                    {showClearPass ? (
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src="../../img/register/close.svg"
                                            onClick={() => handleClearPass()}
                                            className="close-text"
                                        />
                                    ) : null}
                                    {errUserPass ? (
                                        <div className="wrapper-error">
                                            <p className="error-message">{t('global.error.valid_pass')}</p>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>

                        <div class="form-group">
                            <div class="custom-checkbox">
                                <label class="container-checkbox">
                                    {t('signup.agree')}
                                    <a class="txt-blueNewst" href={`/terms?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`} target="_blank">
                                        {t('signup.terms')}
                                    </a>
                                    &nbsp;{t('signup.service')}
                                    <a class="txt-blueNewst" href={`/policy?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`} target="_blank">
                                        {t('signup.policy')}
                                    </a>
                                    <input type="checkbox" id="inputRemember" onClick={() => handleAgreePolicy()} />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <button
                                type="button"
                                disabled={agree}
                                className="btn btn-primary btn-confirm"
                                onClick={() => onDoRegister()}>
                                {t('global.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    })
);

export default SignUp;
