import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { SOCIAL_TAB, CHALLENGE_STT } from '../../helpers/ConstDefined';
import { ListChallenge, NotChallenge, YourChallenge, ChallengeResult, DialogSocial } from './components';
import { getUUID, getTimestamp, isJWTValid } from '../../utils';
import { GLOBAL_CONFIG } from '../../helpers/ConstDefined';
import { toJS } from 'mobx';
const queryString = require('query-string');

let challenge;

const UserChallenge = inject(
    'authStore',
    'userStore',
    'teamStore',
    'globalStore',
    'challengeStore'
)(
    observer((props) => {
        const { userTeam } = props.teamStore;
        const { appVersion, doSetSocialTab, doSetMainTab } = props.globalStore;
        const { userProfile } = props.userStore;
        const {
            challengeDetail,
            doGetListComment,
            doGetListUserLike,
            doGetListUserDisLike,
            doSetCurrentChallenge,
            doGetChallengeDetail
        } = props.challengeStore;

        let paramsQuery = queryString.parse(props.location.search);

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);

        useEffect(() => {
            doSetMainTab(2); //tab you
            window.scrollTo(0, 0);
            if(!isJWTValid()) props.history.push('/login');
            if (userProfile != null && userTeam != null) getChallengeDetail();
        }, [userProfile, userTeam]);

        const handleOpenDialogSocial = (flag) => {
            setOpenDialogSocial(flag);
        };

        function getChallengeDetail() {
            let data = {
                uid: userProfile.uid,
                tgid: userTeam.tgid
            };
            doGetChallengeDetail(data);
        }

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const onViewSocialLike = (act) => {
            challenge = act;
            doSetCurrentChallenge(act);
            doSetSocialTab(SOCIAL_TAB.LIKE);
            getListUserLike(act.tgid);
            getListUserDisLike(act.tgid, 0);
            getListComment(act.tgid);
            handleOpenDialogSocial(true);
        };

        const onViewSocialComment = (act) => {
            challenge = act;
            doSetCurrentChallenge(act);
            doSetSocialTab(SOCIAL_TAB.COMMENT);
            getListUserLike(act.tgid);
            getListUserDisLike(act.tgid);
            getListComment(act.tgid);
            handleOpenDialogSocial(true);
        };

        function getListComment(tgid) {
            let data = {
                tgid,
                from: 0,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListComment(data);
        }

        function getListUserLike(tgid) {
            let data = {
                tgid,
                lktp: 1, //1: like
                from: 0,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListUserLike(data);
        }

        function getListUserDisLike(tgid, from) {
            let data = {
                tgid,
                lktp: 8, //8: dislike
                from,
                size: appVersion.pgsz,
                uid: userProfile.uid
            };
            doGetListUserDisLike(data, false);
        }

        function RenderChallenge() {
            if (challengeDetail == null || challengeDetail.sts == CHALLENGE_STT.DELETED) return <NotChallenge eventID={paramsQuery.evid}/>;
            else if (challengeDetail != null) {
                if (challengeDetail.sts == CHALLENGE_STT.COMMITED)
                    return (
                        <YourChallenge
                            eventID={paramsQuery.evid}
                            onViewSocialLike={() => onViewSocialLike(challengeDetail)}
                            onViewSocialComment={() => onViewSocialComment(challengeDetail)}
                        />
                    );
                else
                    return (
                        <ChallengeResult
                            onViewSocialLike={() => onViewSocialLike(challengeDetail)}
                            onViewSocialComment={() => onViewSocialComment(challengeDetail)}
                        />
                    );
            } else return null;
        }

        if (userProfile != null && userTeam != null)
            return (
                <>
                    <section className="wrapper-friends-challenges">
                        <div className="container">
                            <RenderChallenge />
                            <div className="arrow-back-to-top" onClick={() => scrollTop()}><img src="../../../img/back-to-top.svg" alt="image"/></div>
                        </div>
                    </section>
                    <ListChallenge eventID={paramsQuery.evid} onViewSocialLike={onViewSocialLike} onViewSocialComment={onViewSocialComment} navigation={props.history} />
                    <DialogSocial
                        openDialogSocial={openDialogSocial}
                        onClose={() => handleOpenDialogSocial(false)}
                        data={challenge}
                    />
                </>
            );
        else return null;
    })
);

export default UserChallenge;
