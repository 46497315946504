import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const GroupRankEmpty = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { t } = useTranslation();

        return (
            <div class="card card-details card-rank">
                <div class="card-details-body">
                    <div class="empty-box">
                        <div class="thumbnail">
                            <img src="../../../images/imgs/create_group.png" alt="" class="thumbnail-item" />
                        </div>
                        <div class="title">{t('eu.list_group_empty')}</div>
                        <div class="txt">{t('eu.list_group_empty_ph')}</div>
                    </div>
                </div>
            </div>
        );
    })
);

export default GroupRankEmpty;
