import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { convertTimestamptoDate, formatDistanceKM, getImageResource } from '../../../../../utils/index';
import { FOLLOW_STT } from '../../../../../helpers/ConstDefined';
import { KEY } from '../../../../../helpers/api/APIDefined';
import DialogChallengeResult from '../../../../UserChallenge/components/ListChallenge/DialogChallengeResult'

const DialogChallengeUser = inject(
    'authStore',
    'teamStore',
    'userStore',
    'challengeStore',
    'userProfileStore'
)(
    observer((props) => {
        const { openDialogChallengeMember } = props;
        const { userProfile } = props.userStore;
        let { memberDetail, currentMember, doFollow } = props.teamStore;
        const { challengeDetail } = props.challengeStore;
        const { doSetProfileUserID } = props.userProfileStore;

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: currentMember.id,
                action
            };
            let result = await doFollow(params);
            if (result) {
                memberDetail.fists = action == 0 ? 2 : 0;
            }
        }

        const handleViewFeedMember = () => {
            props.onViewFeedMember();
            props.onClose();
        };

        if (challengeDetail != null) {
            percentDistance = Math.round(((challengeDetail.dis) * 100) / challengeDetail.cdis);
            percentDayActive = Math.round((challengeDetail.days * 100) / challengeDetail.cdays);
            percentLongestDistance = Math.round(((challengeDetail.xdis) * 100) / challengeDetail.cxdis);
        }

        const onViewSocialLike = (challenge) => {
            props.onViewSocialLike(challenge);
        };

        const onViewSocialComment = (challenge) => {
            props.onViewSocialComment(challenge);
        };

        const handleViewProfileUser = () => {
            sessionStorage.setItem(KEY.ID_PROFILE_USER, currentMember.id);
            doSetProfileUserID(currentMember.id);
            props.onViewProfileUser();
        };

        return (
            <Modal show={openDialogChallengeMember} onHide={props.onClose}>
                <div
                    className="custom-modal-complex modal-member-event modal-join-team"
                    role="dialog"
                    aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="wrapper-banner">
                                    <div className="banner background-image-banner">
                                        <img src="../../../img/background-profile-member.png" alt="image" />
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <img src="../../../img/icon-close-2.png" alt="image" />
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="wrapper-content">
                                    <div className="intro">
                                        <div
                                            className="profile-img"
                                            style={{ backgroundImage: `url(${getImageResource(currentMember.ava,true,true)})` }}>
                                            <img src="../../../img/avatar.png" alt="image" />
                                        </div>

                                        <h3 className="title" onClick={() => handleViewProfileUser()}>
                                            {currentMember.name}
                                            <a className="action-name">
                                                <i className="fa fa-angle-right" size="20px"></i>
                                            </a>
                                        </h3>
                                        <p className="team-type">
                                            {currentMember.ctry} - {currentMember.city}
                                        </p>
                                        <span>
                                            <div
                                                className="btn btn-primary custom-primary"
                                                onClick={() => handleViewFeedMember()}>
                                                <label className="see-action">Xem hoạt động </label>
                                            </div>

                                            {memberDetail.fists == 2 ? (
                                                userProfile.uid != currentMember.id ? (
                                                    <button
                                                        onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}
                                                        type="button"
                                                        className="btn btn-outline-primary"
                                                        style={{ width: 120 }}>
                                                        Bỏ theo dõi
                                                    </button>
                                                ) : null
                                            ) : userProfile.uid != currentMember.id ? (
                                                <button
                                                    onClick={() => followUser(FOLLOW_STT.FOLLOW)}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ width: 120 }}>
                                                    Theo dõi
                                                </button>
                                            ) : null}
                                        </span>
                                    </div>
                                </div>

                                <div className="table-responsive custom-table-profile">
                                    <div className="wrapper-content-profile ">
                                        <DialogChallengeResult challengeDetail={challengeDetail} />
                                        <div className="member-reaction">
                                            <div className="row ">
                                                <div className="col-6 ">
                                                    <div
                                                        className="wrapper-like"
                                                        onClick={() => onViewSocialLike(challengeDetail)}>
                                                        <ul className="avatars">
                                                            {challengeDetail.lkls.map((item) => {
                                                                return (
                                                                    <li key={item.uid} className="avatars-item">
                                                                        <img src={getImageResource(item.ava, true,true)} className="avatars-img" />
                                                                    </li>
                                                                );
                                                            })}
                                                            {challengeDetail.lkcnt > 4 ? (
                                                                <li className="avatars-item">
                                                                    <span className="icon-more">...</span>
                                                                </li>
                                                            ) : null}
                                                        </ul>

                                                        <p className="number-like">
                                                            {challengeDetail.lkcnt} người thích
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-6 ">
                                                    <div
                                                        className="wrapper-comments"
                                                        onClick={() => onViewSocialComment(challengeDetail)}>
                                                        <p>
                                                            <a>
                                                                {challengeDetail.cmcnt} bình luận{' '}
                                                                {challengeDetail.cmcnt > 0 ? (
                                                                    <i className="fa fa-chevron-right next"></i>
                                                                ) : null}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogChallengeUser;
