import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEY } from '../../../../helpers/api/APIDefined';

const MENU_LANGUAGE = [
    {
        id: 'en',
        title: 'EN'
    },
    {
        id: 'vi',
        title: 'VI'
    }
];

const MenuHeader = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { data } = props;
        const { language, doChangeLanguage } = props.globalStore;
        const { i18n } = useTranslation();

        useEffect(() => {}, []);

        const onChangeLanguage = (data) => {
            i18n.changeLanguage(data.id);
            localStorage.setItem(KEY.USER_LANGUAGE, data.id);
            doChangeLanguage(data.id);
        };

        function MenuLanguage(props) {
            const { data } = props;
            const css = data.id == language ? 'btn-choose-language active' : 'btn-choose-language';
            return (
                <a className={css} href="#" onClick={() => onChangeLanguage(data)}>
                    {data.title}
                </a>
            );
        }

        return (
            <div className="outer outer--floating">
                <div className="inner">
                    <section id="floating" className="section section--floating floating">
                        <div className="section__content floating__content">
                            <div className="nav-top setPosTop ">
                                <div className="container-top">
                                    <a className="mobile btn-hambuger">
                                        <i className="icon icon-hambuger"></i>
                                        <span className="hidden">hambuger</span>
                                    </a>
                                    <a className="logo" href="#">
                                        <img className="lazyload desktop" data-src="images/logo.png" alt="" />
                                        <img className="lazyload mobile" data-src="images/logo-mb.png" alt="" />
                                    </a>

                                    <ul className="list-nav">
                                        <li>
                                            <a href="#">UpRace 2021</a>
                                        </li>
                                        <li>
                                            <a href="#">Join Now</a>
                                        </li>
                                        <li>
                                            <a href="#">Download</a>
                                        </li>
                                        <li>
                                            <a href="#">Contact us</a>
                                        </li>
                                        <li className="language">
                                            {MENU_LANGUAGE.map((item) => {
                                                return <MenuLanguage data={item} />;
                                            })}
                                        </li>
                                    </ul>

                                    <div className="user-function">
                                        <a className="btn-login btn-white" href="#">
                                            Login
                                        </a>
                                        <a className="btn-signin btn-white" href="#">
                                            Sign Up
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    })
);

export default MenuHeader;
