import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ACTIVITY_TYPE, FEED_TYPE, SOCIAL_TAB, SOCIAL_TYPE } from '../../../../helpers/ConstDefined';
import {
    ActivityItem,
    DialogDeleteActivity,
    DialogEditActivity,
    DialogSocial,
    DialogViewImage
} from '../../components';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import NotActivity from './NotActivity';

let activity;

const ListActivity = inject(
    'authStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID } = props;
        const {
            listFeed,
            currentFeed,
            loadingFeed,
            totalFeed,
            userProfile,
            doGetListFeed,
            doSetFunc
        } = props.userStore;
        const { userTeam } = props.teamStore;

        const [openDialogDeleteActivity, setOpenDialogDeleteActivity] = React.useState(false);
        const [openDialogEditActivity, setOpenDialogEditActivity] = React.useState(false);
        const [openDialogViewImage, setOpenDialogViewImage] = React.useState(false);

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const { appVersion, doResetImageFeed } = props.globalStore;
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);

        useEffect(() => {
            if (userProfile) getUserFeed();
        }, [userProfile]);

        const { t } = useTranslation();

        const isShowLoadMore = listFeed == null ? false : listFeed.length < totalFeed;

        const onViewImage = (act) => {
            activity = act;
            setOpenDialogViewImage(true);
        };

        const onEditActivity = (act) => {
            activity = act;
            setOpenDialogEditActivity(true);
        };

        const handleOpenDialogSocial = (flag) => {
            setOpenDialogSocial(flag);
        };

        function getUserFeed(isLoadMore) {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.YOUR_ACTIVITY,
                evid: eventID,
                value: userProfile.uid
            };
            doGetListFeed(params, isLoadMore);
        }

        function onViewDialogSocial(act, type) {
            activity = act;
            onOpenDialogSocial(type);
        }

        const onDeleteActivity = (act) => {
            activity = act;
            setOpenDialogDeleteActivity(true);
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingFeed)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getUserFeed(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        if (totalFeed == 0) return <NotActivity type={ACTIVITY_TYPE.VALID} />;
        else
            return (
                <div class="yours--activity">
                    <div class="title-row">
                        <h2 class="yours--title">
                            {t('global.list_act_valid')} <span class="num">({totalFeed})</span>
                        </h2>
                        <a href="javascript:void(0);" onClick={() => doSetFunc(1)} class="seemore">
                            {t('global.list_act_invalid')}
                        </a>
                    </div>
                    <div class="activity-list">
                        <ul>
                            {listFeed != null &&
                                listFeed.map((item) => (
                                    <ActivityItem
                                        key={item.atid}
                                        data={item}
                                        eventID={eventID}
                                        onViewDialogSocial={onViewDialogSocial}
                                        onDelete={onDeleteActivity}
                                        onViewFeedDetail={() => onViewFeedDetail(item, true)}
                                        onViewImage={onViewImage}
                                        onEdit={onEditActivity}></ActivityItem>
                                ))}
                        </ul>
                        <LoadMore />
                    </div>
                    {activity && openDialogDeleteActivity ? (
                        <DialogDeleteActivity
                            openDialogDeleteActivity={openDialogDeleteActivity}
                            data={activity}
                            type={ACTIVITY_TYPE.VALID}
                            onClose={() => setOpenDialogDeleteActivity(false)}
                        />
                    ) : null}
                    {activity && openDialogEditActivity ? (
                        <DialogEditActivity
                            data={activity}
                            openDialogEditActivity={openDialogEditActivity}
                            onClose={() => setOpenDialogEditActivity(false)}
                        />
                    ) : null}
                    {openDialogSocial ? (
                        <DialogSocial
                            openDialogSocial={openDialogSocial}
                            onClose={() => handleOpenDialogSocial(false)}
                            data={activity}
                            type={SOCIAL_TYPE.FEED}
                            tab={tabSocial}
                        />
                    ) : null}
                    {activity && openDialogViewImage ? (
                        <DialogViewImage
                            data={activity}
                            openDialogViewImage={openDialogViewImage}
                            onClose={() => setOpenDialogViewImage(false)}
                        />
                    ) : null}
                </div>
            );
    })
);

export default ListActivity;
