import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const CompanyInfo = inject('authStore')(
    observer((props) => {
        const { id, lang } = props;

        function SaigonChild() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/saigonchild_logo.jpg"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Saigon Children's Charity CIO</h4>
                        <img
                            src="../../../img/event-information/saigonchild.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Saigon Children’s Charity CIO (saigonchildren) được thành lập năm 1992 nhằm giúp trẻ em Việt
                            Nam có hoàn cảnh khó khăn được tiếp cận giáo dục và có sự khởi đầu tốt hơn trong cuộc sống.
                            saigonchildren chỉ hoạt động tại Việt Nam và mọi hoạt động của tổ chức gắn liền với cam kết
                            hỗ trợ trẻ em vượt qua những rào cản về kinh tế, địa lý, kiến thức và những khiếm khuyết để
                            đến trường. Chúng tôi quan niệm trẻ em có hoàn cảnh khó khăn có quyền được hỗ trợ để phát
                            triển tiềm năng của mình. Chúng tôi tin rằng khi tập trung hỗ trợ nhu cầu về giáo dục của
                            những em có hoàn cảnh khó khăn nhất, chúng tôi sẽ mang đến những thay đổi tích cực cho các
                            em.
                        </p>
                        <p>
                            <a href="https://www.saigonchildren.com/">https://www.saigonchildren.com/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function SaigonChildEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/saigonchild_logo.jpg"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Saigon Children's Charity CIO</h4>
                        <img
                            src="../../../img/event-information/saigonchild.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Saigon Children’s Charity CIO (saigonchildren) was founded in 1992 to help disadvantaged
                            children get an education and a fairer start in life. Saigonchildren works exclusively
                            across the whole of Vietnam to overcome barriers to education for children, including
                            poverty, geography, knowledge and disability. saigonchildren believes that all children have
                            the right to fulfil their potential and that focusing on education enables even the most
                            disadvantaged children to create a better future for themselves.
                        </p>
                        <p>
                            <a href="https://www.saigonchildren.com/">https://www.saigonchildren.com/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function VietSeeds() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img src="../../../img/event-information/vietseeds_logo.png" alt="image" />
                        </div>
                        <h4 className="title">VietSeeds</h4>

                        <p className="description">
                            VietSeeds là một tổ chức phi lợi nhuận cam kết mang lại sự bình đẳng trong giáo dục cho Sinh
                            viên Việt Nam qua các chương trình học bổng toàn diện, hỗ trợ những “hạt giống tương lai”
                            vững bước trên con đường khai phóng tiềm năng của bản thân. Sau gần 10 năm thực hiện sứ
                            mệnh, các Sinh viên có xuất phát điểm khó khăn nhưng luôn mang trong mình ý chí và nghị lực
                            vươn lên đã trở thành những công dân tích cực, tiếp nối tinh thần “Pay It Forward” cho xã
                            hội.
                        </p>
                        <img src="../../../img/event-information/vietseed.png" alt="image" />
                        <p></p>
                        <p className="description">
                            Giáo dục đã mở ra rất nhiều cơ hội tuyệt vời cho cuộc đời mỗi người và góp phần giúp Việt
                            Nam trở nên tốt đẹp hơn, như thông điệp của VietSeeds “Grow a Seed – Change a Life –
                            Transform a Nation”.
                        </p>
                        <a href="http://www.vietseeds.org/">http://www.vietseeds.org/</a>
                    </div>
                </div>
            );
        }

        function VietSeedsEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img src="../../../img/event-information/vietseeds_logo.png" alt="image" />
                        </div>
                        <h4 className="title">VietSeeds</h4>

                        <p className="description">
                            VietSeeds Foundation is a non-profit organization that commits to bring about fair
                            educational chances to Vietnamese high school graduates by providing comprehensive
                            scholarships with a view to supporting these "future seeds" on their ways to spearhead their
                            own potentials. After 10 years of delivering that mission, those talents who possess a
                            strength of character in overcoming tremendous odds of poverty and hardship have become
                            proactive citizens, continuing with the "Pay It Forward" spirit for society.
                        </p>
                        <img src="../../../img/event-information/vietseed.png" alt="image" />
                        <p></p>
                        <p className="description">
                            Education has opened the door to a great number of opportunities and partially contributed
                            to improve Vietnam, just like Vietseeds' slogan "Grow a Seed – Change a Life – Transform a
                            Nation”.
                        </p>
                        <a href="http://www.vietseeds.org/">http://www.vietseeds.org/</a>
                    </div>
                </div>
            );
        }

        function GreenViet() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/logogreenviet.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">GreenViet</h4>
                        <img
                            src="../../../img/event-information/greenviet.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>

                        <p className="description">
                            GreenViet với tên gọi đầy đủ là Trung tâm Bảo tồn Đa dạng Sinh học Nước Việt xanh, một tổ
                            chức phi chính phủ thành lập vào tháng 10 năm 2012 tại thành phố Đà Nẵng, hoạt động trong
                            lĩnh vực Bảo tồn Đa dạng Sinh học khu vực miền Trung - Tây Nguyên.GreenViet ra đời và phát
                            triển nhằm bảo tồn hệ sinh thái động thực vật, đặc biệt là các loài có tình trạng nguy cấp
                            tại Việt Nam. Từ năm 2013, GreenViet đã và đang bảo tồn thành công quần thể các loài Chà vá
                            chân nâu, chân xám và chân đen. Từ năm 2019, GreenViet phát triển chương trình “Một triệu
                            cây xanh trong đô thị Việt Nam“ (MTIC) nhằm cải thiện môi trường sống của người dân ở các
                            thành phố lớn tại Việt Nam.
                        </p>
                        <p>
                            <a href="http://greenviet.org/">http://greenviet.org/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function GreenVietEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/logogreenviet.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">GreenViet</h4>
                        <img
                            src="../../../img/event-information/greenviet.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>

                        <p className="description">
                            GreenViet is a non-governmental organization established on October 4, 2012 in Da Nang and
                            has operated in the field of Biodiversity Conservation in the Central and Central Highlands
                            regions. GreenViet was founded and developed to conserve ecosystems and endangered species
                            of flora and fauna in Vietnam. In 2019, GreenViet has been developing “Million tree in
                            cities” to improve the living environment of people in major cities in Vietnam.
                        </p>
                        <p>
                            <a href="http://greenviet.org/">http://greenviet.org/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function NewBorns() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/logonewborn.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Newborns Vietnam</h4>
                        <img
                            src="../../../img/event-information/nbv.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Newborns Vietnam là Tổ chức phi chính phủ Anh Quốc với mục tiêu chấm dứt tỷ lệ tử vong sơ
                            sinh có thể phòng ngừa được tại Việt Nam. Hơn 10 năm qua, Newborns đã làm việc với các bệnh
                            viện công tại Việt Nam, chuyển đổi mô hình chăm sóc các bà mẹ và trẻ sơ sinh, loại bỏ các
                            thực hành cũ và có hại, đào tạo chăm sóc trẻ nhẹ cân và bị bệnh, giúp hàng ngàn trẻ được thở
                            những nhịp đầu tiên.Không một bà mẹ hay trẻ sơ sinh nào phải đối mặt với những nguy cơ tử
                            vong mà chúng ta có thể phòng ngừa được. Và để những điều đó thành hiện thực, Newborns
                            Vietnam rất cần sự chung sức của mọi người.
                        </p>

                        <p>
                            <a href="https://www.newbornsvietnam.org/">https://www.newbornsvietnam.org/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function NewBornsEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/logonewborn.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Newborns Vietnam</h4>
                        <img
                            src="../../../img/event-information/nbv.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Newborns Vietnam is a United Kingdom registered charity licensed to operate as a
                            non-governmental organization (NGO) in Vietnam dedicated to reducing neonatal mortality in
                            South East Asia, with a specific focus on Vietnam. NBV believes that the most effective way
                            of saving newborn lives starts with education and strengthening existing hospitalbased care.
                            Working in collaboration with the public health partners in Viet Nam, NBV develops capacity
                            to improve the survival of newborn infants and their long-term health outcomes.
                        </p>

                        <p>
                            <a href="https://www.newbornsvietnam.org/">https://www.newbornsvietnam.org/</a>
                        </p>
                    </div>
                </div>
            );
        }

        function OperationSmile() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/operation_smile_logo.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Operation Smile</h4>
                        <img
                            src="../../../img/event-information/operation_smile_1.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Operation Smile là tổ chức nhân đạo hoạt động trong lĩnh vực y tế với vai trò đem lại các ca
                            phẫu thuật an toàn và miễn phí cho trẻ em bị dị tật vùng hàm mặt. Sau hành trình 30 năm tại
                            Việt Nam, tổ chức đã đem lại nụ cười thay đổi cuộc đời cho 60,000 trẻ em trên khắp đất nước.
                            Tuy nhiên, những con số đó chỉ thể hiện một góc sáng nhỏ trong hành trình còn rất dài phía
                            trước.Tuy vậy, với sự chung tay của tất cả mọi người khi cùng nhau lan tỏa thông điệp tích
                            cực rằng “Thể thao góp phần thay đổi cuộc đời”, mỗi bước chạy của các bạn sẽ giúp chúng tôi
                            vững tin về đích đến phía trước của một giấc mơ Việt Nam không còn em bé nào phải chịu nỗi
                            đau về dị tật trên khuôn mặt của mình.
                        </p>

                        <p>
                            <a href="http://www.operationsmile.org.vn">http://www.operationsmile.org.vn</a>
                        </p>
                    </div>
                </div>
            );
        }

        function OperationSmileEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img
                                src="../../../img/event-information/operation_smile_logo.png"
                                alt="image"
                                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            />
                        </div>
                        <h4 className="title">Operation Smile</h4>
                        <img
                            src="../../../img/event-information/operation_smile_1.png"
                            alt="image"
                            style={{ width: '90%', objectFit: 'contain' }}
                        />
                        <p></p>
                        <p className="description">
                            Operation Smile Vietnam is a non-profit medical organization which specializes in providing
                            safe and humanitarian surgeries for children born with facial deformities. After more than
                            32 years of establishment in Vietnam, Operation Smile has provided more than 62,000
                            life-changing smiles for Vietnamese children across the country. With the meaningful message
                            that “Sports can change lives", each step you take will help us to firmly move on with our
                            dream where no Vietnamese children have to suffer from face deformities.
                        </p>

                        <p>
                            <a href="http://www.operationsmile.org.vn">http://www.operationsmile.org.vn</a>
                        </p>
                    </div>
                </div>
            );
        }

        function UomMamUocMo() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img src="../../../img/event-information/logo_uommam.png" alt="image" />
                        </div>
                        <h4 className="title">Quỹ học bổng Ươm Mầm Ước Mơ</h4>

                        <p className="description">
                            "Quỹ học bổng ""Ươm mầm ước mơ"" thành lập từ năm 2015 trên cơ sở nguồn ngân sách từ quỹ
                            phúc lợi của Ngân hàng SeABank, đồng thời kêu gọi ủng hộ từ các nhà hảo tâm, CBNV để trao
                            tặng các suất học bổng, hỗ trợ khuyến học cho các em học sinh nghèo hiếu học, các tài năng
                            trẻ trên khắp mọi miền Tổ quốc. Đến thời điểm hiện tại, Quỹ đang nhận đỡ đầu cho gần 140 em
                            học sinh nghèo tại 27 tỉnh thành phố với mức hỗ trợ 1 triệu/em/tháng cho tới khi học hết lớp
                            12. <br></br>Riêng trong năm 2019 Quỹ đã nhận đỡ đầu thêm 55 em, tài trợ học bổng cho 5 sinh
                            viên xuất sắc của ngành Ngân hàng – Tài chính tại các trường Đại học hàng đầu của Việt Nam.
                            Bên cạnh đó, một số em học sinh đã tốt nghiệp trung học và thi đỗ vào vào các trường Đại
                            học, bước sang con đường mới và nhiều hứa hẹn tương lai.{' '}
                        </p>
                        <img src="../../../img/event-information/searun.png" alt="image" />
                        <p></p>
                        <p className="description">
                            Đặc biệt, từ 2018, SeABank đã thành công tổ chức chuỗi giải chạy cộng đồng SeABank Run For
                            The Future (SeARun) tại các tỉnh thành phố lớn của Việt Nam nhằm phát động phong trào rèn
                            luyện sức khỏe, tôn vinh những giá trị tốt đẹp của tình cảm gia đình đồng thời gây quỹ ủng
                            hộ khuyến học cho trẻ em nghèo thông qua quỹ Ươm mầm ước mơ.<br></br> Năm 2018, SeARun tổ
                            chức tại 4 tỉnh thành phố lớn (Hà Nội, Hồ Chí Minh, Đà Nẵng) thu hút sự tham gia của hơn
                            4.000 VĐV là CBNV SeABank, CBNV các đối tác và khách hàng trên toàn quốc và đã trao tặng 30
                            học bổng Ươm mầm ước mơ với tổng giá trị gần 3 tỷ đồng.<br></br> Năm 2019, SeARun tổ chức
                            tại 4 tỉnh thành phố lớn đã thu hút được sự tham gia của hơn 6.000 VĐV, và đã trao tặng 41
                            học bổng Ươm mầm ước mơ với tổng giá trị gần 4 tỷ đồng. <br></br>Năm 2020, SeARun sẽ được tổ
                            chức theo cách thức trực tuyến, trên nền tảng của ứng dụng UpRace, với mong muốn thu hút
                            nhiều hơn các VĐV tham dự trên khắp mọi miền tổ quốc, và sẽ trao tặng 26 học bổng Ươm mầm
                            ước mơ với giá trị dự kiến khoảng 3 tỷ đồng nhân dịp kỷ niệm 26 năm thành lập SeABank."
                        </p>
                        <a href="https://www.seabank.com.vn/site#/news/313">
                            https://www.seabank.com.vn/site#/news/313
                        </a>
                    </div>
                </div>
            );
        }

        function UomMamUocMoEN() {
            return (
                <div className="container">
                    <div className="wrapper-content sponsors">
                        <div className="wrapper-image-logo">
                            <img src="../../../img/event-information/logo_uommam.png" alt="image" />
                        </div>
                        <h4 className="title">Nurturing the Dream</h4>

                        <p className="description">
                            The "Dream Nurturing" scholarship fund was established in 2015 from the budget of SeABank’s
                            welfare fund and at the same time called for support from benefactors and employees to award
                            scholarships for the poor, studious and talented young students in all parts of the country.
                            Up to now, the Fund is sponsoring nearly 140 poor students in 27 provinces and cities across
                            the country with a support level of VND 1 million/child/month until the end of grade 12.
                            <br></br>
                            Particularly in 2019, the Fund has sponsored 55 more students, sponsored scholarships for 5
                            excellent students of the Banking and Finance sector at top universities in Vietnam. Until
                            now, Dream Nurturing Fund has 02 students graduated high school and passed the entrance exam
                            to universities, entering a new path and promising future.
                        </p>
                        <img src="../../../img/event-information/searun.png" alt="image" />
                        <p></p>
                        <p className="description">
                            In particular, since 2018, SeABank has successfully organized the SeABank Run For The Future
                            (SeARun) community run series in major cities and provinces of Vietnam, thereby honor good
                            values of health and humanitarian and raised funds to support education promotion for poor
                            children through the Dream Nurturing Fund.<br></br>
                            In 2018, SeARun held in 3 major cities and provinces (Hanoi, Ho Chi Minh, Da Nang),
                            attracting the participation of more than 4,000 SeABank employees, employees of partners and
                            customers nationwide and awarded 30 scholarships for Dream Nurturing Fund with a total value
                            of nearly VND 3 billion.<br></br>
                            In 2019, SeARun held in 4 major cities and provinces attracted the participation of more
                            than 6,000 athletes, awarded 41 scholarships for the Dream Nurturing Fund with a total value
                            of nearly VND 4 billion.<br></br>
                            In 2020, SeARun will be held in an online manner on the platform of the UpRace application,
                            with the desire to attract more athletes to attend all over the country, and will award 26
                            scholarships with an expected value of about VND 3 billion on the occasion of SeABank's 26th
                            anniversary.
                        </p>
                        <a href="https://www.seabank.com.vn/site#/news/313">
                            https://www.seabank.com.vn/site#/news/313
                        </a>
                    </div>
                </div>
            );
        }

        const RenderContent = () => {
            if (id == 1) {
                //newsborns
                if (lang == 'en') return <NewBornsEN />;
                else return <NewBorns />;
            } else if (id == 2) {
                if (lang == 'en') return <VietSeedsEN />;
                else return <VietSeeds />;
            } else if (id == 3) {
                if (lang == 'en') return <GreenVietEN />;
                else return <GreenViet />;
            } else if (id == 4) {
                if (lang == 'en') return <OperationSmileEN />;
                else return <OperationSmile />;
            } else if (id == 5) {
                if (lang == 'en') return <UomMamUocMoEN />;
                else return <UomMamUocMo />;
            } else if (id == 6) {
                if (lang == 'en') return <SaigonChildEN />;
                else return <SaigonChild />;
            } else return null;
        };

        return (
            <section class="fail-activity-list">
                <ul class="list">
                    <RenderContent />
                </ul>
            </section>
        );
    })
);

export default CompanyInfo;
