import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const SocialOrganization = inject('authStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {}, []);
        const { t, i18n } = useTranslation();

        return (
            <>
                <div className="head-text flex space-50">
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: t('landing_page.org.title')
                        }}></h1>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('landing_page.org.desc')
                        }}></p>
                </div>
                <div className="list-social-org flex container justify-content-space-between">
                    <div className="social-org-box">
                        <div className="social-org space-20">
                            <a className="flip-front flex align-item-center justify-content-center" href="http://www.newbornsvietnam.org" target="_blank">
                                <img className="lazyload " data-src="images/social-org/org1.png" alt="" />
                            </a>
                            <a className="flip-back" href="http://www.newbornsvietnam.org" target="_blank">
                                <img
                                    className="img-detail lazyload "
                                    data-src="images/social-org/newborns.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <p className="social-org--name color-black bold text-center">Newborns Việt Nam</p>
                        <a className="btn-detail flex justify-content-center" href="http://www.newbornsvietnam.org" target="_blank">
                            <span>{t('landing_page.org.view_detail')}</span> <i className="icon  icon-more-white"></i>
                        </a>
                    </div>
                    <div className="social-org-box">
                        <div className="social-org space-20">
                            <a className="flip-front flex align-item-center justify-content-center" href="http://www.operationsmile.org.vn" target="_blank">
                                <img className="lazyload " data-src="images/social-org/org2.png" alt="" />
                            </a>
                            <a className="flip-back" href="http://www.operationsmile.org.vn" target="_blank">
                                <img
                                    className="img-detail lazyload "
                                    data-src="images/social-org/org1-detail.jpg"
                                    alt=""
                                />
                            </a>
                        </div>
                        <p className="social-org--name color-black bold text-center">Operation Smile</p>
                        <a className="btn-detail flex justify-content-center" href="http://www.operationsmile.org.vn" target="_blank">
                            <span>{t('landing_page.org.view_detail')}</span> <i className="icon  icon-more-white"></i>
                        </a>
                    </div>
                    <div className="social-org-box">
                        <div className="social-org space-20">
                            <a className="flip-front flex align-item-center justify-content-center" href="http://www.greenviet.org" target="_blank">
                                <img className="lazyload " data-src="images/social-org/org3.png" alt="" />
                            </a>
                            <a className="flip-back" href="http://www.greenviet.org" target="_blank">
                                <img
                                    className="img-detail lazyload "
                                    data-src="images/social-org/greenviet.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <p className="social-org--name color-black bold text-center">Green Việt</p>
                        <a className="btn-detail flex justify-content-center" href="http://www.greenviet.org" target="_blank">
                            <span>{t('landing_page.org.view_detail')}</span> <i className="icon  icon-more-white"></i>
                        </a>
                    </div>

                    <div className="social-org-box">
                        <div className="social-org space-20">
                            <a className="flip-front flex align-item-center justify-content-center" href="https://www.saigonchildren.com/" target="_blank">
                                <img className="lazyload " data-src="images/social-org/saigonchildren.png" alt="" />
                            </a>
                            <a className="flip-back" href="https://www.saigonchildren.com/" target="_blank">
                                <img
                                    className="img-detail lazyload "
                                    data-src="images/social-org/child.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <p className="social-org--name color-black bold text-center">Saigon Children's Charity CIO</p>
                        <a className="btn-detail flex justify-content-center" href="https://www.saigonchildren.com/" target="_blank">
                            <span>{t('landing_page.org.view_detail')}</span> <i className="icon  icon-more-white"></i>
                        </a>
                    </div>
                </div>
            </>
        );
    })
);

export default SocialOrganization;
