import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, Line } from 'react-chartjs-2';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { toJS } from 'mobx';
import { GLOBAL_CONFIG, EVENT_COMPARE_TYPE } from '../../../../helpers/ConstDefined';
import { formatNumber, formatDistanceKM, convertTimeFromSecond } from '../../../../utils';
import { humanTime, formatDateString } from '../../../../helpers/ConvertHelper';
import ChartData from './ChartData';

const AchievementPersonal = inject(
    'globalStore',
    'chartStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, endDate } = props;
        const { userProfile } = props.userStore;
        const { listPersonalAchieve, listDataUserEvent, doGetEventCompareData } = props.chartStore;
        const [yearText, setYearText] = React.useState('');
        const [yearText1, setYearText1] = React.useState('');
        const { language } = props.globalStore;

        const { t } = useTranslation();

        const getCompareEventData = () => {
            const params = {
                evid: eventID,
                oevid:
                    eventID == GLOBAL_CONFIG.EVENT_UPRACE_2020_ID
                        ? GLOBAL_CONFIG.EVENT_UPRACE_2021_ID
                        : GLOBAL_CONFIG.EVENT_UPRACE_2020_ID,
                type: EVENT_COMPARE_TYPE.USER, //compare user
                value: userProfile.uid
            };
            doGetEventCompareData(params, EVENT_COMPARE_TYPE.USER, endDate);
        };

        useEffect(() => {
            if(userProfile) getCompareEventData();
        }, [userProfile]);

        useEffect(() => {
            parseYearName();
        }, [listPersonalAchieve]);

        const parseYearName = () => {
            if (!listPersonalAchieve) return null;
            let year =
                listPersonalAchieve[0].dyls.length > 0 ? listPersonalAchieve[0].dyls[0].day.substring(0, 4) : null;
            let year1 =
                listPersonalAchieve[1].dyls.length > 0 ? listPersonalAchieve[1].dyls[0].day.substring(0, 4) : null;
            setYearText(year);
            setYearText1(year1);
        };

        return (
            <div class="yours--chart">
                <h2 class="yours--title">{t('global.archi_personal')}</h2>
                <div class="sumary">
                    <p class="lb">{t('global.get')}</p>
                    <p class="num">{listPersonalAchieve && formatDistanceKM(listPersonalAchieve[0].rdis)}</p>
                    <p class="unit">{t('global.km_in_2021')}</p>
                    <div class="notity-box">
                        <p class="ico"></p>
                        <div class="tooltip-box">{t('global.km_valid_event_user')}</div>
                    </div>
                </div>
                <div class="years">
                    {yearText ? (
                        <>
                            <p class="color yellow"></p>
                            <p class="num">{yearText}</p>
                        </>
                    ) : null}
                    {yearText1 ? (
                        <>
                            <p class="color green"></p>
                            <p class="num">{yearText1}</p>
                        </>
                    ) : null}
                </div>
                <div class="chart-block">
                    <div class="chart-box">{listPersonalAchieve ? <ChartData /> : null}</div>
                    <div class="statis-block">
                        <div class="statis-box yellow">
                            {listDataUserEvent && listDataUserEvent[0] ? (
                                <>
                                    <div class="title">{formatDateString(listDataUserEvent[0].day,language)}</div>
                                    <ul class="ctn">
                                        <li>
                                            <p className="num">{formatDistanceKM(listDataUserEvent[0].rdis)}</p>
                                            <p className="unit">{t('global.number_km')}</p>
                                        </li>
                                        <li>
                                            <p className="num">{formatNumber(listDataUserEvent[0].act)}</p>
                                            <p className="unit">{t('global.act')}</p>
                                        </li>
                                        <li>
                                            <p className="num">{(convertTimeFromSecond(listDataUserEvent[0].dur))}</p>
                                            <p className="unit">{t('global.time')}</p>
                                        </li>
                                    </ul>
                                </>
                            ) : null}
                        </div>

                        <div class="statis-box green">
                            {listDataUserEvent && listDataUserEvent[1] ? (
                                <>
                                    <div class="title">{formatDateString(listDataUserEvent[1].day,language)}</div>
                                    <ul class="ctn">
                                        <li>
                                            <p className="num">{formatDistanceKM(listDataUserEvent[1].rdis)}</p>
                                            <p className="unit">{t('global.number_km')}</p>
                                        </li>
                                        <li>
                                            <p className="num">{formatNumber(listDataUserEvent[1].act)}</p>
                                            <p className="unit">{t('global.act')}</p>
                                        </li>
                                        <li>
                                            <p className="num">{convertTimeFromSecond((listDataUserEvent[1].dur))}</p>
                                            <p className="unit">{t('global.time')}</p>
                                        </li>
                                    </ul>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default AchievementPersonal;
