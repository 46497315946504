import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import YourTeams from './YourTeams';

const InfoEvent = inject(
    'authStore',
    'userProfileStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { profileUser, navigation, eventID } = props;

        useEffect(() => {}, []);

        return (
            <>
                <h3 className="title-rank">Sự kiện đang tham gia</h3>
                <div className="activate-main-right">
                    <div
                        className="background-user-main"
                        style={{ backgroundImage: `url('../../../img/searun_bg_portral.png')` }}>
                        <img className="opacity-hidden" src="../../../img/searun_bg_portral.png" alt="image" />
                        <div className="div-button" onClick={() => props.onViewAchievement(3)}>
                            <a className="btn btn-danger btn-join">Xem Thành Tích</a>
                        </div>
                    </div>
                </div>
                <p></p>
                <h3 className="title-rank">Sự kiện đã tham gia</h3>
                <div className="activate-main-right">
                    <div
                        className="background-user-main"
                        style={{ backgroundImage: `url('../../../img/upgrade-2020.png')` }}>
                        <img className="opacity-hidden" src="../../../img/upgrade-2020.png" alt="image" />
                        <div className="div-button" onClick={() => props.onViewAchievement(2)}>
                            <a className="btn btn-danger btn-join">Xem Thành Tích</a>
                        </div>
                    </div>
                    <div className="wrapper-category-image ">
                        <YourTeams
                            eventID={eventID}
                            userProfile={profileUser}
                            navigation={navigation}
                        />
                    </div>
                </div>
            </>
        );
    })
);

export default InfoEvent;
