import React from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimestamptoDate, formatDistanceKM, getImageResource } from '../../../../utils/index';
import { humanTime } from '../../../../helpers/ConvertHelper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogCreateChallenge from '../DialogCreateChallenge';
import DialogDeleteChallenge from '../DialogDeleteChallenge';
import NotChallenge from '../NotChallenge';

const YourChallenge = inject(
    'authStore',
    'challengeStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { challengeDetail } = props.challengeStore;

        const [openDialogCreateChallenge, setOpenDialogCreateChallenge] = React.useState(false);
        const [openDialogDeleteChallenge, setOpenDialogDeleteChallenge] = React.useState(false);

        const handleOpenDialogCreateChallenge = (flag) => {
            setOpenDialogCreateChallenge(flag);
        };

        const handleOpenDialogDeleteChallenge = (flag) => {
            setOpenDialogDeleteChallenge(flag);
        };

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleDelete = () => {
            setOpenDialogDeleteChallenge(true);
            setAnchorEl(null);
        };

        const handleEdit = () => {
            handleOpenDialogCreateChallenge(true);
            setAnchorEl(null);
        };

        let percentDistance = 0,
            percentDayActive = 0,
            percentLongestDistance = 0;
        if (challengeDetail != null) {
            percentDistance = Math.round(((challengeDetail.dis) * 100) / challengeDetail.cdis);
            percentDayActive = Math.round((challengeDetail.days * 100) / challengeDetail.cdays);
            percentLongestDistance = Math.round(((challengeDetail.xdis) * 100) / challengeDetail.cxdis);
        }

        const imgGender = challengeDetail.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';

        if (challengeDetail != null)
            return (
                <>
                    <div className="row ">
                        <div className="col-md-6 ">
                            <h3 className="title-challenge">Thử thách của bạn</h3>
                        </div>

                        <div className="col-md-6 text-right">
                            <div className="div-share">
                                <div className="btn-group custom-btn-group custom-btn-group-social" role="group"></div>

                                <div className="btn-group custom-btn-group custom-btn-group-action" role="group">
                                    <button
                                        id="btnGroupDropAction"
                                        type="button"
                                        className="btn btn-link dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        onClick={handleOpenMenu}
                                        aria-expanded="false">
                                        <img className="more" src="../../../img/icon-more.svg" alt="image" />
                                    </button>
                                    <Menu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}>
                                        <MenuItem onClick={handleEdit}>
                                            <a className="dropdown-item">
                                                <i className="fa fa-pencil-square-o"></i> Chỉnh sửa
                                            </a>
                                        </MenuItem>
                                        <MenuItem onClick={handleDelete}>
                                            <a className="dropdown-item">
                                                <i className="fa fa-trash"></i> Xóa
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-challenge-friend">
                        <div className="row ">
                            <div className="col-lg-6 ">
                                <div className="wrapper-position-relative">
                                    <div className="wrapper-profile-user">
                                        <div className="row  ">
                                            <div className="pull-left">
                                                <div
                                                    className="wrapper-avatar-member"
                                                    style={{ backgroundImage: `url(${getImageResource(challengeDetail.ava,true,true)})` }}>
                                                    <img className="avatar" src="../../../img/avatar.png" alt="" />
                                                    <img
                                                        className="gender"
                                                        src={imgGender}
                                                        alt="image"
                                                    />
                                                </div>
                                            </div>
                                            <div className="pull-left">
                                                <div className="information-user custom-information-user">
                                                    <span className="name-user">
                                                        {challengeDetail.name}{' '}
                                                        <p className="groups-user">
                                                            - {challengeDetail.tmnm || ''}
                                                        </p>
                                                    </span>
                                                    <p className="time-active">
                                                        {' '}
                                                        {humanTime(challengeDetail.crtm)}
                                                    </p>
                                                    <p className="description-challenge-user">{challengeDetail.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="member-reaction ">
                                        <div className="row ">
                                            <div className="col-6 ">
                                                <div className="wrapper-like" onClick={props.onViewSocialLike}>
                                                    <ul className="avatars">
                                                        {challengeDetail.lkls.map((item) => {
                                                            return (
                                                                <li key={item.uid} className="avatars-item">
                                                                    <img src={getImageResource(item.ava,true,true)} className="avatars-img" />
                                                                </li>
                                                            );
                                                        })}
                                                        {challengeDetail.lkcnt > 4 ? (
                                                            <li className="avatars-item">
                                                                <span className="icon-more">...</span>
                                                            </li>
                                                        ) : null}
                                                    </ul>

                                                    <p className="number-like">{challengeDetail.lkcnt + challengeDetail.dlkcnt} người khác quan tâm</p>
                                                </div>
                                            </div>
                                            <div className="col-6 ">
                                                <div className="wrapper-comments" onClick={props.onViewSocialComment}>
                                                    <p>
                                                        <a>
                                                            {challengeDetail.cmcnt} bình luận{' '}
                                                            {challengeDetail.cmcnt > 0 ? (
                                                                <i className="fa fa-chevron-right next"></i>
                                                            ) : null}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <div className="category">
                                    <div className="row content-top">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-request">Tổng quãng đường:</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-request">{formatDistanceKM(challengeDetail.cdis)} km</p>
                                        </div>
                                    </div>
                                    <div className="progress progress-category-custom custom-pink">
                                        <div
                                            className="progress-bar progress-bar-custom"
                                            role="progressbar"
                                            style={{ width: `${percentDistance}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                    <div className="row content-bottom">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-result">Đã chạy:</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-result pink">
                                                {formatDistanceKM(challengeDetail.dis)} km
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="category">
                                    <div className="row content-top">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-request">Tổng số ngày chạy:</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-request">{challengeDetail.cdays} ngày</p>
                                        </div>
                                    </div>
                                    <div className="progress progress-category-custom custom-green">
                                        <div
                                            className="progress-bar progress-bar-custom"
                                            role="progressbar"
                                            style={{ width: `${percentDayActive}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                    <div className="row content-bottom">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-result">Số ngày đã chạy::</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-result green">{challengeDetail.days} ngày</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="category">
                                    <div className="row content-top">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-request">Quãng đường dài nhất:</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-request">{formatDistanceKM(challengeDetail.cxdis)} km</p>
                                        </div>
                                    </div>
                                    <div className="progress progress-category-custom">
                                        <div
                                            className="progress-bar progress-bar-custom"
                                            role="progressbar"
                                            style={{ width: `${percentLongestDistance}%` }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                    <div className="row content-bottom">
                                        <div className="col-md-6 custom-margin">
                                            <p className="title-result">Đã chạy dài nhất:</p>
                                        </div>
                                        <div className="col-md-6 custom-margin">
                                            <p className="value-result blue">
                                                {formatDistanceKM(challengeDetail.xdis)} km
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogCreateChallenge
                        openDialogCreateChallenge={openDialogCreateChallenge}
                        challengeDetail={challengeDetail}
                        eventID={eventID}
                        onClose={() => handleOpenDialogCreateChallenge(false)}
                    />

                    <DialogDeleteChallenge
                        openDialogDeleteChallenge={openDialogDeleteChallenge}
                        challengeDetail={challengeDetail}
                        onClose={() => handleOpenDialogDeleteChallenge(false)}
                    />
                </>
            );
        else return <NotChallenge />;
    })
);

export default YourChallenge;
