import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Top3News, NewsContent } from './components';
import { Helmet } from 'react-helmet';
const queryString = require('query-string');
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { stripedHtml } from 'utils';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';

const NewsDetail = inject(
    'globalStore',
    'newsStore'
)(
    observer((props) => {
        const { listNews2021, doGetListNews2021 } = props.newsStore;
        const { newsContent, doGetNewsContent } = props.globalStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid || !paramsQuery.id) return <Redirect to="/"></Redirect>;

        if (!_.isEmpty(paramsQuery)) {
            const data = _.find(listNews2021, (item) => {
                return item.id == stripedHtml(paramsQuery.id);
            });
            if (data) doGetNewsContent(data.url_vi);
        }

        const { t } = useTranslation();

        useEffect(() => {
            window.scrollTo(0, 0);
            if (listNews2021 == null) doGetListNews2021();
        }, []);

        return (
            <>
                <section className="section section--wrapper ">
                    <div className="container container--news">
                        <div className="flex align-item-center breadcrumb">
                            <i className="icon icon-news"></i>
                            <RouterLink to="/">{t('landing_page.home')}</RouterLink>
                            <span> {'>'} </span>
                            <a href="#">{t('landing_page.listnews.title')}</a>
                        </div>
                        <div className="flex outer-news">
                            <NewsContent data={newsContent} />
                            <Top3News />
                        </div>
                    </div>
                    <img className="background-bottom lazyload desktop" data-src="images/bg/bg-bottom.png" alt="" />
                </section>
            </>
        );
    })
);

export default NewsDetail;
