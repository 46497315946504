import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimestamptoDate, getImageResource } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import {
    EVENT_FORM_TYPE,
    EVENT_JOIN_STATUS,
    EVENT_OFFICAL_TYPE,
    EVENT_PUBlIC_TYPE,
    EVENT_TYPE,
    GROUP_JOIN_STATUS
} from 'helpers/ConstDefined';
import { MemberName } from 'components/MemberName';

const EventInfo = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'teamManageStore'
)(
    observer((props) => {
        const { navigation } = props;
        const { currentEvent } = props.eventStore;
        const { currentGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const { listTop15Mem, doGetTop15Member } = props.teamManageStore;

        const { t } = useTranslation();
        if (!currentEvent) return null;

        useEffect(() => {
            getTopMember();
        }, [userProfile, currentEvent.evid]);

        const getTopMember = () => {
            const params = {
                size: 6,
                uid: userProfile && userProfile.uid,
                tmid: 0,
                evid: currentEvent.evid
            };
            doGetTop15Member(params);
        };

        const eventPublicStatus = () => {
            if (currentEvent.jpri == EVENT_PUBlIC_TYPE.PRIVATE || currentEvent.jpri == EVENT_PUBlIC_TYPE.PROTECTED) {
                return <div class="type private">{t('ue.private')}</div>;
            } else return <div class="type public">{t('ue.public')}</div>;
        };

        const eventType = currentEvent.evtp == EVENT_TYPE.CHALLENGE ? t('ue.challen') : t('ue.offical');
        const formType = currentEvent.form == EVENT_FORM_TYPE.GROUP ? t('ue.group') : t('global.personal');
        const iconType = currentEvent.form == EVENT_FORM_TYPE.GROUP ? 'group' : 'user';

        const viewGroupDetail = () => {
            navigation.push(`/group-detail?grid=${currentGroup.grid}&evid=${currentEvent.evid}`);
        };

        function totalMem() {
            const total = currentEvent.mem;
            const totalAva = listTop15Mem ? listTop15Mem.length : 0;
            return total - totalAva;
        }

        return (
            <div class="card card-overview">
                <div class="card-overview-body">
                    <h3 class="card-title">
                        {currentEvent.offical == EVENT_OFFICAL_TYPE.OFFICAL ? (
                            <span class="icon-verified"></span>
                        ) : null}
                        {currentEvent.name}
                    </h3>

                    <div class="card-text">
                        <div class="badge">{eventType}</div>
                        <div class="calendar">
                            <span class="txt">
                                {convertTimestamptoDate(currentEvent.betm, 'HH:mm')}{' '}
                                {convertTimestamptoDate(currentEvent.betm, 'DD/MM/YYYY')} -{' '}
                                {convertTimestamptoDate(currentEvent.entm, 'HH:mm')}{' '}
                                {convertTimestamptoDate(currentEvent.entm, 'DD/MM/YYYY')}
                            </span>
                        </div>
                    </div>

                    <div class="card-text">
                        <div class="avatar-group flex-shrink-0">
                            {listTop15Mem &&
                                listTop15Mem.map((item) => {
                                    return (
                                        <span class="avatar">
                                            <img src={getImageResource(item.ava, true)} alt="avatar" />
                                        </span>
                                    );
                                })}
                        </div>
                        <div class="flex-grow-1">
                            {totalMem() > 0 ? (
                                <span class="fw-500 text-ink-light">+ {totalMem()}</span>
                            ) : null}
                            <span class="fw-500 text-ink-lighter"> {t('global.member_join').toLowerCase()}</span>
                        </div>
                    </div>

                    <div class="card-text">
                        {eventPublicStatus()}
                        <div class="dot"></div>
                        <div class={`type ${iconType}`}>
                            <span class="txt">
                                {t('ue.match_type1')}: {formType}
                            </span>
                        </div>
                    </div>
                    <div class="card-text">
                        <div class="owner-name">
                            <span class="txt">{MemberName(currentEvent)}</span>
                        </div>
                    </div>

                    {currentEvent.form == EVENT_FORM_TYPE.GROUP &&
                    currentEvent.grid == 0 &&
                    currentEvent.jsts != EVENT_JOIN_STATUS.NONE &&
                    currentEvent.jsts != EVENT_JOIN_STATUS.REQUESTING ? (
                        <div class="alert alert-danger" role="alert">
                            <span class="txt">{t('eu.select_group_to_join')}</span>
                        </div>
                    ) : null}

                    {currentEvent.grid != 0 && currentGroup && currentGroup.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                        <div
                            class="alert alert-info"
                            role="alert"
                            style={{ cursor: 'pointer' }}
                            onClick={() => viewGroupDetail()}>
                            <div class="icon-notify"></div>
                            <div class="txt">{t('eu.pending_approve_group')}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    })
);

export default EventInfo;
