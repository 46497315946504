import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Personal, UserStats, ListActivity, EventList, TeamList, DeleteAccount } from './components';
import { isJWTValid, stripedHtml } from '../../utils';
import _ from 'lodash';
import { isAccountDeleted } from 'components/MemberName';

const queryString = require('query-string');

const UserProfile = inject(
    'eventStore',
    'userStore',
    'teamStore',
    'userProfileStore',
    'globalStore'
)(
    observer((props) => {
        const { loadingProfileOther, userProfile, userProfileOther, doGetProfileDetailOther } = props.userStore;

        let { doSetReportType, doSetSportType } = props.userProfileStore;
        const { doSetMainTab, doSetTabMainTop } = props.globalStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.uid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);
        let profileID = stripedHtml(paramsQuery.uid);

        useEffect(() => {
            window.scrollTo(0, 0);
            doSetMainTab(null);
            doSetTabMainTop(null);
            if (userProfile) getProfileUserOther();
        }, [userProfile, profileID]);

        async function getProfileUserOther() {
            const params = {
                uid: userProfile && userProfile.uid,
                pfid: profileID
            };
            doGetProfileDetailOther(params);
        }

        useEffect(() => {
            if (!isJWTValid()) props.history.push('/login');
            window.scrollTo(0, 0);
            doSetReportType(0);
            doSetSportType(0);
        }, []);

        if (!loadingProfileOther && userProfileOther == null) return <DeleteAccount />;
        else if (userProfileOther)
            if (isAccountDeleted(userProfileOther)) return <DeleteAccount />;
            else
                return (
                    <section class="profile-page yours">
                        <div class="profile-ctn">
                            <div class="profile-wrap">
                                <div class="profile-left">
                                    <UserStats profileID={profileID} />
                                    <ListActivity profileID={profileID} eventID={eventID} />
                                </div>

                                <div class="profile-right">
                                    {userProfileOther ? (
                                        <>
                                            <Personal profileID={profileID} eventID={eventID} />
                                            <TeamList data={userProfileOther.tmls} eventID={eventID} />
                                            <EventList data={userProfileOther.evls} profileID={profileID}></EventList>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                );
        else return null;
    })
);

export default UserProfile;
