import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getTypeName, formatNumber, getImageResource } from '../../../../utils';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';

const TeamItem = inject('authStore')(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);

        const onViewTeamProfile = () => {
            props.onViewTeamProfile(data);
        };

        return (
            <div className="member-profile" onClick={onViewTeamProfile}>
                <div className="row">
                    <div className="col-11">
                        <div className="profile-member-team">
                            <div className="pull-left">
                                <div className="wrapper-avatar-member" style={{ backgroundImage: `url(${getImageResource(data.ava, true)})` }}>
                                    <img src="../../img/avatar.png" alt="image" />
                                </div>
                            </div>
                            <div className="pull-left">
                                <div className="info-member">
                                    <div className="info-member">
                                        <p className="name-user">
                                            <strong>{data.name}</strong> <span className="space-subname"> - </span>
                                            <span className="groups-user">{getTypeName(TEAM_TYPE, data.lgid)}</span>
                                        </p>
                                        <p className="groups-user">{formatNumber(data.mem)} VĐV</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 no-pad">
                        <div className="wrapper-action-member">
                            <a>
                                <img src="../../img/next-icon.svg" className="img-more-action" alt="More action" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default TeamItem;
