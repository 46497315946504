import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const CommingSoon = inject('authStore')(
    observer((props) => {
        const { t } = useTranslation();

        return (
            <section class="landing-page">
                <p class="img">
                    <img src="../../images/teaser/runner_img.png" alt="" />
                </p>
                <div class="ctn">
                    <div class="ctn-inside">
                        <p class="name">
                            <img src="../../images/teaser/uprace_2021.png" alt="" />
                        </p>
                        <p class="txt">{t('landing_page.comming_soon.title')}</p>
                        <p class="note">{t('landing_page.comming_soon.desc')}</p>
                        <ul class="social-list">
                            <li>
                                <a href="https://www.facebook.com/uprace.vn" target="_blank">
                                    <img src="../../images/teaser/fb_ico.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://zalo.me/1540100739886485865?gidzl=vy6Q6NPBF3RYsPeVT5CqC-3VcnvgKpqvgzUTGZeDCclzXCDCQ0nbDVo3aHy-1pvYeeEOI6Aal9SVSauyC0" target="_blank">
                                    <img src="../../images/teaser/zalo_ico.png" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    })
);

export default CommingSoon;
