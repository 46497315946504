import { requestAPI, requestAPIV2, requestAPIBodyNotAuth, uploadFile } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { STATS_TYPE } from '../helpers/ConstDefined';
import { log } from '../utils';
import { observable, action, toJS } from 'mobx';
import { ContactlessOutlined, TrendingUpRounded } from '@material-ui/icons';

export default class GlobalStore {
    @observable loading = false;
    @observable messageSnackbar = null
    @observable openSnackbar = false;
    @observable snackbarType = '';
    @observable mainTab = 0;
    @observable tabMainTop = 0;
    @observable teamSexTab = 0; //handle index tab of page team detail
    @observable settingTab = 0;
    @observable appVersion = null;
    @observable arrayImageFeed = [];
    @observable arrayImageFeedReal = [];
    @observable isTeamSearch = null;
    @observable reloadUserProfile = null;
    @observable newsContent = null;
    @observable language = 'vi';

    @action.bound
    doChangeLanguage(lang)
    {
        this.language = lang;
    }

    @action.bound
    async doGetNewsContent(url) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(url, null, HTTP_METHOD.GET)
            .then((resp) => {
                this.newsContent = resp;
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doSetReloadUserProfile() {
        if(this.reloadUserProfile == null) this.reloadUserProfile = false;
        this.reloadUserProfile = !this.reloadUserProfile;
    }

    @action.bound
    doSetIsTeamSearch(flag) {
        this.isTeamSearch = flag;
    }

    @action.bound
    doSetSettingTab(tab) {
        this.settingTab = tab;
    }
    
    @action.bound
    setSnackbarType(type) {
        this.snackbarType = type;
    }
    
    @action.bound
    setMessageSnackbar(msg) {
        this.messageSnackbar = msg;
    }

    @action.bound
    setMessageAndShowSnackbar(msg) {
        this.messageSnackbar = msg;
        this.openSnackbar = true;
    }

    @action.bound
    onOpenSnackbar() {
        this.openSnackbar = true;
    }

    @action.bound
    onCloseSnackbar() {
        this.openSnackbar = false;
    }

    @action.bound
    doResetImageFeed() {
        this.arrayImageFeed = [];
        this.arrayImageFeedReal = [];
    }

    @action.bound
    doSetImageUpload(image) {
        this.arrayImageFeed.push(image);
    }
    @action.bound
    doSetImageUploadReal(image) {
        this.arrayImageFeedReal.push(image);
    }

    @action.bound
    doRemoveImageFeed(image) {
        _.remove(this.arrayImageFeed,(item) => {
            return item == image;
        })
        _.remove(this.arrayImageFeedReal,(item) => {
            return item == image;
        })
    }

    @action.bound
    doSetMainTab(tab) {
        this.mainTab = tab;
    }

    @action.bound
    doSetTabMainTop(tab) {
        this.tabMainTop = tab;
    }

    @action.bound
    doSetTeamSexTab(tab) {
        this.teamSexTab = tab;
    }

    //get confirm pass
    @action.bound
    async doConfirmPass(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPI(API.GLOBAL.AUTH_CONFIRM, params, HTTP_METHOD.GET)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = resp;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get list team
    @action.bound
    doGetAppVersion(params) {
        requestAPIV2(API.GLOBAL.GET_VERSION, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.appVersion = resp.data;
                    //this.appVersion.pgsz = 10;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //upload file
    @action.bound
    async doUploadFile(params, file) {
        let result = null;
        this.loading = true;
        await uploadFile(API.GLOBAL.UPLOAD_IMG, params, file, HTTP_METHOD.POST)
            .then((resp) => {
                if(resp != undefined && resp.hasOwnProperty('url')) {
                    result = resp.url;
                }
                else result = null;
                this.loading = false;
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doUserFeedback(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.FEEDBACK, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }
}