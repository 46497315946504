import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const DiscussEvent = inject('authStore')(
    observer((props) => {

        useEffect(() => {}, []);

        return (
            <div>Diss</div>
        );
    })
);

export default DiscussEvent;