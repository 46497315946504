let apiUrl, apiUrl1, apiKey, facebookAppId, googleClientId;
let stravaClientId, stravaClientSecret, stravaScope;
let garminId, garminSecret;
let urlRedirect, siteDomain, baseName;
let appClientVersion;

if (process.env.REACT_APP_ENV === 'development') {
    apiUrl = 'https://cloud.uprace.vn/';
    apiUrl1 = 'https://cloud.uprace.vn/api/';
    baseName = ''
    apiKey = 'H0esX2ExBeZp8RbIzF4oudnoVJVZbD8R';
    urlRedirect = 'http://localhost:3000/';
    facebookAppId = 295199698192294;
    googleClientId = '260143420906-8nu7aae0pbeh95iu9edhqsch3ti1qrhs.apps.googleusercontent.com';

    stravaClientId = "49384";
    stravaClientSecret = "41c0a7225d20019b82207d1778c40c4095e03774";
    garminId = 'bdfd973f-aead-46ba-9788-a5fb1ff7d240';
    garminSecret = 'd5Asq9o8b12QjKsLPSHhp6RFO9lf7fUJSGZ';
    siteDomain = 'http://localhost:3000/'
    appClientVersion = 977

} else if (process.env.REACT_APP_ENV === 'staging') {
    apiUrl = 'https://cloud.uprace.vn/';
    apiUrl1 = 'https://cloud.uprace.vn/api/';
    baseName = ''
    apiKey = 'H0esX2ExBeZp8RbIzF4oudnoVJVZbD8R';
    //urlRedirect = 'https://web-dev.uprace.vn/';
    urlRedirect = 'https://dev.uprace.vn/';
    facebookAppId = 486135881830248;
    googleClientId = '766569433974-qpcmei1dv48frci8f2ml6s0vl24n5nsj.apps.googleusercontent.com';

    stravaClientId = "26496";
    stravaClientSecret = "06db3de6b974347549b2d27e3c4794dd05e3f811";
    garminId = 'bdf475df-9e7a-4123-a6cc-c8be244ac478';
    garminSecret = '4bL5AcXTx166t0QKVkdQJF45VmAyqsKAOGn';
    siteDomain = 'https://dev.uprace.vn/'
    appClientVersion = 977
} else if (process.env.REACT_APP_ENV === 'production') {
    apiUrl = 'https://api.uprace.vn/';
    apiUrl1 = 'https://api.uprace.vn/api/';
    baseName = ''
    apiKey = 'H0esX2ExBeZp8RbIzF4oudnoVJVZbD8R';
    urlRedirect = 'https://event.uprace.org/';
    facebookAppId = 486135881830248;
    googleClientId = '766569433974-qpcmei1dv48frci8f2ml6s0vl24n5nsj.apps.googleusercontent.com';

    stravaClientId = "26496";
    stravaClientSecret = "06db3de6b974347549b2d27e3c4794dd05e3f811";
    garminId = 'bdf475df-9e7a-4123-a6cc-c8be244ac478';
    garminSecret = '4bL5AcXTx166t0QKVkdQJF45VmAyqsKAOGn';
    siteDomain = 'https://event.uprace.org/';
    appClientVersion = 1253
}

module.exports = {
    API: {
        AUTHEN: {
            LOGIN: apiUrl + 'auth/loginw',
            LOGOUT: apiUrl + 'auth/logoutw',
            REGISTER: apiUrl + 'auth/registerw',
            FORGOT_PASSWORD: apiUrl + 'auth/forgot',
            CAPTCHA: apiUrl + 'auth/captcha',
            VERIFY: apiUrl + 'auth/verifyw',
            CERT: apiUrl + 'auth/cert',
        },
        TEAM: {
            LIST: apiUrl1 + 'team/list',
            DETAIL: apiUrl1 + 'team/details',
            UPDATE_TEAM: apiUrl1 + 'event/update-team',
            REQUEST_JOIN: apiUrl1 + 'team/request-join',
            GET_USERS_TEAM: apiUrl1 + 'team/users',
            STATISTICS: apiUrl1 + 'team/statistics',
            UPDATE: apiUrl1 + 'team/update',
            CREATE: apiUrl1 + 'team/create',
            INVITE_FRIEND: apiUrl1 + 'event/invite-team',
        },
        GROUP: {
            LIST: apiUrl1 + 'event/group/list',
            CREATE: apiUrl1 + 'event/group/create',
            UPDATE: apiUrl1 + 'event/group/update',
            REQUEST_JOIN: apiUrl1 + 'event/group/request-join',
            DO_JOIN: apiUrl1 + 'event/group/do-join',
            DETAIL: apiUrl1 + 'event/group/details',
            DELETE: apiUrl1 + 'event/group/delete',
            APPROVE_ALL: apiUrl1 + 'event/group/approve-all',
            APPROVE_ALL_MEMBER: apiUrl1 + 'event/group/member/approve-all'
        },
        POST: {
            BASIC: apiUrl1 + 'post/basic',
            DETAIL: apiUrl1 + 'post/details',
            CREATE: apiUrl1 + 'post/create',
            UPDATE: apiUrl1 + 'post/update',
            DELETE: apiUrl1 + 'post/delete',
            LIST: apiUrl1 + 'feed/post/list',
            PIN: apiUrl1 + 'post/mark',
            LIST_COMMENT: apiUrl1 + 'post/comment/list',
            WRITE_COMMENT: apiUrl1 + 'post/comment',
            LIST_LIKE: apiUrl1 + 'post/like/list',
            DO_LIKE: apiUrl1 + 'post/like'
        },
        FEED: {
            DO_LIKE: apiUrl1 + 'activity/like',
            ACTION: apiUrl1 + 'event/report/action',
            WRITE_COMMENT: apiUrl1 + 'activity/comment',
        },
        USER: {
            GET_PROFILE: apiUrl1 + 'user/profile/details',
            GET_PROFILE_BASIC: apiUrl1 + 'user/profile/basic',
            RANK_ME: apiUrl1 + 'event/rank/me',
            FEED_LIST: apiUrl1 + 'feed/list',
            FEED_DETAIL: apiUrl1 + 'activity/details',
            GET_POLYLINE: apiUrl + 'activity/polyline',
            LIST_COMMENT: apiUrl1 + 'feed/comment/list',
            LIST_LIKE: apiUrl1 + 'feed/like/list',
            DELETE_ACTIVITY: apiUrl + 'activity/delete',
            UPDATE_ACTIVITY: apiUrl + 'activity/update',
            DO_FOLLOW: apiUrl1 + 'user/do-follow',
            DO_LOCK: apiUrl1 + 'event/lock/user',
            SEARCH: apiUrl1 + 'user/search',
            CHANGE_PASS: apiUrl + 'auth/changepwdw',
            UPDATE_PROFILE: apiUrl1 + 'user/profile/update',
            STATISTICS: apiUrl1 + 'user/statistics',
            FEEDBACK: apiUrl1 + 'app/feedback',
            GET_TOP: apiUrl1 + 'user/top'
        },
        LEADERBOARD: {
            GET_LIST: apiUrl1 + 'event/rank/list',
            ADD_MEMBER: apiUrl1 + 'crank/member/add',
            REMOVE_MEMBER: apiUrl1 + 'crank/member/remove',
            CREATE: apiUrl1 + 'crank/profile/create',
            UPDATE: apiUrl1 + 'crank/profile/update',
            DETAIL: apiUrl1 + 'crank/profile/details',
            LEAVE: apiUrl1 + 'crank/member/leave',
            DELETE: apiUrl1 + 'crank/profile/delete',
        },
        EVENT: {
            STATISTICS: apiUrl1 + 'event/stats',
            GET_DETAIL: apiUrl1 + 'event/details',
            GET_LIST: apiUrl1 + 'event/list',
            LIST_BANNER: apiUrl1 + 'event/banner/list',
            UPDATE_FUND: apiUrl1 + 'event/update-fund',
            ADD_COMPARE: apiUrl1 + 'event/compare/add',
            REMOVE_COMPARE: apiUrl1 + 'event/compare/remove',
            UPDATE_COMPARE: apiUrl1 + 'event/compare/update',
            SUGGEST: apiUrl1 + 'event/suggest',
            DISMISS: apiUrl1 + 'event/dismiss',
            CREATE: apiUrl1 + 'event/create',
            UPDATE: apiUrl1 + 'event/update',
            DELETE: apiUrl1 + 'event/delete',
            REQUEST_JOIN: apiUrl1 + 'event/request-join',
            APPROVE_ALL: apiUrl1 + 'event/approve-all',
            LIST_DONATE: apiUrl1 + 'event/user/donation/getting',
        },
        CHALLENGE: {
            GET_LIST: apiUrl1 + 'feed/target/list',
            CREATE: apiUrl1 + 'target/create',
            UPDATE: apiUrl1 + 'target/update',
            DELETE: apiUrl1 + 'target/delete',
            DETAIL: apiUrl1 + 'target/details',
            LIST_COMMENT: apiUrl1 + 'target/comment/list',
            LIST_LIKE: apiUrl1 + 'target/like/list'
        },
        GLOBAL: {
            GET_VERSION: apiUrl1 + 'app/version',
            UPLOAD_IMG: apiUrl + 'webhook/upload',
            AUTH_CONFIRM: apiUrl + 'auth/confirm',
            SITE_DOMAIN: siteDomain,
            APP_BASENAME: baseName,
            APP_CLIENT_VERSION: appClientVersion
        },
        DEVICE_CONNECT: {
            STRAVA_AUTHORIZE: 'https://www.strava.com/oauth/authorize',
            STRAVA_GET_TOKEN: 'https://www.strava.com/oauth/token',
            STRAVA_CONNECT_API: apiUrl + 'auth/connect',
            GARMIN_GET_TOKEN: apiUrl + 'auth/garmin',
            GARMIN_CONNECT_API: apiUrl + 'auth/garminid',
            URL_REDIRECT: urlRedirect
        },
        CHART: {
            OTHER_EVENT_COMPARE: apiUrl1 + 'event/compare/other-event',
            OTHER_MEMBER_COMPARE: apiUrl1 + 'event/compare/other-members',
        },
        BANNER: {
            GET_LIST: apiUrl1 + 'banner/list'
        },
        JSON_LINK: {
            LIST_SPONSORS: siteDomain + 'data/sponsors.json',
            NEWS: siteDomain + 'data/news.json',
            NEWS_2022: siteDomain + 'data/news_2022.json',
            COMPANY: siteDomain + 'data/company.json',
            PEOPLE_SAY: siteDomain + 'data/peoplesay.json',
            BANNER: siteDomain + 'data/banner.json',
            ERROR_ACTIVITY: siteDomain + 'data/error_activity.json'
        },
        DOWNLOAD_LINK: {
            PROPOSAL: siteDomain + 'data/downloads/uprace_proposal_2021[param]',
        }
    },
    API_STATUS: {
        FETCH_SUCCESS: 1,
        NEED_INFO: 2,
        FETCH_ERROR: 0,
        NO_ERROR: 0,
        NOT_CREATE_USER: 2000
    },
    HTTP_STATUS: {
        OK: 200,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        TOKEN_INVALID: 403
    },
    HTTP_METHOD: {
        POST: 'post',
        GET: 'get',
        DELETE: 'delete',
        PUT: 'put'
    },
    PRIVATE_KEY: {
        FACEBOOK_APPID: facebookAppId,
        GOOGLE_CLIENT_ID: googleClientId,
        STRAVA_CLIENT_ID: stravaClientId,
        STRAVA_CLIENT_SECRET: stravaClientSecret,
        STRAVA_SCOPE: stravaScope,
        GARMIN_ID: garminId,
        GARMIN_SECRET: garminSecret,
    },
    KEY: {
        CURRENT_USER: 'curentUser',
        CURRENT_EVENT: 'currentEvent',
        ID_PROFILE_USER: 'idProfileUser',
        STRAVA_USER: 'stravaUser',
        USER_PROFILE: 'userProfile',
        USER_LANGUAGE: 'userLanguage',
    }
};
