import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import UserActivity from './UserActivity';
import SOUserInfo from './SOUserInfo';
import ListActivity from './ListActivity';
import AchievementPersonal from './AchievementPersonal';

const DialogUserEvent = inject(
    'globalStore',
    'teamStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { openDialogUserEvent, profileData, eventID, teamID } = props;
       
        return (
            <Dialog open={openDialogUserEvent}>
                <div
                    class="uprace-popup in"
                    id="yours-view"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => props.onClose()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div class="popup-body">
                                <div class="popup-yours-view">
                                    <UserActivity profileData={profileData} eventID={eventID}  />
                                    <SOUserInfo profileData={profileData} eventID={eventID} />
                                    <AchievementPersonal profileData={profileData} eventID={eventID} />
                                    <ListActivity profileData={profileData} eventID={eventID} teamID={teamID} />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogUserEvent;
