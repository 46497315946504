import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../utils';
import { humanTime } from '../../../../helpers/ConvertHelper';
import { useTranslation } from 'react-i18next';
import { MemberName } from 'components/MemberName';

const CommentItem = inject(
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;
        const { language } = props.globalStore;
        const { t } = useTranslation();

        if (data != null)
            return (
                <div className="mess-block">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div className="mess-details">
                        <div className="mess-box">
                            <p className="name">{MemberName(data)}</p>
                            <p className="mess">{data.cmt}</p>
                        </div>
                        <div className="d-row">
                            <p className="date">{humanTime(data.cmtm, language)}</p>
                        </div>
                    </div>
                </div>
            );
        else return null;
    })
);

export default CommentItem;
