import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MemberJoinedItem from './MemberJoinedItem';
import { formatNumber, stripedHtml } from '../../../../../utils';
import _ from 'lodash';
import DeleteMemberDialog from './DeleteMemberDialog';

let memberData = null;

const ListMemberJoined = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { t } = useTranslation();
        const { data, eventID, teamID } = props;
        const {
            loadingGroup,
            ownerGroup,
            listMemberJoined,
            currentGroup,
            totalMemberJoined,
            doSetOwnerGroupInfo,
            doGetListMemberJoined
        } = props.groupStore;
        const { userProfile } = props.userStore;

        const isShowLoadMore = listMemberJoined == null ? false : listMemberJoined.length < totalMemberJoined;

        const [openDialogDeleteMember, setOpenDialogDeleteMember] = React.useState(false);
        const [name, setName] = React.useState('');

        const getListMemberJoined = (isLoadMore) => {
            const params = {
                uid: userProfile.uid,
                tmid: teamID,
                evid: eventID,
                grid: data.grid,
                name
            };
            doGetListMemberJoined(params, isLoadMore);
        };

        useEffect(() => {
            getListMemberJoined();
        }, []);

        useEffect(() => {
            getOwnerGroup();
        }, [listMemberJoined]);

        function getOwnerGroup() {
            if (!listMemberJoined) return;
            const obj = _.find(listMemberJoined, (item) => {
                return item.uid == currentGroup.owner;
            });
            if (obj) doSetOwnerGroupInfo(obj);
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getListMemberJoined();
            }
        };

        const getMemberJoinedMore = () => {
            getListMemberJoined(true);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberJoinedMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        const onDeleteMember = (data) => {
            memberData = data;
            setOpenDialogDeleteMember(true);
        }

        return (
            <div className="members-joined">
                <div className="m-search">
                    <button className="del-btn"></button>
                    <input
                        type="text"
                        placeholder={t('global.search_by_name')}
                        value={name}
                        onKeyDown={handleKeyDown}
                        onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                    />
                </div>
                <div className="m-scroll">
                    <h3 className="m-title">{t('global.group_leader')}</h3>
                    {ownerGroup ? <MemberJoinedItem data={ownerGroup} key={ownerGroup.uid} owner={true} /> : null}
                    <h3 className="m-title">
                        {t('global.list_member')} ({formatNumber(totalMemberJoined)})
                    </h3>
                    <div className="m-list-scroll">
                        <ul className="m-list">
                            {listMemberJoined &&
                                listMemberJoined.map((item) => {
                                    return <MemberJoinedItem data={item} onDeleteMember={onDeleteMember} key={item.uid} />;
                                })}
                        </ul>
                    </div>

                    <LoadMore />
                </div>
                {memberData ? (
                    <DeleteMemberDialog
                        openDialogDeleteMember={openDialogDeleteMember}
                        onClose={() => setOpenDialogDeleteMember(false)}
                        data={memberData}></DeleteMemberDialog>
                ) : null}
            </div>
        );
    })
);

export default ListMemberJoined;
