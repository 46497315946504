import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import TeamInfoTab from './TeamInfoTab';
import TabHeader from './TabHeader';
import TeamDescription from './TeamDescription';
import NotificationTeam from './NotificationTeam';
import InviteFriendDialog from '../InviteFriendDialog';
import CreatePostDialog from '../CreatePostDialog';
import ManageGroup from '../ManageGroup';

const TeamDetail = inject(
    'authStore',
    'userStore',
    'teamManageStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { tabMain, doSetMainTab } = props.teamManageStore;

        const onChangeTab = (tab) => {
            doSetMainTab(tab);
        };

        return (
            <div className="team-details-page">
                <TabHeader onChangeTab={onChangeTab} eventID={eventID} teamID={teamID} />
                <div className="team-details--ctn tabs-content">
                    <ul>
                        <li className="active">
                            {tabMain == 0 ? (
                                <div className="team-details--info clearfix">
                                    <div className="right-ctn">
                                        <TeamDescription eventID={eventID} teamID={teamID} />
                                        <NotificationTeam eventID={eventID} teamID={teamID} />
                                    </div>
                                    <TeamInfoTab eventID={eventID} teamID={teamID} />
                                </div>
                            ) : (
                                <ManageGroup eventID={eventID} teamID={teamID} />
                            )}
                        </li>
                    </ul>
                </div>
                {userProfile ? <InviteFriendDialog eventID={eventID} teamID={teamID} /> : null}
                {userProfile ? <CreatePostDialog eventID={eventID} teamID={teamID} /> : null}
            </div>
        );
    })
);

export default TeamDetail;
