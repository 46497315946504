import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { Topbar, Footer, Banner } from './components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Main = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { children } = props;
        const showBanner = children.props.location.pathname != '/comming-soon';

        const [openSidebar, setOpenSidebar] = useState(false);
        const { messageSnackbar, onCloseSnackbar, openSnackbar, snackbarType } = props.globalStore;

        const handleSidebarOpen = () => {
            setOpenSidebar(true);
        };

        const navigationMain = (tab) => {};

        return (
            <>
                <Helmet>
                   
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css"
                    />
                    <script src="https://img.zing.vn/products/uprace/skin-2021/dist/main/main.js"></script>
                    <script
                        src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js"
                        integrity="sha512-uURl+ZXMBrF4AwGaWmEetzrd+J5/8NRkWAvJx5sbPSSuOb0bZLqf+tOzniObO00BjHa/dD7gub9oCGMLPQHtQA=="
                        crossorigin="anonymous"
                        referrerpolicy="no-referrer"></script>
                </Helmet>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={onCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={1500}>
                    <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>
                <div className="outer">
                    <div id="scrollContent" className="inner scrollContent scrollSwipe">
                        <main>
                            {showBanner ? <Banner /> : null}
                            {children}
                            <Footer />
                        </main>
                    </div>
                </div>
                <p id="back-top" className="back-top">
                    <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                </p>
                <Topbar history={children} navigationMain={(tab) => navigationMain(tab)} />
            </>
        );
    })
);

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
