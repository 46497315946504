import React from 'react';
import { inject, observer } from 'mobx-react';
import { TEAM_TYPE } from '../../../../data/master';
import { LEADERBOARD_TYPE } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const TeamCategoty = inject(
    'authStore',
    'leaderboardStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { naviTab, setNavigationTab, doSetNameFilter, doGetListLeaderboardTeam } = props.leaderboardStore;

        const { t } = useTranslation();

        const onChangeNavigationTab = (category) => {
            if (category.id == -1) {
                setNavigationTab(category);
            } else {
                setNavigationTab(category);
                getLeaderboardTeam(category);
                doSetNameFilter(''); //reset name filter trong BXH
                //props.onChangeTab();
            }
        };

        function getLeaderboardTeam(category) {
            const data = {
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                value: category.id
            };
            doGetListLeaderboardTeam(data);
        }

        function MenuItem(props) {
            const { data } = props;
            const cssClass = data.id == naviTab.id ? 'active' : '';

            if (data.id == 0) return null;
            return (
                <li class={cssClass} onClick={() => onChangeNavigationTab(data)}>
                    <a href="javascript:void(0);">
                        <p className={data.icon}></p>
                        <p className="txt">{t(data.title)}</p>
                    </a>
                </li>
            );
        }

        return (
            <div class="top-bxh-menu">
                <ul>
                    {TEAM_TYPE.map((item) => (
                        <MenuItem key={item.id} data={item} />
                    ))}
                </ul>
            </div>
        );
    })
);

export default TeamCategoty;
