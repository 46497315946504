import React, { useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { KEY } from '../../../../helpers/api/APIDefined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AvatarItem from '../../../../../src/views/Common/AvatarItem';
import { HashLink } from 'react-router-hash-link';
import _ from 'lodash';
const queryString = require('query-string');

const CustomRouterLink = forwardRef((props, ref) => <RouterLink {...props} />);

const Topbar = inject(
    'authStore',
    'userStore',
    'globalStore',
    'teamStore',
    'userProfileStore',
    'eventStore'
)(
    observer((props) => {
        const { history } = props;
        const { tabMainTop, doSetTabMainTop, doSetReloadUserProfile } = props.globalStore;
        let { doSetProfileUserID, doSetReportType, doSetSportType } = props.userProfileStore;
        const { userProfile, doClearUserProfile } = props.userStore;
        const { doClearUserTeam } = props.teamStore;
        const { doLogout } = props.authStore;
        const { appVersion, doGetAppVersion } = props.globalStore;
        const { currentEvent, doGetEventData } = props.eventStore;

        let eventID;

        useEffect(() => {
            if (!appVersion) getAppVersion();
            //checkEventValid();
        }, []);

        async function checkEventValid() {
            if (currentEvent == null) {
                let paramsQuery = queryString.parse(history.props.location.search);
                if (_.isEmpty(paramsQuery)) history.props.history.push('/info?evid=20');
                eventID = paramsQuery.evid;
                const params = { evid: eventID };
                let result = await doGetEventData(params);
                if (result == null) history.props.history.push('/info?evid=20');
            } else eventID = currentEvent.evid;
            global.eventID = eventID;
        }

        function getAppVersion() {
            let params = {
                data: {}
            };
            doGetAppVersion(params);
        }

        let MENU_TOP = [
            { id: 0, link: '/info?evid=20', title: 'Sự kiện' },
            //{ id: 1, link: '/about-us', title: 'Về chúng tôi' },
            { id: 2, link: userProfile != null && !_.isEmpty(userProfile) ? '/search' : '/login', title: 'Tìm kiếm' }
        ];

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleLogout = () => {
            doClearUserProfile();
            doLogout();
            doClearUserTeam();
            setAnchorEl(null);
        };

        const closeMenu = () => {
            setAnchorEl(null);
        };

        const handleChangeTabMainTop = (tab) => {
            doSetTabMainTop(tab);
        };

        const goToProfile = () => {
            doSetReportType(0);
            doSetSportType(0);
            doSetProfileUserID(userProfile.uid);
            doSetReloadUserProfile();
            setAnchorEl(null);
        };

        function MenuItemTop(props) {
            const { category } = props;
            const cssClass = category.id == tabMainTop ? 'nav-item active' : 'nav-item';

            return (
                <li className={cssClass} onClick={() => handleChangeTabMainTop(category.id)}>
                    <HashLink to={category.link} className="nav-link">
                        {category.title}
                    </HashLink>
                </li>
            );
        }

        return (
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white">
                        <RouterLink to="/info?evid=20" className="navbar-brand">
                            <img src="../../img/logo/logo-blue.png" alt="logo-uprace" />
                        </RouterLink>
                        <button
                            className="navbar-toggler navbar-toggler-right"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navb">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}>
                            {userProfile ? (
                                <MenuItem onClick={goToProfile}>
                                    <RouterLink to={`/user-profile`} className="dropdown-item">
                                        <i className="fa fa-user"></i> Cá nhân
                                    </RouterLink>
                                </MenuItem>
                            ) : null}

                            <MenuItem onClick={closeMenu}>
                                <RouterLink to="/settings" className="dropdown-item">
                                    <i className="fa fa-cog"></i> Cài đặt
                                </RouterLink>
                            </MenuItem>

                            <MenuItem onClick={handleLogout}>
                                <RouterLink to="/info?evid=20" className="dropdown-item">
                                    <i className="fa fa-power-off"></i> Đăng xuất
                                </RouterLink>
                            </MenuItem>
                        </Menu>

                        <div className="collapse navbar-collapse" id="navb">
                            <ul className="navbar-nav mr-auto">
                                {MENU_TOP.map((cat) => (
                                    <MenuItemTop key={cat.id} category={cat} />
                                ))}
                            </ul>
                        </div>

                        <div className="wrapper-right-nav">
                            {userProfile != null && appVersion != null && !_.isEmpty(userProfile) ? (
                                <ul className="navbar-nav ">
                                    <li className="nav-item dropdown" onClick={handleOpenMenu}>
                                        <a
                                            className="nav-link dropdown-toggle nav-link-profile"
                                            id="navDropDownProfileLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <AvatarItem data={userProfile.ava} />
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navDropDownProfileLink"></div>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="navbar-nav ">
                                    <li className="nav-item ">
                                        <div className="wrapper-btn">
                                            <a href="/login" className="btn btn-primary btn-log-in" role="button">
                                                Đăng nhập
                                            </a>
                                            <a href="/register" className="btn btn-primary btn-register" role="button">
                                                Đăng ký
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </nav>
                </header>
            </div>
        );
    })
);

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};

export default Topbar;
