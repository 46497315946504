import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import mapboxgl from 'mapbox-gl';
import { toJS } from 'mobx';
import { Carousel } from 'react-responsive-carousel';
import { MAPBOX_TYPE } from '../../../../helpers/ConstDefined';

const accessToken = 'pk.eyJ1IjoidnVuZDIiLCJhIjoiY2tjZDV1cjcyMGJmNTJycHF0YnNpdXl4byJ9.slmR0ZnjFREMphnQoC2rUg';

const styles = {
    width: '100%',
    height: 600
};

const DetailMap = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { feedPolyline, feedDetail } = props.userStore;
        const [map, setMap] = useState(null);
        const mapContainer = useRef(null);

        /*useEffect(() => {
            if (
                feedPolyline == null ||
                !feedPolyline.hasOwnProperty('geometry') ||
                feedPolyline.geometry.type == 'Point'
            )
                return;
            let pointCenter;

            if (feedPolyline.geometry.type == MAPBOX_TYPE.LINE_STRING)
                pointCenter = feedPolyline.geometry.coordinates[0];
            else pointCenter = feedPolyline.geometry.coordinates[0][0];

            console.log('feedPolyline', pointCenter);

            mapboxgl.accessToken = accessToken;
            const initializeMap = ({ setMap, mapContainer }) => {
                const map = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
                    center: pointCenter, //feedPolyline.geometry.coordinates[0],
                    zoom: 12
                });

                map.addControl(new mapboxgl.NavigationControl());

                map.on('load', () => {
                    map.addSource('lines', {
                        type: 'geojson',
                        data: feedPolyline
                    });
                    map.addLayer({
                        id: 'lines',
                        type: 'line',
                        source: 'lines',
                        paint: {
                            'line-width': 3,
                            // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
                            // to set the line-color to a feature property value.
                            'line-color': '#f44500' //['get', 'color']
                        }
                    });
                    var marker = new mapboxgl.Marker().setLngLat(pointCenter).addTo(map);
                    setMap(map);
                    map.resize();
                });
            };
            if (!map) {
                initializeMap({ setMap, mapContainer });
            }
        }, []);*/

        const imgMap = feedDetail.img.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg');

        return (
            <div className="slick-map">
                <div id="demo" className="carousel slide image-story" data-ride="carousel">
                    <img className="map-slide-img" src={imgMap} />
                </div>
            </div>
        );
    })
);

export default DetailMap;
