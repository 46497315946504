import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { getUUID, getTimestamp } from '../../../../utils';
import { SOCIAL_TAB } from '../../../../helpers/ConstDefined';
import SocialList from './SocialList';

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 700
    }
}));

const DialogSocial = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { openDialogSocial, data, curTab } = props;
        const [tab, setTab] = React.useState(props.globalStore.socialTab);
        const { socialTab, doSetSocialTab } = props.globalStore;

        const handleChangeTab = (tab) => {
            setTab(tab);
            doSetSocialTab(tab);
        };

        const Tabs = [
            { id: SOCIAL_TAB.LIKE, img: '../../../img/icon-like.svg', val: data != null ? data.lkcnt : 0 },
            { id: SOCIAL_TAB.DISLIKE, img: '../../../img/icon-dislike.svg', val: data != null ? data.dlkcnt : 0 },
            { id: SOCIAL_TAB.COMMENT, img: '../../../img/icon-comment.svg', val: data != null ? data.cmcnt : 0 }
        ];

        function MenuItem(props) {
            const { category } = props;
            const cssClass = category.id == socialTab ? 'nav-item nav-link active' : 'nav-item nav-link';

            return (
                <a
                    onClick={() => handleChangeTab(category.id)}
                    className={cssClass}
                    id="nav-home-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true">
                    <img className="icon-tab-action" src={category.img} alt="Like" />
                    <p id="count-like" className="count-like">
                        {category.val}
                    </p>
                </a>
            );
        }

        if (data != null)
            return (
                <Dialog
                    open={openDialogSocial}
                    onClose={props.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div
                        className="modal custom-modal modal-delete-activate modal-confirm-join-team"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                        style={{ display: 'block' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close custom-close"
                                        data-dismiss="modal"
                                        onClick={props.onClose}
                                        aria-label="Close">
                                        <img src="../../../img/icon-close-2.png" alt="image" />
                                    </button>
                                </div>
                                <div className="modal-body content-tab">
                                    <nav className="nav-head">
                                        <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                            {Tabs.map((cat) => (
                                                <MenuItem key={cat.id} category={cat} />
                                            ))}
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <SocialList tabType={socialTab} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            );
        else return null;
    })
);

export default DialogSocial;
