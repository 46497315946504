import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

import { Provider } from 'mobx-react';

import GlobalStore from './stores/globalStore';
import AuthStore from './stores/authStore';
import TeamStore from './stores/teamStore';
import UserStore from './stores/userStore';
import LeaderboardStore from './stores/leaderboardStore';
import EventStore from './stores/eventStore';
import ChallengeStore from './stores/challengeStore';
import NewsStore from 'stores/newsStore';
import ConnectStore from 'stores/connectStore';
import UserProfileStore from 'stores/userProfileStore';
import UserLeaderboardStore from 'stores/userLeaderboardStore';
import ChartStore from 'stores/chartStore';
import TeamManageStore from 'stores/teamManageStore';
import PostStore from 'stores/postStore';
import GroupStore from 'stores/groupStore';
import ReportStore from 'stores/reportStore';
import InviteFriendStore from 'stores/inviteFriendStore';
import UserEventStore from 'stores/userEventStore';

import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

let globalStore = new GlobalStore();
let authStore = new AuthStore();
let teamStore = new TeamStore();
let userStore = new UserStore();
let leaderboardStore = new LeaderboardStore();
let eventStore = new EventStore();
let challengeStore = new ChallengeStore();
let newsStore = new NewsStore();
let connectStore = new ConnectStore();
let userProfileStore = new UserProfileStore();
let userLeaderboardStore = new UserLeaderboardStore();
let chartStore = new ChartStore();
let teamManageStore = new TeamManageStore();
let postStore = new PostStore();
let groupStore = new GroupStore();
let reportStore = new ReportStore();
let inviteFriendStore = new InviteFriendStore();
let userEventStore = new UserEventStore();

const stores = {
    globalStore,
    authStore,
    teamStore,
    userStore,
    eventStore,
    leaderboardStore,
    challengeStore,
    newsStore,
    reportStore,
    connectStore,
    userProfileStore,
    userLeaderboardStore,
    chartStore,
    groupStore,
    teamManageStore,
    postStore,
    inviteFriendStore,
    userEventStore,
};

//disable console log in staging and production env
if (process.env.REACT_APP_ENV !== 'development') console.log = () => {};

i18next.init({
    interpolation: { escapeValue: false } // React already does escaping
});

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
