import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import CropImageDialog from './CropImageDialog';
import { getImageResource, stripedHtml } from '../../../../../utils';
import { TEAM_TYPE, SPORT_TYPE } from '../../../../../data/master';

const EditTeamDialog = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { openDialogEditTeam } = props;
        const { currentTeam, doUpdateTeam } = props.teamStore;
        const { userProfile } = props.userStore;
        const [imageSrc, setImageSrc] = React.useState(currentTeam && currentTeam.ava);
        const [openDialogCropImage, setOpenDialogCropImage] = useState(false);
        const { doUploadFile, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [sportTab, setSportTab] = React.useState(currentTeam.sport);
        const [clubTab, setClubTab] = React.useState(currentTeam.lgid);
        const [name, setName] = React.useState(currentTeam && currentTeam.name);
        const [desc, setDesc] = React.useState(currentTeam && currentTeam.desc);

        const [avaTeam, setAvaTeam] = React.useState(currentTeam && currentTeam.ava);
        const [avaTeamLocal, setAvaTeamLocal] = React.useState(currentTeam && currentTeam.ava); //file not ready in server
        const [coverTeam, setCoverTeam] = React.useState(currentTeam.cover);
        const [coverTeamLocal, setCoverTeamLocal] = React.useState(currentTeam.cover);

        const [errName, setErrName] = useState(false);
        const [errDesc, setErrDesc] = useState(false);
        const [errAva, setErrAva] = useState(false);

        const isInactive = !name || name.trim().length == 0 || !desc || desc.trim().length == 0;
        const cssButton = isInactive ? 'btn-44 blue inactive' : 'btn-44 blue';

        useEffect(() => {}, []);

        const { t } = useTranslation();

        function validInput() {
            let hasError = false;

            if (!name || name.trim().length == 0 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (avaTeam == null) {
                setErrAva(true);
                hasError = true;
            } else setErrAva(false);

            if (!desc || desc.trim().length == 0) {
                setErrDesc(true);
                hasError = true;
            } else setErrDesc(false);

            return hasError;
        }

        async function updateTeam() {
            if (!validInput()) {
                const params = {
                    uid: userProfile.uid,
                    tmid: currentTeam.tmid,
                    name,
                    desc,
                    ava: avaTeam,
                    cover: coverTeam,
                    lgid: clubTab,
                    sport: sportTab
                };
                let result = await doUpdateTeam(params);
                if (result == true) {
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    props.onClose();
                } else {
                    //setMessageSnackbar(t('global.action.fail'));
                    setMessageSnackbar(result.desc);
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        async function uploadFileServer(file, type) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: type
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                if (type == 0) {
                    //upload ava
                    setAvaTeam(result);
                    setAvaTeamLocal(URL.createObjectURL(file));
                } else if (type == 2) {
                    //upload cover
                    setCoverTeam(result);
                    setCoverTeamLocal(URL.createObjectURL(file)); //file upload not ready
                }
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function readFile(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }

        async function uploadCoverTeam(e) {
            uploadFileServer(e.target.files[0], 2);
        }

        const onFileChange = async (e) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                let imageDataUrl = await readFile(file);
                setImageSrc(imageDataUrl);
                setOpenDialogCropImage(true);
            } else {
                //not select file
            }
        };

        const onCropSuccess = (file) => {
            uploadFileServer(file, 0);
        };

        function MenuSportItem(props) {
            const { category } = props;
            const cssClass = category.id == sportTab ? 'active' : '';

            if (category.id != 0 && category.id != 3 && category.id != 6)
                return (
                    <li class={cssClass} onClick={() => setSportTab(category.id)}>
                        <a href="javascript:void(0);">{t(category.title)}</a>
                    </li>
                );
            else return null;
        }

        function MenuClubItem(props) {
            const { category } = props;
            const cssClass = category.id == clubTab ? 'active' : '';
            if(category.id == 0) return null;
            
            return (
                <li class={cssClass} onClick={() => setClubTab(category.id)}>
                    <a href="javascript:void(0);">{t(category.title)}</a>
                </li>
            );
        }

        const onCloseDialog = () => {
            props.onClose();
        };

        return (
            <>
                <Dialog open={openDialogEditTeam}>
                    <div
                        class="uprace-popup has-title in"
                        id="create-team"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div class="popup-dialog" role="document">
                            <div class="popup-content w600">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => onCloseDialog()}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 class="popup-title">{t('global.edit_info')}</h2>
                                <div class="popup-body">
                                    <div class="popup-create-group team">
                                        <div class="create-group-wrap">
                                            <div class="cover">
                                                <p class="img">
                                                    <button class="upload-btn">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name=""
                                                            class="file-upload-input"
                                                            onChange={uploadCoverTeam}
                                                        />
                                                    </button>
                                                    <img src={getImageResource(coverTeamLocal)} alt="" />
                                                </p>
                                                <div class="logo">
                                                    {!avaTeamLocal ? (
                                                        <p className="default"></p>
                                                    ) : (
                                                        <p className="img" style={{ display: 'block' }}>
                                                            <img src={getImageResource(avaTeamLocal, true)} alt="" />
                                                        </p>
                                                    )}
                                                    <button className="upload-btn">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name=""
                                                            className="file-upload-input"
                                                            onChange={onFileChange}
                                                        />
                                                    </button>
                                                </div>
                                                {errAva ? (
                                                    <p className="error-tag">{t('global.error.valid_image')}</p>
                                                ) : null}
                                            </div>
                                            <div class="frm-block">
                                                <div class="frm-name">
                                                    <button class="del-btn"></button>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        value={name}
                                                        maxLength={60}
                                                        onChange={(text) => setName(stripedHtml(text.target.value))}
                                                    />
                                                    <label>{t('global.team_name')}</label>
                                                    {errName ? (
                                                        <p className="error-tag">{t('global.error.valid_name')}</p>
                                                    ) : null}
                                                </div>
                                                <div class="frm-ctn">
                                                    <button class="del-btn"></button>
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        cols=""
                                                        rows="1"
                                                        maxLength={1500}
                                                        value={desc}
                                                        onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                        placeholder={t('team.group.createdl.desc.tip')}></textarea>
                                                    {errDesc ? (
                                                        <p className="error-tag">{t('global.error.valid_desc')}</p>
                                                    ) : null}
                                                </div>
                                                <div class="choose-box sport">
                                                    <p class="lb">{t('global.sport')}</p>
                                                    <ul class="list">
                                                        {SPORT_TYPE.map((item) => {
                                                            return <MenuSportItem category={item} key={item.id} />;
                                                        })}
                                                    </ul>
                                                </div>
                                                <div class="choose-box team">
                                                    <p class="lb">{t('global.team_type')}</p>
                                                    <ul class="list">
                                                        {TEAM_TYPE.map((item) => {
                                                            return <MenuClubItem category={item} key={item.id} />;
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="popup-btns col-1">
                                            <button class={cssButton} onClick={() => updateTeam()}>
                                                {t('global.edit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <CropImageDialog
                    openDialogCropImage={openDialogCropImage}
                    onCropSuccess={onCropSuccess}
                    imgSource={imageSrc}
                    onClose={() => setOpenDialogCropImage(false)}
                />
            </>
        );
    })
);

export default EditTeamDialog;
