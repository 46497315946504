import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var FileSaver = require('file-saver');

const ViewCertDialog = inject(
    'userStore',
    'globalStore',
    'eventStore',
    'authStore'
)(
    observer((props) => {
        const { openDialogViewCert } = props;
        const { eventUserStats } = props.eventStore;
        const { userProfile } = props.userStore;

        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const onSaveFile = (file) => {
            FileSaver.saveAs(file);
        };

        const onCloseDialog = () => {
            props.onClose();
        };

        if (eventUserStats && eventUserStats.certsts == 4)
            return (
                <Dialog open={openDialogViewCert}>
                    <div
                        class="uprace-popup has-title in"
                        id="certificate"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div class="popup-dialog" role="document">
                            <div class="popup-content w420">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 class="popup-title">{t('global.cert')}</h2>
                                <div class="popup-body">
                                    <div class="popup-certificate">
                                        <p class="img">
                                            <img src={eventUserStats.certlink} alt="" />
                                        </p>
                                        {eventUserStats.certlink ? (
                                            <ul class="btn-list">
                                                <li>
                                                    <button class="download" onClick={() => onSaveFile(eventUserStats.certlink)}>
                                                        {t('global.download')}
                                                    </button>
                                                </li>

                                                <li>
                                                    <CopyToClipboard text={eventUserStats.certlink} onCopy={() => onCopySuccess()}>
                                                        <button class="share">{t('global.share_now')}</button>
                                                    </CopyToClipboard>
                                                </li>
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            );
        else return null;
    })
);

export default ViewCertDialog;
