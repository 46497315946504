import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CreatePostDialog from '../CreatePostDialog';

const PostEmpty = inject(
    'postStore',
    'userStore',
    'teamManageStore'
)(
    observer((props) => {
        const { isAdmin, eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { doGetListPost } = props.postStore;
        const { doSetFunc } = props.teamManageStore;

        const { t } = useTranslation();

        const [openDialogCreatePost, setOpenDialogCreatePost] = React.useState(false);

        const title = isAdmin ? t('global.no_post_title') : '';
        const desc = isAdmin ? t('global.no_post_desc') : t('global.no_post_title_user');

        const getListPost = () => {
            const params = {
                from: 0,
                feed: 2,
                uid: userProfile && userProfile.uid,
                sort: 7, //get bài post ghim lên đầu
                order: 1,
                value: teamID,
                evid: eventID
            };
            doGetListPost(params);
        };

        const onCloseDialog = () => {
            getListPost();
            setOpenDialogCreatePost(false);
        };

        return (
            <>
                {isAdmin ? (
                    <div class="manage-wbox">
                        <div class="manage-header">
                            <div class="manage-title-row">
                                <h2 class="manage-title-large" style={{ lineHeight: 0, fontSize: 20 }}>
                                    {t('team.manage.leader_noti')}
                                </h2>

                                <div class="groups-block" style={{ paddingBottom: 20 }}>
                                    <div class="groups-empty" style={{ padding: 0 }}>
                                        <div class="btn-row">
                                            <button class="btn-40 blue" onClick={() => doSetFunc(1)}>
                                                {t('team.manage.tab1')}
                                            </button>
                                            <button
                                                class="btn-40 blue-light"
                                                onClick={() => setOpenDialogCreatePost(true)}>
                                                <span class="btn-txt">{t('team.post.menu.add')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <CreatePostDialog
                            eventID={eventID}
                            teamID={teamID}
                            openDialogCreatePost={openDialogCreatePost}
                            createPostSucc={() => {}}
                            onClose={() => onCloseDialog()}
                        />
                    </div>
                ) : null}
                <div class="tabs-content manage-tabs-content">
                    <div class="manage-wbox waiting ">
                        <div class="groups-block">
                            <div class="groups-empty">
                                <p class="img">
                                    <img src="../../../images/team/imgs/1.png" alt="notification" />
                                </p>
                                <div class="groups-empty">
                                    <p class="title">{title}</p>
                                    <p class="note">{desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

export default PostEmpty;
