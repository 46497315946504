import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, getImageResource } from '../../../../utils';
import { EVENT_LIST } from '../../../../data/master';
import _ from 'lodash';
const stc = require('string-to-color');

const TopRank = inject(
    'authStore',
    'leaderboardStore',
    'eventStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { listRankTop } = props.leaderboardStore;
        const { currentEvent } = props.eventStore;

        useEffect(() => {}, []);

        const obj = _.find(EVENT_LIST, (item) => {
            return item.id == eventID;
        })

        let colorStr = eventID == 2 ? "#fff" : "#222831"

        if(currentEvent != null)
            return (
                <div className="view-rank" style={{ backgroundImage: `url(${obj && obj.leaderboardbg})` }}>
                    <img src={obj && obj.leaderboardbg} alt="view rank" />
                    <div className="rank-img" style={{ backgroundImage: `url(${obj && obj.lbdrank})` }}>
                        <img src={obj && obj.lbdrank} alt="" />
                        {listRankTop != null && listRankTop.length > 1 ? (
                            <div className="top top-2">
                                <div className="wrapper-avatar-member rectangle-avatar" style={{ backgroundImage: `url(${getImageResource(listRankTop[1].ava,true)})` }}>
                                    <img src={listRankTop[1].ava} alt="" />
                                </div>
                                <p className="name-top" style={{color: colorStr}}>{listRankTop[1].name}</p>
                                <p className="qty-top" style={{color: colorStr}}>{formatDistanceKM(listRankTop[1].dis)} Km</p>
                            </div>
                        ) : null}
                        {listRankTop != null && listRankTop.length > 0 ? (
                            <div className="top top-1">
                                <div className="wrapper-avatar-member rectangle-avatar" style={{ backgroundImage: `url(${getImageResource(listRankTop[0].ava,true)})` }}>
                                    <img src={listRankTop[0].ava} alt="" />
                                </div>
                                <p className="name-top" style={{color: colorStr}}>{listRankTop[0].name}</p>
                                <p className="qty-top" style={{color: colorStr}}>{formatDistanceKM(listRankTop[0].dis)} Km</p>
                            </div>
                        ) : null}
                        {listRankTop != null && listRankTop.length > 2 ? (
                            <div className="top top-3">
                                <div className="wrapper-avatar-member rectangle-avatar" style={{ backgroundImage: `url(${getImageResource(listRankTop[2].ava,true)})` }}>
                                    <img src={listRankTop[2].ava} alt="" />
                                </div>
                                <p className="name-top" style={{color: colorStr}}>{listRankTop[2].name}</p>
                                <p className="qty-top" style={{color: colorStr}}>{formatDistanceKM(listRankTop[2].dis)} Km</p>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        else return null;
    })
);

export default TopRank;
