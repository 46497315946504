import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const NotJoinEvent = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { t } = useTranslation();

        return (
            <div class="card card-details card-rank">
                <div class="card-details-body">
                    <div class="empty-box">
                        <div class="thumbnail">
                            <img src="../../../images/imgs/join_event.png" alt="Thumbnail" class="thumbnail-item" />
                        </div>
                        <div class="title">{t('eu.not_join_event')}</div>
                        <div class="txt">{t('eu.not_join_event_view_group')}</div>
                    </div>
                </div>
            </div>
        );
    })
);

export default NotJoinEvent;
