import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';

const DeletePostDialog = inject(
    'authStore',
    'postStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {}, []);

        const { data, openDialogDeletePost } = props;
        const { doDeletePost } = props.postStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function deletePost() {
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid
            };
            let result = await doDeletePost(params);
            if (result) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            props.onClose();
            onOpenSnackbar();
        }
        return (
            <Dialog open={openDialogDeletePost}>
                <div
                    className="uprace-popup"
                    id="delete-post"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <p className="name">{t('team.post.deletedl.title')}</p>
                                    <div className="txt">
                                        <p>{t('team.post.deletedl.desc')}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={() => deletePost()}>
                                            {t('team.post.deletedl.btok')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DeletePostDialog;
