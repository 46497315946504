import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const ENLang = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <section className="main-content">
                <div className="container">
                    <h3 className="title">COMPETITION RULES</h3>
                    <p>
                        <strong>Time for event registration opening: starting from 12:00am on November 06, 2020</strong>{' '}
                        Competition time: from 12:00am November 15, 2020 to 11:59pm December 06, 2020, runners can
                        continue to register and join Teams during the event.
                    </p>

                    <p>
                        <strong> - Competition rules:</strong>
                    </p>

                    <ul className="dot-list">
                        <li>
                            {' '}
                            + Runner, after successful account registration, will automatically participate in the
                            SeARun event. Runner can choose to compete for any team or independently.
                        </li>
                        <li>
                            + Recorded as completed and be displayed on the runner&#39;s account within 72 hours from
                            the starting time of the event and no later than the last day of the event.
                        </li>
                        <li>
                            + The running map must be expressed clearly, there is no sign of big errors due to the error
                            of catching GPS waves of mobile devices. The runs are imported manual results, either shared
                            by others, or recorded for different accounts will not be valid; In case of running on
                            treadmills, the result must be recorded by fitness tracker or smart watch like Garmin; which
                            is able to synchronize with Uprace app.
                        </li>
                        <li>
                            + Runs that are duplicated by recording from multiple devices at a same time will be counted
                            as one activity. The priority order of activity’s source will be: 1. Garmin; 2. Strava; 3.
                            UpRace.
                        </li>
                        <li>
                            + There is a minimum distance of 1km. There is an unlimited maximum limit. Players are
                            allowed to pause the running while running with the purpose of resting and distributing
                            power.
                        </li>
                        <li>
                            + Average Pace maximum is 4:00 minutes/km and minimum is 15:00 minutes/km. Split pace of
                            each 1 km should not be faster than 3:30 minutes/km
                        </li>
                        <li>
                            + Valid day (active day) the date of at least one valid run according to the above rule.
                        </li>
                        <li>
                            + Special days:
                            <ul className="dot-list">
                                <p>
                                    o SeARun Day: including Sundays (Nov 15-22-29) and Saturdays (Nov 28 & Dec 5) during
                                    the event period. Valid achievements of runners on this day will be multiplied by 2.
                                </p>
                                <p>
                                    o Triple Day: Vietnamese Teacher's Day (November 20) and last Sunday (Dec 6). Valid
                                    achievements of runners on this day will be multiplied by 3. Note: for Saturday and
                                    Sunday specials: runs that are valid on special days must be started on the day and
                                    ended by Sunday of that week (before 24:00) and be saved (uploaded) to the system by
                                    Monday of next week. The achievement will be calculated by the date the race
                                    started.
                                </p>
                            </ul>
                        </li>
                        <li>
                            + A runner will be certified to complete the contest when both of the following conditions
                            are reached: must achieve a minimum number of 8 valid days, and ust achieve a minimum
                            distance of 40km (including double achievement on Sundays, and triple achievement on
                            20/11/2020).
                        </li>
                    </ul>
                </div>
            </section>
        );
    })
);

export default ENLang;
