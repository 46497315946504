import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { ACTIVITY_TYPE, REPORT_ACTION } from 'helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const RadioAction = inject(
    'reportStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { userProfile, feedDetail } = props.userStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [radioValue, setRadioValue] = useState(null);
        const { isViewDetail, doAction, doSetViewDetail } = props.reportStore;

        const isActive = radioValue != null ? 'btn-40 blue' : 'btn-40 blue inactive';

        const MENUS = [
            {
                id: 0,
                title: 'global.this_act_valid'
            },
            {
                id: 1,
                title: 'global.this_act_invalid'
            }
        ];

        const { t } = useTranslation();

        async function processActivity() {
            let action;
            let type = isViewDetail;

            if (type == ACTIVITY_TYPE.COMPLAIN) {
                action = radioValue == 0 ? REPORT_ACTION.ADMIN_ACCEPT_COMPLAIN : REPORT_ACTION.ADMIN_REJECT_COMPLAIN;
            } else if (type == ACTIVITY_TYPE.REPORT)
                action = radioValue == 1 ? REPORT_ACTION.ADMIN_ACCEPT_REPORT : REPORT_ACTION.ADMIN_REJECT_REPORT;

            let params = {
                atid: feedDetail.atid,
                evid: eventID,
                uid: userProfile && userProfile.uid,
                action
            };
            
            let result = await doAction(params, type);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                onOpenSnackbar();
                doSetViewDetail(null);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function RadioItem(props) {
            const { item } = props;
            return (
                <div class="frm-radio" onClick={() => setRadioValue(item.id)}>
                    <input
                        type="radio"
                        id={`r_${item.id}${feedDetail.atid}`}
                        name={`radiolist${feedDetail.atid}`}
                        checked={radioValue === item.id}
                    />
                    <label htmlFor={`r_${item.id}${feedDetail.atid}`}>
                        <span class="radio"></span>
                        <span class="txt">{t(item.title)}</span>
                    </label>
                </div>
            );
        }

        return (
            <>
                <div class="choose-list">
                    {MENUS.map((item) => {
                        return <RadioItem item={item} key={item.id} />;
                    })}
                </div>
                <div class="process-btn">
                    <button class={isActive} onClick={() => processActivity()}>
                        {t('global.process')}
                    </button>
                </div>
            </>
        );
    })
);

export default RadioAction;
