import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
    STATS_TYPE,
    GROUP_JOIN_STATUS,
    JOIN_TEAM_STT,
    EVENT_JOIN_STATUS,
    GROUP_ACTION,
    EVENT_PUBlIC_TYPE
} from 'helpers/ConstDefined';
import { formatDistanceKM, formatNumber, getImageResource, replaceURLs } from 'utils';
import ChoiseLeaderDialog from 'views/TeamDetails/components/GroupDetail/ChoiseLeaderDialog';
import LeaveConfirmDialog from 'views/TeamDetails/components/GroupDetail/LeaveConfirmDialog';
import DeleteGroupDialog from 'views/TeamDetails/components/GroupDetail/DeleteGroupDialog';
import ConfirmChangeLeaderDialog from 'views/TeamDetails/components/GroupDetail/ConfirmChangeLeaderDialog';
import CancelJoinGroupDialog from 'views/TeamDetails/components/GroupDetail/CancelJoinGroupDialog';
import CancelRequestCreateGroupDialog from 'views/TeamDetails/components/GroupDetail/CancelRequestCreateGroupDialog';
import $ from 'jquery';
import { MemberName } from 'components/MemberName';


let leaderObj = null;

const GroupHeader = inject(
    'userStore',
    'eventStore',
    'groupStore',
    'globalStore'
)(
    observer((props) => {
        const { groupData, eventData, navigation } = props;
        const { eventGroupStats, doGetEventStats, doSetGroupRequestID } = props.eventStore;
        const { userProfile } = props.userStore;
        const { doActionGroup } = props.groupStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [openDialogChoiseLeader, setOpenDialogChoiseLeader] = React.useState(false);
        const [openDialogLeaveConfirm, setOpenDialogLeaveConfirm] = React.useState(false);
        const [openDialogDeleteGroup, setOpenDialogDeleteGroup] = React.useState(false);
        const [openDialogCancelJoinGroup, setOpenDialogCancelJoinGroup] = React.useState(false);
        const [openDialogConfirmChangeLeader, setOpenDialogConfirmChangeLeader] = React.useState(false);
        const [openDialogCancelRequestCreateGroup, setOpenDialogCancelRequestCreateGroup] = React.useState(false);

        const cssStatus =
            groupData.jsts == GROUP_JOIN_STATUS.REQUESTING || groupData.gejsts == GROUP_JOIN_STATUS.REQUESTING || groupData.jsts == GROUP_JOIN_STATUS.NONE
                ? 'group-info__status'
                : 'group-info__status joined';

        useEffect(() => {
            getGroupEventStats();
        }, [userProfile]);

        useEffect(() => {
            $('.action-show-hide').click(showHide);
        }, [groupData, eventData]);

        // show hide
        function showHide() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        }

        const { t } = useTranslation();

        const handleClickStatus = () => {
            if (groupData.jsts == GROUP_JOIN_STATUS.REQUESTING) {
                setOpenDialogCancelJoinGroup(true);
            } else if (groupData.gejsts == GROUP_JOIN_STATUS.REQUESTING) {
                setOpenDialogCancelRequestCreateGroup(true);
            } else if (groupData.jsts == GROUP_JOIN_STATUS.JOINED || groupData.jsts == GROUP_JOIN_STATUS.ADMIN) {
                setOpenDialogLeaveConfirm(true);
            }
        };

        function getGroupEventStats() {
            let data = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                type: STATS_TYPE.GROUP, // 0: event: 1: league, 2: team, 2: fund
                value: groupData.grid
            };
            doGetEventStats(data, STATS_TYPE.GROUP);
        }

        const onShowConfirmChangeLeader = (data) => {
            leaderObj = data;
            setOpenDialogConfirmChangeLeader(true);
        };

        function onEditGroup() {
            props.onEditGroup(groupData);
        }

        async function onJoinGroup() {
            const params = {
                uid: userProfile.uid,
                grid: groupData.grid,
                action: eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ? GROUP_ACTION.JOIN : GROUP_ACTION.USER_REQUEST_JOIN
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doSetGroupRequestID(groupData.grid);

                if ((eventData.jpri = EVENT_PUBlIC_TYPE.PUBLIC)) {
                    groupData.jsts = GROUP_JOIN_STATUS.JOINED;
                    groupData.mem += 1;
                } else groupData.jsts = GROUP_JOIN_STATUS.REQUESTING;
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const openTabManageMember = () => {
            props.onChangeTab(1);
        };

        function ButtonJoin() {
            if (groupData.jsts == GROUP_JOIN_STATUS.NONE) {
                return (
                    <a href="javascript:void(0);" className={cssStatus} onClick={() => onJoinGroup()}>
                        {t('global.join_group')}
                    </a>
                );
            } else if (groupData.jsts == GROUP_JOIN_STATUS.REQUESTING) {
                return (
                    <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                        {t('global.wait_approve')}
                    </a>
                );
            } else if (groupData.jsts == GROUP_JOIN_STATUS.JOINED || groupData.jsts == GROUP_JOIN_STATUS.ADMIN) {
                //nhóm được tao và đang đợi duyệt
                if (groupData.gejsts == GROUP_JOIN_STATUS.REQUESTING)
                    return (
                        <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                            {t('global.wait_approve_group')}
                        </a>
                    );
                else
                    return (
                        <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                            {t('global.ready_join')}
                        </a>
                    );
            }
        }

        const showEdit =
            (userProfile && userProfile.uid == eventData.owner) || eventData.jsts == EVENT_JOIN_STATUS.ADMIN;

        return (
            <div class="group-detail-info">
                <div class="group-info__avatar">
                    <img src={getImageResource(groupData.ava, true)} alt="Avatar" class="avatar-item" />
                </div>
                <div class="group-info__details">
                    <div class="r-col">
                        <div class="group-info__name">{MemberName(groupData)}</div>
                        <div class="group-info__owner">{groupData.ownm}</div>
                        <div class={cssStatus}>{userProfile ? <ButtonJoin /> : null}</div>
                        <div class="group-info-row">
                            {eventGroupStats ? (
                                <ul class="statistical">
                                    <li class="statistical-item km">
                                        <div class="ico"></div>
                                        <div class="num">{formatDistanceKM(eventGroupStats.dis, true)}</div>
                                        <div class="lb">{t('global.number_km')}</div>
                                    </li>
                                    <li class="statistical-item members">
                                        <div class="ico"></div>
                                        <div class="num">{formatNumber(groupData.mem)}</div>
                                        <div class="lb">{t('global.member1')}</div>
                                    </li>
                                    <li class="statistical-item rank">
                                        <div class="ico"></div>
                                        <div class="num">{formatNumber(eventGroupStats.rank)}</div>
                                        <div class="lb">{t('global.rank_current')}</div>
                                    </li>
                                </ul>
                            ) : null}

                            {userProfile && groupData.owner == userProfile.uid ? (
                                <div class="btn-group">
                                    <div class="uiselector setting-more">
                                        <p
                                            class="setting-more-btn"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"></p>
                                        <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                            <li onClick={() => onEditGroup()}>{t('global.edit_info')}</li>
                                            {groupData.gejsts == GROUP_JOIN_STATUS.JOINED ? (
                                                <>
                                                    <li onClick={() => openTabManageMember()}>
                                                        {t('global.manage_member')}
                                                    </li>
                                                    <li onClick={() => setOpenDialogLeaveConfirm(true)}>
                                                        {t('global.leave_group')}
                                                    </li>
                                                    <li onClick={() => setOpenDialogDeleteGroup(true)}>
                                                        {t('global.delete_group')}
                                                    </li>
                                                </>
                                            ) : null}

                                            {groupData.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                                                <li onClick={() => setOpenDialogCancelRequestCreateGroup(true)}>
                                                    {t('global.cancel_create_group')}
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div class="group-info__introduce">
                    <h5 class="title">{t('global.intro')}</h5>

                    {groupData.desc ? (
                        <div class="desc">
                            <a href="javascript:void(0);" class="see-more action-show-hide"></a>
                            <p
                                class="txt"
                                dangerouslySetInnerHTML={{
                                    __html: replaceURLs(groupData.desc).replace(/\n/g, '<br />')
                                }}></p>
                        </div>
                    ) : null}

                    {userProfile && groupData.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                        <>
                            <div class="alert alert-danger" role="alert">
                                {t('team.group.wait_approve')}
                            </div>

                            <CancelRequestCreateGroupDialog
                                openDialogCancelRequestCreateGroup={openDialogCancelRequestCreateGroup}
                                data={groupData}
                                navigation={navigation}
                                onClose={() => setOpenDialogCancelRequestCreateGroup(false)}
                                eventID={eventData.evid}
                            />
                        </>
                    ) : null}

                    {userProfile &&
                    groupData.owner == userProfile.uid &&
                    groupData.gejsts == GROUP_JOIN_STATUS.JOINED ? (
                        <>
                            <DeleteGroupDialog
                                openDialogDeleteGroup={openDialogDeleteGroup}
                                data={groupData}
                                navigation={navigation}
                                onClose={() => setOpenDialogDeleteGroup(false)}
                                eventID={eventData.evid}
                            />
                        </>
                    ) : null}

                    {userProfile && groupData.jsts != GROUP_JOIN_STATUS.NONE && openDialogLeaveConfirm ? (
                        <LeaveConfirmDialog
                            choiseLeader={() => setOpenDialogChoiseLeader(true)}
                            isLeader={groupData.owner == userProfile.uid}
                            openDialogLeaveConfirm={openDialogLeaveConfirm}
                            navigation={navigation}
                            data={groupData}
                            onClose={() => setOpenDialogLeaveConfirm(false)}
                            eventID={eventData.evid}
                        />
                    ) : null}

                    {userProfile && groupData.owner == userProfile.uid && groupData.mem > 1 ? (
                        <ChoiseLeaderDialog
                            data={groupData}
                            onClose={() => setOpenDialogChoiseLeader(false)}
                            onShowConfirm={onShowConfirmChangeLeader}
                            openDialogChoiseLeader={openDialogChoiseLeader}
                            eventID={eventData.evid}
                            teamID={0}
                        />
                    ) : null}

                    {leaderObj ? (
                        <ConfirmChangeLeaderDialog
                            data={leaderObj}
                            onClose={() => setOpenDialogConfirmChangeLeader(false)}
                            openDialogConfirmChangeLeader={openDialogConfirmChangeLeader}
                            navigation={navigation}
                            eventID={eventData.evid}
                        />
                    ) : null}

                    {userProfile && groupData.jsts == GROUP_JOIN_STATUS.REQUESTING ? (
                        <CancelJoinGroupDialog
                            data={groupData}
                            navigation={navigation}
                            openDialogCancelJoinGroup={openDialogCancelJoinGroup}
                            onClose={() => setOpenDialogCancelJoinGroup(false)}
                            eventID={eventData.evid}
                        />
                    ) : null}
                </div>
            </div>
        );
    })
);

export default GroupHeader;
