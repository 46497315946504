import { checkHttpStatus, parseJSON, log, throwAPIError, getRequestBody } from '../../utils';
import { HTTP_METHOD, KEY, HTTP_STATUS, API } from '../api/APIDefined';
import axios from 'axios';
import { getUUID, getTimestamp } from '../../utils';

function clearLocalStorage() {
    localStorage.removeItem(KEY.CURRENT_USER);
}

export async function fetchJson(url) {
    return await axios({
        timeout: 15000,
        method: 'get',
        url: url,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
        }
    })
        .then((result) => {
            log('result', result);
            return result.data;
        })
        .catch((error) => {
            log('error: ' + url, error);
        });
}

export async function requestOAuth(url, headers) {
    return await axios({
        timeout: 15000,
        method: 'POST',
        url: url,
        headers
    })
        .then((result) => {
            log('result', result);
            return result.data;
        })
        .catch((error) => {
            log('error: ' + url, error);
        });
}

export async function requestAPI(url, params, httpMethod) {
    const userLocal = localStorage.getItem(KEY.CURRENT_USER);
    let token = null;
    try {
        if (userLocal != null && userLocal != 'undefined') {
            token = JSON.parse(userLocal).accesstoken;
        }
    } catch (error) {}

    return await axios({
        timeout: 15000,
        method: httpMethod,
        url: url,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            CustomAuth: 'vwZ7XiHnN4uAlFHFyfCKyvOzrk8=',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
        },
        params
    })
        .then((result) => {
            log('result', result);
            return result.data;
        })
        .catch((error) => {
            log('error: ' + url, error);
            if (error.response) {
                if (
                    error.response.status == HTTP_STATUS.TOKEN_INVALID ||
                    error.response.status == HTTP_STATUS.UNAUTHORIZED
                ) {
                    clearLocalStorage();
                    window.location.href = '/login';
                }
            }
        });
}

export async function requestAPIV2(url, data, httpMethod) {
    const userLocal = localStorage.getItem(KEY.CURRENT_USER);
    const userLang = localStorage.getItem(KEY.USER_LANGUAGE);
    const language = userLang && userLang == 'en' ? 'en' : 'vi';

    let token = null;
    try {
        if (userLocal != null && userLocal != 'undefined') {
            token = JSON.parse(userLocal).accesstoken;
        }
    } catch (error) {}

    const params = {
        trid: getUUID(),
        trtm: getTimestamp(),
        data
    };

    log('token', token);
    log('params' + url + ':', params);
    log('url', url);

    return await axios({
        timeout: 15000,
        method: httpMethod,
        url,
        data: JSON.stringify(params),
        contentType: 'application/json',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept-Language': language,
            'App-Client-Os': 'Web',
            'App-Client-Version': API.GLOBAL.APP_CLIENT_VERSION,
            Authorization: 'Bearer ' + token
        },
        dataType: 'json'
    })
        .then((result) => {
            log('result ' + url, result);
            return result.data;
        })
        .catch((error) => {
            log('error: ' + url, error);
            if (
                error.response.status == HTTP_STATUS.TOKEN_INVALID ||
                error.response.status == HTTP_STATUS.UNAUTHORIZED
            ) {
                //clearLocalStorage();
                //window.location.href = '/login';
            }
        });
}

export async function requestAPIBodyNotAuth(url, data, httpMethod) {
    log('data', data);
    log('url', url);

    return await axios({
        timeout: 15000,
        method: httpMethod,
        url: url,
        data: httpMethod == HTTP_METHOD.GET ? null : data,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            CustomAuth: 'vwZ7XiHnN4uAlFHFyfCKyvOzrk8='
        },
        body: httpMethod == HTTP_METHOD.GET ? null : data
    })
        .then((result) => {
            log('result', result);
            if (result != undefined) {
                return result.data;
            }
        })
        .catch((error) => {
            log('error: ' + url, error);
            //clearLocalStorage();
            //window.location.href = '/login';
        });
}

export async function requestAPIBody(url, data, httpMethod, headersExtra) {
    log('data', data);
    log('url', url);

    const userLocal = localStorage.getItem(KEY.CURRENT_USER);
    let token = null;
    try {
        if (userLocal != null && userLocal != 'undefined') {
            token = JSON.parse(userLocal).accesstoken;
        }
    } catch (error) {}

    console.log('token', token);

    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        CustomAuth: 'vwZ7XiHnN4uAlFHFyfCKyvOzrk8=',
        'App-Client-Os': 'Web',
        'App-Client-Version': API.GLOBAL.APP_CLIENT_VERSION,
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + token
    };

    if (headersExtra) {
        headers.XToken = headersExtra.XToken;
        headers.XSec = headersExtra.XSec;
        headers.XVerifier = headersExtra.XVerifier;
    }

    return await axios({
        timeout: 15000,
        method: httpMethod,
        url: url,
        data: httpMethod == HTTP_METHOD.GET ? null : data,
        headers,
        body: httpMethod == HTTP_METHOD.GET ? null : data
    })
        .then((result) => {
            log('result', result);
            if (result != undefined) {
                return result.data;
            }
        })
        .catch((error) => {
            log('error: ' + url, error);
            if (error.response) {
                if (
                    error.response.status == HTTP_STATUS.TOKEN_INVALID ||
                    error.response.status == HTTP_STATUS.UNAUTHORIZED
                ) {
                    clearLocalStorage();
                    window.location.href = '/login';
                }
            }
        });
}

export async function uploadFile(url, params, file, httpMethod) {
    log('params', params);
    log('url', url);

    const userLocal = localStorage.getItem(KEY.CURRENT_USER);
    let token = null;
    try {
        if (userLocal != null && userLocal != 'undefined') {
            token = JSON.parse(userLocal).accesstoken;
        }
    } catch (error) {}

    return await axios({
        timeout: 15000,
        method: httpMethod,
        url: url,
        params,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
        },
        data: file
    })
        .then((result) => {
            log('result', result);
            if (result != undefined) {
                return result.data;
            }
        })
        .catch((error) => {
            log('error: ' + url, error);
            log('error: ' + url, error);
            if (error.response) {
                if (error.response.status == HTTP_STATUS.TOKEN_INVALID) {
                    clearLocalStorage();
                    window.location.href = '/login';
                }
            }
        });
}
