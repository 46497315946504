import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { API } from '../../../../../helpers/api/APIDefined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogSelectTeam from '../../../UserTeam/components/DialogSelectTeam';

const TABS = [
    {
        id: 0,
        title: 'team.team_list.tab1',
        class: 'info'
    },
    {
        id: 1,
        title: 'team.team_list.tab2',
        class: 'rank'
    }
];

const TabHeader = inject(
    'eventStore',
    'teamManageStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { tabMain, doSetMainTab } = props.teamManageStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { t } = useTranslation();
        const { currentEvent, doSetTeamJoinEvent } = props.eventStore;
        const { currentTeam, userTeam } = props.teamStore;
        const { userProfile } = props.userStore;

        const [openDialogSelectTeam, setOpenDialogSelectTeam] = React.useState(false);

        useEffect(() => {}, []);

        function onChangeTab(tab) {
            doSetMainTab(tab);
            //props.onChangeTab(tab);
        }

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        function TabItem(props) {
            const { data } = props;
            const css = data.id == tabMain ? 'active' : '';
            return (
                <li className={data.class + ' ' + css} onClick={() => onChangeTab(data.id)}>
                    <a href="javascript:void(0);">
                        {t(data.title)}
                    </a>
                </li>
            );
        }

        const joinTeamEventSuccess = () => {
            //cập nhật id team trong currentEvent khi join team succ
            doSetTeamJoinEvent(teamID);
        };

        const cssButton = userTeam && userTeam.tmid != 0 ? 'btn-40 blue inactive' : 'btn-40 blue';

        return (
            <div className="team-details--tabs-menu">
                <ul>
                    {TABS.map((item) => {
                        return <TabItem data={item} key={item.id} />;
                    })}
                </ul>
                <div className="right">
                    {currentTeam && currentEvent && currentEvent.tmid == currentTeam.tmid ? (
                        <button className="btn-40 blue-light" data-toggle="modal" data-target="#team-invite-friends-2">
                            {t('global.invite_friend')}
                        </button>
                    ) : (
                        <>
                            {userProfile ? (
                                <>
                                    <button className={cssButton} onClick={() => setOpenDialogSelectTeam(true)}>
                                        {t('global.join_team')}
                                    </button>
                                    <DialogSelectTeam
                                        openDialogSelectTeam={openDialogSelectTeam}
                                        onJoinTeamSuccess={() => joinTeamEventSuccess()}
                                        teamData={currentTeam}
                                        eventID={eventID}
                                        onClose={() => setOpenDialogSelectTeam(false)}
                                    />
                                </>
                            ) : (
                                <Link to={'/login'}>
                                    <button className="btn-40 blue">{t('global.join_team')}</button>
                                </Link>
                            )}
                        </>
                    )}
                    <div className="share-block">
                        <button className="share-btn"></button>
                        <div className="share-tooltip">
                            <CopyToClipboard
                                text={API.GLOBAL.SITE_DOMAIN + `2022/team-details?evid=${eventID}&tmid=${teamID}`}
                                onCopy={() => onCopySuccess()}>
                                <button className="box">{t('global.copy_link')}</button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default TabHeader;
