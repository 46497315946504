import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { SOCIAL_TAB, FOLLOW_STT } from '../../../../helpers/ConstDefined';
import DialogFollowerUsers from '../DialogFollowerUsers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getImageResource, getLoginTypeResource } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const Personal = inject(
    'userStore',
    'globalStore',
    'userProfileStore'
)(
    observer((props) => {
        const { userProfileOther, doFollow } = props.userStore;
        const { userProfile } = props.userStore;

        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.FOLLOWER);
        const [openDialogUserFollower, setOpenDialogUserFollower] = React.useState(false);

        const { profileID, eventID } = props;
        const { t } = useTranslation();

        const onOpenDialogUserFollow = (tab) => {
            setOpenDialogUserFollower(true);
            setTabSocial(tab);
        };

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: profileID,
                action
            };
            let result = await doFollow(params);
        }

        function ButtonFollow(props) {
            const { data } = props;
            if (data.fists == 2)
                return (
                    <button class="btn-40 blue-light" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                        {t('global.unfollow')}
                    </button>
                );
            else
                return (
                    <button class="btn-40 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                        {t('global.follow')}
                    </button>
                );
        }

        if (userProfileOther != null)
            return (
                <>
                    <div class="profile-wbox info">
                        <div class="profile-info">
                            <div class="avatar">
                                <p class="img">
                                    <img src={getImageResource(userProfileOther.ava, true, true)} alt="" />
                                </p>
                                <p class="ico">
                                    <img src={getLoginTypeResource(userProfileOther.type)} alt="" />
                                </p>
                            </div>
                            <div class="details">
                                <p class="name">{userProfileOther.name}</p>
                                <p class="code">{userProfileOther.code}</p>
                                <p class="code">{userProfileOther.email}</p>
                            </div>
                            <ul class="list">
                                <li onClick={()=>onOpenDialogUserFollow(SOCIAL_TAB.FOLLOWING)}>
                                    <a href="javascript:void(0);">
                                        <p class="num">{userProfileOther.ficnt}</p>
                                        <p class="lb">{t('global.you_follow')}</p>
                                    </a>
                                </li>
                                <li onClick={()=>onOpenDialogUserFollow(SOCIAL_TAB.FOLLOWER)}>
                                    <a href="javascript:void(0);">
                                        <p class="num">{userProfileOther.fecnt}</p>
                                        <p class="lb">{t('global.follower1')}</p>
                                    </a>
                                </li>
                            </ul>
                            <div class="btn-row">
                                {userProfileOther.uid != userProfile.uid ? (
                                    <ButtonFollow key={userProfileOther.fists} data={userProfileOther} />
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {openDialogUserFollower ? (
                        <DialogFollowerUsers
                            openDialogUserFollower={openDialogUserFollower}
                            profileID={profileID}
                            eventID={eventID}
                            tab={tabSocial}
                            onClose={() => setOpenDialogUserFollower(false)}
                        />
                    ) : null}
                </>
            );
        else return null;
    })
);

export default Personal;
