import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml } from 'utils';
import CreateGroupDialog from 'views/TeamDetails/components/CreateGroupDialog';
import {
    EVENT_JOIN_STATUS,
    EVENT_PUBlIC_TYPE,
    EVENT_STATUS_TYPE,
    GLOBAL_CONFIG,
    GROUP_ACTION,
    GROUP_JOIN_STATUS
} from 'helpers/ConstDefined';

const ListGroupDialog = inject(
    'globalStore',
    'groupStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { loadingGroup, totalGroupJoinFilter, listGroupJoinFilter, doGetListGroupJoinFilter, doActionGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const { doSetGroupIDEvent } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { openDialogListGroup, eventData, navigation } = props;

        const { t } = useTranslation();
        const [search, setSearch] = React.useState('');
        const [openDialogCreateGroup, setOpenDialogCreateGroup] = React.useState(false);

        const isJoined = eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING;

        const isShowLoadMore = listGroupJoinFilter == null ? false : listGroupJoinFilter.length < totalGroupJoinFilter;

        useEffect(() => {
            getListGroupJoin();
        }, []);

        const getListGroupJoin = (isLoadMore) => {
            const params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile.uid,
                evid: eventData.evid,
                name: search,
                gejsts: GROUP_JOIN_STATUS.JOINED,
                tmid: 0
            };
            doGetListGroupJoinFilter(params, isLoadMore);
        };

        const onCreateGroupSucc = () => {
            props.onClose();
        }

        async function onJoinGroup(data) {
            const action =
                eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ? GROUP_ACTION.JOIN : GROUP_ACTION.USER_REQUEST_JOIN;
            const params = {
                uid: userProfile.uid,
                grid: data.grid,
                action
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doSetGroupIDEvent(data.grid);
                props.onClose();
                //doSetGroupRequestID(data.grid);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getListGroupJoin();
            }
        };

        const onOpenDialogCreateGroup = () => {
            setOpenDialogCreateGroup(true);
            props.onClose();
        };

        const goToDetail = (grid) => {
            navigation.push(`/group-detail?grid=${grid}&evid=${eventData.evid}`);
        }

        const GroupItem = (props) => {
            const { data } = props;
            return (
                <li class="list-group-item">
                    <div class="group-box">
                        <div class="group-logo" onClick={() => goToDetail(data.grid)}>
                            <img src={getImageResource(data.ava, true)} class="group-logo__thumbnail" alt="Avatar" />
                        </div>
                        <div class="group-details" onClick={() => goToDetail(data.grid)}>
                            <div class="group-details__name">{data.name}</div>
                            <div class="group-details__owner">{data.ownm}</div>
                            <div class="group-details__size">
                                <span class="number">{data.mem}</span>
                                <span class="txt"> {t('global.member_join').toLowerCase()}</span>
                            </div>
                        </div>
                        {eventData.grid == 0 && isJoined ? (
                            <div class="group-btn" onClick={() => onJoinGroup(data)}>
                                <button class="btn btn-blue btn-choose">{t('global.select')}</button>
                            </div>
                        ) : null}

                        {eventData.grid > 0 &&
                        data.jsts != GROUP_JOIN_STATUS.NONE &&
                        data.jsts != GROUP_JOIN_STATUS.REQUESTING ? (
                            <div class="group-btn">
                                <button class="btn btn-blue btn-choose">{t('global.ready_join')}</button>
                            </div>
                        ) : null}
                    </div>
                </li>
            );
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListGroupJoin(true)} style={{padding: 20}}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <Dialog open={openDialogListGroup}>
                <div
                    class="uprace-popup group-event-challenges-popup has-title in"
                    id="list-group"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content">
                            <div class="popup-header">
                                <h4 class="title">{t('eu.list_group_in_event1')}</h4>
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="popup-body">
                                <div class="frm-row">
                                    <div class="frm-row__item search">
                                        <input
                                            type="text"
                                            placeholder={t('global.search')}
                                            value={search}
                                            onKeyDown={handleKeyDown}
                                            onChange={(text) => setSearch(stripedHtml(text.target.value))}
                                        />
                                    </div>
                                    {eventData.grid == 0 && isJoined ? (
                                        <div class="frm-row__item">
                                            <button
                                                class="btn-40 btn-blue btn-add"
                                                onClick={() => setOpenDialogCreateGroup(true)}>
                                                {t('team.group.createdl.title')}
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {eventData.grid == 0 && isJoined ? (
                                    <div class="alert alert-info" role="alert">
                                        <img src="../../images/imgs/choose_group.png" alt="icon" class="icon" />
                                        <span class="txt">{t('eu.select_group_join_event_ph')}</span>
                                    </div>
                                ) : null}

                                <ul class="list-group">
                                    {listGroupJoinFilter &&
                                        listGroupJoinFilter.map((item) => {
                                            return <GroupItem data={item} key={item.grid} />;
                                        })}
                                </ul>
                            </div>
                            <LoadMore />
                            <div class="popup-footer justify-content-end">
                                <button
                                    class="btn-40 btn-outline-blue btn-close"
                                    data-dismiss="modal"
                                    onClick={props.onClose}>
                                    {t('global.close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {openDialogCreateGroup == true ? (
                    <CreateGroupDialog
                        openDialogCreateGroup={openDialogCreateGroup}
                        navigation={navigation}
                        onCreateGroupSucc={onCreateGroupSucc}
                        eventID={eventData.evid}
                        teamID={0}
                        onClose={() => setOpenDialogCreateGroup(false)}
                    />
                ) : null}
            </Dialog>
        );
    })
);

export default ListGroupDialog;
