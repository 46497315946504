import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import TeamItem from './TeamItem';
import { toJS } from 'mobx';

const YourTeam = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { userProfile } = props;

        useEffect(() => {}, []);

        if (userProfile != null)
            return (
                <section className="profile-team">
                    <div className="analytics-team">
                        <div className="member-team">
                            <div className="clearfix">
                                <h3 className="title-rank pull-left">Đội của bạn</h3>
                                <div className="pull-right">
                                    <button className="btn btn-primary " onClick={props.onCreateTeam}>Tạo đội mới</button>
                                </div>
                            </div>
                            <div className="list-member">
                                {userProfile.tmls &&
                                    userProfile.tmls.map((item) => {
                                        return <TeamItem key={item.tmid} onViewTeamProfile={props.onViewTeamProfile} data={toJS(item)} />;
                                    })}
                            </div>
                        </div>
                    </div>
                </section>
            );
        else return null;
    })
);

export default YourTeam;
