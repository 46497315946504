import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, getImageResource } from '../../../../utils';
import { GROUP_ACTION } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import { MemberName, MemberCode } from 'components/MemberName';

const MemberRequestItem = inject(
    'userEventStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventID } = props;
        const { doActionEvent, doApproveMember, doRejectMember } = props.userEventStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        async function actionEvent(action) {
            const params = {
                uid: userProfile && userProfile.uid,
                pfid: data.uid,
                evid: eventID,
                action
            };
            let result = await doActionEvent(params);
            if (result) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                if (action == GROUP_ACTION.ADMIN_APPROVE_JOIN) doApproveMember(data.uid);
                else if (action == GROUP_ACTION.ADMIN_REJECT_JOIN) doRejectMember(data.uid);
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        function addDefaultSrc(e) {
            e.target.src = '../../../img/ic_avatar.png';
        }

        return (
            <div class="list-group-item">
                <div class="manage-card rows-2">
                    <div class="avatar">
                        <img
                            src={getImageResource(data.ava, true)}
                            onError={addDefaultSrc}
                            class="avatar-item"
                            alt="Avatar"
                        />
                    </div>
                    <div class="group-info">
                        <div class="group-info__name">{MemberName(data)}</div>
                        <div class="group-info__owner1">{MemberCode(data)}</div>
                    </div>
                    <div class="btn-group">
                        <div class="btn-group-item">
                            <button
                                class="btn-32 btn-blue btn-w-120"
                                onClick={() => actionEvent(GROUP_ACTION.ADMIN_APPROVE_JOIN)}>
                                {t('global.approve')}
                            </button>
                        </div>
                        <div class="btn-group-item">
                            <button
                                class="btn-32 btn-outline-blue btn-w-120"
                                onClick={() => actionEvent(GROUP_ACTION.ADMIN_REJECT_JOIN)}>
                                {t('global.reject')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default MemberRequestItem;
