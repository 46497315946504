import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TabHeader from './TabHeader';
import GroupRequestList from './GroupRequestList';
import GroupJoinList from './GroupJoinList';

const ManagerGroup = inject('authStore')(
    observer((props) => {
        const { eventData } = props;
        const [tab, setTab] = useState(0);

        useEffect(() => {}, []);

        const onChangeTab = (val) => {
            setTab(val);
        };

        const { t } = useTranslation();

        return (
            <div class="manage-page">
                <div class="manage-wrap">
                    <div class="manage-box">
                        <div class="manage-box__header">
                            <h4 class="title">{t('team.manage.tab2')}</h4>
                        </div>

                        <div class="manage-box__body">
                            <div class="content">
                                <TabHeader tab={tab} onChangeTab={onChangeTab} />

                                <div class="tabs-v2-content">
                                    <ul>
                                        {tab == 0 ? (
                                            <GroupRequestList eventData={eventData} />
                                        ) : (
                                            <GroupJoinList eventID={eventData.evid} />
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default ManagerGroup;
