import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const Top3NewsItem = inject('authStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {}, []);

        return (
            <div className="news">
                <a className="news--thumb" href="#news">
                    <img className="lazyload" data-src={data.image} alt="news" />
                </a>
                <div className="news-info">
                    <RouterLink to = {`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID }&id=${data.id}`}  className="news--title">
                        {data.title_vi}
                    </RouterLink>

                    <div className="news-bottom flex justify-content-space-between">
                        <RouterLink to ={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`} className="news--detail">
                            Xem thêm
                        </RouterLink>
                        <span className="news--date">{data.date}</span>
                    </div>
                </div>
            </div>
        );
    })
);

export default Top3NewsItem;
