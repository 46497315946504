import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimestamptoDate, formatDistanceKM } from '../../../../utils/index';
import { CHALLENGE_STT } from '../../../../helpers/ConstDefined';

const DialogChallengeResult = inject('authStore')(
    observer((props) => {
        const { challengeDetail } = props;

        useEffect(() => {}, []);

        const cover =
            challengeDetail.sts == CHALLENGE_STT.LOSE
                ? '../../../img/background-dnf.png'
                : '../../../img/background-finished.png';

        let percentDistance = 0,
            percentDayActive = 0,
            percentLongestDistance = 0;
        if (challengeDetail != null) {
            percentDistance = Math.round((challengeDetail.dis * 100) / challengeDetail.cdis);
            percentDayActive = Math.round((challengeDetail.days * 100) / challengeDetail.cdays);
            percentLongestDistance = Math.round((challengeDetail.xdis * 100) / challengeDetail.cxdis);
        }

        if (challengeDetail.sts != CHALLENGE_STT.COMMITED && challengeDetail.sts != CHALLENGE_STT.DELETED)
            return (
                <>
                    <div className="banner-background" style={{ backgroundImage: `url(${cover})` }}>
                        <img src={cover} alt="Banner Finished" />
                    </div>
                    <p className="description">{challengeDetail.desc}</p>

                    <div className="linear-challenge linear-challenge-custom">
                        <div className="row">
                            <div className="col-lg-4 padding-big-challenge">
                                <div className="wrapper-linear linear-total-distance">
                                    <p className="title">Tổng quãng đường</p>
                                    <p className="value-distance">
                                        {formatDistanceKM(challengeDetail.cdis)} <span className="unit">km</span>
                                    </p>
                                    <p className="title">
                                        Đã chạy:{' '}
                                        <span className="value-result">{formatDistanceKM(challengeDetail.dis)} km</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 padding-big-challenge">
                                <div className="wrapper-linear linear-total-day">
                                    <p className="title">Tổng số ngày</p>
                                    <p className="value-distance">
                                        {challengeDetail.cdays} ngày <span className="unit"></span>
                                    </p>
                                    <p className="title">
                                        Ngày: <span className="value-result total-day">{challengeDetail.days}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 padding-big-challenge">
                                <div className="wrapper-linear linear-max-distance">
                                    <p className="title">Quãng đg dài nhất</p>
                                    <p className="value-distance">
                                        {formatDistanceKM(challengeDetail.cxdis)} <span className="unit">km</span>
                                    </p>
                                    <p className="title">
                                        Dài nhất:{' '}
                                        <span className="value-result total-distance">
                                            {formatDistanceKM(challengeDetail.xdis)} km
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        else if (challengeDetail.sts == CHALLENGE_STT.COMMITED)
            return (
                <>
                    <div className="wrapper-ribbon">
                        <p className="banner title-ribbon">Thử thách và Cam kết của bạn</p>
                    </div>

                    <p className="description">{challengeDetail.desc}</p>
                    <div className="category">
                        <div className="row content-top">
                            <div className="col-md-6 no-margin">
                                <p className="title-request">Tổng quãng đường:</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-request">{formatDistanceKM(challengeDetail.cdis)} km</p>
                            </div>
                        </div>
                        <div className="progress progress-category-custom custom-pink">
                            <div
                                className="progress-bar progress-bar-custom"
                                role="progressbar"
                                style={{ width: `${percentDistance}%` }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                        <div className="row content-bottom">
                            <div className="col-md-6 no-margin">
                                <p className="title-result">Đã chạy:</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-result pink">{formatDistanceKM(challengeDetail.dis)} km</p>
                            </div>
                        </div>
                    </div>
                    <div className="category">
                        <div className="row content-top">
                            <div className="col-md-6 no-margin">
                                <p className="title-request">Tổng số ngày chạy:</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-request">{challengeDetail.cdays} ngày</p>
                            </div>
                        </div>
                        <div className="progress progress-category-custom custom-green">
                            <div
                                className="progress-bar progress-bar-custom"
                                role="progressbar"
                                style={{ width: `${percentDayActive}%` }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                        <div className="row content-bottom">
                            <div className="col-md-6 no-margin">
                                <p className="title-result">Số ngày đã chạy::</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-result green">{challengeDetail.days} ngày</p>
                            </div>
                        </div>
                    </div>
                    <div className="category">
                        <div className="row content-top">
                            <div className="col-md-6 no-margin">
                                <p className="title-request">Quãng đường dài nhất:</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-request">{formatDistanceKM(challengeDetail.cxdis)} km</p>
                            </div>
                        </div>
                        <div className="progress progress-category-custom">
                            <div
                                className="progress-bar progress-bar-custom"
                                role="progressbar"
                                style={{ width: `${percentLongestDistance}%` }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                        <div className="row content-bottom">
                            <div className="col-md-6 no-margin">
                                <p className="title-result">Đã chạy dài nhất:</p>
                            </div>
                            <div className="col-md-6 no-margin">
                                <p className="value-result blue">{formatDistanceKM(challengeDetail.xdis)} km</p>
                            </div>
                        </div>
                    </div>
                </>
            );
    })
);

export default DialogChallengeResult;
