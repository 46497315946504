import React, { useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Countdown from 'react-countdown';
import OtpInput from 'react-otp-input';
import { GROUP_ACTION } from 'helpers/ConstDefined';

const DialogVerifyOTP = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { email, evid, navigation } = props;
        const { doActionEvent } = props.userEventStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const childRef = useRef();
        const { openDialogVerifyOTP } = props;
        const [time, setTime] = React.useState(Date.now() + 60000 * 1);
        const [otp, setOTP] = React.useState(null);
        const activeBtn = otp && otp.length === 4 ? '' : 'inactive';
        const { t } = useTranslation();

        const onResendOTP = async () => {
            const params = {
                uid: userProfile.uid,
                evid,
                action: GROUP_ACTION.REQUIRE_OTP_VIA_EMAIL,
                oadr: email
            };
            let result = await doActionEvent(params);
            if (result && result.hasOwnProperty('jsts')) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                setTime(Date.now() + 60000 * 1);
                childRef.current.start();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        };

        const onConfirmOTP = async () => {
            const params = {
                uid: userProfile.uid,
                evid,
                action: GROUP_ACTION.MEMBER_CONFIRM_OTP,
                oval: otp,
                oadr: email
            };
            let result = await doActionEvent(params);
            if (result && result.hasOwnProperty('jsts')) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                navigation.push('/event-detail?evid=' + evid);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                setOTP(null);
            }
            onOpenSnackbar();
        };

        const handleChangeOTP = (val) => {
            setOTP(val);
        };

        const rendererCountDown = ({ api, days, hours, minutes, seconds, completed }) => {
            if (completed) {
                return (
                    <span onClick={() => onResendOTP()} style={{ cursor: 'pointer' }}>
                        {t('global.resend')}
                    </span>
                );
            } else {
                // Render a countdown
                return (
                    <span>
                        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                    </span>
                );
            }
        };

        return (
            <Dialog open={openDialogVerifyOTP}>
                <div
                    class="uprace-popup uprace-popup--v2 otp-verification"
                    id="otpVerification"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <div class="popup-body flex-column">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <div class="text-center">
                                    <div class="avatar">
                                        <img
                                            src="https://uprace2.vcdn.vn/web/uprace/illustrations/oc-unlock.png"
                                            class="avatar-item"
                                            alt="Check mail"
                                        />
                                    </div>
                                    <div class="txt-title">{t('global.confirm_otp')}</div>
                                    <div
                                        class="txt"
                                        style={{overflowWrap: 'break-word'}}
                                        dangerouslySetInnerHTML={{
                                            __html: t('global.confirm_otp_desc').replace('[email]', email)
                                        }}></div>
                                    <div class="row">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChangeOTP}
                                            numInputs={4}
                                            containerStyle={styles.wrapperOTP}
                                            inputStyle={styles.inputOTP}
                                            separator={<span>&nbsp;&nbsp;</span>}
                                        />
                                    </div>
                                    <div class="d-grid mb-4">
                                        <button
                                            class={`btn-40 btn-blue btn-44 btn-close ${activeBtn}`}
                                            data-dismiss="modal"
                                            onClick={() => onConfirmOTP()}>
                                            {t('global.confirm')}
                                        </button>
                                    </div>
                                    <div class="txt text-center">
                                        {t('global.resend_otp_time')}{' '}
                                        <span class="text-blue">
                                            <Countdown ref={childRef} date={time} renderer={rendererCountDown} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

const styles = {
    wrapperOTP: {
        justifyContent: 'center',
        padding: '20px'
    },
    inputOTP: {
        width: '50px',
        display: 'block',
        fontWeight: 600,
        border: '1px solid #dbdfe2',
        padding: '0.375rem 0.75rem',
        lineHeight: 1.5,
        borderRadius: ' 0.5rem',
        fontSize: 'xx-large'
    }
};

export default DialogVerifyOTP;
