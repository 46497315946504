import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
const queryString = require('query-string');
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

import ENLang from './ENLang';
import VNLang from './VNLang';
import ENLang2021 from './ENLang2021';
import VNLang2021 from './VNLang2021';
import ENLang2022 from './ENLang2022';
import VNLang2022 from './VNLang2022';

const EventRuleBasic = inject('authStore')(
    observer((props) => {
        let paramsCallback = queryString.parse(props.location.search);
        if (_.isEmpty(paramsCallback) || _.isEmpty(paramsCallback.evid)) return <Redirect to={`/event`}></Redirect>;

        let eventId = paramsCallback.evid;
        let lang = paramsCallback.lang;

        const RenderContent2020 = () => {
            if (lang == 'vi') return <VNLang />;
            else if (lang == 'en') return <ENLang />;
            else return <VNLang />;
        };

        const RenderContent2021 = () => {
            if (lang == 'vi') return <VNLang2021 />;
            else if (lang == 'en') return <ENLang2021 />;
            else return <VNLang2021 />;
        };

        const RenderContent2022 = () => {
            if (lang == 'vi') return <VNLang2022 />;
            else if (lang == 'en') return <ENLang2022 />;
            else return <VNLang2022 />;
        };

        let path;

        const RenderContent = () => {
            if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2020_ID) {
                path = '../../../img/banner/banner001.png';
                return <RenderContent2020 />;
            } else if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2021_ID) {
                path = '../../../img/banner/event2021.png';
                return <RenderContent2021 />;
            }
            else if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2022_ID) {
                path = '../../../img/banner/event2021.png';
                return <RenderContent2022 />;
            }
            else return null;
        };

        return (
            <div>
                <img src={path} alt="image" style={{ opacity: 1, width: '100%', objectFit: 'contain' }} />
                <RenderContent />
            </div>
        );
    })
);

export default EventRuleBasic;
