import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import CropImageDialog from '../EditTeamDialog/CropImageDialog';
import { getImageResource, stripedHtml } from '../../../../utils';

const CreateGroupDialog = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore'
)(
    observer((props) => {
        const { eventID, teamID, navigation } = props;
        const { doSetFunc } = props.teamManageStore;
        const { openDialogCreateGroup } = props;
        const { doCreateGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const [imageSrc, setImageSrc] = React.useState(null);
        const [openDialogCropImage, setOpenDialogCropImage] = useState(false);
        const { doUploadFile, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doSetGroupIDEvent } = props.eventStore;

        const [name, setName] = React.useState('');
        const [desc, setDesc] = React.useState('');
        const [avaGroup, setAvaGroup] = React.useState(null);
        const [avaGroupLocal, setAvaGroupLocal] = React.useState(null);

        const [errName, setErrName] = useState(false);
        const [errDesc, setErrDesc] = useState(false);
        const [errAva, setErrAva] = useState(false);

        const isInactive = !name || name.trim().length == 0 || !desc || desc.trim().length == 0;
        const cssButton = isInactive ? 'btn-44 blue inactive' : 'btn-44 blue';

        useEffect(() => {}, []);

        const { t } = useTranslation();

        function validInput() {
            let hasError = false;

            if (!name || name.trim().length == 0 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            /*if (avaGroup == null) {
                setErrAva(true);
                hasError = true;
            } else setErrAva(false);*/

            if (!desc || desc.trim().length == 0) {
                setErrDesc(true);
                hasError = true;
            } else setErrDesc(false);

            return hasError;
        }

        const onCloseDialog = () => {
            //reset params field
            setAvaGroup(null);
            setAvaGroupLocal(null);
            setImageSrc(null);
            setErrAva(false);
            setErrName(false);
            setErrDesc(false);
            setName('');
            setDesc('');
            props.onClose();
        };

        async function createGroup() {
            if (!validInput()) {
                const params = {
                    uid: userProfile.uid,
                    evid: eventID,
                    tmid: teamID,
                    name,
                    desc,
                    ava: avaGroup
                };
                let result = await doCreateGroup(params);
                if (result) {
                    doSetGroupIDEvent(result.grid); //cập nhật grid trong currentEvent 
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    doSetFunc(4);
                    onCloseDialog();
                    
                    if(navigation) {
                        props.onCreateGroupSucc();
                        navigation.push(`/group-detail?grid=${result.grid}&evid=${eventID}`);
                    } 
                } else {
                    setMessageSnackbar(t('global.action.fail'));
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        async function uploadFileServer(file) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: 0
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                setAvaGroup(result);
                //hiển thị ava từ local khi hình upload lên server chưa ready
                setAvaGroupLocal(URL.createObjectURL(file)); 
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function readFile(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }

        const onCropSuccess = (file) => {
            uploadFileServer(file);
        }

        const onFileChange = async (e) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                let imageDataUrl = await readFile(file);
                setImageSrc(imageDataUrl);
                setOpenDialogCropImage(true);
            } else {
                //not select file
            }
        };

        return (
            <>
                <Dialog open={openDialogCreateGroup}>
                    <div
                        className="uprace-popup has-title in"
                        id="create-group-step1"
                        role="dialog"
                        tabIndex="-1"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div className="popup-dialog" role="document">
                            <div className="popup-content w600">
                                <button
                                    type="button"
                                    className="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => onCloseDialog()}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 className="popup-title">{t('team.group.createdl.title')}</h2>
                                <div className="popup-body">
                                    <div className="popup-create-group">
                                        <div className="create-group-wrap">
                                            <div className="cover">
                                                <p className="img">
                                                    <img src="../../images/imgs/cover.png" alt="" />
                                                </p>
                                                <div className="logo">
                                                    {!avaGroupLocal ? (
                                                        <p className="default"></p>
                                                    ) : (
                                                        <p className="img" style={{ display: 'block' }}>
                                                            <img src={avaGroupLocal} alt="" />
                                                        </p>
                                                    )}
                                                    <button className="upload-btn">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name=""
                                                            className="file-upload-input"
                                                            onChange={onFileChange}
                                                        />
                                                    </button>
                                                </div>
                                                {errAva ? (
                                                    <p className="error-tag">{t('global.error.valid_image')}</p>
                                                ) : null}
                                            </div>
                                            <div className="frm-block">
                                                <div className="frm-name">
                                                    <button className="del-btn" style={{display: 'none'}}></button>
                                                    <input
                                                        type="text"
                                                        placeholder=""
                                                        value={name}
                                                        maxLength={60}
                                                        onChange={(text) => setName(stripedHtml(text.target.value))}
                                                    />
                                                    <label>{t('team.group.createdl.name')}</label>
                                                    {errName ? (
                                                        <p className="error-tag">{t('global.error.valid_name')}</p>
                                                    ) : null}
                                                </div>
                                                <div className="frm-ctn">
                                                    <button className="del-btn"></button>
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        cols=""
                                                        rows=""
                                                        maxLength={1500}
                                                        value={desc}
                                                        onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                        placeholder={t('team.group.createdl.desc.tip')}></textarea>
                                                    {errDesc ? (
                                                        <p className="error-tag">{t('global.error.valid_desc')}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="note-row">
                                                <div className="note">{t('team.group.createdl.rule')}</div>
                                            </div>
                                        </div>
                                        <div className="popup-btns col-1">
                                            <button className={cssButton} onClick={() => createGroup()}>
                                                {t('team.group.createdl.title')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <CropImageDialog
                    openDialogCropImage={openDialogCropImage}
                    onCropSuccess={onCropSuccess}
                    imgSource={imageSrc}
                    onClose={() => setOpenDialogCropImage(false)}
                />
            </>
        );
    })
);

export default CreateGroupDialog;
