var moment = require('moment');
var numeral = require('numeral');
import _ from 'lodash';
import { SPORT_TYPE_ACTIVITY } from '../helpers/ConstDefined';

export function humanTime(timestamp, lang) {
    let yesterday = moment().subtract(1, 'days');
    let today = new Date();
    let runDate = new Date(timestamp);

    let hour = formatDate('hh:mm A', runDate);

    yesterday = formatDate('DD-MM-YYYY', yesterday);
    today = formatDate('DD-MM-YYYY', today);
    runDate = formatDate('DD-MM-YYYY', runDate);


    const strToday = lang == 'en' ? 'Today at ' : 'Hôm nay lúc ';
    const strYesterday = lang == 'en' ? 'Yesterday at ' : 'Hôm qua lúc ';
    const strOther = lang == 'en' ? ' at ' : ' lúc ';

    if (runDate == today) return strToday + hour;
    else if (runDate == yesterday) return strYesterday + hour;
    else return runDate + strOther + hour;
}

export function formatDate(format, date) {
    if (date == undefined || date == null || date == '') return '';
    if (!moment(date).isValid()) return '';
    return moment(new Date(date)).format(format);
}

export function formatDateString(date, lang) {
    if (date == undefined || date == null || date == '') return '';
    if (lang == 'en') return moment(date).format('LL');
    
    const year = date.substr(0,4);
    const month = date.substr(4,2)
    const day = date.substr(6,2);

    const strMonth = lang == 'en' ? ' Month ' : ' Tháng ';
    return day + strMonth + month + ', ' + year;
}

export function sportTypeImage(sport) {
    if(sport == SPORT_TYPE_ACTIVITY.RUNNING)
        return "run";
    else if(sport == SPORT_TYPE_ACTIVITY.SWIMMING)
        return "swim";
    else if(sport == SPORT_TYPE_ACTIVITY.CYCLING)
        return "bike";
    else if(sport == SPORT_TYPE_ACTIVITY.TRIATHLON)
        return "trialthon";
    else if(sport == SPORT_TYPE_ACTIVITY.WALKING)
        return "walk";
    else return null;
}

export function srcTypeImage(src) {
    let imgSrc = '../../../images/yours/uprace.png';
    if (src == 1) imgSrc = '../../../images/yours/strava.png';
    else if (src == 2) imgSrc = '../../../images/yours/garmin.png';
    return imgSrc;
}

