import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TeamCategoty from './TeamCategoty';
import TopRank from './TopRank';
import TeamRankList from './TeamRankList';
import { TEAM_TYPE } from '../../../../data/master';
import { LEADERBOARD_TYPE, TEAM_TYPE_VAL } from '../../../../helpers/ConstDefined';

const Leaderboard = inject(
    'authStore',
    'leaderboardStore',
    'eventStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { doGetListLeaderboardTeam, setNavigationTab } = props.leaderboardStore;
        const { currentEvent } = props.eventStore;

        useEffect(() => {
            getLeaderboardTeam();
        }, []);

        const { t } = useTranslation();

        function getLeaderboardTeam() {
            setNavigationTab(TEAM_TYPE[TEAM_TYPE_VAL.COMPANY]);
            const data = {
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                value: TEAM_TYPE_VAL.COMPANY //0 : all ,tab sport =>value = 1, tab school => 2
            };
            doGetListLeaderboardTeam(data);
        }

        const onChangeTab = () => {};

        return (
            <div class="info--bxh">
                <h2 class="info--title">{t('global.leaderboard')}</h2>
                <div class="bxh-box">
                    <div class="top-bxh">
                        <TeamCategoty eventID={eventID} onChangeTab={onChangeTab} />
                        <TopRank />
                    </div>
                    {currentEvent ? <TeamRankList eventID={eventID} onChangeTab={onChangeTab} /> : null}
                </div>
            </div>
        );
    })
);

export default Leaderboard;
