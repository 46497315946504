import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { TEAM_TYPE } from '../../../../data/master';
import { getTypeName, formatNumber, getImageResource } from '../../../../utils';

const TeamList = inject('teamStore')(
    observer((props) => {
        const { data, eventID } = props;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        function TeamItem(props) {
            const { data } = props;
            return (
                <li>
                    <Link to={`team-profile?tmid=${data.tmid}`}>
                        <p class="logo">
                            <img src={getImageResource(data.cover,true)} alt="" />
                        </p>
                        <div class="details">
                            <p class="name">{data.name}</p>
                            <p class="type">
                                {t(getTypeName(TEAM_TYPE, data.lgid))} -{' '}
                                <span class="runner">{formatNumber(data.mem)} VĐV</span>
                            </p>
                        </div>
                    </Link>
                </li>
            );
        }
        return (
            <div class="profile-wbox team">
                <h2 class="profile-title">
                    {t('global.team_join')} <span class="num">({data.length})</span>
                </h2>
                <ul class="profile-team-list">
                    {data && data.map((item) => {
                        return <TeamItem data={item} key={item.tmid} />
                    })}
                </ul>
            </div>
        );
    })
);

export default TeamList;
