import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DialogUserEvent } from 'views/YourInfo/components';
import { EVENT_STATUS, EVENT_TYPE } from '../../../../helpers/ConstDefined';
import { getImageResource } from 'utils';

let eventID;

const EventList = inject(
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { data, teamID } = props;
        const { userProfile, userProfileOther } = props.userStore;
        const [openDialogUserEvent, setOpenDialogUserEvent] = React.useState(false);

        useEffect(() => {}, []);
        const { t } = useTranslation();

        function ListEvent(status) {
            let list = [];
            list = _.filter(data, (item) => {
                return item.sts == status;
            });
            return list;
        }

        const onViewUserEvent = (event) => {
            eventID = event;
            userProfileOther.id = userProfileOther.uid;
            setOpenDialogUserEvent(true);
        };

        function EventItem(props) {
            const { data } = props;

            const link =
                data.evtp != EVENT_TYPE.SUPPER_CHALLENGE ? `/user-detail?uid=${userProfileOther.uid}&evid=${data.evid}` : `/you?evid=${data.evid}`;

            if (userProfile.uid != userProfileOther.uid)
                return (
                    <div class="profile-bn">
                        <img src={getImageResource(data.cover)} alt="" />
                        <button class="btn-40 orange" onClick={() => onViewUserEvent(data.evid)}>
                            {t('global.view_rank')}
                        </button>
                    </div>
                );
            else
                return (
                    <div class="profile-bn">
                        <img src={getImageResource(data.cover)} alt="" />
                        <Link to={link}>
                            <button class="btn-40 orange">{t('global.view_rank')}</button>
                        </Link>
                    </div>
                );
        }
        return (
            <>
                <div class="profile-wbox bn">
                    <h2 class="profile-title">{t('global.event_join_now')}</h2>
                    {ListEvent(EVENT_STATUS.RUNNING).map((item) => {
                        return <EventItem data={item} key={item.evid} />;
                    })}
                </div>
                <div class="profile-wbox bn">
                    <h2 class="profile-title">{t('global.event_join')}</h2>
                    {ListEvent(EVENT_STATUS.FINISHED).map((item) => {
                        return <EventItem data={item} key={item.evid} />;
                    })}
                </div>

                {openDialogUserEvent ? (
                    <DialogUserEvent
                        openDialogUserEvent={openDialogUserEvent}
                        profileData={userProfileOther}
                        eventID={eventID}
                        teamID={teamID}
                        onClose={() => setOpenDialogUserEvent(false)}
                    />
                ) : null}
            </>
        );
    })
);

export default EventList;
