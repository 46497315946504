import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { REPORT_ACTION, COMMON_ERROR } from '../../../../../helpers/ConstDefined';
import { stripedHtml } from '../../../../../utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const DialogReport = inject(
    'globalStore',
    'userStore',
    'reportStore'
)(
    observer((props) => {
        const { openDialogReport, data, eventID } = props;
        const { userProfile } = props.userStore;
        const { doAction } = props.reportStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [desc, setDesc] = React.useState('');
        const [errDesc, setErrDesc] = useState(false);

        const { t } = useTranslation();

        function validInput() {
            let hasError = false;
            if (!desc || desc.trim().length == 0) {
                setErrDesc(true);
                hasError = true;
            } else setErrDesc(false);

            return hasError;
        }

        async function sendReport() {
            if (!validInput()) {
                let params = {
                    atid: data.atid,
                    evid: eventID,
                    uid: userProfile.uid,
                    action: REPORT_ACTION.USER_REPORT_OTHER,
                    reason: desc
                };
                let result = await doAction(params);
                if (result == true) {
                    data.reported = true;
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    onOpenSnackbar();
                    props.onClose();
                } else if (result.ercd == COMMON_ERROR.REPORT_ACTIVITY.YOUR_REPORTED) {
                    props.onViewReported();
                } else showError(result);
            }
        }

        const showError = (result) => {
            let message;
            let code = result.ercd;
            if (code == COMMON_ERROR.OVER_QUOTA) message = t('global.limit_report_day');
            else if (code == COMMON_ERROR.REPORT_ACTIVITY.REVIEW_YOUR_ACT) message = t('global.review_your_activity');
            else if (code == COMMON_ERROR.REPORT_ACTIVITY.NOT_PERMISSION) message = t('global.report_act_lock')
            else message = result.desc;

            setMessageSnackbar(message);
            setSnackbarType('error');
            onOpenSnackbar();
        };

        const onCloseDialog = () => {
            props.onClose();
        };

        return (
            <Dialog open={openDialogReport}>
                <div
                    class="uprace-popup has-title in"
                    id="complain"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => onCloseDialog()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 class="popup-title">{t('global.report_act')}</h2>
                            <div class="popup-body">
                                <div class="popup-complain">
                                    <div class="complain-scroll">
                                        <h2 class="complain-title">{t('global.report_act.title')}</h2>
                                        <p class="complain-txt">{t('global.report_act.desc')}</p>
                                        <div class="frm-ctn active">
                                            <textarea
                                                name=""
                                                id=""
                                                cols=""
                                                rows=""
                                                maxLength={1500}
                                                value={desc}
                                                onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                placeholder={t('global.report_reason')}></textarea>
                                            {errDesc ? <p class="error-tag">{t('global.error.valid_desc')}</p> : null}
                                        </div>
                                    </div>
                                    <div class="complain-btn col-2">
                                        <button class="btn-44 cancel" onClick={() => onCloseDialog()}>
                                            {t('global.destroy')}
                                        </button>
                                        <button class="btn-44 blue" onClick={() => sendReport()}>
                                            {t('global.send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogReport;
