import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DeleteAccount = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { t } = useTranslation();

        return (
            <div class="users-event-box box--empty" style={{margin: 100}}>
                <div class="thumbnail">
                    <img
                        src="../../images/imgs/empty_search_event.png"
                        alt="Thumbnail"
                        class="thumbnail-item__list-search"
                    />
                </div>
                <div class="txt-title">{t('global.deleted_account_desc')}</div>
            </div>
        );
    })
);

export default DeleteAccount;
