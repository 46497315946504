import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { toJS } from 'mobx';
import Select from 'react-select';
import { TEAM_TYPE, SPORT_TYPE } from '../../../../data/master';
import { getUUID, getTimestamp } from '../../../../utils';

const typeList = Object.keys(TEAM_TYPE).map((key) => ({ value: TEAM_TYPE[key].id, label: TEAM_TYPE[key].name }));

let currentCategory = 0;
const TeamListToolbar = inject(
    'authStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { tabTeamType, setTabTeamType, doGetListTeam } = props.teamStore;
        const { userProfile } = props.userStore;

        const [type, setType] = React.useState(0); //type all

        const onChangeNavigationTab = (category) => {
            setTabTeamType(category.id);
            currentCategory = category;
            getListTeam();
            props.onChangeTab();
        };

        function getListTeam() {
            let data = {
                uid: userProfile.uid,
                sport: currentCategory.id,
                lgid: type.value
            };
            doGetListTeam(data);
        }

        const handleTeamTypeChange = (selectedOption) => {
            console.log(`Option selected:`, selectedOption);
            props.onChangeTeamType(selectedOption);
            setType(selectedOption.value);
        };

        function MenuItem(props) {
            const { category } = props;
            const cssClass =
                category.id == tabTeamType
                    ? 'btn btn-outline-primary custom-btn active'
                    : 'btn btn-outline-primary custom-btn inactive';

            if(category.id == 3) return null;
            return (
                <div className="col-md-2dot4" onClick={() => onChangeNavigationTab(category)}>
                    <a className={cssClass}>
                        {category.img != null ? (
                            <span>
                                <img className="img-nav" src={category.img} alt="" />
                            </span>
                        ) : null}
                        <span>{category.name}</span>
                    </a>
                </div>
            );
        }

        return (
            <>
                <div className="row wrapper-nav">
                    {SPORT_TYPE.map((cat) => (
                        <MenuItem key={cat.id} category={cat} />
                    ))}
                </div>
            </>
        );
    })
);

export default TeamListToolbar;
