import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { getImageResource, isJWTValid, stripedHtml } from '../../utils';
import { ListMember, Member1000, ProfileHeader, TeamStats, EventList } from './components';
import { DialogViewTeam } from '../UserTeam/components';
import DialogLeaveTeam from './components/DialogLeaveTeam';
import DialogLeaveResult from './components/DialogLeaveResult';
import { TEAM_MSG } from '../../helpers/MsgDefined';
import { toJS } from 'mobx';
import _ from 'lodash';
const queryString = require('query-string');

const TeamProfile = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore',
    'leaderboardStore'
)(
    observer((props) => {
        let { currentTeam, userTeam, doSetUserTeam, doGetTeamDetail } = props.teamStore;
        let { userProfile } = props.userStore;
        const { listLeaderboard } = props.leaderboardStore;
        const { doSetMainTab, doSetTabMainTop, doSetIsTeamSearch } = props.globalStore;

        const [msgLeaveTeam, setMsgLeaveTeam] = React.useState('');
        const [] = React.useState(false);
        const [openDialogViewTeam, setOpenDialogViewTeam] = React.useState(false);
        const [openDialogLeaveTeam, setOpenDialogLeaveTeam] = React.useState(false);
        const [openDialogLeaveResult, setOpenDialogLeaveResult] = React.useState(false);

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.tmid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);
        let teamID = stripedHtml(paramsQuery.tmid);

        const handleOpenDialogViewTeam = (flag) => {
            setOpenDialogViewTeam(flag);
        };
        const handleOpenDialogLeaveTeam = (flag) => {
            setOpenDialogLeaveTeam(flag);
            if (flag) setMsgLeaveTeam(null);
        };
        const handleOpenDialogLeaveResult = (flag) => {
            setOpenDialogLeaveResult(flag);
        };

        useEffect(() => {
            if (!isJWTValid()) props.history.push('/login');
            window.scrollTo(0, 0);
            doSetMainTab(null);
            doSetTabMainTop(null);
            if (currentTeam != null && userTeam != null) {
                if (currentTeam.tmid == userTeam.tmid) setMsgLeaveTeam(TEAM_MSG.LEAVE_TEAM_EVENT_CONFIRM_TEXT);
            }
        }, []);

        useEffect(() => {
            if (userProfile) getTeamDetail();
        }, [userProfile]);

        async function getTeamDetail() {
            let data = {
                uid: userProfile && userProfile.uid,
                tmid: teamID
            };
            let result = await doGetTeamDetail(data);
            if (!result) {
                props.history.push('/');
            }
        }

        if (currentTeam != null)
            return (
                <div class="profile-page">
                    <ProfileHeader eventID={paramsQuery.evid} teamID={teamID} />

                    <div class="profile-ctn">
                        <div class="profile-wrap">
                            <div class="profile-left">
                                {currentTeam ? (
                                    <>
                                        <TeamStats teamID={teamID} />
                                        <ListMember teamID={teamID} eventID={eventID} />
                                    </>
                                ) : null}
                            </div>
                            <div class="profile-right">
                                {currentTeam ? (
                                    <>
                                        <Member1000 teamID={teamID} eventID={eventID} />
                                        <EventList data={currentTeam.evls} teamID={teamID} />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
    })
);
export default TeamProfile;
