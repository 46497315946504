import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { GLOBAL_CONFIG, GROUP_ACTION } from '../helpers/ConstDefined';
import { log } from '../utils';
import _ from 'lodash';

export default class GroupStore {
    @observable loadingGroup = false;
    @observable currentGroup = null;
    @observable groupRank = null; //rank of current group in team
    @observable userRank = null; // rank of user in group
    @observable ownerGroup = null; // handle owner group info

    @observable listLeaderboard = []; //bảng xep hạng các thanh viên trong group
    @observable totalMemberGroup = 0; //tổng member trong group
    @observable pageLeaderboard = 0;

    @observable listGroupRequest = null; //list all group
    @observable totalGroupRq = 0;
    @observable pageGroupRq = 0;

    @observable listGroupJoin = null; // danh sách các group đã được duyệt join team
    @observable totalGroupJoin;
    @observable pageGroupJoin = 0;

    @observable listMemberChoiseLeader = []; //list member of group in dialog choise leader
    @observable totalRecordChoiseLeader = 0;
    @observable pageChoiseLeader = 0;

    @observable listGroupJoinFilter =  null;
    @observable totalGroupJoinFilter;
    @observable pageGroupJoinFilter = 0;

    @observable listMemberJoined = []; //danh sách member đã join trong group
    @observable totalMemberJoined;
    @observable pageMemberJoined;

    @observable listMemberRequestJoin = []; //danh sách member request join group
    @observable totalMemberRqJoin;
    @observable pageMemberRqJoin;

    @observable func = 0; //0 : group list , 1 : group detail

    @action.bound
    doApproveMember(uid) {
        const obj = _.find(this.listMemberRequestJoin, (item) => {
            return item.uid == uid;
        });
        if (obj) {
            this.listMemberJoined.push(obj);
            _.remove(this.listMemberRequestJoin, (item) => {
                return item.uid == uid;
            });
            this.currentGroup.mem += 1;
        }
        this.totalMemberJoined += 1;
        this.totalMemberRqJoin -= 1;
        this.currentGroup.rmem -= 1;
    }

    @action.bound
    doRejectMember(uid) {
        _.remove(this.listMemberRequestJoin, (item) => {
            return item.uid == uid;
        });
        this.totalMemberRqJoin -= 1;
        this.currentGroup.rmem -= 1;
    }

    @action.bound
    doSetStatusJoinGroup(val) {
        if (this.currentGroup) this.currentGroup.jsts = val;
    }

    @action.bound
    doSetStatusJoinEventGroup(val) {
        if (this.currentGroup) this.currentGroup.gejsts = val;
    }

    @action.bound
    doSetOwnerGroupInfo(data) {
        this.ownerGroup = data;
        _.remove(this.listMemberJoined, (item) => {
            return item.uid == data.uid;
        });
        this.totalMemberJoined -= 1; // giảm 1 member trong list join cho paging đúng
    }

    @action.bound
    doSetFunc(val) {
        this.func = val;
    }

    @action.bound
    async doGetGroupRank(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.USER.RANK_ME, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                    result = false;
                } else {
                    
                    this.groupRank = resp.data.list[0];
                    this.loadingGroup = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doClearUserRank() {
        this.userRank = null;
    }

    @action.bound
    async doGetUserRank(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.USER.RANK_ME, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                    result = false;
                } else {
                    
                    this.userRank = resp.data.list[0];
                    this.loadingGroup = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doGetListMember(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageChoiseLeader += 1;
            params.from = params.size * this.pageChoiseLeader;
        } else {
            this.pageChoiseLeader = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    
                    if (!isLoadMore) this.listMemberChoiseLeader = [];
                    this.totalRecordChoiseLeader = resp.data.total;
                    if (isLoadMore) this.listMemberChoiseLeader = this.listMemberChoiseLeader.concat(resp.data.list);
                    else this.listMemberChoiseLeader = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    @action.bound
    doGetListMemberRqJoin(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageMemberRq += 1;
            params.from = params.size * this.pageMemberRq;
        } else {
            this.pageMemberRq = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    
                    if (!isLoadMore) this.listMemberRequestJoin = [];
                    this.totalMemberRqJoin = resp.data.total;
                    this.currentGroup.rmem = resp.data.total;

                    if (isLoadMore) this.listMemberRequestJoin = this.listMemberRequestJoin.concat(resp.data.list);
                    else this.listMemberRequestJoin = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    @action.bound
    doGetListMemberJoined(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageMemberJoined += 1;
            params.from = params.size * this.pageMemberJoined;
        } else {
            this.pageMemberJoined = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    if (!isLoadMore) this.listMemberJoined = [];
                    this.totalMemberJoined = resp.data.total;
                    this.currentGroup.mem = resp.data.total;
                    if (isLoadMore) this.listMemberJoined = this.listMemberJoined.concat(resp.data.list);
                    else this.listMemberJoined = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    //get list leaderboard member in group
    @action.bound
    doGetListLeaderboard(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageLeaderboard += 1;
            params.from = params.size * this.pageLeaderboard;
        } else {
            this.pageLeaderboard = 0;
            params.from = 0;
        }

        requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    if (!isLoadMore) this.listLeaderboard = [];
                    this.totalMemberGroup = resp.data.total;
                    if (isLoadMore) this.listLeaderboard = this.listLeaderboard.concat(resp.data.list);
                    else this.listLeaderboard = resp.data.list;
                    this.loading = false;
                    console.log(toJS(this.listLeaderboard));
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //apporove, reject group
    @action.bound
    async doJoinGroup(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.GROUP.DO_JOIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = null;
                } else {
                    //update local
                    result = true;

                    if (params.action == GROUP_ACTION.ADMIN_APPROVE_JOIN) {
                        const obj = _.find(this.listGroupRequest, (item) => {
                            return item.grid == params.grid;
                        });
                        if (obj) this.listGroupJoin.push(obj);
                        this.totalGroupJoin += 1;
                    }

                    _.remove(this.listGroupRequest, (item) => {
                        return item.grid == params.grid;
                    });

                    this.totalGroupRq -= 1;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //approve all group
    @action.bound
    async doApproveAll(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.GROUP.APPROVE_ALL, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loadingGroup = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    //update local
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = null;
            });
        return result;
    }

    //approve all member
    @action.bound
    async doApproveMemberAll(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.GROUP.APPROVE_ALL_MEMBER, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loadingGroup = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    //update local
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = null;
            });
        return result;
    }

    //get list group
    @action.bound
    doGetListGroupRequest(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageGroupRq += 1;
            params.from = params.size * this.pageGroupRq;
        } else {
            this.pageGroupRq = 0;
            params.from = 0;
        }

        requestAPIV2(API.GROUP.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    
                    if (!isLoadMore) this.listGroupRequest = [];
                    this.totalGroupRq = resp.data.total;
                    if (isLoadMore) this.listGroupRequest = this.listGroupRequest.concat(resp.data.list);
                    else this.listGroupRequest = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    //get list group
    @action.bound
    doGetListGroupJoin(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageGroupJoin += 1;
            params.from = params.size * this.pageGroupJoin;
        } else {
            this.pageGroupJoin = 0;
            params.from = 0;
        }

        requestAPIV2(API.GROUP.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    
                    if (!isLoadMore) this.listGroupJoin = [];
                    this.totalGroupJoin = resp.data.total;
                    if (isLoadMore) this.listGroupJoin = this.listGroupJoin.concat(resp.data.list);
                    else this.listGroupJoin = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    //get list group
    @action.bound
    doGetListGroupJoinFilter(params, isLoadMore) {
        this.loadingGroup = true; //show progress

        if (isLoadMore) {
            this.pageGroupJoinFilter += 1;
            params.from = params.size * this.pageGroupJoinFilter;
        } else {
            this.pageGroupJoinFilter = 0;
            params.from = 0;
        }

        requestAPIV2(API.GROUP.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingGroup = false;
                } else {
                    
                    if (!isLoadMore) this.listGroupJoinFilter = [];
                    this.totalGroupJoinFilter = resp.data.total;
                    if (isLoadMore) this.listGroupJoinFilter = this.listGroupJoinFilter.concat(resp.data.list);
                    else this.listGroupJoinFilter = resp.data.list;
                    this.loadingGroup = false;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
            });
    }

    @action.bound
    async doCreateGroup(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.GROUP.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loadingGroup = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = null;
                } else {
                    //update local
                    this.currentGroup = resp.data;
                    result = resp.data;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doActionGroup(params) {
        let result = null;
        this.loadingGroup = true; //show progress

        await requestAPIV2(API.GROUP.REQUEST_JOIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loadingGroup = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    //update local
                    result = true;
                    if(params.action == GROUP_ACTION.DELETE_MEMBER)
                    {
                        this.totalMemberJoined -= 1;
                        _.remove(this.listMemberJoined,(item) => {
                            return item.uid == params.pfid
                        });
                        this.currentGroup.mem -= 1;
                    }

                    if(params.action == GROUP_ACTION.LEAVE)
                    {
                        _.remove(this.listLeaderboard, (item) => {
                            return item.grid == params.grid;
                        })
                    }
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doDeleteGroup(params) {
        let result = null;
        this.loadingGroup = true; //show progress
        await requestAPIV2(API.GROUP.DELETE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loadingGroup = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else {
                    //update local
                    result = true;
                    _.remove(this.listGroupJoin, (item) => {
                        return item.grid == params.grid;
                    });
                    this.totalGroupJoin -= 1;
                }
            })
            .catch((error) => {
                
                this.loadingGroup = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doUpdateGroup(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.GROUP.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp;
                } else {
                    //update local
                    this.currentGroup.name = params.name;
                    this.currentGroup.desc = params.desc;
                    this.currentGroup.ava = params.ava;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetGroupDetail(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.GROUP.DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.currentGroup = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    //update local
                    const obj1 = _.find(this.listMemberJoined, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }
}
