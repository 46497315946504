import { SPORT_TYPE_ACTIVITY } from '../helpers/ConstDefined';
import { convertShortDuration } from './index';
var numeral = require('numeral');
import _ from 'lodash';
import { toJS } from 'mobx';

function buildAvgPaceForAll(duration, distance) {
    var avgPace = duration > 0 && distance > 0 ? duration / (distance / 1000) : null;
    return convertShortDuration(avgPace) + ' /km';
}

function buildAvgPaceSwimming(duration, distance) {
    var avgPace = duration > 0 && distance > 0 ? duration / (distance / 100) : null;
    return convertShortDuration(avgPace) + ' /100m';
}

export function buildAvgSpeed(duration, distance) {
    var avgSpeed = duration > 0 && distance > 0 ? distance / 1000 / (duration / 3600) : null;
    return numeral(avgSpeed).format('0,0.[0]') + ' km/h';
}

export function buildDistance(sport, distance, round) {
    //var distanceValue = this.sport == SportType.SWIMMING.index ? '${formatNumber(this.distance)} m' : '${formatNumber(this.distance / 1000)} km';
    let result;
    if (sport == SPORT_TYPE_ACTIVITY.SWIMMING) {
        result = Math.round(distance * 100) / 100;
        if (round) return numeral(result).format('0,0.[0]') + ' m';
        else return numeral(result).format('0,0.[00]') + ' m';
    } else {
        result = Math.round((distance / 1000) * 100) / 100;
        if (round) return numeral(result).format('0,0.[0]') + ' km';
        else return numeral(result).format('0,0.[00]') + ' km';
    }
}

export function buildAvgPace(sport, duration, distance) {
    if (duration > 0 && distance > 0) {
        if (sport == SPORT_TYPE_ACTIVITY.SWIMMING) return buildAvgPaceSwimming(duration, distance);
        else if (sport == SPORT_TYPE_ACTIVITY.CYCLING) return buildAvgSpeed(duration, distance);
        else return buildAvgPaceForAll(duration, distance);
    } else return '-:--/km';
}

//------- laps chart-------------------
export function calcLapsChart(data) {
    if (!data || data.length == 0) return [];

    const minItem = _.minBy(data, (item) => {
        return item.pace;
    });
    const minVal = minItem.pace; // min pace

    const result = data.map((item) => {
        if (item.pace == minVal) item.percent = 98; //100: fix issue 100% padding
        else item.percent = 100 / (item.pace / minVal);
        return item;
    });

    return result;
}
