import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from '../../../../../utils';

const DeleteGroupDialog = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore'
)(
    observer((props) => {
        const { data, navigation } = props;

        useEffect(() => {}, []);

        const { openDialogDeleteGroup } = props;
        const { userProfile } = props.userStore;
        const { doDeleteGroup, doSetStatusJoinGroup } = props.groupStore;
        const { currentEvent, doSetGroupIDEvent } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doSetFunc } = props.teamManageStore;
        
        const { t } = useTranslation();

        async function deleteGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.grid
            };
            let result = await doDeleteGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                //check và remove grid in currentEvent nếu xóa chính nhóm đang join
                if(currentEvent.grid == data.grid)
                {
                    doSetGroupIDEvent(0);
                    doSetStatusJoinGroup(0);
                    doSetFunc(0); //back lại screen danh sách Group
                }
                if(navigation) navigation.goBack();
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogDeleteGroup}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={props.onClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <div className="logo-row">
                                        <p className="glogo">
                                            <img src={getImageResource(data.ava, true)} alt="" />
                                        </p>
                                    </div>
                                    <p className="name">{data.name}</p>
                                    <div className="owner-name-row">
                                        <p className="owner-name">{data.ownm}</p>
                                    </div>
                                    <div className="txt">
                                        <p>{t('team.groupdetail.delete_group_confirm')}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={() => deleteGroup()}>
                                            {t('global.delete_group')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DeleteGroupDialog;
