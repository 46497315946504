import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LEADERBOARD_TYPE, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';

import GroupHeader from './GroupHeader';
import GroupRankEmpty from './GroupRankEmpty';
import GroupRankList from './GroupRankList';
import EditGroupDialog from '../EditGroupDialog';

let nameSearch;

const GroupDetail = inject(
    'globalStore',
    'teamManageStore',
    'groupStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { doSetFunc } = props.teamManageStore;
        const { currentGroup, listLeaderboard, doGetListLeaderboard } = props.groupStore;
        const { appVersion } = props.globalStore;

        const [openDialogEditGroup, setOpenDialogEditGroup] = React.useState(false);

        useEffect(() => {
            getLeaderboardMember();
        }, []);

        const { t } = useTranslation();

        function getLeaderboardMember(name, isLoadMore) {
            let data = {
                size: appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.USER_GROUP,
                value: currentGroup.grid,
                name
            };
            doGetListLeaderboard(data, isLoadMore);
        }

        const onEditGroup = () => {
            setOpenDialogEditGroup(true);
        };

        const onLoadMoreMemberGroup = () => {
            getLeaderboardMember(nameSearch, true);
        };

        const onSearchMemberGroup = (name) => {
            nameSearch = name;
            getLeaderboardMember(name);
        };

        return (
            <div className="group-details-page">
                <div className="group-details-wrap">
                    <a href="javascript:void(0);" onClick={() => doSetFunc(0)} className="back-btn">
                        {t('global.back')}
                    </a>
                    {currentGroup ? (
                        <GroupHeader data={currentGroup} onEditGroup={onEditGroup} eventID={eventID} teamID={teamID} />
                    ) : null}
                    {listLeaderboard &&
                    currentGroup &&
                    currentGroup.gejsts == GROUP_JOIN_STATUS.JOINED &&
                    listLeaderboard.length > 0 ? (
                        <GroupRankList
                            eventID={eventID}
                            teamID={teamID}
                            valSearch={nameSearch}
                            onLoadMoreMemberGroup={onLoadMoreMemberGroup}
                            onSearchMemberGroup={onSearchMemberGroup}
                        />
                    ) : (
                        <GroupRankEmpty
                            eventID={eventID}
                            teamID={teamID}
                            valSearch={nameSearch}
                            onSearchMemberGroup={onSearchMemberGroup}
                        />
                    )}
                </div>
                {currentGroup ? (
                    <EditGroupDialog
                        openDialogEditGroup={openDialogEditGroup}
                        eventID={eventID}
                        teamID={teamID}
                        onClose={() => setOpenDialogEditGroup(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default GroupDetail;
