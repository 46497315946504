import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { GLOBAL_CONFIG } from '../helpers/ConstDefined';
import { log } from '../utils';
import _ from 'lodash';

export default class TeamStore {
    @observable loadingTeam = false;
    @observable listUser = []; //list all user of team
    @observable userTeam = null; //handle team of user
    @observable userTeamDetail = null;
    @observable currentTeam = null; //handle current team

    @observable currentMember = null;
    @observable memberDetail = null;
    @observable memberEventInfo = null;

    @observable teamStatisticsInfo = null; // all statistics info of team by weekly, monthly and year..
    @observable teamRank = null;

    //filter params
    @observable teamName = '';
    @observable sportType = null; // sport type (run, swim...)
    @observable tabTeamType = 0; //tab navigation team type (school, company, club...)

    @observable listTeam = null; //list all team
    @observable totalTeam = 0;
    @observable pageTeam = 0;

    @action.bound
    setTeamNameFilter(val) 
    {
        this.teamName = val;
    }

    @action.bound
    setTabTeamType(tab) {
        this.tabTeamType = tab;
    }

    @action.bound
    setSportType(type)
    {
        this.sportType = type;
    }

    @action.bound
    setTeamDetailData(team) {
        this.currentTeam = team;
    }


    @action.bound
    doSetCurrentMember(member) {
        this.currentMember = member;
    }

    @action.bound
    doSetCurrentTeam(team) {
        this.currentTeam = team;
    }

    @action.bound
    async doGetTeamRank(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.RANK_ME, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    this.teamRank = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doGetUserTeam(user, eventID) {
        if (user == null) return null;
        console.log('eventID', eventID);

        if (user.hasOwnProperty('evls')) {
            this.userTeam = _.find(user.evls, (item) => {
                return item.evid == eventID;
            });
        }
        log('userTeam', this.userTeam);
    }

    @action.bound
    doSetUserTeam(teamData) {
        if (teamData == null) this.userTeam.tid = 0;
        else this.userTeam = teamData;
    }

    @action.bound
    doUpdateTeamTarget(tgid) {
        this.userTeam.tgid = tgid;
    }

    @action.bound
    doSetJoinTeamStt(stt) {
        if (this.userTeam != null) this.userTeam.jsts = stt;
        if (this.currentTeam != null) this.currentTeam.jsts = stt;

        if (stt == 0) {
            if (this.userTeam && this.currentTeam && this.userTeam.tmid == this.currentTeam.tmid) {
                this.userTeam.tmid = 0;
            }
        }
    }

    @action.bound
    doUpdateSttJoinTeam(tmid) {
        const obj = _.find(this.listTeam, (item) => {
            return item.tmid ==  tmid;
        });
        if(obj) obj.jsts = 2;
        this.currentTeam.jsts = 2;
    }

    //get list team
    @action.bound
    doGetListTeam(params, isLoadMore) {
        this.loadingTeam = true; //show progress

        if (isLoadMore) {
            this.pageTeam += 1;
            params.from = params.size * this.pageTeam;
        } else {
            this.pageTeam = 0;
            params.from = 0;
        }

        requestAPIV2(API.TEAM.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingTeam = false;
                } else {
                    
                    if (!isLoadMore) this.listTeam = [];
                    this.totalTeam = resp.data.total;
                    if (isLoadMore) this.listTeam = this.listTeam.concat(resp.data.list);
                    else this.listTeam = resp.data.list;
                    this.loadingTeam = false;
                }
            })
            .catch((error) => {
                
                this.loadingTeam = false;
            });
    }

    @action.bound
    async doCreateTeam(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    //update local
                    this.loading = false;
                    result = resp.data;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doUpdateTeam(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp;
                } else {
                    //update local
                    this.currentTeam.name = params.name;
                    this.currentTeam.desc = params.desc;
                    this.currentTeam.lgid = params.lgid;
                    this.currentTeam.sport = params.sport;
                    this.currentTeam.ava = params.ava;
                    this.currentTeam.cover = params.cover;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //request join team or leave team
    @action.bound
    async doRequestUpdateTeam(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.UPDATE_TEAM, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp;
                } else {
                    
                    this.loading = false;
                    if (params.tmid == 0) {
                        this.userTeam.tmid = 0;
                    }
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doJoinLeaveTeam(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.REQUEST_JOIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    //update local
                    const obj = _.find(this.listTeam, (item) => {
                        return item.tmid == params.tmid;
                    });
                    if (obj) obj.jsts = params.action == 0 ? 2 : 0;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get profile member detail
    @action.bound
    async doGetMemberDetail(params, eventID) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.GET_PROFILE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    
                    this.memberDetail = resp.data;

                    //get info event user join
                    if (resp.data != null && resp.data.hasOwnProperty('evls')) {
                        this.memberEventInfo = _.find(resp.data.evls, (item) => {
                            return item.evid == eventID;
                        });
                    }
                    result = toJS(this.memberEventInfo);
                    console.log(result)
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //do follow, unfollow
    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    

                    //update local
                    if (this.memberDetail) this.memberDetail.fists = resp.data.fists;
                    if (this.currentMember) this.currentMember.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get detail info of team
    @action.bound
    async doGetTeamDetail(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.currentTeam = resp.data;
                    console.log('doGetTeamDetail', toJS(this.currentTeam));
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get detail info of team
    @action.bound
    async doGetUserTeamDetail(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.userTeamDetail = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //Get statistics team
    @action.bound
    doGetStatisticsTeam(params) {
        this.loading = true; //show progress
        requestAPIV2(API.TEAM.STATISTICS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.teamStatisticsInfo = resp.data;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get all user of team
    @action.bound
    doGetTeamUsers(params) {
        this.loading = true; //show progress
        requestAPIV2(API.TEAM.GET_USERS_TEAM, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.listUser.push(resp.data);
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    @action.bound
    doClearUserTeam() {
        this.userTeam = null;
        this.userTeamDetail = null;
    }
}
