import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { CHALLENGE_TYPE } from '../helpers/ConstDefined';
import { log } from '../utils';

export default class ChallengeStore {
    @observable tabIndex = CHALLENGE_TYPE.ALL;
    @observable listChallenge = null;
    @observable challengeDetail = null;
    @observable currentChallenge = null;
    @observable listComment = []; //all comment of feed
    @observable listUserLike = []; //list user like feed
    @observable listUserDisLike = []; //list user dislike feed

    // total record when fetch like, comment
    @observable totalLike = 0;
    @observable totalDislike = 0;
    @observable totalComment = 0;

    @observable loadingChallenge = false;
    @observable totalRecord = 0;
    @observable pageIndex = 1;

    @action.bound
    doSetPageIndex(page) {
        this.pageIndex = page;
    }

    @action.bound
    doIncreasePageIndex() {
        this.pageIndex += 1;
    }

    @action.bound
    doSetTabIndex(tab) {
        this.tabIndex = tab;
    }

    @action.bound
    doSetCurrentChallenge(challenge) {
        this.currentChallenge = challenge;
    }

    //get challenge detail
    @action.bound
    doGetChallengeDetail(params) {
        this.loading = true; //show progress
        requestAPIV2(API.CHALLENGE.DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if(resp.ercd == 117) {
                    this.challengeDetail = null;
                    this.loading = false;
                }
                else if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.challengeDetail = resp.data;
                    //this.challengeDetail.sts = 3;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    @action.bound
    async doCreateChallenge(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.CHALLENGE.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    //update store
                    this.challengeDetail = resp.data;
                    this.loading = false;
                    result = resp.data.tgid;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doDeleteChallenge(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.CHALLENGE.DELETE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    this.challengeDetail = null;
                    this.loading = false;
                    result = resp.ercd;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doUpdateChallenge(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.CHALLENGE.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    //updte store
                    this.challengeDetail.cdis = params.cdis;
                    this.challengeDetail.cdays = params.cdays;
                    this.challengeDetail.cxdis = params.cxdis;
                    this.challengeDetail.desc = params.desc;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get list challenge
    @action.bound
    doGetListChallenge(params, isLoadMore) {
        this.loadingChallenge = true; //show progress
        if (!isLoadMore) this.listChallenge = [];
        requestAPIV2(API.CHALLENGE.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingChallenge = false;
                } else {
                    
                    this.totalRecord = resp.data.total;
                    if (isLoadMore) this.listChallenge =  this.listChallenge.concat(resp.data.list);
                    else this.listChallenge = resp.data.list;
                    this.loadingChallenge = false;
                }
            })
            .catch((error) => {
                
                this.loadingChallenge = false;
            });
    }

    //get list comment of challenge
    @action.bound
    async doGetListComment(params, isLoadMore) {
        let result = null;
        this.loading = true; //show progress
        if (!isLoadMore) this.listComment = [];
        await requestAPIV2(API.CHALLENGE.LIST_COMMENT, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.totalComment = resp.data.total;
                    if (isLoadMore) this.listComment = this.listComment.concat(resp.data.list);
                    else this.listComment = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get list like of activity
    @action.bound
    async doGetListUserLike(params, isLoadMore) {
        let result = null;
        this.loading = true; //show progress
        if (!isLoadMore) this.listUserLike = [];
        await requestAPIV2(API.CHALLENGE.LIST_LIKE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    this.totalLike = resp.data.total;
                    if (isLoadMore) this.listUserLike = this.listUserLike.concat(resp.data.list);
                    else this.listUserLike = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get list dislike of activity
    @action.bound
    async doGetListUserDisLike(params, isLoadMore) {
        let result = null;
        this.loading = true; //show progress
        if (!isLoadMore) this.listUserDisLike = [];
        await requestAPIV2(API.CHALLENGE.LIST_LIKE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    this.totalDislike = resp.data.total;
                    if (isLoadMore) this.listUserDisLike = this.listUserDisLike.concat(resp.data.list);
                    else this.listUserDisLike = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //do follow, unfollow
    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    

                    //update local
                    const obj = _.find(this.listUserLike, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj) obj.fists = resp.data.fists;

                    const obj1 = _.find(this.listUserDisLike, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }
}
