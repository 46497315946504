import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SPORT_TYPE } from '../../../../data/master';
import { stripedHtml } from '../../../../utils';

const TeamListToolbar = inject(
    'authStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { teamName, tabTeamType, sportType, setSportType, setTeamNameFilter } = props.teamStore;
        const cssShowClear = teamName && teamName.length > 0 ? 'search-box active' : 'search-box';

        const { t } = useTranslation();

        if (!sportType) setSportType(SPORT_TYPE[0]);

        const handleChangeName = (val) => {
            setTeamNameFilter(val);
        };

        const onChangeSportType = (data) => {
            setSportType(data);
            getListTeam(data);
            setTeamNameFilter('');
            props.onChangeTab();
        };

        function getListTeam(data) {
            let params = {
                sport: data.id,
                lgid: tabTeamType,
                name: teamName
            };
            props.onSearchTeam(params);
        }

        function MenuItem(props) {
            const { data } = props;

            return <li onClick={() => onChangeSportType(data)}>{t(data.title)}</li>;
        }

        function searchTeam() {
            let params = {
                sport: sportType.id,
                lgid: tabTeamType,
                name: teamName
            };
            props.onSearchTeam(params);
        }

        function resetSearch() {
            setTeamNameFilter('');
            let params = {
                sport: sportType.id,
                lgid: tabTeamType,
            };
            props.onSearchTeam(params);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchTeam();
            }
        };

        return (
            <div className="teams-list--header">
                <h2 className="title">{t('global.list_team')}</h2>
                <div className="filter-box">
                    <p className="lb">{t('global.sport_type')}:</p>
                    <div className="uiselector">
                        <p
                            className="uiselector--click"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {sportType && t(sportType.title)}
                        </p>
                        <ul className="uiselector--list" aria-labelledby="list-server-choose">
                            {SPORT_TYPE.map((item) => {
                                return <MenuItem data={item} key={item.id} />;
                            })}
                        </ul>
                    </div>
                </div>
                <div className={cssShowClear}>
                    <button className="del-btn" onClick={() => resetSearch()}></button>
                    <input
                        id="search"
                        type="text"
                        className="form-control form-search"
                        value={teamName}
                        onKeyDown={handleKeyDown}
                        onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        placeholder={t('global.team_search')}
                    />

                    <button className="search-btn" onClick={() => searchTeam()}>
                        {t('global.search')}
                    </button>
                </div>
            </div>
        );
    })
);

export default TeamListToolbar;
