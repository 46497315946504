import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import MemberItem from './MemberItem';
import { formatNumber } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const MemberList = inject(
    'authStore',
    'teamManageStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { loading, listAdmin, listMember, totalMember } = props.teamManageStore;
        const { currentTeam } = props.teamStore;

        const isShowLoadMore = listMember == null ? false : listMember.length < totalMember;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        const getMemberListMore = () => {
            props.onLoadMoreMember();
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getMemberListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="manage-members">
                <div className="manage-wbox">
                    <h2 className="manage-title-large">{t('global.admins')}</h2>
                    <h3 className="manage-title-small">Owner</h3>
                    {listAdmin &&
                        listAdmin.map((item) => {
                            if (item.uid == currentTeam.owner)
                                return (
                                    <MemberItem
                                        data={item}
                                        key={item.uid}
                                        isOwner={true}
                                        eventID={eventID}
                                        teamID={teamID}
                                    />
                                );
                            else return null;
                        })}

                    <h3 className="manage-title-small">Admin</h3>
                    <ul className="manage-list admin">
                        {listAdmin &&
                            listAdmin.map((item) => {
                                if (item.uid != currentTeam.owner)
                                    return (
                                        <li>
                                            <MemberItem
                                                data={item}
                                                key={item.uid}
                                                isAdmin={true}
                                                eventID={eventID}
                                                teamID={teamID}
                                            />
                                        </li>
                                    );
                                else return null;
                            })}
                    </ul>
                </div>

                <div className="manage-wbox">
                    <h2 className="manage-title-large">
                        {t('global.member1')} <span className="num">({formatNumber(totalMember)})</span>
                    </h2>
                    <ul className="manage-list members">
                        {listMember &&
                            listMember.map((item) => {
                                return (
                                    <li>
                                        <MemberItem
                                            data={item}
                                            key={item.uid}
                                            isAdmin={false}
                                            eventID={eventID}
                                            teamID={teamID}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                    <LoadMore />
                </div>
            </div>
        );
    })
);

export default MemberList;
