import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { GROUP_ACTION, GROUP_JOIN_STATUS } from 'helpers/ConstDefined';

const LeaveEventDialog = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, navigation } = props;
        const { openDialogLeaveEvent } = props;
        const { userProfile } = props.userStore;
        const { doActionEvent } = props.userEventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function actionEvent() {
            const params = {
                uid: userProfile.uid,
                evid: data.evid,
                action: data.jsts == GROUP_JOIN_STATUS.REQUESTING ? GROUP_ACTION.USER_CANCEL_JOIN : GROUP_ACTION.LEAVE
            };
            let result = await doActionEvent(params);
            if (result != null && result.hasOwnProperty('jsts')) {
                data.jsts = result.jsts;
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
                props.onClose();
            }
        }

        return (
            <Dialog open={openDialogLeaveEvent}>
                <div
                    class="uprace-popup uprace-popup--v2"
                    id="remove-event"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content rounded">
                            <div class="popup-body">
                                <div class="icon-warning"></div>
                                <div>
                                    <h5 class="popup-title">{t('eu.leave_event')}</h5>
                                    <div class="popup-text">{t('eu.leave_event_confirm')}</div>
                                    <div class="action">
                                        <button
                                            type="button"
                                            class="btn btn-outline-blue"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => actionEvent()}>
                                            {t('eu.leave_event')}
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-blue"
                                            data-dismiss="modal"
                                            onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default LeaveEventDialog;
