import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const TermsOfUse = inject('globalStore')(
    observer((props) => {
        const { language, doSetTabMainTop } = props.globalStore;

        useEffect(() => {
            doSetTabMainTop(null);
            window.scrollTo(0, 0);
        }, []);

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        function TermsUprace() {
            if (language == 'en') return <TermsUpraceEN />;
            else return <TermsUpraceVI />;
        }

        function TermsUpraceEN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>THỎA THUẬN SỬ DỤNG DỊCH VỤ</p>
                    </div>
                    <ul class="list">
                        <section className="main-content">
                            <div class="list-desc">
                                <p>
                                    Chào mừng bạn đã đến với trang web và ứng dụng UPRACE. UPRACE cung cấp các sản phẩm
                                    và dịch vụ dựa trên những điều kiện dưới đây.
                                </p>
                                <p>
                                    Khi bạn sử dụng sản phẩm và dịch vụ do UPRACE cung cấp, bạn đã xác nhận đồng ý với
                                    những điều khoản sử dụng sau. Vui lòng đọc kỹ các điều khoản dưới đây.
                                </p>

                                <h4 className="title">I. CHÍNH SÁCH VÀ QUY ĐỊNH CHUNG</h4>
                                <h5 className="title">1. Quyền Truy Cập</h5>
                                <p>
                                    Với điều kiện bạn tuân theo các <i>Thỏa thuận sử dụng</i> và các khoản thanh toán
                                    cho các dịch vụ bổ sung, bạn có quyền truy cập và sử dụng các dịch vụ của UPRACE.
                                    Quyền truy cập này không bao gồm bất kỳ giao dịch mua đi bán lại hoặc sử dụng vì mục
                                    đích thương mại các dịch vụ và nội dung của UPRACE; các thông tin mô tả, đánh giá,
                                    bình luận; bất kỳ sự sao chép hoặc download thông tin để phục vụ lợi ích của bên thứ
                                    ba; hoặc việc sử dụng các công cụ khai thác dữ liệu. UPRACE có quyền khiếu nại tất
                                    cả các hành động sao chép, sử dụng với mục đích thương mại mà không được sự đồng ý
                                    từ UPRACE. Bạn có thể bị tước quyền truy cập vào UPRACE nếu bạn không tuân theo các{' '}
                                    <i>Thỏa thuận sử dụng</i> này.
                                </p>
                                <h5 className="title">2. Tạo Tài Khoản Và Chế Độ Bảo Mật</h5>
                                <p>Khi đăng ký tham gia UPRACE, bạn vui lòng:</p>
                                <ul className="dot-list">
                                    <li>Cung cấp những thông tin chính xác, đầy đủ theo bản đăng ký mẫu của UPRACE.</li>
                                    <li>
                                        Duy trì và cập nhật những thông tin, thay đổi mới nhất một cách chính xác và đầy
                                        đủ.
                                    </li>
                                </ul>
                                <p>
                                    Sự không chính xác của những thông tin bạn cung cấp có thể làm bạn không tận dụng
                                    được hết những sản phẩm và dịch vụ UPRACE cung cấp. Trong một số trường hợp đặc
                                    biệt, UPRACE có quyền từ chối cung cấp dịch vụ cho bạn và đình chỉ tài khoản của bạn
                                    vào thời điểm hiện tại và trong tương lai nếu những thông tin mà bạn cung cấp không
                                    chính xác hoặc UPRACE có lý do nghi ngờ tính trung thực của những thông tin mà bạn
                                    cung cấp.
                                </p>
                                <p>
                                    Chúng tôi sẽ không sử dụng những thông tin cá nhân của bạn nếu chưa được sự cho phép
                                    của bạn. Bạn có thể yên tâm rằng khi bạn cung cấp thông tin cho UPRACE, thông tin
                                    của bạn luôn được bảo mật tuyệt đối. Thông tin của bạn chỉ được sử dụng với mục đích
                                    gửi thông báo cho bạn về các chương trình, sự kiện… diễn ra trên UPRACE. UPRACE cam
                                    kết sẽ không tiết lộ thông tin của bạn cho một bên thứ ba mà không có sự đồng ý,
                                    ngoại trừ yêu cầu cung cấp bởi pháp luật.
                                </p>
                                <p>
                                    Quyền truy cập và sử dụng tài khoản chỉ dành riêng cho cá nhân có thẩm quyền. Mọi
                                    hành vi cố ý truy cập trái phép đều có thể bị truy tố.
                                </p>

                                <h5 className="title">3. Tài Khoản Của Bạn</h5>
                                <p>
                                    Nếu bạn sử dụng dịch vụ của UPRACE, bạn có trách nhiệm duy trì sự bảo mật tài khoản
                                    và mật khẩu của bạn, cũng như hạn chế sự truy cập vào máy tính và thiết bị di động
                                    cá nhân. Bạn cũng đồng ý chịu trách nhiệm cho tất cả các hoạt động phát sinh dưới
                                    tài khoản và mật khẩu của bạn. UPRACE có quyền đơn phương từ chối cung cấp dịch vụ,
                                    đóng tài khoản cá nhân, xóa bỏ hoặc thay đổi nội dung của bạn.
                                </p>

                                <h5 className="title">4. Bình Luận, Đánh Giá, Và Những Nội Dung Khác</h5>
                                <p>
                                    Bạn có thể đăng tải các bình luận, đánh giá và những nội dung khác; và gửi các gợi
                                    ý, ý tưởng, bình luận, câu hỏi, hoặc những loại thông tin khác nếu như những thông
                                    tin này không chứa các nội dung bất hợp pháp, đồi trụy, đe dọa, phỉ báng, xâm phạm
                                    quyền riêng tư cá nhân, xâm phạm quyền sở hữu trí tuệ, hoặc những nội dung gây hại
                                    cho bên thứ ba, hoặc những nội dung không phù hợp với thuần phong mỹ tục, và không
                                    chứa virut, nội dung vận động chính trị, nội dung mang tính thương mại, hoặc bất kỳ
                                    hình thức thư rác nào.
                                </p>
                                <p>
                                    Khi bạn đăng tải thông tin hoặc gửi các loại tài liệu cho UPRACE, thì UPRACE có toàn
                                    quyền sử dụng, sao chép, thay đổi, biên dịch, công bố, hiển thị nội dung đó cho tất
                                    cả mọi người dưới bất kỳ hình thức nào trừ trường hợp có thỏa thuận khác giữa UPRACE
                                    và bạn. Đồng thời, bạn cũng cho phép UPRACE có quyền được sử dụng tên đi kèm với nội
                                    dung mà bạn gửi hoặc đăng tải. Bạn đảm bảo rằng bạn sở hữu và có toàn quyền sử dụng
                                    nội dung mà bạn đăng tải; rằng nội dung được đăng tải là chính xác; rằng việc sử
                                    dụng các nội dung bạn cung cấp không vi phạm Thỏa thuận sử dụng này và không gây hại
                                    cho bên thứ ba nào; và rằng bạn sẽ bồi thường cho UPRACE nếu như có bất kỳ khiếu
                                    kiện nào phát sinh từ những nội dung mà bạn cung cấp.
                                </p>
                                <p>
                                    UPRACE không chịu trách nhiệm sàng lọc, chỉnh sửa hoặc giám sát nội dung được người
                                    sử dụng đăng tải lên website và các sản phẩm liên quan, cũng như không thể đảm bảo
                                    tính chính xác của những ý kiến, bình luận này. Nếu nhận được thông tin về những vi
                                    phạm, gây tổn hại hoặc bất hợp pháp, UPRACE có quyền điều tra những cáo buộc trên để
                                    xác minh, và có quyền quyết định chấm dứt cung cấp dịch vụ đối với thành viên vi
                                    phạm những điều khoản sử dụng.
                                </p>
                                <p>
                                    Mặc dù đã có những quy định nêu trên, nhưng UPRACE cũng như những cá thể, tập thể
                                    liên quan không thể bảo đảm có thể chỉnh sửa hoặc xoá bỏ lập tức những nội dung vi
                                    phạm. UPRACE có quyền nhưng không phải là nghĩa vụ kiểm soát và thay đổi hoặc xóa bỏ
                                    bất kỳ nội dung nào. Cũng như UPRACE không phải chịu trách nhiệm pháp lý đối với
                                    những nội dung do bạn hoặc bất kỳ bên thứ 3 nào đăng tải trên UPRACE. Đồng thời,
                                    Khách hàng cũng có trách nhiệm cho mối liên hệ giữa bạn và những bạn khác. UPRACE có
                                    quyền, nhưng không có nghĩa vụ theo dõi các tranh chấp giữa các bạn với nhau.
                                </p>
                                <h5 className="title">5. Các Website Liên Kết</h5>
                                <p>
                                    Ngoại trừ nội dung trên website và các sản phẩm liên quan của UPRACE, chúng tôi
                                    không trực tiếp hay gián tiếp quản lý những website liên kết. UPRACE không hợp tác,
                                    tài trợ, xác thực hay sát nhập với những website đó, trừ khi sự hợp tác đó được công
                                    bố rõ ràng. Khi truy cập vào UPRACE, chúng tôi hy vọng bạn có thể hiểu rằng UPRACE
                                    không kiểm soát, quản lý những trang liên kết và không chịu trách nhiệm về nội dung
                                    của bất kỳ website liên kết nào.
                                </p>
                                <h5 className="title">6. Bản Quyền Sở Hữu</h5>
                                <p>
                                    Tất cả nội dung được hiển thị trên website và các sản phẩm liên quan UPRACE dưới bất
                                    kỳ hình thức nào như ký tự, hình ảnh, logo, video clip… là tài sản của UPRACE hoặc
                                    các đối tác cung cấp nội dung của UPRACE, được bảo vệ bởi luật pháp Việt Nam và các
                                    quy định bản quyền quốc tế. Sự biên soạn và hiển thị các nội dung này thông qua
                                    UPRACE là tài sản riêng của UPRACE.
                                </p>
                                <p>
                                    UPRACE là chủ bản quyền của website và các sản phẩm liên quan. UPRACE có quyền chỉnh
                                    sửa, thay đổi, sắp xếp lại nội dung website và các sản phẩm liên quan. Việc chỉnh
                                    sửa, thay đổi, sắp xếp lại hoặc tái sử dụng những nội dung này mà không có sự đồng ý
                                    của UPRACE đều là vi phạm quyền lợi hợp pháp của UPRACE.
                                </p>
                                <p>
                                    UPRACE luôn tôn trọng sở hữu trí tuệ của người khác, và chúng tôi yêu cầu bạn của
                                    chúng tôi cũng làm như vậy. Chúng tôi luôn cố gắng đảm bảo những dữ liệu trên
                                    website và các sản phẩm liên quan đều là hợp pháp, nhưng chúng tôi không chắc chắn
                                    có thể kiểm soát tất cả thông tin trên website và các sản phẩm liên quan. Khi phát
                                    hiện được bất kỳ hành vi vi phạm bản quyền nào trên website và các sản phẩm liên
                                    quan, Ban quản trị sẽ xoá nội dung đó khỏi website một cách nhanh nhất có thể.
                                </p>
                                <h5 className="title">7. Trách Nhiệm Pháp Lý</h5>
                                <p>
                                    Trừ phi có ghi chú khác bằng văn bản, tất cả dịch vụ, thông tin, nội dung, công cụ,
                                    sản phẩm (bao gồm cả phần mềm) của UPRACE hoặc được hiển thị trên website và các sản
                                    phẩm liên quan của UPRACE được cung cấp dựa trên thỏa thuận sử dụng và các quy định
                                    khác của UPRACE và các nhà cung cấp.
                                </p>
                                <p>
                                    Bạn đồng ý rằng việc sử dụng các dịch vụ, thông tin, nội dung, công cụ và sản phẩm
                                    của UPRACE hoặc được hiển thị trên website và các sản phẩm liên quan của UPRACE
                                    thuộc phạm trù rủi ro riêng của bạn. UPRACE không bảo đảm cho bất kỳ dịch vụ, thông
                                    tin, nội dung, công cụ của UPRACE hoặc được hiển thị trên website và các sản phẩm
                                    liên quan của UPRACE. UPRACE server và các thông điệp truyền thông được gửi đi từ
                                    UPRACE không chứa virut hay bất kỳ tác nhân gây hại nào cho bạn. Trừ phi có ghi chú
                                    khác bằng văn bản, UPRACE từ chối trách nhiệm pháp lý cho bất kỳ thiệt hại nào phát
                                    sinh từ việc sử dụng các dịch vụ, thông tin, nội dung, công cụ và sản phẩm của
                                    UPRACE hoặc được hiển thị trên website và các sản phẩm liên quan của UPRACE.
                                </p>
                                <p>
                                    Nếu các bộ luật, và quy định pháp luật được ban hành bởi Nhà Nước có bất kỳ nội dung
                                    nào mâu thuẫn với bất kỳ quy định về từ chối bảo đảm và trách nhiệm pháp lý, thì
                                    những điểm quy định đó không có hiệu lực áp dụng đối với bạn. Điều này đồng nghĩa
                                    với việc bạn có quyền lợi bổ sung.
                                </p>
                                <h5 className="title">9. Sự Cố Phát Sinh</h5>
                                <p>
                                    Khi đã sử dụng dịch vụ của UPRACE, bạn đã chấp nhận một số sự cố có thể phát sinh
                                    trong quá trình truy cập, và đồng ý rằng UPRACE và các đối tác liên quan sẽ không
                                    chịu trách nhiệm pháp lý về những thất thoát, thiệt hại xảy ra một cách trực tiếp
                                    hay gián tiếp trong khi truy cập vào website và các sản phẩm liên quan, khi tải dữ
                                    liệu, không loại trừ những tổn hại do virus, những tác động ảnh hưởng đến hệ thống
                                    máy tính, đường dây điện thoại, huỷ hoại các chương trình phần cứng, phần mềm, các
                                    trở ngại của đường truyền máy vi tính hoặc kết nối mạng.
                                </p>
                                <h5 className="title">10. Dừng Sử Dụng Dịch Vụ</h5>
                                <p>
                                    Bạn có thể dừng việc sử dụng tài khoản của bạn bất cứ khi nào bằng cách thông báo
                                    cho Ban quản trị của UPRACE. Bạn sẽ nhận được e-mail xác nhận và tài khoản của bạn
                                    sẽ ngưng hoạt động trong vòng 2 ngày làm việc. Bạn sẽ chịu trách nhiệm cho tất cả
                                    các chi phí phát sinh (nếu có) cho đến khi tài khoản ngưng hoạt động.
                                </p>
                                <h5 className="title">11. Ngưng Cung Cấp Dịch Vụ</h5>
                                <p>UPRACE có quyền ngưng cung cấp dịch vụ cho bạn nếu vi phạm những điều sau đây:</p>
                                <ul className="dot-list">
                                    <li>
                                        Thông tin bạn cung cấp không chính xác, không đầy đủ, không trung thực, hoặc
                                        Uprace.org có căn cứ để nghi ngờ độ chính xác của thông tin.
                                    </li>
                                    <li>
                                        Đăng tải lên website và các sản phẩm liên quan những nội dung không phù hợp như
                                        những nội dung có tính chất khiêu dâm, đồi truỵ, phỉ báng, thô tục, gây hiểu
                                        lầm, hoặc phạm pháp.
                                    </li>
                                    <li>
                                        Quấy rối, đe doạ hoặc phân biệt một cá nhân hoặc một tập thể vì lý do giới tính,
                                        tôn giáo, khuynh hướng tình dục, chủng tộc, dân tộc, tuổi tác hoặc khuyết tật.
                                    </li>
                                    <li>"Spam", quảng cáo trái phép hoặc bất kỳ hình thức xổ số, bài bạc nào.</li>
                                    <li>Sử dụng tài khoản cá nhân với mục đích thương mại.</li>
                                    <li>Vi phạm những điều khoản khác của UPRACE.</li>
                                </ul>

                                <p>
                                    UPRACE không chịu trách nhiệm với những nội dung do bạn đăng tải và sẽ cung cấp
                                    những nội dung này cho các cơ quan có thẩm quyền trong trường hợp cần thiết.
                                </p>
                                <h4 className="title">II. CHẤP NHẬN VÀ ĐỒNG Ý CÁC ĐIỀU KHOẢN SỬ DỤNG</h4>
                                <p>
                                    Khi đã sử dụng website và các sản phẩm liên quan đến UPRACE đồng nghĩa với việc bạn
                                    đã chấp nhận và đồng ý với những ràng buộc về mặt pháp lý, và tuân thủ theo quy chế
                                    hoạt động và điều khoản sử dụng của UPRACE.
                                </p>
                                <p>
                                    UPRACE có quyền thay đổi, bổ sung quy chế hoạt động và điều khoản sử dụng trên
                                    website và các sản phẩm liên quan vào bất cứ lúc nào. Người sử dụng có trách nhiệm
                                    cập nhật và theo dõi những thay đổi mới nhất trên website UPRACE. Nếu bạn không đồng
                                    ý với những thay đổi mới nhất, bạn có quyền yêu cầu ngưng sử dụng tài khoản tại
                                    website và các sản phẩm liên quan của UPRACE.
                                </p>
                                <p>
                                    Tài khoản của bạn trên UPRACE không dùng để trao đổi, mua bán với mục đích thương
                                    mại
                                </p>
                            </div>
                        </section>
                    </ul>
                </section>
            );
        }

        function TermsUpraceVI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>THỎA THUẬN SỬ DỤNG DỊCH VỤ</p>
                    </div>
                    <ul class="list">
                        <section className="main-content">
                            <div class="list-desc">
                                <p>
                                    Chào mừng bạn đã đến với trang web và ứng dụng UPRACE. UPRACE cung cấp các sản phẩm
                                    và dịch vụ dựa trên những điều kiện dưới đây.
                                </p>
                                <p>
                                    Khi bạn sử dụng sản phẩm và dịch vụ do UPRACE cung cấp, bạn đã xác nhận đồng ý với
                                    những điều khoản sử dụng sau. Vui lòng đọc kỹ các điều khoản dưới đây.
                                </p>

                                <h4 className="title">I. CHÍNH SÁCH VÀ QUY ĐỊNH CHUNG</h4>
                                <h5 className="title">1. Quyền Truy Cập</h5>
                                <p>
                                    Với điều kiện bạn tuân theo các <i>Thỏa thuận sử dụng</i> và các khoản thanh toán
                                    cho các dịch vụ bổ sung, bạn có quyền truy cập và sử dụng các dịch vụ của UPRACE.
                                    Quyền truy cập này không bao gồm bất kỳ giao dịch mua đi bán lại hoặc sử dụng vì mục
                                    đích thương mại các dịch vụ và nội dung của UPRACE; các thông tin mô tả, đánh giá,
                                    bình luận; bất kỳ sự sao chép hoặc download thông tin để phục vụ lợi ích của bên thứ
                                    ba; hoặc việc sử dụng các công cụ khai thác dữ liệu. UPRACE có quyền khiếu nại tất
                                    cả các hành động sao chép, sử dụng với mục đích thương mại mà không được sự đồng ý
                                    từ UPRACE. Bạn có thể bị tước quyền truy cập vào UPRACE nếu bạn không tuân theo các{' '}
                                    <i>Thỏa thuận sử dụng</i> này.
                                </p>
                                <h5 className="title">2. Tạo Tài Khoản Và Chế Độ Bảo Mật</h5>
                                <p>Khi đăng ký tham gia UPRACE, bạn vui lòng:</p>
                                <ul className="dot-list">
                                    <li>Cung cấp những thông tin chính xác, đầy đủ theo bản đăng ký mẫu của UPRACE.</li>
                                    <li>
                                        Duy trì và cập nhật những thông tin, thay đổi mới nhất một cách chính xác và đầy
                                        đủ.
                                    </li>
                                </ul>
                                <p>
                                    Sự không chính xác của những thông tin bạn cung cấp có thể làm bạn không tận dụng
                                    được hết những sản phẩm và dịch vụ UPRACE cung cấp. Trong một số trường hợp đặc
                                    biệt, UPRACE có quyền từ chối cung cấp dịch vụ cho bạn và đình chỉ tài khoản của bạn
                                    vào thời điểm hiện tại và trong tương lai nếu những thông tin mà bạn cung cấp không
                                    chính xác hoặc UPRACE có lý do nghi ngờ tính trung thực của những thông tin mà bạn
                                    cung cấp.
                                </p>
                                <p>
                                    Chúng tôi sẽ không sử dụng những thông tin cá nhân của bạn nếu chưa được sự cho phép
                                    của bạn. Bạn có thể yên tâm rằng khi bạn cung cấp thông tin cho UPRACE, thông tin
                                    của bạn luôn được bảo mật tuyệt đối. Thông tin của bạn chỉ được sử dụng với mục đích
                                    gửi thông báo cho bạn về các chương trình, sự kiện… diễn ra trên UPRACE. UPRACE cam
                                    kết sẽ không tiết lộ thông tin của bạn cho một bên thứ ba mà không có sự đồng ý,
                                    ngoại trừ yêu cầu cung cấp bởi pháp luật.
                                </p>
                                <p>
                                    Quyền truy cập và sử dụng tài khoản chỉ dành riêng cho cá nhân có thẩm quyền. Mọi
                                    hành vi cố ý truy cập trái phép đều có thể bị truy tố.
                                </p>

                                <h5 className="title">3. Tài Khoản Của Bạn</h5>
                                <p>
                                    Nếu bạn sử dụng dịch vụ của UPRACE, bạn có trách nhiệm duy trì sự bảo mật tài khoản
                                    và mật khẩu của bạn, cũng như hạn chế sự truy cập vào máy tính và thiết bị di động
                                    cá nhân. Bạn cũng đồng ý chịu trách nhiệm cho tất cả các hoạt động phát sinh dưới
                                    tài khoản và mật khẩu của bạn. UPRACE có quyền đơn phương từ chối cung cấp dịch vụ,
                                    đóng tài khoản cá nhân, xóa bỏ hoặc thay đổi nội dung của bạn.
                                </p>

                                <h5 className="title">4. Bình Luận, Đánh Giá, Và Những Nội Dung Khác</h5>
                                <p>
                                    Bạn có thể đăng tải các bình luận, đánh giá và những nội dung khác; và gửi các gợi
                                    ý, ý tưởng, bình luận, câu hỏi, hoặc những loại thông tin khác nếu như những thông
                                    tin này không chứa các nội dung bất hợp pháp, đồi trụy, đe dọa, phỉ báng, xâm phạm
                                    quyền riêng tư cá nhân, xâm phạm quyền sở hữu trí tuệ, hoặc những nội dung gây hại
                                    cho bên thứ ba, hoặc những nội dung không phù hợp với thuần phong mỹ tục, và không
                                    chứa virut, nội dung vận động chính trị, nội dung mang tính thương mại, hoặc bất kỳ
                                    hình thức thư rác nào.
                                </p>
                                <p>
                                    Khi bạn đăng tải thông tin hoặc gửi các loại tài liệu cho UPRACE, thì UPRACE có toàn
                                    quyền sử dụng, sao chép, thay đổi, biên dịch, công bố, hiển thị nội dung đó cho tất
                                    cả mọi người dưới bất kỳ hình thức nào trừ trường hợp có thỏa thuận khác giữa UPRACE
                                    và bạn. Đồng thời, bạn cũng cho phép UPRACE có quyền được sử dụng tên đi kèm với nội
                                    dung mà bạn gửi hoặc đăng tải. Bạn đảm bảo rằng bạn sở hữu và có toàn quyền sử dụng
                                    nội dung mà bạn đăng tải; rằng nội dung được đăng tải là chính xác; rằng việc sử
                                    dụng các nội dung bạn cung cấp không vi phạm Thỏa thuận sử dụng này và không gây hại
                                    cho bên thứ ba nào; và rằng bạn sẽ bồi thường cho UPRACE nếu như có bất kỳ khiếu
                                    kiện nào phát sinh từ những nội dung mà bạn cung cấp.
                                </p>
                                <p>
                                    UPRACE không chịu trách nhiệm sàng lọc, chỉnh sửa hoặc giám sát nội dung được người
                                    sử dụng đăng tải lên website và các sản phẩm liên quan, cũng như không thể đảm bảo
                                    tính chính xác của những ý kiến, bình luận này. Nếu nhận được thông tin về những vi
                                    phạm, gây tổn hại hoặc bất hợp pháp, UPRACE có quyền điều tra những cáo buộc trên để
                                    xác minh, và có quyền quyết định chấm dứt cung cấp dịch vụ đối với thành viên vi
                                    phạm những điều khoản sử dụng.
                                </p>
                                <p>
                                    Mặc dù đã có những quy định nêu trên, nhưng UPRACE cũng như những cá thể, tập thể
                                    liên quan không thể bảo đảm có thể chỉnh sửa hoặc xoá bỏ lập tức những nội dung vi
                                    phạm. UPRACE có quyền nhưng không phải là nghĩa vụ kiểm soát và thay đổi hoặc xóa bỏ
                                    bất kỳ nội dung nào. Cũng như UPRACE không phải chịu trách nhiệm pháp lý đối với
                                    những nội dung do bạn hoặc bất kỳ bên thứ 3 nào đăng tải trên UPRACE. Đồng thời,
                                    Khách hàng cũng có trách nhiệm cho mối liên hệ giữa bạn và những bạn khác. UPRACE có
                                    quyền, nhưng không có nghĩa vụ theo dõi các tranh chấp giữa các bạn với nhau.
                                </p>
                                <h5 className="title">5. Các Website Liên Kết</h5>
                                <p>
                                    Ngoại trừ nội dung trên website và các sản phẩm liên quan của UPRACE, chúng tôi
                                    không trực tiếp hay gián tiếp quản lý những website liên kết. UPRACE không hợp tác,
                                    tài trợ, xác thực hay sát nhập với những website đó, trừ khi sự hợp tác đó được công
                                    bố rõ ràng. Khi truy cập vào UPRACE, chúng tôi hy vọng bạn có thể hiểu rằng UPRACE
                                    không kiểm soát, quản lý những trang liên kết và không chịu trách nhiệm về nội dung
                                    của bất kỳ website liên kết nào.
                                </p>
                                <h5 className="title">6. Bản Quyền Sở Hữu</h5>
                                <p>
                                    Tất cả nội dung được hiển thị trên website và các sản phẩm liên quan UPRACE dưới bất
                                    kỳ hình thức nào như ký tự, hình ảnh, logo, video clip… là tài sản của UPRACE hoặc
                                    các đối tác cung cấp nội dung của UPRACE, được bảo vệ bởi luật pháp Việt Nam và các
                                    quy định bản quyền quốc tế. Sự biên soạn và hiển thị các nội dung này thông qua
                                    UPRACE là tài sản riêng của UPRACE.
                                </p>
                                <p>
                                    UPRACE là chủ bản quyền của website và các sản phẩm liên quan. UPRACE có quyền chỉnh
                                    sửa, thay đổi, sắp xếp lại nội dung website và các sản phẩm liên quan. Việc chỉnh
                                    sửa, thay đổi, sắp xếp lại hoặc tái sử dụng những nội dung này mà không có sự đồng ý
                                    của UPRACE đều là vi phạm quyền lợi hợp pháp của UPRACE.
                                </p>
                                <p>
                                    UPRACE luôn tôn trọng sở hữu trí tuệ của người khác, và chúng tôi yêu cầu bạn của
                                    chúng tôi cũng làm như vậy. Chúng tôi luôn cố gắng đảm bảo những dữ liệu trên
                                    website và các sản phẩm liên quan đều là hợp pháp, nhưng chúng tôi không chắc chắn
                                    có thể kiểm soát tất cả thông tin trên website và các sản phẩm liên quan. Khi phát
                                    hiện được bất kỳ hành vi vi phạm bản quyền nào trên website và các sản phẩm liên
                                    quan, Ban quản trị sẽ xoá nội dung đó khỏi website một cách nhanh nhất có thể.
                                </p>
                                <h5 className="title">7. Trách Nhiệm Pháp Lý</h5>
                                <p>
                                    Trừ phi có ghi chú khác bằng văn bản, tất cả dịch vụ, thông tin, nội dung, công cụ,
                                    sản phẩm (bao gồm cả phần mềm) của UPRACE hoặc được hiển thị trên website và các sản
                                    phẩm liên quan của UPRACE được cung cấp dựa trên thỏa thuận sử dụng và các quy định
                                    khác của UPRACE và các nhà cung cấp.
                                </p>
                                <p>
                                    Bạn đồng ý rằng việc sử dụng các dịch vụ, thông tin, nội dung, công cụ và sản phẩm
                                    của UPRACE hoặc được hiển thị trên website và các sản phẩm liên quan của UPRACE
                                    thuộc phạm trù rủi ro riêng của bạn. UPRACE không bảo đảm cho bất kỳ dịch vụ, thông
                                    tin, nội dung, công cụ của UPRACE hoặc được hiển thị trên website và các sản phẩm
                                    liên quan của UPRACE. UPRACE server và các thông điệp truyền thông được gửi đi từ
                                    UPRACE không chứa virut hay bất kỳ tác nhân gây hại nào cho bạn. Trừ phi có ghi chú
                                    khác bằng văn bản, UPRACE từ chối trách nhiệm pháp lý cho bất kỳ thiệt hại nào phát
                                    sinh từ việc sử dụng các dịch vụ, thông tin, nội dung, công cụ và sản phẩm của
                                    UPRACE hoặc được hiển thị trên website và các sản phẩm liên quan của UPRACE.
                                </p>
                                <p>
                                    Nếu các bộ luật, và quy định pháp luật được ban hành bởi Nhà Nước có bất kỳ nội dung
                                    nào mâu thuẫn với bất kỳ quy định về từ chối bảo đảm và trách nhiệm pháp lý, thì
                                    những điểm quy định đó không có hiệu lực áp dụng đối với bạn. Điều này đồng nghĩa
                                    với việc bạn có quyền lợi bổ sung.
                                </p>
                                <h5 className="title">9. Sự Cố Phát Sinh</h5>
                                <p>
                                    Khi đã sử dụng dịch vụ của UPRACE, bạn đã chấp nhận một số sự cố có thể phát sinh
                                    trong quá trình truy cập, và đồng ý rằng UPRACE và các đối tác liên quan sẽ không
                                    chịu trách nhiệm pháp lý về những thất thoát, thiệt hại xảy ra một cách trực tiếp
                                    hay gián tiếp trong khi truy cập vào website và các sản phẩm liên quan, khi tải dữ
                                    liệu, không loại trừ những tổn hại do virus, những tác động ảnh hưởng đến hệ thống
                                    máy tính, đường dây điện thoại, huỷ hoại các chương trình phần cứng, phần mềm, các
                                    trở ngại của đường truyền máy vi tính hoặc kết nối mạng.
                                </p>
                                <h5 className="title">10. Dừng Sử Dụng Dịch Vụ</h5>
                                <p>
                                    Bạn có thể dừng việc sử dụng tài khoản của bạn bất cứ khi nào bằng cách thông báo
                                    cho Ban quản trị của UPRACE. Bạn sẽ nhận được e-mail xác nhận và tài khoản của bạn
                                    sẽ ngưng hoạt động trong vòng 2 ngày làm việc. Bạn sẽ chịu trách nhiệm cho tất cả
                                    các chi phí phát sinh (nếu có) cho đến khi tài khoản ngưng hoạt động.
                                </p>
                                <h5 className="title">11. Ngưng Cung Cấp Dịch Vụ</h5>
                                <p>UPRACE có quyền ngưng cung cấp dịch vụ cho bạn nếu vi phạm những điều sau đây:</p>
                                <ul className="dot-list">
                                    <li>
                                        Thông tin bạn cung cấp không chính xác, không đầy đủ, không trung thực, hoặc
                                        Uprace.org có căn cứ để nghi ngờ độ chính xác của thông tin.
                                    </li>
                                    <li>
                                        Đăng tải lên website và các sản phẩm liên quan những nội dung không phù hợp như
                                        những nội dung có tính chất khiêu dâm, đồi truỵ, phỉ báng, thô tục, gây hiểu
                                        lầm, hoặc phạm pháp.
                                    </li>
                                    <li>
                                        Quấy rối, đe doạ hoặc phân biệt một cá nhân hoặc một tập thể vì lý do giới tính,
                                        tôn giáo, khuynh hướng tình dục, chủng tộc, dân tộc, tuổi tác hoặc khuyết tật.
                                    </li>
                                    <li>"Spam", quảng cáo trái phép hoặc bất kỳ hình thức xổ số, bài bạc nào.</li>
                                    <li>Sử dụng tài khoản cá nhân với mục đích thương mại.</li>
                                    <li>Vi phạm những điều khoản khác của UPRACE.</li>
                                </ul>

                                <p>
                                    UPRACE không chịu trách nhiệm với những nội dung do bạn đăng tải và sẽ cung cấp
                                    những nội dung này cho các cơ quan có thẩm quyền trong trường hợp cần thiết.
                                </p>
                                <h4 className="title">II. CHẤP NHẬN VÀ ĐỒNG Ý CÁC ĐIỀU KHOẢN SỬ DỤNG</h4>
                                <p>
                                    Khi đã sử dụng website và các sản phẩm liên quan đến UPRACE đồng nghĩa với việc bạn
                                    đã chấp nhận và đồng ý với những ràng buộc về mặt pháp lý, và tuân thủ theo quy chế
                                    hoạt động và điều khoản sử dụng của UPRACE.
                                </p>
                                <p>
                                    UPRACE có quyền thay đổi, bổ sung quy chế hoạt động và điều khoản sử dụng trên
                                    website và các sản phẩm liên quan vào bất cứ lúc nào. Người sử dụng có trách nhiệm
                                    cập nhật và theo dõi những thay đổi mới nhất trên website UPRACE. Nếu bạn không đồng
                                    ý với những thay đổi mới nhất, bạn có quyền yêu cầu ngưng sử dụng tài khoản tại
                                    website và các sản phẩm liên quan của UPRACE.
                                </p>
                                <p>
                                    Tài khoản của bạn trên UPRACE không dùng để trao đổi, mua bán với mục đích thương
                                    mại
                                </p>
                            </div>
                        </section>
                    </ul>
                </section>
            );
        }

        return <TermsUprace />;
    })
);

export default TermsOfUse;
