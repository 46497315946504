import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import LikeItem from './LikeItem';
import CommentItem from './CommentItem';
import { toJS } from 'mobx';
import { SOCIAL_TAB, SOCIAL_TYPE } from '../../../../../helpers/ConstDefined';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getImageResource, stripedHtml } from 'utils';
import { useTranslation } from 'react-i18next';

const SocialList = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { tabType, type, data } = props;
        const { userProfile, listUserLike, listComment, totalLike, totalComment } = props.userStore;
        const { doGetListComment, doGetListUserLike, doComment } = props.userStore;
        let { appVersion } = props.globalStore;

        const [comment, setComment] = React.useState('');
        const [hasMoreLike, setHasMoreLike] = React.useState(true);
        const [hasMoreComment, setHasMoreComment] = React.useState(true);
        const isShowMoreComment = listComment == null ? false : listComment.length < totalComment;

        const { t } = useTranslation();
        const myRefname = useRef(null);

        const fetchMoreDataLike = () => {
            if (listUserLike.length >= totalLike) {
                setHasMoreLike(false);
                return;
            }
            getListLike(type, true);
        };

        const fetchMoreDataComment = () => {
            if (listComment.length >= totalComment) {
                setHasMoreComment(false);
                return;
            }
            getListComment(type, true);
        };

        async function writeComment() {
            let params = {
                uid: userProfile && userProfile.uid,
                action: 2,
                cmid: 0, //new comment
                cmt: comment
            };
            if (type == SOCIAL_TYPE.POST) params.psid = data.psid;
            else if (type == SOCIAL_TYPE.FEED) params.atid = data.atid;
            let result = await doComment(params, type);
            if (result == true) {
                data.cmcnt += 1;
                myRefname.current.scrollIntoView({ behavior: 'smooth' });
                setComment('');
            }
        }

        const getListComment = (type, isLoadMore) => {
            let params = {
                size: appVersion.pgsz,
                uid: userProfile && userProfile.uid
            };
            if (type == SOCIAL_TYPE.POST) params.psid = data.psid;
            else if (type == SOCIAL_TYPE.FEED) params.atid = data.atid;

            doGetListComment(params, type, isLoadMore);
        };

        const getListLike = (type, isLoadMore) => {
            let params = {
                size: appVersion.pgsz,
                uid: userProfile && userProfile.uid
            };
            if (type == SOCIAL_TYPE.POST) params.psid = data.psid;
            else if (type == SOCIAL_TYPE.FEED) params.atid = data.atid;

            doGetListUserLike(params, type, isLoadMore);
        };

        useEffect(() => {
            getListComment(type);
            getListLike(type);
        }, []);

        useEffect(() => {
            if(myRefname.current) myRefname.current.scrollIntoView({ behavior: 'smooth' });
        }, [listComment]);

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                writeComment();
            }
        };

        if (tabType == SOCIAL_TAB.LIKE)
            return (
                <li class="active">
                    <div class="like-list">
                        <InfiniteScroll
                            dataLength={listUserLike.length}
                            next={fetchMoreDataLike}
                            hasMore={hasMoreLike}
                            style={{ overflowX: 'hidden' }}
                            height={550}>
                            <ul>
                                {listUserLike.map((item) => (
                                    <LikeItem key={item.uid} data={item} />
                                ))}
                            </ul>
                        </InfiniteScroll>
                    </div>
                </li>
            );
        else
            return (
                <li class="active">
                    <div class="comment-list">
                        <div class="comment-scroll">
                            <div class="mess-seemore" style={{ display: 'none' }}>
                                <a href="javascript:void(0);">Xem thêm 2 bình luận</a>
                            </div>
                            {listComment &&
                                listComment.map((item) => {
                                    return <CommentItem data={item} key={item.id} />;
                                })}
                            <div ref={myRefname} />
                        </div>

                        {userProfile ? (
                            <div class="mess-frm">
                                <div class="avatar">
                                    <img src={getImageResource(userProfile.ava, true, true)} alt="" />
                                </div>
                                <div class="txt-frm">
                                    <input
                                        type="text"
                                        value={comment}
                                        onKeyDown={handleKeyDown}
                                        onChange={(text) => setComment(stripedHtml(text.target.value))}
                                        placeholder={t('global.write_comment')}
                                    />
                                </div>
                            </div>
                        ) : <div class="mess-frm">&nbsp;</div>}
                    </div>
                </li>
            );
    })
);

export default SocialList;
