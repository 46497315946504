import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDistanceKM, getImageResource } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { EVENT_LOCK_STATUS } from 'helpers/ConstDefined';
import { MemberName } from 'components/MemberName';

const RankMemberItem = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { t } = useTranslation();
        const { data } = props;

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';
        const cssRankNum = data.rank < 4 ? 'level num' + data.rank : 'level';

        function addDefaultSrc(e) {
            e.target.src = '../../../../img/ic_avatar.png';
        }

        return (
                <li onClick={() => props.onViewUserEvent(data)}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                        <div className={cssRankNum}>{data.rank < 4 ? null : data.rank}</div>
                        <div className="avatar">
                            <img src={getImageResource(data.ava, true, true)} onError={addDefaultSrc} alt="" />
                        </div>
                        <div className="name-box">
                            <p className="name">{MemberName(data)}</p>
                            {data.diff != 0 ? <p className={cssRankArrow}>{Math.abs(data.diff)}</p> : null}
                        </div>
                        <div className="date">{formatDistanceKM(data.ddis)}</div>
                        <div className="sum">{formatDistanceKM(data.dis)}</div>
                    </a>
                </li>
            );
    })
);

export default RankMemberItem;
