import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DialogDeleteAccount = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { openDialogDeleteAccount } = props;

        const { t } = useTranslation();

        return (
            <Dialog open={openDialogDeleteAccount}>
                <div
                    class="uprace-popup uprace-popup--v2"
                    id="remove-event"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content rounded">
                            <div class="popup-body">
                                <div class="icon-warning"></div>
                                <div>
                                    <h5 class="popup-title">{t('global.deleted_account')}</h5>
                                    <div class="popup-text">{t('global.deleted_account_desc')}</div>
                                    <div class="action">
                                        <button
                                            type="button"
                                            class="btn btn-blue"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => props.onClose()}>
                                            {t('global.close')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogDeleteAccount;
