import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const NewsItem = inject('authStore','globalStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {}, []);

        const { language } = props.globalStore;

        const css = data.hot == 1 ? 'news news-big flex' : 'news';
        return (
            <div className={css}>
                <RouterLink className="news--thumb" to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}>
                    <img className="lazyload" data-src={data.image} alt="news" />
                </RouterLink>
                <div className="news-info">
                    <RouterLink className="news--title" to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}>
                        {data[`title_${language}`]}
                    </RouterLink>
                    {data.hot == 1 ? (
                        <RouterLink className="news--description" to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}>
                            {data[`desc_${language}`]}
                        </RouterLink>
                    ) : null}
                    <div className="news-bottom flex justify-content-space-between">
                        <RouterLink className="news--detail" to={`/news-detail?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}&id=${data.id}`}>
                            Xem thêm
                        </RouterLink>
                        <span className="news--date">{data.date}</span>
                    </div>
                </div>
            </div>
        );
    })
);

export default NewsItem;
