import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ChartSummary from './ChartSummary';
import RankMemberList from './RankMemberList';

const TeamInfoTab = inject('eventStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { eventID, teamID } = props;
        const { currentEvent } = props.eventStore;

        return (
            <div className="left-ctn">
                {currentEvent ? <ChartSummary eventID={eventID} teamID={teamID} /> : null}
                <RankMemberList eventID={eventID} teamID={teamID} />
            </div>
        );
    })
);

export default TeamInfoTab;
