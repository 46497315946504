import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { TEAM_TYPE } from '../../../../data/master';
import $ from 'jquery';

let currentCategory = 0;
const TeamCategory = inject(
    'authStore',
    'teamStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { eventID } = props;
        const {
            tabTeamType,
            sportType,
            teamName,
            setTabTeamType,
            setTeamNameFilter,
            setSportType,
            doGetListTeam
        } = props.teamStore;

        const { t } = useTranslation();

        function CategoryMenu(props) {
            const { data } = props;
            const css = data.id == tabTeamType ? 'active' : '';
            return (
                <li className={css} onClick={() => onChangeNavigationTab(data)}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                        <p className={data.icon}></p>
                        <p className="txt">{t(data.title)}</p>
                    </a>
                </li>
            );
        }

        useEffect(() => {
            initSticky();

            //unmount reset all value filter
            return () => {
                setTeamNameFilter(null);
                setTabTeamType(0);
                setSportType(0);
            };
        }, []);

        function initSticky() {
            var wScreen = $(window).width();
            if (wScreen > 1050) {
                $('#sticker').sticky({ topSpacing: 160, center: true, className: 'sticker-box' });
            }
        }

        function getListTeam() {
            let data = {
                sport: sportType.id,
                lgid: currentCategory.id,
                sort: 7
            };
            doGetListTeam(data);
        }

        const onChangeNavigationTab = (category) => {
            setTeamNameFilter('');
            setTabTeamType(category.id);
            currentCategory = category;
            getListTeam();
            props.onChangeTab();
        };

        return (
            <div className="tabs-type-menu" id="sticker">
                <ul>
                    {TEAM_TYPE.map((item) => {
                        return <CategoryMenu data={item} key={item.id} />;
                    })}
                </ul>
            </div>
        );
    })
);

export default TeamCategory;
