import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { stripedHtml, validEmail } from '../../utils/index';
import { useTranslation } from 'react-i18next';

const ForgetPass = inject('authStore')(
    observer((props) => {
        const { doForgotPass } = props.authStore;
        const [email, setEmail] = React.useState('');
        const [sendSuccess, setSendSuccess] = React.useState(false);
        const [errEmail, setErrEmail] = useState(false);

        const { t } = useTranslation();

        const showClearEmail = email.length > 0;

        function validInput() {
            let hasError = false;
            if (!validEmail(email)) {
                setErrEmail(true);
                hasError = true;
            } else setErrEmail(false);
            return hasError;
        }

        const handleClearEmail = () => {
            setEmail('');
        };

        async function forgotPass() {
            if (!validInput()) {
                const params = {
                    email
                };
                let result = await doForgotPass(params);
                if (result) setSendSuccess(true); //send email get pass success
                else {

                }
            }
        }

        return (
            <section className="wrapper-login wrapper-forgot-password ">
                <div className="wrapper-user-card">
                    <div className="wrapper-head">
                        <h3 className="title">{t('login.set_pass')}</h3>
                    </div>

                    {!sendSuccess ? (
                        <div className="wrapper-content">
                            <p className="description">
                               {t('login.set_pass_desc')}
                            </p>

                            <form className="form-group-login" name="form-forgot" id="form-forgot">
                                <div className="form-label-group form-control">
                                    <input
                                        type="email"
                                        id="inputEmail"
                                        className="form-control"
                                        placeholder="Email"
                                        required
                                        autoFocus
                                        value={email}
                                        onChange={(text) => setEmail(stripedHtml(text.target.value))}
                                    />
                                    <label htmlFor="inputEmail" className="label-input-custom">
                                        Email
                                    </label>
                                    {showClearEmail ? (
                                        <img
                                            id="closeEmail"
                                            src="../../img/register/close.svg"
                                            className="close-text"
                                            onClick={() => handleClearEmail()}
                                        />
                                    ) : null}
                                    {errEmail ? (
                                        <div className="wrapper-error">
                                            <p className="error-message">{t('global.error.valid_email')}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="button"
                                        className="btn btn-primary btn-confirm"
                                        onClick={() => forgotPass()}
                                        value={t('global.send')}
                                    />
                                </div>
                            </form>

                            <div className="back-login">
                                <label className="title">
                                   {t('login.back_login')} <Link to="/login">{t('login.login')}</Link>
                                </label>
                            </div>
                        </div>
                    ) : (
                        <div className="wrapper-content">
                            <p className="description">
                                {t('login.get_pass_desc')}
                            </p>

                            <p className="email-user-forgot">{email}</p>

                            <div className="back-login">
                                <Link className="btn btn-primary btn-confirm" to="/login">
                                    {t('login.back_login1')}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    })
);

export default ForgetPass;
