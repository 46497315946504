import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const VNLang2022 = inject('authStore')(
    observer((props) => {
        useEffect(() => { }, []);

        return (
            <section class="fail-activity-list">
                <ul class="list">
                    <h3 className="list-title">THỂ LỆ THI ĐẤU 2022</h3>
                    <p>
                        <strong>Thời gian sự kiện: 0:00 28/10 - 23:59 20/11</strong>
                    </p>

                    <p>
                        <strong> - Thể lệ thi đấu:</strong>
                    </p>

                    <div class="list-desc">
                        <ul>
                            <li>
                                + Tốc độ chạy hợp lệ 4:00-15:00 phút/km.
                            </li>
                            <li>
                                + Cự ly chạy tối thiểu 1km/hoạt động.
                            </li>
                            <li>
                                + 12 ngày hoạt động hợp lệ + tối thiểu 60km.
                            </li>
                            <li>
                                + Tự động tham gia khi có tài khoản
                            </li>

                            <li>
                                + Nhân đôi km hoạt động ghi bằng ứng dụng UpRace ngày 11/11
                            </li>
                            <li>
                                + Nhân đôi km hoạt động trong các ngày chủ nhật trong thời gian diễn ra sự kiện
                            </li>
                        </ul>
                    </div>
                </ul>
            </section>
        );
    })
);

export default VNLang2022;
