import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from 'utils';
import { useTranslation } from 'react-i18next';
import { API } from 'helpers/api/APIDefined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    EVENT_PUBlIC_TYPE,
    EVENT_DETAIL_TAB_TYPE,
    EVENT_FORM_TYPE,
    EVENT_JOIN_STATUS,
    EVENT_STATUS,
    GROUP_ACTION,
    EVENT_STATUS_TYPE
} from 'helpers/ConstDefined';
import DeleteEventDialog from './DeleteEventDialog';
import LeaveEventDialog from './LeaveEventDialog';
import EmptyGroupDialog from './EmptyGroupDialog';
import CreateGroupDialog from 'views/TeamDetails/components/CreateGroupDialog';
import { ListGroupDialog } from '..';
import { toJS } from 'mobx';

const TabHeader = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore',
    'groupStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;

        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { tabEventDetailHome, doSetTabEventDetailHome, doActionEvent } = props.userEventStore;
        const { userProfile } = props.userStore;
        const { listGroupJoin } = props.groupStore;

        const [openDialogDeleteEvent, setOpenDialogDeleteEvent] = React.useState(false);
        const [openDialogLeaveEvent, setOpenDialogLeaveEvent] = React.useState(false);
        const [openDialogEmptyGroup, setOpenDialogEmptyGroup] = React.useState(false);
        const [openDialogListGroup, setOpenDialogListGroup] = React.useState(false);
        const [openDialogCreateGroup, setOpenDialogCreateGroup] = React.useState(false);

        useEffect(() => {}, []);
        const { t } = useTranslation();

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const TABS = [
            {
                id: 0,
                title: 'global.info',
                icon: 'info'
            },
            {
                id: 1,
                title: 'global.leaderboard',
                icon: 'rank'
            }
        ];

        const onSelectGroup = () => {
            if (!listGroupJoin || listGroupJoin.length == 0) setOpenDialogEmptyGroup(true);
            else setOpenDialogListGroup(true);
        };

        const onCreateGroup = () => {
            setOpenDialogEmptyGroup(false);
            setOpenDialogCreateGroup(true);
        };

        const onCreateGroupSucc = () => {
            setOpenDialogListGroup(false);
        }

        const onChangeTab = (tab) => {
            if (tab == 1) {
                if (eventData.form == EVENT_FORM_TYPE.GROUP)
                    doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.LEADERBOARD_GROUP);
                else doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.LEADERBOARD_USER);
            } else doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.INFO_TAB);
        };

        const actionEvent = async () => {
            if (!userProfile) {
                navigation.push('/login');
                return;
            }

            if(eventData.otp === 1) {
                navigation.push('/verify-email');
                return;
            }

            const params = {
                uid: userProfile.uid,
                evid: eventData.evid,
                action: eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ? GROUP_ACTION.JOIN : GROUP_ACTION.USER_REQUEST_JOIN
            };
            let result = await doActionEvent(params);
            if (result != null && result.hasOwnProperty('jsts')) {
                eventData.jsts = result.jsts;
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        };

        function TabItem(props) {
            const { data } = props;
            let cssClass;
            if ((data.id == 1 && tabEventDetailHome == 1) || (data.id == 1 && tabEventDetailHome == 2))
                cssClass = 'active';
            else if (data.id == tabEventDetailHome) cssClass = 'active';
            else cssClass = '';

            return (
                <li class="menu-item" onClick={() => onChangeTab(data.id)}>
                    <a href="javascript:void(0);" class={`menu-link ${data.icon} ${cssClass}`}>
                        {t(data.title)}
                    </a>
                </li>
            );
        }

        const RenderButton = () => {
            const activeCss = eventData.sts == EVENT_STATUS.FINISHED ? ' inactive' : '';
            //user là owner event
            if (userProfile && userProfile.uid == eventData.owner) {
                //event form theo group
                if (eventData.form == EVENT_FORM_TYPE.GROUP && eventData.grid == 0) {
                    return (
                        <button class={`btn btn-red btn-choose-group ${activeCss}`} onClick={() => onSelectGroup()}>
                            {t('eu.select_group')}
                        </button>
                    );
                } else {
                    return (
                        <button
                            class="btn btn-blue btn-choose-group"
                            data-toggle="modal"
                            data-target="#team-invite-friends-2">
                            {t('global.invite_friend')}
                        </button>
                    );
                }
            } else {
                if (eventData.jsts == EVENT_JOIN_STATUS.NONE)
                    return (
                        <button class={`btn btn-red btn-choose-group ${activeCss}`} onClick={() => actionEvent()}>
                            {t('global.join')}
                        </button>
                    );
                else if (eventData.jsts == EVENT_JOIN_STATUS.REQUESTING)
                    return <button class="btn btn-blue btn-choose-group">{t('eu.requested')}</button>;
                else {
                    if (eventData.form == EVENT_FORM_TYPE.GROUP && eventData.grid == 0) {
                        return (
                            <button class={`btn btn-red btn-choose-group ${activeCss}`} onClick={() => onSelectGroup()}>
                                {t('eu.select_group')}
                            </button>
                        );
                    } else {
                        return (
                            <button
                                class="btn btn-blue btn-choose-group"
                                data-toggle="modal"
                                data-target="#team-invite-friends-2">
                                {t('global.invite_friend')}
                            </button>
                        );
                    }
                }
            }
        };

        const isOwner = userProfile && userProfile.uid == eventData.owner;
        const isAdmin = userProfile && eventData.jsts == EVENT_JOIN_STATUS.ADMIN;

        return (
            <div class="tabs--menu">
                <ul class="left tabs-header">
                    {TABS.map((item) => {
                        return <TabItem data={item} key={item.id} />;
                    })}
                </ul>
                <div class="right">
                    <div class="col">{RenderButton()}</div>

                    <div class="col btn-group-icon">
                        <CopyToClipboard
                            text={API.GLOBAL.SITE_DOMAIN + `event-detail?evid=${eventData.evid}`}
                            onCopy={() => onCopySuccess()}>
                            <button class="btn btn-outline-blue btn-share"></button>
                        </CopyToClipboard>

                        {isOwner || isAdmin ? (
                            <div class="btn-dropdown">
                                <div class="uiselector owner">
                                    <p
                                        class="owner-btn"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        {eventData.revgrcnt > 0 ? (
                                            <span class="badge bg-red notify">{eventData.revgrcnt}</span>
                                        ) : null}
                                    </p>
                                    <ul class="owner-list" aria-labelledby="list-server-choose">
                                        <li
                                            onClick={() =>
                                                doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.MANAGER_MEMBER)
                                            }>
                                            {t('global.manage_member')}
                                        </li>
                                        {eventData.form == EVENT_FORM_TYPE.GROUP ? (
                                            <li
                                                onClick={() =>
                                                    doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.MANAGER_GROUP)
                                                }>
                                                {t('team.manage.tab2')}
                                                {eventData.revgrcnt > 0 ? (
                                                    <span class="badge bg-red notify ms-2">{eventData.revgrcnt}</span>
                                                ) : null}
                                            </li>
                                        ) : null}
                                        <li
                                            onClick={() =>
                                                doSetTabEventDetailHome(EVENT_DETAIL_TAB_TYPE.MANAGER_COMPLAIN)
                                            }>
                                            {t('team.manage.tab3')}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : null}

                        {isOwner ? (
                            <>
                                <div class="btn-dropdown">
                                    <div class="uiselector setting-more">
                                        <p
                                            class="setting-more-btn"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"></p>
                                        <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                            {eventData.sts != EVENT_STATUS_TYPE.DONE ? (
                                                <li
                                                    onClick={() =>
                                                        navigation.push(`/update-event?evid=${eventData.evid}`)
                                                    }>
                                                    {t('global.edit')}
                                                </li>
                                            ) : null}
                                            <li onClick={() => setOpenDialogDeleteEvent(true)}>{t('global.delete')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <DeleteEventDialog
                                    openDialogDeleteEvent={openDialogDeleteEvent}
                                    data={eventData}
                                    navigation={navigation}
                                    onClose={() => setOpenDialogDeleteEvent(false)}
                                    eventID={eventData.evid}
                                />
                            </>
                        ) : (
                            <>
                                {eventData.jsts != EVENT_JOIN_STATUS.NONE ? (
                                    <>
                                        <div class="btn-dropdown">
                                            <div class="uiselector setting-more">
                                                <p
                                                    class="setting-more-btn"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"></p>
                                                <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                                    <li onClick={() => setOpenDialogLeaveEvent(true)}>
                                                        {t('eu.leave_event')}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <LeaveEventDialog
                                            openDialogLeaveEvent={openDialogLeaveEvent}
                                            data={eventData}
                                            navigation={navigation}
                                            onClose={() => setOpenDialogLeaveEvent(false)}
                                            eventID={eventData.evid}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
                {openDialogEmptyGroup ? (
                    <EmptyGroupDialog
                        openDialogEmptyGroup={openDialogEmptyGroup}
                        data={eventData}
                        navigation={navigation}
                        onCreateGroup={onCreateGroup}
                        onClose={() => setOpenDialogEmptyGroup(false)}
                        eventID={eventData.evid}
                    />
                ) : null}
                {openDialogCreateGroup ? (
                    <CreateGroupDialog
                        openDialogCreateGroup={openDialogCreateGroup}
                        eventID={eventData.evid}
                        navigation={navigation}
                        teamID={0}
                        onClose={() => setOpenDialogCreateGroup(false)}
                    />
                ) : null}
                {openDialogListGroup == true ? (
                    <ListGroupDialog
                        openDialogListGroup={openDialogListGroup}
                        eventData={eventData}
                        navigation={navigation}
                        onClose={() => setOpenDialogListGroup(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default TabHeader;
