import { Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ContainerFooter,
    Section,
    ColContainer,
    Logo,
    CopyContainer,
    CopySection,
    CopyText,
    FooterText,
    FooterLink,
    FooterHref,
    SocialContact,
    SocialIcon,
    FooterTitle,
    DownloadButtonContainer,
    DownloadButtonImage
} from './Footer.styles';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <Section>
                <ContainerFooter>
                    <Row>
                        <ColContainer xl={6} md={12} sm={24} xs={24}>
                            <Logo src="https://uprace2.vcdn.vn/web/uprace/logo/brand.svg" />
                            <FooterText>{t('footer.followUs')}</FooterText>
                            <SocialContact>
                                <li>
                                    <a
                                        href="https://www.facebook.com/uprace.org"
                                        target="_blank">
                                        <SocialIcon src="https://uprace2.vcdn.vn/web/uprace/icons/facebook.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href={'https://uprace.org'}>
                                        <SocialIcon src="https://uprace2.vcdn.vn/web/uprace/icons/globe-icon.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.tiktok.com/@uprace.org"
                                        target="_blank">
                                        <SocialIcon src="https://uprace2.vcdn.vn/web/uprace/icons/tiktok.svg" />
                                    </a>
                                </li>
                            </SocialContact>
                        </ColContainer>
                        <ColContainer xl={6} md={12} sm={24} xs={24}>
                            <FooterTitle>{t('global.info')}</FooterTitle>

                            <FooterHref href={'https://uprace.org/about'} target="_blank">
                                <FooterText>{t('footer.about')}</FooterText>
                            </FooterHref>
                            <FooterHref href="https://faq.uprace.org/" target="_blank">
                                <FooterText>{t('footer.support')}</FooterText>
                            </FooterHref>
                            <FooterHref
                                href="https://faq.uprace.org/?lang=vi"
                                target="_blank">
                                <FooterText>FAQs</FooterText>
                            </FooterHref>
                            <FooterHref href={'https://uprace.org/join-now'} target="_blank">
                                <FooterText>{t('global.guide')}</FooterText>
                            </FooterHref>
                        </ColContainer>
                        <ColContainer xl={6} md={12} sm={24} xs={24}>
                            <FooterTitle>{t('footer.terms')}</FooterTitle>

                            <FooterHref href="https://uprace.org/terms" target="_blank">
                                <FooterText>{t('footer.termsUse')}</FooterText>
                            </FooterHref>
                            <FooterHref href="https://uprace.org/policy" target="_blank">
                                <FooterText>{t('footer.policy')}</FooterText>
                            </FooterHref>
                        </ColContainer>
                        <ColContainer xl={6} md={12} sm={24} xs={24}>
                            <FooterTitle>{t('footer.contact')}</FooterTitle>
                            <FooterText>support@uprace.org</FooterText>
                            <FooterText
                                dangerouslySetInnerHTML={{
                                    __html: t('footer.address')
                                }}></FooterText>
                            <DownloadButtonContainer>
                                <a href="https://apps.apple.com/us/app/id1476173971">
                                    <DownloadButtonImage src="https://uprace2.vcdn.vn/web/uprace/icons/app-store.svg" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=vn.vng.uprace">
                                    <DownloadButtonImage src="https://uprace2.vcdn.vn/web/uprace/icons/google-play.svg" />
                                </a>
                            </DownloadButtonContainer>
                        </ColContainer>
                    </Row>
                </ContainerFooter>
            </Section>
            <CopySection>
                <CopyContainer>
                    <CopyText>© 2022 UpRace. All rights reserved</CopyText>
                </CopyContainer>
            </CopySection>
        </>
    );
};

export default Footer;
