import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, getImageResource } from '../../../../utils';
import { GROUP_ACTION } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const GroupRequestItem = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, eventData } = props;
        const { doJoinGroup } = props.groupStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        async function actionGroup(action) {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventData.evid,
                grid: data.grid,
                action
            };
            let result = await doJoinGroup(params);
            if (result) {
                eventData.revgrcnt -= 1;
                props.onActionGroup();
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <div class="list-group-item">
                <div class="manage-card rows-2">
                    <div class="avatar">
                        <img src={getImageResource(data.ava, true)} class="avatar-item" alt="Avatar" />
                    </div>
                    <div class="group-info">
                        <div class="group-info__name">{data.name}</div>
                        <div class="group-info__owner">{data.ownm}</div>
                    </div>
                    <div class="btn-group">
                        <div class="btn-group-item">
                            <button
                                class="btn-32 btn-blue btn-w-120"
                                onClick={() => actionGroup(GROUP_ACTION.ADMIN_APPROVE_JOIN)}>
                                {t('global.approve')}
                            </button>
                        </div>
                        <div class="btn-group-item">
                            <button
                                class="btn-32 btn-outline-blue btn-w-120"
                                onClick={() => actionGroup(GROUP_ACTION.ADMIN_REJECT_JOIN)}>
                                {t('global.reject')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default GroupRequestItem;
