import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';

const DialogViewSize = inject('authStore')(
    observer((props) => {
        const { openDialogViewSize } = props;
        useEffect(() => {}, []);

        return (
            <Dialog open={openDialogViewSize}>
                <div
                    class="uprace-popup in"
                    id="size-list"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div class="popup-body">
                                <div class="popup-size-list">
                                    <img src="../img/sizeao.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogViewSize;
