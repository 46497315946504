import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';
const queryString = require('query-string');

const RuleEvent2021EN = inject('globalStore')(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        function RuleUpraceEN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>UPRACE 2021</p>
                        <p>PARTICIPATION REGULATION</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Definition</h3>
                            <div class="list-desc">
                                UpRace 2021 (hereinafter referred to as "event") is a FREE community running event for
                                everyone on the web/mobile UpRace. Participation (hereinafter referred to as "runner")
                                are free to run at arbitrary time and place and actively record the results of running
                                on mobile devices (mobile phones, sports watches ...) with target accumulates as much
                                running distance as possible.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Time for event registration opening:</strong> starting from 00:00 10th
                                        October 2021, runners can continue to register and join Teams during the event.
                                    </li>
                                    <li>
                                        <strong>Competition time:</strong> from from 00:00 31/10/2021 to 23:59
                                        21/11/2021 (22 days)
                                    </li>
                                    <li>
                                        <strong>Vietnamese time (GMT + 7)</strong> is used as the standard time zone for
                                        the event.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Registration</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        Runner downloads the UpRace app on their mobile phones HERE and register an
                                        account. The runners who participated in the 2020 event can log in their existed
                                        account. (Watch tutorial video)
                                    </li>
                                    <li>
                                        Runners click to join the Team that you want to choose. Each runner's account
                                        can only select one Team to compete in the event. The runners are not allowed to
                                        change the Team since the event starts until the event ends.
                                    </li>
                                    <li>
                                        The runners chose to donate funds to a Social Organization of their choice. Each
                                        runner's account can only choose one Social Organization to raise funds for. The
                                        runners are not allowed to change their chosen Social Organization during the
                                        course of the event. There are 4 social organizations for athletes to choose
                                        from including:
                                        <p>- Newborns Vietnam</p>
                                        <p>- Operation Smile Vietnam</p>
                                        <p>- Green Viet</p>
                                        <p>- Saigon Children's Charity</p>
                                    </li>
                                    <li>
                                        The runners, who participate in the event after the event starts, will be able
                                        to join normally.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Team's Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Teams are placed in 3 league tables including:</p>
                                        <p>- Table 1: Company Teams</p>
                                        <p>- Table 2: Sports Club Teams</p>
                                        <p>- Table 3: School Teams</p>
                                    </li>
                                    <li>
                                        Teams in the same Table will compete against each other and rank based on the
                                        total running distance by each team.
                                    </li>
                                    <li>
                                        Teams, which are established after the event starts, still participate in the
                                        competition normally.
                                    </li>
                                    <li>
                                        Each team can create their own groups to compete in an internal ranking. The
                                        competition among groups will also be complied with “Runners competition rules”
                                        under section 5. There will be no UpRace prize for group competition. Watch
                                        tutorial HERE to learn how to create a group and groups ranking.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Runners Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Record achievements:</strong> The runners record their achievements
                                        every runs by mobile device (phone, watch with GPS function ...).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Valid race:</strong> is counted when committing the following
                                            conditions:
                                        </p>
                                        <p>
                                            - Recorded as completed and displayed on the runner' s UpRace account within
                                            72 hours from the activity's start time and no later than the last day of
                                            the event.
                                        </p>
                                        <p>
                                            - Express the map clearly with no sign of critical errors due to the error
                                            of catching GPS signal of mobile devices. The activities which are imported
                                            manual results, either shared by others, or recorded for different accounts
                                            will not be validated; In case of running on treadmils, the result must be
                                            recorded by fitness tracker or smart watch like Garmin; which is able to
                                            synchronize with UpRace.
                                        </p>
                                        <p>
                                            - Activities that are duplicated by recording from multiple devices at a
                                            same time will be counted as one activity. The priority order of activity’s
                                            source will be: 1. UpRace; 2. Garmin; 3. Strava.
                                        </p>
                                        <p>
                                            - There is a minimum distance of 1km. There is an unlimited maximum limit.
                                            Runners are allowed to pause the activity while running with the purpose of
                                            short break;
                                        </p>
                                        <p>
                                            - Average Pace maximum is 4:00 minutes/km and minimum is 15:00 minutes/km.
                                            Split pace of each 1 km must not be faster than 3:30 minutes/km
                                        </p>
                                        <p>
                                            - Runs that take place on Sundays and one special day 11/11/2021 (used
                                            UpRace app) during the event period must be completed before 24:00 on that
                                            day and double-result will be counted on the achievement of that day.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Complete the contest:</strong> A runner will be certified to
                                            complete the contest when both of the following conditions are reached
                                        </p>
                                        <p>- Must achieve a minimum number of 12 valid days, and</p>
                                        <p>
                                            - Must achieve a minimum distance of 60km (including double achievement on
                                            Sundays and on November 11th).
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Valid days:</strong> is the date of at least one valid run according to
                                        the above rule.
                                    </li>
                                    <li>
                                        <strong>Achievement date:</strong> The achievement will be counted on the day
                                        when activity starts.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Prize</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Individual Prizes:</strong>
                                        </p>
                                        <p>
                                            All runners who are qualified to finish the competition will receive a
                                            certificate of completion of the competition (electronic version) according
                                            to the completed km milestones including: 60km, 150km, 300km respectively
                                            with the titles: Pro runner, Hero runner and Angel runner.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Achievement Award for Team:</strong>
                                        </p>
                                        <p>
                                            10 teams with the best overall performance in each league table will receive
                                            the Team reward. The Team reward includes UpRace Winning Team 2020 T-shirts,
                                            which will be given to:
                                        </p>
                                        <p>+ Eligible UpRace’s athletes who complete the competition</p>
                                        <p>+ Members of the winning teams, according to the following table:</p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Company</th>
                                                <th>Sport club</th>
                                                <th>School</th>
                                            </tr>
                                            <tr>
                                                <td>Rank 1</td>
                                                <td>Top 300 runners</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 2</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 3</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 4 and 5</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 6,7,8,9 and 10</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>
                                                In addition, the team with the highest achievement in each group will
                                                receive
                                            </strong>
                                        </p>
                                        <p>
                                            + The prize of VND 100 million is used to donate to 01 UpRace beneficiary
                                            that the team nominates,{' '}
                                        </p>
                                        <p> + Certificate of achievement of the winning team (electronic version)</p>
                                        <p>
                                            {' '}
                                            + Honoring post for the winning team on UpRace fanpage & 1 PR honoring post
                                            for 3 teams together.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Fundraising for the community</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Origin of funds</strong>
                                        </p>
                                        <p>
                                            - From donors: sponsors will donate 1000 VND/1km of valid run from runners.
                                        </p>
                                        <p>
                                            - From runners: The runners are encouraged to donate to their own
                                            achievements or from other runners. The minimum level of donation is VND
                                            1,000/1km of their valid run or of other runners.
                                        </p>
                                        <p>
                                            - Runners are also encouraged to call fans or other runners donate their
                                            running achievement. Note: this is NOT a mandatory condition for runners.
                                        </p>
                                        <p>
                                            - From fans: Fans are encouraged to participate in donating the run
                                            achievement of the runner that they want to cheer. The minimum donation
                                            amount is 1,000 VND/1km.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Use of funds:</strong> All UpRace's funds will be transferred to social
                                        organizations.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Rights of Organizing Committee and Technical Council</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        Organizing Committee's Right: The Organizer has full authority to decide whether
                                        a team and runners are eligible to compete or not, to stop the match and cancel
                                        the results of the team and runners in case of fraud or non-compliance with the
                                        rules contests and the right to adjudicate complaints. The Organizing Committee
                                        has many plans to prevent and detect frauds if any, and call runners to show the
                                        spirit of fairplay and self-awareness. When any fraud is detected, the runner
                                        will be disqualified from the competition and not recognized for all
                                        achievements.
                                    </li>
                                    <li>
                                        Team Administration Board’s Right: Administration board, who are the owners and
                                        moderator, of each team has full authority to decide whether a team member is
                                        eligible to compete or not, to stop and cancel the result of a member in case of
                                        fraud or non-compliance with the rules contest and the right to adjudicate
                                        complaints from team members.
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        return <RuleUpraceEN />;
    })
);

export default RuleEvent2021EN;
