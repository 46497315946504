import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY } from '../../helpers/api/APIDefined';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import ChartDemo from './ChartDemo';

const dogImg = 'https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000';

const DemoLanguage = () => {
    const [imageSrc, setImageSrc] = React.useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(dogImg, croppedAreaPixels, rotation);
            console.log('donee', { croppedImage });
            var file = new File([croppedImage], "name");
            console.log('clll', file);
            //setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);

    return (
        <ChartDemo />
    );
};

export default DemoLanguage;
