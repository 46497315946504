import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PeopleSayItem from './PeopleSayItem';
import { useTranslation } from 'react-i18next';

const OtherPeopleSay = inject('newsStore')(
    observer((props) => {
        const { listPeopleSay } = props.newsStore;
        const { t, i18n } = useTranslation();

        useEffect(() => {}, []);

        return (
            <>
                <div className="container flex align-item-center">
                    <h1 className=" space-20" dangerouslySetInnerHTML={{ __html: t('landing_page.peoplesay.title') }}>
                        
                    </h1>
                    <div className="swiper-button-prev runner-back"></div>
                    <div className="swiper-button-next runner-next"></div>
                </div>
                <div className="swiper-container swiper-runners">
                    <div className="swiper-wrapper">
                        {listPeopleSay && listPeopleSay.map((item) => {
                            return <PeopleSayItem data = {item} key={item.id} />
                        })}
                    </div>
                </div>
                <div className="swiper-pagination swiper-pagination-runners"></div>
            </>
        );
    })
);

export default OtherPeopleSay;
