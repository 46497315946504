import React, { useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { STATISTICS_TYPE } from '../../../../helpers/ConstDefined';
import { SPORT_TYPE, STATS_TYPE } from '../../../../data/master';
import WeeklyComponent from '../../../TeamProfile/components/TeamStats/WeeklyComponent';
import { formatNumber, formatDistanceKM, formatDate, convertShortDurationText } from '../../../../utils';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const UserStats = inject(
    'userStore',
    'userProfileStore'
)(
    observer((props) => {
        const { profileID } = props;
        const { userProfile, userStatisticsInfo, doGetStatisticsUser } = props.userStore;
        const {
            reportType,
            sportType,
            textDuration,
            doSetTextDuration,
            doSetReportType,
            doSetSportType
        } = props.userProfileStore;

        const [openWeekly, setOpenWeekly] = React.useState(false);

        useEffect(() => {
            if (userProfile) getStatisticsUser(0, STATISTICS_TYPE.WEEK, null);
        }, [userProfile]);

        const { t } = useTranslation();

        const CustomDateMonthly = forwardRef(({ onClick }, props) => (
            <div class="date">
                <a href="javascript:void(0);" onClick={onClick}>
                    <span class="txt">{t('global.pre_month')}</span>
                </a>
            </div>
        ));

        const CustomDateYearly = forwardRef(({ onClick }, props) => (
            <div class="date">
                <a href="javascript:void(0);" onClick={onClick}>
                    <span class="txt">{t('global.pre_year')}</span>
                </a>
            </div>
        ));

        function getStatisticsUser(sport, type, value) {
            const params = {
                uid: userProfile && userProfile.uid,
                sport,
                term: type,
                value,
                pfid: profileID
            };
            doGetStatisticsUser(params);
        }

        const onSelectWeek = (week, text) => {
            doSetTextDuration(text);
            getStatisticsUser(sportType, reportType, week);
        };

        const showWeeklyComponent = () => {
            setOpenWeekly(!openWeekly);
        };

        const onChangeNavigationTab = (category) => {
            doSetReportType(category.id);
            getStatisticsUser(sportType, category.id, null);
            if (category.id == STATISTICS_TYPE.WEEK) doSetTextDuration(t('global.pre_week'));
            else if (category.id == STATISTICS_TYPE.MONTH) doSetTextDuration(t('global.pre_month'));
            else doSetTextDuration(t('global.pre_year'));
        };

        const onChangeNavigationTabSport = (category) => {
            doSetSportType(category.id);
            getStatisticsUser(category.id, reportType, null);
        };

        const handleChangeDate = (date, type) => {
            let value;
            if (type == STATISTICS_TYPE.MONTH) {
                value = formatDate('YYYYMM', date);
                doSetTextDuration(formatDate('MMM-YYYY', date));
            } else {
                value = formatDate('YYYY', date);
                doSetTextDuration(formatDate('YYYY', date));
            }
            getStatisticsUser(sportType, type, value);
        };

        function MenuItem(props) {
            const { data } = props;
            let cssClass;
            cssClass = data.id == reportType ? 'active' : '';

            return (
                <li class={cssClass}>
                    <a href="javascript:void(0);" onClick={() => onChangeNavigationTab(data)}>
                        <span class="txt">{t(data.title)}</span>
                    </a>
                </li>
            );
        }
        function MenuItemSport(props) {
            const { category } = props;
            const cssClass = category.id == sportType ? 'active' : '';

            if (category.id == 4 || category.id == 3 || category.id == 6) return null;
            else
                return (
                    <li class={cssClass} onClick={() => onChangeNavigationTabSport(category)}>
                        <a href="javascript:void(0);">{t(category.title)}</a>
                    </li>
                );
        }

        function RenderRange() {
            if (reportType == STATISTICS_TYPE.WEEK) {
                if (textDuration.length == 0) doSetTextDuration(t('global.pre_week'));
                return (
                    <>
                        <li class="col col2">{t('global.cur_week')}</li>
                        <li class="col col3 uiselector">
                            <div class="date" onClick={() => showWeeklyComponent()}>
                                <a href="javascript:void(0);">
                                    <span class="txt">{textDuration}</span>
                                </a>
                            </div>
                            <WeeklyComponent
                                open={openWeekly}
                                onSelectWeek={onSelectWeek}
                                onClose={() => setOpenWeekly(false)}
                            />
                        </li>
                    </>
                );
            } else if (reportType == STATISTICS_TYPE.MONTH) {
                if (textDuration.length == 0) doSetTextDuration(t('global.pre_month'));
                return (
                    <>
                        <li class="col col2">{t('global.cur_month')}</li>
                        <li class="col col3 uiselector">
                            <DatePicker
                                //selected={startDate}
                                onChange={(date) => handleChangeDate(date, STATISTICS_TYPE.MONTH)}
                                dateFormat="yyyyMM"
                                showMonthYearPicker
                                customInput={<CustomDateMonthly />}
                            />
                        </li>
                    </>
                );
            } else if (reportType == STATISTICS_TYPE.YEAR)
                return (
                    <>
                        <li class="col col2">{t('global.cur_year')}</li>
                        <li class="col col3 uiselector">
                            <DatePicker
                                //selected={startDate}
                                onChange={(date) => handleChangeDate(date, STATISTICS_TYPE.YEAR)}
                                dateFormat="yyyy"
                                showYearPicker
                                customInput={<CustomDateYearly />}
                            />
                        </li>
                    </>
                );
        }

        if (userStatisticsInfo != null)
            return (
                <div class="profile-wbox statistical">
                    <h2 class="profile-title">{t('global.stats')}</h2>
                    <div class="profile-sport-list">
                        <ul>
                            {SPORT_TYPE.map((cat) => (
                                <MenuItemSport key={cat.id} category={cat} />
                            ))}
                        </ul>
                    </div>

                    <div class="profile-tabs-menu">
                        <ul>
                            {STATS_TYPE.map((item) => {
                                return <MenuItem data={item} key={item.id} />;
                            })}
                        </ul>
                    </div>
                    <div class="tabs-content profile-tabs-content">
                        <ul>
                            <li class="active">
                                <div class="profile-statistical">
                                    <ul class="title-row">
                                        <li class="col col1">{t('global.info')}</li>
                                        <RenderRange />
                                    </ul>
                                    <ul class="list">
                                        <li>
                                            <div class="col col1">{t('global.distince_km')}</div>
                                            <div class="col col2">{formatDistanceKM(userStatisticsInfo.cur.dis)}</div>
                                            <div class="col col3">{formatDistanceKM(userStatisticsInfo.other.dis)}</div>
                                        </li>
                                        <li>
                                            <div class="col col1">{t('global.time_act')}</div>
                                            <div class="col col2">
                                                {convertShortDurationText(userStatisticsInfo.cur.dur)}
                                            </div>
                                            <div class="col col3">
                                                {convertShortDurationText(userStatisticsInfo.other.dur)}
                                            </div>
                                        </li>
                                        <li>
                                            <div class="col col1">{t('global.number_act')}</div>
                                            <div class="col col2">{formatNumber(userStatisticsInfo.cur.act)}</div>
                                            <div class="col col3">{formatNumber(userStatisticsInfo.other.act)}</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            );
        else return null;
    })
);

export default UserStats;
