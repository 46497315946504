import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml } from '../../../../utils';

const TabHeader = inject(
    'authStore',
    'groupStore',
    'teamManageStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { tab, doSetTab, search, doSetSearchVal } = props.teamManageStore;
        const { totalGroupRq } = props.groupStore;
        const { t, i18n } = useTranslation();

        const TABS = [
            {
                id: 0,
                title: 'team.manage.tab1',
                num: 0
            },
            {
                id: 1,
                title: 'team.manage.tab2',
                num: totalGroupRq
            },
            {
                id: 2,
                title: 'team.manage.tab3',
                num: 0
            }
        ];

        const handleChangeTab = (val) => {
            doSetTab(val);
            doSetSearchVal('');
        };

        function TabItem(props) {
            const { data } = props;
            const css = data.id == tab ? 'active' : '';
            return (
                <li className={css} onClick={() => handleChangeTab(data.id)}>
                    <a href="javascript:void(0);">
                        <span className="txt">
                            {t(data.title)}
                            {data.num > 0 ? <span className="num">{data.num}</span> : null}
                        </span>
                    </a>
                </li>
            );
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                props.resetSearch();
            }
        };

        return (
            <div className="manage-wbox">
                <div className="manage-tabs-menu">
                    <ul>
                        {TABS.map((item) => {
                            return <TabItem data={item} key={item.id} />;
                        })}
                    </ul>
                </div>
                {tab != 2 ? (
                    <div className="manage-search">
                        <input
                            type="text"
                            placeholder={t('global.search_by_name')}
                            value={search}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => doSetSearchVal(stripedHtml(text.target.value))}
                        />
                    </div>
                ) : null}
            </div>
        );
    })
);

export default TabHeader;
