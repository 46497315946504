import React, { useEffect, useState, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SPORT_TYPE } from '../../data/master';
import {
    convertTimeToLong,
    stripedHtml,
    stripedHtmlNumber,
    convertDatetoTimestamp,
    convertTimestamptoDate,
    getImageResource,
    convertShortDuration,
    formatDate
} from 'utils';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { TimePicker, DatePicker, Select } from 'antd';
import { EVENT_STATUS, EVENT_STATUS_TYPE, SPORT_TYPE_ACTIVITY } from 'helpers/ConstDefined';
import numeral from 'numeral';
import CloseConfirmDialog from './CloseConfirmDialog';

const UpdateEvent = inject(
    'eventStore',
    'userStore',
    'userEventStore',
    'globalStore'
)(
    observer((props) => {
        const { Option } = Select;
        const { currentEvent } = props.eventStore;

        if (!currentEvent) return <Redirect to="/event"></Redirect>;

        const formatD = 'DD/MM/YYYY';
        const formatT = 'hh:mm';
        const formatT24 = 'HH:mm';

        const { userProfile } = props.userStore;
        const { doUploadFile, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doUpdateEvent } = props.userEventStore;
        const [openDialogCloseConfirm, setOpenDialogCloseConfirm] = React.useState(false);

        //default value spm, bpm
        let dfArray = [];
        for (var i = 60; i <= 300; i += 1) {
            dfArray.push(i);
        }

        let dfArrayBpm = [];
        for (var i = 35; i <= 300; i += 1) {
            dfArrayBpm.push(i);
        }

        const MENUS = [
            {
                id: 1,
                title: 'ue.group',
                icon: 'icon-group',
                desc: 'ue.group_desc'
            },
            {
                id: 2,
                title: 'global.personal',
                icon: 'icon-user',
                desc: 'ue.personal_desc'
            }
        ];

        const MENUS_PRIVACY = [
            {
                id: 1,
                title: 'eu.public_join',
                icon: 'icon-group',
                desc: 'eu.public_join_desc'
            },
            {
                id: 2,
                title: 'eu.private_join',
                icon: 'icon-user',
                desc: 'eu.private_join_desc'
            }
        ];

        const sportKey = Object.keys(currentEvent.evrl)[0];
        const sportVal = currentEvent.evrl[sportKey];
        const minSpeedDefault =
            sportVal && sportVal.hasOwnProperty('mvtm0') && sportVal.mvtm0 != -1
                ? convertShortDuration(sportVal.mvtm0)
                : null;
        const maxSpeedDefault =
            sportVal && sportVal.hasOwnProperty('mvtm1') && sportVal.mvtm1 != -1
                ? convertShortDuration(sportVal.mvtm1)
                : null;

        const checkValidDefault =
            sportVal &&
            ((sportVal.hasOwnProperty('dis0') && sportVal.dis0 != -1) ||
                (sportVal.hasOwnProperty('dis1') && sportVal.dis1 != -1) ||
                (sportVal.hasOwnProperty('mvtm0') && sportVal.mvtm0 != -1) ||
                (sportVal.hasOwnProperty('mvtm1') && sportVal.mvtm1 != -1) ||
                (sportVal.hasOwnProperty('spm0') && sportVal.spm0 != -1) ||
                (sportVal.hasOwnProperty('spm1') && sportVal.spm1 != -1) ||
                (sportVal.hasOwnProperty('bmp0') && sportVal.bmp0 != -1) ||
                (sportVal.hasOwnProperty('bmp1') && sportVal.bmp1 != -1));

        const checkValidExtraDefault =
            (sportVal && sportVal.hasOwnProperty('spm0') && sportVal.spm0 != -1) ||
            (sportVal.hasOwnProperty('spm1') && sportVal.spm1 != -1) ||
            (sportVal.hasOwnProperty('bmp0') && sportVal.bmp0 != -1) ||
            (sportVal.hasOwnProperty('bmp1') && sportVal.bmp1 != -1);

        const checkDisMinDefault = sportVal && sportVal.hasOwnProperty('dis0') && sportVal.dis0 != -1;
        const checkDisMaxDefault = sportVal && sportVal.hasOwnProperty('dis1') && sportVal.dis1 != -1;
        const checkSpeedMinDefault = sportVal && sportVal.hasOwnProperty('mvtm0') && sportVal.mvtm0 != -1;
        const checkSpeedMaxDefault = sportVal && sportVal.hasOwnProperty('mvtm1') && sportVal.mvtm1 != -1;
        const checkSpmDefault = sportVal && sportVal.hasOwnProperty('spm0') && sportVal.spm0 != -1;
        const checkBpmDefault = sportVal && sportVal.hasOwnProperty('bmp0') && sportVal.bmp0 != -1;

        const [sportType, setSportType] = useState(sportKey);
        const [radioMatchTypeValue, setRadioMatchTypeValue] = useState(1);
        const [radioPrivacyValue, setRadioPrivacyValue] = useState(currentEvent.jpri);
        const [startDate, setStartDate] = useState(moment(convertTimestamptoDate(currentEvent.betm, formatD), formatD));
        const [endDate, setEndDate] = useState(moment(convertTimestamptoDate(currentEvent.entm, formatD), formatD));
        const [startTime, setStartTime] = useState(
            moment(convertTimestamptoDate(currentEvent.betm, formatT24), formatT24)
        );
        const [endTime, setEndTime] = useState(moment(convertTimestamptoDate(currentEvent.entm, formatT24), formatT24));

        const [checkValid, setCheckValid] = useState(checkValidDefault);
        const [checkValidExtra, setCheckValidExtra] = useState(checkValidExtraDefault);
        const [checkSpm, setCheckSpm] = useState(checkSpmDefault);
        const [checkBpm, setCheckBpm] = useState(checkBpmDefault);
        const [checkMinDis, setCheckMinDis] = useState(checkDisMinDefault);
        const [checkMaxDis, setCheckMaxDis] = useState(checkDisMaxDefault);
        const [checkMinSpeed, setCheckMinSpeed] = useState(checkSpeedMinDefault);
        const [checkMaxSpeed, setCheckMaxSpeed] = useState(checkSpeedMaxDefault);

        const [name, setName] = React.useState(currentEvent.name);
        const [desc, setDesc] = React.useState(currentEvent.desc);
        const [evtp, setEvtp] = React.useState(currentEvent.evtp);
        const [coverEvent, setCoverEvent] = React.useState(currentEvent.cover);
        const [coverEventLocal, setCoverEventLocal] = React.useState(getImageResource(currentEvent.cover));
        const [minDis, setMinDis] = useState(sportVal && sportVal.dis0 != -1 ? sportVal.dis0 : null);
        const [maxDis, setMaxDis] = useState(sportVal && sportVal.dis1 != -1 ? sportVal.dis1 : null);
        const [minSpeed, setMinSpeed] = useState(minSpeedDefault ? moment(minSpeedDefault, formatT) : null);
        const [maxSpeed, setMaxSpeed] = useState(maxSpeedDefault ? moment(maxSpeedDefault, formatT) : null);
        const [minSpm, setMinSpm] = useState(
            sportVal && sportVal.hasOwnProperty('spm0') && sportVal.spm0 != -1 ? sportVal.spm0 : 100
        );
        const [maxSpm, setMaxSpm] = useState(
            sportVal && sportVal.hasOwnProperty('spm1') && sportVal.spm1 != -1 ? sportVal.spm1 : 200
        );
        const [minBpm, setMinBpm] = useState(
            sportVal && sportVal.hasOwnProperty('bmp0') && sportVal.bmp0 != -1 ? sportVal.bmp0 : 90
        );
        const [maxBpm, setMaxBpm] = useState(
            sportVal && sportVal.hasOwnProperty('bmp1') && sportVal.bmp1 != -1 ? sportVal.bmp1 : 200
        );

        const validName = name && name.trim().length > 0;
        const validDesc = desc && desc.trim().length > 0;
        const isActive = validName;
        const cssButton = isActive ? 'btn-40 btn-blue action-create' : 'btn-40 btn-blue action-create inactive';
        const isEventReady = currentEvent.sts == EVENT_STATUS_TYPE.READY;

        const [errName, setErrName] = useState(false);
        const [errDesc, setErrDesc] = useState(false);

        const { t } = useTranslation();

        function validInput() {
            let hasError = false;

            if (!name || name.trim().length == 0 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (!minDis && checkMinDis && checkValid) {
                setMessageSnackbar(t('eu.error_input_min_dis'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (!maxDis && checkMaxDis && checkValid) {
                setMessageSnackbar(t('eu.error_input_max_dis'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (numeral(maxDis).value() < numeral(minDis).value() && checkValid && checkMaxDis && checkMinDis) {
                setMessageSnackbar(t('eu.error_dis1_max'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (!minSpeed && checkMinSpeed && checkValid) {
                setMessageSnackbar(t('eu.error_input_min_speed'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (!maxSpeed && checkMaxSpeed && checkValid) {
                setMessageSnackbar(t('eu.error_input_max_speed'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (
                checkValid &&
                checkMaxSpeed &&
                checkMinSpeed &&
                convertTimeToLong(moment(maxSpeed).format(formatT24)) >
                    convertTimeToLong(moment(minSpeed).format(formatT24))
            ) {
                setMessageSnackbar(t('eu.error_speed_max'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (maxSpm < minSpm && checkSpm && checkValidExtra && checkValid) {
                setMessageSnackbar(t('eu.error_spm_max'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            if (maxBpm < minBpm && checkBpm && checkValidExtra && checkValid) {
                setMessageSnackbar(t('eu.error_bpm_max'));
                setSnackbarType('error');
                onOpenSnackbar();
                return true;
            }

            return hasError;
        }

        async function uploadFileServer(file) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: 2
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                //upload cover
                setCoverEvent(result);
                setCoverEventLocal(URL.createObjectURL(file)); //file upload not ready
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onCancelUpdateEvent = () => {
            props.history.push(`/event-detail?evid=${currentEvent.evid}`);
        };

        const showConfirmCancel = () => {
            setOpenDialogCloseConfirm(true);
        };

        async function updateEvent() {
            const matchProps = {
                [sportType]: {
                    dis0: minDis && checkValid && checkMinDis ? numeral(minDis).value() : -1,
                    dis1: maxDis && checkValid && checkMaxDis ? numeral(maxDis).value() : -1,
                    mvtm0:
                        minSpeed && checkValid && checkMinSpeed
                            ? convertTimeToLong(moment(minSpeed).format(formatT24))
                            : -1, //tốc độ chậm nhất - pace 15:00
                    mvtm1:
                        maxSpeed && checkValid && checkMaxSpeed
                            ? convertTimeToLong(moment(maxSpeed).format(formatT24))
                            : -1, //tốc độ nhanh nhất - pace 3:30
                    spm0: minSpm && checkSpm && checkValidExtra && checkValid ? minSpm : -1,
                    spm1: maxSpm && checkSpm && checkValidExtra && checkValid ? maxSpm : -1,
                    bmp0: minBpm && checkBpm && checkValidExtra && checkValid ? minBpm : -1,
                    bmp1: maxBpm && checkBpm && checkValidExtra && checkValid ? maxBpm : -1
                }
            };

            //xóa các props có val -1
            /*for(var propt in matchProps[sportType]){
                const val = matchProps[sportType][propt]
                if(val == -1) delete matchProps[sportType][propt];
            }*/

            if (!validInput()) {
                let params;
                if (isEventReady) {
                    params = {
                        evid: currentEvent.evid,
                        uid: userProfile.uid,
                        ava: '',
                        form: radioMatchTypeValue,
                        jpri: radioPrivacyValue,
                        cover: coverEvent ? coverEvent : '',
                        entm: convertDatetoTimestamp(endDate, endTime),
                        desc: desc ? desc : ''
                    };
                } else {
                    params = {
                        evid: currentEvent.evid,
                        uid: userProfile.uid,
                        evtp,
                        sctp: 2,
                        ava: '',
                        form: radioMatchTypeValue,
                        jpri: radioPrivacyValue,
                        sport: sportType,
                        cover: coverEvent ? coverEvent : '',
                        betm: convertDatetoTimestamp(startDate, startTime),
                        entm: convertDatetoTimestamp(endDate, endTime),
                        name: name.trim(),
                        desc: desc ? desc : '',
                        evrl: matchProps
                    };
                }

                let result = await doUpdateEvent(params);

                if (result.ercd == 0) {
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    props.history.push(`/event-detail?evid=${currentEvent.evid}`);
                } else {
                    //setMessageSnackbar(t('global.action.fail'));
                    setMessageSnackbar(result.desc);
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        const handleChangeSportType = (val) => {
            if (val == SPORT_TYPE_ACTIVITY.RUNNING) setSportType(val);
        };

        function MenuSportItem(props) {
            const { category } = props;
            const cssClass = category.id == sportType ? 'card active' : 'card disabled';

            if (category.id != 0 && category.id != 3 && category.id != 6)
                return (
                    <div class="col" onClick={() => handleChangeSportType(category.id)}>
                        <div class={cssClass}>
                            {category.id == sportType ? (
                                <div class="card-header">
                                    <span class="icon-check_thick"></span>
                                    <span class="icon-triangle"></span>
                                </div>
                            ) : null}
                            <div class="card-body">
                                <div class={category.icon}></div>
                                <div class="txt">{t(category.title)}</div>
                            </div>
                        </div>
                    </div>
                );
            else return null;
        }

        function RadioItemMatchType(props) {
            const { item } = props;
            return (
                <li class="list-group-item">
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 frm-row radio">
                            <div class="frm-radio" onClick={() => setRadioMatchTypeValue(item.id)}>
                                <input
                                    type="radio"
                                    id={`r_${item.id}`}
                                    name={`radioMatchType${item.id}`}
                                    checked={radioMatchTypeValue === item.id}
                                />
                                <label htmlFor={`r_${item.id}`}>
                                    <span class="radio"></span>
                                </label>
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <div class="title">
                                <i class={`title-icon ${item.icon}`}></i>
                                <span class="ms-2">{t(item.title)}</span>
                            </div>
                            <div class="txt">{t(item.desc)}</div>
                        </div>
                    </div>
                </li>
            );
        }

        function RadioItemPrivacy(props) {
            const { item } = props;
            return (
                <div class="frm-radio-group-item" onClick={() => setRadioPrivacyValue(item.id)}>
                    <div class="flex-shrink-0 radio">
                        <div class="frm-radio">
                            <input
                                type="radio"
                                id={`r_${item.id}`}
                                name={`radioPrivacy${item.id}`}
                                checked={radioPrivacyValue === item.id}
                            />
                            <label htmlFor={`r_${item.id}`}>
                                <span class="radio"></span>
                            </label>
                        </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <div class="title">
                            <span class="">{t(item.title)}</span>
                        </div>
                        <div class="txt">{t(item.desc)}</div>
                    </div>
                </div>
            );
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleChangeDesc = (val) => {
            setDesc(val);
        };

        const handleChangeMinDis = (val) => {
            if (val.length > 0 && val < 1) val = 1;
            if (val.length > 0 && val > 160) val = 160;
            setMinDis(numeral(val).value());
        };

        const handleChangeMaxDis = (val) => {
            if (val.length > 0 && val < 1) val = 1;
            if (val.length > 0 && val > 160) val = 160;
            setMaxDis(numeral(val).value());
        };

        const handleChangeMinSpm = (val) => {
            setMinSpm(val);
        };

        const handleChangeMaxSpm = (val) => {
            setMaxSpm(val);
        };

        const handleChangeMinBpm = (val) => {
            setMinBpm(val);
        };

        const handleChangeMaxBpm = (val) => {
            setMaxBpm(val);
        };

        const handleChangeMinSpeed = (val) => {
            //setMinSpeed(moment(val, formatT24));
            setMinSpeed(val);
        };

        const handleChangeMaxSpeed = (val) => {
            setMaxSpeed(val);
        };

        const handleChangeStartDate = (val) => {
            setStartDate(val);
        };

        const handleChangeEndDate = (val) => {
            setEndDate(val);
        };

        const handleChangeStartTime = (val) => {
            setStartTime(moment(val, formatT));
        };

        const handleChangeEndTime = (val) => {
            setEndTime(moment(val, formatT));
        };

        const handleChangeCheckValid = (e) => {
            setCheckValid(e.target.checked);
        };

        const handleChangeCheckValidExtra = (e) => {
            setCheckValidExtra(e.target.checked);
        };

        const handleChangeCheckSpm = (e) => {
            setCheckSpm(e.target.checked);
        };

        const handleChangeCheckBpm = (e) => {
            setCheckBpm(e.target.checked);
        };

        const handleChangeCheckMinDis = (e) => {
            setCheckMinDis(e.target.checked);
        };

        const handleChangeCheckMaxDis = (e) => {
            setCheckMaxDis(e.target.checked);
        };

        const handleChangeCheckMinSpeed = (e) => {
            setCheckMinSpeed(e.target.checked);
        };

        const handleChangeCheckMaxSpeed = (e) => {
            setCheckMaxSpeed(e.target.checked);
        };

        const onFileChange = async (e) => {
            uploadFileServer(e.target.files[0]);
        };

        function disabledDate(current) {
            // Can not select days before today and today
            return current && current.valueOf() < Date.now();
        }

        const getDisabledHours = () => {
            var hours = [];
            for (var i = 20; i <= 23; i++) {
                hours.push(i);
            }
            return hours;
        };

        return (
            <section class="create-event-challenges">
                <div class="create-event-challenges-wrap">
                    <div class="page-intro-box">
                        <h2 class="page-intro-title">{t('ue.update_event_challen')}</h2>
                    </div>

                    <div class="create-event-challenges-box" style={{ display: 'none' }}>
                        <div class="create-event-challenges-box-header">
                            <h5 class="title">{t('ue.match_type')}</h5>
                        </div>
                        <div class="create-event-challenges-box-body">
                            <ul class="list-group">
                                {MENUS.map((item) => {
                                    return <RadioItemMatchType item={item} key={item.id} />;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div class="create-event-challenges-box" style={{ display: 'none' }}>
                        <div class="create-event-challenges-box-header">
                            <h5 class="title">{t('ue.title_create_event1')}</h5>
                        </div>
                        <div class="create-event-challenges-box-body sports">
                            <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-4">
                                {SPORT_TYPE.map((item) => {
                                    return <MenuSportItem category={item} key={item.id} />;
                                })}
                            </div>
                            <div class="description">{t('ue.soon_func')}</div>
                        </div>
                    </div>

                    <div class="create-event-challenges-box">
                        {!isEventReady ? (
                            <div class="create-event-challenges-box-header">
                                <h5 class="title">{t('ue.challen_criteria')}</h5>
                            </div>
                        ) : null}

                        <div class="create-event-challenges-box-body">
                            <div class="rules">
                                <ul class="list-group">
                                    {!isEventReady ? (
                                        <li class="list-group-item">
                                            <div class="rules-title">{t('ue.rank_criteria')}</div>
                                            <div class="frm-radio">
                                                <input type="radio" id="r3" name="radioList1" checked />
                                                <label for="r3">
                                                    <span class="radio"></span>
                                                    <span class="txt">{t('global.distince_km')}</span>
                                                </label>
                                            </div>
                                        </li>
                                    ) : null}

                                    {!isEventReady ? (
                                        <li class="list-group-item">
                                            <div class="frm-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="cb1"
                                                    name="checkboxList"
                                                    checked={checkValid}
                                                    onChange={handleChangeCheckValid}
                                                />
                                                <label for="cb1">
                                                    <span class="checkbox"></span>
                                                    <span class="txt">{t('ue.act_valid_criteria')}</span>
                                                </label>
                                            </div>

                                            <div
                                                class="card rules-valid-frm"
                                                style={{ display: checkValid ? 'block' : 'none' }}>
                                                <div class="card-body">
                                                    <div class="rules-valid-frm-item">
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbDistanceMin"
                                                                    name="checkboxDistanceList"
                                                                    checked={checkMinDis}
                                                                    onChange={handleChangeCheckMinDis}
                                                                />
                                                                <label for="cbDistanceMin">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('ue.min_distince')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkMinDis ? 'flex' : 'none' }}>
                                                                <div class="frm-txt has-form-text">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        maxLength={3}
                                                                        value={minDis}
                                                                        onChange={(text) =>
                                                                            handleChangeMinDis(
                                                                                stripedHtmlNumber(text.target.value)
                                                                            )
                                                                        }
                                                                        class="form-control distance"
                                                                    />
                                                                    <div class="form-text">km</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbDistanceMax"
                                                                    name="checkboxDistanceList"
                                                                    checked={checkMaxDis}
                                                                    onChange={handleChangeCheckMaxDis}
                                                                />
                                                                <label for="cbDistanceMax">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('ue.max_distince')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkMaxDis ? 'block' : 'none' }}>
                                                                <div class="frm-txt has-form-text">
                                                                    <input
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={maxDis}
                                                                        maxLength={3}
                                                                        onChange={(text) =>
                                                                            handleChangeMaxDis(
                                                                                stripedHtmlNumber(text.target.value)
                                                                            )
                                                                        }
                                                                        class="form-control distance "
                                                                    />
                                                                    <div class="form-text">km</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="rules-valid-frm-item">
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbSpeedMin"
                                                                    name="checkboxSpeedList"
                                                                    checked={checkMinSpeed}
                                                                    onChange={handleChangeCheckMinSpeed}
                                                                />
                                                                <label for="cbSpeedMin">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('ue.min_speed')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkMinSpeed ? 'block' : 'none' }}>
                                                                <div class="frm-txt has-form-text">
                                                                    <TimePicker
                                                                        //defaultValue={moment()}
                                                                        style={{ width: 200 }}
                                                                        onChange={(date) => handleChangeMinSpeed(date)}
                                                                        value={minSpeed}
                                                                        disabledHours={getDisabledHours}
                                                                        showNow={false}
                                                                        placeholder=""
                                                                        format={formatT24}
                                                                        showSecond={false}
                                                                        //clearIcon={null}
                                                                        minuteStep={1}
                                                                    />

                                                                    <div class="form-text">/km</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbSpeedMax"
                                                                    name="checkboxSpeedList"
                                                                    checked={checkMaxSpeed}
                                                                    onChange={handleChangeCheckMaxSpeed}
                                                                />
                                                                <label for="cbSpeedMax">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('ue.max_speed')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkMaxSpeed ? 'block' : 'none' }}>
                                                                <div class="frm-txt has-form-text">
                                                                    <TimePicker
                                                                        //defaultValue={moment()}
                                                                        style={{ width: 200 }}
                                                                        onChange={(date) => handleChangeMaxSpeed(date)}
                                                                        value={maxSpeed}
                                                                        disabledHours={getDisabledHours}
                                                                        showNow={false}
                                                                        placeholder=""
                                                                        format={formatT24}
                                                                        showSecond={false}
                                                                        minuteStep={1}
                                                                    />

                                                                    <div class="form-text">/km</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="frm-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={checkValidExtra}
                                                            id="cb2"
                                                            name="checkboxList2"
                                                            onChange={handleChangeCheckValidExtra}
                                                        />
                                                        <label for="cb2">
                                                            <span class="checkbox"></span>
                                                            <span class="txt">{t('ue.hight_criteria')}</span>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="rules-valid-frm-item"
                                                        style={{ display: checkValidExtra ? 'flex' : 'none' }}>
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbSpm"
                                                                    checked={checkSpm}
                                                                    name="checkboxSpmList"
                                                                    onChange={handleChangeCheckSpm}
                                                                />
                                                                <label for="cbSpm">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('global.spm')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkSpm ? 'block' : 'none' }}>
                                                                <div class="row row-cols-auto align-items-center">
                                                                    <div class="col">
                                                                        <div class="frm-txt-lb">{t('ue.min')}</div>
                                                                        <div class="frm-txt has-form-text">
                                                                            <Select
                                                                                defaultValue={minSpm}
                                                                                style={{ width: 120 }}
                                                                                onChange={handleChangeMinSpm}>
                                                                                {dfArray.map((item) => {
                                                                                    return (
                                                                                        <Option value={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>

                                                                            <div class="form-text space">-</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="frm-txt-lb">{t('ue.max')}</div>
                                                                        <div class="frm-txt has-form-text">
                                                                            <Select
                                                                                defaultValue={maxSpm}
                                                                                style={{ width: 120 }}
                                                                                onChange={handleChangeMaxSpm}>
                                                                                {dfArray.map((item) => {
                                                                                    return (
                                                                                        <Option value={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>

                                                                            <div class="form-text">spm</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="frm-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="cbBpm"
                                                                    checked={checkBpm}
                                                                    name="checkboxBpmList"
                                                                    onChange={handleChangeCheckBpm}
                                                                />
                                                                <label for="cbBpm">
                                                                    <span class="checkbox"></span>
                                                                    <span class="txt-fw">{t('eu.heartbeat')}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="frm-row"
                                                                style={{ display: checkBpm ? 'block' : 'none' }}>
                                                                <div class="row row-cols-auto align-items-center">
                                                                    <div class="col">
                                                                        <div class="frm-txt-lb">{t('ue.min')}</div>
                                                                        <div class="frm-txt has-form-text">
                                                                            <Select
                                                                                defaultValue={minBpm}
                                                                                style={{ width: 120 }}
                                                                                onChange={handleChangeMinBpm}>
                                                                                {dfArrayBpm.map((item) => {
                                                                                    return (
                                                                                        <Option value={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>

                                                                            <div class="form-text space">-</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="frm-txt-lb">{t('ue.max')}</div>
                                                                        <div class="frm-txt has-form-text">
                                                                            <Select
                                                                                defaultValue={maxBpm}
                                                                                style={{ width: 120 }}
                                                                                onChange={handleChangeMaxBpm}>
                                                                                {dfArrayBpm.map((item) => {
                                                                                    return (
                                                                                        <Option value={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>

                                                                            <div class="form-text">bpm</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="create-event-challenges-box">
                        <div class="create-event-challenges-box-header">
                            <h5 class="title">{t('global.info_event')}</h5>
                        </div>
                        <div class="create-event-challenges-box-body">
                            <div class="rules">
                                <ul class="list-group">
                                    <li class="list-group-item">
                                        <div class={!coverEvent ? 'dropzone' : 'dropzone dz-max-files-reached'}>
                                            {!coverEvent ? (
                                                <div class="dropzone-fallback">
                                                    <h5 class="dropzone-fallback-title">{t('eu.cover_event')}</h5>
                                                    <div class="dropzone-fallback-needsClick">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            id="uploadBanner"
                                                            name=""
                                                            class="file-upload-input"
                                                            onChange={onFileChange}
                                                        />
                                                        <i class="icon-cloud-upload"></i>
                                                        <label class="msg" for="uploadBanner">
                                                            <span>{t('eu.cover_upload_tip')}</span>
                                                            <span class="msg-select">{t('eu.select_file')}</span>
                                                        </label>
                                                    </div>

                                                    <div class="dropzone-fallback-txt">
                                                        {t('eu.size_cover_suggest')}
                                                        <span class="text-ink-light">1212111x313321</span> (
                                                        {t('eu.size_cover_limit')})
                                                    </div>
                                                </div>
                                            ) : (
                                                <div class="dropzone-preview dropzone-preview-single">
                                                    <div class="dropzone-preview-cover dropzone-complete">
                                                        <img
                                                            class="dropzone-preview-img"
                                                            src={coverEventLocal}
                                                            alt="Banner"
                                                        />
                                                        <button class="btn dropzone-preview-edit">
                                                            <span class="ms-1">{t('global.edit')}</span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                name=""
                                                                className="file-upload-input"
                                                                onChange={onFileChange}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div class="frm-row">
                                            <p class="lb">{t('ue.event_name')} *</p>

                                            <div class="frm-txt">
                                                <input
                                                    type="text"
                                                    placeholder={t('ue.event_name_ph')}
                                                    maxLength={60}
                                                    value={name}
                                                    disabled={isEventReady}
                                                    onChange={(text) =>
                                                        handleChangeName(stripedHtml(text.target.value))
                                                    }
                                                    class="form-control"
                                                />
                                            </div>
                                            {errName ? (
                                                <div class="invalid-feedback">{t('ue.event_name_input_valid')}</div>
                                            ) : null}
                                        </div>
                                        <div class="frm-row">
                                            <p class="lb">{t('ue.event_desc')}</p>

                                            <div class="frm-txt">
                                                <textarea
                                                    class="form-control"
                                                    value={desc}
                                                    maxLength={1500}
                                                    onChange={(text) =>
                                                        handleChangeDesc(stripedHtml(text.target.value))
                                                    }
                                                    placeholder={t('ue.event_desc_ph')}
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="rules-title">{t('ue.event_time')}</div>
                                        <div class="rules-time-frm">
                                            <div class="rules-time-frm-item">
                                                <div class="col">
                                                    <div class="frm-row">
                                                        <p class="lb">{t('ue.start_time')}</p>
                                                        <div class="row row-cols-auto">
                                                            <div class="col">
                                                                <div class="frm-txt has-form-text">
                                                                    <DatePicker
                                                                        onChange={handleChangeStartDate}
                                                                        defaultValue={startDate}
                                                                        format={formatD}
                                                                        disabled={isEventReady}
                                                                        value={startDate}
                                                                        disabledDate={disabledDate}
                                                                        minDate={moment().toDate()}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="frm-txt has-form-text">
                                                                    <TimePicker
                                                                        onChange={(date, dateStr) =>
                                                                            handleChangeStartTime(dateStr)
                                                                        }
                                                                        disabled={isEventReady}
                                                                        value={startTime}
                                                                        format={formatT24}
                                                                        showSecond={false}
                                                                        clearIcon={null}
                                                                        minuteStep={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="frm-row">
                                                        <p class="lb">{t('ue.end_time')}</p>
                                                        <div class="row row-cols-auto">
                                                            <div class="col">
                                                                <div class="frm-txt has-form-text">
                                                                    <DatePicker
                                                                        onChange={handleChangeEndDate}
                                                                        minDate={moment().toDate()}
                                                                        defaultValue={endDate}
                                                                        disabledDate={disabledDate}
                                                                        format={formatD}
                                                                        svalue={endDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="frm-txt has-form-text">
                                                                    <TimePicker
                                                                        onChange={(date, dateStr) =>
                                                                            handleChangeEndTime(dateStr)
                                                                        }
                                                                        value={endTime}
                                                                        format={formatT24}
                                                                        showSecond={false}
                                                                        clearIcon={null}
                                                                        minuteStep={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="rules-time-frm-item"></div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="rules-title">{t('eu.private_mode')}</div>
                                        <div class="frm-radio-group">
                                            {MENUS_PRIVACY.map((item) => {
                                                return <RadioItemPrivacy item={item} key={item.id} />;
                                            })}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="action">
                        <button class="btn-40 btn-outline-blue action-cancel" onClick={() => showConfirmCancel()}>
                            {t('global.destroy')}
                        </button>
                        <button class={cssButton} onClick={() => updateEvent()}>
                            {t('eu.save_info')}
                        </button>
                    </div>
                </div>

                {openDialogCloseConfirm ? (
                    <CloseConfirmDialog
                        openDialogCloseConfirm={openDialogCloseConfirm}
                        onCancelUpdateEvent={onCancelUpdateEvent}
                        onClose={() => setOpenDialogCloseConfirm(false)}
                    />
                ) : null}
            </section>
        );
    })
);

export default UpdateEvent;
