import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from '../../../../../utils';
import { GROUP_ACTION } from '../../../../../helpers/ConstDefined';

const DeleteMemberDialog = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore'
)(
    observer((props) => {
        const { data, openDialogDeleteMember } = props;
        const { userProfile } = props.userStore;
        const { currentGroup, doActionGroup } = props.groupStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function deleteUser() {
            const params = {
                uid: userProfile.uid,
                grid: currentGroup.grid,
                action: GROUP_ACTION.DELETE_MEMBER,
                pfid: data.uid
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            props.onClose();
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogDeleteMember}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div class="popup-dialog">
                            <div class="popup-content w380">
                                <button type="button" class="popup-close" onClick={props.onClose}></button>
                                <div class="popup-body">
                                    <div class="popup-team-notify small">
                                        <p class="avatar">
                                            <img src={getImageResource(data.ava, true, true)} alt="" />
                                        </p>
                                        <p class="name">{t('global.delete_member')}</p>
                                        <div class="txt">
                                            <p>{t('global.delete_member_desc')}</p>
                                        </div>
                                        <div class="popup-btns col-2 w140">
                                            <button className="btn-44 blue" onClick={props.onClose}>
                                                {t('global.seaagain')}
                                            </button>
                                            <button className="btn-44 cancel" onClick={() => deleteUser()}>
                                                {t('global.delete_member')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DeleteMemberDialog;
