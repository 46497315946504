import { Col } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../../../configs/MediaQuery';

export const Section = styled.footer`
    font-family: 'Inter script=latin rev=1';
    width: 100%;
    background: #212b36;
    color: #fff;

    @media ${device.mobileS} {
        height: auto;
    }
    @media ${device.laptop} {
        height: auto;
    }
`;

export const Container = styled.div`
    max-width: 1160px;
    padding-bottom: 60px;
    margin: 0 auto;

    @media ${device.mobileS} {
        padding-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }
    @media ${device.tablet} {
        padding-top: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media ${device.laptop} {
        padding-top: 60px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const ContainerFooter = styled(Container)`
    padding-bottom: 0;
    @media ${device.mobileS} {
        padding-top: 60px;
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const CopySection = styled.div`
    background: #314154;
    width: 100%;
`;

export const CopyContainer = styled.div`
    max-width: 1160px;
    margin: 0 auto;
    height: 32px;
    width: 100%;
`;

export const FooterTitle = styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
    text-decoration: none;
`;

export const FooterHref = styled.a`
    text-decoration: none;
`;

export const FooterText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    margin-bottom: 16px;
`;

export const CopyText = styled(FooterText)`
    padding: 5px;
    margin: 0;

    @media ${device.mobileS} {
        text-align: center;
    }
    @media ${device.tablet} {
        text-align: left;
    }
`;

export const ColContainer = styled(Col)`
    padding-bottom: 32px;
    @media ${device.mobileS} {
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    @media ${device.tablet} {
        text-align: left;
        display: block;
    }
`;

export const Logo = styled.img`
    max-width: 100%;
    height: auto;
    padding-bottom: 32px;
`;

export const SocialContact = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    @media ${device.tablet} {
        padding-left: 0;
    }
`;

export const SocialIcon = styled.img`
    padding-right: 25px;
    height: 25px;
    object-fit: cover;
`;

export const DownloadButtonContainer = styled.div`
    padding-bottom: 18px;

    @media ${device.mobileS} {
        display: flex;
        flex-direction: column;
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: row;
    }
`;

export const DownloadButtonImage = styled.img`
    width: 118px;
    
    @media ${device.mobileS} {
        padding-bottom: 10px;
    }
    @media ${device.tablet} {
        padding-right: 10px;
    }
`;