import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const ChangePassResult = inject('globalStore')(
    observer((props) => {
        const { doConfirmPass } = props.globalStore;
        const { token } = props;

        const [title, setTitle] = React.useState('');
        const [desc, setDesc] = React.useState('');
        const [email, setEmail] = React.useState('');
        const [css, setCSS] = React.useState('');

        useEffect(() => {
            handleConfirmPass();
        }, []);

        async function handleConfirmPass() {
            const params = {
                token
            };
            let result = await doConfirmPass(params);

            if (result != null && result.hasOwnProperty('message')) {
                setTitle('Đặt lại mật khẩu thành công');
                setDesc('Cập nhật mật khẩu mới thành công! Vui lòng đăng nhập trên ứng dụng bằng mật khẩu đã được gửi tới bạn qua email. Trường hợp cần hỗ trợ vui lòng liên hệ support@uprace.org.');
                setCSS('title');
                setEmail(result.message || '');
            } else {
                setTitle('Đặt lại mật khẩu thất bại');
                setDesc(
                    'Rất tiếc cập nhật mật khẩu mới cho tài khoản của bạn đã thất bại. Vui lòng thử lại chức năng "Quên mật khẩu" trong ứng dụng hoặc liên hệ support@uprace.org để được hỗ trợ.'
                );
                setCSS('title-fail');
                setEmail('');
            }
        }

        return (
            <section className="main-content">
                <div className="container">
                    <section className="navigation-uprace-app-login">
                        <div className="wrapper-btn">
                            <a className="btn  active" role="button">
                                UpRace - Đặt lại mật khẩu
                            </a>
                        </div>
                    </section>

                    <div className="wrapper-content forget-pass-app">
                        <div className="wrapper-user-card">
                            <div className="wrapper-image-logo">
                                <img src="../../img/logo/logo-blue.png" alt="logo-uprace" />
                            </div>
                            <h3 className={css}>{title}</h3>

                            <p className="description">{desc}</p>
                            <p className="email">{email}</p>

                            <div className="back-login">
                                <a className="btn btn-primary btn-confirm" href="https://uprace.org">
                                    Quay về trang chủ
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    })
);

export default ChangePassResult;
