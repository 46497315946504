import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { stripedHtml, getImageResource } from '../../../../../utils';
import { humanTime } from '../../../../../helpers/ConvertHelper';
import Dialog from '@material-ui/core/Dialog';
import UploadFile from './UploadFile';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { SPORT_TYPE } from '../../../../../data/master';

const DialogEditActivity = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, openDialogEditActivity } = props;
        const { userProfile, doUpdateActivity } = props.userStore;
        const {
            loading,
            language,
            doResetImageFeed,
            arrayImageFeedReal,
            setSnackbarType,
            setMessageSnackbar,
            onOpenSnackbar
        } = props.globalStore;

        const [sportTab, setSportTab] = React.useState(data.sport);
        const [title, setTitle] = React.useState(data.title);
        const [desc, setDesc] = React.useState(data.desc);

        const [errTitle, setErrTitle] = useState(false);

        const isInactive = !title || title.trim().length == 0;
        const cssButton = isInactive ? 'btn-44 blue inactive' : 'btn-44 blue';

        const { t } = useTranslation();

        async function updateActivity() {
            const params = {
                uid: userProfile.uid,
                atid: data.atid,
                photos: arrayImageFeedReal.length > 0 ? toJS(arrayImageFeedReal) : [''],
                sport: sportTab,
                title,
                desc
            };

            let result = await doUpdateActivity(params);
            if (result == true) {
                //update UI
                data.title = params.title;
                data.desc = params.desc;
                if (params.photos.length == 1 && params.photos[0] == '') data.photos = [];
                else data.photos = params.photos;
                data.sport = params.sport;

                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                onCloseDialog();
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const onCloseDialog = () => {
            doResetImageFeed();
            props.onClose();
        };

        function MenuSportItem(props) {
            const { category } = props;
            const cssClass = category.id == sportTab ? 'active' : '';

            if (category.id != 0 && category.id != 3 && category.id != 6)
                return (
                    <li class={cssClass} onClick={() => setSportTab(category.id)}>
                        <a href="javascript:void(0);">{t(category.title)}</a>
                    </li>
                );
            else return null;
        }

        function RenderButton() {
            if (loading) return <button className="btn-44 blue loading"></button>;
            else
                return (
                    <button class={cssButton} onClick={() => updateActivity()}>
                        {t('global.save_change')}
                    </button>
                );
        }

        if (data != null)
            return (
                <Dialog open={openDialogEditActivity}>
                    <div
                        class="uprace-popup has-title in"
                        id="edit-activity"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div class="popup-dialog" role="document">
                            <div class="popup-content w600">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => onCloseDialog()}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 class="popup-title">{t('global.edit_act')}</h2>
                                <div class="popup-body">
                                    <div class="popup-create-group team activity">
                                        <div class="create-group-wrap">
                                            <div class="frm-block">
                                                <div class="acc-box">
                                                    <p class="avatar">
                                                        <img src={getImageResource(data.ava, true, true)} alt="" />
                                                    </p>
                                                    <div class="details">
                                                        <p class="name">{data.name}</p>
                                                        <p class="date">{humanTime(data.betm, language)}</p>
                                                    </div>
                                                </div>
                                                <div class="frm-name">
                                                    <button class="del-btn"></button>
                                                    <input
                                                        type="text"
                                                        value={title}
                                                        maxLength={60}
                                                        onChange={(text) => setTitle(stripedHtml(text.target.value))}
                                                    />
                                                    <label>{t('global.title')}</label>
                                                    {errTitle ? (
                                                        <p class="error-tag">{t('global.error.valid_title')}</p>
                                                    ) : null}
                                                </div>
                                                <div class="frm-ctn">
                                                    <button class="del-btn"></button>
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        cols=""
                                                        rows=""
                                                        maxLength={1500}
                                                        value={desc}
                                                        onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                        placeholder={t('global.desc_your_act_more')}></textarea>
                                                </div>
                                                <div class="choose-box sport">
                                                    <p class="lb">{t('global.sport')}</p>
                                                    <ul class="list">
                                                        {SPORT_TYPE.map((item) => {
                                                            return <MenuSportItem category={item} key={item.id} />;
                                                        })}
                                                    </ul>
                                                </div>
                                                <UploadFile data={data} />
                                            </div>
                                        </div>
                                        <div class="popup-btns col-1">
                                            <RenderButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            );
        else return null;
    })
);

export default DialogEditActivity;
