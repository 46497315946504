import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
    EVENT_MEMBER_SORT_TYPE,
    EVENT_STATUS_TYPE,
    GLOBAL_CONFIG,
    SORT_VALUE_TYPE
} from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import EventItem from '../EventItem';
import SearchEmpty from './SearchEmpty';

const ViewAllEvent = inject(
    'userStore',
    'userEventStore',
    'eventStore'
)(
    observer((props) => {
        const { navigation } = props;
        const { userProfile } = props.userStore;
        const {
            loading,
            filterEventStatus,
            filterName,
            filterOrder,
            filterEventOffical,

            listEventFilter,
            totalEventFilter,
            doGetListEventFilter
        } = props.userEventStore;

        const isShowLoadMore = listEventFilter == null ? false : listEventFilter.length < totalEventFilter;
        const [title, setTitle] = useState('');

        const { t, i18n } = useTranslation();

        const handleTitlePage = () => {
            switch (filterEventStatus) {
                case EVENT_STATUS_TYPE.ALL:
                    setTitle(t('global.all') + `(${totalEventFilter})`);
                    break;
                case EVENT_STATUS_TYPE.SUGGEST:
                    setTitle(t('ue.suggest') + `(${totalEventFilter})`);
                    break;
                case EVENT_STATUS_TYPE.READY:
                    setTitle(t('ue.ready') + `(${totalEventFilter})`);
                    break;
                case EVENT_STATUS_TYPE.YET:
                    setTitle(t('ue.soon') + `(${totalEventFilter})`);
                    break;
                case EVENT_STATUS_TYPE.DONE:
                    setTitle(t('ue.finished') + `(${totalEventFilter})`);
                    break;
            }
            if (filterName) {
                let msg = t('eu.search_found');
                msg = msg.replace('[result]', listEventFilter && listEventFilter.length);
                setTitle(msg);
            }
        };

        const getListEventFilter = (isLoadMore) => {
            let params = {
                size: GLOBAL_CONFIG.PAGE_SIZE,
                uid: userProfile && userProfile.uid,
                name: filterName,
                sort: EVENT_MEMBER_SORT_TYPE.MEMBER,
                sts: filterEventStatus,
                offical: filterEventOffical,
                order: filterOrder
            };
            doGetListEventFilter(params, isLoadMore);
        };

        useEffect(() => {
            handleTitlePage();
        }, [t, listEventFilter]);

        useEffect(() => {
            getListEventFilter();
        }, []);

        function LoadMore() {
            if (isShowLoadMore) {
                if (loading)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getListEventFilter(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div class="users-event-box">
                {listEventFilter && listEventFilter.length > 0 ? (
                    <>
                        <div class="users-event-box-header">
                            <h3 class="title">{title}</h3>
                        </div>
                        <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                            {listEventFilter.map((item) => {
                                return (
                                    <EventItem data={item} key={item.evid} navigation={navigation} showDelete={false} />
                                );
                            })}
                        </div>
                        <LoadMore />
                    </>
                ) : (
                    <SearchEmpty />
                )}
            </div>
        );
    })
);

export default ViewAllEvent;
