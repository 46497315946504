import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const ENLang2022 = inject('authStore')(
    observer((props) => {
        useEffect(() => { }, []);

        return (
            <section class="fail-activity-list">
                <ul class="list">
                    <h3 className="list-title">COMPETITION RULES 2022</h3>
                    <p>
                        <strong>Event period: 00:00 28 October- 23:59 20 November</strong>
                    </p>

                    <p>
                        <strong> - Competition rules:</strong>
                    </p>

                    <div class="list-desc">
                        <li>
                            {' '}
                            + Average pace: 4:00-15:00 minute/km
                        </li>
                        <li>
                            + Minimum distance required to be eligible: 1km.
                        </li>
                        <li>
                            + Account should meet 12 days of activity + minimum total distance of 60km.
                        </li>
                        <li>
                            + (Users/ Runners) will automatically join the event by creating UpRace accounts
                        </li>
                        <li>
                            + Double the distance (km) recorded on the UpRace app on 11 November
                        </li>
                        <li>
                            + Double the distance (km) recorded on the UpRace app every Sunday during the event period.
                        </li>

                    </div>
                </ul>
            </section>
        );
    })
);

export default ENLang2022;
