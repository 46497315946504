import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const AboutUs = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <section className="main-content about-us">
                <div className="container container-2">
                    <div className="wrapper-content2 wrapper-content-left">
                        <div className="row">
                            <div className="col-md-6 content">
                                <h3 className="title">Mỗi bước chạy là một sự kết nối và tạo ra tác động xã hội to lớn</h3>
                                <button className="btn btn-primary btn-background">Tham gia cùng UpRace</button>
                            </div>

                            <div className="col-md-6">
                                <div
                                    className="banner-background"
                                    style={{ backgroundImage: `url('../../../img/vechungtoi/001.png')`}}>
                                    <img src="../../../img/vechungtoi/001.png" alt="bg-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper-content2 wrapper-content-center banner-background-001">
                    <div className="content">
                        <div className="content-top">
                            <h3 className="title title-color">Về chúng tôi</h3>
                            <p>
                                UpRace là ứng dụng thể thao được phát triển bởi đội ngũ kỹ sư Việt Nam, với những tính
                                năng chính là đo thành tích chạy bộ, đạp xe. Giúp cho người sử dụng dễ dàng theo dõi quá
                                trình tập luyện mỗi ngày. UpRace còn mang đến nhiều tính năng nổi bật như tổ chức sự
                                kiện trực tuyến kết nối và lan tỏa tinh thần yêu chạy bộ, hướng đến cộng đồng.
                            </p>
                            <p>
                                Từ ý tưởng tổ chức một giải chạy bộ cồng đồng cùng nội bộ VNG, chúng tôi đã phát triển
                                ứng dụng để giúp cho thật nhiều người có thể bắt đầu theo dõi thành tích chạy bộ của
                                mình, cũng như xây dựng cộng đồng chạy bộ xóa mờ khoản cách địa lý.
                            </p>
                        </div>

                        <div className="position-bottom">
                            <p>
                                <strong>Chúng tôi có mặt trên</strong>
                            </p>
                            <a href="https://apps.apple.com/us/app/id1476173971" target="_blank">
                                <img src="../../../img/vechungtoi/ios.png" alt="" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=vn.vng.uprace" target="_blank">
                                <img src="../../../img/vechungtoi/android.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container container-2">
                    <div className="wrapper-content2 wrapper-content-left">
                        <h3 className="title text-center">
                            <img src="../../../img/vechungtoi/logo-text-logo-logo-blue.png" alt="" />
                            <br></br>
                            Những tính năng dành riêng cho bạn
                        </h3>

                        <div className="row">
                            <div className="col-md-6 content">
                                <p className="icon">
                                    <img src="../../../img/vechungtoi/icon-systems-other-event.png" alt="" />
                                    <br></br>
                                </p>
                                <h4 className="title">Bảng xếp hạng trực quan</h4>
                                <p>
                                    Dễ dàng nhìn thấy vị trí của mình đang ở đâu trong bảng xếp hạng và tăng giảm thứ
                                    hạng như thế nào so với ngày trước.
                                </p>

                                <div className="wrapper-box">
                                    <div className="box">
                                        <div className="box-content">
                                            <img
                                                src="../../../img/vechungtoi/icon-systems-other-gender-arrow-up.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img
                                                src="../../../img/vechungtoi/icon-systems-other-gender-arrow-down.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div
                                    className="banner-background"
                                    style={{ backgroundImage: `url('../../../img/vechungtoi/banner002.png')`}}>
                                    <img src="../../../img/vechungtoi/banner002.png" alt="bg-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-2">
                    <div className="wrapper-content2 wrapper-content-right">
                        <div className="row">
                            <div className="col-md-6">
                                <div
                                    className="banner-background"
                                    style={{ backgroundImage: `url('../../../img/vechungtoi/banner004.png')`}}>
                                    <img src="../../../img/vechungtoi/banner004.png" alt="bg-1" />
                                </div>
                            </div>

                            <div className="col-md-6 content">
                                <p className="icon">
                                    <img src="../../../img/vechungtoi/icon-systems-other-record.png" alt="" />
                                    <br></br>
                                </p>
                                <h4 className="title">Định vị không cần Internet</h4>
                                <p>
                                    Người chạy bộ có thể đo thành tích chạy bộ, đạp xe của mình mà không cần kết nối
                                    Internet.
                                </p>

                                <div className="wrapper-box">
                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-glyph-nowifi.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>Offline</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-map-tracking.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>GPS tracking</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-2">
                    <div className="wrapper-content2 wrapper-content-left">
                        <div className="row">
                            <div className="col-md-6 content">
                                <p className="icon">
                                    <img src="../../../img/vechungtoi/logo-icon-logo-logo-blue.png" alt="" />
                                    <br></br>
                                </p>
                                <h4 className="title">Giao diện trực quan</h4>
                                <p>
                                    Thiết kế đơn giản với những chỉ số quan trọng, giúp người sử dụng có thể dễ dàng
                                    theo dõi thành tích của mình, để so sánh và có kế hoạch tập luyện khoa học, mang lại
                                    kết quả tích cực.
                                </p>

                                <div className="wrapper-box">
                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-other-team.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>Xếp hạng</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-other-road.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>Quãng đường</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-other-date.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>Ngày hoạt động</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div
                                    className="banner-background"
                                    style={{ backgroundImage: `url('../../../img/vechungtoi/banner003.png')`}}>
                                    <img src="../../../img/vechungtoi/banner003.png" alt="bg-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-2">
                    <div className="wrapper-content2 wrapper-content-right">
                        <div className="row">
                            <div className="col-md-6">
                                <div
                                    className="banner-background"
                                    style={{ backgroundImage: `url('../../../img/vechungtoi/banner005.png')`}}>
                                    <img src="../../../img/vechungtoi/banner005.png" alt="bg-1" />
                                </div>
                            </div>

                            <div className="col-md-6 content">
                                <p className="icon">
                                    <img src="../../../img/vechungtoi/icon-systems-other-share.png" alt="" />
                                    <br></br>
                                </p>
                                <h4 className="title">Chia sẻ thành tích tập luyện</h4>
                                <p>
                                    Bạn có thể chia sẻ thành tích tập luyện của bạn qua mạng xã hội bằng hình ảnh với
                                    các thông số, màu chữ tùy chỉnh.
                                </p>

                                <div className="wrapper-box">
                                    <div className="box">
                                        <div className="box-content">
                                            <img
                                                src="../../../img/vechungtoi/icon-systems-other-running-man.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="box-desc">
                                            <p>Chạy bộ</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img src="../../../img/vechungtoi/icon-systems-other-bike.png" alt="" />
                                        </div>
                                        <div className="box-desc">
                                            <p>Đạp xe</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="box-content">
                                            <img
                                                src="../../../img/vechungtoi/icon-systems-other-swiming-man.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="box-desc">
                                            <p>Bơi lội</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper-content2 wrapper-content-center banner-background-002"></div>
            </section>
        );
    })
);

export default AboutUs;
