import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../utils';
import { FOLLOW_STT, USER_EVENT_STATUS, GROUP_ACTION } from '../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import { MemberName, MemberCode } from 'components/MemberName';

const MemberItem = inject(
    'userStore',
    'groupStore',
    'eventStore',
    'userEventStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { isOwner, isAdmin, data, eventData } = props;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { doFollow, doLockUser } = props.groupStore;

        const { t } = useTranslation();

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        async function deleteUser() {
            props.onDeleteMember(data);
        }

        function addDefaultSrc(e) {
            e.target.src = '../../../img/ic_avatar.png';
        }

        if (isAdmin)
            return (
                <>
                    {userProfile.uid == eventData.owner ? (
                        <div class="manage-card">
                            <div class="avatar">
                                <img
                                    src={getImageResource(data.ava, true, true)}
                                    onError={addDefaultSrc}
                                    class="avatar-item"
                                />
                            </div>
                            <div class="info">
                                <div class="info__name">{MemberName(data)}</div>
                                <div class="info__code">{MemberCode(data)}</div>
                            </div>
                            <div class="btn-group">
                                {data.evlsts == USER_EVENT_STATUS.LOCK ? (
                                    <div class="btn-group-item">
                                        <div class="btn-block" disabled></div>
                                    </div>
                                ) : null}
                                <div class="btn-dropdown">
                                    <div class="uiselector setting-more">
                                        <p
                                            class="setting-more-btn"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"></p>
                                        <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                            {data.fists == 2 ? (
                                                userProfile.uid != data.uid ? (
                                                    <li onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                                        {t('global.unfollow')}
                                                    </li>
                                                ) : null
                                            ) : userProfile.uid != data.uid ? (
                                                <li onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                                                    {t('global.follow')}
                                                </li>
                                            ) : null}

                                            <li onClick={() => deleteUser()}>{t('eu.delete_member')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div class="manage-card">
                            <div class="avatar">
                                <img
                                    src={getImageResource(data.ava, true, true)}
                                    onError={addDefaultSrc}
                                    class="avatar-item"
                                />
                            </div>
                            <div class="info">
                                <div class="info__name">{MemberName(data)}</div>
                                <div class="info__code">{MemberCode(data)}</div>
                            </div>
                            <div class="btn-group">
                                {data.fists == 2 ? (
                                    userProfile.uid != data.uid ? (
                                        <button
                                            class="btn-32 btn-outline-blue btn-unfollow"
                                            onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                            {t('global.unfollow')}
                                        </button>
                                    ) : null
                                ) : userProfile.uid != data.uid ? (
                                    <div class="btn-group-item" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                                        <button class="btn-32 btn-blue btn-follow"> {t('global.follow')}</button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    )}
                </>
            );
        else if (isOwner)
            return (
                <div class="manage-card border-0 px-0">
                    <div class="avatar">
                        <img src={getImageResource(data.ava, true, true)} onError={addDefaultSrc} class="avatar-item" />
                    </div>
                    <div class="info">
                        <div class="info__name">{MemberName(data)}</div>
                        <div class="info__code">{MemberCode(data)}</div>
                    </div>
                </div>
            );
        else
            return (
                <div class="manage-card">
                    <div class="avatar">
                        <img src={getImageResource(data.ava, true, true)} onError={addDefaultSrc} class="avatar-item" />
                    </div>
                    <div class="info">
                        <div class="info__name">{MemberName(data)}</div>
                        <div class="info__code">{MemberCode(data)}</div>
                    </div>
                    <div class="btn-group">
                        {data.evlsts == USER_EVENT_STATUS.LOCK ? (
                            <div class="btn-group-item">
                                <div class="btn-block" disabled></div>
                            </div>
                        ) : null}
                        <div class="btn-dropdown">
                            <div class="uiselector setting-more">
                                <p
                                    class="setting-more-btn"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"></p>
                                <ul class="setting-more-list" aria-labelledby="list-server-choose">
                                    {data.fists == 2 ? (
                                        userProfile.uid != data.uid ? (
                                            <li onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                                {t('global.unfollow')}
                                            </li>
                                        ) : null
                                    ) : userProfile.uid != data.uid ? (
                                        <li onClick={() => followUser(FOLLOW_STT.FOLLOW)}>{t('global.follow')}</li>
                                    ) : null}

                                    <li onClick={() => deleteUser()}>{t('eu.delete_member')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
    })
);

export default MemberItem;
