import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import ImageList from './ImageList';
import { getImageResource, stripedHtml } from '../../../../utils';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseConfirmDialog from './CloseConfirmDialog';
import $ from 'jquery';

const CreatePostDialog = inject(
    'authStore',
    'globalStore',
    'userStore',
    'postStore'
)(
    observer((props) => {
        const { eventID, teamID, postType, autoOpenImgPicker, openDialogCreatePost, role } = props;

        const {
            loading,
            arrayImageFeedReal,
            doSetImageUpload,
            doSetImageUploadReal,
            doResetImageFeed,
            doUploadFile,
            setSnackbarType,
            setMessageSnackbar,
            onOpenSnackbar
        } = props.globalStore;
        const { userProfile } = props.userStore;
        const { doCreatePost } = props.postStore;

        const [title, setTitle] = React.useState('');
        const [desc, setDesc] = React.useState('');
        const [errTitle, setErrTitle] = useState(false);
        const [errDesc, setErrDesc] = useState(false);

        //check active button
        const validTitle = title && title.trim().length > 0;
        const validDesc = desc && desc.trim().length > 0;
        const validImg = arrayImageFeedReal.length > 0;

        const isActive = (validTitle && validDesc) || (validTitle && validImg); //!title || title.trim().length == 0 || !desc || desc.trim().length == 0;
        const cssButton = isActive ? 'btn-44 blue' : 'btn-44 blue inactive';

        const [openDialogCloseConfirm, setOpenDialogCloseConfirm] = React.useState(false);

        useEffect(() => {
            if(autoOpenImgPicker) 
            {
                $(document).ready(function(){
                    $("#fileUpload").trigger('click');
                });
            }
        },[]);


        const { t } = useTranslation();

        function validInput() {
            let hasError = false;

            if ((!title || title.trim().length == 0 || title.length > 60) && !isActive) {
                setErrTitle(true);
                hasError = true;
            } else setErrTitle(false);

            if ((!desc || desc.trim().length == 0) && !isActive) {
                setErrDesc(true);
                hasError = true;
            } else setErrDesc(false);

            return hasError;
        }

        async function addPost() {
            if (!validInput()) {
                const params = {
                    uid: userProfile.uid,
                    tmid: teamID,
                    evid: eventID,
                    title,
                    desc,
                    photos: arrayImageFeedReal.length > 0 ? toJS(arrayImageFeedReal) : []
                };
                if (postType) params.pstp = postType;

                let result = await doCreatePost(params);
                if (result == true) {
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    props.createPostSucc();
                    onCloseDialog();
                } else {
                    setMessageSnackbar(t('global.action.fail'));
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        function uploadMultipleFiles(e) {
            let fileObj = [];
            if (arrayImageFeedReal.length + e.target.files.length > 10) {
                setMessageSnackbar(t('team.post.create.limit_select_img'));
                setSnackbarType('success');
                onOpenSnackbar();
                return;
            }

            fileObj.push(e.target.files);

            for (let i = 0; i < fileObj[0].length; i++) {
                uploadFileServer(fileObj[0][i]);
            }
        }

        async function uploadFileServer(file) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: 1
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                doSetImageUploadReal(result);
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onCloseDialog = (confirm) => {
            if (confirm) setOpenDialogCloseConfirm(true);
            else {
                onCalcelPost();
            }
        };

        const onCalcelPost = () => {
            doResetImageFeed();
            setTitle('');
            setDesc('');
            setErrTitle(false);
            setErrDesc(false);
            props.onClose();
        };

        if (!userProfile) return null;

        function RenderButton() {
            if (loading) return <button className="btn-44 blue loading"></button>;
            else
                return (
                    <button className={cssButton} onClick={() => addPost()}>
                        {t('global.add_post')}
                    </button>
                );
        }

        return (
            <>
                <Dialog open={openDialogCreatePost}>
                    <div
                        className="uprace-popup has-title in"
                        id="create-post-fill-ctn"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div className="popup-dialog" role="document">
                            <div className="popup-content w600">
                                <button
                                    type="button"
                                    className="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => onCloseDialog(true)}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <h2 className="popup-title">{t('global.create_post')}</h2>
                                <div className="popup-body">
                                    <div className="popup-create-post">
                                        <div className="account">
                                            <p className="avatar">
                                                <img src={getImageResource(userProfile.ava, true, true)} alt="" />
                                            </p>
                                            <div className="desc">
                                                <p className="name">{userProfile.name}</p>
                                                <p className="tag">{userProfile.role && userProfile.role.name || role}</p>
                                            </div>
                                        </div>
                                        <div className="frm-block">
                                            <div className="frm-title">
                                                <button className="del-btn" style={{ display: 'none' }}></button>
                                                <p className="lb">{t('global.title')}</p>
                                                <div className="frm-txt">
                                                    <input
                                                        type="text"
                                                        placeholder={t('team.post.create.input_title')}
                                                        value={title}
                                                        maxLength={60}
                                                        onChange={(text) => setTitle(stripedHtml(text.target.value))}
                                                    />
                                                    {errTitle ? (
                                                        <p class="error-tag">{t('global.error.valid_name')}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="frm-cnt">
                                                <textarea
                                                    name=""
                                                    id=""
                                                    cols=""
                                                    rows="4"
                                                    maxLength={1500}
                                                    value={desc}
                                                    onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                    placeholder={t('team.post.create.input_desc')}></textarea>

                                                {errDesc ? (
                                                    <p class="error-tag">{t('global.error.valid_desc')}</p>
                                                ) : null}

                                                <ImageList />
                                            </div>
                                            <div className="add-imgs-block">
                                                <p className="lb">{t('team.post.create.add_image')}</p>
                                                <div className="add-file">
                                                    <input
                                                        id="fileUpload"
                                                        type="file"
                                                        accept="image/*"
                                                        name=""
                                                        className="file-upload-input"
                                                        onChange={uploadMultipleFiles}
                                                        multiple
                                                    />
                                                    <p className="txt">{t('global.select_image')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup-btns col-1">
                                            <RenderButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <CloseConfirmDialog
                    openDialogCloseConfirm={openDialogCloseConfirm}
                    onCalcelPost={onCalcelPost}
                    onClose={() => setOpenDialogCloseConfirm(false)}
                />
            </>
        );
    })
);

export default CreatePostDialog;
