import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { NotJoinTeam, TopRank, RankTable, TeamInfo, NotLogin, CustomRank, Banner  } from './components';
import { LEADERBOARD_TYPE, SPORT_TYPE } from '../../helpers/ConstDefined';
import _ from 'lodash';
const queryString = require('query-string');

const Leaderboard = inject(
    'authStore',
    'leaderboardStore',
    'eventStore',
    'userStore',
    'teamStore',
    'globalStore'
)(
    observer((props) => {
        const { naviTab, doGetListLeaderboardTeam, setNavigationTab } = props.leaderboardStore;
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;
        const { userTeam } = props.teamStore;
        const { doSetMainTab } = props.globalStore;
        const [title, setTitle] = React.useState('');

        let paramsQuery = queryString.parse(props.location.search);

        useEffect(() => {
            getLeaderboardTeam();
            doSetMainTab(1); //tab leaderboard
            setNavigationTab(SPORT_TYPE.COMPANY);
        }, []);

        function getLeaderboardTeam() {
            const data = {
                evid: paramsQuery.evid,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                value: SPORT_TYPE.COMPANY //tab company => value = 0 ,tab sport =>value = 1, tab school => 2
            };
            doGetListLeaderboardTeam(data);
        }

        function RenderLeft() {
            if (userProfile == null || _.isEmpty(userProfile)) {
                setTitle('');
                return <NotLogin eventID={paramsQuery.evid} />;
            } else {
                if (userTeam != null && userTeam.tmid == 0) {
                    setTitle('Bạn chưa có đội?');
                    return <NotJoinTeam eventID={paramsQuery.evid} />;
                } else {
                    setTitle('Đội của bạn');
                    return <TeamInfo navigation={props.history} eventID={paramsQuery.evid} />;
                }
            }
        }

        const handleJoinTeamSuccess = () => {
            props.history.push('/team');
        };

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        return (
            <>
                <section className="wrapper-banner-rank">
                    <div className="container">
                        <div className="banner-rank">
                            <div className="row">
                                <div className="col-lg-4  ">
                                    <h3 className="title-rank">{title}</h3>
                                    <RenderLeft />
                                </div>
                                <div className="col-lg-8  ">
                                    <h3 className="title-rank">Bảng xếp hạng</h3>
                                    {naviTab != -1 ?
                                    <TopRank eventID={paramsQuery.evid} /> : <Banner eventID={paramsQuery.evid} />}
                                </div>
                            </div>
                        </div>
                        <div className="arrow-back-to-top" onClick={() => scrollTop()}><img src="../../../img/back-to-top.svg" alt="image"/></div>
                    </div>
                </section>

                <section className="main-content nav-rank">
                    {naviTab != -1 ?
                        <RankTable onJoinTeamSuccess={() => handleJoinTeamSuccess()} navigation={props.history} eventID={paramsQuery.evid}  />
                    : <CustomRank navigation={props.history} eventID={paramsQuery.evid}  />}
                </section>
            </>
        );
    })
);

export default Leaderboard;
