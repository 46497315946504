import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { CHALLENGE_STT } from '../../../../helpers/ConstDefined';
import { convertTimestamptoDate, formatDistanceKM, getImageResource } from '../../../../utils/index';
import { humanTime } from '../../../../helpers/ConvertHelper';

const ChallengeResult = inject('challengeStore')(
    observer((props) => {
        const { challengeDetail } = props.challengeStore;
        useEffect(() => {}, []);

        const cover =
            challengeDetail.sts == CHALLENGE_STT.LOSE
                ? '../../../img/background-dnf.png'
                : '../../../img/background-finished.png';
        const avatar =
            challengeDetail != null && challengeDetail.ava != null && challengeDetail.ava.length > 0
                ? challengeDetail.ava.replace('.png', '@1x.png').replace('.jpeg','@1x.jpeg')
                : '../../../img/ic_avatar.png';

        return (
            <>
                <div className="row ">
                    <div className="col-md-6 ">
                        <h3 className="title-challenge">Thử thách của bạn</h3>
                    </div>
                    <div className="col-md-6 text-right"></div>
                </div>

                <div className="banner-challenges custom-banner-challenge">
                    <div className="banner-background" style={{ backgroundImage: `url(${cover})` }}>
                        <img src="../../../img/background-dnf.png" alt="Banner DNF" />
                    </div>
                    <div className="user-challenges-finished">
                        <div className="row ">
                            <div className="col-md-7 ">
                                <div className="wrapper-individual-content">
                                    <div className="wrapper-profile-user">
                                        <div className="row ">
                                            <div className="col-lg-2 ">
                                                <div className="avatar-user" style={{ backgroundImage: `url(${avatar})` }}>
                                                    <img className="avatar" src="../../../img/avatar.png" alt="image" />
                                                    <img className="gender-user" src="../../../img/male.png" alt="image" />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 ">
                                                <div className="information-user">
                                                    <span className="name-user">
                                                        {challengeDetail.name}{' '}
                                                        <p className="groups-user">
                                                            - {challengeDetail.tmnm}
                                                        </p>
                                                    </span>
                                                    <p className="time-active"> {humanTime(challengeDetail.crtm)}</p>
                                                    <p className="description-challenge-user">{challengeDetail.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="member-reaction ">
                                        <div className="row ">
                                            <div className="col-6 ">
                                                <div className="wrapper-like" onClick={props.onViewSocialLike}>
                                                    <ul className="avatars">
                                                        {challengeDetail.lkls.map((item) => {
                                                            return (
                                                                <li key={item.uid} className="avatars-item">
                                                                    <img src={getImageResource(item.ava, true,true)} className="avatars-img" />
                                                                </li>
                                                            );
                                                        })}
                                                        {challengeDetail.lkcnt > 4 ? (
                                                            <li className="avatars-item">
                                                                <span className="icon-more">...</span>
                                                            </li>
                                                        ) : null}
                                                    </ul>

                                                    <p className="number-like">
                                                        <a>{challengeDetail.lkcnt} người thích</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6 " onClick={props.onViewSocialComment}>
                                                <div className="wrapper-comments">
                                                    <p>
                                                        <a>
                                                            {challengeDetail.cmcnt} bình luận{' '}
                                                            {challengeDetail.cmcnt > 0 ? (
                                                                <i className="fa fa-chevron-right next"></i>
                                                            ) : null}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 ">
                                <div className="linear-challenge linear-challenge-custom">
                                    <div className="row">
                                        <div className="col-lg-4 padding-big-challenge">
                                            <div className="wrapper-linear linear-total-distance">
                                                <p className="title">Tổng quãng đường</p>
                                                <p className="value-distance">
                                                    {formatDistanceKM(challengeDetail.cdis)}{' '}
                                                    <span className="unit">km</span>
                                                </p>
                                                <p className="title">
                                                    Đã chạy:{' '}
                                                    <span className="value-result">
                                                        {formatDistanceKM(challengeDetail.dis)}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 padding-big-challenge">
                                            <div className="wrapper-linear linear-total-day">
                                                <p className="title">Tổng số ngày</p>
                                                <p className="value-distance">
                                                    {challengeDetail.cdays} <span className="unit"></span>
                                                </p>
                                                <p className="title">
                                                    Ngày:{' '}
                                                    <span className="value-result total-day">{challengeDetail.days}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 padding-big-challenge">
                                            <div className="wrapper-linear linear-max-distance">
                                                <p className="title">Quãng đg dài nhất</p>
                                                <p className="value-distance">
                                                    {formatDistanceKM(challengeDetail.cxdis)}{' '}
                                                    <span className="unit">km</span>
                                                </p>
                                                <p className="title">
                                                    Dài nhất:{' '}
                                                    <span className="value-result total-distance">
                                                        {formatDistanceKM(challengeDetail.xdis)}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

export default ChallengeResult;
