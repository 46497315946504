import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FEED_TYPE, SOCIAL_TAB, SOCIAL_TYPE, ACTIVITY_TYPE } from '../../../../../helpers/ConstDefined';
import { ActivityItem, DialogDeleteActivity, DialogEditActivity, DialogSocial, DialogViewImage } from '..';
import { useTranslation } from 'react-i18next';
import DialogComplain from '../DialogComplain';
import DialogComplained from '../DialogComplained';
import DialogLockComplain from '../DialogLockComplain';
import NotActivity from './NotActivity';
import { Link } from 'react-router-dom';

let activity;

const ListActivityInvalid = inject(
    'newsStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { eventID } = props;
        const {
            listFeedInvalid,
            loadingFeed,
            totalFeedInvalid,
            userProfile,
            doGetListFeedInvalid,
            doSetFunc
        } = props.userStore;
        const { userTeam } = props.teamStore;
        const { listErrorActivity, doGetListErrorActivity } = props.newsStore;

        const [openDialogDeleteActivity, setOpenDialogDeleteActivity] = React.useState(false);
        const [openDialogEditActivity, setOpenDialogEditActivity] = React.useState(false);
        const [openDialogViewImage, setOpenDialogViewImage] = React.useState(false);
        const [openDialogComplain, setOpenDialogComplain] = React.useState(false);
        const [openDialogComplained, setOpenDialogComplained] = React.useState(false);
        const [openDialogLockComplain, setOpenDialogLockComplain] = React.useState(false);

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const { appVersion, doResetImageFeed } = props.globalStore;
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);

        useEffect(() => {
            getUserFeedInvalid();
            if (!listErrorActivity) doGetListErrorActivity();
            window.scrollTo(0, 0);
        }, []);

        const { t } = useTranslation();

        const isShowLoadMore = listFeedInvalid == null ? false : listFeedInvalid.length < totalFeedInvalid;

        const onViewImage = (act) => {
            activity = act;
            setOpenDialogViewImage(true);
        };

        const onEditActivity = (act) => {
            activity = act;
            setOpenDialogEditActivity(true);
        };

        const onViewComplain = (act) => {
            activity = act;
            setOpenDialogComplain(true);
        };

        const onDeleteActivity = (act) => {
            activity = act;
            setOpenDialogDeleteActivity(true);
        };

        const handleOpenDialogSocial = (flag) => {
            setOpenDialogSocial(flag);
        };

        function getUserFeedInvalid(isLoadMore) {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile.uid,
                feed: FEED_TYPE.YOUR_ACTIVITY,
                evid: eventID,
                evsts: ACTIVITY_TYPE.INVALID,
                value: userProfile.uid
            };
            doGetListFeedInvalid(params, isLoadMore);
        }

        function onViewDialogSocial(act, type) {
            activity = act;
            onOpenDialogSocial(type);
        }

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingFeed)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getUserFeedInvalid(true)}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        if (totalFeedInvalid == 0)
            return (
                <div class="yours-page">
                    <div class="yours--back">
                        <a href="javascript:void(0);" onClick={() => doSetFunc(0)} class="back-btn">
                            {t('global.back')}
                        </a>
                    </div>
                    <NotActivity type={ACTIVITY_TYPE.INVALID} eventID={eventID} />
                </div>
            );
        else
            return (
                <div class="yours-page">
                    <div class="yours--back">
                        <a href="javascript:void(0);" onClick={() => doSetFunc(0)} class="back-btn">
                            {t('global.back')}
                        </a>
                    </div>
                    <div class="yours--activity">
                        <div class="title-row">
                            <h2 class="yours--title">
                                {t('global.list_act_invalid')} <span class="num">({totalFeedInvalid})</span>
                            </h2>
                            <Link to={`/error?evid=${eventID}`} class="seemore">
                                {t('global.view_error_act_invalid')}
                            </Link>
                        </div>
                        <div class="activity-list fail">
                            <ul>
                                {listFeedInvalid != null &&
                                    listFeedInvalid.map((item) => (
                                        <ActivityItem
                                            key={item.atid}
                                            data={item}
                                            eventID={eventID}
                                            type={ACTIVITY_TYPE.INVALID}
                                            onViewComplain={onViewComplain}
                                            onViewComplained={() => setOpenDialogComplained(true)}
                                            onViewLockComplain={() => setOpenDialogLockComplain(true)}
                                            onViewDialogSocial={onViewDialogSocial}
                                            onDelete={onDeleteActivity}
                                            onViewImage={onViewImage}
                                            onEdit={onEditActivity}></ActivityItem>
                                    ))}
                            </ul>
                            <LoadMore />
                        </div>
                        {openDialogComplain == true ? (
                            <DialogComplain
                                openDialogComplain={openDialogComplain}
                                data={activity}
                                eventID={eventID}
                                onClose={() => setOpenDialogComplain(false)}
                            />
                        ) : null}
                        {openDialogLockComplain == true ? (
                            <DialogLockComplain
                                openDialogLockComplain={openDialogLockComplain}
                                type={ACTIVITY_TYPE.COMPLAIN}
                                onClose={() => setOpenDialogLockComplain(false)}
                            />
                        ) : null}
                        {openDialogComplained == true ? (
                            <DialogComplained
                                openDialogComplained={openDialogComplained}
                                type={ACTIVITY_TYPE.COMPLAIN}
                                onClose={() => setOpenDialogComplained(false)}
                            />
                        ) : null}
                        {activity && openDialogDeleteActivity ? (
                            <DialogDeleteActivity
                                openDialogDeleteActivity={openDialogDeleteActivity}
                                data={activity}
                                type={ACTIVITY_TYPE.INVALID}
                                onClose={() => setOpenDialogDeleteActivity(false)}
                            />
                        ) : null}
                        {activity && openDialogEditActivity ? (
                            <DialogEditActivity
                                data={activity}
                                openDialogEditActivity={openDialogEditActivity}
                                onClose={() => setOpenDialogEditActivity(false)}
                            />
                        ) : null}
                        {openDialogSocial ? (
                            <DialogSocial
                                openDialogSocial={openDialogSocial}
                                onClose={() => handleOpenDialogSocial(false)}
                                data={activity}
                                type={SOCIAL_TYPE.FEED}
                                tab={tabSocial}
                            />
                        ) : null}
                        {activity && openDialogViewImage ? (
                            <DialogViewImage
                                data={activity}
                                openDialogViewImage={openDialogViewImage}
                                onClose={() => setOpenDialogViewImage(false)}
                            />
                        ) : null}
                    </div>
                </div>
            );
    })
);

export default ListActivityInvalid;
