import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { humanTime, formatDateString } from '../../../../helpers/ConvertHelper';
import { formatNumber, formatDistanceKM, formatDate } from '../../../../utils';
import { GLOBAL_CONFIG, EVENT_COMPARE_TYPE } from '../../../../helpers/ConstDefined';
import { toJS } from 'mobx';
import ChartData from './ChartData';
import { useTranslation } from 'react-i18next';

const ChartSummary = inject(
    'globalStore',
    'chartStore',
    'teamStore',
    'eventStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const { currentTeam } = props.teamStore;
        const { listEventCompare, listDataTeamEvent, doGetEventCompareData } = props.chartStore;
        const [yearText, setYearText] = React.useState('');
        const [yearText1, setYearText1] = React.useState('');
        const { currentEvent } = props.eventStore;
        const { language } = props.globalStore;

        const getCompareEventData = () => {
            const params = {
                evid: eventID,
                oevid:
                    eventID == GLOBAL_CONFIG.EVENT_UPRACE_2020_ID
                        ? GLOBAL_CONFIG.EVENT_UPRACE_2021_ID
                        : GLOBAL_CONFIG.EVENT_UPRACE_2020_ID,
                type: EVENT_COMPARE_TYPE.TEAM, //compare team
                value: teamID
            };
            doGetEventCompareData(params, EVENT_COMPARE_TYPE.TEAM, currentEvent.entm);
        };

        useEffect(() => {
            getCompareEventData();
        }, []);

        useEffect(() => {
            parseYearName();
        }, [listEventCompare]);

        const parseYearName = () => {
            if (!listEventCompare) return null;
            let year =
                listEventCompare[0].dyls.length > 0 ? listEventCompare[0].dyls[0].day.substring(0, 4) : null;
            let year1 =
                listEventCompare[1].dyls.length > 0 ? listEventCompare[1].dyls[0].day.substring(0, 4) : null;
            setYearText(year);
            setYearText1(year1);
        };

        const { t } = useTranslation();

        if (!listEventCompare) return null;

        return (
            <div>
                <h2 className="team-details--title">
                    {t('team.manage.team_stats')} {currentTeam && currentTeam.name}
                </h2>
                <div className="chart-block">
                    <div className="sumary">
                        <p className="lb">{t('global.get')}</p>
                        <p className="num">{formatDistanceKM(listEventCompare[0].rdis)}</p>
                        <p className="unit">{t('global.km_in_2021')}</p>
                        <div className="notity-box">
                            <p className="ico"></p>
                            <div className="tooltip-box">{t('global.km_valid_event_team')}</div>
                        </div>
                    </div>
                    <div className="years">
                        {yearText ? (
                            <>
                                <p className="color red"></p>
                                <p className="num">{yearText}</p>
                            </>
                        ) : null}
                        {yearText1 ? (
                            <>
                                <p className="color blue"></p>
                                <p className="num">{yearText1}</p>
                            </>
                        ) : null}
                    </div>
                    <div className="chart-box">
                        <ChartData eventID={eventID} />
                    </div>
                    <div className="statis-block">
                        {listDataTeamEvent ? (
                            <>
                                <div className="statis-box red">
                                    {listDataTeamEvent[0] ? (
                                        <>
                                            <div className="title">{formatDateString(listDataTeamEvent[0].day, language)}</div>
                                            <ul className="ctn">
                                                <li>
                                                    <p className="num">{formatDistanceKM(listDataTeamEvent[0].rdis)}</p>
                                                    <p className="unit">{t('global.number_km')}</p>
                                                </li>
                                                <li>
                                                    <p className="num">{formatNumber(listDataTeamEvent[0].act)}</p>
                                                    <p className="unit">{t('global.act')}</p>
                                                </li>
                                                <li>
                                                    <p className="num">{formatNumber(listDataTeamEvent[0].amem)}</p>
                                                    <p className="unit">{t('global.people_act')}</p>
                                                </li>
                                            </ul>
                                        </>
                                    ) : null}
                                </div>
                                <div className="statis-box blue">
                                    {listDataTeamEvent[1] ? (
                                        <>
                                            <div className="title">{formatDateString(listDataTeamEvent[1].day, language)}</div>
                                            <ul className="ctn">
                                                <li>
                                                    <p className="num">
                                                        {listDataTeamEvent[1] &&
                                                            formatDistanceKM(listDataTeamEvent[1].rdis)}
                                                    </p>
                                                    <p className="unit">{t('global.number_km')}</p>
                                                </li>
                                                <li>
                                                    <p className="num">
                                                        {listDataTeamEvent[1] && formatNumber(listDataTeamEvent[1].act)}
                                                    </p>
                                                    <p className="unit">{t('global.act')}</p>
                                                </li>
                                                <li>
                                                    <p className="num">
                                                        {listDataTeamEvent[1] && formatNumber(listDataTeamEvent[1].amem)}
                                                    </p>
                                                    <p className="unit">{t('global.people_act')}</p>
                                                </li>
                                            </ul>
                                        </>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    })
);

export default ChartSummary;
