import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { GLOBAL_CONFIG } from '../helpers/ConstDefined';
import { log } from '../utils';
import _ from 'lodash';

export default class TeamManageStore {
    @observable loading = false;
    @observable listAdmin = [];
    @observable listGroup = [];
    @observable listGroupRank = []; //rank groups of team
    @observable listTop15Mem = [];

    @observable tabMain = 0; // 0 (Thông tin đội, 1 : BXH  nhóm của đội)
    @observable tab = 0; // current tab (Quản lý đội / Quản lý nhóm)
    @observable func = 0; // 0: current team-detail, 1 : manage team, 2 : list post

    //filter
    @observable search = '';

    //total record for paging
    
    @observable totalGroup = 0;
    @observable totalGroupRank = 0;

    @observable listMember = [];
    @observable totalMember = 0;
    @observable pageListMember = 0;

    @observable listLeaderboardMember = []; // bảng xếp hạng thành viên trong team
    @observable totalLeaderboradMember = 0;
    @observable pageLeaderboardMember = 0;

    @observable pageListGroup = 0;
    @observable pageGroupRank = 0;

    @action.bound
    doAssignAdmin(user)
    {
        _.remove(this.listMember, (item) => {
            return item.uid == user.uid;
        });
        this.listAdmin.push(user);
        this.totalMember -= 1;
    }

    @action.bound
    doRegainAdmin(user)
    {
        _.remove(this.listAdmin, (item) => {
            return item.uid == user.uid;
        });
        this.listMember.push(user);
        this.totalMember += 1;
    }

    @action.bound
    doRemoveGroup(grid) {
        _.remove(this.listGroup, (item) => {
            return item.grid == grid;
        });
        _.remove(this.listGroupRank, (item) => {
            return item.grid == grid;
        });
    }

    @action.bound
    doSetMainTab(tab) {
        this.tabMain = tab;
    }

    @action.bound
    doSetFunc(val) {
        this.func = val;
    }

    @action.bound
    doSetTab(tab) {
        this.tab = tab;
    }

    @action.bound
    doSetSearchVal(val) {
        this.search = val;
    }

    //get leaderboad groups of team
    @action.bound
    doGetLeaderboardGroup(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageGroupRank += 1;
            params.from = params.size * this.pageGroupRank;
        } else {
            this.pageGroupRank = 0;
            params.from = 0;
        }

        requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    //if (!isLoadMore) this.listGroupRank = [];
                    this.totalGroupRank = resp.data.total;
                    if (isLoadMore) this.listGroupRank = this.listGroupRank.concat(resp.data.list);
                    else this.listGroupRank = resp.data.list;

                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list top 15 mem of team
    @action.bound
    doGetTop15Member(params) {
        this.loading = true; //show progress
        requestAPIV2(API.USER.GET_TOP, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.listTop15Mem = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list member of team
    @action.bound
    doGetListAdminTeam(params) {
        this.loading = true; //show progress
        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.listAdmin = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list member of team
    @action.bound
    doGetListMemberTeam(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageListMember += 1;
            params.from = params.size * this.pageListMember;
        } else {
            this.pageListMember = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    if (!isLoadMore) this.listMember = [];
                    this.totalMember = resp.data.total;
                    if (isLoadMore) this.listMember = this.listMember.concat(resp.data.list);
                    else this.listMember = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list group of team
    @action.bound
    doGetListGroupTeam(params, isLoadMore) {
        this.loading = true; //show progress
        requestAPIV2(API.GROUP.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    if (!isLoadMore) this.listGroup = [];
                    this.totalGroup = resp.data.total;
                    if (isLoadMore) this.listGroup = this.listGroup.concat(resp.data.list);
                    else this.listGroup = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //get list leaderboard
    @action.bound
    doGetListLeaderboardMember(params, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageLeaderboardMember += 1;
            params.from = params.size * this.pageLeaderboardMember;
        } else {
            this.pageLeaderboardMember = 0;
            params.from = 0;
        }

        requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    if (!isLoadMore) this.listLeaderboardMember = [];
                    this.totalLeaderboradMember = resp.data.total;
                    if (isLoadMore) this.listLeaderboardMember =  this.listLeaderboardMember.concat(resp.data.list);
                    else this.listLeaderboardMember = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //do follow, unfollow
    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    

                    //update local
                    const obj = _.find(this.listMember, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj) obj.fists = resp.data.fists;

                    const obj1 = _.find(this.listAdmin, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doLockUser(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_LOCK, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    log(resp);
                    //update local
                    const obj = _.find(this.listMember, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj) obj.evlsts = params.lsts;

                    const obj1 = _.find(this.listAdmin, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.evlsts = params.lsts;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }
}
