import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';
import { STATS_TYPE } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const UserActivity = inject(
    'authStore',
    'eventStore',
    'userStore',
    'teamStore',
    'userProfileStore'
)(
    observer((props) => {
        const { eventID, profileData } = props;
        const { currentTeam } = props.teamStore;
        const { eventUserStats, doGetEventStats } = props.userProfileStore;
        const { userProfile } = props.userStore;

        const { t } = useTranslation();
        useEffect(() => {
            getUserStats();
        }, []);

        function getUserStats() {
            let data = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: STATS_TYPE.USER,
                value: profileData.id
            };
            doGetEventStats(data, STATS_TYPE.USER);
        }

        if (eventUserStats != null)
            return (
                <>
                    <div class="yours--info">
                        <div class="info-details">
                            <p class="avatar">
                                <img src={getImageResource(profileData.ava, true, true)} alt="" />
                            </p>
                            <div class="details">
                                <p class="name">{profileData.name}</p>
                                <p class="company">{currentTeam.name}</p>
                            </div>
                        </div>
                        <div class="info-level">
                            <div class="box km-box">
                                <p class="num-box">
                                    <span class="num">{formatDistanceKM(eventUserStats.dis)}</span>
                                    <span class="unit">Km</span>
                                </p>
                            </div>
                            <div class="g-box">
                                <div class="box date-box">
                                    <p class="num">{formatNumber(eventUserStats.days)}</p>
                                    <p class="lb">{t('global.day_act')}</p>
                                </div>
                                <div class="box level-box">
                                    <p class="num">{formatNumber(eventUserStats.rank)}</p>
                                    <p class="lb">{t('global.rank_of_team')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        else return null;
    })
);

export default UserActivity;
