import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';
const queryString = require('query-string');

const ErrorEventList = inject('globalStore')(
    observer((props) => {
        const { language, doSetTabMainTop, doChangeLanguage } = props.globalStore;

        let params = queryString.parse(props.location.search);
        //if (_.isEmpty(paramsCallback) || _.isEmpty(paramsCallback.evid)) return <Redirect to={`/event`}></Redirect>;
        if (params && params.lang) doChangeLanguage(params.lang);
        let eventId = params.evid;
       
        useEffect(() => {
            doSetTabMainTop(null);
            window.scrollTo(0, 0);
        }, []);

        function ErrorEvent2021EN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">Details of activity not valid in the event</div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Wrong sport type</h3>
                            <div class="list-desc">
                                Activity was invalid due to choosing the wrong sport type of the event. <br /> How to
                                fix it: choose the correct event's sport type 
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Manual activity is not acceptable</h3>
                            <div class="list-desc">
                                Activity was invalid due to inputing manually using Strava. <br />
                                How to fix it: only activity that is recorded using GPS signal by UpRace or Garmin or
                                Strava is accepted.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity time is not valid </h3>
                            <div class="list-desc">
                                <p>Activity was invalid due to one of a few reasons below:</p>
                                <ul>
                                    <li>
                                        Activity’s start time/ end time/ upload time does not fall within the event’s
                                        duration.
                                    </li>
                                    <li>
                                        Activity has incorrect start time or start time in the future. For example: the
                                        current time of system is 9am but the start time of activity is 10am. 
                                    </li>
                                    <li>Activity has incorrect order of start time, end time and upload time</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity distance is not valid</h3>
                            <div class="list-desc">
                                Activity was invalid due to distance shorten than minimum distance or longer than
                                maximum distance
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Activity avg pace is not valid</h3>
                            <div class="list-desc">
                                Activity was invalid due to the average pace is not fall between slowest pace and
                                fastest pace.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity contains invalid split pace </h3>
                            <div class="list-desc">
                                In "Offical" event, even the average pace of the whole activity was valid, it might
                                still be invalid if there’s at least one average split pace faster than 2:20min/km
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity average cadence is not valid</h3>
                            <div class="list-desc">
                                The activity was invalid due to the cadence is not meet the requirement of the cadence
                                of the event
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity average heart rate is not valid </h3>
                            <div class="list-desc">
                                The activity was invalid due to the heart rate is not meet the requirement of the heart
                                rate of the event
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">You haven't joined this event</h3>
                            <div class="list-desc">
                                Activity was invalid due to the fact that you had not joined the event before the
                                activity was recorded.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time overlap with another activity </h3>
                            <div class="list-desc">
                                Activity was invalid due to recording overlap with another activity. <br />
                                For example: using all three apps/devices (UpRace, Garmin and Strava) to record at a
                                time. In that case, the priority order to validate should be activity from UpRace -{
                                    '>'
                                }{' '}
                                Garmin -{'>'} Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time overlap with a higher priority activity</h3>
                            <div class="list-desc">
                                Activity was invalid due to recording overlap with another activity. <br />
                                For example: using all three apps/devices (UpRace, Garmin and Strava) to record at a
                                time. In that case, the priority order to validate should be activity from UpRace -{
                                    '>'
                                }{' '}
                                Garmin -{'>'} Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">App priority recording error </h3>
                            <div class="list-desc">
                                <p>
                                    Activity was invalid due to the system not correctly records the valid order UpRace-
                                    {'>'}Garmin-{'>'}Strava
                                </p>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is banned by Admin </h3>
                            <div class="list-desc">Activity was invalid due to cheating found by Admin</div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity has been edited by Admin and become invalid</h3>
                            <div class="list-desc">
                                <p>Activity was invalid due to being edited by Admin.</p>
                                <p>
                                    The Admin has edited the parameters in the activity, making this activity no longer
                                    valid.
                                </p>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is not uploaded within bonus day</h3>
                            <div class="list-desc">
                                Activity was invalid due to not being uploaded within the bonus day. <br />
                                For example: any activity started on Sunday must be uploaded withthin that day. If it is
                                uploaded after 12am of the next day, it will be invalid.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is banned by Admin due to hack/cheat</h3>
                            <div class="list-desc">Activity was invalid due to cheating using software.</div>
                        </li>

                        <li>
                            <h3 class="list-title">Activity is not set public</h3>
                            <div class="list-desc">
                                Activity was invalid due to not being set at public mode. How to fix: Choose “Edit
                                activity”, then “Who can see your activity”, then “Anyone”
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }
        function ErrorEvent2021VI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">Chi tiết về các lỗi hoạt động không được ghi nhận trong sự kiện</div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Sai loại hình thể thao</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động ghi nhận sai loại hình thể thao so với loại thể thao của sự
                                kiện. <br />
                                Cách khắc phục: ghi hoạt động đúng với loại thể thao của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động được tạo thủ công không được chấp nhận</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động được tạo bằng cách nhập thủ công các thông số quãng đường, thời
                                gian, vận tốc... thông qua phần mềm Strava sẽ không được chấp nhận. <br />
                                Cách khắc phục: sử dụng tính năng “Ghi hoạt động" trên UpRace hoặc dùng đồng hồ Garmin,
                                hoặc tính năng “Ghi hoạt động" trên Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có thời gian không hợp lệ</h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra trong các trường hợp sau:</p>
                                <ul>
                                    <li>
                                        Thời gian bắt đầu, kết thúc và upload của hoạt động không nằm trong thời gian
                                        diễn ra sự kiện
                                    </li>
                                    <li>
                                        Hoạt động bị lỗi thời gian bắt đầu. Ví dụ: thời gian hiện tại của server là{' '}
                                        <strong>9 giờ sáng</strong> nhưng hoạt động được upload lên vào thời điểm này
                                        lại có thời gian bắt đầu lúc <strong>10 giờ sáng</strong>
                                    </li>
                                    <li>
                                        Thời gian của hoạt động không đúng theo thứ tự:{' '}
                                        <strong>
                                            bắt đầu -{'>'} kết thúc -{'>'} upload
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có quãng đường không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có độ dài chưa đủ quãng đường tối thiểu hoặc dài hơn quãng
                                đường tối đa
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động có tốc độ trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có tốc độ trung bình không nằm trong khoảng quy định từ tốc độ
                                chậm nhất đến tốc độ nhanh nhất
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có tốc độ quãng (1km) không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra trong sự kiện "Chính thức" khi hoạt động có bất kỳ một quãng (split 1km) nào
                                có tốc độ nhanh quá 1:30 phút/km. Ngay cả khi hoạt động có tốc độ trung bình hợp lệ
                                nhưng có một quãng nhanh quá 2:20 phút/km cũng sẽ bị coi là bất hợp lệ.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có nhịp chân trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có nhịp chân trung bình không nằm trong khoảng tiêu chí nhịp
                                chân của sự kiện
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động có nhịp tim trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có nhịp tim trung bình không nằm trong khoảng tiêu chí nhịp tim
                                của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động không được ghi nhận vì bạn chưa tham gia sự kiện này.</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi bạn chưa chọn Tham Gia sự kiện. Hoạt động này không được tính vào sự kiện
                                chưa tham gia.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có thời gian trùng với một hoạt động khác</h3>
                            <div class="list-desc">
                                Lỗi khi có nhiều hoạt động được ghi trùng thời gian bằng những phần mềm khác nhau.
                                <br /> Ví dụ: đồng thời ghi một hoạt động bằng cả Garmin, Strava và UpRace. Khi đó thứ
                                tự ưu tiên ghi nhận hoạt động hợp lệ sẽ là UpRace -{'>'} Garmin -{'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">
                                Hoạt động có thời gian trùng với một hoạt động khác được ưu tiên hơn
                            </h3>
                            <div class="list-desc">
                                Lỗi khi có nhiều hoạt động được ghi trùng thời gian bằng những phần mềm khác nhau.
                                <br /> Ví dụ: đồng thời ghi một hoạt động bằng cả Garmin, Strava và UpRace. Khi đó thứ
                                tự ưu tiên ghi nhận hoạt động hợp lệ sẽ là UpRace-{'>'}Garmin -{'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động này xảy ra do lỗi hệ thống ghi nhận thứ tự ưu tiên</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi Hệ thống ghi nhận chưa đúng thứ tự ưu tiên hợp lệ UpRace-{'>'} Garmin -
                                {'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động bị loại bỏ bởi Ban quản trị</h3>
                            <div class="list-desc">
                                Hoạt động bất hợp lệ vì Ban Quản trị phát hiện có sai phạm, gian lận trong hoạt động
                                này. Hoặc user nằm trong danh sách bị khóa của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">
                                Hoạt động được chỉnh sửa bởi Ban Quản Trị khiến hoạt động không còn hợp lệ.
                            </h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra do sự tác động của Ban Quản Trị vào hoạt động này.</p>
                                <p>
                                    Có thể Ban Quản Trị đã chỉnh sửa các thông số trong hoạt động khiến hoạt động này
                                    không còn hợp lệ nữa.
                                </p>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">
                                Hoạt động không được đồng bộ lên hệ thống trong ngày nhân đôi kết quả.
                            </h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động được ghi trong bonus day (ngày nhân 2) không được upload lên
                                ngay trong ngày đó.
                                <br /> Ví dụ hoạt động hợp lệ ghi bằng Garmin trong ngày Chủ nhật, nhưng sáng thứ 2 mới
                                upload lên hệ thống UpRace, dẫn đến lỗi không được ghi nhận.
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động bị loại bỏ do có sự gian lận bằng phần mềm</h3>
                            <div class="list-desc">
                                Lỗi do hoạt động được tạo ra bằng cách sử dụng phần mềm tạo kết quả chạy giả.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động này không để chế độ công khai</h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra khi hoạt động không được đặt ở chế độ công khai.</p>
                                <p>
                                    Cách khắc phục: Chọn “Sửa hoạt động" -{'>'} Chọn “Ai có thể xem hoạt động của bạn" -
                                    {'>'} Chọn “Tất cả mọi người”.
                                </p>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        function ErrorEvent2022EN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">Details of activity not valid in the event 2022</div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Wrong sport type</h3>
                            <div class="list-desc">
                                Activity was invalid due to choosing the wrong sport type of the event. <br /> How to
                                fix it: choose the correct event's sport type 
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Manual activity is not acceptable</h3>
                            <div class="list-desc">
                                Activity was invalid due to inputing manually using Strava. <br />
                                How to fix it: only activity that is recorded using GPS signal by UpRace or Garmin or
                                Strava is accepted.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity time is not valid </h3>
                            <div class="list-desc">
                                <p>Activity was invalid due to one of a few reasons below:</p>
                                <ul>
                                    <li>
                                        Activity’s start time/ end time/ upload time does not fall within the event’s
                                        duration.
                                    </li>
                                    <li>
                                        Activity has incorrect start time or start time in the future. For example: the
                                        current time of system is 9am but the start time of activity is 10am. 
                                    </li>
                                    <li>Activity has incorrect order of start time, end time and upload time</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity distance is not valid</h3>
                            <div class="list-desc">
                                Activity was invalid due to distance shorten than minimum distance or longer than
                                maximum distance
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Activity avg pace is not valid</h3>
                            <div class="list-desc">
                                Activity was invalid due to the average pace is not fall between slowest pace and
                                fastest pace.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity contains invalid split pace </h3>
                            <div class="list-desc">
                                In "Offical" event, even the average pace of the whole activity was valid, it might
                                still be invalid if there’s at least one average split pace faster than 2:20min/km
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity average cadence is not valid</h3>
                            <div class="list-desc">
                                The activity was invalid due to the cadence is not meet the requirement of the cadence
                                of the event
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity average heart rate is not valid </h3>
                            <div class="list-desc">
                                The activity was invalid due to the heart rate is not meet the requirement of the heart
                                rate of the event
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">You haven't joined this event</h3>
                            <div class="list-desc">
                                Activity was invalid due to the fact that you had not joined the event before the
                                activity was recorded.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time overlap with another activity </h3>
                            <div class="list-desc">
                                Activity was invalid due to recording overlap with another activity. <br />
                                For example: using all three apps/devices (UpRace, Garmin and Strava) to record at a
                                time. In that case, the priority order to validate should be activity from UpRace -{
                                    '>'
                                }{' '}
                                Garmin -{'>'} Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time overlap with a higher priority activity</h3>
                            <div class="list-desc">
                                Activity was invalid due to recording overlap with another activity. <br />
                                For example: using all three apps/devices (UpRace, Garmin and Strava) to record at a
                                time. In that case, the priority order to validate should be activity from UpRace -{
                                    '>'
                                }{' '}
                                Garmin -{'>'} Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">App priority recording error </h3>
                            <div class="list-desc">
                                <p>
                                    Activity was invalid due to the system not correctly records the valid order UpRace-
                                    {'>'}Garmin-{'>'}Strava
                                </p>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is banned by Admin </h3>
                            <div class="list-desc">Activity was invalid due to cheating found by Admin</div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity has been edited by Admin and become invalid</h3>
                            <div class="list-desc">
                                <p>Activity was invalid due to being edited by Admin.</p>
                                <p>
                                    The Admin has edited the parameters in the activity, making this activity no longer
                                    valid.
                                </p>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is not uploaded within bonus day</h3>
                            <div class="list-desc">
                                Activity was invalid due to not being uploaded within the bonus day. <br />
                                For example: any activity started on Sunday must be uploaded withthin that day. If it is
                                uploaded after 12am of the next day, it will be invalid.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Activity is banned by Admin due to hack/cheat</h3>
                            <div class="list-desc">Activity was invalid due to cheating using software.</div>
                        </li>

                        <li>
                            <h3 class="list-title">Activity is not set public</h3>
                            <div class="list-desc">
                                Activity was invalid due to not being set at public mode. How to fix: Choose “Edit
                                activity”, then “Who can see your activity”, then “Anyone”
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }
        function ErrorEvent2022VI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">Chi tiết về các lỗi hoạt động không được ghi nhận trong sự kiện 2022</div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Sai loại hình thể thao</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động ghi nhận sai loại hình thể thao so với loại thể thao của sự
                                kiện. <br />
                                Cách khắc phục: ghi hoạt động đúng với loại thể thao của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động được tạo thủ công không được chấp nhận</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động được tạo bằng cách nhập thủ công các thông số quãng đường, thời
                                gian, vận tốc... thông qua phần mềm Strava sẽ không được chấp nhận. <br />
                                Cách khắc phục: sử dụng tính năng “Ghi hoạt động" trên UpRace hoặc dùng đồng hồ Garmin,
                                hoặc tính năng “Ghi hoạt động" trên Strava.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có thời gian không hợp lệ</h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra trong các trường hợp sau:</p>
                                <ul>
                                    <li>
                                        Thời gian bắt đầu, kết thúc và upload của hoạt động không nằm trong thời gian
                                        diễn ra sự kiện
                                    </li>
                                    <li>
                                        Hoạt động bị lỗi thời gian bắt đầu. Ví dụ: thời gian hiện tại của server là{' '}
                                        <strong>9 giờ sáng</strong> nhưng hoạt động được upload lên vào thời điểm này
                                        lại có thời gian bắt đầu lúc <strong>10 giờ sáng</strong>
                                    </li>
                                    <li>
                                        Thời gian của hoạt động không đúng theo thứ tự:{' '}
                                        <strong>
                                            bắt đầu -{'>'} kết thúc -{'>'} upload
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có quãng đường không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có độ dài chưa đủ quãng đường tối thiểu hoặc dài hơn quãng
                                đường tối đa
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động có tốc độ trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có tốc độ trung bình không nằm trong khoảng quy định từ tốc độ
                                chậm nhất đến tốc độ nhanh nhất
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có tốc độ quãng (1km) không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra trong sự kiện "Chính thức" khi hoạt động có bất kỳ một quãng (split 1km) nào
                                có tốc độ nhanh quá 1:30 phút/km. Ngay cả khi hoạt động có tốc độ trung bình hợp lệ
                                nhưng có một quãng nhanh quá 2:20 phút/km cũng sẽ bị coi là bất hợp lệ.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có nhịp chân trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có nhịp chân trung bình không nằm trong khoảng tiêu chí nhịp
                                chân của sự kiện
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động có nhịp tim trung bình không hợp lệ</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động có nhịp tim trung bình không nằm trong khoảng tiêu chí nhịp tim
                                của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động không được ghi nhận vì bạn chưa tham gia sự kiện này.</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi bạn chưa chọn Tham Gia sự kiện. Hoạt động này không được tính vào sự kiện
                                chưa tham gia.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động có thời gian trùng với một hoạt động khác</h3>
                            <div class="list-desc">
                                Lỗi khi có nhiều hoạt động được ghi trùng thời gian bằng những phần mềm khác nhau.
                                <br /> Ví dụ: đồng thời ghi một hoạt động bằng cả Garmin, Strava và UpRace. Khi đó thứ
                                tự ưu tiên ghi nhận hoạt động hợp lệ sẽ là UpRace -{'>'} Garmin -{'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">
                                Hoạt động có thời gian trùng với một hoạt động khác được ưu tiên hơn
                            </h3>
                            <div class="list-desc">
                                Lỗi khi có nhiều hoạt động được ghi trùng thời gian bằng những phần mềm khác nhau.
                                <br /> Ví dụ: đồng thời ghi một hoạt động bằng cả Garmin, Strava và UpRace. Khi đó thứ
                                tự ưu tiên ghi nhận hoạt động hợp lệ sẽ là UpRace-{'>'}Garmin -{'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động này xảy ra do lỗi hệ thống ghi nhận thứ tự ưu tiên</h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi Hệ thống ghi nhận chưa đúng thứ tự ưu tiên hợp lệ UpRace-{'>'} Garmin -
                                {'>'} Strava
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động bị loại bỏ bởi Ban quản trị</h3>
                            <div class="list-desc">
                                Hoạt động bất hợp lệ vì Ban Quản trị phát hiện có sai phạm, gian lận trong hoạt động
                                này. Hoặc user nằm trong danh sách bị khóa của sự kiện
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">
                                Hoạt động được chỉnh sửa bởi Ban Quản Trị khiến hoạt động không còn hợp lệ.
                            </h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra do sự tác động của Ban Quản Trị vào hoạt động này.</p>
                                <p>
                                    Có thể Ban Quản Trị đã chỉnh sửa các thông số trong hoạt động khiến hoạt động này
                                    không còn hợp lệ nữa.
                                </p>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">
                                Hoạt động không được đồng bộ lên hệ thống trong ngày nhân đôi kết quả.
                            </h3>
                            <div class="list-desc">
                                Lỗi xảy ra khi hoạt động được ghi trong bonus day (ngày nhân 2) không được upload lên
                                ngay trong ngày đó.
                                <br /> Ví dụ hoạt động hợp lệ ghi bằng Garmin trong ngày Chủ nhật, nhưng sáng thứ 2 mới
                                upload lên hệ thống UpRace, dẫn đến lỗi không được ghi nhận.
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Hoạt động bị loại bỏ do có sự gian lận bằng phần mềm</h3>
                            <div class="list-desc">
                                Lỗi do hoạt động được tạo ra bằng cách sử dụng phần mềm tạo kết quả chạy giả.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Hoạt động này không để chế độ công khai</h3>
                            <div class="list-desc">
                                <p>Lỗi xảy ra khi hoạt động không được đặt ở chế độ công khai.</p>
                                <p>
                                    Cách khắc phục: Chọn “Sửa hoạt động" -{'>'} Chọn “Ai có thể xem hoạt động của bạn" -
                                    {'>'} Chọn “Tất cả mọi người”.
                                </p>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        const RenderContent = () => {
            if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2021_ID) {
                if (language == 'en') return <ErrorEvent2021EN />;
                else return <ErrorEvent2021VI />;
            }
            else if (eventId == GLOBAL_CONFIG.EVENT_UPRACE_2022_ID) {
                if (language == 'en') return <ErrorEvent2022EN />;
                else return <ErrorEvent2022VI />;
            }
            else return null;
        };

        return <RenderContent />;
    })
);

export default ErrorEventList;
