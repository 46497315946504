import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const DialogSendReq = inject('eventStore')(
    observer((props) => {
        const { openDialogSendReq } = props;

        const { t } = useTranslation();

        return (
            <Dialog open={openDialogSendReq}>
                <div
                    class="uprace-popup uprace-popup--v2 otp-verification"
                    id="requestJoinEvent"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content" style={{ width: '22rem' }}>
                            <div class="popup-body flex-column">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <div class="text-center">
                                    <div class="avatar mb-4">
                                        <img
                                            src="https://uprace2.vcdn.vn/web/uprace/illustrations/request-join.png"
                                            class="img-fluid request-join-img"
                                            alt="Check mail"
                                        />
                                    </div>
                                    <div class="txt">
                                        {t('global.send_join_req_desc')}
                                    </div>
                                    <div class="d-grid mb-4">
                                        <button
                                            class="btn-40 btn-blue btn-44 btn-close"
                                            data-dismiss="modal"
                                            onClick={() => props.onConfirm()}>
                                            {t('global.send_request')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogSendReq;
