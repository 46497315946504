import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import NewsItem from './NewsItem';
import { useTranslation } from 'react-i18next';

const ListNews = inject(
    'authStore',
    'newsStore'
)(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        const { listNews2021 } = props.newsStore;
        const { t, i18n } = useTranslation();

        return (
            <section className="section section--wrapper ">
                <div className="container container--news">
                    <h3 className="flex align-item-center">
                        <i className="icon icon-news"></i> <span>{t('landing_page.listnews.title')}</span>
                    </h3>
                    <div className="list-news flex wrap ">
                        {listNews2021 &&
                            listNews2021.map((item) => {
                                return <NewsItem data={item} key={item.id} />;
                            })}
                    </div>

                    <ul id="pagination" className="pagination flex align-item-center justify-content-center"></ul>
                </div>
                <img className="background-bottom lazyload desktop" data-src="images/bg/bg-bottom.png" alt="" />
            </section>
        );
    })
);

export default ListNews;
