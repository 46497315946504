import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const RuleEvent2023EN = inject('globalStore')(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        function RuleUpraceVI() {
            return (
                <section class="fail-activity-list">
                     <div class="title">
                        <p>UPRACE 2023</p>
                        <p>PARTICIPATION REGULATION</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Definition:</h3>
                            <div class="list-desc">
                            UpRace 2023 (hereinafter referred to as "event") is a FREE community running event for everyone on the UpRace web/mobile. Participation (hereinafter referred to as "runner") are free to run at arbitrary time and place and actively record the results of running on mobile devices (mobile phones, sports watches ...) with target accumulates as much running distance as possible. 
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Time:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Time for event registration opening:</strong> 
                                        starting from 00:00 04/09/2023, runners can continue to register and join Teams during the event.
                                    </li>
                                    <li>
                                        <strong>Competition time:</strong> 
                                        from 00:00 06/10/2023 to 23:59 29/10/2023 (24 days).
                                    </li>
                                    <li>
                                        <strong>Vietnamese time (GMT + 7)</strong> is used as the standard time zone for the event.
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">How to register:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                    Runners download the UpRace app on mobile phones or visit the website:{' '}
                                        <a href="https://uprace.org">https://uprace.org</a> 
                                        and register an account. The runners, who participated in the 2022 event, can log in to their existing accounts. 
                                    </li>
                                    <li>
                                        After successfully registering an account, runners can follow the step by going through the “Community” section then “Event” section on the app and click the “Join” button to participate in the UpRace 2023 event.
                                    </li>
                                    <li>
                                        Runners click to join the Team that you want to choose. Each runner's account can only select one Team to compete in the event. The runners are not allowed to change the Team from the time the event starts at 00:00 06/10/2023 to 23:59 26/10/2023, when the event ends.
                                    </li>
                                    <li>
                                        Runners, who join the event after the event has already started, will still be allowed to compete with the others.
                                    </li>

                                    <li>
                                    The runners then choose to fundraise to a Community Organization that they prefer. Each runner's account can only be chosen to fundraise to one Community Organization. The runners are not allowed to change their chosen Community Organization during the course of the event. There are 3 Community organizations for runners, including:
                                        <p style={{paddingLeft:30}}>• ASVHO (The Association in Support of Vietnamese Handicapped and Orphans)</p>
                                        <p style={{paddingLeft:30}}>
                                            • Green Viet
                                        </p>
                                        <p style={{paddingLeft:30}}>
                                            • Vu A Dinh Scholarship
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Team's Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Teams are placed in 3 league tables including:</p>
                                        <p>- Table 1: Company Teams</p>
                                        <p>- Table 2: Sports Club Teams</p>
                                        <p>- able 3: School Teams</p>
                                    </li>
                                    <li>
                                        Teams in the same Table will compete against each other and rank based on the total running distance of each team member. 
                                    </li>
                                    <li>
                                        Teams, which are established after the event starts, still participate in the competition normally.
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Runners Competition Rules</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Record achievements:</strong> 
                                        The runners record their achievements every runs by mobile device (phone, watch with GPS function ...).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Valid race:</strong>  is counted when committing the following conditions:
                                        </p>
                                        <p>
                                            - Recorded as completed and displayed on the runner' s UpRace account within 72 hours from the activity's start time and no later than the last day of the event.
                                        </p>
                                        <p>
                                            - Express the map clearly with no sign of critical errors due to the error of catching GPS signal of mobile devices. The activities which are imported manual results, either shared by others, or recorded for different accounts will not be validated; In case of running on treadmills, the result must be recorded by fitness tracker or smart watch like Garmin; which is able to synchronize with UpRace.
                                        </p>
                                        <p>
                                        Activities that are duplicated by recording from multiple devices at a same time will be counted as one activity. The priority order of activity’s source will be: (Garmin, Coros, Suunto, Apple Watch, Fitbit, POLAR…) {'>'} UpRace.
                                        </p>
                                        <p>
                                        There is a minimum distance of 1km. There is an unlimited maximum limit. Runners are allowed to pause the activity while running with the purpose of short break;
                                        </p>
                                        <p>
                                        Maximum Average Moving Pace is 4:00 minutes/km and minimum is 15:00 minutes/km, which are calculated by the number of minutes to complete 01 km. Split pace of each 1 km must not be faster than 3:30 minutes/km
                                        </p>
                                        <p>
                                        Races that take place on Sundays during the event period must be completed (before 24:00) on that day and double-results will be counted on the achievement of that day.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Active days: </strong> are the dates of at least one valid run according to the above rule.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Double-day:</strong> includes Sundays during the event period and special day 10/10/2023. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Achievement milestones:</strong> 
                                        </p>
                                        <p>
                                        - Milestone 1 (from 1 km to under 500 km): for this milestone, all valid activities specified in Article V.2 will be recorded in achievements.
                                        </p>
                                        <p>
                                        - Milestone 2 (from 500 km and above): in order to be recognized for achievements from 500 km or more, the valid activities specified in clause V.2 must be recorded by using Specialized Wrist Running Devices including: Garmin , Coros, Suunto, Apple Watch, Fitbit, POLAR
                                        </p>
                                        <p>
                                            <strong>*Note:</strong>  miles include double-results on special days or Sundays
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Complete the contest:</strong> 
                                            A runner will be certified to complete the contest when both of the following conditions are reached:
                                        </p>
                                        <p>
                                        - Must achieve a minimum number of 12 valid days
                                        </p>
                                        <p>
                                        - Must achieve a minimum distance of 60km (including double achievement on Sundays and on special day 10/10/2023).
                                        </p>
                                        <p>
                                            <strong>*Note:</strong>  
                                            For runners who do not complete the contest (minimum number of 12 valid days and minimum distance of 60km), the completed kilometers are still contributed to the team and fundraised by the sponsor to a social organization.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Achievement date: </strong> 
                                            The achievement will be counted on the day when activity starts. 
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Prize:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Individual Prizes:</strong>
                                        </p>
                                        <p>
                                        All runners who are qualified to finish the competition will receive a certificate of completion of the competition (electronic version) according to the completed km milestones including: 60km, 150km, 300km respectively with the titles: Pro runner, Hero runner and Angel runner.
                                        </p>
                                    </li>




                                    <li>
                                        <p>
                                            <strong>In-kind Award:</strong>
                                        </p>
                                        <p>
                                            <strong> - Achievement Award for Team: </strong>
                                        </p>
                                        <p>
                                            - 10 teams with the best overall performance in each league table will receive the Team reward. Team reward which includes UpRace Winning Team 2023 t-shirt will be given to 
                                            </p>
                                        <p>
                                        + Runners that complete the contest
                                        </p>
                                        <p>
                                        + And belong to the winning teams, according to the following table: 
                                        </p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Company</th>
                                                <th>Sport club</th>
                                                <th>School</th>
                                            </tr>
                                            <tr>
                                                <td>Rank 1</td>
                                                <td>Top 300 runners</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 2</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 3</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 4 and 5</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Rank 6,7,8,9 and 10</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                        </table>

                                        <p>
                                            <strong>Individual awards: </strong> 
                                            UpRace T-shirt is awarded to the Top 100 male/female runners with the highest performance (excluding runners who received the top 10 awards in the above table)
                                            </p>
                                        <p>
                                            <strong>Other prizes:</strong> 
                                            Prizes for the highest performing team in each group (Top 1):
                                        </p>
                                        <p style={{paddingLeft: 30}}>
                                            (1)   100 million VND is fundraised to the UpRace 2023 beneficiary that the team designates,
                                            </p>
                                        <p style={{paddingLeft: 30}}>
                                            (2)   Certification of winning team achievement (electronic version),
                                            </p>
                                        <p style={{paddingLeft: 30}}>
                                            (3)   Posts honoring the Top 1 team in each group on UpRace fanpage.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Fundraising for the community:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Origin of funds:</strong>
                                        </p>
                                        <p>
                                            - From sponsors: sponsors will donate at least 1000 VND/1km of valid run from runners
                                            </p>
                
                                    </li>
                                    <li>
                                        <p><strong>Use of funds: </strong> </p>
                                        <p>- All UpRace’s funds will be transferred to social organizations.</p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Rights of Organizing Committee and Team Administration Board</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Complaint: </strong> 
                                        Organizing Committee and Team Administration Board only consider complaints related to the results of runners in the top 10 of each Team. Email is the only channel to receive complaints and complaints must be sent by the representative of each team to the Organizer’s email address: support@uprace.vn. The decision of the Team Administration Board is the final decision in all arising complaints.
                                        </li>
                                    <li>
                                        <strong>Penalty: </strong> 
                                        The Organizing Committee has many plans to prevent and detect frauds if any, and call runners to show the spirit of fairplay and self-awareness. When any fraud is detected, the runner will be disqualified from the competition and not recognized for all achievements.
                                        </li>
                                    <li>
                                        <strong>Organizing Committee’s Right:</strong> 
                                        Administration board, who are the owners and moderator, of each team has full authority to decide whether a team member is eligible to compete or not, to stop and cancel the result of a member in case of fraud or non-compliance with the contest rules and the right to adjudicate complaints from team members.
</li>
                                </ul>
                            </div>
                        </li>

                        </ul>
                </section>
            );
        }

        return <RuleUpraceVI />;
    })
);

export default RuleEvent2023EN;
