import React from 'react';
import { EVENT_LOCK_STATUS } from 'helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

export const isAccountDeleted = (data) => {
    return data.sts == EVENT_LOCK_STATUS.DELETED || data.sts == EVENT_LOCK_STATUS.DELETED_PENDING;
};

export const MemberName = (data) => {
    const { t } = useTranslation();
    if (isAccountDeleted(data)) return <span style={{ color: '#919EAB' }}>{t('global.deleted_account')}</span>;
    return <span className="name">{data.name}</span>;
};

export const MemberCode = (data) => {
    if (isAccountDeleted(data)) return <span style={{ color: '#919EAB' }}>-</span>;
    return <span className="name">{data.code}</span>;
};

export const MemberAddress = (data) => {
    if (isAccountDeleted(data)) return <span style={{ color: '#919EAB' }}>-</span>;
    return (
        <span className="name">
            {data.city} - {data.ctry}
        </span>
    );
};
