import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const Footer = inject(
    'authStore',
    'newsStore'
)(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <div className="container">
                <a href="#">
                    <img className="lazyload" data-src="images/logo2.png" alt="" />
                </a>
                <div className="footer-content flex justify-content-space-between">
                    <div className="col">
                        <h4>Contact</h4>
                        <p></p>
                        <p>support@UpRace.org</p>
                    </div>
                    <div className="col">
                        <h4>UpRace</h4>
                        <a href="#">About us</a>
                        <a href="#">Events</a>
                        <a href="#">Download</a>
                        <a href="#">Contact</a>
                        <a href="#">News</a>
                    </div>

                    <div className="col ">
                        <h4>Download UpRace</h4>
                        <div className="flex">
                            <a className="download-ios--small" href="#">
                                <img className="lazyload" data-src="images/download/download-ios.png" alt="" />
                            </a>
                            <a className="download-and--small" href="#">
                                <img className="lazyload" data-src="images/download/download-and.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <p>© 2021 UpRace. All rights reserved.</p>
            </div>
        );
    })
);

export default Footer;
