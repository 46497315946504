import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const VNLang2021 = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <section class="fail-activity-list">
                <ul class="list">
                    <h3 className="list-title">THỂ LỆ THI ĐẤU</h3>
                    <p>
                        <strong>Thời gian thi đấu: 0:00 ngày 31/10/2021 đến 23:59 ngày 21/11/2021 (22 ngày). </strong>{' '}
                        Các đội và VĐV tham gia vào sự kiện sau thời điểm sự kiện bắt đầu vẫn được thi đấu bình thường.
                    </p>

                    <p>
                        <strong> - Thể lệ thi đấu:</strong>
                    </p>

                    <div class="list-desc">
                        <ul>
                            <li>
                                + VĐV sẽ được tự động tham gia vào sự kiện sau khi đăng ký tài khoản thành công. VĐV có
                                thể chọn thi đấu cho một đội bất kỳ hoặc thi đấu độc lập.
                            </li>
                            <li>
                                + Cuộc chạy của VĐV phải được ghi nhận hoặc đồng bộ vào UpRace trong vòng 72 giờ kể từ
                                thời điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối của sự kiện.
                            </li>
                            <li>
                                + Cự li chạy tối thiểu 1km/cuộc chạy. Cuộc chạy phải thể hiện đường chạy (route map) rõ
                                ràng, không có dấu hiệu sai số GPS. Trường hợp cuộc chạy thực hiện trên máy chạy bộ cần
                                được ghi nhận bằng thiết bị đo chuyên dụng như đồng hồ Garmin và cảm biến đeo chân.
                            </li>
                            <li>
                                + Tốc độ di chuyển trung bình tính theo số phút hoàn thành 1km của cuộc chạy chậm tối
                                thiểu là 15:00 phút/km và nhanh tối đa là 4:00 phút/km. Có tốc độ di chuyển trung bình
                                của tất cả các quãng 1km nhanh tối đa là 3:30 phút/km.
                            </li>
                            
                            <li>
                                + Các cuộc chạy diễn ra trong những ngày Chủ nhật trong thời gian diễn ra sự kiện phải
                                được hoàn tất trong ngày và kết quả nhân 2 được tính vào thành tích của ngày đó.
                            </li>
                            <li>
                                + Các cuộc chạy hợp lệ và được đo bằng app UpRace trong ngày 11/11/2021 sẽ được nhân 2
                                tính vào thành tích của ngày đó. Các cuộc chạy đo bằng Garmin hoặc Strava trong ngày này
                                được ghi nhận bình thường, không nhân 2.
                            </li>
                            <li>
                                + Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều app sẽ chỉ được ghi
                                nhận là một cuộc chạy, và theo thứ tự ưu tiên sau: UpRace -{'>'} Garmin -{'>'} Strava
                            </li>
                            <li>
                                + VĐV được chứng nhận hoàn thành cuộc thi khi đạt đồng thời 2 điều kiện:
                                <ul className="dot-list">
                                    <p> - Có số ngày chạy hợp lệ tối thiểu 12 ngày và </p>
                                    <p>
                                        - Có tổng số quãng đường chạy tối thiểu là 60 km (bao gồm cả thành tích nhân đôi
                                        trong các ngày Chủ nhật và ngày 11/11/2021)
                                    </p>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </ul>
            </section>
        );
    })
);

export default VNLang2021;
