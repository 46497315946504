import React, { useEffect, useState, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { COUNTRY, CITIES } from '../../../../data/location';
import { SIZE_CLOTHES } from '../../../../data/master';
import Select from 'react-select';
import styled from 'styled-components';
import { stripedHtml, stripedHtmlNumber, validEmail, formatDate, getImageResource } from '../../../../utils/index';
import DatePicker from 'react-datepicker';
import { format, setMonth, getMonth, getYear } from 'date-fns';
import * as moment from 'moment';
import DialogViewSize from '../../../SignUp/DialogViewSize';
import { GLOBAL_CONFIG, COMMON_ERROR } from '../../../../helpers/ConstDefined';
import { USER_MSG } from '../../../../helpers/MsgDefined';
import { useTranslation } from 'react-i18next';
import CropImageDialog from '../../../TeamDetails/components/EditTeamDialog/CropImageDialog';

const countryList = Object.keys(COUNTRY).map((key) => ({ value: COUNTRY[key], label: COUNTRY[key] }));
const cityList = Object.keys(CITIES).map((key) => ({ value: CITIES[key], label: CITIES[key] }));
const sizeList = Object.keys(SIZE_CLOTHES).map((key) => ({ value: SIZE_CLOTHES[key], label: SIZE_CLOTHES[key] }));

const SelectBox = styled.div`
    width: 100%;
`;

const customHeader = ({ date, changeMonth, changeYear }) => {
    const months = new Array(12).fill(null).map((_, i) => ({
        value: i,
        label: format(setMonth(new Date(), i), 'MMMM')
    }));

    const years = new Array(100).fill(null).map((_, i) => 2020 - i);

    const handleYearChange = ({ target: { value } }) => changeYear(value);
    const handleMonthChange = ({ target: { value } }) => changeMonth(value);

    return (
        <div>
            <select onChange={handleMonthChange} value={getMonth(date)}>
                {months.map(({ value, label }) => (
                    <option value={value} key={value}>
                        {label}
                    </option>
                ))}
            </select>

            <select onChange={handleYearChange} value={getYear(date)}>
                {years.map((year) => (
                    <option value={year} key={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const ProfileInfo = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { userProfile, doUpdateProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar, doUploadFile } = props.globalStore;
        const { t } = useTranslation();

        const GENDER_TYPE = [
            {
                id: 1,
                title: 'global.male'
            },
            {
                id: 2,
                title: 'global.female'
            }
        ];

        let userBOD;
        if (userProfile != null && userProfile.bod) userBOD = moment.unix(userProfile.bod / 1000).format('YYYY-MM-DD');
        else userBOD = formatDate('YYYY-MM-DD', new Date());

        const defaultBOD = moment(userBOD).toDate();
        const [bod, setBOD] = useState(defaultBOD);
        const [phone, setPhone] = useState(userProfile != null ? userProfile.phone : '');
        const [sexIndex, setSexIndex] = useState(userProfile != null && userProfile.sex);
        const [birthday, setBirthday] = useState(userBOD);
        const [height, setHeight] = useState(userProfile != null ? userProfile.hei : '');
        const [weight, setWeight] = useState(userProfile != null ? userProfile.wei : '');
        const [name, setName] = useState(userProfile != null ? userProfile.name : '');
        const [address, setAddress] = useState(userProfile != null ? userProfile.addr : '');
        const [city, setCity] = useState(userProfile != null && userProfile.city.length > 0 ? userProfile.city : 0);
        const [ctry, setCtry] = useState(userProfile && userProfile.ctry.length > 0 != null ? userProfile.ctry : 0);
        const [shirt, setShirt] = useState(userProfile != null ? userProfile.shirt : 0);
        const [ava, setAva] = React.useState(userProfile.ava);
        const [imageSrc, setImageSrc] = React.useState(userProfile && userProfile.ava);
        const [avaLocal, setAvaLocal] = React.useState(
            userProfile != null && userProfile.ava != null && userProfile.ava.length > 0
                ? getImageResource(userProfile.ava, true, true)
                : '../../../img/ic_avatar.png'
        );

        const [showTextCtry, setShowTextCtry] = useState(false);
        const [openDialogViewSize, setOpenDialogViewSize] = React.useState(false);
        const [openDialogCropImage, setOpenDialogCropImage] = useState(false);

        //error
        const [errPhone, setErrPhone] = useState(false);
        const [errHeight, setErrHeight] = useState(false);
        const [errWeight, setErrWeight] = useState(false);
        const [errName, setErrName] = useState(false);
        const [errCity, setErrCity] = useState(false);
        const [errAddress, setErrAddress] = useState(false);
        const [errBOD, setErrBOD] = useState(false);

        useEffect(() => {
            if (userProfile.ctry && userProfile.ctry != 'Vietnam') {
                setShowTextCtry(true);
                setCity(userProfile.city);
            }
            if (!userProfile.ctry || userProfile.ctry.length == 0) {
                userProfile.ctry = 'Vietnam';
                setCtry(userProfile.ctry);
            }
            if (!userProfile.city || userProfile.city.length == 0) {
                userProfile.city = 'Hà Nội';
                setCity(userProfile.city);
            }
            if (!userProfile.shirt || userProfile.shirt.length == 0) {
                userProfile.shirt = 'M';
                setShirt(userProfile.shirt);
            }
        }, []);

        function validInput() {
            let hasError = false;
            if (!name || name.length == 0 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (!height || height.length == 0 || height < 90 || height > 250) {
                setErrHeight(true);
                hasError = true;
            } else setErrHeight(false);

            if (!weight || weight.length == 0 || weight < 20 || weight > 200) {
                setErrWeight(true);
                hasError = true;
            } else setErrWeight(false);

            if (!city || city.length < 1 || city.length > 255) {
                setErrCity(true);
                hasError = true;
            } else setErrCity(false);

            if (!phone || phone.length < 1 || phone.length > 20) {
                setErrPhone(true);
                hasError = true;
            } else setErrPhone(false);

            if (!address || address.length == 0 || address.length > 255) {
                setErrAddress(true);
                hasError = true;
            } else setErrAddress(false);

            //check date
            const selectedDate = moment(birthday);
            const currentDate = moment(new Date());
            if (selectedDate.diff(currentDate) >= 0) {
                setErrBOD(true);
                hasError = true;
            } else setErrBOD(false);

            return hasError;
        }

        function readFile(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }

        const onFileChange = async (e) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                let imageDataUrl = await readFile(file);
                setImageSrc(imageDataUrl);
                setOpenDialogCropImage(true);
            } else {
                //not select file
            }
        };

        const onCropSuccess = (file) => {
            uploadFileServer(file);
        };

        async function uploadFileServer(file) {
            let dataFile = new FormData();
            dataFile.append('file', file);

            const params = {
                uid: userProfile.uid,
                image: 0
            };

            let result = await doUploadFile(params, dataFile);
            if (result) {
                //upload ava
                setAva(result);
                setAvaLocal(URL.createObjectURL(file));
            } else {
                setMessageSnackbar(t('global.error.upload_img_fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        async function uploadAvaUser(e) {
            uploadFileServer(e.target.files[0]);
        }

        const handleUpdateProfile = () => {
            if (!validInput()) {
                updateProfile();
            }
        };

        async function updateProfile() {
            let params = {
                uid: userProfile.uid,
                bod: moment(birthday).format('x'),
                ctry,
                city,
                wei: weight,
                hei: height,
                phone,
                ava,
                addr: address,
                shirt
            };
            if (userProfile.name != name) params.name = name;
            if (userProfile.sex != sexIndex) params.sex = sexIndex;

            let result = await doUpdateProfile(params);
            if (result == COMMON_ERROR.EVENT.EVENT_READY) {
                setMessageSnackbar(t('global.change_info_event_ready'));
                setSnackbarType('error');
                onOpenSnackbar();
            } else if (result) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                onOpenSnackbar();
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function getIndex(arr, val) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value == val) return i;
            }
            return 0;
        }

        const onChangeSize = (val) => {
            console.log(val.target.value);
        };

        const handleCityChange = (val) => {
            setCity(val.value);
        };

        const handleSexChange = (gender) => {
            setSexIndex(gender);
        };

        const handleChangeDate = (val) => {
            const date = formatDate('YYYY-MM-DD', val);
            setBirthday(date);
            setBOD(val);
        };

        const handleShirtChange = (val) => {
            setShirt(val.value);
        };

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleChangePhone = (val) => {
            if (val.length > 20) return;
            setPhone(val);
        };

        const handleChangeCity = (val) => {
            if (val.length > 255) return;
            setCity(val);
        };

        const handleChangeAddress = (val) => {
            //if (val.length > 255) return;
            setAddress(val);
        };

        const handleCountryChange = (selectedOption) => {
            if (selectedOption.value != 'Vietnam') {
                setCity('');
                setShowTextCtry(true);
            } else {
                setShowTextCtry(false);
                setCtry('Hà Nội');
            }
            setCtry(selectedOption.value);
        };

        const handleChangeHeight = (val) => {
            if (val > 250) val = 250;
            setHeight(val);
        };

        const handleChangeWeight = (val) => {
            if (val > 200) val = 200;
            setWeight(val);
        };

        function getMonth(date) {
            const d = moment(date);
            return d.month();
        }

        function getYear(date) {
            const d = moment(date);
            return d.year();
        }

        function RadioItem(props) {
            const { item } = props;
            return (
                <div onClick={() => handleSexChange(item.id)}>
                    <input type="radio" id={`r_${item.id}`} name={`radiolist}`} checked={sexIndex === item.id} />
                    <label htmlFor={`r_${item.id}`}>
                        <span class="radio"></span>
                        <span class="txt">{t(item.title)}</span>
                    </label>
                </div>
            );
        }

        const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
            <input
                onClick={onClick}
                type="text"
                id="birthday"
                readOnly
                defaultValue={birthday}
                className="form-control"
                placeholder="Ngày sinh"
            />
        ));

        return (
            <div class="profile-wbox statistical">
                <h2 class="profile-title">{t('settings.profileinfo.personal_info')}</h2>
                <div class="profile-frm">
                    <div class="frm-block">
                        <div class="frm-row avatar">
                            <p class="lb">{t('global.avatar')}</p>
                            <div class="frm-avatar">
                                <div class="avatar">
                                    <p class="img">
                                        <img src={avaLocal} alt="" />
                                    </p>
                                    <button class="upload-btn">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name=""
                                            class="file-upload-input"
                                            onChange={onFileChange}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.name')}</p>
                            <div class="frm-txt active">
                                <input
                                    type="text"
                                    placeholder=""
                                    value={name}
                                    maxLength={60}
                                    onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                />
                                {errName ? <p class="error-tag">{t('global.error.valid_name')}</p> : null}
                            </div>
                        </div>
                        <div class="frm-row radio">
                            <p class="lb">{t('global.gender')}</p>
                            <div class="frm-radio">
                                {GENDER_TYPE.map((item) => {
                                    return <RadioItem item={item} key={item.id} />;
                                })}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.birthday')}</p>
                            <div class="frm-txt active">
                                <DatePicker
                                    selected={bod}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={handleChangeDate}
                                    customInput={<CustomInputDate />}
                                    renderCustomHeader={(props) => customHeader({ ...props })}
                                />
                                {errBOD ? <p class="error-tag">{t('global.error.valid_birthday')}</p> : null}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.country')}</p>
                            <div class="frm-txt active">
                                <SelectBox>
                                    <Select
                                        className=""
                                        options={countryList}
                                        defaultValue={countryList[getIndex(countryList, ctry)]}
                                        onChange={handleCountryChange}
                                    />
                                </SelectBox>
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.city')}</p>
                            <div class="frm-txt active">
                                {showTextCtry ? (
                                    <>
                                        <input
                                            type="text"
                                            id="city"
                                            className="form-control"
                                            value={city}
                                            maxLength={100}
                                            onChange={(text) => handleChangeCity(stripedHtml(text.target.value))}
                                        />
                                        {errCity ? <p class="error-tag">{t('global.error.valid_city')}</p> : null}
                                    </>
                                ) : (
                                    <SelectBox>
                                        <Select
                                            options={cityList}
                                            onChange={handleCityChange}
                                            defaultValue={cityList[getIndex(cityList, city)]}
                                        />
                                    </SelectBox>
                                )}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.height')}</p>
                            <div class="frm-txt active">
                                <input
                                    type="text"
                                    value={height}
                                    maxLength={3}
                                    onChange={(text) => handleChangeHeight(stripedHtmlNumber(text.target.value))}
                                />
                                {errHeight ? <p class="error-tag">{t('global.error.valid_height')}</p> : null}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.weight')}</p>
                            <div class="frm-txt active">
                                <input
                                    type="text"
                                    value={weight}
                                    maxLength={3}
                                    onChange={(text) => handleChangeWeight(stripedHtmlNumber(text.target.value))}
                                />
                                {errWeight ? <p class="error-tag">{t('global.error.valid_weight')}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="profile-title">{t('global.extra_info')}</h2>
                <div class="profile-frm">
                    <div class="frm-block">
                        <div class="frm-row">
                            <p class="lb">{t('global.phone_number')}</p>
                            <div class="frm-txt active">
                                <input
                                    type="text"
                                    value={phone}
                                    maxLength={20}
                                    onChange={(text) => handleChangePhone(stripedHtmlNumber(text.target.value))}
                                />
                                {errPhone ? <p class="error-tag">{t('global.error.valid_phone')}</p> : null}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.address')}</p>
                            <div class="frm-txt">
                                <input
                                    type="text"
                                    value={address}
                                    maxLength={200}
                                    onChange={(text) => handleChangeAddress(stripedHtml(text.target.value))}
                                />
                                {errAddress ? <p class="error-tag">{t('global.error.valid_address')}</p> : null}
                            </div>
                        </div>
                        <div class="frm-row">
                            <p class="lb">{t('global.shirt_size')}</p>
                            <div class="frm-txt">
                                <Select
                                    options={sizeList}
                                    onChange={handleShirtChange}
                                    defaultValue={sizeList[getIndex(sizeList, shirt)]}
                                />
                            </div>
                            <div class="lnk">
                                <a href="javascript:void(0);" onClick={() => setOpenDialogViewSize(true)}>
                                    {t('global.view_size')}
                                </a>
                            </div>
                        </div>
                        <div class="frm-row" style={{ justifyContent: 'center' }}>
                            <div class="process-btn">
                                <button
                                    class="btn-40 blue"
                                    style={{ width: 260 }}
                                    onClick={() => handleUpdateProfile()}>
                                    {t('global.save')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {openDialogViewSize ? (
                    <DialogViewSize
                        onClose={() => setOpenDialogViewSize(false)}
                        openDialogViewSize={openDialogViewSize}
                    />
                ) : null}
                <CropImageDialog
                    openDialogCropImage={openDialogCropImage}
                    onCropSuccess={onCropSuccess}
                    imgSource={imageSrc}
                    onClose={() => setOpenDialogCropImage(false)}
                />
            </div>
        );
    })
);

export default ProfileInfo;
