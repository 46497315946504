import React from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';

const RankItem = inject(
    'authStore',
    'teamStore'
)(
    observer((props) => {
        const { data } = props;
        const rankImg = data.diff > 0 ? '../../../img/arrow-up.png' : '../../../img/arrow-down.png';
        const ava = data.ava.replace('.png','@1x.png').replace('.jpeg','@1x.jpeg');

        return (
            <tr onClick={props.onViewDetail}>
                <td>
                    <strong>{data.rank}</strong>
                </td>
                <td>
                    {data.diff != 0 ? (
                        <div className="status">
                            <img src={rankImg} alt="" />
                            <span>{Math.abs(data.diff)}</span>
                        </div>
                    ) : null}
                </td>
                <td>
                    <div className="member">
                        <div className="pull-left wrapper-avatar-member" style={{ backgroundImage: `url(${getImageResource(data.ava,true)})` }}>
                            <img src={getImageResource(data.ava,true)} alt="" />
                        </div>

                        <div className="pull-left ">
                            <p>
                                <span className="member-name">{data.name}</span>
                            </p>
                            <p className="sub-title">{formatNumber(data.mem)} VDV</p>
                        </div>
                    </div>
                </td>
                <td>{formatDistanceKM(data.ddis)}</td>
                <td>{formatDistanceKM(data.dis)}</td>
            </tr>
        );
    })
);

export default RankItem;
