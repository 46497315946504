import React from 'react';
import { Modal } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { COMMON_ERROR } from '../../../../helpers/ConstDefined';

const DialogDeleteChallenge = inject(
    'userStore',
    'challengeStore',
    'userStore',
    'globalStore',
    'teamStore',
    'eventStore'
)(
    observer((props) => {
        let { challengeDetail, openDialogDeleteChallenge } = props;
        const { doDeleteChallenge } = props.challengeStore;
        const { userProfile } = props.userStore;
        const { userTeam } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        async function deleteChallenge() {
            const params = {
                uid: userProfile.uid,
                tgid: challengeDetail.tgid
            };
            let result = await doDeleteChallenge(params);
            if (result == COMMON_ERROR.SUCCESS) {
                setMessageSnackbar('Xóa thử thách thành công');
                setSnackbarType('success');
                if (userTeam != null) userTeam.tgid = 0;
                onOpenSnackbar();
                props.onClose();
            } else if (result == COMMON_ERROR.EVENT.EVENT_READY) {
                setMessageSnackbar('Không thể xóa thử thách khi sự kiện đang diễn ra');
                setSnackbarType('error');
                onOpenSnackbar();
            } else {
                setMessageSnackbar('Xóa thử thách thất bại');
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        return (
            <Modal show={openDialogDeleteChallenge} onHide={props.onClose}>
                <div className="custom-modal  modal-confirm-join-team" role="dialog" aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Xoá thử thách cá nhân</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src="../../../img/icon-close-2.png" alt="image" onClick={props.onClose} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Thử thách này sẽ bị xóa vĩnh viễn và bạn sẽ không thể tìm thấy nó nữa. Bạn cũng có
                                    thể chỉnh sửa bài đăng này, nếu bạn chỉ muốn thay đổi điều gì đó.
                                </p>
                            </div>

                            <div className="modal-footer">
                                <div className="wrapper-btn custom-wrapper-btn">
                                    <a type="button" className="btn btn-outline-primary" onClick={props.onClose}>
                                        Bỏ qua
                                    </a>
                                    <button type="button" className="btn btn-primary" onClick={() => deleteChallenge()}>
                                        Xoá
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogDeleteChallenge;
