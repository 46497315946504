import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml } from 'utils';

const GroupRankEmpty = inject(
    'authStore',
    'teamManageStore'
)(
    observer((props) => {
        const {valSearch} = props;
        const { doSetFunc } = props.teamManageStore;

        useEffect(() => {}, []);

        //filter
        const [name, setName] = React.useState(valSearch);
        const cssShowClear = name && name.length > 0 ? 'search-box active' : 'search-box';

        const { t } = useTranslation();

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
               props.onSearchMemberGroup(name);
            }
        };

        function resetSearch() {
            setName('');
            props.onSearchMemberGroup();
        }

        return (
            <div className="group-details-wbox g-members-list-box">
                <h2 className="title">{t('team.groupdetail.bxh_group')}</h2>

                <div className="group-details-search">
                    <div className={cssShowClear}>
                        <button className="del-btn" onClick={() => resetSearch()}></button>
                        <input
                            type="text"
                            placeholder={t('team.grouplist.search_group')}
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        <button className="search-btn" onClick={() => props.onSearchMemberGroup(name)}>{t('global.search')}</button>
                    </div>
                </div>

                <div className="empty-box">
                    <p className="img">
                        <img src="../../images/imgs/no_member.png" alt="" />
                    </p>
                    <p className="empty-title">{t('team.groupdetail.member_empty_group')}</p>
                    <p className="note">{t('team.groupdetail.member_empty_group_desc')}</p>
                    <div className="btn-row">
                        <button className="btn-44 blue" onClick={() => doSetFunc(0)}>
                            {t('team.groupdetail.back_button')}
                        </button>
                    </div>
                </div>
            </div>
        );
    })
);

export default GroupRankEmpty;
