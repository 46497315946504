import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import TeamItem from '../../../Search/components/YourTeams/TeamItem';
import { DialogViewTeam } from '../../../UserTeam/components';
import { toJS } from 'mobx';

const YourTeam = inject(
    'authStore',
    'userStore',
    'teamStore'
)(
    observer((props) => {
        const { userProfile, navigation } = props;

        const [openDialogViewTeam, setOpenDialogViewTeam] = React.useState(false);

        const { currentTeam,userTeam, doGetTeamDetail } = props.teamStore;
        const showJoinBtn = userTeam == null || userTeam.tmid == 0;

        const onViewTeamProfile = (team) => {
            getTeamDetail(team.tmid, 0);
        };

        const handleOpenDialogViewTeam = (flag) => {
            setOpenDialogViewTeam(flag);
        };

        async function getTeamDetail(teamID, action) {
            let data = {
                uid: userProfile.uid,
                tmid: teamID
            };
            let result = await doGetTeamDetail(data);
            if (result) {
                if (action == 0) navigation.push('/team-profile');
                else {
                    handleOpenDialogViewTeam(true);
                    childRef.current.initData();
                }
            }
        }

        useEffect(() => {}, []);
        const childRef = useRef();

        if (userProfile != null)
            return (
                <>
                    <h3 className="title-rank">Đội tham gia</h3>
                    <p className="count-img-user">{userProfile.tmls.length} đội</p>
                    <div className="analytics-team">
                        <div className="member-team">
                            <div className="list-member">
                                {userProfile.tmls &&
                                    userProfile.tmls.map((item) => {
                                        return (
                                            <TeamItem
                                                key={item.tmid}
                                                onViewTeamProfile={() => onViewTeamProfile(item)}
                                                data={toJS(item)}
                                            />
                                        );
                                    })}
                            </div>
                        </div>

                        {currentTeam != null ? (
                            <DialogViewTeam
                                openDialogViewTeam={openDialogViewTeam}
                                pageIndexTeam={0}
                                ref={childRef}
                                onJoinTeamSuccess={props.onJoinTeamSuccess}
                                showJoinTeam={false}
                                onClose={() => handleOpenDialogViewTeam(false)}></DialogViewTeam>
                        ) : null}
                    </div>
                </>
            );
        else return null;
    })
);

export default YourTeam;
