import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import NotJoin from './NotJoin';
import OwnerJoin from './OwnerJoin';
import AdminJoin from './AdminJoin';
import MemberJoin from './MemberJoin';
import { GROUP_ACTION, SPORT_TYPE_ACTIVITY } from '../../../../helpers/ConstDefined';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';
import { getTypeName, getImageResource } from '../../../../utils';
import DialogEditTeam from '../DialogEditTeam';
import DialogLeaveTeam from '../DialogLeaveTeam';
import { useTranslation } from 'react-i18next';

const ProfileHeader = inject(
    'eventStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { currentTeam, doJoinLeaveTeam, doSetJoinTeamStt } = props.teamStore;
        const { userProfile, doPushUserTeamEvent } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [openDialogEditTeam, setOpenDialogEditTeam] = React.useState(false);
        const [openDialogLeaveTeam, setOpenDialogLeaveTeam] = React.useState(false);

        const { t } = useTranslation();

        const handleOpenDialogEditTeam = (flag) => {
            setOpenDialogEditTeam(flag);
        };

        async function joinLeaveTeam() {
            const params = {
                uid: userProfile.uid,
                tmid: currentTeam.tmid,
                action: GROUP_ACTION.JOIN
            };
            let result = await doJoinLeaveTeam(params);
            if (result == true) {
                setMessageSnackbar(t('global.join_team_success'));
                setSnackbarType('success');
                onOpenSnackbar();
                doSetJoinTeamStt(2);
                doPushUserTeamEvent(currentTeam);
                currentTeam.mem += 1;
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        let icon;
        if (currentTeam.sport == SPORT_TYPE_ACTIVITY.RUNNING) icon = 'run';
        else if (currentTeam.sport == SPORT_TYPE_ACTIVITY.SWIMMING) icon = 'swim';
        else if (currentTeam.sport == SPORT_TYPE_ACTIVITY.CYCLING) icon = 'bike';
        else icon = 'trialthon';

        function RenderStatus(props) {
            const { status } = props;
            if (status != 0) {
                return (
                    <p class="joined-status" onClick={() => setOpenDialogLeaveTeam(true)}>
                        <a href="javascript:void(0);">{t('global.group_status.joined')}</a>
                    </p>
                );
            } else
                return (
                    <p class="join-btn" onClick={() => joinLeaveTeam()}>
                        <button class="btn-40 blue">{t('global.join')}</button>
                    </p>
                );
        }

        if (currentTeam != null)
            return (
                <div class="team-top-info">
                    <div class="bn-block">
                        <p class="bn">
                            <img src={getImageResource(currentTeam.cover)} alt="" />
                        </p>
                        <div class="logo">
                            <p class="logo-inside">
                                <img src={getImageResource(currentTeam.ava, true, true)} alt="" />
                            </p>
                        </div>
                    </div>
                    <div class="info-block">
                        <p class="name">{currentTeam.name}</p>
                        <p class={`type ${icon}`}>{t(getTypeName(TEAM_TYPE, currentTeam.lgid))}</p>
                        <p class="level">{currentTeam.desc}</p>
                        <RenderStatus status={currentTeam.jsts} />
                    </div>

                    {currentTeam ? (
                        <DialogLeaveTeam
                            openDialogLeaveTeam={openDialogLeaveTeam}
                            onClose={() => setOpenDialogLeaveTeam(false)}
                            teamData={currentTeam}
                        />
                    ) : null}
                </div>
            );
        else return null;
    })
);
export default ProfileHeader;
