import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { REPORT_ACTION } from '../../../../../helpers/ConstDefined';
import { stripedHtml } from '../../../../../utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

const DialogComplain = inject(
    'globalStore',
    'userStore',
    'reportStore'
)(
    observer((props) => {
        const { openDialogComplain, data, eventID } = props;
        const { userProfile } = props.userStore;
        const { doAction } = props.reportStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [desc, setDesc] = React.useState('');
        const [errDesc, setErrDesc] = useState(false);

        const { t } = useTranslation();

        function validInput() {
            let hasError = false;
            if (!desc || desc.trim().length == 0) {
                setErrDesc(true);
                hasError = true;
            } else setErrDesc(false);

            return hasError;
        }

        async function sendComplain() {
            if (!validInput()) {
                let params = {
                    atid: data.atid,
                    evid: eventID,
                    uid: userProfile.uid,
                    action: REPORT_ACTION.USER_COMPLAIN,
                    reason: desc
                };
                let result = await doAction(params);
                if (result == true) {
                    data.complained = true;
                    setMessageSnackbar(t('global.action.success'));
                    setSnackbarType('success');
                    onOpenSnackbar();
                } else {
                    setMessageSnackbar(result.desc);
                    setSnackbarType('error');
                    onOpenSnackbar();
                }
                props.onClose();
            }
        }

        const onCloseDialog = () => {
            props.onClose();
        };

        return (
            <Dialog open={openDialogComplain}>
                <div
                    class="uprace-popup has-title in"
                    id="complain"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => onCloseDialog()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 class="popup-title">{t('global.complain_act')}</h2>
                            <div class="popup-body">
                                <div class="popup-complain">
                                    <div class="complain-scroll">
                                        <h2 class="complain-title">{t('global.complain_act.title')}</h2>
                                        <p class="complain-txt">{t('global.complain_act.desc')}</p>
                                        <div class="frm-ctn active">
                                            <textarea
                                                name=""
                                                id=""
                                                cols=""
                                                rows=""
                                                maxLength={1500}
                                                value={desc}
                                                onChange={(text) => setDesc(stripedHtml(text.target.value))}
                                                placeholder={t('global.complain_reason')}></textarea>
                                            {errDesc ? <p class="error-tag">{t('global.error.valid_desc')}</p> : null}
                                        </div>
                                    </div>
                                    <div class="complain-btn col-2">
                                        <button class="btn-44 cancel" onClick={() => onCloseDialog()}>
                                            {t('global.destroy')}
                                        </button>
                                        <button class="btn-44 blue" onClick={() => sendComplain()}>
                                            {t('global.send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogComplain;
