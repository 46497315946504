import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody, requestAPIBodyNotAuth } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';
import _ from 'lodash';

export default class InviteFriendStore {
    @observable listMemberTemp = [];
    @observable listMemberAdd = [];
    @observable listMemberRemove = [];
    @observable listMemberInvite = [];
    @observable loading = null;

    @action.bound
    doAddMemberTemp(user) {
        const exist = _.find(this.listMemberTemp, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) this.listMemberTemp.push(user);
        console.log(toJS(this.listMemberTemp));
    }

    @action.bound
    doClearAllMember() {
        this.listMemberTemp = [];
    }

    @action.bound
    doInitMember(list) {
        //if (this.listMemberTemp.length == 0) this.listMemberTemp = _.clone(list);
        if (list && list.length > 0) this.listMemberTemp = _.clone(list);
        else this.listMemberTemp = [];
    }

    @action.bound
    doAddMember(user) {
        let exist = _.find(this.listMemberAdd, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) {
            exist = _.find(this.listMemberInvite, (item) => {
                return item.uid == user.uid;
            });

            if (!exist) {
                this.listMemberAdd.push(user);
                _.remove(this.listMemberRemove, (item) => {
                    return item.uid == user.uid;
                });
            }
        }
    }

    @action.bound
    doRemoveMemberTemp(user) {
        _.remove(this.listMemberTemp, (item) => {
            return item.uid == user.uid;
        });
    }

    @action.bound
    doRemoveMember(user) {
        let exist = _.find(this.listMemberRemove, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) {
            exist = _.find(this.listMemberTemp, (item) => {
                return item.uid == user.uid;
            });

            if (!exist) {
                this.listMemberRemove.push(user);
                _.remove(this.listMemberAdd, (item) => {
                    return item.uid == user.uid;
                });
            }
        }
    }

    @action.bound
    async doInviteMultiFriends(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.TEAM.INVITE_FRIEND, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = null;
                } else result = resp;
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doAddMemberCompare(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.ADD_COMPARE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else result = true;
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doRemoveMemberCompare(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.EVENT.REMOVE_COMPARE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = resp;
                } else result = true;
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }
}
