import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { convertShortDuration } from 'utils';
import { EVENT_TYPE } from 'helpers/ConstDefined';
import { replaceURLs } from 'utils';
import $ from 'jquery';

const RuleEvent = inject('eventStore')(
    observer((props) => {
        const { eventData } = props;

        if (eventData.evtp == EVENT_TYPE.SUPPER_CHALLENGE) return null;

        var key = Object.keys(eventData.evrl)[0];

        const [evrl, setEvrl] = useState(eventData.evrl && eventData.evrl[key]);

        const showHeightRule =
            (evrl.hasOwnProperty('spm0') && evrl.spm0 != -1) ||
            (evrl.hasOwnProperty('spm1') && evrl.spm1 != -1) ||
            (evrl.hasOwnProperty('bmp0') && evrl.bmp0 != -1) ||
            (evrl.hasOwnProperty('bmp1') && evrl.bmp1 != -1);

        const { t } = useTranslation();

        useEffect(() => {
            $('.action-show-hide').click(showHide);
            showMore();
        }, [eventData]);
        // show hide
        function showHide() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        }

        function showMore() {
            const eventDetail = $('.event-detail-page .rules-text .txt');
            if (eventDetail.length && eventDetail[0].scrollHeight <= eventDetail[0].offsetHeight) {
                eventDetail
                    .parent()
                    .find('.see-more')
                    .hide();
            }
        }

        return (
            <div class="rules">
                {evrl ? (
                    <div class="rules-item">
                        <div class="rules-title">{t('ue.act_valid_criteria')}</div>
                        <div class="rules-card-group">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon distance-min"></div>
                                        <div class="lb">{t('ue.min_distince')}</div>
                                        <div class="txt">
                                            {evrl.dis0 && evrl.dis0 != -1 ? `${evrl.dis0} km` : t('eu.not_limit')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon distance-max"></div>
                                        <div class="lb">{t('ue.max_distince')}</div>
                                        <div class="txt">
                                            {evrl.dis1 && evrl.dis1 != -1 ? `${evrl.dis1} km` : t('eu.not_limit')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon speed-min"></div>
                                        <div class="lb">{t('ue.min_speed')}</div>
                                        <div class="txt">
                                            {evrl.mvtm0 && evrl.mvtm0 != -1
                                                ? `${convertShortDuration(evrl.mvtm0)} /km`
                                                : t('eu.not_limit')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon speed-max"></div>
                                        <div class="lb">{t('ue.max_speed')}</div>
                                        <div class="txt">
                                            {evrl.mvtm1 && evrl.mvtm1 != -1
                                                ? `${convertShortDuration(evrl.mvtm1)} /km`
                                                : t('eu.not_limit')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showHeightRule ? (
                                <>
                                    <div class="col">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="icon cadence"></div>
                                                <div class="lb">{t('global.spm')}</div>
                                                <div class="txt">
                                                    {evrl.spm0 && evrl.spm0 != -1
                                                        ? `${evrl.spm0} - ${evrl.spm1}spm`
                                                        : t('eu.not_limit')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="icon heartbeat"></div>
                                                <div class="lb">{t('eu.heartbeat')}</div>
                                                <div class="txt">
                                                    {evrl.bmp0 && evrl.bmp0 != -1
                                                        ? `${evrl.bmp0} - ${evrl.bmp1}spm`
                                                        : t('eu.not_limit')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                </>
                            ) : null}
                        </div>
                        <p class="rules-text--sm">
                            <span class="text-red">*</span>
                            <span>{t('eu.rule_record_time')}</span>
                        </p>
                    </div>
                ) : null}
                <div class="rules-item">
                    <div class="rules-title">{t('ue.event_desc')}</div>
                    <div class="rules-text mt-3">
                        <a href="javascript:void(0);" class="see-more action-show-hide"></a>
                        {eventData.desc ? (
                            <div
                                class="txt"
                                dangerouslySetInnerHTML={{
                                    __html: replaceURLs(eventData.desc).replace(/\n/g, '<br />')
                                }}></div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    })
);

export default RuleEvent;
