import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import UserDonate from '../SocialOrganizationUser/UserDonate';
import {
    UserInfoWrapper,
    UserInfoContainer,
    AvatarUser,
    UserName,
    UserTeam,
    CardDistance,
    CardDayActive
} from './UserInfo.styles';

const UserInfo = inject(
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);

        const { currentEvent, doUpdateFund, doSetEventFund } = props.eventStore;
        const { userProfile } = props.userStore;

        const { t } = useTranslation();

        return (
            <UserInfoWrapper>
                <UserInfoContainer>
                    <Col xl={4} lg={6} sm={24} md={24} xs={24}>
                        <AvatarUser></AvatarUser>
                    </Col>
                    <Col xl={10} lg={18} sm={24} md={24} xs={24}>
                        <Row>
                            <Col>
                                <UserName>John</UserName>
                                <UserTeam>VNG Runner</UserTeam>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CardDistance></CardDistance>
                            </Col>
                            <Col>
                                <Row>
                                    <CardDayActive />
                                </Row>
                                <Row>
                                    <CardDayActive />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={10} lg={24} sm={24} md={24} xs={24}>
                        <UserDonate />
                    </Col>
                </UserInfoContainer>
            </UserInfoWrapper>
        );
    })
);

export default UserInfo;
