import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import { SOCIAL_TAB } from '../../../../../helpers/ConstDefined';
import SocialList from './SocialList';

const DialogSocial = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        let { openDialogSocial, data, tab, type } = props;
        const [tabLocal, setTabLocal] = React.useState(tab);

        useEffect(() => {}, []);

        const handleChangeTab = (val) => {
            setTabLocal(val);
        };

        const Tabs = [
            { id: SOCIAL_TAB.LIKE, icon: 'ico like', val: data != null ? data.lkcnt : 0 },
            { id: SOCIAL_TAB.COMMENT, icon: 'ico comment', val: data != null ? data.cmcnt : 0 }
        ];

        function MenuItem(props) {
            const { data } = props;
            const cssClass = data.id == tabLocal ? 'active' : '';

            return (
                <li class={cssClass} onClick={() => handleChangeTab(data.id)}>
                    <a href="javascript:void(0);">
                        <span class={data.icon}>
                            <span class="num">{data.val}</span>
                        </span>
                    </a>
                </li>
            );
        }

        if (data != null)
            return (
                <Dialog open={openDialogSocial}>
                    <div
                        class="uprace-popup has-title in"
                        id="like-list"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        style={{ display: 'block' }}>
                        <div class="popup-dialog" role="document">
                            <div class="popup-content w600">
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onClose()}>
                                    <span aria-hidden="true"></span>
                                </button>
                                <div class="popup-body">
                                    <div class="post-action-popup">
                                        <div class="action-tabs-menu">
                                            <ul>
                                                {Tabs.map((item) => {
                                                    return <MenuItem data={item} key={item.id} />;
                                                })}
                                            </ul>
                                        </div>
                                        <div class="tabs-content action-tabs-content">
                                            <ul>
                                                <SocialList tabType={tabLocal} type={type} data={data} />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            );
        else return null;
    })
);

export default DialogSocial;
