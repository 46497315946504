import { observable, action } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { STATS_TYPE, EVENT_STATUS_TYPE } from '../helpers/ConstDefined';
import { log } from '../utils';
import { toJS } from 'mobx';
export default class EventStore {
    @observable eventStats;
    @observable eventLeagueStats;
    @observable eventTeamStats;
    @observable eventGroupStats;
    @observable eventUserTeamStats;
    @observable eventUserStats;
    @observable eventListDone;
    @observable eventListYet;
    @observable eventListReady;
    @observable currentEvent;
    @observable listSO = null; // danh sach tổ chức xã hội của event
    @observable listDonate = null; //list donate
    @observable totalDonate = 0;

    @observable eventTabSportType = 0;

    @observable totalEventDone;
    @observable totalEventYet;
    @observable totalEventReady;

    @action.bound
    doHiddenTabEvent(isDone) {
        if (isDone) {
            this.totalEventReady = 0;
            this.totalEventYet = 0;
        } else this.totalEventDone = 0;
    }

    @action.bound
    doSetListSO(data) {
        this.listSO = data;
    }

    @action.bound
    doDeleteCurrentEvent() {
        this.currentEvent = null;
    }

    @action.bound
    doSetEventFund(id) {
        if (this.currentEvent) this.currentEvent.fuid = id;
    }

    @action.bound
    doSetEventTabSportType(id) {
        this.eventTabSportType = id;
    }

    @action.bound
    doSetGroupRequestID(val) {
        this.currentEvent.rgrid = val;
    }

    @action.bound
    doSetTeamJoinEvent(tmid) {
        this.currentEvent.tmid = tmid;
    }

    @action.bound
    doSetGroupIDEvent(val) {
        this.currentEvent.grid = val;
    }

    @action.bound
    async doGetEventData(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.EVENT.GET_DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.currentEvent = resp.data;
                    console.log('111', resp.data);
                    console.log('1111', this.currentEvent);
                    this.doGetListSOEvent(resp.data);
                    localStorage.setItem(KEY.CURRENT_EVENT, resp.data);
                    this.loading = false;
                    result = resp.data;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    doGetListSOEvent(event) {
        let list = [];
        {
            _.each(event.fund, (val, key) => {
                list.push(val);
            });
        }

        list = _.sortBy(list, (item) => {
            return item.donate;
        }).reverse();

        this.doSetListSO(list);
    }

    @action.bound
    async doUpdateFund(params) {
        this.loading = true; //show progress
        let result = null;

        await requestAPIV2(API.EVENT.UPDATE_FUND, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = resp.data;
                    this.currentEvent.fuid = params.fuid;

                    if (this.listSO) {
                        const obj = _.find(this.listSO, (item) => {
                            return item.fuid == params.fuid;
                        });
                        if (obj) obj.donate += resp.data.donate;
                    }
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doGetListEvent(params) {
        this.loading = true; //show progress
        let result = null;

        await requestAPIV2(API.EVENT.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    if (params.sts == EVENT_STATUS_TYPE.DONE) {
                        this.eventListDone = resp.data.list;
                        this.totalEventDone = resp.data.total;
                    } else if (params.sts == EVENT_STATUS_TYPE.READY) {
                        this.eventListReady = resp.data.list;
                        this.totalEventReady = resp.data.total;
                    }
                    if (params.sts == EVENT_STATUS_TYPE.YET) {
                        this.eventListYet = resp.data.list;
                        this.totalEventYet = resp.data.total;
                    }

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doGetEventUserTeamStats(params) {
        this.loading = true; //show progress
        let result = null;
        this.listLeaderboard = [];
        await requestAPIV2(API.EVENT.STATISTICS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.eventUserTeamStats = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doGetListDonate(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.EVENT.LIST_DONATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.listDonate = resp.data.funds;
                    this.totalDonate = resp.data.totalBudget;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doGetEventStats(params, type) {
        this.loading = true; //show progress
        let result = null;
        this.listLeaderboard = [];
        await requestAPIV2(API.EVENT.STATISTICS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    if (type == STATS_TYPE.LEAGUE) this.eventLeagueStats = resp.data;
                    else if (type == STATS_TYPE.TEAM) this.eventTeamStats = resp.data;
                    else if (type == STATS_TYPE.USER) this.eventUserStats = resp.data;
                    else if (type == STATS_TYPE.EVENT) this.eventStats = resp.data;
                    else if (type == STATS_TYPE.GROUP) this.eventGroupStats = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loading = false;
                result = null;
            });
        return result;
    }
}
