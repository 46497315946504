import React from 'react';
import { inject, observer } from 'mobx-react';
import { getTypeName, formatNumber, getImageResource } from '../../../../utils';
import { EVENT_LOCK_STATUS } from '../../../../helpers/ConstDefined';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TeamItem = inject(
    'authStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;
        const { userProfile } = props.userStore;
        const { userTeam } = props.teamStore;

        const { t, i18n } = useTranslation();

        const ava = data.ava.replace('.png', '@1x.png').replace('.jpeg', '@1x.jpeg');

        const onViewDetail = () => {
            props.onViewDetail(data);
        };

        function renderButton() {
            if (userProfile != null) {
                if (userTeam && userTeam.lsts == EVENT_LOCK_STATUS.MUTED) return null;
                else
                    return (
                        <p className="btn-block" onClick={() => props.onSelect()}>
                            <button className="btn-40 blue">{t('global.join')}</button>
                        </p>
                    );
            } else {
                if (userTeam && userTeam.lsts == EVENT_LOCK_STATUS.MUTED) return null;
                else
                    return (
                        <p className="btn-block" onClick={() => props.onSelect()}>
                            <button className="btn-40 blue">{t('global.join')}</button>
                        </p>
                    );
            }
        }

        return (
            <li data-toggle="modal">
                <a href="javascript:void(0);">
                    <p className="logo" onClick={() => onViewDetail()}>
                        <img src={getImageResource(data.ava, true)} alt="" />
                    </p>
                    <p className="name" onClick={() => onViewDetail()}>
                        {data.name}
                    </p>
                    <p className="type" onClick={() => onViewDetail()}>
                        {t(getTypeName(TEAM_TYPE, data.lgid))}
                    </p>
                    <div className="vdv" onClick={() => onViewDetail()}>
                        <p className="num">
                            <strong>{formatNumber(data.mem)}</strong> {t('global.member2')}
                        </p>
                    </div>
                    {renderButton()}
                </a>
            </li>
        );
    })
);

export default TeamItem;
