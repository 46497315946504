import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { formatCurrency } from '../../../../../utils';
import {
    UserDonateWrapper,
    UserDonateContainer,
    LogoContainer,
    LogoBox,
    LogoCompany,
    TotalMoney,
    TextMessage,
    DonateTooltip,
    TooltipContentWrapper,
    TooltipMoneyWrapper,
    TooltipTitle,
    TooltipNumber,
    TooltipText
} from './UserDonate.styles';
import './UserDonate.css';

const UserDonate = inject(
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { data } = props;
        const { t } = useTranslation();

        const {
            currentEvent,
            listDonate,
            totalDonate,
            doUpdateFund,
            doSetEventFund,
            doGetListDonate
        } = props.eventStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            onGetListDonate();
        }, [currentEvent]);

        const tooltipContent = (data) => {
            return (
                <TooltipContentWrapper>
                    <TooltipTitle>{data.fund}</TooltipTitle>
                    <TooltipMoneyWrapper>
                        <TooltipNumber>{formatCurrency(data.budget)}</TooltipNumber>
                        <TooltipText>vnđ</TooltipText>
                    </TooltipMoneyWrapper>
                </TooltipContentWrapper>
            );
        };

        const onGetListDonate = () => {
            const params = {
                uid: userProfile.uid,
                evid: currentEvent.evid
            };
            doGetListDonate(params);
        };

        const onCloseDialog = () => {
            props.onClose();
        };

        if (listDonate)
            return (
                <UserDonateWrapper>
                    <UserDonateContainer>
                        <LogoContainer>
                            {listDonate.map((item) => {
                                return (
                                    <DonateTooltip
                                        placement="bottom"
                                        trigger="click"
                                        content={tooltipContent(item)}
                                        title="Title">
                                        <LogoBox>
                                            <LogoCompany src={item.img}></LogoCompany>
                                        </LogoBox>
                                    </DonateTooltip>
                                );
                            })}
                        </LogoContainer>
                        <TotalMoney>
                            {formatCurrency(totalDonate)}
                            <i style={{ textDecoration: 'underline', fontSize: 16 }}>đ</i>
                        </TotalMoney>
                        <TextMessage>{t('global.donate_total_msg')}</TextMessage>
                    </UserDonateContainer>
                </UserDonateWrapper>
            );
        else return null;
    })
);

export default UserDonate;
