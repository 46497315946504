import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import { Modal } from 'react-bootstrap';
import { stripedHtml, stripedHtmlNumber, getUUID, getTimestamp, convertTimestamptoDate } from '../../../../utils/index';
import { GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import { toJS } from 'mobx';
const numeral = require('numeral');

const DialogCreateChallenge = inject(
    'authStore',
    'userStore',
    'challengeStore',
    'globalStore',
    'teamStore',
    'eventStore'
)(
    observer((props) => {
        const { challengeDetail, openDialogCreateChallenge, eventID } = props;
        const { doCreateChallenge, doUpdateChallenge } = props.challengeStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { userProfile } = props.userStore;
        const { doUpdateTeamTarget } = props.teamStore;
        const { currentEvent } = props.eventStore;

        const [distance, setDistance] = React.useState(challengeDetail != null ? challengeDetail.cdis / 1000 : '');
        const [dayActive, setDayActive] = React.useState(challengeDetail != null ? challengeDetail.cdays : '');
        const [longestDistance, setLongestDistance] = React.useState(
            challengeDetail != null ? challengeDetail.cxdis / 1000 : ''
        );
        const [desc, setDescription] = React.useState(challengeDetail != null ? challengeDetail.desc : '');

        const limitDay = eventID == 2 ? 24 : 15;

        useEffect(() => {}, []);

        async function createChallenge() {
            if (distance == 0 || dayActive == 0 || longestDistance == 0 || desc.length == 0) return;
            let data = {
                uid: userProfile.uid,
                evid: eventID,
                cdis: distance * 1000,
                cxdis: longestDistance * 1000,
                cdays: dayActive,
                desc
            };
            let result = await doCreateChallenge(data);
            if (result != null) {
                setMessageSnackbar('Tạo thử thách cá nhân thành công');
                setSnackbarType('success');
                doUpdateTeamTarget(result);
                onOpenSnackbar();
                props.onClose();
            } else {
                setMessageSnackbar('Tạo thử thách thất bại');
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        async function updateChallenge() {
            if (distance == 0 || dayActive == 0 || longestDistance == 0 || desc.length == 0) return;
            let data = {
                tgid: challengeDetail.tgid,
                uid: userProfile.uid,
                evid: eventID,
                cdis: distance * 1000,
                cxdis: longestDistance * 1000,
                cdays: dayActive,
                desc
            };
            let result = await doUpdateChallenge(data);
            if (result) {
                setMessageSnackbar('Cập nhật thử thách thành công');
                setSnackbarType('success');
                onOpenSnackbar();
                props.onClose();
            } else {
                setMessageSnackbar('Cập nhật thử thách thất bại');
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function actionChallenge() {
            if (challengeDetail != null) updateChallenge();
            else createChallenge();
        }

        const handleChangeDistance = (val) => {
            if(val < 0 || val > 2000) return;
            setDistance(val);
        };

        const handleChangeDayActive = (val) => {
            if(val < 0 || val > limitDay) return;
            setDayActive(val);
        };

        const handleChangeLongestDistance = (val) => {
            if(val < 0 || val > 100) return;
            setLongestDistance(val);
        };

        const handleChangeDescription = (val) => {
            if(val.length > 360) return;
            setDescription(val);
        }

        const cssButton =
            distance > 0 && dayActive > 0 && longestDistance > 0 && desc.length > 0
                ? 'btn custom-btn active'
                : 'btn custom-btn';

        return (
            <Modal show={openDialogCreateChallenge} onHide={props.onClose}>
                <div role="dialog" aria-modal="true" className="show">
                    <div
                        className="custom-modal modal-create-challenge modal-confirm-join-team"
                        role="dialog"
                        aria-hidden="true">
                        <div className="custom-modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title">Tạo thử thách cá nhân</h3>
                                    <button
                                        onClick={props.onClose}
                                        type="button"
                                        className="close custom-close"
                                        data-dismiss="modal"
                                        aria-label="Close">
                                        <img src="../../../img/icon-close-2.png" alt="image" />
                                    </button>
                                </div>
                                <form action="">
                                    <div className="modal-body content">
                                        <div className="banner-modal-create">
                                            <img
                                                src="../../../img/banner-create-challenge.png"
                                                style={{ opacity: 0 }}
                                                alt="Banner Create Challenge"
                                            />
                                        </div>
                                        <div className="wrapper-data">
                                            <div className="input-group mb-3 custom-input-group">
                                                <div className="custom-input-prepend">
                                                    <span className="input-group-text custom-input-text" id="distance">
                                                        Tổng quãng đường (km)
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control custom-input-form"
                                                    placeholder="0"
                                                    value={distance}
                                                    onChange={(text) =>
                                                        handleChangeDistance(stripedHtmlNumber(text.target.value))
                                                    }
                                                    id="distance-title"
                                                    aria-describedby="distance"
                                                />
                                            </div>
                                            <div className="input-group mb-3 custom-input-group">
                                                <div className="custom-input-prepend">
                                                    <span
                                                        className="input-group-text custom-input-text"
                                                        id="day-activate">
                                                        Tổng số ngày chạy/ ngày sự kiện
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control pull-right custom-input-form"
                                                    placeholder="0"
                                                    value={dayActive}
                                                    onChange={(text) =>
                                                        handleChangeDayActive(stripedHtmlNumber(text.target.value))
                                                    }
                                                    id="title-date-activate"
                                                    aria-describedby="day-activate"
                                                />
                                            </div>
                                            <div className="input-group mb-3 custom-input-group">
                                                <div className="custom-input-prepend">
                                                    <span
                                                        className="input-group-text custom-input-text"
                                                        id="longest-distance">
                                                        Quãng đường dài nhất trong một lần chạy (km)
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control custom-input-form"
                                                    placeholder="0"
                                                    value={longestDistance}
                                                    onChange={(text) =>
                                                        handleChangeLongestDistance(stripedHtmlNumber(text.target.value))
                                                    }
                                                    id="title-longest-distance"
                                                    aria-describedby="longest-distance"
                                                />
                                            </div>
                                            <div className="form-group custom-text-aria-group">
                                                <label
                                                    className="label-text-aria"
                                                    htmlFor="exampleFormControlTextarea1">
                                                    Hãy nêu cam kết của bạn ở đây
                                                </label>
                                                <textarea
                                                    placeholder="VD: Nếu tôi không hoàn thành thử thách cá nhân của mình, tôi sẽ đóng góp 500K cho quỹ Newborns Vietnam"
                                                    className="form-control text-aria-input"
                                                    value={desc}
                                                    onChange={(text) => handleChangeDescription(stripedHtml(text.target.value))}
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>
                                            <div className="warning-create-challenge">
                                                <div className="row">
                                                    <div className="col-2 img-warning">
                                                        <img
                                                            className="warning-img"
                                                            src="../../../img/icon-warning.svg"
                                                            alt="Warning"
                                                        />
                                                    </div>
                                                    <div className="col-10 text-warning">
                                                        <p className="warning-text">
                                                            Thông tin sẽ không được thay đổi khi bắt đầu sự kiện
                                                            &nbsp;{currentEvent.name} ({convertTimestamptoDate(currentEvent.betm)} - {convertTimestamptoDate(currentEvent.entm)})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-footer modal-footer-custom">
                                        <div className="form-control wrapper-btn custom-form-group">
                                            <button
                                                type="button"
                                                className={cssButton}
                                                onClick={() => actionChallenge()}>
                                                {challengeDetail != null ? 'Cập nhật thử thách' : 'Tạo thử thách'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogCreateChallenge;
