import React, { useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { STATISTICS_TYPE } from '../../../../helpers/ConstDefined';
import { formatNumber, formatDistanceKM, formatDate, convertShortDurationText } from '../../../../utils';
import { STATS_TYPE } from '../../../../data/master';
import WeeklyComponent from './WeeklyComponent';
import { useTranslation } from 'react-i18next';

const TeamStats = inject(
    'authStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { teamID } = props;
        const { teamStatisticsInfo, doGetStatisticsTeam } = props.teamStore;
        const { userProfile } = props.userStore;

        const [openWeekly, setOpenWeekly] = React.useState(false);

        useEffect(() => {
            if (userProfile) getStatisticsTeam(null, STATISTICS_TYPE.WEEK);
        }, [userProfile]);

        const { t } = useTranslation();

        const handleChangeDate = (date, type) => {
            let value;
            if (type == STATISTICS_TYPE.MONTH) {
                value = formatDate('YYYYMM', date);
                setTextDuration(formatDate('MMM-YYYY', date));
            } else {
                value = formatDate('YYYY', date);
                setTextDuration(formatDate('YYYY', date));
            }
            getStatisticsTeam(value, type);
        };
        const [reportType, setReportType] = React.useState(0);
        const [textDuration, setTextDuration] = React.useState('');

        const CustomDateMonthly = forwardRef(({ onClick }, props) => (
            <div class="date">
                <a href="javascript:void(0);" onClick={onClick}>
                    <span class="txt">{t('global.pre_month')}</span>
                </a>
            </div>
        ));

        const CustomDateYearly = forwardRef(({ onClick }, props) => (
            <div class="date">
                <a href="javascript:void(0);" onClick={onClick}>
                    <span class="txt">{t('global.pre_year')}</span>
                </a>
            </div>
        ));

        const onChangeNavigationTab = (category) => {
            setReportType(category.id);
            getStatisticsTeam(null, category.id);
            if (category.id == STATISTICS_TYPE.WEEK) setTextDuration(t('global.pre_week'));
            else if (category.id == STATISTICS_TYPE.MONTH) setTextDuration(t('global.pre_month'));
            else setTextDuration(t('global.pre_year'));
        };

        function getStatisticsTeam(value, type) {
            const params = {
                uid: userProfile && userProfile.uid,
                tmid: teamID,
                term: type,
                value
            };
            doGetStatisticsTeam(params);
        }

        function MenuItem(props) {
            const { data } = props;
            let cssClass;
            cssClass = data.id == reportType ? 'active' : '';

            return (
                <li class={cssClass}>
                    <a href="javascript:void(0);" onClick={() => onChangeNavigationTab(data)}>
                        <span class="txt">{t(data.title)}</span>
                    </a>
                </li>
            );
        }

        const onSelectWeek = (week, text) => {
            setTextDuration(text);
            getStatisticsTeam(week);
        };

        const showWeeklyComponent = () => {
            setOpenWeekly(!openWeekly);
        };

        function RenderRange() {
            if (reportType == STATISTICS_TYPE.WEEK) {
                if (textDuration.length == 0) setTextDuration(t('global.pre_week'));
                return (
                    <>
                        <li class="col col2">{t('global.cur_week')}</li>
                        <li class="col col3 uiselector">
                            <div class="date" onClick={() => showWeeklyComponent()}>
                                <a href="javascript:void(0);">
                                    <span class="txt">{textDuration}</span>
                                </a>
                            </div>
                            <WeeklyComponent
                                open={openWeekly}
                                onSelectWeek={onSelectWeek}
                                onClose={() => setOpenWeekly(false)}
                            />
                        </li>
                    </>
                );
            } else if (reportType == STATISTICS_TYPE.MONTH) {
                if (textDuration.length == 0) setTextDuration(t('global.pre_month'));
                return (
                    <>
                        <li class="col col2">{t('global.cur_month')}</li>
                        <li class="col col3 uiselector">
                            <DatePicker
                                //selected={startDate}
                                onChange={(date) => handleChangeDate(date, STATISTICS_TYPE.MONTH)}
                                dateFormat="yyyyMM"
                                showMonthYearPicker
                                customInput={<CustomDateMonthly />}
                            />
                        </li>
                    </>
                );
            } else if (reportType == STATISTICS_TYPE.YEAR)
                return (
                    <>
                        <li class="col col2">{t('global.cur_year')}</li>
                        <li class="col col3 uiselector">
                            <DatePicker
                                //selected={startDate}
                                onChange={(date) => handleChangeDate(date, STATISTICS_TYPE.YEAR)}
                                dateFormat="yyyy"
                                showYearPicker
                                customInput={<CustomDateYearly />}
                            />
                        </li>
                    </>
                );
        }

        if (teamStatisticsInfo != null)
            return (
                <div class="profile-wbox statistical">
                    <h2 class="profile-title">{t('global.stats')}</h2>
                    <div class="profile-tabs-menu">
                        <ul>
                            {STATS_TYPE.map((item) => {
                                return <MenuItem data={item} key={item.id} />;
                            })}
                        </ul>
                    </div>
                    <div class="tabs-content profile-tabs-content">
                        <ul>
                            <li class="active">
                                <div class="profile-statistical">
                                    <ul class="title-row">
                                        <li class="col col1">{t('global.info')}</li>
                                        <RenderRange />
                                    </ul>
                                    <ul class="list">
                                        <li>
                                            <div class="col col1">{t('global.distince_km')}</div>
                                            <div class="col col2">{formatDistanceKM(teamStatisticsInfo.cur.dis)}</div>
                                            <div class="col col3">{formatDistanceKM(teamStatisticsInfo.other.dis)}</div>
                                        </li>
                                        <li>
                                            <div class="col col1">{t('global.time_act')}</div>
                                            <div class="col col2">
                                                {convertShortDurationText(teamStatisticsInfo.cur.dur)}
                                            </div>
                                            <div class="col col3">
                                                {convertShortDurationText(teamStatisticsInfo.other.dur)}
                                            </div>
                                        </li>
                                        <li>
                                            <div class="col col1">{t('global.number_act')}</div>
                                            <div class="col col2">{formatNumber(teamStatisticsInfo.cur.act)}</div>
                                            <div class="col col3">{formatNumber(teamStatisticsInfo.other.act)}</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            );
        else return null;
    })
);
export default TeamStats;
