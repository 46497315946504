import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_STATUS_DATA, SORT_MEMBER_DATA, EVENT_MODE_DATA, EVENT_OFFICAL_DATA } from 'data/master';
import { useTranslation } from 'react-i18next';
import {
    EVENT_STATUS_TYPE,
    EVENT_HOME_TYPE,
    SORT_VALUE_TYPE,
    EVENT_MEMBER_SORT_TYPE,
    EVENT_TYPE
} from 'helpers/ConstDefined';
import $ from 'jquery';

const FilterEvent = inject(
    'authStore',
    'userEventStore',
    'userStore'
)(
    observer((props) => {
        const {
            tabEventHome,
            filterName,
            filterOrder,
            filterEventType,
            filterEventStatus,
            filterEventOffical,

            doSetEventStatusFilter,
            doGetListEventFilter,
            doSetTabEventHome,
            doResetFilterEvent,
            doSetFilterOrderEvent,
            doSetEventTypeFilter,
            doSetEventOfficalFilter
        } = props.userEventStore;
        const { userProfile } = props.userStore;

        useEffect(() => {});

        const { t } = useTranslation();

        function RadioItemEventStatus(props) {
            const { item } = props;
            return (
                <div class="col" onClick={() => doSetEventStatusFilter(item.id)}>
                    <input
                        type="radio"
                        class="btn-check"
                        name="options-time"
                        id={`r_${item.id}`}
                        autocomplete="off"
                        checked={filterEventStatus === item.id}
                    />
                    <label class="btn btn-outline-secondary" for="r1">
                        {t(item.title)}
                    </label>
                </div>
            );
        }

        function RadioItemSortMember(props) {
            const { item } = props;
            return (
                <div class="col" onClick={() => doSetFilterOrderEvent(item.id)}>
                    <input
                        type="radio"
                        class="btn-check"
                        name="options-number-join"
                        id={`r_${item.id}`}
                        autocomplete="off"
                        checked={filterOrder === item.id}
                    />
                    <label class="btn btn-outline-secondary" for="r1">
                        {t(item.title)}
                    </label>
                </div>
            );
        }

        function RadioItemEventMode(props) {
            const { item } = props;
            if (item.id == EVENT_TYPE.SUPPER_CHALLENGE) return null;
            return (
                <div class="col" onClick={() => setEventTypeFilter(item.id)}>
                    <input
                        type="radio"
                        class="btn-check"
                        name="options-type"
                        id={`r_${item.id}`}
                        autocomplete="off"
                        disabled={item.id == EVENT_TYPE.VITURL_RACE}
                        checked={filterEventType === item.id}
                    />
                    <label class="btn btn-outline-secondary" for="r1">
                        {t(item.title)}
                    </label>
                </div>
            );
        }

        function RadioItemEventOffical(props) {
            const { item } = props;
            return (
                <div class="col" onClick={() => doSetEventOfficalFilter(item.id)}>
                    <input
                        type="radio"
                        class="btn-check"
                        name="options-event"
                        id={`r_${item.id}`}
                        autocomplete="off"
                        checked={filterEventOffical === item.id}
                    />
                    <label class="btn btn-outline-secondary" for="r1">
                        {t(item.title)}
                    </label>
                </div>
            );
        }

        const setEventTypeFilter = (id) => {
            if (id == EVENT_TYPE.VITURL_RACE) return;
            doSetEventTypeFilter(id);
        };

        const getListFilterEvent = () => {
            const params = {
                uid: userProfile && userProfile.uid,
                name: filterName,
                sts: filterEventStatus,
                sort: EVENT_MEMBER_SORT_TYPE.MEMBER,
                order: filterOrder,
                evtp: filterEventType,
                offical: filterEventOffical
            };
            doGetListEventFilter(params);
        };

        const onApplyFilter = () => {
            if (tabEventHome != EVENT_HOME_TYPE.ALL_EVENT) doSetTabEventHome(EVENT_HOME_TYPE.ALL_EVENT);
            else getListFilterEvent();
        };

        const onCancelFilter = () => {
            //reset filter
            doSetEventStatusFilter(global.EVENT_STATUS_RESET ? global.EVENT_STATUS_RESET : EVENT_STATUS_TYPE.ALL);
            doResetFilterEvent();
            if (tabEventHome == EVENT_HOME_TYPE.HOME_EVENT) {
            } else {
                const params = {
                    uid: userProfile && userProfile.uid,
                    sort: EVENT_MEMBER_SORT_TYPE.MEMBER,
                    order: SORT_VALUE_TYPE.DESC
                };
                doGetListEventFilter(params);
            }
        };

        return (
            <section id="filterEvent" class="offcanvas right-bar">
                <div class="right-bar-header">
                    <h4 class="right-bar-header-title">{t('ue.filter')}</h4>
                    <button type="button" class="btn-close" data-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="right-bar-body">
                    <div class="filter-group">
                        <div class="filter-group-item">
                            <h5 class="filter-group-item-title">{t('global.time')}</h5>
                            <div class="filter-group-item-body">
                                {EVENT_STATUS_DATA.map((item) => {
                                    return <RadioItemEventStatus item={item} key={item.id} />;
                                })}
                            </div>
                        </div>
                        <div class="filter-group-item">
                            <h5 class="filter-group-item-title">{t('eu.quantity')}</h5>
                            <div class="filter-group-item-body">
                                {SORT_MEMBER_DATA.map((item) => {
                                    return <RadioItemSortMember item={item} key={item.id} />;
                                })}
                            </div>
                        </div>
                        <div class="filter-group-item">
                            <h5 class="filter-group-item-title">{t('eu.form')}</h5>
                            <div class="filter-group-item-body">
                                {EVENT_MODE_DATA.map((item) => {
                                    return <RadioItemEventMode item={item} key={item.id} />;
                                })}
                            </div>
                        </div>
                        <div class="filter-group-item">
                            <h5 class="filter-group-item-title">{t('eu.event_type')}</h5>
                            <div class="filter-group-item-body">
                                {EVENT_OFFICAL_DATA.map((item) => {
                                    return <RadioItemEventOffical item={item} key={item.id} />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-bar-footer">
                    <div class="action">
                        <button
                            class="btn-40 btn-outline-blue action-cancel"
                            data-dismiss="offcanvas"
                            onClick={() => onCancelFilter()}>
                            {t('eu.cancel_filter')}
                        </button>
                        <button
                            class="btn-40 btn-blue action-apply"
                            data-dismiss="offcanvas"
                            onClick={() => onApplyFilter()}>
                            {t('eu.apply')}
                        </button>
                    </div>
                </div>
            </section>
        );
    })
);

export default FilterEvent;
