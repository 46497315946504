import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';

const DialogConfirmLeave = inject(
    'authStore',
    'userStore',
    'globalStore',
    'userLeaderboardStore'
)(
    observer((props) => {
        const { data, openDialogConfirmLeave } = props;
        const { userProfile, doRemoveUserLeaderboard } = props.userStore;
        const { loading, doLeaveUserLeaderboard } = props.userLeaderboardStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        async function leaveLeaderboard() {
            let rkid = data.rkid;
            let params = {
                uid: userProfile.uid,
                rkid
            };
            let result = await doLeaveUserLeaderboard(params);
            if (result) {
                doRemoveUserLeaderboard(rkid);
                setMessageSnackbar('Rời bảng xếp hạng thành công');
                setSnackbarType('success');
            } else {
                setMessageSnackbar('Rời bảng xếp hạng thất bại.');
                setSnackbarType('error');
            }
            onOpenSnackbar();
            props.onClose();
        }

        return (
            <Dialog
                open={openDialogConfirmLeave}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="custom-modal  modal-confirm-join-team" role="dialog" aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Rời bảng xếp hạng</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src="../../../img/icon-close-2.png" alt="image" onClick={props.onClose} />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Bạn có chắc chắn muốn rời khỏi bảng xếp hạng: {data.name} ?</p>
                            </div>

                            <div className="modal-footer">
                                <div className="wrapper-btn custom-wrapper-btn">
                                    <a type="button" className="btn btn-outline-primary" onClick={props.onClose}>
                                        Bỏ qua
                                    </a>
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="btn btn-primary"
                                        onClick={() => leaveLeaderboard()}>
                                        Rời bảng
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogConfirmLeave;
