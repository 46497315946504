import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml, stripedHtmlNumber, validEmail, formatDate } from '../../utils/index';

const ContactUs = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const { doUserFeedback } = props.globalStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();
        const [tab, setTab] = useState(1);

        const [name, setName] = useState('');
        const [phone, setPhone] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');

        //valid error
        const [errEmail, setErrEmail] = useState(false);
        const [errName, setErrName] = useState(false);
        const [errPhone, setErrPhone] = useState(false);
        const [errMess, setErrMess] = useState(false);

        function validInput() {
            let hasError = false;
            if (!validEmail(email)) {
                setErrEmail(true);
                hasError = true;
            } else setErrEmail(false);

            if (!name || name.length < 2 || name.length > 50) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (!phone || phone.length < 10) {
                setErrPhone(true);
                hasError = true;
            } else setErrPhone(false);

            if (!message || message.length < 1) {
                setErrMess(true);
                hasError = true;
            } else setErrMess(false);

            return hasError;
        }

        async function onSendInfo() {
            if (!validInput()) {
                const params = {
                    name,
                    email,
                    phone,
                    content: message
                };
                const result = await doUserFeedback(params);
                if (result) {
                    setMessageSnackbar(t('landing_page.join.feedback_succ'));
                    setSnackbarType('success');
                    clearInput();
                } else {
                    setMessageSnackbar(t('landing_page.join.feedback_fail'));
                    setSnackbarType('error');
                }
                onOpenSnackbar();
            }
        }

        const handleChangeName = (val) => {
            if (val && val.length > 50) return;
            setName(val);
        };
        const handleChangeEmail = (val) => {
            if (val &&  val.length > 50) return;
            setEmail(val);
        };
        const handleChangePhone = (val) => {
            if (val &&  val.length > 20) return;
            setPhone(val);
        };
        const handleChangeMessage = (val) => {
            if (val &&  val.length > 250) return;
            setMessage(val);
        };

        function clearInput() {
            setName('');
            setPhone('');
            setEmail('');
            setMessage('');
        }

        return (
            <section className="section section--wrapper ">
                <div className="container">
                    <h1 className="space-60 text-center">{t('landing_page.contact.title')}</h1>

                    <div className="flex contact contact--small justify-content-space-between space-40">
                        <a className="flex column align-item-center" href="mailto:sponsor@UpRace.org">
                            <img className="lazyload space-10" src="images/icons/gm.png" alt="" />
                            <p
                                className="text-center color-black-gray bold"
                                dangerouslySetInnerHTML={{
                                    __html: t('landing_page.join.tab2_content.l1')
                                }}></p>
                        </a>
                        <a
                            className="flex column align-item-center"
                            href="https://www.facebook.com/UpRace.org"
                            target="_blank">
                            <img className="lazyload space-10" src="images/icons/fb.png" alt="" />
                            <p
                                className="text-center color-black-gray bold"
                                dangerouslySetInnerHTML={{
                                    __html: t('landing_page.join.tab2_content.l2')
                                }}></p>
                        </a>
                    </div>
                    <div className="form">
                        <p
                            className="space-50 bold color-black-gray"
                            dangerouslySetInnerHTML={{ __html: t('landing_page.join.tab2_content.l3') }}></p>
                        <label htmlFor="name">{t('landing_page.join.input.name')}:</label>
                        <br />
                        <input
                            type="text"
                            id="name"
                            name="name"
                            maxLength={60}
                            value={name}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        {errName ? <p className="color-red">{t('global.error.valid_name')}</p> : null}
                        <br />
                        <label htmlFor="email">E-mail:</label>
                        <br />
                        <input
                            type="text"
                            id="email"
                            name="email"
                            maxLength={50}
                            value={email}
                            onChange={(text) => handleChangeEmail(stripedHtml(text.target.value))}
                        />
                        {errEmail ? <p className="color-red">{t('global.error.valid_email')}</p> : null}
                        <br />
                        <label htmlFor="phonenumber">{t('landing_page.join.input.phone')}</label>
                        <br />
                        <input
                            type="text"
                            id="phonenumber"
                            name="phonenumber"
                            maxLength={20}
                            value={phone}
                            onChange={(text) => handleChangePhone(stripedHtml(text.target.value))}
                        />
                        {errPhone ? <p className="color-red">{t('global.error.valid_phone')}</p> : null}
                        <br />
                        <label htmlFor="message">{t('landing_page.join.input.mess')}:</label>
                        <br />
                        <textarea
                            type="text"
                            id="message"
                            name="message"
                            maxLength={1500}
                            value={message}
                            onChange={(text) => handleChangeMessage(stripedHtml(text.target.value))}></textarea>
                            {errMess ? <p className="color-red">{t('global.error.valid_mess')}</p> : null}
                        <br />
                        <input
                            className="btn-submit"
                            type="submit"
                            value={t('global.send')}
                            onClick={() => onSendInfo()}
                        />
                    </div>
                    <div></div>
                </div>
            </section>
        );
    })
);

export default ContactUs;
