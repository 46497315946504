import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DialogDeleteActivity = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, openDialogDeleteActivity, type } = props;
        const { userProfile, doDeleteActivity } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        async function deleteActivity() {
            const params = {
                uid: userProfile.uid,
                atid: data.atid
            };
            let result = await doDeleteActivity(params, type);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                onOpenSnackbar();
                props.onClose();
            } else {
                if (result.code == 5015) {
                    setMessageSnackbar(t('global.delete_act_event'));
                } else setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
                props.onClose();
            }
        }

        return (
            <Dialog open={openDialogDeleteActivity}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={props.onClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <p className="name">{t('global.delete_act.title')}</p>

                                    <div className="txt">
                                        <p>{t('global.delete_act.desc')}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={() => deleteActivity()}>
                                            {t('global.delete_act.title')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogDeleteActivity;
