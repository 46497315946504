import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimestamptoDate, convertTimeFromSecond, getImageResource } from '../../../../utils';

const CommentItem = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);
        
        const imgGender = null; //data != null && data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';

        if (data != null)
            return (
                <li>
                    <div
                        className="row wrapper-profile-user friend-profile-modal"
                        style={{ marginLeft: 0, marginRight: 0, paddingTop: 10 }}>
                        <div className="col-2 profile-user-custom">
                            <div className="avatar-profile" style={{ backgroundImage: `url(${getImageResource(data.ava,true,true)})` }}>
                                <img className="avatar" src="../../../img/avatar.png" alt="" />
                                {imgGender ? <img className="gender" src={imgGender} alt="" /> : null}
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="comment-user-modal custom-information-user">
                                <span className="name-user">{data.name}</span>
                                <p className="groups-user">{data.tmnm || ''}</p>
                                <p>{data.cmt}</p>
                            </div>
                            <div className="time-post">
                                <p>{convertTimestamptoDate(data.cmtm)}</p>
                            </div>
                        </div>
                    </div>
                </li>
            );
        else return null;
    })
);

export default CommentItem;
