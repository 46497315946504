import React, { forwardRef, useImperativeHandle } from 'react';
import { inject, observer } from 'mobx-react';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';
import { Modal } from 'react-bootstrap';
import MemberItem from './MemberItem';
import { STATS_TYPE, LEADERBOARD_TYPE, GENDER_TYPE, GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import { toJS } from 'mobx';
import { formatDistanceKM, stripedHtml, formatNumber, getImageResource } from '../../../../utils';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import { KEY } from '../../../../helpers/api/APIDefined';

const MENU = [
    { id: GENDER_TYPE.ALL, title: 'Tất cả' },
    { id: GENDER_TYPE.MALE, title: 'Nam' },
    { id: GENDER_TYPE.FEMALE, title: 'Nữ' }
];

const typeGender = Object.keys(MENU).map((key) => ({ value: MENU[key].id, label: MENU[key].title }));

let eventID;

const DialogViewTeam = inject(
    'authStore',
    'teamStore',
    'eventStore',
    'leaderboardStore',
    'globalStore',
    'userStore',
    'userProfileStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { totalRecord, listLeaderboard, doGetListLeaderboard } = props.leaderboardStore;
            const { openDialogViewTeam, showJoinTeam, navigation } = props;
            const { currentTeam, doRequestUpdateTeam } = props.teamStore;
            const { eventTeamStats, doGetEventStats } = props.eventStore;
            const { appVersion } = props.globalStore;
            const { userProfile } = props.userStore;
            const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
            const { doSetProfileUserID } = props.userProfileStore;

            //filter params
            const [name, setName] = React.useState('');
            const [typeSex, setTypeSex] = React.useState(0); //type all
            const [hasMoreMember, setHasMoreMember] = React.useState(true);
            let [pageMember, setPageMember] = React.useState(0);
            const showClearName = name && name.length > 0;
            const [showHeader, setShowHeader] = React.useState(true);
            const [cssScroll, setCSSScroll] = React.useState('table-responsive custom-table custom-table-list-member');
            
            useImperativeHandle(ref, () => ({
                initData(id) {
                    setName('');
                    eventID = id;
                    getLeaderboard(currentTeam.tmid);
                    getTeamEventStats(currentTeam.tmid);
                }
            }));

            const getTypeName = (arr, id) => {
                var obj = _.find(arr, (item) => {
                    return item.id == id;
                });
                if (obj) return obj.name;
                else return null;
            };

            const handleChangeName = (val) => {
                setName(val);
            };

            async function requestJoinTeam() {
                let data = {
                    tmid: currentTeam.tmid,
                    evid: eventID,
                    uid: userProfile.uid
                };
                let result = doRequestUpdateTeam(data);
                if (result) {
                    props.onJoinTeamSuccess();
                } else {
                    setMessageSnackbar('Tham gia đội thất bại');
                    setSnackbarType('error');
                    onOpenSnackbar();
                }
            }

            function onLoadMoreMember() {
                let data = {
                    from: pageMember * appVersion.pgsz,
                    size: appVersion.pgsz,
                    evid: eventID,
                    type: LEADERBOARD_TYPE.TEAM, // 0: league, 1: team, 2: fund
                    sex: typeSex,
                    name,
                    value: currentTeam.tmid
                };
                doGetListLeaderboard(data, true);
            }

            function getLeaderboard(teamID) {
                let data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.TEAM, // 0: league, 1: team, 2: fund
                    sex: GENDER_TYPE.ALL,
                    value: teamID
                };
                doGetListLeaderboard(data);
            }

            function getTeamEventStats(teamID) {
                let data = {
                    evid: eventID,
                    type: STATS_TYPE.TEAM, // 0: event: 1: league, 2: team, 2: fund
                    value: teamID
                };
                doGetEventStats(data, STATS_TYPE.TEAM);
            }

            function searchMember() {
                setPageMember(0); //reset page index when search
                const data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.TEAM,
                    sex: typeSex,
                    name,
                    value: currentTeam.tmid
                };
                doGetListLeaderboard(data);
            }

            const handleKeyDown = (e) => {
                if (e.key === 'Enter') {
                    searchMember();
                }
            };

            function handleResetSearch() {
                setPageMember(0); //reset page index when search
                setName('');
                const data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.TEAM,
                    sex: typeSex,
                    value: currentTeam.tmid
                };
                doGetListLeaderboard(data);
            }

            const fetchMoreMember = () => {
                if (listLeaderboard.length >= totalRecord) {
                    setHasMoreMember(false);
                    return;
                }
                setPageMember((pageMember += 1));
                onLoadMoreMember();
            };

            const onCloseDialog = () => {
                //reset page and hash more
                setTypeSex(0);
                setPageMember(0);
                setHasMoreMember(true);
                props.onClose();
            };

            const handleTeamTypeGender = (selectedOption) => {
                setTypeSex(selectedOption.value);
                setPageMember(0); //reset page index when search
                const data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.TEAM,
                    sex: selectedOption.value,
                    name,
                    value: currentTeam.tmid
                };
                doGetListLeaderboard(data);
            };

            const handleViewProfileUser = (item) => {
                sessionStorage.setItem(KEY.ID_PROFILE_USER, item.id);
                doSetProfileUserID(item.id);
                navigation.push('/user-profile');
            };

            const onScroll = (e) => {
                if(e.target.scrollTop == 0)
                {
                    setShowHeader(true);
                    setCSSScroll('table-responsive custom-table custom-table-list-member');
                }
                else {
                    setShowHeader(false);
                    setCSSScroll('table-responsive custom-table custom-table-list-member custom-scroll-modal');
                }
            };

            if (currentTeam != null)
                return (
                    <Modal show={openDialogViewTeam} onHide={onCloseDialog}>
                        <div
                            className="custom-modal-complex custom-modal-sticky modal-join-team"
                            role="dialog"
                            aria-hidden="true">
                            <div className="custom-modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="wrapper-banner">
                                            <div
                                                className="banner"
                                                style={{
                                                    backgroundImage: `url(${getImageResource(currentTeam.cover)})`
                                                }}>
                                                <img src="../../../img/banner/banner002.png" alt="" />
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={props.onClose}>
                                            <img src="../../../img/icon-close-2.png" alt="image" />
                                        </button>
                                    </div>

                                    <div className="modal-body">
                                        {showHeader ?
                                        <div className="wrapper-content">
                                            <div className="intro">
                                                <div
                                                    className="profile-img rectangle-avatar"
                                                    style={{
                                                        backgroundImage: `url(${getImageResource(
                                                            currentTeam.ava,
                                                            true
                                                        )})`
                                                    }}>
                                                    <img src="../../../img/avatar.png" alt="image" />
                                                </div>
                                                <h3 className="title">{currentTeam.name}</h3>
                                                <p className="team-type">
                                                    {getTypeName(TEAM_TYPE, currentTeam.lgid)} -{' '}
                                                    {getTypeName(SPORT_TYPE, currentTeam.sport)}
                                                </p>
                                                {userProfile != null ? (
                                                    <Link to={'/team-profile?evid=' + eventID} className="btn btn-primary see-info">
                                                        Xem thông tin đội <i className="fa fa-chevron-right next"></i>
                                                    </Link>
                                                ) : (
                                                    <Link to="/login" className="btn btn-primary see-info">
                                                        Xem thông tin đội <i className="fa fa-chevron-right next"></i>
                                                    </Link>
                                                )}
                                            </div>

                                            {eventTeamStats != null ? (
                                                <div className="wrapper-achievements">
                                                    <div className="achievement">
                                                        <img
                                                            src="../../../img/icon-member.png"
                                                            className="image-icon"
                                                            alt="image"
                                                        />
                                                        <p className="number">{formatNumber(eventTeamStats.mem)}</p>
                                                        <p className="description">Vận động viên</p>
                                                    </div>

                                                    <div className="achievement">
                                                        <img
                                                            src="../../../img/icon-km.svg"
                                                            className="image-icon"
                                                            alt="image"
                                                        />
                                                        <p className="number">
                                                            {formatDistanceKM(eventTeamStats.dis, true)} Km
                                                        </p>
                                                        <p className="description">Số Km đạt được</p>
                                                    </div>

                                                    <div className="achievement">
                                                        <img
                                                            src="../../../img/icon-rank.svg"
                                                            className="image-icon"
                                                            alt="image"
                                                        />
                                                        <p className="number">{formatNumber(eventTeamStats.rank)}</p>
                                                        <p className="description">Xếp hạng</p>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="clearfix">
                                                <div className="pull-left">
                                                    <h3 className="title">BXH trong đội</h3>
                                                </div>
                                                <div className="pull-right">
                                                    <Select
                                                        options={typeGender}
                                                        defaultValue={typeGender[0]}
                                                        onChange={handleTeamTypeGender}
                                                    />
                                                </div>
                                            </div>

                                            <div className="wrapper-search">
                                                <div className="form-search-custom" id="search-form">
                                                    <div className="input-group input-group-custom">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <img src="../../../img/icon-search.png" alt="" />
                                                            </span>
                                                        </div>
                                                        <input
                                                            id="search"
                                                            type="text"
                                                            className="form-control form-search"
                                                            value={name}
                                                            onKeyDown={handleKeyDown}
                                                            onChange={(text) =>
                                                                handleChangeName(stripedHtml(text.target.value))
                                                            }
                                                            placeholder="Tìm vận động viên"
                                                        />
                                                        {showClearName ? (
                                                            <img
                                                                src="../../../img/register/close.svg"
                                                                className="close-text"
                                                                onClick={() => handleResetSearch()}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <button
                                                        className="btn btn-primary btn-search btn-search-custom"
                                                        onClick={() => searchMember()}>
                                                        Tìm kiếm
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : null}

                                        <div className={cssScroll}>
                                            {listLeaderboard != null ? (
                                                <InfiniteScroll
                                                    dataLength={listLeaderboard.length}
                                                    next={fetchMoreMember}
                                                    hasMore={hasMoreMember}
                                                    //onScroll={onScroll}
                                                    style={{ overflowX: 'hidden' }}
                                                    height={200}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <p>Hạng</p>
                                                                </th>
                                                                <th></th>
                                                                <th>
                                                                    <p>Tên VĐV</p>
                                                                </th>
                                                                <th>
                                                                    <p>Km</p>
                                                                </th>
                                                                <th>
                                                                    <p>Tổng (Km)</p>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {listLeaderboard != null &&
                                                                listLeaderboard.map((item) => (
                                                                    <MemberItem
                                                                        onViewProfileUser={() =>
                                                                            handleViewProfileUser(item)
                                                                        }
                                                                        key={item.id}
                                                                        data={toJS(item)}></MemberItem>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                    {listLeaderboard && listLeaderboard.length == 0 ? (
                                                        <p style={{ textAlign: 'center' }}>
                                                            Không tìm thấy VĐV theo yêu cầu
                                                        </p>
                                                    ) : null}
                                                </InfiniteScroll>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        {showJoinTeam ? (
                                            <div className="wrapper-btn">
                                                {userProfile == null ? (
                                                    <Link to="/login">
                                                        <button type="button" className="btn btn-danger btn-join-team">
                                                            Tham gia đội
                                                        </button>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => requestJoinTeam()}
                                                        className="btn btn-danger btn-join-team">
                                                        Tham gia đội
                                                    </button>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            else return null;
        })
    )
);

export default DialogViewTeam;
