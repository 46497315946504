import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { GLOBAL_CONFIG, COMMON_ERROR } from '../../../../helpers/ConstDefined';
import { TEAM_MSG } from '../../../../helpers/MsgDefined';
import { TEAM_TYPE } from '../../../../data/master';
import { getTypeName, getImageResource } from '../../../../utils';

const DialogChangeTeam = inject(
    'globalStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { openDialogChangeTeam, teamData, msg, eventID } = props;
        const { doRequestUpdateTeam } = props.teamStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        function onChangeTeam() {
            requestUpdateTeam();
        }

        async function requestUpdateTeam() {
            let data = {
                tmid: 0,
                evid: eventID,
                uid: userProfile.uid
            };
            let result = await doRequestUpdateTeam(data);
            if(result == COMMON_ERROR.EVENT.EVENT_READY) {
                props.showLeaveTeamResult();
                props.onClose();
            }
            else if(result == COMMON_ERROR.EVENT.EVENT_FINISHED) {
                props.showLeaveTeamResultEventFinish();
                props.onClose();
            }
            else if (result) {
                props.onChangeTeamSuccess();
                props.onClose();
            } else {
                setMessageSnackbar(TEAM_MSG.CHANGE_TEAM_FAIL);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        return (
            <Modal show={openDialogChangeTeam} onHide={props.onClose}>
                <div className="custom-modal modal-confirm-join-team" role="dialog" aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Bạn muốn chuyển đội</h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <img src="../../../img/icon-close-2.png" alt="image" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="wrapper-content">
                                    <div
                                        className="wrapper-profile-user-2"
                                        style={{ backgroundImage: `url(${getImageResource(teamData.ava,true)})` }}>
                                        <img src={teamData.ava} alt="image" />
                                    </div>
                                    <p className="team-title">{teamData.name}</p>
                                    <p className="team-description">{getTypeName(TEAM_TYPE, teamData.lgid)}</p>

                                    <p>{teamData.desc}</p>
                                </div>

                                <p className="txt-red">{msg}</p>
                            </div>

                            <div className="modal-footer">
                                <div className="wrapper-btn">
                                    <button type="button" className="btn btn-outline-primary" onClick={() => onChangeTeam()}>
                                        Chuyển đội
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={props.onClose}>
                                        Ở lại đội
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogChangeTeam;
