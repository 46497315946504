import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from 'utils';
import { USER_EVENT_STATUS } from '../../../../helpers/ConstDefined';

const LockUserDialog = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore',
    'teamManageStore'
)(
    observer((props) => {
        let { userLock, eventID } = props;
        const { openDialogLockUser } = props;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doLockUser } = props.teamManageStore;

        const { t } = useTranslation();

        async function lockUser() {
            const params = {
                uid: userProfile.uid,
                pfid: userLock.uid,
                evid: eventID,
                lsts: USER_EVENT_STATUS.LOCK
            };
            let result = await doLockUser(params);
            if (result) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            props.onClose();
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogLockUser}>
                <div
                    class="uprace-popup uprace-popup--v2"
                    id="recovery-admin"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content">
                            <div class="popup-header">
                                <h4 class="popup-header__title">{t('eu.lock_user')}</h4>
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onClose()}>
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="popup-body flex-column text-center">
                                <div class="avatar">
                                    <img src={getImageResource(userLock.ava, true)} class="avatar-item" alt="avater" />
                                </div>
                                <div class="txt-title">
                                    {t('eu.lock_user')} {userLock.name}
                                </div>
                                <div class="txt">{t('eu.lock_user_ph')}</div>
                            </div>
                            <div class="popup-footer justify-content-end">
                                <div class="action">
                                    <button
                                        type="button"
                                        class="btn btn-outline-blue"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => lockUser()}>
                                        {t('global.lock')}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-blue"
                                        data-dismiss="modal"
                                        onClick={() => props.onClose()}>
                                        {t('global.seaagain')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default LockUserDialog;
