import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { calcLapsChart } from '../../../../utils/paceCalc';
import { convertShortDuration, formatDistanceKM, avgPace } from '../../../../utils';
import { useTranslation } from 'react-i18next';

const ChartPace = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { feedDetail } = props.userStore;
        const [arrLaps, setArrLaps] = React.useState([]);

        const { t } = useTranslation();
        useEffect(() => {
            setArrLaps(calcLapsChart(feedDetail.laps));
        }, []);

        const imgMap = feedDetail.img.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg');

        function addDefaultSrc(e) {
            e.target.src = '../../../img/feed_default.png';
        }

        if (feedDetail != null)
            return (
                <div class="speed-map">
                    <div class="speed-box">
                        <ul class="title">
                            <li>Km</li>
                            <li>{t('global.veloc')}</li>
                        </ul>
                        <ul class="list">
                            {arrLaps &&
                                arrLaps.map((item) => (
                                    <li>
                                        <div class="km">
                                            {item.index == arrLaps.length
                                                ? formatDistanceKM(item.distance)
                                                : item.index}
                                        </div>
                                        <div class="speed">
                                            <p class="time">{convertShortDuration(item.pace, true)}</p>
                                            <p class="long">
                                                <span class="percent" style={{ width: `${item.percent}%` }}></span>
                                            </p>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div class="map-box">
                        <img
                            src={imgMap}
                            onError={addDefaultSrc}
                            style={{ display: 'block', width: '100%' }}
                        />
                    </div>
                </div>
            );
        else return null;
    })
);

export default ChartPace;
