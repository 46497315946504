import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { stripedHtml, isJWTValid } from 'utils';
import { LeftMenu, DeviceConnect, ChangePass, ProfileInfo, Permission } from './components';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const Settings = inject(
    'authStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { userProfile } = props.userStore;
        const { settingTab, doSetTabMainTop } = props.globalStore;

        const { t } = useTranslation();
        
        useEffect(() => {
            doSetTabMainTop(null);
            if (!isJWTValid()) props.history.push('/login');
        }, []);

        let paramsQuery = queryString.parse(props.location.search);
        //if (_.isEmpty(paramsQuery) || !paramsQuery.evid) return <Redirect to="/"></Redirect>;
        
        let eventID = stripedHtml(paramsQuery.evid);

        function RenderCenter() {
            if (settingTab == 0) return <ChangePass navigation={props.history} />;
            else if (settingTab == 1) return <ProfileInfo />;
            else if (settingTab == 2) return <Permission />;
        }

        if (userProfile != null)
            return (
                <section class="profile-page yours">
                    <div class="profile-ctn">
                        <div class="profile-wrap">
                            <div class="profile-left">
                                <RenderCenter />
                            </div>
                            <div class="profile-right">
                                <LeftMenu />
                                <DeviceConnect eventID={eventID} />
                            </div>
                        </div>
                    </div>
                </section>
            );
        else return null;
    })
);

export default Settings;
