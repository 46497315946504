import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import TabHeader from './TabHeader';
import { EVENT_STATUS_TYPE } from 'helpers/ConstDefined';
import EventItem from '../EventItem';
import { useTranslation } from 'react-i18next';
import CreateEventChoiseTypeDialog from '../CreateEventChoiseTypeDialog';

const YourEvent = inject(
    'userStore',
    'eventStore',
    'userEventStore'
)(
    observer((props) => {
        const { navigation } = props;
        const { userProfile } = props.userStore;
        const {
            loading,
            eventListDone,
            totalEventDone,
            totalEventYet,
            totalEventReady,
            eventListYet,
            eventListReady,
            doGetListEvent,
            doHiddenTabEvent
        } = props.eventStore;

        const { t, i18n } = useTranslation();
        const [tab, setTab] = React.useState(1);
        const [openDialogCreateEventChoiseType, setOpenDialogCreateEventChoiseType] = React.useState(false);

        useEffect(() => {
            getListEvent(tab, EVENT_STATUS_TYPE.READY);
            getListEvent(tab, EVENT_STATUS_TYPE.YET);
            doHiddenTabEvent(false);
        }, []);

        const onChangeTab = (tab) => {
            setTab(tab);
            if (tab == 4) {
                getListEvent(tab, EVENT_STATUS_TYPE.DONE);
                doHiddenTabEvent(true);
            } else {
                getListEvent(tab, EVENT_STATUS_TYPE.READY);
                getListEvent(tab, EVENT_STATUS_TYPE.YET);
                doHiddenTabEvent(false);
            }
        };

        const getListEvent = (tab, sts) => {
            let params = {
                uid: userProfile && userProfile.uid,
                metab: tab,
                sts,
                pfid: userProfile.uid
            };
            doGetListEvent(params);
        };

        const EmptyEventOwner = () => {
            return (
                <div class="users-event-box box--empty">
                    <div class="thumbnail">
                        <img src="../../images/imgs/self_event_empty.png" alt="Thumbnail" class="thumbnail-item" />
                    </div>
                    <div class="txt-title">{t('eu.not_create_event')}</div>
                    <div class="txt" style={{ display: 'none' }}>
                        {t('eu.not_create_event_ph')}
                    </div>
                    <button
                        class="btn-40 btn-blue btn-add"
                        style={{ display: 'none' }}
                        onClick={() => setOpenDialogCreateEventChoiseType(true)}>
                        {t('ue.create_event')}
                    </button>
                </div>
            );
        };

        return (
            <div class="users-event--tabs-content">
                <ul>
                    <li class="active">
                        <TabHeader tab={tab} onChangeTab={onChangeTab} />

                        {tab == 2 && totalEventReady == 0 && totalEventYet == 0 ? <EmptyEventOwner /> : null}

                        {totalEventReady > 0 ? (
                            <div class="users-event-box">
                                <div class="users-event-box-header">
                                    <h3 class="title">
                                        {' '}
                                        {t('ue.ready')} ({totalEventReady})
                                    </h3>
                                </div>

                                <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                                    {eventListReady &&
                                        eventListReady.map((item) => {
                                            return <EventItem data={item} key={item.evid} navigation={navigation} />;
                                        })}
                                </div>
                            </div>
                        ) : null}

                        {totalEventYet > 0 ? (
                            <div class="users-event-box">
                                <div class="users-event-box-header">
                                    <h3 class="title">
                                        {t('ue.soon')} ({totalEventYet})
                                    </h3>
                                </div>

                                <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                                    {eventListYet &&
                                        eventListYet.map((item) => {
                                            return <EventItem data={item} key={item.evid} navigation={navigation} />;
                                        })}
                                </div>
                            </div>
                        ) : null}

                        {totalEventDone > 0 ? (
                            <div class="users-event-box">
                                <div class="users-event-box-header">
                                    <h3 class="title">
                                        {t('ue.finished')} ({totalEventDone})
                                    </h3>
                                </div>

                                <div class="row row-cols-1 row-cols-tablet-2 row-cols-desktop-4 g-3">
                                    {eventListDone &&
                                        eventListDone.map((item) => {
                                            return (
                                                <EventItem
                                                    data={item}
                                                    key={item.evid}
                                                    finished={true}
                                                    navigation={navigation}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        ) : null}
                    </li>
                </ul>

                {openDialogCreateEventChoiseType ? (
                    <CreateEventChoiseTypeDialog
                        navigation={navigation}
                        openDialogCreateEventChoiseType={openDialogCreateEventChoiseType}
                        onClose={() => setOpenDialogCreateEventChoiseType(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default YourEvent;
