import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const News = inject('authStore','globalStore')(
    observer((props) => {
        const { newsContent } = props.globalStore;

        useEffect(() => {
            window.scrollTo(0,0);
        }, []);

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        return (
            <div className="container">
                <div dangerouslySetInnerHTML={{__html: newsContent}}>
                </div>
                <div className="arrow-back-to-top" onClick={() => scrollTop()}><img src="../../../img/back-to-top.svg" alt="image"/></div>
            </div>
        );
    })
);

export default News;