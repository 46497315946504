'use strict';
import * as moment from 'moment';

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

function ymd(dt) {
    return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
}

function getDateOfWeek(weekDaysArr, type) {
    //0: first , 1 : last
    if (type == 0) {
        var _weekDays = weekDaysArr[1][0];
        var firstDate = new Date(_weekDays[0]);
        var firstDay = new Date(firstDate).getDay();
        var firstDateTmp = '';

        for (var j = 1; j < firstDay; j++) {
            var tomorrow = new Date();
            //tomorrow.setDate(firstDate.getDate() - (firstDay-j));
            var tomorrow = moment(firstDate)
                .add(-(firstDay - j), 'days')
                .toDate();

            //alert(firstDay+'->'+tomorrow)
            firstDateTmp += ymd(tomorrow) + ',';
        }

        var tomorrow = new Date();
        tomorrow.setDate(firstDate.getDate() - 1);

        return (firstDateTmp + _weekDays).split(',');
        //return new Date(firstDate).getDay()+"("+firstDateTmp+_weekDays+") - "+firstDate+" -> "+firstDateTmp;
    } else {
        _weekDays = weekDaysArr[1][weekDaysArr[0].length - 1];
        var _weekLength = _weekDays.length;
        var lastDate = new Date(_weekDays[_weekLength - 1]);
        var firstDateTmp = '';
        var _inc = 1;
        while (_weekLength < 7) {
            var tomorrow = new Date();
            var tomorrow = moment(lastDate)
                .add(_inc, 'days')
                .toDate();
            //tomorrow.setDate(lastDate.getDate() + _inc);
            firstDateTmp += ',' + ymd(tomorrow);

            _weekLength++;
            _inc++;
        }
        //console.log(new Date(lastDate).getDay()+"("+firstDateTmp+_weekDays+") - "+lastDate);
        return (_weekDays + firstDateTmp).split(',');
    }
    //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
}

export function daysInMonth(year, months) {
    var month = 0;
    if (months == 0) {
        month = 0;
    } else {
        month = months - 1;
    }
    var dayInMonth = new Date(year, month, 0).getDate();
    //console.log('Day In Month  ' + dayInMonth);
    var monthList = new Array();
    var weeks = new Array();
    var weekdays = new Array();
    var _weekNo = 0;
    var _weekInc = -1;
    var _tmpDay = '';
    for (var i = 1; i <= dayInMonth; i++) {
        var input = new Date(year, month, i);
        var result = getWeekNumber(input);
        var dayofweek = result[1];
        if (_weekNo != result[1]) {
            _weekInc++;
            _weekNo = result[1];
            weeks.push(dayofweek);
            weekdays.push([]);
        }
        weekdays[_weekInc].push(year + '-' + months + '-' + i);
        //console.log('Day In Month  '+result[1]+"|"+year+'-'+(month+1)+'-'+i);
    }
    monthList.push(weeks);
    monthList.push(weekdays);

    monthList[1][0] = getDateOfWeek(monthList, 0);
    monthList[1][monthList[0].length - 1] = getDateOfWeek(monthList, 1);
    return monthList;
}
