import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber, getImageResource } from '../../../../utils';

const GroupJoinItem = inject('authStore')(
    observer((props) => {
        const { data } = props;

        useEffect(() => {}, []);

        return (
            <div class="list-group-item">
                <div class="manage-card">
                    <div class="avatar">
                        <img src={getImageResource(data.ava, true)} class="avatar-item" alt="Avatar" />
                    </div>
                    <div class="group-info">
                        <div class="group-info__name">{data.name}</div>
                        <div class="group-info__owner">{data.ownm}</div>
                    </div>
                    <div class="btn-group">
                        <div class="btn-group-item">
                            <button class="btn-del" onClick={() => props.onDeleteGroup(data)}></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default GroupJoinItem;
