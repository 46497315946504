import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const EventFinish = inject('authStore')(
    observer((props) => {
        const { t } = useTranslation();

        return (
            <div class="container space-80">
                <h1 class="space-20">{t('global.event_finish')}</h1>
                <div class="event">
                    <img
                        class="lazyload event-thumb"
                        src="../../../img/banner/event2020.png"
                        alt=""
                    />
                    <a class="btn-blue" href="https://uprace2020.uprace.org/" target="_blank">
                        <span>UPRACE 2020</span>
                    </a>
                </div>
            </div>
        );
    })
);

export default EventFinish;
