import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../utils';

const RemoveFriendItem = inject(
    'authStore',
    'inviteFriendStore',
    'userStore',
    'userProfileStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { data } = props;
        const { doRemoveMemberTemp, doRemoveMember } = props.inviteFriendStore;
        const { userProfile, doChangeSelectedStatusUser } = props.userStore;
        const { doChangeSelectedStatusUserFollower } = props.userProfileStore;

        function removeMember() {
            doChangeSelectedStatusUser(data.uid);
            doChangeSelectedStatusUserFollower(data.uid);
            doRemoveMemberTemp(data);
            doRemoveMember(data);
        }

        return (
            <li>
                <button className="del-btn" onClick={() => removeMember()}></button>
                <p className="avatar">
                    <img src={getImageResource(data.ava, true, true)} alt="" />
                </p>
                <div className="details">
                    <p className="name">{data.name}</p>
                    <p className="code">{data.code}</p>
                </div>
            </li>
        );
    })
);

export default RemoveFriendItem;
