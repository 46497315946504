import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PostItem from './PostItem';
import EditPostDialog from 'views/TeamDetails/components/EditPostDialog';
import DeletePostDialog from './DeletePostDialog';
import CreatePostDialog from 'views/TeamDetails/components/CreatePostDialog';
import { useTranslation } from 'react-i18next';
import { DialogSocial } from '../../../YourInfo/components';
import PostEmpty from './PostEmpty';
import NotPermission from './NotPermission';

import {
    JOIN_TEAM_STT,
    SOCIAL_TYPE,
    SOCIAL_TAB,
    CREATE_POST_TYPE,
    EVENT_PUBlIC_TYPE,
    EVENT_JOIN_STATUS
} from '../../../../helpers/ConstDefined';
import { getImageResource } from 'utils';
import { EVENT_MODE_DATA } from 'data/master';

let postItem;

const ManagePost = inject(
    'teamManageStore',
    'postStore',
    'userStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;
        const { currentPost, listPost, doGetListPost, doMarkPost } = props.postStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            getListPost();
        }, []);

        const { t } = useTranslation();

        const [autoOpenImgPicker, setAutoOpenImgPicker] = React.useState(false);
        const [openDialogCreatePost, setOpenDialogCreatePost] = React.useState(false);
        const [openDialogDeletePost, setOpenDialogDeletePost] = React.useState(false);
        const [openDialogEditPost, setOpenDialogEditPost] = React.useState(false);
        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE); //default tab like

        const getListPost = () => {
            const params = {
                from: 0,
                feed: 3,
                uid: userProfile && userProfile.uid,
                sort: 7, //get bài post ghim lên đầu
                order: 1,
                evid: eventData.evid
            };
            doGetListPost(params);
        };

        const onOpenDialogDeletePost = (data) => {
            postItem = data;
            setOpenDialogDeletePost(true);
        };

        const onOpenDialogEditPost = (data) => {
            setOpenDialogEditPost(true);
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        async function onMarkPost(data) {
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid,
                action: data.action
            };
            let result = await doMarkPost(params);
            if (result == true) {
                getListPost(); // cập nhật lại list post
                //window.scrollTo(0,0);
            }
        }

        function onEditPost(post) {
            postItem = post;
        }

        function onViewDialogSocial(post, type) {
            postItem = post;
            onOpenDialogSocial(type);
        }

        const onOpenCreatePostAuto = () => {
            setAutoOpenImgPicker(true);
            setOpenDialogCreatePost(true);
        };

        const onOpenCreatePost = () => {
            setAutoOpenImgPicker(false);
            setOpenDialogCreatePost(true);
        };

        function onReloadData() {
            getListPost();
        }

        const showMenu =
            userProfile && (eventData.jsts == JOIN_TEAM_STT.ADMIN || eventData.jsts == JOIN_TEAM_STT.OWNER);

        const isAllowView =
            eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ||
            (eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING);

        let role = null;
        if(userProfile && eventData.owner == userProfile.uid) role = t('global.role_user.owner');
        else if (eventData.jsts == JOIN_TEAM_STT.ADMIN) role = t('global.role_user.admin');

        return (
            <div class="posts-v2">
                {showMenu ? (
                    <div class="posts-v2__header">
                        <div class="post-box">
                            <div class="avatar">
                                <img src={getImageResource(userProfile.ava, true)} class="avatar-item" alt="Avatar" />
                            </div>
                            <div class="desc" onClick={() => onOpenCreatePost()}>
                                <a href="" data-toggle="modal" data-target="#create-post" class="question-create">
                                    {t('eu.create_new_post')}
                                </a>
                            </div>
                        </div>
                        <div class="post-menu">
                            <div class="post-menu-item" onClick={() => onOpenCreatePostAuto()}>
                                <label>{t('eu.fast_select')}</label>
                                <button data-toggle="modal" data-target="#create-post" class="photos">
                                    {t('eu.image')}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}

                {!isAllowView ? <NotPermission /> : null}

                {listPost && listPost.length == 0 && isAllowView ? (
                    <PostEmpty isAdmin={showMenu} eventID={eventData.evid} />
                ) : null}

                <div class="posts-v2__body">
                    <div class="list-group">
                        {listPost &&
                            isAllowView &&
                            listPost.map((item) => {
                                return (
                                    <PostItem
                                        showMenu={showMenu}
                                        data={item}
                                        eventData={eventData}
                                        key={item.psid}
                                        navigation={navigation}
                                        onMarkPost={onMarkPost}
                                        onEditPost={onEditPost}
                                        onViewDialogSocial={onViewDialogSocial}
                                        onOpenDialogDeletePost={onOpenDialogDeletePost}
                                        onOpenDialogEditPost={onOpenDialogEditPost}
                                    />
                                );
                            })}
                    </div>
                </div>

                {currentPost && openDialogEditPost ? (
                    <EditPostDialog
                        data={currentPost}
                        onReload={onReloadData}
                        role={role}
                        openDialogEditPost={openDialogEditPost}
                        onClose={() => setOpenDialogEditPost(false)}
                    />
                ) : null}
                {postItem ? (
                    <DeletePostDialog
                        data={postItem}
                        openDialogDeletePost={openDialogDeletePost}
                        onClose={() => setOpenDialogDeletePost(false)}
                    />
                ) : null}
                {openDialogSocial && postItem ? (
                    <DialogSocial
                        data={postItem}
                        type={SOCIAL_TYPE.POST}
                        tab={tabSocial}
                        openDialogSocial={openDialogSocial}
                        onClose={() => setOpenDialogSocial(false)}></DialogSocial>
                ) : null}
                {openDialogCreatePost ? (
                    <CreatePostDialog
                        eventID={eventData.evid}
                        teamID={0}
                        role={role}
                        autoOpenImgPicker={autoOpenImgPicker}
                        createPostSucc={onReloadData}
                        postType={CREATE_POST_TYPE.EVENT}
                        openDialogCreatePost={openDialogCreatePost}
                        onClose={() => setOpenDialogCreatePost(false)}
                    />
                ) : null}
            </div>
        );
    })
);

export default ManagePost;
