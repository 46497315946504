import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const TabHeader = inject(
    'authStore',
    'groupStore'
)(
    observer((props) => {
        const { totalMemberRqJoin, totalMemberJoined } = props.groupStore;
        const { currentGroup } = props.groupStore;

        const TABS = [
            {
                id: 0,
                title: 'global.group_status.request',
                num: currentGroup.rmem //totalMemberRqJoin
            },
            {
                id: 1,
                title: 'global.group_status.joined',
                num: 0//currentGroup.mem //totalMemberJoined
            }
        ];

        useEffect(() => {}, []);
        const [tab, setTab] = React.useState(0);

        const onChangeTab = (tab) => {
            setTab(tab);
            props.onChangeTab(tab);
        };

        const { t } = useTranslation();

        function TabItem(props) {
            const { data } = props;
            const css = data.id == tab ? 'active' : '';

            return (
                <li className={css} onClick={() => onChangeTab(data.id)}>
                    <a href="javascript:void(0);">
                        <span className="txt">
                            {t(data.title)}
                            {data.num > 0 ? <span className="num">{data.num}</span> : null}
                        </span>
                    </a>
                </li>
            );
        }

        return (
            <div className="g-tabs-menu">
                <ul>
                    {TABS.map((item) => {
                        return <TabItem data={item} key={item.id} />;
                    })}
                </ul>
            </div>
        );
    })
);

export default TabHeader;
