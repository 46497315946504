import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, getImageResource } from '../../../../utils';
import _ from 'lodash';

const TopRank = inject(
    'leaderboardStore',
    'eventStore'
)(
    observer((props) => {
        const { data } = props;
        const { listRankTop } = props.leaderboardStore;

        useEffect(() => {}, []);

        return (
            <div class="tabs-content top-bxh-content">
                <ul>
                    <li class="active">
                        <div class="company-tab bg">
                            {listRankTop != null && listRankTop.length > 0 ? (
                                <div class="item lv1">
                                    <p class="logo">
                                        <img src={getImageResource(listRankTop[0].ava,true)} alt="" />
                                    </p>
                                    <div class="details">
                                        <p class="name">{listRankTop[0].name}</p>
                                        <p class="km">{formatDistanceKM(listRankTop[0].dis)} km</p>
                                    </div>
                                </div>
                            ) : null}
                            {listRankTop != null && listRankTop.length > 1 ? (
                                <div class="item lv2">
                                    <p class="logo">
                                        <img src={getImageResource(listRankTop[1].ava,true)} alt="" />
                                    </p>
                                    <div class="details">
                                        <p class="name">{listRankTop[1].name}</p>
                                        <p class="km">{formatDistanceKM(listRankTop[1].dis)} km</p>
                                    </div>
                                </div>
                            ) : null}
                            {listRankTop != null && listRankTop.length > 2 ? (
                                <div class="item lv3">
                                    <p class="logo">
                                        <img src={getImageResource(listRankTop[2].ava,true)} alt="" />
                                    </p>
                                    <div class="details">
                                        <p class="name">{listRankTop[2].name}</p>
                                        <p class="km">{formatDistanceKM(listRankTop[2].dis)} km</p>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </li>
                </ul>
            </div>
        );
    })
);

export default TopRank;
