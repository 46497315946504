import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ChoiseSODialog from './ChoiseSODialog';
import UserDonate from './UserDonate';
import { formatNumber } from 'utils';
import _ from 'lodash';
import { toJS } from 'mobx';

let soData;

const SocialOrganizationUser = inject(
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;
        const { listSO, currentEvent, eventUserStats, doGetListSOEvent } = props.eventStore;
        const { userProfile } = props.userStore;

        const [openDialogChoiseSO, setOpenDialogChoiseSO] = React.useState(false);
        const [fundUser, setFundUser] = React.useState(false);

        useEffect(() => {
            if (currentEvent) doGetListSOEvent(currentEvent);
        }, [currentEvent]);

        useEffect(() => {
            getFundUserInfo();
        }, [listSO, currentEvent.fuid]);

        const { t } = useTranslation();

        function getFundUserInfo() {
            if (!currentEvent || currentEvent.fuid == 0 || !listSO) return;
            const obj = _.find(listSO, (item) => {
                return item.fuid == currentEvent.fuid;
            });
            setFundUser(obj);
        }

        function RenderItem(props) {
            const { data } = props;
            return (
                <li>
                    <div class="item">
                        <p class="logo">
                            <img src={data.ava} alt="" />
                        </p>
                        <p class="name">{data.name}</p>
                        <button class="btn-32 blue" onClick={() => props.onChoise(data)}>
                            {t('global.select')}
                        </button>
                    </div>
                </li>
            );
        }

        const onChoise = (data) => {
            soData = data; //dư liệu tổ chức xã hội user chọn quyên góp
            setOpenDialogChoiseSO(true);
        };

        function RenderChoise() {
            if (fundUser)
                return (
                    <div class="yours--tcxh-chosen">
                        <p class="logo">
                            <img src={fundUser.ava} alt="" />
                        </p>
                        <p class="money">
                            <span class="num">{formatNumber(eventUserStats && eventUserStats.donate)}</span>
                            <span class="unit">đ</span>
                        </p>
                        <p class="txt">{t('global.money_you_donate')}</p>
                        <p class="name">{fundUser.name}</p>
                    </div>
                );
            else return null;
        }

        function RenderNotChoise() {
            return (
                <div class="yours--tcxh">
                    <h2 class="title">{t('global.not_choise_so')}</h2>
                    <p class="note">{t('global.not_choise_so_desc')}</p>
                    <ul class="list">
                        {listSO &&
                            listSO.map((item) => {
                                return <RenderItem data={item} key={item.fuid} onChoise={onChoise} />;
                            })}
                    </ul>
                    {soData ? (
                        <ChoiseSODialog
                            data={soData}
                            openDialogChoiseSO={openDialogChoiseSO}
                            onClose={() => setOpenDialogChoiseSO(false)}
                        />
                    ) : null}
                </div>
            );
        }

        if (currentEvent && currentEvent.fuid > 0) return <UserDonate />;
        else return <RenderNotChoise />;
    })
);

export default SocialOrganizationUser;
