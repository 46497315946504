import React, { forwardRef, useImperativeHandle } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { LEADERBOARD_TYPE, GENDER_TYPE, GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import { KEY } from '../../../../helpers/api/APIDefined';
import MemberItem from '../../../UserTeam/components/DialogViewTeam/MemberItem';
import { toJS } from 'mobx';
import { formatDistanceKM, formatNumber } from '../../../../utils';
import * as moment from 'moment';

const DialogRankUser = inject(
    'userLeaderboardStore',
    'globalStore',
    'userStore',
    'userProfileStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { openDialogRankUser, navigation, eventID } = props;
            const { userProfile } = props.userStore;
            const { currentUserLeaderboard, listLeaderboard, doGetListLeaderboard } = props.userLeaderboardStore;
            const { doSetProfileUserID } = props.userProfileStore;

            useImperativeHandle(ref, () => ({
                initData() {
                    getLeaderboard(currentUserLeaderboard.rkid);
                }
            }));

            function getLeaderboard(teamID) {
                let data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.CUSTOM, // 0: league, 1: team, 2: fund
                    sex: GENDER_TYPE.ALL,
                    value: teamID
                };
                doGetListLeaderboard(data);
            }

            const handleViewProfileUser = (item) => {
                sessionStorage.setItem(KEY.ID_PROFILE_USER, item.id);
                doSetProfileUserID(item.id);
                navigation.push('/user-profile');
            };

            function getTotalMember() {
                if (!listLeaderboard || listLeaderboard.length == 0) return 0;
                else return listLeaderboard.length;
            }

            function getTotalDistance() {
                if (!listLeaderboard || listLeaderboard.length == 0) return 0;
                let total = 0;
                _.forEach(listLeaderboard, (item) => {
                    total += item.dis;
                });
                return total;
            }

            function onEditUserLeaderboard() {
                props.onEditUserLeaderboard();
                props.onClose();
            }

            function onLeaveUserLeaderboard() {
                props.onLeaveUserLeaderboard();
                props.onClose();
            }

            const onCloseDialog = () => {
                props.onClose();
            };

            return (
                <Modal show={openDialogRankUser} onHide={onCloseDialog}>
                    <div
                        className="custom-modal-complex custom-modal-sticky modal-list-customize-leaderboard"
                        role="dialog"
                        aria-hidden="true">
                        <div className="custom-modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="wrapper-banner">
                                        <div className="banner background-image-002">
                                            <img src="../../../img/banner/banner002.png" alt="image" />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => onCloseDialog()}>
                                        <img src="../../../img/icon-close-2.png" alt="image" />
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="wrapper-content">
                                        <div className="intro">
                                            <h3 className="title">{currentUserLeaderboard.name}</h3>

                                            {currentUserLeaderboard.owner == userProfile.uid ? (
                                                <a
                                                    className="btn btn-primary see-info"
                                                    onClick={() => onEditUserLeaderboard()}>
                                                    Chỉnh sửa <i className="fa fa-chevron-right next" size="10"></i>
                                                </a>
                                            ) : (
                                                <a
                                                    className="btn btn-primary see-info"
                                                    onClick={() => onLeaveUserLeaderboard()}>
                                                    Rời bảng xếp hạng <i className="fa fa-chevron-right next" size="10"></i>
                                                </a>
                                            )}

                                            <div className="row wrapper-achievements">
                                                <div className="col-md-4 achievement">
                                                    <img
                                                        src="../../../img/icon-member.png"
                                                        className="image-icon"
                                                        alt="image"
                                                    />
                                                    <p className="number">{formatNumber(getTotalMember())}</p>
                                                    <p className="description">Vận động viên</p>
                                                </div>

                                                <div className="col-md-4 achievement">
                                                    <img
                                                        src="../../../img/icon-km.svg"
                                                        className="image-icon"
                                                        alt="image"
                                                    />
                                                    <p className="number">{formatDistanceKM(getTotalDistance())}</p>
                                                    <p className="description">Số Km đạt được</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive custom-table custom-table-list-member custom-table-list-team ">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <p>Hạng</p>
                                                    </th>
                                                    <th></th>
                                                    <th>
                                                        <p>Tên VĐV</p>
                                                    </th>
                                                    <th>
                                                        <p>{moment().format('DD/MM')} (Km)</p>
                                                    </th>
                                                    <th>
                                                        <p>Tổng (Km)</p>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {listLeaderboard != null &&
                                                    listLeaderboard.map((item) => (
                                                        <MemberItem
                                                            onViewProfileUser={() => handleViewProfileUser(item)}
                                                            key={item.id}
                                                            data={toJS(item)}></MemberItem>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        })
    )
);

export default DialogRankUser;
