import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ImageItem from './ImageItem';

const ImageList = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { arrayImageFeedReal, doRemoveImageFeed } = props.globalStore;

        const onRemoveImage = (url) => {
            doRemoveImageFeed(url);
        };

        return (
            <div className="imgs-list">
                {arrayImageFeedReal.map((url) => (
                    <ImageItem key={url} removeImage={() => onRemoveImage(url)} imgPath={url} />
                ))}
            </div>
        );
    })
);

export default ImageList;
