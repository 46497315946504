import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKM, formatNumber, getImageResource } from '../../../../utils';
import { EVENT_LOCK_STATUS } from 'helpers/ConstDefined';
import { MemberName } from 'components/MemberName';

const GroupRankItem = inject('authStore')(
    observer((props) => {
        const { data, rank } = props;

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';

        return (
            <li onClick={() => props.onViewUserEvent(data)}>
                <a>
                    <div className="level">{data.rank}</div>
                    <div className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </div>
                    <div className="name-box">
                        <p className="name">{MemberName(data)}</p>
                        {data.diff != 0 ? <p className={cssRankArrow}>{Math.abs(data.diff)}</p> : null}
                    </div>
                    <div className="code">{formatNumber(data.act)}</div>
                    <div className="sum">{formatDistanceKM(data.dis)}</div>
                </a>
            </li>
        );
    })
);

export default GroupRankItem;
