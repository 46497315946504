import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml } from '../../../../utils';
import { LEADERBOARD_TYPE, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';

const HeaderMain = inject(
    'eventStore',
    'groupStore',
    'userStore',
    'teamManageStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { teamID } = props;
        const { userProfile } = props.userStore;
        const { currentEvent } = props.eventStore;
        const { currentGroup, doGetGroupDetail } = props.groupStore;
        const { doSetFunc } = props.teamManageStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        //filter
        const [name, setName] = React.useState('');

        const { t } = useTranslation();

        async function getGroupDetail() {
            const params = {
                uid: userProfile && userProfile.uid,
                grid: currentEvent.rgrid || currentEvent.grid
            };
            let result = await doGetGroupDetail(params);
            if (result) {
                doSetFunc(4); //naviga group detail
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                props.onSearchGroup(name);
            }
        };

        return (
            <>
                {currentEvent && currentEvent.rgrid != 0 ? (
                    <div className="notify-box blue" style={{ display: 'block' }}>
                        {t('team.grouplist.request_join_pending_msg')}.{' '}
                        <a href="#" onClick={() => getGroupDetail()}>
                            {t('team.grouplist.view_group_pending')}
                        </a>
                    </div>
                ) : null}

                {currentEvent.grid != 0 && currentGroup && currentGroup.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                    <div className="notify-box blue" style={{ display: 'block' }}>
                        {t('team.grouplist.create_pending_msg')}.{' '}
                        <a href="#" onClick={() => getGroupDetail()}>
                            {t('team.grouplist.view_group_pending')}
                        </a>
                    </div>
                ) : null}

                <div className="groups-search">
                    <div className="search-box">
                        <button className="del-btn"></button>
                        <input
                            type="text"
                            placeholder={t('global.find_group')}
                            value={name}
                            onKeyDown={handleKeyDown}
                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        />
                        <button className="search-btn" onClick={() => props.onSearchGroup(name)}>
                            {t('global.search')}
                        </button>
                    </div>
                    {currentEvent &&
                    currentEvent.grid == 0 &&
                    currentEvent.rgrid == 0 &&
                    currentEvent.tmid &&
                    currentEvent.tmid > 0 &&
                    currentEvent.tmid == teamID ? (
                        <div className="add-new-group">
                            <button className="btn-40 blue" onClick={props.openDialogCreateGroup}>
                                <span className="txt">{t('global.create_new_group')}</span>
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    })
);

export default HeaderMain;
