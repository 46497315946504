import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const AvatarItem = inject('authStore')(
    observer((props) => {
        const { data, css } = props;
        const avatar = data != null && data.length > 0 ? data.replace('.png', '@1x.png').replace('.jpeg','@1x.jpeg').replace('.gif','@1x.gif') : '../../../img/ic_avatar.png';
        return <img src={avatar} className={css} />;
    })
);

export default AvatarItem;
