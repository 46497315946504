import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { COUNTRY, CITIES } from '../../data/location';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SIZE_CLOTHES } from '../../data/master';
import Select from 'react-select';
import styled from 'styled-components';
import { stripedHtml, stripedHtmlNumber, validEmail, formatDate } from '../../utils/index';
import DatePicker from 'react-datepicker';
import { GLOBAL_CONFIG, LOGIN_TYPE } from '../../helpers/ConstDefined';
import * as moment from 'moment';
import _ from 'lodash';
import DialogViewSize from '../SignUp/DialogViewSize';
import { format, setMonth, getMonth, getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    select2: {}
}));

const countryList = Object.keys(COUNTRY).map((key) => ({ value: COUNTRY[key], label: COUNTRY[key] }));
const cityList = Object.keys(CITIES).map((key) => ({ value: CITIES[key], label: CITIES[key] }));
const sizeList = Object.keys(SIZE_CLOTHES).map((key) => ({ value: SIZE_CLOTHES[key], label: SIZE_CLOTHES[key] }));

const defaultBOD = moment('1990-01-01').toDate();

const customHeader = ({ date, changeMonth, changeYear }) => {
    const months = new Array(12).fill(null).map((_, i) => ({
        value: i,
        label: format(setMonth(new Date(), i), 'MMMM')
    }));

    const years = new Array(100).fill(null).map((_, i) => 2020 - i);

    const handleYearChange = ({ target: { value } }) => changeYear(value);
    const handleMonthChange = ({ target: { value } }) => changeMonth(value);

    return (
        <div>
            <select onChange={handleMonthChange} value={getMonth(date)}>
                {months.map(({ value, label }) => (
                    <option value={value} key={value}>
                        {label}
                    </option>
                ))}
            </select>

            <select onChange={handleYearChange} value={getYear(date)}>
                {years.map((year) => (
                    <option value={year} key={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const UpdateProfile = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const classes = useStyles();
        const { user } = props.authStore;
        const { doUpdateProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [userEmail, setUserEmail] = useState(user != null ? user.emailText : '');
        const [phone, setPhone] = useState(user != null ? user.phone : '');
        const [userPass, setUserPass] = useState(user != null ? user.password : '');
        const [sexIndex, setSexIndex] = useState(user != null ? user.gender : 1);

        const [birthday, setBirthday] = useState(
            user != null && user.birthday != null ? user.birthday : formatDate('YYYY-MM-DD', defaultBOD)
        );
        const [bod, setBOD] = useState(defaultBOD);

        const { t } = useTranslation();
        const GENDER_TYPE = [
            {
                id: 1,
                title: 'global.male'
            },
            {
                id: 2,
                title: 'global.female'
            }
        ];

        const [height, setHeight] = useState(user != null ? user.height : '');
        const [weight, setWeight] = useState(user != null ? user.weight : '');
        const [name, setName] = useState(user != null ? user.name : '');
        const [address, setAddress] = useState(user != null ? user.addr : '');
        const [city, setCity] = useState(user != null ? user.city : 0);
        const [, setCtry] = useState(user != null ? user.ctry : 0);
        const [, setShirt] = useState(user != null ? user.shirt : 0);

        const [agree, setAgree] = useState(true);
        const [showTextCtry, setShowTextCtry] = useState(false);

        //error
        const [, setErrEmail] = useState(false);
        const [errPhone, setErrPhone] = useState(false);
        const [, setErrUserPass] = useState(false);
        const [errHeight, setErrHeight] = useState(false);
        const [errWeight, setErrWeight] = useState(false);
        const [errName, setErrName] = useState(false);
        const [errCity, setErrCity] = useState(false);
        const [errAddress, setErrAddress] = useState(false);
        const [errBOD, setErrBOD] = useState(false);

        const readOnlyEmail = user.emailText && user.emailText.length > 0;

        const isShowPass = user.type != LOGIN_TYPE.GOOGLE && user.type != LOGIN_TYPE.FACEBOOK;

        const [openDialogViewSize, setOpenDialogViewSize] = React.useState(false);

        const handleOpenDialogViewSize = (flag) => {
            setOpenDialogViewSize(flag);
        };

        useEffect(() => {
            if (user.ctry && user.ctry != 'Vietnam') {
                setShowTextCtry(true);
            }
            if (!user.ctry || user.ctry.length == 0) {
                user.ctry = 'Vietnam';
                setCtry(user.ctry);
            }
            if (!user.city || user.city.length == 0) {
                user.city = 'Hà Nội';
                setCity(user.city);
            }
            if (!user.shirt || user.shirt.length == 0) {
                user.shirt = '2XS';
                setShirt(user.shirt);
            }
        }, []);

        function getIndex(arr, val) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value == val) return i;
            }
            return 0;
        }

        function validInput() {
            let hasError = false;
            if (!readOnlyEmail) {
                if (!validEmail(userEmail)) {
                    setErrEmail(true);
                    hasError = true;
                } else setErrEmail(false);
            }

            if (!name || name.length == 0) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (isShowPass) {
                if (!userPass || userPass.length < 6) {
                    setErrUserPass(true);
                    hasError = true;
                } else setErrUserPass(false);
            }

            if (!height || height.length == 0 || height < 90 || height > 250) {
                setErrHeight(true);
                hasError = true;
            } else setErrHeight(false);

            if (!weight || weight.length == 0 || weight < 20 || weight > 200) {
                setErrWeight(true);
                hasError = true;
            } else setErrWeight(false);

            if (!name || name.length < 1 || name.length > 60) {
                setErrName(true);
                hasError = true;
            } else setErrName(false);

            if (!city || city.length == 0) {
                setErrCity(true);
                hasError = true;
            } else setErrCity(false);

            if (!phone || phone.length < 10 || phone.length > 20) {
                setErrPhone(true);
                hasError = true;
            } else setErrPhone(false);

            if (!address || address.length == 0 || address.length > 255) {
                setErrAddress(true);
                hasError = true;
            } else setErrAddress(false);

            //check date
            const selectedDate = moment(birthday);
            const currentDate = moment(new Date());
            if (selectedDate.diff(currentDate) >= 0) {
                setErrBOD(true);
                hasError = true;
            } else setErrBOD(false);

            return hasError;
        }

        async function onDoRegister() {
            if (!validInput()) {
                const params = {
                    uid: user.uid,
                    sex: user.gender,
                    bod: moment(user.birthday).format('x'),
                    ctry: user.ctry,
                    city: user.city,
                    wei: user.weight,
                    hei: user.height,
                    phone: user.phone,
                    addr: user.addr,
                    shirt: user.shirt
                };
                let result = await doUpdateProfile(params);
                if (result) {
                    props.history.push(`/event`);
                } else {
                    setMessageSnackbar(t('global.action.fail'));
                    setSnackbarType('error');
                    onOpenSnackbar();
                }
            }
        }

        const handleCountryChange = (selectedOption) => {
            if (selectedOption.value != 'Vietnam') {
                setCity('');
                setShowTextCtry(true);
            } else {
                setShowTextCtry(false);
                setCtry('Hà Nội');
                user.ctry = 'Hà Nội';
            }
            user.ctry = selectedOption.value;
            setCtry(selectedOption.value);
        };

        const handleSexChange = (gender) => {
            user.gender = gender;
            setSexIndex(gender);
        };

        const handleChangeEmail = (email) => {
            user.email = email;
            user.emailText = email;
            setUserEmail(email);
        };

        const handleChangePhone = (phone) => {
            if (phone.length > 20) return;
            user.phone = phone;
            setPhone(phone);
        };

        const handleChangeDate = (val) => {
            const date = formatDate('YYYY-MM-DD', val);
            user.birthday = date;
            setBirthday(date);
            setBOD(val);
        };

        const handleChangeHeight = (val) => {
            if (val > 250) val = 250;
            user.height = val;
            setHeight(val);
        };

        const handleChangeWeight = (val) => {
            if (val > 200) val = 200;
            user.weight = val;
            setWeight(val);
        };

        const handleChangeName = (val) => {
            user.name = val;
            setName(val);
        };

        const handleChangeAddress = (val) => {
            if (val && val.length > 255) return;
            user.addr = val;
            setAddress(val);
        };

        const handleChangeCity = (val) => {
            user.city = val;
            setCity(val);
        };

        const handleCityChange = (val) => {
            user.city = val.value;
            setCity(val.value);
        };

        const handleShirtChange = (val) => {
            user.shirt = val.value;
            setShirt(val.value);
        };

        const showClearCity = city && city.length > 0;
        const showClearPhone = phone && phone.length > 0;
        const showClearHeight = height && height.length > 0;
        const showClearWeight = weight && weight.length > 0;
        const showClearAddress = address && address.length > 0;

        const handleClearCity = () => {
            setCity('');
        };
        const handleClearHeight = () => {
            setHeight('');
        };
        const handleClearWeight = () => {
            setWeight('');
        };
        const handleClearPhone = () => {
            setPhone('');
        };
        const handleClearAddress = () => {
            setAddress('');
        };

        const onSkipUpdate = () => {
            props.history.push(`/event`);
        };

        function RadioItem(props) {
            const { item } = props;
            return (
                <div class="col-md-6" onClick={() => handleSexChange(item.id)}>
                    <div class="frm-radio">
                        <input type="radio" id={`r_${item.id}`} name={`radiolist}`} checked={sexIndex === item.id} />
                        <label htmlFor={`r_${item.id}`}>
                            <span class="radio"></span>
                            <span class="txt">{t(item.title)}</span>
                        </label>
                    </div>
                </div>
            );
        }

        const CustomInputDate = forwardRef(({ onClick }) => (
            <div className="form-label-group form-control" onClick={onClick}>
                <input
                    type="text"
                    id="birthday"
                    defaultValue={birthday}
                    readOnly={true}
                    className="form-control"
                    placeholder={t('global.birthday')}
                />
                <label htmlFor="birthday" className="label-input-custom">
                    {t('global.birthday')}
                </label>
                {errBOD ? (
                    <div className="wrapper-error">
                        <p className="error-message">{t('global.error.valid_birthday')}</p>
                    </div>
                ) : null}
            </div>
        ));

        //if (!user.password) return <Redirect to="/register"></Redirect>;

        return (
            <div class="outer">
                <div id="scrollContent" class="inner scrollContent scrollSwipe">
                    <section class=" wrapper-login wrapper-register profile-page  yours">
                        <div className="wrapper-user-card">
                            <div className="wrapper-head">
                                <h3 className="title">{t('global.extra_info')}</h3>
                                <div className="wrapper-image-logo">
                                    <img src="../../img/register/logo-login.svg" className="brand_logo" alt="Logo" />
                                </div>
                            </div>

                            <div className="wrapper-content">
                                <div class="form-group-login profile-frm" name="form-forgot" id="form-forgot">
                                    <div class="row wrapper-input-custom">
                                        <div className="col-md-6">
                                            <div className="form-label-group form-control">
                                                <input
                                                    id="inputEmail"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    disabled={readOnlyEmail}
                                                    value={userEmail}
                                                    onChange={(text) =>
                                                        handleChangeEmail(stripedHtml(text.target.value))
                                                    }
                                                    autoFocus
                                                />
                                                <label htmlFor="inputEmail" className="label-input-custom">
                                                    Email
                                                </label>
                                            </div>
                                            <div className="form-label-group form-control">
                                                <input
                                                    type="text"
                                                    id="userName"
                                                    //disabled={true}
                                                    maxLength={60}
                                                    className="form-control"
                                                    placeholder={t('global.name')}
                                                    value={name}
                                                    onChange={(text) =>
                                                        handleChangeName(stripedHtml(text.target.value))
                                                    }
                                                />
                                                <label htmlFor="userName" className="label-input-custom">
                                                    {t('global.name')}
                                                </label>
                                                {errName ? (
                                                    <div className="wrapper-error">
                                                        <p className="error-message">{t('global.error.valid_name')}</p>
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div class="frm-row row radio">
                                                {GENDER_TYPE.map((item) => {
                                                    return <RadioItem item={item} key={item.id} />;
                                                })}
                                            </div>

                                            <div className="clearfix"></div>

                                            <DatePicker
                                                selected={bod}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={handleChangeDate}
                                                customInput={<CustomInputDate />}
                                                renderCustomHeader={(props) => customHeader({ ...props })}
                                            />

                                            <div className="row form-label-group">
                                                <div className="col-md-6">
                                                    <Select
                                                        className={classes.select2}
                                                        options={countryList}
                                                        defaultValue={countryList[getIndex(countryList, user.ctry)]}
                                                        onChange={handleCountryChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    {showTextCtry ? (
                                                        <div className="form-label-group form-control">
                                                            <input
                                                                type="text"
                                                                id="city"
                                                                className="form-control"
                                                                placeholder={t('global.city')}
                                                                value={city}
                                                                onChange={(text) =>
                                                                    handleChangeCity(stripedHtml(text.target.value))
                                                                }
                                                            />
                                                            <label htmlFor="city" className="label-input-custom">
                                                                {t('global.city')}
                                                            </label>
                                                            {showClearCity ? (
                                                                <img
                                                                    src="../../img/register/close.svg"
                                                                    onClick={() => handleClearCity()}
                                                                    className="close-text"
                                                                />
                                                            ) : null}
                                                            {errCity ? (
                                                                <div className="wrapper-error">
                                                                    <p className="error-message">
                                                                        {t('global.error.valid_city')}
                                                                    </p>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ) : (
                                                        <Select
                                                            className={classes.select2}
                                                            options={cityList}
                                                            onChange={handleCityChange}
                                                            defaultValue={cityList[getIndex(cityList, user.city)]}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row form-label-group">
                                                <div className="col-md-6">
                                                    <div className="form-label-group form-control">
                                                        <input
                                                            type="text"
                                                            id="height"
                                                            className="form-control"
                                                            placeholder={t('global.height')}
                                                            maxLength={3}
                                                            value={height}
                                                            onChange={(text) =>
                                                                handleChangeHeight(stripedHtmlNumber(text.target.value))
                                                            }
                                                        />
                                                        <label htmlFor="height" className="label-input-custom">
                                                            {t('global.height')}
                                                        </label>
                                                        {showClearHeight ? (
                                                            <img
                                                                src="../../img/register/close.svg"
                                                                onClick={() => handleClearHeight()}
                                                                className="close-text"
                                                            />
                                                        ) : null}
                                                        {errHeight ? (
                                                            <div className="wrapper-error">
                                                                <p className="error-message">
                                                                    {t('global.error.valid_height')}
                                                                </p>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-label-group form-control">
                                                        <input
                                                            type="text"
                                                            id="weight"
                                                            className="form-control"
                                                            placeholder={t('global.weight')}
                                                            value={weight}
                                                            maxLength={3}
                                                            onChange={(text) =>
                                                                handleChangeWeight(stripedHtmlNumber(text.target.value))
                                                            }
                                                        />
                                                        <label htmlFor="weight" className="label-input-custom">
                                                            {t('global.weight')}
                                                        </label>
                                                        {showClearWeight ? (
                                                            <img
                                                                src="../../img/register/close.svg"
                                                                onClick={() => handleClearWeight()}
                                                                className="close-text"
                                                            />
                                                        ) : null}
                                                        {errWeight ? (
                                                            <div className="wrapper-error">
                                                                <p className="error-message">
                                                                    {t('global.error.valid_weight')}
                                                                </p>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <p className=" description">{t('global.register_extra_desc')}</p>
                                            <p className="description">{t('global.register_info')}</p>

                                            <div className="form-label-group form-control">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder={t('global.phone_number')}
                                                    value={phone}
                                                    maxLength={20}
                                                    onChange={(text) =>
                                                        handleChangePhone(stripedHtmlNumber(text.target.value))
                                                    }
                                                />
                                                <label htmlFor="name" className="label-input-custom">
                                                    {t('global.phone_number')}
                                                </label>
                                                {showClearPhone ? (
                                                    <img
                                                        src="../../img/register/close.svg"
                                                        onClick={() => handleClearPhone()}
                                                        className="close-text"
                                                    />
                                                ) : null}
                                                {errPhone ? (
                                                    <div className="wrapper-error">
                                                        <p className="error-message">{t('global.error.valid_phone')}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-label-group form-control">
                                                <input
                                                    type="text"
                                                    id="address"
                                                    className="form-control"
                                                    placeholder={t('global.address')}
                                                    maxLength={255}
                                                    value={address}
                                                    onChange={(text) =>
                                                        handleChangeAddress(stripedHtml(text.target.value))
                                                    }
                                                />
                                                <label htmlFor="address" className="label-input-custom">
                                                    {t('global.address')}
                                                </label>
                                                {showClearAddress ? (
                                                    <img
                                                        src="../../img/register/close.svg"
                                                        onClick={() => handleClearAddress()}
                                                        className="close-text"
                                                    />
                                                ) : null}
                                                {errAddress ? (
                                                    <div className="wrapper-error">
                                                        <p className="error-message">
                                                            {t('global.error.valid_address')}
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="row form-label-group">
                                                <div className="col-md-7">
                                                    <p className="description">{t('global.shirt_size1')}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p className="description txt-blueNewst">
                                                        {' '}
                                                        <a
                                                            target="_blank"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleOpenDialogViewSize(true)}>
                                                            {t('global.view_size')}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-label-group form-control">
                                                <Select
                                                    options={sizeList}
                                                    onChange={handleShirtChange}
                                                    defaultValue={sizeList[getIndex(sizeList, user.shirt)]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-custom-confirm"
                                        onClick={() => onSkipUpdate()}>
                                        {t('global.later')}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-custom-confirm"
                                        onClick={() => onDoRegister()}>
                                        {t('global.save_change')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <DialogViewSize
                            onClose={() => handleOpenDialogViewSize(false)}
                            openDialogViewSize={openDialogViewSize}
                        />
                    </section>
                </div>
            </div>
        );
    })
);

export default UpdateProfile;
