import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource, stripedHtml } from '../../../../../utils';
import _ from 'lodash';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import FriendItem from './FriendItem';
import RemoveFriendItem from './RemoveFriendItem';
import { EVENT_COMPARE_TYPE } from '../../../../../helpers/ConstDefined';

const CompareFriendDialog = inject(
    'globalStore',
    'userProfileStore',
    'userStore',
    'inviteFriendStore',
    'chartStore'
)(
    observer((props) => {
        const { teamID, eventID, openDialogCompareFriend } = props;
        const { listUserCompare, doUpdateUserCompare } = props.chartStore;
        const { userProfile, listUserSearch, doSearchUser, doChangeSelectedStatusUserMulti } = props.userStore;
        const { listFollower, doGetListUserFollower, doChangeSelectedStatusUserFollowerMulti } = props.userProfileStore;
        const { listMemberTemp, doInitMember, doAddMemberCompare, doRemoveMemberCompare } = props.inviteFriendStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [name, setName] = React.useState('');
        const showClearName = name && name.length > 0;
        const cssButton = listMemberTemp.length == 0 ? 'btn-44 blue' : 'btn-44 blue';

        const { t } = useTranslation();

        useEffect(() => {
            getListUserFollower(name);
            getListUserSuggest(name);
        }, []);

        useEffect(() => {
            if (!listUserCompare) return;
            doInitMember(listUserCompare);
        }, [listUserCompare]);

        useEffect(() => {
            if (!listUserSearch) return;
            doChangeSelectedStatusUserMulti(listMemberTemp);
            doChangeSelectedStatusUserFollowerMulti(listMemberTemp);
        }, [listUserSearch]);

        async function updateCompare() {
            let listID = [];
            _.forEach(listMemberTemp, (item) => {
                listID.push(parseInt(item.uid));
            });

            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: EVENT_COMPARE_TYPE.USER,
                vals: listID
            };
            let result = await doUpdateUserCompare(params);
            if (result != null) {
                setMessageSnackbar(t('team.invitedl.invite_succ'));
                setSnackbarType('success');
                onCloseDialog();
            } else {
                setMessageSnackbar(t('team.invitedl.invite_fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        function getListUserFollower(name) {
            let params = {
                uid: userProfile && userProfile.uid,
                feid: userProfile && userProfile.uid,
                evid: eventID,
                name
            };
            doGetListUserFollower(params);
        }

        function getListUserSuggest(name) {
            let params = {
                uid: userProfile && userProfile.uid,
                feid: userProfile && -userProfile.uid,
                evid: eventID,
                name
            };
            doSearchUser(params);
        }

        const handleChangeName = (val) => {
            setName(val);
        };
        const handleClearName = () => {
            setName('');
            getListUserFollower('');
            getListUserSuggest('');
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getListUserFollower(name);
                getListUserSuggest(name);
            }
        };

        const onCloseDialog = () => {
            doInitMember(listUserCompare);
            props.onClose();
        };

        return (
            <Dialog open={openDialogCompareFriend}>
                <div
                    className="uprace-popup has-title in"
                    id="team-invite-friends-2"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w800">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => onCloseDialog()}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('global.add_friend')}</h2>
                            <div className="popup-body">
                                <div className="popup-invite-friends has-notify">
                                    <div className="notify-box blue">{t('global.limit_compare_friends')}</div>
                                    <div className="search-box active">
                                        {showClearName ? (
                                            <button className="del-btn" onClick={() => handleClearName()}></button>
                                        ) : null}
                                        <input
                                            type="text"
                                            placeholder={t('global.search_by_name')}
                                            value={name}
                                            onKeyDown={handleKeyDown}
                                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                        />
                                    </div>
                                    <div className="friends-box clearfix">
                                        <div className="friends-right">
                                            <div className="friends-scroll">
                                                <h3 className="friends-title">{t('team.invitedl.friend_seleted')} <span class="num">(<strong>{listMemberTemp.length}</strong>/5)</span></h3>
                                                <ul className="friends-list">
                                                    {listMemberTemp &&
                                                        listMemberTemp.map((item) => {
                                                            return <RemoveFriendItem key={item.uid} data={item} />;
                                                        })}
                                                </ul>
                                                {!listMemberTemp || listMemberTemp.length == 0 ? (
                                                    <div className="friends-empty">
                                                        {t('team.invitedl.friend_seleted_empty')}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="friends-left">
                                            <div className="friends-scroll">
                                                <h3 className="friends-title">{t('global.follower')}</h3>
                                                <ul className="friends-list">
                                                    {listFollower &&
                                                        listFollower.map((item) => {
                                                            return <FriendItem key={item.uid} data={item} />;
                                                        })}
                                                </ul>
                                                <h3 className="friends-title">{t('global.suggest')}</h3>
                                                <ul className="friends-list">
                                                    {listUserSearch &&
                                                        listUserSearch.map((item) => {
                                                            return <FriendItem key={item.uid} data={item} />;
                                                        })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popup-btns">
                                        <div className="btn-inside">
                                            <button className={cssButton} onClick={() => updateCompare()}>
                                                {t('global.complete')}
                                            </button>
                                            <button className="btn-44 cancel" onClick={() => onCloseDialog()}>
                                                {t('global.destroy')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CompareFriendDialog;
