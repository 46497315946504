import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validators from './common/validators';
import Routes from './Routes';
import { inject, observer } from 'mobx-react';
import { getUUID, getTimestamp } from '../src/utils';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { API, KEY } from './helpers/api/APIDefined';

//css import
import '../src/assets/css/uprace-2022.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../src/assets/css/common/general.css';
import '../src/assets/css/team/team_list.css';
import '../src/assets/css/team/team_info.css';
import '../src/assets/css/team/team_details.css';
import '../src/assets/css/team/posts.css';
import '../src/assets/css/team/manage.css';
import '../src/assets/css/common/popup.css';
import '../src/assets/css/team/group_details.css';
import '../src/assets/css/info/info.css';
import '../src/assets/css/info/faq.css';
import '../src/assets/css/yours/yours.css';
import '../src/assets/css/yours/fail_activity.css';
import '../src/assets/css/profile/profile.css';
import '../src/assets/css/teaser/teaser.css';
import '../src/assets/css/team/complain_details.css';
import '../src/assets/css/login/login.css';

import '../src/assets/css/common/uprace.css';
import '../src/assets/css/users-event/users_event.css';
import '../src/assets/css/users-event/popup.css';
import '../src/assets/css/users-event/create_event_challenges.css';
import '../src/assets/css/users-event/event_detail.css';
import '../src/assets/css/users-event/manage.css';
import '../src/assets/css/users-event/posts_v2.css';
import '../src/assets/css/yours/yours_v2.css';
import '../src/assets/css/users-event/complain_details_v2.css';
import '../src/assets/css/users-event/group_detail.css';

import '../src/assets/js/jquery.js';
import '../src/assets/js/jquery.sticky.js';
import '../src/assets/js/offcanvas.js';

import '../src/assets/js/datepicker';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import './i18n'

validate.validators = {
    ...validate.validators,
    ...validators
};

const App = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { doChangeLanguage } = props.globalStore;

        const loadDefaultLanguage = () => {
            const key = localStorage.getItem(KEY.USER_LANGUAGE);
            if(key && key == 'en') doChangeLanguage(key);
            else doChangeLanguage('vi');
        }
        useEffect(() => {
            loadDefaultLanguage();
        }, []);

        return (
            <Suspense fallback={null}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter basename={API.GLOBAL.APP_BASENAME}>
                        <Routes />
                    </BrowserRouter>
                </ThemeProvider>
            </Suspense>
        );
    })
);

export default App;
