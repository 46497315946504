import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ENLang from './ENLang';
import VNLang from './VNLang';

const SeaBankRuleEvent = inject('authStore')(
    observer((props) => {
        const { lang } = props;

        useEffect(() => {}, []);

        const RenderContent = () => {
            if (lang == 'vi') return <VNLang />;
            else if (lang == 'en') return <ENLang />;
            else return <VNLang />;
        };

        return (
            <div>
                <section>
                    <div className="banner">
                        <img src="../../../img/banner/banner_searun.png" alt="image" />
                    </div>
                </section>
                <RenderContent />
            </div>
        );
    })
);

export default SeaBankRuleEvent;
