import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
const queryString = require('query-string');

const Support = inject('globalStore')(
    observer((props) => {
        const { language, doChangeLanguage, doSetTabMainTop } = props.globalStore;
        let params = queryString.parse(props.location.search);
        if (params && params.lang) doChangeLanguage(params.lang);
        
        useEffect(() => {
            doSetTabMainTop(null);
            window.scrollTo(0, 0);
        }, []);

        function SupportGPS() {
            if (language == 'en') return <SupportGPSEN />;
            else return <SupportGPSVI />;
        }

        function SupportGPSEN() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>Hướng dẫn cấp quyền sử dụng vị trí</p>
                    </div>
                    <ul class="list">
                        <section className="main-content">
                            <div class="list-desc">
                                <p>
                                    Để có thể ghi hoạt động bằng điện thoại di động, bạn cần phải cấp quyền sử dụng vị trí GPS cho ứng dụng UpRace.
                                </p>
                                <p>
                                    Mặc định khi mở tính năng Ghi hoạt động, ứng dụng sẽ xin quyền và bạn chỉ cần bấm cho phép là xong. Tuy nhiên nếu vì lý do nào đó mà bạn đã không cho phép thì bạn có thể thực hiện cấp quyền sử dụng vị trí theo hướng dẫn dưới đây.
                                </p>

                                <h5 className="title" style={{ textAlign: 'left' }}>Android</h5>
                                <ul>
                                    <li>- Nhấn & giữ biểu tượng ứng dụng UpRace → Chọn Thông tin ứng dụng</li>
                                    <li>- Chọn Quyền → Chọn Vị trí → Chọn Luôn cho phép</li>
                                    <li>- Bật Sử dụng vị trí chính xác (Nếu có)</li>
                                </ul>
                                <img src='/img/gps1.png' style={{ width: '100%' }} />


                                <h5 className="title" style={{ textAlign: 'left' }}>iOS (iPhone)</h5>
                                <ul>
                                    <li>- Vào Cài đặt → Chọn ứng dụng UpRace</li>
                                    <li>- Chọn Vị trí → Cho phép truy cập vị trí Luôn luôn hoặc Khi dùng Ứng dụng</li>
                                    <li>- Bật Vị trí chính xác (Nếu có)</li>
                                </ul>
                                <img src='/img/gps2.png' style={{ width: '100%' }} />

                                <p>
                                    Nếu đã cấp quyền đầy đủ nhưng vẫn không thể ghi hoạt động được? Vui lòng liên hệ UpRace để được hỗ trợ:
                                </p>
                                <ul>
                                    <li>
                                        - Fanpage: www.facebook.com/uprace.org
                                    </li>
                                    <li>
                                        - Email: support@uprace.org
                                    </li>
                                </ul>

                            </div>
                        </section>
                    </ul>
                </section>
            );
        }

        function SupportGPSVI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>Hướng dẫn cấp quyền sử dụng vị trí</p>
                    </div>
                    <ul class="list">
                        <section className="main-content">
                            <div class="list-desc">
                                <p>
                                    Để có thể ghi hoạt động bằng điện thoại di động, bạn cần phải cấp quyền sử dụng vị trí GPS cho ứng dụng UpRace.
                                </p>
                                <p>
                                    Mặc định khi mở tính năng Ghi hoạt động, ứng dụng sẽ xin quyền và bạn chỉ cần bấm cho phép là xong. Tuy nhiên nếu vì lý do nào đó mà bạn đã không cho phép thì bạn có thể thực hiện cấp quyền sử dụng vị trí theo hướng dẫn dưới đây.
                                </p>

                                <h5 className="title" style={{ textAlign: 'left' }}>Android</h5>
                                <ul>
                                    <li>- Nhấn & giữ biểu tượng ứng dụng UpRace → Chọn Thông tin ứng dụng</li>
                                    <li>- Chọn Quyền → Chọn Vị trí → Chọn Luôn cho phép</li>
                                    <li>- Bật Sử dụng vị trí chính xác (Nếu có)</li>
                                </ul>
                                <img src='/img/gps1.png' style={{ width: '100%' }} />


                                <h5 className="title" style={{ textAlign: 'left' }}>iOS (iPhone)</h5>
                                <ul>
                                    <li>- Vào Cài đặt → Chọn ứng dụng UpRace</li>
                                    <li>- Chọn Vị trí → Cho phép truy cập vị trí Luôn luôn hoặc Khi dùng Ứng dụng</li>
                                    <li>- Bật Vị trí chính xác (Nếu có)</li>
                                </ul>
                                <img src='/img/gps2.png' style={{ width: '100%' }} />

                                <p>
                                    Nếu đã cấp quyền đầy đủ nhưng vẫn không thể ghi hoạt động được? Vui lòng liên hệ UpRace để được hỗ trợ:
                                </p>
                                <ul>
                                    <li>
                                        - Fanpage: www.facebook.com/uprace.org
                                    </li>
                                    <li>
                                        - Email: support@uprace.org
                                    </li>
                                </ul>

                            </div>
                        </section>
                    </ul>
                </section>
            );
        }

        return <SupportGPS />;
    })
);

export default Support;
