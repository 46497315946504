import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getUUID, getTimestamp } from '../../../../utils';
import { STATS_TYPE, GLOBAL_CONFIG, LEADERBOARD_TYPE, SPORT_TYPE } from '../../../../helpers/ConstDefined';

const menu = [
    { id: SPORT_TYPE.COMPANY, name: 'Doanh nghiệp' },
    { id: SPORT_TYPE.CLUB, name: 'Câu lạc bộ' },
    { id: SPORT_TYPE.SCHOOL, name: 'Trường học' },
    { id: -1, name: 'BXH tự tạo' }
];

const RankNavigation = inject(
    'authStore',
    'leaderboardStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { eventID } = props;
        const { naviTab, setNavigationTab, doSetPageIndexTeam, doGetListLeaderboardTeam } = props.leaderboardStore;
        const { doGetEventStats } = props.eventStore;
        const { userProfile } = props.userStore;

        const onChangeNavigationTab = (category) => {
            if(category.id == -1)
            {
                setNavigationTab(category.id);
            }
            else {
                setNavigationTab(category.id);
                getLeaderboardTeam(category);
                doSetPageIndexTeam(1);
                props.onChangeTab();
            }
        };

        function getLeaderboardTeam(category) {
            const data = {
                tmsg: getTimestamp(),
                sign: '56c6ed20caf80c38b033106aa41d2adc',
                evid: eventID,
                type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                value: category.id //tab company => value = 0 ,tab sport =>value = 1, tab school => 2
            };
            doGetListLeaderboardTeam(data);
        }

        function MenuItem(props) {
            const { category } = props;
            const cssClass = category.id == naviTab ? 'nav-link active' : 'nav-link';

            return (
                <div className="col-md-3" onClick={() => onChangeNavigationTab(category)}>
                    <div className="nav-item ">
                        <a className={cssClass} id="business-tab" data-toggle="tab"
                           role="tab" aria-controls="business" aria-selected="true">{category.name}</a>
                    </div>
                </div>
            );
        }

        return (
            <div className="row nav-tab">
                {menu.map((cat) => (
                    <MenuItem key={cat.id} category={cat} />
                ))}
            </div>
        );
    })
);

export default RankNavigation;
