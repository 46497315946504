import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const NotLogin = inject('authStore')(
    observer((props) => {
        const { eventID } = props;

        useEffect(() => {}, []);

        if (eventID == 2)
            return (
                <div className="action-join">
                    <img src="../../../img/upgrade-2020.png" alt="action join" />
                    <div className="div-button">
                        <Link className="btn btn-danger btn-join" to="/login">
                            Tham gia ngay
                        </Link>
                    </div>
                </div>
            );
        else
            return (
                <div className="action-join" style={{backgroundImage: `url('../../../img/searun_bg_portral.png')`}}>
                    <img src="../../../img/searun_bg_portral.png" alt="action join" />
                    <div className="div-button">
                        <Link className="btn btn-danger btn-join" to="/login">
                            Tham gia ngay
                        </Link>
                    </div>
                </div>
            );
    })
);

export default NotLogin;
