import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const Permission = inject('authStore')(
    observer((props) => {

        useEffect(() => {}, []);

        return (
            <>
            <h3 className="title-rank">Quyền riêng tư</h3>
                <div className="wrapper-user-info">
                    <div className="permission-change">

                        <form className="form-change-user" name="form1" id="form1">
                            <label className="title-permission">Quyền theo dõi</label>
                            <p className="description">Chọn quyền mà người khác có thể theo dõi bạn.</p>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Tự do theo dõi</label>
                                <p className="description">Bất cứ ai cũng có quyền tìm kiếm và theo dõi bạn, mà không cần sự chấp thuận của bạn. Họ có thể xem tất cả hồ sơ, số liệu thống kê và hoạt động của bạn trên nguồn cung cấp dữ liệu của bạn.</p>
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck2" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Yêu cầu theo dõi</label>
                                <p className="description">Bất cứ ai cũng có quyền tìm kiếm và  yêu cầu theo dõi bạn. Bạn có thể phê duyệt hoặc từ chối các yêu cầu đó.</p>
                            </div>

                            <label className="title-permission">Hoạt động riêng tư</label>
                            <p className="description">Các hoạt động là tập luyện, cuộc đua hoặc sự kiện bạn ghi lại hoặc đồng bộ hoá với Uprace. Những gì bạn chọn dưới đây sẽ là mặc định của bạn, nhưng bạn có thể thay đổi cài đặt cho từng.</p>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck3" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Công khai</label>
                                <p className="description">Bất cứ ai trên UpRace đều có thể xem các hoạt động của bạn. Các hoạt động của bạn sẽ được hiển thị trên Bảng Tin và sẽ được tính vào bảng xếp hạng của đội.</p>
                            </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck4" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Chỉ những người đang theo dõi bạn</label>
                                <p className="description">Chỉ những người đang theo dõi bạn mới có thể truy cập chi tiết hoạt động của bạn. Các hoạt động của bạn sẽ được tính vào bảng xếp hạng của đội.</p>
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck5" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Chỉ mình tôi</label>
                                <p className="description">Các hoạt động của bạn là riêng tư. Chỉ có bạn có thể xem chúng. Không ai sẽ nhìn thấy chúng trên trang hồ sơ, bảng xếp hạng của bạn hoặc bất cứ nơi nào khác trên UpRace.</p>
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary btn-confirm" type="submit" name="submit_reg" value="Xác nhận">
                                    Cập nhật
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    })
);

export default Permission;