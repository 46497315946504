import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { convertTimestamptoDate, formatDistanceKM, getImageResource } from '../../../../utils/index';
import { FOLLOW_STT } from '../../../../helpers/ConstDefined';
import { KEY } from '../../../../helpers/api/APIDefined';
import DialogChallengeResult from './DialogChallengeResult';

const DialogChallengeUser = inject(
    'authStore',
    'teamStore',
    'userStore',
    'challengeStore',
    'userProfileStore'
)(
    observer((props) => {
        const { openDialogChallengeMember, challengeDetail, navigation } = props;
        const { userProfile } = props.userStore;
        let { memberDetail, currentMember, doFollow } = props.teamStore;
        const { doSetProfileUserID } = props.userProfileStore;

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: currentMember.uid,
                action
            };
            let result = await doFollow(params);
            if (result) {
                currentMember.fists = action == 0 ? 2 : 0;
            }
        }

        const handleViewProfileUser = () => {
            sessionStorage.setItem(KEY.ID_PROFILE_USER, currentMember.uid);
            doSetProfileUserID(currentMember.uid);
            navigation.push('/user-profile');
        };

        return (
            <Modal show={openDialogChallengeMember} onHide={props.onClose}>
                <div
                    className="custom-modal-complex modal-member-event modal-join-team"
                    role="dialog"
                    aria-hidden="true">
                    <div className="custom-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="wrapper-banner">
                                    <div className="banner background-image-banner">
                                        <img src="../../../img/background-profile-member.png" alt="image" />
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}>
                                    <img src="../../../img/icon-close-2.png" alt="image" />
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="wrapper-content">
                                    <div className="intro">
                                        <div
                                            className="profile-img"
                                            style={{ backgroundImage: `url(${getImageResource(currentMember.ava, true, true)})` }}>
                                            <img src="../../../img/avatar.png" alt="image" />
                                        </div>

                                        <h3 className="title" onClick={() => handleViewProfileUser()} style={{cursor: "pointer"}}>
                                            {currentMember.name}
                                            <a className="action-name">
                                                <i className="fa fa-angle-right" size="20px"></i>
                                            </a>
                                        </h3>
                                        <p className="team-type">
                                            {currentMember.ctry} - {currentMember.city}
                                        </p>
                                        <span>
                                            
                                            {currentMember.fists == 2 ? (
                                                userProfile.uid != currentMember.id ? (
                                                    <button
                                                        onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}
                                                        type="button"
                                                        className="btn btn-outline-primary"
                                                        style={{ width: 120 }}>
                                                        Bỏ theo dõi
                                                    </button>
                                                ) : null
                                            ) : userProfile.uid != currentMember.id ? (
                                                <button
                                                    onClick={() => followUser(FOLLOW_STT.FOLLOW)}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ width: 120 }}>
                                                    Theo dõi
                                                </button>
                                            ) : null}
                                        </span>
                                    </div>
                                </div>

                                <div className="table-responsive custom-table-profile">
                                    <div className="wrapper-content-profile ">
                                        <DialogChallengeResult challengeDetail={challengeDetail} />
                                        <div className="member-reaction"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    })
);

export default DialogChallengeUser;
