import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';
import { DetailMap, DetailSummary, DetailDistanceSwim, ChartPace, RadioAction } from './components';
import { SPORT_TYPE_ACTIVITY } from 'helpers/ConstDefined';
import { isJWTValid, stripedHtml } from '../../utils';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');
import _ from 'lodash';

const FeedDetail = inject(
    'reportStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { isViewDetail } = props.reportStore;
        const { feedDetail, userProfile, doGetFeedDetail } = props.userStore;
        const { appVersion, doResetImageFeed } = props.globalStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.atid || !paramsQuery.evid) return <Redirect to="/"></Redirect>;
        const feedID = stripedHtml(paramsQuery.atid);
        const eventID = stripedHtml(paramsQuery.evid);

        useEffect(() => {
            window.scrollTo(0, 0);
            if (userProfile) getFeedDetail();
        }, [userProfile]);

        useEffect(() => {
            if (!isJWTValid()) props.history.push('/login');
        }, []);
        

        const { t } = useTranslation();

        async function getFeedDetail() {
            doResetImageFeed(); //reset image photo of feed current
            let data = {
                uid: userProfile && userProfile.uid,
                atid: feedID
            };
            let result = await doGetFeedDetail(data);
            if (!result) {
                props.history.push('/');
            }
        }

        function RenderBottom() {
            if (feedDetail.sport == SPORT_TYPE_ACTIVITY.SWIMMING) return <DetailDistanceSwim feedDetail={feedDetail} />;
            else return <ChartPace />;
        }

        if (feedDetail != null)
            return (
                <section class="complain-details-page">
                    <div class="complain-details-wrap">
                        <a href="javascript:void(0);" class="back-btn" onClick={() => props.history.goBack()}>
                            {t('global.back')}
                        </a>
                        <div style={{ margin: 15 }}></div>
                        <div class="complain-details-box">
                            <h2 class="complain-details-title">{t('global.detail_act')}</h2>
                            <DetailSummary />
                            <RenderBottom />
                            {isViewDetail != null ? <RadioAction eventID={eventID} /> : null}
                        </div>
                    </div>
                </section>
            );
    })
);

export default FeedDetail;
