import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { Sidebar, Topbar, Footer, Tabbar } from './components';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    content: {
        height: '100%'
        //backgroundColor: '#F5F5F5'
    }
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const SmallerLayout = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { children } = props;
        const classes = useStyles();
        const { messageSnackbar, onCloseSnackbar, openSnackbar, snackbarType } = props.globalStore;

        const handleSidebarOpen = () => {
            setOpenSidebar(true);
        };

        return (
            <div>
                 <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={onCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={1500}>
                    <Alert onClose={onCloseSnackbar} severity={snackbarType}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>

                <Topbar onSidebarOpen={handleSidebarOpen} history={children} />

                <main className={classes.content}>
                    {children}
                    <Footer />
                </main>
            </div>
        );
    })
);

SmallerLayout.propTypes = {
    children: PropTypes.node
};

export default SmallerLayout;
