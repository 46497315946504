import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { daysInMonth } from '../../../../helpers/DateHelper';
import * as moment from 'moment';

const WeeklyComponent = inject('authStore')(
    observer((props) => {
        const { open, textDuration } = props;
        let [curMonth, setCurMonth] = React.useState(moment().month() + 1);
        let [curYear, setCurYear] = React.useState(new Date().getFullYear());
        let [weekDays, setWeekDays] = React.useState(null);

        useEffect(() => {
            initControl();
        }, [curMonth, curYear]);

        function toggleDialog() {
            if (open) setOpen(false);
            else setOpen(true);
        }

        const cssOpen = open ? 'block' : 'none';

        function initControl() {
            const weekDays = daysInMonth(curYear, curMonth);
            const total = weekDays[0].length;

            setWeekDays(weekDays);
        }

        function nextMonth() {
            if (curMonth < 12) setCurMonth((curMonth += 1));
            else {
                setCurMonth(1);
                setCurYear((curYear += 1));
            }
        }

        function preMonth() {
            if (curMonth > 1) setCurMonth((curMonth -= 1));
            else {
                setCurMonth(12);
                setCurYear((curYear -= 1));
            }
        }

        function handleSelectWeek(week, from, to) {
            const val = `${curYear}${week}`;
            const text = `${moment(from).format('MMM DD')} - ${to}`;
            props.onSelectWeek(val, text);
            props.onClose();
        }

        function WeeklyItem(props) {
            if (weekDays == null) return null;
            const { week, index } = props;

            var arrDate = weekDays[1][index].map((item) => {
                return moment(item).format('MMM DD, YYYY');
            });

            return (
                <tr onClick={() => handleSelectWeek(week, arrDate[0], arrDate[arrDate.length - 1])}>
                    <td>
                        <strong>Week</strong> <span className="value-week">{week}</span>
                    </td>
                    <td>
                        <span className="value-month">{arrDate[0]}</span>
                    </td>
                    <td>
                        <span className="value-month">{arrDate[arrDate.length - 1]}</span>
                    </td>
                </tr>
            );
        }

        return (
            <div class="dropdown-menu" aria-labelledby="list-server-choose" style={{ display: cssOpen }}>
                <div class="month-pick">
                    <span class="fa fa-chevron-left" onClick={() => preMonth()}></span>
                    <span class="month-value">
                        {moment(curMonth, 'MM').format('MMMM')} {curYear}
                    </span>
                    <span class="fa fa-chevron-right" onClick={() => nextMonth()}></span>
                </div>
                <div class="wrapper-week">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Week number</th>
                                <th>From Date</th>
                                <th>To Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {weekDays != null &&
                                weekDays[0].map((item, index) => {
                                    return <WeeklyItem key={item} week={item} index={index} />;
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    })
);

export default WeeklyComponent;
