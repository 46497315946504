import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_LOCK_STATUS, FOLLOW_STT, GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import { getImageResource } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberName, MemberCode } from 'components/MemberName';

const LikeItem = inject(
    'authStore',
    'userStore'
)(
    observer((props) => {
        const { data } = props;
        const { userProfile, doFollow } = props.userStore;

        const { t } = useTranslation();

        async function followUser(action) {
            const params = {
                uid: userProfile && userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        return (
            <li>
                {data.fists == 2 ? (
                    userProfile && userProfile.uid != data.uid ? (
                        <button class="btn-32 blue-light" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                            {t('global.unfollow')}
                        </button>
                    ) : null
                ) : userProfile && userProfile.uid != data.uid ? (
                    <button class="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                        {t('global.follow')}
                    </button>
                ) : null}
                <Link to={`/user-profile?uid=${data.uid}&evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`}>
                    <p class="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div class="details">
                        <p class="name">{MemberName(data)}</p>
                        <p class="code">{MemberCode(data)}</p>
                    </div>
                </Link>
            </li>
        );
    })
);

export default LikeItem;
