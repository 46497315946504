import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody, requestAPIBodyNotAuth } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';
import _ from 'lodash';

export default class UserLeaderboardStore {
    @observable listMemberTemp = [];
    @observable listMemberAdd = [];
    @observable listMemberRemove = [];
    @observable listLeaderboard = [];
    @observable currentUserLeaderboard = null;
    @observable loading = null;

    @action.bound
    doAddMemberTemp(user) {
        const exist = _.find(this.listMemberTemp, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) this.listMemberTemp.push(user);
        console.log(toJS(this.listMemberTemp));
    }

    @action.bound
    doClearAllMember() {
        this.listMemberTemp = [];
    }

    @action.bound
    doAddMember(user) {
        let exist = _.find(this.listMemberAdd, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) {
            exist = _.find(this.listLeaderboard, (item) => {
                return item.uid == user.uid;
            });

            if (!exist) {
                this.listMemberAdd.push(user);
                _.remove(this.listMemberRemove, (item) => {
                    return item.uid == user.uid;
                });
            }
        }
    }

    @action.bound
    doRemoveMember(user) {
        let exist = _.find(this.listMemberRemove, (item) => {
            return item.uid == user.uid;
        });
        if (!exist) {
            exist = _.find(this.listMemberTemp, (item) => {
                return item.uid == user.uid;
            });

            if (!exist) {
                this.listMemberRemove.push(user);
                _.remove(this.listMemberAdd, (item) => {
                    return item.uid == user.uid;
                });
            }
        }
    }

    @action.bound
    doSetUserLeaderboard(data) {
        this.currentUserLeaderboard = data;
    }

    @action.bound
    async doGetDetailLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.currentUserLeaderboard = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    doRemoveMemberTemp(user) {
        _.remove(this.listMemberTemp, (item) => {
            return item.uid == user.uid;
        });
    }

    @action.bound
    async doGetListLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.GET_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.listLeaderboard = resp.data.list;

                    _.forEach(this.listLeaderboard, (item) => {
                        item.uid = item.id;
                    });
                    this.listMemberTemp = _.clone(this.listLeaderboard);

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doCreateUserLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp) {
                    result = resp;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doUpdateUserLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doDeleteUserLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.DELETE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doLeaveUserLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.LEAVE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doAddMemberLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.ADD_MEMBER, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp) {
                    result = resp;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    async doRemoveMemberLeaderboard(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.LEADERBOARD.REMOVE_MEMBER, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = resp.ercd;
                } else {
                    
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }
}
