import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { LOGIN_TYPE } from '../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';

const MENU = [
    { id: 0, title: 'settings.lmenu.set_pass', active: true },
    { id: 1, title: 'settings.lmenu.info_personal', active: true }
];

const LeftMenu = inject(
    'authStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { settingTab, doSetSettingTab } = props.globalStore;
        const { userProfile } = props.userStore;

        const { t } = useTranslation();
        useEffect(() => {
            if (userProfile.type != LOGIN_TYPE.EMAIL) doSetSettingTab(1);
        }, []);

        function MenuItem(props) {
            const { category } = props;
            const cssClass = category.id == settingTab ? 'active' : '';

            if (category.id == 0 && userProfile.type != LOGIN_TYPE.EMAIL) return null;

            return (
                <li class={cssClass} onClick={() => doSetSettingTab(category.id)}>
                    <a href="javascript:void(0);">{t(category.title)}</a>
                </li>
            );
        }

        return (
            <div class="profile-wbox setting">
                <h2 class="profile-title">{t('global.setting')}</h2>
                <div class="profile-setting-menu">
                    <div class="setting-icon">
                        <span class="line"></span>
                    </div>
                    <ul class="setting-list">
                        {MENU.map((cat) => {
                            return <MenuItem key={cat.id} category={cat} />;
                        })}
                    </ul>
                </div>
            </div>
        );
    })
);

export default LeftMenu;
