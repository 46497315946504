import { observable, action, toJS } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { EVENT_COMPARE_TYPE } from '../helpers/ConstDefined';
import { log, formatDate } from '../utils';
import _ from 'lodash';

export default class ChartStore {
    @observable listEventCompare = null;
    @observable listPersonalAchieve = null;

    @observable listDataTeamEvent = null; //data event của team khi click point chart
    @observable listDataUserEvent = null; //data event của user khi click point chart
    @observable listUserCompare = null;

    @observable loading = false;

    @observable activeMember = null;
    @observable startDate = new Date();
    @observable startDateText = formatDate('DD/MM', new Date());

    @action.bound
    doSetActiveMember(val)
    {
        this.activeMember = val;
    }

    @action.bound
    doSetStartDate(val) {
        this.startDate = val;
    }

    @action.bound
    doSetStartDateText(val) {
        this.startDateText = val;
    }

    @action.bound
    doSetDataTeamEvent(data) {
        this.listDataTeamEvent = data;
    }

    @action.bound
    doSetDataUserEvent(data) {
        this.listDataUserEvent = data;
    }

    @action.bound
    doGetDisByDayUserCompare(day) {
        _.forEach(this.listUserCompare, (item) => {
            const obj = _.find(item.chartData, (val) => {
                return val.day == day;
            });
            item.ddis = obj;
        });
    }

    @action.bound
    async doUpdateUserCompare(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.EVENT.UPDATE_COMPARE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    let listResp = [];
                    const now = formatDate('YYYYMMDD', new Date());

                    _.each(resp.data, (val, key) => {
                        //lấy vị trí của ngày hiện tại trong list dyls
                        let index = _.findIndex(val.dyls, (item) => {
                            return item.day == now;
                        });
                        //only show data 28 days lastest
                        let start = index < 28 ? 0 : index - 27;

                        val.uid = key;
                        //val.chartData = val.dyls.slice(start, index + 1);
                        val.chartData = val.dyls;
                        listResp.push(val);
                    });

                    //sort
                    let list = _.sortBy(listResp, (item) => {
                        return item.dis;
                    }).reverse();

                    //get dis of current day
                    _.forEach(list, (item) => {
                        const obj = _.find(item.chartData, (val) => {
                            return val.day == now;
                        });
                        item.ddis = obj;
                    });

                    this.listUserCompare = list;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    @action.bound
    doGetListUserCompare(params, endDate) {
        let result = null;
        this.loading = true; //show progress
        requestAPIV2(API.CHART.OTHER_MEMBER_COMPARE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    let listResp = [];
                    let now = formatDate('YYYYMMDD', new Date());
                    endDate = formatDate('YYYYMMDD', endDate);
                    if (now >= endDate) now = endDate;

                    _.each(resp.data, (val, key) => {
                        //lấy vị trí của ngày hiện tại trong list dyls
                        let index = _.findIndex(val.dyls, (item) => {
                            return item.day == now;
                        });
                        //only show data 28 days lastest
                        let start = index < 28 ? 0 : index - 27;

                        val.uid = key;
                        //val.chartData = val.dyls.slice(start, index + 1);
                        val.chartData = val.dyls;
                        listResp.push(val);
                    });

                    //sort
                    let list = _.sortBy(listResp, (item) => {
                        return item.dis;
                    }).reverse();

                    //get dis of current day
                    _.forEach(list, (item) => {
                        const obj = _.find(item.chartData, (val) => {
                            return val.day == now;
                        });
                        item.ddis = obj;
                    });

                    this.listUserCompare = list;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetEventCompareData(params, type, endDate) {
        let result = null;
        this.loading = true; //show progress

        await requestAPIV2(API.CHART.OTHER_EVENT_COMPARE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    let listResp = [];
                    let now = formatDate('YYYYMMDD', new Date());
                    endDate = formatDate('YYYYMMDD', endDate);
                    if (now >= endDate) now = endDate;

                    //lấy vị trí của ngày hiện tại trong list dyls
                    let index = _.findIndex(resp.data[params.evid].dyls, (item) => {
                        return item.day == now;
                    });

                    //only show data 28 days lastest
                    let start = index < 28 ? 0 : index - 27;

                    _.each(resp.data, (val, key) => {
                        val.id = key;
                        //val.chartData = val.dyls.slice(start, index + 1);
                        val.chartData = val.dyls;
                        listResp.push(val);
                    });

                    let list = _.sortBy(listResp, (item) => {
                        return item.id;
                    }).reverse();

                    if (type == EVENT_COMPARE_TYPE.USER) this.listPersonalAchieve = list;
                    else if (type == EVENT_COMPARE_TYPE.TEAM) this.listEventCompare = list;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }
}
