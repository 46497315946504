import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const HeaderInfo = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        const { t } = useTranslation();

        return (
            <div className="teams-info">
                <div className="inside">
                    <div className="img pc">
                        <img src="../../images/team/imgs/team_bn.png" alt="" />
                    </div>
                    <div className="img mb">
                        <img src="../../images/team/imgs/team_bn_m.png" alt="" />
                    </div>
                    <div className="info">
                        <h2 className="title">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('team.team_list.title')
                                }}></div>
                        </h2>
                        <p
                            className="details"
                            dangerouslySetInnerHTML={{
                                __html: t('team.team_list.desc')
                            }}></p>
                    </div>
                </div>
            </div>
        );
    })
);

export default HeaderInfo;
