import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { GLOBAL_CONFIG, CHALLENGE_TYPE } from '../../../../helpers/ConstDefined';
import { stripedHtml } from '../../../../utils';

const MENU = [
    { id: CHALLENGE_TYPE.ALL, title: 'Tất cả' },
    { id: CHALLENGE_TYPE.FOLLOWING, title: 'Đang theo dõi' },
    { id: CHALLENGE_TYPE.TEAM, title: 'Cùng đội' }
];

const ListChallengeToolbar = inject(
    'authStore',
    'challengeStore',
    'userStore',
    'teamStore'
)(
    observer((props) => {
        const { nameSearch, eventID } = props;
        const { tabIndex, doSetTabIndex, doGetListChallenge } = props.challengeStore;
        const { userProfile } = props.userStore;
        const { userTeam } = props.teamStore;

        const showClearName = nameSearch && nameSearch.length > 0; 

        useEffect(() => {}, []);

        const onChangeNavigationTab = (category) => {
            doSetTabIndex(category.id);
            getListChallenge(category.id);
            props.onChangeTab();
        };

        function onSearch() {
            props.onSearch();
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                props.onSearch();
            }
        };

        function handleResetSearch() {
            props.handleChangeName('');
            props.onSearchReset();
        }


        const handleChangeName = (val) => {
            props.handleChangeName(val);
        };

        function getListChallenge(tab) {
            let params = {
                uid: userProfile.uid,
                feed: tab,
                evid: eventID
            };
            if (tab == CHALLENGE_TYPE.ALL) params.value = userProfile.uid;
            else if (tab == CHALLENGE_TYPE.FOLLOWING) params.value = userProfile.uid;
            else params.value = userTeam.tmid;
            doGetListChallenge(params);
        }

        function MenuItem(props) {
            const { category } = props;
            const cssName = category.id == tabIndex ? 'btn btn-action-list active' : 'btn btn-action-list inactive';

            return (
                <a className={cssName} role="button" onClick={() => onChangeNavigationTab(category)}>
                    {category.title}
                </a>
            );
        }

        return (
            <>
                <h4 className="title">Thử thách của VĐV khác</h4>

                <div className="row ">
                    <div className="col-lg-6">
                        <div className="btn-tab-custom">
                            {MENU.map((cat) => (
                                <MenuItem key={cat.id} category={cat} />
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="wrapper-search custom-search-challenges">
                            <div className="form-search-custom" id="search-form">
                                <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <img src="../../../img/icon-search.png" alt="" />
                                        </span>
                                    </div>
                                    <input
                                        id="search"
                                        type="text"
                                        className="form-control form-search"
                                        name="search"
                                        onKeyDown={handleKeyDown}
                                        value={nameSearch}
                                        onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                        placeholder="Tìm tên VDV"
                                    />
                                    {showClearName ? (
                                                <img
                                                    src="../../../img/register/close.svg"
                                                    className="close-text"
                                                    onClick={() => handleResetSearch()}
                                                />
                                            ) : null}
                                </div>
                                <button
                                    className="btn btn-primary btn-search btn-search-custom"
                                    onClick={() => onSearch()}>
                                    Tìm kiếm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

export default ListChallengeToolbar;
