module.exports = {
    EVENT_LIST: [
        {
            id: 2,
            name: 'UpRace 2020',
            leaderboardbg: '../../../img/background-rank.png',
            lbdrank: '../../../img/rank-top.svg'
        },
        {
            id: 3,
            name: 'SeaBank Run',
            leaderboardbg: '../../../img/seabank_bxh_bg.png',
            lbdrank: '../../../img/seabank_bxh_rank.png'
        }
    ],

    MAIN_MENU: [
        {
            id: 0,
            title: 'global.main_menu.info'
        },
        {
            id: 1,
            title: 'global.main_menu.team'
        },
        {
            id: 2,
            title: 'global.main_menu.you'
        }
    ],

    EVENT_MODE_DATA: [
        {
            id: 0,
            title: 'global.all'
        },
        {
            id: 1,
            title: 'ue.offical'
        },
        {
            id: 2,
            title: 'ue.challen',
        },
        {
            id: 3,
            title: "ue.viturl"
        }
    ],

    EVENT_STATUS_DATA: [
        {
            id: 0,
            title: 'global.all'
        },
        {
            id: 1,
            title: 'ue.soon'
        },
        {
            id: 2,
            title: 'ue.ready'
        },
        {
            id: 3,
            title: 'ue.finished'
        }
    ],

    SORT_MEMBER_DATA: [
        {
            id: 0,
            title: 'eu.sort_asc'
        },
        {
            id: 1,
            title: 'eu.sort_desc'
        }
    ],

    EVENT_OFFICAL_DATA: [
        {
            id: 0,
            title: 'global.all'
        },
        {
            id: 1,
            title: 'ue.unoffical'
        },
        {
            id: 2,
            title: 'ue.offical'
        }
    ],

    EVENT_PUBLIC_TYPE: [
        {
            id: 1,
            title: 'ue.public',
        },
        {
            id: 2,
            title: 'ue.protected',
        },
        {
            id: 3,
            title: 'eu.private'
        }
    ],

    SPORT_TYPE: [
        {
            id: 0,
            title: 'global.all',
            icon: ''
        },
        {
            id: 2,
            title: 'global.team_sport_type.run',
            icon: 'type run'
        },
        {
            id: 1,
            title: 'global.team_sport_type.cycling',
            icon: 'type bike'
        },
        {
            id: 3,
            title: 'global.team_sport_type.walk',
            icon: 'type walk'
        },
        {
            id: 5,
            title: 'global.team_sport_type.swim',
            icon: 'type swim'
        },
        {
            id: 4,
            title: 'global.team_sport_type.triathlon',
            icon: 'type triathlon'
        },
        {
            id: 6,
            title: 'global.team_sport_type.other',
            icon: ''
        }
    ],

    TEAM_TYPE: [
        {
            id: 0,
            title: 'global.all',
            icon: 'ico all'
        },
        {
            id: 1,
            title: 'global.tab_sport_type.company',
            icon: 'ico company'
        },
        {
            id: 3,
            title: 'global.tab_sport_type.school',
            icon: 'ico school'
        },
        {
            id: 2,
            title: 'global.tab_sport_type.club',
            icon: 'ico fc'
        }
    ],

    GENDER_TYPE: [
        {
            id: 0,
            title: 'global.all',
            icon: 'ico all'
        },
        {
            id: 1,
            title: 'global.male'
        },
        {
            id: 2,
            title: 'global.female'
        }
    ],

    GROUP_STATUS: [
        {
            id: 0,
            title: 'global.group_status.none'
        },
        {
            id: 1,
            title: 'global.group_status.request'
        },
        {
            id: 2,
            title: 'global.group_status.joined'
        },
        {
            id: 3,
            title: 'global.group_status.admin'
        }
    ],

    USER_ROLE: [
        {
            id: 0,
            title: 'global.role_user.none'
        },
        {
            id: 1,
            title: 'global.role_user.member'
        },
        {
            id: 2,
            title: 'global.role_user.admin'
        },
        {
            id: 3,
            title: 'global.role_user.owner'
        }
    ],

    STATS_TYPE: [
        { id: 0, title: 'global.weekly' },
        { id: 1, title: 'global.monthly' },
        { id: 2, title: 'global.yearly' }
    ],

    MEMBER_TYPE: [
        {id: 0, title: 'global.you_follow'},
        {id: 1, title: 'global.other_member'},
        {id: 2, title: 'global.admin_owner'}
    ],









    SPORT_TYPE_: [
        { id: 0, name: 'Tất cả', img: null },
        { id: 2, name: 'Chạy bộ', img: '../../../img/icon-run.png' },
        { id: 5, name: 'Bơi lội', img: '../../../img/event-team/swimming.svg' },
        { id: 1, name: 'Đạp xe', img: '../../../img/event-team/icon-cycling.svg' },
        { id: 4, name: 'Triathlon', img: '../../../img/event-team/icon-triathlon.svg' },
        { id: 3, name: 'Đi bộ', img: '../../../img/ic_walk.png' }
    ],
    SPORT_TYPE_DIALOG: [
        { id: 2, name: 'Chạy bộ', img: '../../../img/icon-run.png' },
        { id: 5, name: 'Bơi lội', img: '../../../img/event-team/swimming.svg' },
        { id: 1, name: 'Đạp xe', img: '../../../img/event-team/icon-cycling.svg' },
        { id: 4, name: 'Triathlon', img: null }
    ],
    TEAM_TYPE_: [
        { id: 0, name: 'Tất cả' },
        { id: 1, name: 'Doanh nghiệp' },
        { id: 2, name: 'Câu lạc bộ thể thao' },
        { id: 3, name: 'Trường học' }
    ],
    EVENT_TIME_TYPE: [
        { id: 0, name: 'Thời gian' },
        { id: 1, name: 'Sắp diễn ra' },
        { id: 2, name: 'Đang diễn ra' },
        { id: 3, name: 'Đã kết thúc' }
    ],
    EVENT_OPTION_TYPE: [
        { id: 0, name: 'Loại sự kiện' },
        { id: 1, name: 'Chính thức' },
        { id: 2, name: 'Không chính thức' }
    ],
    EVENT_MEMBER_TYPE: [
        { id: 0, name: 'Số lượng tham gia' },
        { id: 1, name: 'Giảm dần' },
        { id: 2, name: 'Tăng dần' }
    ],
    SIZE_CLOTHES: ['2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL']
};
