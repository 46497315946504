import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DialogVerifyOTP from './components/DialogVerifyOTP';
import DialogSendReq from './components/DialogSendReq';
import { stripedHtml, validEmail } from '../../utils/index';
import _ from 'lodash';
import { GROUP_ACTION } from 'helpers/ConstDefined';

const VerifyEmail = inject(
    'eventStore',
    'userEventStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;
        const { doActionEvent } = props.userEventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [openDialogVerifyOTP, setOpenDialogVerifyOTP] = React.useState(false);
        const [openDialogSendReq, setOpenDialogSendReq] = React.useState(false);
        const [errorText, setErrorText] = React.useState(null);
        const [email, setEmail] = React.useState(null);
        const [errDomain, setErrDomain] = useState(false);
        const disableCSS = email && email.length > 0 ? '' : ' inactive';

        useEffect(() => {
            window.scrollTo(0, 0);
            if (userProfile && checkDomainValid(userProfile.email)) {
                setEmail(userProfile.email);
            }
        }, []);
        const { t } = useTranslation();

        //back event page if event data is null
        if (!currentEvent && !global.EVENT_ID) props.history.push('/event');

        const evid = global.EVENT_ID ? global.EVENT_ID : currentEvent && currentEvent.evid;
        const showReqJoin = global.SHOW_REQUEST_JOIN ? global.SHOW_REQUEST_JOIN : currentEvent && currentEvent.jreq;

        const requestJoin = async () => {
            const params = {
                uid: userProfile.uid,
                evid,
                action: GROUP_ACTION.USER_REQUEST_JOIN
            };
            let result = await doActionEvent(params);
            if (result != null && result.hasOwnProperty('jsts')) {
                props.history.push('/event-detail?evid=' + evid);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        };

        const onGetOTP = async () => {
            const params = {
                uid: userProfile.uid,
                evid,
                action: GROUP_ACTION.REQUIRE_OTP_VIA_EMAIL,
                oadr: email
            };

            let result = await doActionEvent(params);

            if (result && result.hasOwnProperty('jsts')) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                setOpenDialogVerifyOTP(true);
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        };

        const checkDomainValid = (emailVal) => {
            const list = (currentEvent && currentEvent.emls) || global.EMAIL_LIST;
            const index = emailVal.indexOf('@');
            const domain = emailVal.substring(index, emailVal.length);

            if (!_.includes(list, domain)) {
                if (email) {
                    setErrorText(t('global.otp_domain_not_valid'));
                    setErrDomain(true);
                }
                return false;
            }
            return true;
        };

        const verifyEmail = () => {
            if (validInput()) return;
            if (!checkDomainValid(email)) return;
            onGetOTP();
        };

        const sendReqJoin = () => {
            setOpenDialogSendReq(true);
        };

        function validInput() {
            let hasError = false;
            if (!validEmail(email)) {
                setErrDomain(true);
                setErrorText(t('global.error.valid_email'));
                hasError = true;
            } else setErrDomain(false);
            return hasError;
        }

        return (
            <section class="email-verification-page">
                <div class="email-verification-wrap">
                    <div class="container py-5 py-sm-7">
                        <div class="mx-auto" style={{ maxWidth: '35rem' }}>
                            <div class="card border-0">
                                <div class="card-body">
                                    <div class="mb-4 text-center">
                                        <img
                                            class="avatar mb-3"
                                            src="https://uprace2.vcdn.vn/web/uprace/illustrations/oc-email-verification.png"
                                            alt="Image Description"
                                        />
                                        <h1 class="display-5">{t('global.confirm_email_title')}</h1>
                                        <p class="text-ink-lighter">{t('global.confirm_email_desc')}</p>
                                    </div>

                                    <div class="mb-4">
                                        <label class="form-label" for="verifyEmail">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            class="form-control"
                                            name="email"
                                            id="verifyEmail"
                                            placeholder=""
                                            maxLength={250}
                                            value={email}
                                            onChange={(text) => setEmail(stripedHtml(text.target.value))}
                                            aria-label="Enter your email address"
                                        />
                                        {errDomain ? (
                                            <div className="wrapper-error">
                                                <p className="error-message">{errorText}</p>
                                            </div>
                                        ) : null}
                                    </div>
                                    <h4> {t('global.info_for_you')}</h4>
                                    <ol>
                                        <li>{t('global.info_for_you1')}</li>
                                        <li>{t('global.info_for_you2')}</li>
                                    </ol>

                                    {showReqJoin ? (
                                        <p class="mb-4">
                                            {t('global.not_member')},{' '}
                                            <a href="#" onClick={() => sendReqJoin()}>
                                                {t('global.send_join_req_now')}
                                            </a>
                                        </p>
                                    ) : null}

                                    <div class="d-grid">
                                        <button
                                            onClick={() => verifyEmail()}
                                            type="button"
                                            class={`btn btn-blue ${disableCSS}`}
                                            data-toggle="modal"
                                            data-target="#requestJoinEvent">
                                            {t('global.confirm')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openDialogVerifyOTP && (
                    <DialogVerifyOTP
                        navigation={props.history}
                        email={email}
                        evid={evid}
                        openDialogVerifyOTP={openDialogVerifyOTP}
                        onClose={() => setOpenDialogVerifyOTP(false)}
                    />
                )}
                {openDialogSendReq && (
                    <DialogSendReq
                        openDialogSendReq={openDialogSendReq}
                        onConfirm={() => requestJoin()}
                        evid={evid}
                        onClose={() => setOpenDialogSendReq(false)}
                    />
                )}
            </section>
        );
    })
);

export default VerifyEmail;
