import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
    OtherPeopleSay,
    Sponsors,
    ReasonJoin,
    SocialOrganization,
    MenuHeader,
    Introduction,
    News,
    EventFinish,
    Footer
} from './components';
import { Helmet } from 'react-helmet';

const LandingPage = inject('newsStore')(
    observer((props) => {
        return (
            <>
                <Helmet>
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css"
                    />
                    <script src="https://img.zing.vn/products/uprace/skin-2021/dist/main/main.js"></script>
                </Helmet>

                <Introduction />
                <ReasonJoin />
                <section className="section section--4 ">
                    <div className="container">
                        <SocialOrganization />
                        <Sponsors />
                    </div>
                </section>

                <section className="section section--5 ">
                    <EventFinish />
                </section>

                <section className="section section--5 ">
                    <OtherPeopleSay />
                </section>

                <section className="section section--6 ">
                    <News />
                </section>
            </>
        );
    })
);

export default LandingPage;
