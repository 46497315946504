import React, { useEffect, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AvatarItem from '../../../../../src/views/Common/AvatarItem';
import { HashLink } from 'react-router-hash-link';
import _ from 'lodash';
const queryString = require('query-string');
import { useTranslation } from 'react-i18next'

const CustomRouterLink = forwardRef((props, ref) => <RouterLink {...props} />);

let MENU;
const Topbar = inject(
    'authStore',
    'userStore',
    'globalStore',
    'teamStore',
    'userProfileStore',
    'eventStore'
)(
    observer((props) => {
        const { history } = props;
        const { mainTab, tabMainTop, doSetMainTab, doSetTabMainTop } = props.globalStore;
        let { doSetProfileUserID, doSetReportType, doSetSportType } = props.userProfileStore;
        const { userProfile, doClearUserProfile } = props.userStore;
        const { doClearUserTeam } = props.teamStore;
        const { doLogout } = props.authStore;
        const { appVersion, doGetAppVersion } = props.globalStore;
        const { currentEvent, doGetEventData } = props.eventStore;

        let eventID;
        const { t, i18n } = useTranslation(); 

        useEffect(() => {
            if (!appVersion) getAppVersion();
            //checkEventValid();
        }, []);

        async function checkEventValid() {
            if (currentEvent == null) {
                let paramsQuery = queryString.parse(history.props.location.search);
                if (_.isEmpty(paramsQuery)) history.props.history.push('/info?evid=20');
                eventID = paramsQuery.evid;
                const params = { evid: eventID };
                let result = await doGetEventData(params);
                if (result == null) history.props.history.push('/info?evid=20');
            } else eventID = currentEvent.evid;
            global.eventID = eventID;
        }

        MENU = [
            { id: 0, link: '/info?evid=' + (currentEvent && currentEvent.evid), title: t('global.info') },
            { id: 1, link: '/leaderboard?evid=' + (currentEvent && currentEvent.evid), title: t('leaderboard.leaderboard') },
            {
                id: 2,
                link:
                    userProfile != null && !_.isEmpty(userProfile)
                        ? '/you?evid=' + (currentEvent && currentEvent.evid)
                        : '/login',
                title: t('global.you')
            },
            {
                id: 3,
                link:
                    userProfile != null && !_.isEmpty(userProfile)
                        ? '/team?evid=' + (currentEvent && currentEvent.evid)
                        : '/login',
                title: t('global.team')
            }
        ];

        function getAppVersion() {
            let params = {
                data: {}
            };
            doGetAppVersion(params);
        }

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleLogout = () => {
            doClearUserProfile();
            doLogout();
            doClearUserTeam();
            setAnchorEl(null);
        };

        useEffect(() => {}, []);

        let MENU_TOP = [
            { id: 0, link: '/info?evid=20', title: t('global.event')  },
            //{ id: 1, link: '/about-us', title: 'Về chúng tôi' },
            { id: 2, link: userProfile != null && !_.isEmpty(userProfile) ? '/search' : '/login', title: t('global.search') }
        ];

        const handleChangeTab = (tab) => {
            doSetMainTab(tab);
        };

        const handleChangeTabMainTop = (tab) => {
            doSetTabMainTop(tab);
        };

        const goToProfile = () => {
            doSetReportType(0);
            doSetSportType(0);
            doSetProfileUserID(userProfile.uid);
            setAnchorEl(null);
        };

        function MenuItemMain(props) {
            const { category } = props;
            const cssClass = category.id == mainTab ? 'btn btn-primary active' : 'btn btn-primary inactive';

            return (
                <CustomRouterLink
                    to={category.link}
                    className={cssClass}
                    onClick={() => handleChangeTab(category.id)}
                    style={{ marginLeft: 20 }}>
                    {category.title}
                </CustomRouterLink>
            );
        }

        const closeMenu = () => {
            setAnchorEl(null);
        };

        function MenuItemTop(props) {
            const { category } = props;
            const cssClass = category.id == tabMainTop ? 'nav-item active' : 'nav-item';

            return (
                <li className={cssClass} onClick={() => handleChangeTabMainTop(category.id)}>
                    <HashLink to={category.link} className="nav-link">
                        {category.title}
                    </HashLink>
                </li>
            );
        }

        return (
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white">
                        <RouterLink to="/info?evid=20" className="navbar-brand">
                            <img src="../../img/logo/logo-blue.png" alt="logo-uprace" />
                        </RouterLink>
                        <button
                            className="navbar-toggler navbar-toggler-right"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navb">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Menu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}>
                            {userProfile ? (
                                <MenuItem onClick={goToProfile}>
                                    <RouterLink to={`/user-profile`} className="dropdown-item">
                                        <i className="fa fa-user"></i> {t('global.personal')}
                                    </RouterLink>
                                </MenuItem>
                            ) : null}

                            <MenuItem onClick={closeMenu}>
                                <RouterLink to="/settings" className="dropdown-item">
                                    <i className="fa fa-cog"></i> {t('global.setting')}
                                </RouterLink>
                            </MenuItem>

                            <MenuItem onClick={handleLogout}>
                                <RouterLink to="/info?evid=20" className="dropdown-item">
                                    <i className="fa fa-power-off"></i> {t('login.logout')}
                                </RouterLink>
                            </MenuItem>
                        </Menu>

                        <div className="collapse navbar-collapse" id="navb">
                            <ul className="navbar-nav mr-auto">
                                {MENU_TOP.map((cat) => (
                                    <MenuItemTop key={cat.id} category={cat} />
                                ))}
                            </ul>
                        </div>

                        <div className="wrapper-right-nav">
                            {userProfile != null && appVersion != null && !_.isEmpty(userProfile) ? (
                                <ul className="navbar-nav ">
                                    <li className="nav-item dropdown" onClick={handleOpenMenu}>
                                        <a
                                            className="nav-link dropdown-toggle nav-link-profile"
                                            id="navDropDownProfileLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <AvatarItem data={userProfile.ava} />
                                        </a>
                                        <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navDropDownProfileLink"></div>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="navbar-nav ">
                                    <li className="nav-item ">
                                        <div className="wrapper-btn">
                                            <a href="/login" className="btn btn-primary btn-log-in" role="button">
                                                {t('login.login')}
                                            </a>
                                            <a href="/register" className="btn btn-primary btn-register" role="button">
                                                {t('login.register')}
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </nav>
                </header>

                <section className="navigation-uprace">
                    <div className="wrapper-btn">
                        {MENU && MENU.map((cat) => <MenuItemMain key={cat.id} category={cat} />)}
                    </div>
                </section>
            </div>
        );
    })
);

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};

export default Topbar;
