import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getImageResource, stripedHtml, formatNumber, formatDistanceKM, getTypeName } from '../../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION, LEADERBOARD_TYPE } from '../../../../../helpers/ConstDefined';
import ManageMemberDialog from '../ManageMemberGroupDialog/ManageMemberGroupDialog';
import ChoiseLeaderDialog from './ChoiseLeaderDialog';
import LeaveConfirmDialog from './LeaveConfirmDialog';
import DeleteGroupDialog from './DeleteGroupDialog';
import ConfirmChangeLeaderDialog from './ConfirmChangeLeaderDialog';
import CancelJoinGroupDialog from './CancelJoinGroupDialog';
import CancelRequestCreateGroupDialog from './CancelRequestCreateGroupDialog';

let leaderObj = null;

const GroupHeader = inject(
    'groupStore',
    'userStore',
    'eventStore',
    'globalStore'
)(
    observer((props) => {
        let { data, eventID, teamID } = props;

        const { userProfile } = props.userStore;
        const { doSetGroupRequestID } = props.eventStore;
        const { doActionGroup, doGetListLeaderboard } = props.groupStore;
        const { appVersion, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const [openDialogManageMember, setOpenDialogManageMember] = React.useState(false);
        const [openDialogChoiseLeader, setOpenDialogChoiseLeader] = React.useState(false);
        const [openDialogLeaveConfirm, setOpenDialogLeaveConfirm] = React.useState(false);
        const [openDialogDeleteGroup, setOpenDialogDeleteGroup] = React.useState(false);
        const [openDialogCancelJoinGroup, setOpenDialogCancelJoinGroup] = React.useState(false);
        const [openDialogConfirmChangeLeader, setOpenDialogConfirmChangeLeader] = React.useState(false);
        const [openDialogCancelRequestCreateGroup, setOpenDialogCancelRequestCreateGroup] = React.useState(false);

        const { t } = useTranslation();

        const cssStatus =
            data.jsts == GROUP_JOIN_STATUS.REQUESTING || data.gejsts == GROUP_JOIN_STATUS.REQUESTING
                ? 'status wait'
                : 'status';

        function onEditGroup() {
            props.onEditGroup(data);
        }

        const onShowConfirmChangeLeader = (data) => {
            leaderObj = data;
            setOpenDialogConfirmChangeLeader(true);
        };

        //call khi close dialog manage member
        function getLeaderboardMember() {
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                type: LEADERBOARD_TYPE.USER_GROUP,
                value: data.grid,
            };
            doGetListLeaderboard(params);
        }

        const onCloseDialogManageMember = () => {
            setOpenDialogManageMember(false);
            getLeaderboardMember();
        }

        async function onJoinGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.grid,
                action: GROUP_ACTION.USER_REQUEST_JOIN
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                doSetGroupRequestID(data.grid);
                data.jsts = GROUP_JOIN_STATUS.REQUESTING;
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const handleClickStatus = () => {
            if (data.jsts == GROUP_JOIN_STATUS.REQUESTING) {
                setOpenDialogCancelJoinGroup(true);
            } else if (data.gejsts == GROUP_JOIN_STATUS.REQUESTING) {
                setOpenDialogCancelRequestCreateGroup(true);
            } else if (data.jsts == GROUP_JOIN_STATUS.JOINED || data.jsts == GROUP_JOIN_STATUS.ADMIN) {
                setOpenDialogLeaveConfirm(true);
            }
        };

        function ButtonJoin() {
            if (data.jsts == GROUP_JOIN_STATUS.NONE) {
                return (
                    <button className="btn-28 blue" onClick={() => onJoinGroup()}>
                        {t('global.join_group')}
                    </button>
                );
            } else if (data.jsts == GROUP_JOIN_STATUS.REQUESTING) {
                return (
                    <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                        {t('global.wait_approve')}
                    </a>
                );
            } else if (data.jsts == GROUP_JOIN_STATUS.JOINED || data.jsts == GROUP_JOIN_STATUS.ADMIN) {
                //nhóm được tao và đang đợi duyệt
                if (data.gejsts == GROUP_JOIN_STATUS.REQUESTING)
                    return (
                        <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                            {t('global.wait_approve_group')}
                        </a>
                    );
                else
                    return (
                        <a href="javascript:void(0);" className={cssStatus} onClick={() => handleClickStatus()}>
                            {t('global.ready_join')}
                        </a>
                    );
            }
        }

        return (
            <div className="group-details-wbox member-info-box has-btn">
                <div className="member-info">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true)} alt="" />
                    </p>
                    <div className="details">
                        <p className="g-name">{data.name}</p>
                        <p className="m-name">{data.ownm}</p>
                        {userProfile ? <ButtonJoin /> : null}
                    </div>
                    {userProfile && data.owner == userProfile.uid ? (
                        <div className="btns-row" style={{ display: 'flex' }}>
                            {data.gejsts == GROUP_JOIN_STATUS.JOINED ? (
                                <div className="members-manage-btn">
                                    {data.rmem > 0 ? <span className="num">{data.rmem}</span> : null}
                                    <button
                                        className="btn-40 blue-light"
                                        onClick={() => setOpenDialogManageMember(true)}>
                                        {t('global.manage_member')}
                                    </button>
                                </div>
                            ) : null}

                            <div className="uiselector setting-more">
                                <p
                                    className="setting-more-btn"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"></p>
                                <ul className="setting-more-list" aria-labelledby="list-server-choose">
                                    <li onClick={() => onEditGroup()}>{t('global.edit_info')}</li>

                                    {data.gejsts == GROUP_JOIN_STATUS.JOINED ? (
                                        <>
                                            <li onClick={() => setOpenDialogLeaveConfirm(true)}>
                                                {t('global.leave_group')}
                                            </li>
                                            <li onClick={() => setOpenDialogDeleteGroup(true)}>
                                                {t('global.delete_group')}
                                            </li>
                                        </>
                                    ) : null}

                                    {data.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                                        <li onClick={() => setOpenDialogCancelRequestCreateGroup(true)}>
                                            {t('global.cancel_create_group')}
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>
                <ul className="statistical">
                    <li className="members">
                        <p className="num">{formatNumber(data.mem)}</p>
                        <p className="lb">{t('global.member1')}</p>
                    </li>
                    <li className="km">
                        <p className="num">{formatDistanceKM(data.dis)}</p>
                        <p className="lb">{t('global.total_km1')}</p>
                    </li>
                </ul>
                <h2 className="title">{t('global.intro')}</h2>
                <div className="desc">{data.desc}</div>

                {userProfile && data.gejsts == GROUP_JOIN_STATUS.REQUESTING ? (
                    <>
                        <div className="notify-box red">
                            {t('team.group.wait_approve')}
                        </div>

                        <CancelRequestCreateGroupDialog
                            openDialogCancelRequestCreateGroup={openDialogCancelRequestCreateGroup}
                            data={data}
                            onClose={() => setOpenDialogCancelRequestCreateGroup(false)}
                            eventID={eventID}
                            teamID={teamID}
                        />
                    </>
                ) : null}

                {userProfile && data.owner == userProfile.uid && data.gejsts == GROUP_JOIN_STATUS.JOINED ? (
                    <>
                        <ManageMemberDialog
                            data={data}
                            openDialogManageMember={openDialogManageMember}
                            eventID={eventID}
                            onClose={() => onCloseDialogManageMember()}
                            teamID={teamID}
                        />
                        <DeleteGroupDialog
                            openDialogDeleteGroup={openDialogDeleteGroup}
                            data={data}
                            onClose={() => setOpenDialogDeleteGroup(false)}
                            eventID={eventID}
                            teamID={teamID}
                        />
                    </>
                ) : null}

                {userProfile && data.jsts == GROUP_JOIN_STATUS.JOINED || data.jsts == GROUP_JOIN_STATUS.ADMIN ? (
                    <LeaveConfirmDialog
                        choiseLeader={() => setOpenDialogChoiseLeader(true)}
                        isLeader={data.owner == userProfile.uid}
                        openDialogLeaveConfirm={openDialogLeaveConfirm}
                        data={data}
                        onClose={() => setOpenDialogLeaveConfirm(false)}
                        eventID={eventID}
                        teamID={teamID}
                    />
                ) : null}

                {userProfile && data.owner == userProfile.uid && data.mem > 1 ? (
                    <ChoiseLeaderDialog
                        data={data}
                        onClose={() => setOpenDialogChoiseLeader(false)}
                        onShowConfirm={onShowConfirmChangeLeader}
                        openDialogChoiseLeader={openDialogChoiseLeader}
                        eventID={eventID}
                        teamID={teamID}
                    />
                ) : null}

                {leaderObj ? (
                    <ConfirmChangeLeaderDialog
                        data={leaderObj}
                        onClose={() => setOpenDialogConfirmChangeLeader(false)}
                        openDialogConfirmChangeLeader={openDialogConfirmChangeLeader}
                        eventID={eventID}
                        teamID={teamID}
                    />
                ) : null}

                {userProfile && data.jsts == GROUP_JOIN_STATUS.REQUESTING ? (
                    <CancelJoinGroupDialog
                        data={data}
                        openDialogCancelJoinGroup={openDialogCancelJoinGroup}
                        onClose={() => setOpenDialogCancelJoinGroup(false)}
                        eventID={eventID}
                        teamID={teamID}
                    />
                ) : null}
            </div>
        );
    })
);

export default GroupHeader;
