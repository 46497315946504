import React, { useEffect, forwardRef, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import RankItem from './RankItem';
import RankNavigation from './RankNavigation';
import { LEADERBOARD_TYPE } from '../../../../helpers/ConstDefined';
import { stripedHtml, formatDate } from '../../../../utils';
import DatePicker from 'react-datepicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DialogViewTeam } from '../../../UserTeam/components';

const RankTable = inject(
    'authStore',
    'leaderboardStore',
    'eventStore',
    'globalStore',
    'teamStore',
    'userStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { eventID } = props;
            const {
                loading,
                totalRecordTeam,
                listLeaderboardTeam,
                naviTab,
                pageIndexTeam,
                doIncreasePageIndexTeam,
                doSetPageIndexTeam,
                doGetListLeaderboardTeam
            } = props.leaderboardStore;
            const { userTeam } = props.teamStore;
            const { userProfile } = props.userStore;
            const { appVersion } = props.globalStore;
            const { currentTeam, doGetTeamDetail } = props.teamStore;
            const { navigation } = props;

            const [openDialogViewTeam, setOpenDialogViewTeam] = React.useState(false);
            const [] = React.useState(false);
            const [] = React.useState(false);
            const [, setOpenDialogFeedUser] = React.useState(false);
            const [, setOpenDialogChallengeUser] = React.useState(false);

            const [name, setName] = React.useState('');
            const [day, setDay] = React.useState(null);
            const [dayText, setDayText] = React.useState(formatDate('DD/MM', new Date()));
            const showClearName = name && name.length > 0;


            useEffect(() => {
                if (day != null) searchLeaderboard(); // auto search when day change
            }, [day]);

            const childRef = useRef();

            const onChangeTab = () => {
                //reset filter when switch tab
                setName('');
                setDay(null);
                doSetPageIndexTeam(1); //reset page index
            };

            const handleOpenDialogViewTeam = (flag) => {
                setOpenDialogViewTeam(flag);
            };

            const isShowLoadMore = listLeaderboardTeam == null ? false : listLeaderboardTeam.length < totalRecordTeam;

            const handleChangeName = (val) => {
                setName(val);
            };

            const handleChangeDate = (val) => {
                const format = 'YYYYMMDD';
                const day = formatDate(format, val);
                const dayText = formatDate('DD/MM', val);
                setDayText(dayText);
                setDay(day);
            };

            function getLeaderboardMore() {
                doIncreasePageIndexTeam();
                const data = {
                    from: pageIndexTeam * appVersion.pgsz,
                    size: appVersion.pgsz,
                    evid: eventID,
                    type: LEADERBOARD_TYPE.LEAGUE,
                    day,
                    name,
                    value: naviTab
                };
                doGetListLeaderboardTeam(data, true);
            }

            function handleResetSearch() {
                onChangeTab();
                const data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                    value: naviTab
                };
                doGetListLeaderboardTeam(data);
            }

            async function getTeamDetail(team) {
                if (userProfile != null) {
                    let data = {
                        tmid: team.id,
                        uid: (userProfile && userProfile.uid) || null
                    };
                    let result = await doGetTeamDetail(data);
                    if (result) {
                        handleOpenDialogViewTeam(true);
                        childRef.current.initData(eventID);
                    }
                } else {
                    navigation.push('/login');
                }
            }

            function searchLeaderboard() {
                doSetPageIndexTeam(1);
                const data = {
                    evid: eventID,
                    type: LEADERBOARD_TYPE.LEAGUE, // 0: league, 1: team, 2: fund
                    name,
                    day,
                    value: naviTab
                };
                doGetListLeaderboardTeam(data, null, true);
            }

            const CustomInputDate = forwardRef(({ onClick }) => (
                <button className="more-down" onClick={onClick}>
                    <i className="fa fa-angle-down" size="10px"></i>
                </button>
            ));

            let sportType;
            if (naviTab == 1) sportType = 'Doanh nghiệp';
            else if (naviTab == 2) sportType = 'Câu lạc bộ';
            else if (naviTab == 3) sportType = 'Trường học';
            const placeholder = `Tìm đội trong bảng ${sportType}`;


            function LoadMore() {
                if (isShowLoadMore) {
                    if (loading)
                        return (
                            <div className="load-more">
                                <CircularProgress disableShrink />
                            </div>
                        );
                    else
                        return (
                            <>
                                <div className="load-more">
                                    <button className="btn btn-primary" onClick={() => getLeaderboardMore()}>
                                        Xem thêm
                                    </button>
                                </div>
                            </>
                        );
                } else return null;
            }


            const handleKeyDown = (e) => {
                if (e.key === 'Enter') {
                    searchLeaderboard();
                }
            };

            return (
                <div className="container">
                    <div className="wrapper-table">
                        <RankNavigation onChangeTab={onChangeTab} eventID={props.eventID} />

                        <div className="tab-content nav-content">
                            <div
                                className="tab-pane wrapper-list-member fade show active"
                                id="business"
                                role="tabpanel"
                                aria-labelledby="business-tab">
                                <h3 className="title">
                                    Có {totalRecordTeam} đội trong bảng {sportType}
                                </h3>
                                <div className="wrapper-search">
                                    <div className="form-search-custom" id="search-form">
                                        <div className="input-group input-group-custom">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <img src="../../../img/icon-search.png" alt="" />
                                                </span>
                                            </div>
                                            <input
                                                id="search"
                                                type="text"
                                                className="form-control form-search"
                                                value={name}
                                                onKeyDown={handleKeyDown}
                                                onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                                placeholder={placeholder}
                                            />
                                            {showClearName ? (
                                                <img
                                                    src="../../../img/register/close.svg"
                                                    className="close-text"
                                                    onClick={() => handleResetSearch()}
                                                />
                                            ) : null}
                                        </div>
                                        <button
                                            className="btn btn-primary btn-search btn-search-custom"
                                            onClick={() => searchLeaderboard()}>
                                            Tìm kiếm
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive custom-table custom-table-list-team  ">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <p>Hạng</p>
                                                </th>
                                                <th></th>
                                                <th>
                                                    <p>Đội</p>
                                                </th>
                                                <th>
                                                    <p className="d-inline-block">{dayText} (Km) </p>
                                                    <DatePicker
                                                        onChange={handleChangeDate}
                                                        customInput={<CustomInputDate />}
                                                    />
                                                </th>
                                                <th>
                                                    <p>Tổng (Km)</p>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {listLeaderboardTeam != null &&
                                                listLeaderboardTeam.map((item) => (
                                                    <RankItem
                                                        onViewDetail={() => getTeamDetail(item)}
                                                        key={item.id}
                                                        data={toJS(item)}></RankItem>
                                                ))}
                                        </tbody>
                                    </table>
                                    {listLeaderboardTeam && listLeaderboardTeam.length == 0 ? (
                                        <p style={{ textAlign: 'center' }}>Không tìm thấy Đội theo yêu cầu</p>
                                    ) : null}
                                </div>
                                <LoadMore />
                            </div>
                        </div>
                    </div>

                    {currentTeam != null ? (
                        <DialogViewTeam
                            openDialogViewTeam={openDialogViewTeam}
                            pageIndexTeam={0}
                            navigation={navigation}
                            ref={childRef}
                            onJoinTeamSuccess={props.onJoinTeamSuccess}
                            showJoinTeam={false}
                            onClose={() => handleOpenDialogViewTeam(false)}></DialogViewTeam>
                    ) : null}
                </div>
            );
        })
    )
);

export default RankTable;
