import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const PeopleSayItem = inject(
    'authStore',
    'globalStore'
)(
    observer((props) => {
        const { data } = props;
        const { language } = props.globalStore;

        useEffect(() => {});

        return (
            <div className="swiper-slide">
                <div className="runner">
                    <p className="runner--comment">{data[`desc_${language}`]}</p>
                    <div className="flex align-item-center">
                        {data.avatar != "" ? <img className="runner--avatar lazyload" data-src={data.avatar} alt="" /> : null}
                        <div className="text-info">
                            <p className="runner--name">{data[`name_${language}`]}</p>
                            <p className="runner--pos">{data[`job_${language}`]}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default PeopleSayItem;
