import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource, stripedHtml } from '../../../../../utils';
import _ from 'lodash';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

import InviteFriendItem from './InviteFriendItem';
import RemoveFriendItem from './RemoveFriendItem';

const InviteFriendDialog = inject(
    'globalStore',
    'userProfileStore',
    'userStore',
    'inviteFriendStore'
)(
    observer((props) => {
        const { teamID, eventID } = props;
        const { userProfile, listUserSearch, doSearchUser } = props.userStore;
        const { listFollower, doGetListUserFollower } = props.userProfileStore;
        const { listMemberTemp, doInviteMultiFriends, doClearAllMember } = props.inviteFriendStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [name, setName] = React.useState('');
        const showClearName = name && name.length > 0;
        const cssButton = listMemberTemp.length == 0 ? 'btn-44 blue inactive' : 'btn-44 blue';

        const { t } = useTranslation();

        useEffect(() => {
            doClearAllMember();
            getListUserFollower(name);
            getListUserSuggest(name);
        }, [eventID]);

        async function doInviteFriends() {
            let listID = [];
            _.forEach(listMemberTemp, (item) => {
                listID.push(item.uid);
            });

            const params = {
                uid: userProfile && userProfile.uid,
                tmid: teamID,
                evid: eventID,
                uids: listID
            };
            let result = await doInviteMultiFriends(params);
            if (result != null) {
                setMessageSnackbar(t('team.invitedl.invite_succ'));
                setSnackbarType('success');
            } else {
                setMessageSnackbar(t('team.invitedl.invite_fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        function getListUserFollower(name) {
            let params = {
                uid: userProfile && userProfile.uid,
                feid: userProfile && userProfile.uid,
                name
            };
            doGetListUserFollower(params);
        }

        function getListUserSuggest(name) {
            let params = {
                uid: userProfile && userProfile.uid,
                feid: userProfile && -userProfile.uid,
                name
            };
            doSearchUser(params);
        }

        const handleChangeName = (val) => {
            setName(val);
        };
        const handleClearName = () => {
            setName('');
            getListUserFollower('');
            getListUserSuggest('');
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                getListUserFollower(name);
                getListUserSuggest(name);
            }
        };

        return (
            <div
                className="uprace-popup has-title"
                id="team-invite-friends-2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel">
                <div className="popup-dialog" role="document">
                    <div className="popup-content w800">
                        <button type="button" className="popup-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"></span>
                        </button>
                        <h2 className="popup-title">{t('global.invite_friend')}</h2>
                        <div className="popup-body">
                            <div className="popup-invite-friends">
                                <div className="search-box active">
                                    {showClearName ? <button className="del-btn" onClick={() => handleClearName()}></button> : null}
                                    <input
                                        type="text"
                                        placeholder={t('global.search_by_name')}
                                        value={name}
                                        onKeyDown={handleKeyDown}
                                        onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                    />
                                </div>
                                <div className="friends-box clearfix">
                                    <div className="friends-right">
                                        <div className="friends-scroll">
                                            <h3 className="friends-title">{t('team.invitedl.friend_seleted')}</h3>
                                            <ul className="friends-list">
                                                {listMemberTemp &&
                                                    listMemberTemp.map((item) => {
                                                        return <RemoveFriendItem key={item.uid} data={item} />;
                                                    })}
                                            </ul>
                                            {!listMemberTemp || listMemberTemp.length == 0 ? (
                                                <div className="friends-empty">
                                                    {t('team.invitedl.friend_seleted_empty')}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="friends-left">
                                        <div className="friends-scroll">
                                            <h3 className="friends-title">{t('global.follower')}</h3>
                                            <ul className="friends-list">
                                                {listFollower &&
                                                    listFollower.map((item) => {
                                                        return <InviteFriendItem key={item.uid} data={item} />;
                                                    })}
                                            </ul>
                                            <h3 className="friends-title">{t('global.suggest')}</h3>
                                            <ul className="friends-list">
                                                {listUserSearch &&
                                                    listUserSearch.map((item) => {
                                                        return <InviteFriendItem key={item.uid} data={item} />;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="popup-btns">
                                    <div className="btn-inside">
                                        <button className={cssButton} onClick={() => doInviteFriends()}>
                                            {t('global.send_invite')}
                                        </button>
                                        <button className="btn-44 cancel" data-dismiss="modal">
                                            {t('global.destroy')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default InviteFriendDialog;
