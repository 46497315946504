import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../../../../../utils';
import { GROUP_JOIN_STATUS } from '../../../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import GroupItem from './GroupItem';
import GroupJoinItem from './GroupJoinItem';
import DeleteGroupDialog from '../GroupDetail/DeleteGroupDialog';
import _ from 'lodash';

let groupObj = null;

const GroupList = inject(
    'globalStore',
    'groupStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, teamID } = props;
        const {
            loadingGroup,
            totalGroupRq,
            totalGroupJoin,
            listGroupRequest,
            listGroupJoin,
            doGetListGroupRequest,
            doGetListGroupJoin,
            doApproveAll
        } = props.groupStore;
        const { userProfile } = props.userStore;

        const isShowLoadMore = listGroupJoin == null ? false : listGroupJoin.length < totalGroupJoin;
        const isShowLoadMoreRq = listGroupRequest == null ? false : listGroupRequest.length < totalGroupRq;

        const [openDialogDeleteGroup, setOpenDialogDeleteGroup] = React.useState(false);
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {
            getListGroupJoin();
        }, []);

        const { t } = useTranslation();

        const getListGroupRequest = () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                gejsts: GROUP_JOIN_STATUS.REQUESTING //Đang chờ duyệt
            };
            doGetListGroupRequest(params);
        };

        const getListGroupJoin = () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                gejsts: GROUP_JOIN_STATUS.JOINED //Đã tham gia
            };
            doGetListGroupJoin(params);
        };

        async function onDeleteGroup(data) {
            groupObj = data;
            setOpenDialogDeleteGroup(true);
        }

        function getListIDGroupRequest() {
            let list = [];
            _.forEach(listGroupRequest, (item) => {
                list.push(item.grid);
            });
            return list;
        }

        async function onApproveAllGroup() {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                grids: getListIDGroupRequest()
            };
            let result = await doApproveAll(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                getListGroupJoin();
                getListGroupRequest();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        const getGroupJoinListMore = () => {
            props.onLoadMoreGroupJoin();
        };

        const getGroupRqListMore = () => {
            props.onLoadMoreGroupRq();
        };

        function LoadMoreRq() {
            if (isShowLoadMoreRq) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupRqListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingGroup)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getGroupJoinListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        function ApproveAllButton() {
            if (totalGroupRq > 0) {
                if (loadingGroup) return <button className="btn-44 blue loading"></button>;
                else
                    return (
                        <>
                            <button className="btn-44 blue" onClick={() => onApproveAllGroup()}>
                                {t('team.managegroup.approve_all')}
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div className="manage-groups">
                <div className="manage-wbox waiting">
                    <h2 className="manage-title-large">
                        {t('team.managegroup.group_not_approve')}{' '}
                        <span className="num">({formatNumber(totalGroupRq, true)})</span>
                    </h2>
                    <ul className="manage-list">
                        {listGroupRequest &&
                            listGroupRequest.map((item) => {
                                return <GroupItem data={item} key={item.id} eventID={eventID} />;
                            })}
                    </ul>
                    <LoadMoreRq />

                    <div className="btn-row">
                        <ApproveAllButton />
                    </div>
                </div>
                <div className="manage-wbox joined">
                    <h2 className="manage-title-large">
                        {t('team.managegroup.group_join')}{' '}
                        <span className="num">({formatNumber(totalGroupJoin, true)})</span>
                    </h2>
                    <ul className="manage-list">
                        {listGroupJoin &&
                            listGroupJoin.map((item) => {
                                return <GroupJoinItem data={item} key={item.id} onDeleteGroup={onDeleteGroup} />;
                            })}
                    </ul>
                    <LoadMore />
                </div>

                {groupObj ? (
                    <DeleteGroupDialog
                        openDialogDeleteGroup={openDialogDeleteGroup}
                        data={groupObj}
                        onClose={() => setOpenDialogDeleteGroup(false)}
                        eventID={eventID}
                        teamID={teamID}
                    />
                ) : null}
            </div>
        );
    })
);

export default GroupList;
