import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { humanTime } from '../../helpers/ConvertHelper';
import { getImageResource, stripedHtml, isJWTValid, replaceURLs } from '../../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API } from '../../helpers/api/APIDefined';
import { USER_ROLE } from '../../data/master';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SOCIAL_TYPE, SOCIAL_TAB, LIKE_TYPE } from '../../helpers/ConstDefined';
import { Carousel } from 'react-responsive-carousel';
import { DialogSocial } from '../../views/YourInfo/components';
const queryString = require('query-string');

const PostDetail = inject(
    'postStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { userProfile } = props.userStore;
        const { currentPost, doLike, doGetDetailPostInfo } = props.postStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);
        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE);

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.psid || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);
        let postID = stripedHtml(paramsQuery.psid);

        const { t } = useTranslation();

        async function getDetailPost() {
            const params = {
                uid: userProfile && userProfile.uid,
                psid: postID
            };
            let result = await doGetDetailPostInfo(params);
            if (!result) {
                props.history.push('/');
            }
        }

        useEffect(() => {
            if (userProfile) getDetailPost();
        }, [userProfile]);

        useEffect(() => {
            if (!isJWTValid()) props.history.push('/login');
        }, []);

        function LikeStatus() {
            let status;
            if (currentPost.lkcnt == 0) return null;

            if (currentPost.lktp != 0) {
                if (currentPost.lkcnt == 1) return t('global.only_you_like_stt');
                else {
                    status = t('global.you_other_like_stt').replace('[like]', currentPost.lkcnt - 1);
                    return status;
                }
            } else return `${currentPost.lkcnt} ${t('global.other_like_stt')}`;
        }

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        function CommentItem(props) {
            const { data } = props;
            return (
                <div className="mess-block">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div className="mess-details">
                        <div className="mess-box">
                            <p className="name">{data.name}</p>
                            <p className="mess">{data.cmt}</p>
                        </div>
                        <div className="d-row">
                            <p className="date">{humanTime(data.cmtm)}</p>
                        </div>
                    </div>
                </div>
            );
        }

        const onLikePost = async (data) => {
            const lktp = data.lktp == LIKE_TYPE.NONE ? LIKE_TYPE.LIKE : LIKE_TYPE.NONE;
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid,
                lktp
            };
            let result = await doLike(params, SOCIAL_TYPE.POST);
            if (result && result != false) {
                data.lkls = result.lkls;
                data.lktp = lktp;
                data.lkcnt = result.lkcnt;
            }
        };

        function RoleUser(role) {
            const obj = _.find(USER_ROLE, (item) => {
                return item.id == role;
            });
            return t(obj.title);
        }

        const onViewLike = () => {
            setTabSocial(SOCIAL_TAB.LIKE);
            setOpenDialogSocial(true);
        };

        const onViewComment = () => {
            setTabSocial(SOCIAL_TAB.COMMENT);
            setOpenDialogSocial(true);
        };

        if (currentPost != null)
            return (
                <div className="posts-wrap">
                    <ul className="posts-list" style={{ paddingTop: 100, paddingBottom: 100 }}>
                        <li>
                            <div className="account">
                                <p className="avatar">
                                    <img src={getImageResource(currentPost.ava, true, true)} alt="" />
                                </p>
                                <div className="desc">
                                    <p className="name">{currentPost.name}</p>
                                    <div className="d-row">
                                        <p className="tag">{RoleUser(currentPost.tmrole)}</p>
                                        <p className="date">{humanTime(currentPost.crtm)}</p>
                                        {currentPost.pin == 1 ? <button className="stick"></button> : null}
                                    </div>
                                </div>
                            </div>
                            <h2 className="title">{currentPost.title}</h2>
                            <div className="details">
                                {currentPost.desc ? (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: replaceURLs(currentPost.desc).replace(/\n/g, '<br />')
                                        }}></p>
                                ) : null}

                                {currentPost.photos && currentPost.photos.length > 0 ? (
                                    <div className="slide-block">
                                        <ul className="slide">
                                            <Carousel
                                                autoPlay
                                                showArrows={false}
                                                showThumbs={false}
                                                infiniteLoop={true}>
                                                {currentPost.photos.map((item, index) => {
                                                    if (item != '')
                                                        return (
                                                            <li
                                                                className="img-slide"
                                                                key={index}
                                                                style={{ display: 'block' }}>
                                                                <img
                                                                    src={getImageResource(item, false, false, true)}
                                                                    alt=""
                                                                />
                                                            </li>
                                                        );
                                                    else return null;
                                                })}
                                            </Carousel>
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                            <div className="account-group">
                                <ul className="avatars">
                                    {currentPost.lkls.map((item) => {
                                        return (
                                            <li key={item.uid}>
                                                <img src={getImageResource(item.ava, true, true)} alt="" />
                                            </li>
                                        );
                                    })}
                                </ul>
                                <p className="txt" onClick={() => onViewLike()}>
                                    {currentPost.lkcnt ? <LikeStatus /> : null}
                                </p>
                            </div>
                            <div className="action-row">
                                <ul>
                                    <li>
                                        <button
                                            className={currentPost.lktp != 0 ? 'like active' : 'like'}
                                            onClick={() => onLikePost(currentPost)}></button>
                                    </li>
                                    <li>
                                        <button className="mess" onClick={() => onViewComment()}>
                                            <span className="num">{currentPost.cmcnt}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <CopyToClipboard
                                            text={
                                                API.GLOBAL.SITE_DOMAIN +
                                                `post-detail?evid=${eventID}&psid=${currentPost.psid}`
                                            }
                                            onCopy={() => onCopySuccess()}>
                                            <button class="share"></button>
                                        </CopyToClipboard>
                                    </li>
                                </ul>
                            </div>
                            {currentPost.cmls.map((item) => {
                                return <CommentItem data={item} key={item.cmid} />;
                            })}
                        </li>
                    </ul>

                    {openDialogSocial && currentPost ? (
                        <DialogSocial
                            data={currentPost}
                            type={SOCIAL_TYPE.POST}
                            tab={tabSocial}
                            openDialogSocial={openDialogSocial}
                            onClose={() => setOpenDialogSocial(false)}></DialogSocial>
                    ) : null}
                </div>
            );
    })
);

export default PostDetail;
