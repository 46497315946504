import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatDistanceKM, getImageResource } from '../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../helpers/ConstDefined';

const GroupRankItem = inject(
    'groupStore',
    'userStore',
    'globalStore',
    'teamManageStore',
    'eventStore'
)(
    observer((props) => {
        const { data, rank, showAction, navigation } = props;
        const { currentEvent, doSetGroupRequestID } = props.eventStore;
        const { doGetGroupDetail, doActionGroup } = props.groupStore;
        const { doSetFunc } = props.teamManageStore;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        async function getGroupDetail() {
            if (userProfile) navigation.push(`group-detail?grid=${data.id}&evid=${currentEvent.evid}`);
            else navigation.push(`/login`);
        }

        const cssRankArrow = data.diff > 0 ? 'num up' : 'num down';
        const cssRankNum = rank < 4 ? 'level num' + rank : 'level';
        return (
            <li>
                <a href="javascript:void(0);">
                    <div class="level" onClick={() => getGroupDetail()}>
                        {data.rank}
                    </div>
                    <div class="logo" onClick={() => getGroupDetail()}>
                        <img src={getImageResource(data.ava, true)} alt="" />
                    </div>
                    <div class="name-box" onClick={() => getGroupDetail()}>
                        <p class="name">{data.name}</p>
                        {data.diff != 0 ? <p class={cssRankArrow}>{data.diff}</p> : null}
                    </div>
                    <div class="date">{formatDistanceKM(data.ddis, true)}</div>
                    <div class="sum">{formatDistanceKM(data.dis, true)}</div>
                </a>
            </li>
        );
    })
);

export default GroupRankItem;
