import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEY } from '../../../../helpers/api/APIDefined';
import { Link as RouterLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getImageResource, stripedHtml } from 'utils';
import { Redirect } from 'react-router';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const queryString = require('query-string');

const MENU_LANGUAGE = [
    {
        id: 'en',
        title: 'EN'
    },
    {
        id: 'vi',
        title: 'VI'
    }
];

const Topbar = inject(
    'authStore',
    'globalStore',
    'newsStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { history } = props;
        const { language, doChangeLanguage } = props.globalStore;

        const { t, i18n } = useTranslation();

        const onChangeLanguage = (data) => {
            i18n.changeLanguage(data.id);
            localStorage.setItem(KEY.USER_LANGUAGE, data.id);
            doChangeLanguage(data.id);
        };

        function MenuLanguage(props) {
            const { data } = props;
            const css = data.id == language ? 'btn-choose-language active' : 'btn-choose-language';
            return (
                <a className={css} style={{ cursor: 'pointer' }} onClick={() => onChangeLanguage(data)}>
                    {data.title}
                </a>
            );
        }

        return (
            <div className="outer outer--floating">
                <div className="inner">
                    <section id="floating" className="section section--floating floating">
                        <div className="section__content floating__content">
                            <div className="nav-top setPosTop ">
                                <div className="container-top">
                                    <a className="mobile btn-hambuger">
                                        <i className="icon icon-hambuger"></i>
                                        <span className="hidden">hambuger</span>
                                    </a>
                                    <a className="logo" href={`/event?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`}>
                                        <img className="lazyload desktop" data-src="images/logo.png" alt="" />
                                        <img className="lazyload mobile" data-src="images/logo-mb.png" alt="" />
                                    </a>

                                    <ul className="list-nav">
                                        <li className="language">
                                            {MENU_LANGUAGE.map((item) => {
                                                return <MenuLanguage data={item} key={item.id} />;
                                            })}
                                        </li>
                                    </ul>
                                    <div className="user-function">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    })
);

export default Topbar;
