import React, {useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import { ACTIVITY_TYPE } from '../../../../../helpers/ConstDefined';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

const DialogComplained = inject(
    'globalStore',
    'teamStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { openDialogComplained, type } = props;

        const [title, setTitle] = React.useState('');
        const [desc, setDesc] = React.useState('');

        const { t } = useTranslation();

        useEffect(() => {
            initDialog();
        }, [openDialogComplained, type]);

        const initDialog = () => {
            if (type == ACTIVITY_TYPE.COMPLAIN) {
                setTitle(t('global.complain_act'));
                setDesc(t('global.complained_act'));
            } else {
                setTitle(t('global.report_act'));
                setDesc(t('global.reported_act'));
            }
        };

        return (
            <Dialog open={openDialogComplained}>
                <div
                    class="uprace-popup has-title in"
                    id="complained"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w600">
                            <button
                                type="button"
                                class="popup-close"
                                onClick={() => props.onClose()}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 class="popup-title">{title}</h2>
                            <div class="popup-body">
                                <div class="popup-complain">
                                    <div class="complain-scroll">
                                        <div class="complain-notify">
                                            <p class="img">
                                                <img src="../../../images/imgs/checked.png" alt="" />
                                            </p>
                                            <div class="note">
                                                <p>{desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="complain-btn col-1" onClick={() => props.onClose()}>
                                        <button class="btn-44 blue">{t('global.close')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogComplained;
