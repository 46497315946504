import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { EVENT_TYPE } from '../../../helpers/ConstDefined';
import { formatNumber, formatDistanceKMFloor, stripedHtml, formatDate } from '../../../utils';
import { Achievement, SocialOrganizationEvent, SocialOrganizationUser, Leaderboard } from './components';
import CountDown from './CountDown';
import RulesShort from './RulesShort';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const InfoEvent = inject(
    'eventStore',
    'newsStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { currentEvent } = props.eventStore;
        const { doSetMainTab, doSetTabMainTop } = props.globalStore;
        const { userProfile } = props.userStore;

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let eventID = stripedHtml(paramsQuery.evid);

        const { t } = useTranslation();

        useEffect(() => {
            if (currentEvent) {
                if (currentEvent.evtp != EVENT_TYPE.SUPPER_CHALLENGE)
                    props.history.push(`/event-detail?evid=${eventID}`);
                //return <Redirect to={`/event-detail?evid=${eventID.evid}`}></Redirect>
            }
        }, [currentEvent]);

        useEffect(() => {
            doSetMainTab(0);
            doSetTabMainTop(0);
            window.scrollTo(0, 0);
        }, []);

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        return (
            <div class="info-page">
                {!userProfile ? (
                    <div class="info-notify-box" style={{ display: 'block' }}>
                        <p class="txt-1">{t('global.not_login_join_title')}</p>
                        <p class="txt-2">{t('global.not_login_join_txt')}</p>
                        <Link to={'/login'}>
                            <div class="btn-row">
                                <button class="btn-40 blue">{t('global.join_team_now')}</button>
                            </div>
                        </Link>
                    </div>
                ) : null}

                <div class="l-side">
                    {currentEvent ? <CountDown data={currentEvent} /> : null}
                    <Achievement />
                    {currentEvent && currentEvent.fuid == 0 && userProfile ? <SocialOrganizationUser /> : null}
                    <Leaderboard eventID={eventID} />
                </div>
                <div class="r-side">
                    {currentEvent ? <RulesShort /> : null}
                    {currentEvent ? <SocialOrganizationEvent data={currentEvent} /> : null}
                </div>
            </div>
        );
    })
);

export default InfoEvent;
