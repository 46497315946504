import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MemberJoinList from './MemberJoinList';
import MemberRequestList from './MemberRequestList';
import TabHeader from './TabHeader';
import { EVENT_PUBlIC_TYPE } from 'helpers/ConstDefined';

const ManageMember = inject('userStore')(
    observer((props) => {
        const { eventData } = props;
        const { userProfile } = props.userStore;

        const [tab, setTab] = useState(eventData.jpri != EVENT_PUBlIC_TYPE.PUBLIC ? 0 : 1);

        useEffect(() => {}, []);
        const { t } = useTranslation();

        const onChangeTab = (val) => {
            setTab(val);
        };

        return (
            <div class="manage-page">
                <div class="manage-wrap">
                    <div class="manage-box">
                        <div class="manage-box__header">
                            <h4 class="title">{t('global.manage_member')}</h4>
                        </div>

                        <div class="manage-box__body">
                            <div class="content">
                                {eventData.jpri != EVENT_PUBlIC_TYPE.PUBLIC ? (
                                    <TabHeader tab={tab} onChangeTab={onChangeTab} />
                                ) : null}

                                <div class="tabs-v2-content">
                                    <ul>
                                        {tab == 0 ? (
                                            <MemberRequestList eventData={eventData} />
                                        ) : (
                                            <MemberJoinList eventData={eventData} />
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default ManageMember;
