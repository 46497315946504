import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
const queryString = require('query-string');
import { PRIVATE_KEY } from '../../helpers/api/APIDefined';
import { toJS } from 'mobx';
import {stripedHtml} from '../../utils';
let _ = require('lodash');

let paramsCallback, extraData, eventID, urlRedirect;

const Callback = inject(
    'authStore',
    'connectStore',
    'userStore'
)(
    observer((props) => {
        const { stravaUserInfo, doGetStravaToken, doGetGarminToken, doStravaConnectAPI } = props.connectStore;

        paramsCallback = queryString.parse(props.location.search);
        if (_.isEmpty(paramsCallback)) return <Redirect to="/"></Redirect>;

        useEffect(() => {
            console.log('paramsCallback', paramsCallback);
            handleCallback();
        }, []);

        function validParams(params) {
            if (_.isEmpty(params)) return false;
            if (params.extra == undefined) return false;
            extraData = params.extra.split(',');
            if (!extraData || extraData.length == 0) return false;
            return true;
        }

        function handleCallback() {
            try {
                if (validParams(paramsCallback)) {
                    const uid = extraData[0];
                    const type = extraData[1];
                    eventID = extraData[2];
                    
                    if (!uid || !type || !eventID) props.history.push('/');

                    urlRedirect = `/settings?evid=${eventID}`;

                    if (type == 0) getStravaToken(uid, stripedHtml(paramsCallback.code));
                    else if (type == 1) {
                        const secret = extraData[3];
                        if (!secret) props.history.push('/');
                        getGarminToken(uid, secret);
                    }
                } else props.history.push(urlRedirect);
            } catch (error) {
                props.history.push(urlRedirect);
            }
        }

        async function getGarminToken(uid, secret) {
            let params = {
                userId: uid,
                step: 2
            };
            const headers = {
                'XToken': stripedHtml(paramsCallback.oauth_token),
                'XSec': secret,
                'XVerifier': stripedHtml(paramsCallback.oauth_verifier)
            };
            let result = await doGetGarminToken(params, headers);
            
            if (result && result.length > 0) {
                const obj = JSON.parse(result[0]);
                const openToken = {
                    oauth_token: stripedHtml(paramsCallback.oauth_token),
                    oauth_token_secret: secret
                };

                const data = {
                    openType: 4,
                    openId: obj.userId,
                    openToken: JSON.stringify(openToken)
                };
                params = {
                    userId: uid,
                    data
                };
                stravaConnectAPI(params);
            } else {
                props.history.push(urlRedirect);
            }
        }

        async function getStravaToken(uid, token) {
            let params = {
                client_id: PRIVATE_KEY.STRAVA_CLIENT_ID,
                client_secret: PRIVATE_KEY.STRAVA_CLIENT_SECRET,
                code: token
            };
            let result = await doGetStravaToken(params);
            if (result) {
                const data = {
                    openType: 2,
                    openId: `${stravaUserInfo.openId}`,
                    openToken: JSON.stringify(toJS(stravaUserInfo.openToken)),
                    openName: stravaUserInfo.name,
                    openImg: stravaUserInfo.img,
                    openEmail: stravaUserInfo.email || ''
                };
                params = {
                    userId: uid,
                    data
                };
                stravaConnectAPI(params);
            } else props.history.push(urlRedirect);
        }

        async function stravaConnectAPI(params) {
            let result = await doStravaConnectAPI(params);
            if (result != null) {
                props.history.push(urlRedirect);
            } else props.history.push(urlRedirect);
        }

        return null;
    })
);

export default Callback;
