import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FOLLOW_STT } from '../../../../helpers/ConstDefined';
import { getImageResource } from '../../../../utils';

const LikeItem = inject(
    'authStore',
    'userStore',
    'challengeStore'
)(
    observer((props) => {
        const { data } = props;
        const { userProfile } = props.userStore;
        const { doFollow } = props.challengeStore;

        useEffect(() => {}, []);

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        const imgGender = null; //data != null && data.sex == 1 ? '../../../img/male.png' : '../../../img/female.png';

        return (
            <li>
                <div className="row">
                    <div className="col-md-8">
                        <div className="row wrapper-profile-user friend-profile-modal">
                            <div className="col-2">
                                <div className="avatar-profile" style={{ backgroundImage: `url(${getImageResource(data.ava,true,true)})` }}>
                                    <img className="avatar" src="../../../img/avatar.png" alt="" />
                                    {imgGender ? <img className="gender" src={imgGender} alt="" /> : null}
                                </div>
                            </div>
                            <div className="col-md-10 information-user-modal custom-information-user">
                                <span className="name-user">{data.name}</span>
                                <p className="groups-user">{data.tmnm || ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {data.fists == 2 ? (
                            userProfile.uid != data.uid ? (
                                <div
                                    className="wrapper-btn custom-wrapper-btn"
                                    onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                    <button type="button" className="btn btn-outline-primary" style={{ width: 120 }}>
                                        Bỏ theo dõi
                                    </button>
                                </div>
                            ) : null
                        ) : userProfile.uid != data.uid ? (
                            <div
                                className="wrapper-btn custom-wrapper-btn"
                                onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                                <button type="button" className="btn btn-primary" style={{ width: 120 }}>
                                    Theo dõi
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </li>
        );
    })
);

export default LikeItem;
