import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml, formatNumber, formatDistanceKM, getTypeName } from '../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../helpers/ConstDefined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var FileSaver = require('file-saver');
import DownloadLink from 'react-download-link';

const ViewCertDialog = inject(
    'userStore',
    'globalStore',
    'eventStore',
    'authStore'
)(
    observer((props) => {
        const { eventID } = props;

        const { openDialogViewCert } = props;
        const { certLink, doGetUserCert } = props.authStore;
        const { currentEvent } = props.eventStore;
        const { userProfile } = props.userStore;

        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        useEffect(() => {
            if (openDialogViewCert) getUserCert();
        }, [openDialogViewCert]);

        const getUserCert = async () => {
            const params = {
                userId: userProfile.uid,
                eventId: eventID
            };
            let result = await doGetUserCert(params);
        };

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const onSaveFile = () => {
            FileSaver.saveAs(certLink);
        };

        const onCloseDialog = () => {
            props.onClose();
        };

        return (
            <Dialog open={openDialogViewCert}>
                <div
                    class="uprace-popup has-title in"
                    id="certificate"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content w420">
                            <button
                                type="button"
                                class="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 class="popup-title">{t('global.cert')}</h2>
                            <div class="popup-body">
                                <div class="popup-certificate">
                                    <p class="img">
                                        <img src={certLink} alt="" />
                                    </p>
                                    {certLink ? (
                                        <ul class="btn-list">
                                            <li>
                                                <button class="download" onClick={() => onSaveFile()}>
                                                    {t('global.download')}
                                                </button>
                                            </li>

                                            <li>
                                                <CopyToClipboard text={certLink} onCopy={() => onCopySuccess()}>
                                                    <button class="share">{t('global.share_now')}</button>
                                                </CopyToClipboard>
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default ViewCertDialog;
