import styled from 'styled-components';
import { Popover } from 'antd';
import { device } from '../../../../../configs/MediaQuery';

export const UserDonateWrapper = styled.div`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    height: 246px;
    background-image: url('https://uprace2.vcdn.vn/web/uprace/images/backgrounds/donate_cover.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;

    @media ${device.mobileS} {
        margin-top: 20px;
        position: relative;
        width: 100%;
    }
    @media ${device.tablet} {
        margin-top: 20px;
        position: relative;
        width: 100%;
    }
    @media ${device.laptop} {
        position: absolute;
        margin-top: 0px;
        right: 60px;
        top: 120px;
        width: 600px;
    }
`;

export const UserDonateContainer = styled.div`
    padding-top: 44px;
    padding-bottom: 44px;
    margin: 0 auto;
    text-align: center;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const LogoBox = styled.div`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileS} {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }
    @media ${device.tablet} {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }
    @media ${device.laptop} {
        width: 88px;
        height: 88px;
        border-radius: 44px;
    }
`;

export const LogoCompany = styled.img`
    width: 100%;
    border-radius: 50%;
`;

export const TotalMoney = styled.h1`
    font-weight: 600;
    color: #000000;

    @media ${device.mobileS} {
        font-size: 16px;
        line-height: 20px;
    }
    @media ${device.tablet} {
        font-size: 18px;
        line-height: 24px;
    }
    @media ${device.laptop} {
        font-size: 22px;
        line-height: 29px;
    }
`;



export const TextMessage = styled.p`
    font-weight: 400;

    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }
    @media ${device.tablet} {
        font-size: 16px;
        line-height: 24px;
    }
    @media ${device.laptop} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const DonateTooltip = styled(Popover)``;

export const TooltipContentWrapper = styled.div`
    text-align: center;
`;

export const TooltipMoneyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const TooltipNumber = styled.h1`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-family:"SFProDisplay-Bold, SF Pro Display";
`;

export const TooltipText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 5px;
`;

export const TooltipTitle = styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
`;
