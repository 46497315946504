import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chart, Line } from 'react-chartjs-2';
import { inject, observer } from 'mobx-react';

const ChartDemo = inject('globalStore')(
    observer((props) => {
        const { t, i18n } = useTranslation();

        const plugins = [
            {
                afterDraw: ({ chart }) => {
                    // eslint-disable-next-line no-underscore-dangle
                    if (chart.tooltip._active && chart.tooltip._active.length > 0) {
                        // draw vertical line
                        const activePoint = chart.tooltip._active[0];
                        const { ctx } = chart;
                        const { x } = activePoint._view;
                        const topY = activePoint._yScale.top;
                        const bottomY = activePoint._yScale.bottom;

                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#3e64ff';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        ];

        const data = (canvas) => {
            const ctx = canvas.getContext('2d');
            const gradient1 = ctx.createLinearGradient(0, 50, 0, 200);
            gradient1.addColorStop(0, '#f15b28');
            gradient1.addColorStop(1, '#fff');

            const gradient2 = ctx.createLinearGradient(0, 50, 0, 200);
            gradient2.addColorStop(0, '#0046c2');
            gradient2.addColorStop(1, '#fff');

            return {
                labels: [
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    'Tuần 1',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    'Tuần 2',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    'Tuần 3',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    'Tuần 4'
                ],
                datasets: [
                    {
                        label: '2020',
                        backgroundColor: gradient1, // Put the gradient here as a fill color
                        borderColor: '#f15b28',
                        borderWidth: 5,
                        pointColor: '#fff',
                        pointStrokeColor: '#f15b28',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: '#f15b28',
                        data: [25.0, 32.4, 22.2, 39.4, 34.2, 22.0, 23.2, 24.1, 20.0, 18.4, 19.1, 17.4],
                        pointBorderColor: '#f15b28',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: '#f15b28',
                        pointHoverBorderColor: '#FFE0B2',
                        pointHoverBorderWidth: 8,
                        pointRadius: 5,
                        pointHitRadius: 10
                    },
                    {
                        label: '2021',
                        backgroundColor: gradient2, // Put the gradient here as a fill color
                        borderColor: '#0046c2',
                        borderWidth: 2,
                        pointColor: '#fff',
                        pointStrokeColor: '#0046c2',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: '#0046c2',
                        data: [35.0, 12.4, 12.2, 19.4, 54.2, 32.0, 23.2, 14.1, 40.0, 28.4, 0, 0],
                        pointBorderColor: '#0046c2',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 2,
                        pointHoverRadius: 10,
                        pointHoverBackgroundColor: '#0046c2',
                        pointHoverBorderColor: '#BBDEFB',
                        pointHoverBorderWidth: 8,
                        pointRadius: 5,
                        pointHitRadius: 10
                    }
                ]
            };
        };

        var options = {
            responsive: true,
            //option for line instead of curves
            elements: {
                line: {
                    tension: 0
                }
            },
            animation: {
                easing: 'easeInOutBack'
            },
            legend: {
                position: 'top',
                align: 'start'
            }
        };

        return (
            <div>
                <Line data={data} options={options} plugins={plugins} />
            </div>
        );
    })
);

export default ChartDemo;
