import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import TabHeader from './TabHeader';
import RuleEvent from './RuleEvent';
import DiscussEvent from './DiscussEvent';
import { ManagePost } from '..';
import TopGroup from './TopGroup';
import { EVENT_FORM_TYPE, EVENT_JOIN_STATUS, EVENT_PUBlIC_TYPE } from 'helpers/ConstDefined';

const EventInfoTab = inject(
    'authStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { eventData, navigation } = props;
        const { userProfile } = props.userStore;
        const [tab, setTab] = useState(0);

        useEffect(() => {}, []);

        const onChangeTab = (val) => {
            setTab(val);
        };

        const isAllowView =
            eventData.jpri == EVENT_PUBlIC_TYPE.PUBLIC ||
            (eventData.jsts != EVENT_JOIN_STATUS.NONE && eventData.jsts != EVENT_JOIN_STATUS.REQUESTING);

        return (
            <div class="card card-details">
                <div class="card-details-body">
                    <TabHeader tab={tab} onChangeTab={onChangeTab} />
                    {userProfile && eventData.form == EVENT_FORM_TYPE.GROUP && tab == 0 && isAllowView ? (
                        <TopGroup eventData={eventData} navigation={navigation} />
                    ) : null}
                    <div class="tabs-sub--content">
                        <ul>
                            <li class="active">
                                {tab == 0 ? (
                                    <RuleEvent eventData={eventData} />
                                ) : (
                                    <ManagePost eventData={eventData} navigation={navigation} />
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    })
);

export default EventInfoTab;
