import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const RuleEvent2023VI = inject('globalStore')(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        function RuleUpraceVI() {
            return (
                <section class="fail-activity-list">
                     <div class="title">
                        <p>UPRACE 2023</p>
                        <p>ĐIỀU LỆ THAM GIA</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Định nghĩa:</h3>
                            <div class="list-desc">
                            UpRace 2023 (sau đây gọi tắt là “sự kiện") là một sự kiện chạy bộ cộng đồng MIỄN PHÍ dành cho tất cả mọi người trên nền tảng web/mobile UpRace. Người tham gia (sau đây gọi là vận động viên, viết tắt “VĐV”) được tự do chạy vào thời gian và địa điểm tùy ý và chủ động ghi lại kết quả chạy trên các thiết bị di động (điện thoại di động, đồng hồ thể thao…) với mục đích tích luỹ được quãng đường chạy càng nhiều càng tốt. 
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Thời gian:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Thời gian mở đăng ký sự kiện:</strong> 
                                        bắt đầu từ 00 giờ ngày 04/09/2023, VĐV vẫn có thể tiếp tục đăng ký và gia nhập Đội thi trong quá trình sự kiện diễn ra. 
                                    </li>
                                    <li>
                                        <strong>Thời gian thi đấu:</strong> 
                                        từ 00 giờ ngày 06/10/2023 tới 23 giờ 59 phút ngày 29/10/2023 (24 ngày)
                                    </li>
                                    <li>
                                        <strong>Giờ Việt Nam (GMT+7)</strong> được sử dụng làm múi giờ chuẩn cho sự kiện
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Cách thức đăng ký</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        VĐV tải ứng dụng UpRace trên điện thoại di động hoặc truy cập website:{' '}
                                        <a href="https://uprace.org">https://uprace.org</a> và đăng ký tài khoản. Các VĐV đã dự thi các năm trước có thể đăng nhập vào tài khoản sẵn có của mình. 
                                    </li>
                                    <li>
                                        Các VĐV sau khi đăng ký tài khoản thành công sẽ tham gia sự kiện bằng cách bấm nút “Tham gia” vào sự kiện UpRace 2023 trong mục “Cộng đồng” {'>'} “Sự kiện” trên ứng dụng.
                                    </li>
                                    <li>
                                        VĐV bấm chọn một (01) Đội (Team) để thi đấu. Mỗi tài khoản của VĐV chỉ được chọn một Đội. Các VĐV không được phép thay đổi Đội kể từ thời điểm sự kiện bắt đầu (00 giờ ngày 06/10/2023) cho tới lúc sự kiện kết thúc (23 giờ 59 phút ngày 29/10/2023).  
                                    </li>
                                    <li>
                                        VĐV tham gia vào sự kiện sau thời điểm sự kiện bắt đầu vẫn được thi đấu bình thường.
                                    </li>

                                    <li>
                                        VĐV bấm tham gia gây quỹ cho một (01) Tổ chức xã hội mà mình lựa chọn. Mỗi tài khoản của VĐV chỉ được chọn một (01) Tổ chức xã hội để gây quỹ. Các VĐV không được phép thay đổi Tổ chức xã hội mình đã chọn trong quá trình sự kiện diễn ra. Có 3 Tổ chức xã hội để VĐV lựa chọn bao gồm:
                                        <p style={{paddingLeft:30}}>• ASVHO (Hội Bảo trợ người khuyết tật và trẻ mồ côi Việt Nam)</p>
                                        <p style={{paddingLeft:30}}>
                                            • Green Việt
                                        </p>
                                        <p style={{paddingLeft:30}}>
                                            • Quỹ học bổng Vừ A Dính
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Cách thức thi đấu giữa các Đội</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Các đội được xếp vào 3 bảng đấu gồm:</p>
                                        <p>- Bảng 1: gồm các Doanh nghiệp (Company)</p>
                                        <p>- Bảng 2: gồm các Câu lạc bộ thể thao (Sports Club)</p>
                                        <p>- Bảng 3: gồm các Trường học (Schools)</p>
                                    </li>
                                    <li>
                                        Thành tích của mỗi Đội là tổng thành tích hợp lệ của tất cả các VĐV tham gia thi đấu cho Đội trong sự kiện. Các Đội trong cùng bảng sẽ thi đấu với nhau. 
                                    </li>
                                    <li>
                                        Các Đội được thành lập sau thời điểm sự kiện bắt đầu vẫn được tham gia thi đấu bình thường. 
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Cách thức thi đấu đối với các VĐV</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Cách thức ghi lại thành tích:</strong> Các VĐV tự ghi lại thành tích mỗi
                                        lần chạy của mình bằng thiết bị di động (điện thoại, đồng hồ có chức năng GPS…).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Cuộc chạy hợp lệ:</strong> được tính khi đạt đủ các điều kiện sau:
                                        </p>
                                        <p>
                                            - Được ghi nhận hoàn tất trên tài khoản UpRace của VĐV trong vòng 72 giờ kể
                                            từ thời điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối cùng của sự kiện.
                                        </p>
                                        <p>
                                            - Thể hiện bản đồ chạy rõ ràng, không có dấu hiệu sai số lớn do lỗi bắt sóng
                                            GPS của thiết bị di động. Các cuộc chạy được nhập kết quả thủ công bằng tay
                                            (manual input), hoặc được share bởi người khác, hoặc ghi nhận cho nhiều tài
                                            khoản khác nhau sẽ không được công nhận hợp lệ; Trong trường hợp cuộc chạy
                                            thực hiện trên máy chạy bộ cần được ghi nhận bằng thiết bị đo chuyên dụng
                                            như đồng hồ Garmin và cảm biến đeo chân.
                                        </p>
                                        <p>
                                            - Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều ứng dụng sẽ chỉ được ghi nhận là một cuộc chạy và theo thứ tự ưu tiên sau: Thiết bị chạy bộ đeo tay chuyên dụng (bao gồm: Garmin, Coros, Suunto, Apple Watch, Fitbit, POLAR...) {'>'} UpRace. 
                                        </p>
                                        <p>
                                            - Có cự li chạy (Distance) tối thiểu là 1km. Cự li tối đa không giới hạn.
                                            VĐV được bấm tạm dừng cuộc chạy trong khi chạy với mục đích nghỉ và phân
                                            phối sức
                                        </p>
                                        <p>
                                            - Có tốc độ di chuyển trung bình (Average Moving Pace) tính theo số phút hoàn thành 01 km chạy nhanh tối đa là 3:30 phút/km và chậm tối thiểu là 15:00 phút/km. Có tốc độ di chuyển trung bình của tất cả các quãng 01km (Split) nhanh tối đa 3:00 phút/km.
                                        </p>
                                        <p>
                                            - Các cuộc chạy diễn ra trong những ngày Chủ nhật trong thời gian diễn ra sự kiện phải được hoàn tất trong ngày (trước 24h00) và kết quả nhân 2 được tính vào thành tích của ngày đó.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Ngày chạy hợp lệ (active days):</strong> là ngày có ít nhất một cuộc
                                            chạy hợp lệ theo điều trên.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Ngày nhân đôi:</strong> bao gồm những ngày Chủ nhật trong thời gian sự kiện diễn ra và ngày đặc biệt 10/10/2023 với điều kiện dùng app UpRace để ghi lại thành tích. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Các mốc ghi nhận thành tích:</strong> 
                                        </p>
                                        <p>
                                        - Mốc 1 (từ km 1 đến dưới km 500): đối với mốc này mọi hoạt động hợp lệ quy định ở điều V.2 đều được ghi nhận vào thành tích 
                                        </p>
                                        <p>
                                        - Mốc 2 (từ km500 trở lên): để được ghi nhận thành tích từ km500 trở lên, các hoạt động hợp lệ quy định ở điều V.2 phải được ghi bằng các Thiết bị chạy bộ đeo tay chuyên dụng bao gồm: Garmin, Coros, Suunto, Apple Watch, Fitbit, POLAR
                                        </p>
                                        <p>
                                            <strong>*Lưu ý:</strong>  các mốc km đã bao gồm thành tích nhân đôi của các ngày đặc biệt hoặc ngày Chủ nhật
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Hoàn thành cuộc thi:</strong> 
                                            Một VĐV sẽ được chứng nhận là hoàn thành cuộc thi khi đạt đồng thời hai điều kiện sau: 
                                        </p>
                                        <p>
                                        - Có số ngày chạy hợp lệ tối thiểu là 12 ngày. 
                                        </p>
                                        <p>
                                        - Có tổng số quãng đường chạy tối thiểu là 60km (bao gồm cả thành tích nhân đôi trong các ngày Chủ nhật) 
                                        </p>
                                        <p>
                                            <strong>*Lưu ý:</strong>  
                                            Đối với vận động viên không chạy đủ 12 ngày và 60km, thì số km chạy hoàn thành vẫn được đóng góp cho đội và được nhà tài trợ quyên góp cho quỹ. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Ngày ghi nhận thành tích: </strong> 
                                            Thời điểm bắt đầu cuộc chạy thuộc ngày nào thì thành tích của cuộc chạy được tính cho ngày đó. 
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Giải thưởng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Chứng nhận hoàn thành cuộc thi:</strong>
                                        </p>
                                        <p>
                                        Tất cả các VĐV đạt đủ điều kiện hoàn thành cuộc thi sẽ nhận được một giấy chứng nhận hoàn thành cuộc thi (bản điện tử) theo các mốc số km hoàn thành gồm: 60km, 150km, 300km tương ứng với các danh hiệu: Pro Runner, Hero Runner và Angel Runner.
                                        </p>
                                    </li>




                                    <li>
                                        <p>
                                            <strong>Giải thưởng hiện vật:</strong>
                                        </p>
                                        <p>
                                            - Mười (10) Đội có tổng thành tích tốt nhất trong mỗi Bảng sẽ nhận giải thưởng đồng đội. Giải thưởng đồng đội bao gồm: áo UpRace Winning Team 2023 sẽ trao cho: 
                                        </p>
                                        <p>
                                        + Vận động viên đủ điều kiện hoàn thành cuộc thi 
                                        </p>
                                        <p>
                                        + Và có thành tích nằm trong top của các Đội đoạt giải theo bảng sau 
                                        </p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Doanh nghiệp</th>
                                                <th>Câu lạc bộ</th>
                                                <th>Trường học</th>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhất</td>
                                                <td>Top 300 VĐV</td>
                                                <td>Top 250 VĐV</td>
                                                <td>Top 200 VĐV</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhì</td>
                                                <td>Top 250 VĐV</td>
                                                <td>Top 200 VĐV</td>
                                                <td>Top 150 VĐV</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng ba</td>
                                                <td>Top 200 VĐV</td>
                                                <td>Top 150 VĐV</td>
                                                <td>Top 100 VĐV</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 4,5</td>
                                                <td>Top 150 VĐV</td>
                                                <td>Top 100 VĐV</td>
                                                <td>Top 50 VĐV</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 6,7,8,9 và 10</td>
                                                <td>Top 50 VĐV</td>
                                                <td>Top 50 VĐV</td>
                                                <td>Top 50 VĐV</td>
                                            </tr>
                                        </table>

                                        <p>
                                            <strong>Giải thưởng thành tích cá nhân: </strong> 
                                            Áo UpRace trao cho Top 300 VĐV nam/nữ có thành tích thi đấu cao nhất (không bao gồm các vđv đã nhận giải top 10 các bảng trên)
                                        </p>
                                        <p>
                                            <strong>Giải thưởng khác: </strong> 
                                            Giải thưởng dành cho đội có thành tích cao nhất mỗi bảng (Top 1):
                                        </p>
                                        <p style={{paddingLeft: 30}}>
                                            (1)   100 triệu VNĐ dùng để quyên góp cho đơn vị thụ hưởng UpRace 2023 mà đội đó chỉ định,
                                        </p>
                                        <p style={{paddingLeft: 30}}>
                                            (2)   Giấy chứng nhận thành tích đội thắng cuộc (bản điện tử),
                                        </p>
                                        <p style={{paddingLeft: 30}}>
                                            (3)   Bài vinh danh đội Top 1 mỗi bảng trên fanpage UpRace.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Gây quỹ cho cộng đồng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Nguồn quỹ:</strong>
                                        </p>
                                        <p>
                                            - Từ các nhà tài trợ (Sponsors): các nhà tài trợ sẽ quyên góp 1.000 đồng/01km chạy hợp lệ của VĐV.
                                        </p>
                
                                    </li>
                                    <li>
                                        <p><strong>Sử dụng quỹ:</strong></p>
                                        <p> - Toàn bộ tiền gây quỹ của UpRace sẽ được chuyển cho
                                        các Tổ chức xã hội.</p>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <h3 class="list-title">Quyền của Ban tổ chức (BTC) và Hội đồng kỹ thuật (HĐKT)</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Khiếu nại: </strong> 
                                        BTC và HĐKT chỉ xem xét các khiếu nại có liên quan đến kết quả của các VĐV nằm trong top 10 của mỗi Đội. Email là kênh tiếp nhận khiếu nại duy nhất và khiếu nại phải do người đại diện của mỗi đội gửi tới địa chỉ email của BTC: support@uprace.vn. Quyết định của HĐKT là quyết định cuối cùng trong mọi khiếu nại phát sinh. 
                                    </li>
                                    <li>
                                        <strong>Xử phạt: </strong> 
                                        BTC có các phương án phòng chống và phát hiện gian lận nếu có, đồng thời kêu gọi các VĐV thể hiện tinh thần fair play và tự giác cao nhất. Khi có bất kỳ hành vi gian lận nào bị phát hiện, VĐV sẽ bị loại khỏi cuộc thi và không được công nhận toàn bộ thành tích đã đạt được. 
                                    </li>
                                    <li>
                                        <strong>Quyền của BTC: </strong> 
                                        BTC có toàn quyền quyết định một Đội và VĐV có đủ điều kiện thi đấu hay không, quyền dừng thi đấu và huỷ kết quả của Đội và VĐV trong trường hợp phát hiện hành vi gian lận hoặc không tuân thủ các quy định của cuộc thi và quyền phân xử đối với các khiếu nại.
                                    </li>
                                </ul>
                            </div>
                        </li>

                        </ul>
                </section>
            );
        }

        return <RuleUpraceVI />;
    })
);

export default RuleEvent2023VI;
