import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import { getImageResource, replaceURLs } from '../../../../../utils';
import { humanTime } from '../../../../../helpers/ConvertHelper';
import { JOIN_TEAM_STT, GROUP_JOIN_STATUS, SOCIAL_TYPE, SOCIAL_TAB, LIKE_TYPE } from '../../../../../helpers/ConstDefined';
import { USER_ROLE } from '../../../../../data/master';
import CreatePostDialog from '../CreatePostDialog';
import { DialogSocial } from '../../../YourInfo/components';
import _ from 'lodash';
import { toJS } from 'mobx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API } from '../../../../../helpers/api/APIDefined';
import { isAccountDeleted } from 'components/MemberName';

const NotificationTeam = inject(
    'userStore',
    'postStore',
    'teamManageStore',
    'groupStore',
    'eventStore',
    'globalStore'
)(
    observer((props) => {
        const { doSetFunc } = props.teamManageStore;
        const { pinPost, doGetCurrentPinPost, doLike } = props.postStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { userProfile } = props.userStore;
        const { totalGroupRq, doGetListGroupRequest } = props.groupStore;
        const { currentEvent } = props.eventStore;
        const { teamID, eventID } = props;

        const [tabSocial, setTabSocial] = React.useState(SOCIAL_TAB.LIKE); //default tab like
        const [openDialogCreatePost, setOpenDialogCreatePost] = React.useState(false);
        const [openDialogSocial, setOpenDialogSocial] = React.useState(false);

        useEffect(() => {
            getCurrentPinPostTeam();
            getListGroupRequest();
        }, [userProfile]);

        const getListGroupRequest = () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                gejsts: GROUP_JOIN_STATUS.REQUESTING //Đang chờ duyệt
            };
            doGetListGroupRequest(params);
        };

        const { t } = useTranslation();

        function getCurrentPinPostTeam() {
            const params = {
                from: 0,
                feed: 2,
                uid: userProfile && userProfile.uid,
                sort: 0,
                order: 1,
                value: teamID,
                evid: eventID,
                size: 1
            };
            doGetCurrentPinPost(params);
        }

        function CommentItem(props) {
            const { data } = props;
            return (
                <div className="mess-block">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div className="mess-details">
                        <div className="mess-box">
                            <p className="name">{MemberName(data)}</p>
                            <p className="mess">{data.cmt}</p>
                        </div>
                        <div className="d-row">
                            <p className="date">{humanTime(data.cmtm, language)}</p>
                        </div>
                    </div>
                </div>
            );
        }

        function LikeStatus() {
            let status;
            if (pinPost.lkcnt == 0) return null;

            if (pinPost.lktp != 0) {
                if (pinPost.lkcnt == 1) return t('global.only_you_like_stt');
                else {
                    status = t('global.you_other_like_stt').replace('[like]', pinPost.lkcnt - 1);
                    return status;
                }
            } else return `${pinPost.lkcnt} ${t('global.other_like_stt')}`;
        }

        function RoleUser(role) {
            const obj = _.find(USER_ROLE, (item) => {
                return item.id == role;
            });
            return t(obj.title);
        }

        const MemberName = (data) => {
            if (isAccountDeleted(data)) return <span style={{ color: '#919EAB' }}>{t('global.deleted_account')}</span>;
            return <span className="name">{data.name}</span>;
        };

        const onOpenDialogSocial = (tab) => {
            setOpenDialogSocial(true);
            setTabSocial(tab);
        };

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const onLikePost = async (data) => {
            const lktp = data.lktp == LIKE_TYPE.NONE ? LIKE_TYPE.LIKE : LIKE_TYPE.NONE;
            const params = {
                uid: userProfile && userProfile.uid,
                psid: data.psid,
                lktp
            };
            let result = await doLike(params, SOCIAL_TYPE.POST);
            if (result && result != false) {
                pinPost.lkls = result.lkls;
                pinPost.lktp = lktp;
                pinPost.lkcnt = result.lkcnt;
            }
        };

        const isAdmin = () => {
            if (userProfile && currentEvent) {
                if (currentEvent.tmid == teamID && userProfile.role) {
                    return userProfile.role.id == JOIN_TEAM_STT.OWNER || userProfile.role.id == JOIN_TEAM_STT.ADMIN;
                } else return false;
            } else return false;
        };

        function RenderNoPost() {
            const title = isAdmin() ? t('global.no_post_title') : '';
            const desc = isAdmin() ? t('global.no_post_desc') : t('global.no_post_title_user');
            return (
                <div class="gbox noti-box-details">
                    <div class="groups-block">
                        <div class="groups-empty">
                            <p class="img">
                                <img src="../../../images/team/imgs/1.png" alt="notification" />
                            </p>
                            <div class="groups-empty">
                                <p class="title">{title}</p>
                                <p class="note">{desc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="team-noti-box">
                <div className="title-row">
                    <h2 className="title">{t('team.manage.leader_noti')}</h2>
                    <a href="#" onClick={() => doSetFunc(2)} className="seemore">
                        {t('global.seaall')}
                    </a>
                </div>
                {isAdmin() ? (
                    <ul className="leader-manage">
                        <li onClick={() => doSetFunc(1)}>
                            {totalGroupRq > 0 ? <span className="num">{totalGroupRq}</span> : null}
                            <button className="btn-40 blue">{t('team.manage.tab1')}</button>
                        </li>
                        <li>
                            <button className="btn-40 blue-light" onClick={() => setOpenDialogCreatePost(true)}>
                                <span className="txt">{t('team.post.menu.add')}</span>
                            </button>

                            <CreatePostDialog
                                eventID={eventID}
                                teamID={teamID}
                                openDialogCreatePost={openDialogCreatePost}
                                onClose={() => setOpenDialogCreatePost(false)}
                            />
                        </li>
                    </ul>
                ) : null}

                {pinPost ? (
                    <div className="gbox noti-box-details">
                        <div className="account">
                            <p className="avatar">
                                <img src={getImageResource(pinPost.ava, true, true)} alt="" />
                            </p>
                            <div className="desc">
                                <p className="name">{MemberName(pinPost)}</p>
                                <div className="d-row">
                                    <p className="tag">{RoleUser(pinPost.tmrole)}</p>
                                    <p className="date">{humanTime(pinPost.crtm, language)}</p>
                                    {pinPost.pin == 1 ? <button className="stick"></button> : null}
                                </div>
                            </div>
                        </div>
                        <h2 className="title">{pinPost.title}</h2>
                        <div className="details">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: pinPost.desc ? replaceURLs(pinPost.desc).replace(/\n/g, '<br />') : null
                                }}></p>
                            <div className="slide-block">
                                <ul className="slide">
                                    <Carousel autoPlay showArrows={false} showThumbs={false} infiniteLoop={true}>
                                        {pinPost.photos.map((item, index) => {
                                            if (item != '')
                                                return (
                                                    <li className="img-slide" style={{ display: 'block' }}>
                                                        <img src={getImageResource(item, false, false, true)} alt="" />
                                                    </li>
                                                );
                                            else return null;
                                        })}
                                    </Carousel>
                                </ul>
                            </div>
                        </div>
                        <div className="account-group">
                            <ul className="avatars">
                                {pinPost.lkls.map((item) => {
                                    return (
                                        <li key={item.uid}>
                                            <img src={getImageResource(item.ava, true, true)} alt="" />
                                        </li>
                                    );
                                })}
                            </ul>
                            <p className="txt" onClick={() => onOpenDialogSocial(SOCIAL_TAB.LIKE)}>
                                {pinPost.lkcnt ? <LikeStatus /> : null}
                            </p>
                        </div>
                        <div className="action-row">
                            <ul>
                                <li>
                                    <button
                                        className={pinPost.lktp != 0 ? 'like active' : 'like'}
                                        onClick={() => onLikePost(pinPost)}></button>
                                </li>
                                <li>
                                    <button className="mess" onClick={() => onOpenDialogSocial(SOCIAL_TAB.COMMENT)}>
                                        <span className="num">{pinPost.cmcnt}</span>
                                    </button>
                                </li>
                                <li>
                                    <CopyToClipboard
                                        text={
                                            API.GLOBAL.SITE_DOMAIN + `post-detail?evid=${eventID}&psid=${pinPost.psid}`
                                        }
                                        onCopy={() => onCopySuccess()}>
                                        <button class="share"></button>
                                    </CopyToClipboard>
                                </li>
                            </ul>
                        </div>

                        {pinPost.cmls.map((item) => {
                            return <CommentItem data={item} key={item.cmid} />;
                        })}
                    </div>
                ) : (
                    <RenderNoPost />
                )}

                {openDialogSocial ? (
                    <DialogSocial
                        data={pinPost}
                        type={SOCIAL_TYPE.POST}
                        tab={tabSocial}
                        openDialogSocial={openDialogSocial}
                        onClose={() => setOpenDialogSocial(false)}></DialogSocial>
                ) : null}
            </div>
        );
    })
);

export default NotificationTeam;
