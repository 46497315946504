import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import MemberItem from './MemberItem';
import { MEMBER_TYPE } from '../../../../data/master';
import { MEMBER_SEARCH_TYPE } from '../../../../helpers/ConstDefined';
import { toJS } from 'mobx';
import { stripedHtml } from '../../../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const ListMember = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { navigation, teamID, eventID } = props;
        const { currentTeam } = props.teamStore;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const { loadingSearch, listUserSearch, totalUser, doSearchUser } = props.userStore;

        const [memberTab, setMemberTab] = React.useState(0);
        //filter params
        const [name, setName] = React.useState('');
        const isShowLoadMore = listUserSearch == null ? false : listUserSearch.length < totalUser;
        const showClearName = name && name.length > 0;

        const { t } = useTranslation();

        useEffect(() => {
            window.scrollTo(0, 0);
            searchUser(MEMBER_SEARCH_TYPE.FOLLOW);
        }, []);

        const handleChangeTabMember = (tab) => {
            resetParamsFilter();
            setMemberTab(tab);
            searchUser(tab);
        };

        const handleChangeName = (val) => {
            setName(val);
        };

        const resetParamsFilter = () => {
            //reset filter when switch tab
            setName('');
        };

        function handleResetSearch() {
            resetParamsFilter();
            if (currentTeam == null) return;
            let params = {
                uid: userProfile && userProfile.uid,
                tmid: teamID
                //evid: eventID
            };

            if (memberTab == MEMBER_SEARCH_TYPE.FOLLOW) params.fiid = userProfile.uid;
            else if (memberTab == MEMBER_SEARCH_TYPE.ADMIN) {
                params.tmad = teamID;
            } else if (memberTab == MEMBER_SEARCH_TYPE.OTHER) {
                params.tmad = -teamID;
                params.fiid = -userProfile.uid;
            }
            doSearchUser(params);
        }

        function searchUser(type, isLoadMore) {
            if (currentTeam == null) return;
            let params = {
                size: appVersion && appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                tmid: teamID,
                name
            };

            if (type == MEMBER_SEARCH_TYPE.FOLLOW) params.fiid = userProfile && userProfile.uid;
            else if (type == MEMBER_SEARCH_TYPE.ADMIN) {
                params.tmad = teamID;
            } else if (type == MEMBER_SEARCH_TYPE.OTHER) {
                params.tmad = -teamID;
                params.fiid = userProfile && -userProfile.uid;
            }

            doSearchUser(params, isLoadMore);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchUser(memberTab);
            }
        };

        const getSearchMemberListMore = () => {
            searchUser(memberTab, true);
        };

        function MemberTypeItem(props) {
            const { category } = props;
            const cssClass = category.id == memberTab ? 'active' : '';

            return (
                <li class={cssClass} onClick={() => handleChangeTabMember(category.id)}>
                    <a href="javascript:void(0);">
                        <span class="txt">{t(category.title)}</span>
                    </a>
                </li>
            );
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingSearch)
                    return (
                        <button className="seemore-btn loading">
                            <span></span>
                        </button>
                    );
                else
                    return (
                        <>
                            <button className="seemore-btn" onClick={() => getSearchMemberListMore()}>
                                <span>{t('global.loadmore')}</span>
                            </button>
                        </>
                    );
            } else return null;
        }

        return (
            <div class="profile-wbox m-list" id="see-all-users">
                <h2 class="profile-title">{t('global.list_member')}</h2>
                <div class="profile-tabs-menu">
                    <ul>
                        {MEMBER_TYPE.map((cat) => (
                            <MemberTypeItem key={cat.id} category={cat} />
                        ))}
                    </ul>
                </div>

                <div class="profile-search">
                    <button class="del-btn"></button>
                    <input
                        id="search"
                        type="text"
                        className="form-control form-search"
                        onKeyDown={handleKeyDown}
                        value={name}
                        onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                        placeholder={t('global.search_member_name')}
                    />
                </div>

                <div class="tabs-content profile-tabs-content">
                    <ul>
                        <li class="active">
                            <div class="profile-members-list">
                                <ul>
                                    {listUserSearch != null &&
                                        listUserSearch.map((item) => (
                                            <MemberItem key={item.uid} data={toJS(item)} eventID={eventID}></MemberItem>
                                        ))}
                                    <li>&nbsp;</li>
                                </ul>
                                <LoadMore />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    })
);
export default ListMember;
