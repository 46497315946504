import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const ENLang2021 = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <section class="fail-activity-list">
                <ul class="list">
                    <h3 className="list-title">COMPETITION RULES</h3>
                    <p>
                        <strong>Competition time: from 00:00 31/10/2021 to 23:59 21/11/2021 (22 days).</strong> Runners
                        and teams, who participate in the event after the event starts, will be able to compete
                        normally.
                    </p>

                    <p>
                        <strong> - Competition rules:</strong>
                    </p>

                    <div class="list-desc">
                        <li>
                            {' '}
                            + Runners will automatically join the event by creating an UpRace account. A runner can either
                            choose to compete under a team or compete individually.
                        </li>
                        <li>
                            + Recorded as completed and displayed on the runner's UpRace account within 72 hours from the
                            event starts and no later than the last day of the event.
                        </li>
                        <li>
                            + There is a minimum distance of any activity is 1km. Activity must display a clear route map
                            with no GPS errors. Activity on treadmill must be recorded by sport tracker device such as
                            Garmin with foot pod.
                        </li>
                        <li>
                            + Average Pace maximum is 4:00 minutes/km and minimum is 15 minutes/km. Split pace of each 1km
                            should not be faster than 3:30 minutes/km
                        </li>
                        <li>
                            + Valid activities that take place on Sundays and during the event period and complete within
                            the day will be counted doubly.
                        </li>
                        <li>
                            + Valid activities that take place on 11/11/2021, complete within the day and use UpRace app
                            to record will be counted doubly. Other activities that are recorded by Garmin or Strava on
                            that that day will be counted normally without doubling.
                        </li>
                        <li>
                            + Activities that are duplicated by recording from multiple devices at a same time will be
                            counted as one activity. The priority order of activity’s source will be: 1. UpRace; 2.
                            Garmin; 3. Strava
                        </li>

                        <li>
                            + A runner will be certified to complete the contest when both of the following conditions are
                            reached
                            <ul className="dot-list">
                                <p> - Must achieve a minimum number of 12 valid days, and  </p>
                                <p>
                                    - Must achieve a minimum distance of 60km (including double achievement on Sundays
                                    and Nov 11)
                                </p>
                            </ul>
                        </li>
                    </div>
                </ul>
            </section>
        );
    })
);

export default ENLang2021;
