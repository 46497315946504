import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { getTimestamp, getTypeName, formatNumber, formatDistanceKM, getImageResource } from '../../../../utils';
import { STATS_TYPE, GLOBAL_CONFIG } from '../../../../helpers/ConstDefined';
import { SPORT_TYPE, TEAM_TYPE } from '../../../../data/master';
import _ from 'lodash';

const TeamInfo = inject(
    'authStore',
    'eventStore',
    'teamStore',
    'userStore'
)(
    observer((props) => {
        const { navigation, eventID } = props;
        const { eventUserTeamStats, doGetEventUserTeamStats } = props.eventStore;
        const { userTeam, userTeamDetail, doGetUserTeamDetail, setTeamDetailData } = props.teamStore;
        const { userProfile } = props.userStore;

        useEffect(() => {
            if (userTeam != null) {
                getEventStats();
                getUserTeamDetail();
            }
        }, []);

        function getEventStats() {
            const data = {
                uid: userProfile.uid,
                evid: eventID,
                type: STATS_TYPE.TEAM,
                value: userTeam.tmid
            };
            doGetEventUserTeamStats(data);
        }

        function getUserTeamDetail() {
            const data = {
                uid: userProfile.uid,
                tmid: userTeam.tmid
            };
            doGetUserTeamDetail(data);
        }

        function goToProfile() {
            setTeamDetailData(_.clone(userTeamDetail));
            navigation.push('/team-profile?evid=' + eventID);
        }

        if (eventUserTeamStats != null && userTeamDetail != null)
            return (
                <div className="info-team">
                    <div className="wrapper-content">
                        <div className="intro">
                            <div className="team-head" style={{ backgroundImage: `url(${getImageResource(userTeamDetail.cover)})` }}>
                                <img src="../../../img/banner-left-unlogin.png" alt="image" />
                                <div className="profile-img rectangle-avatar" style={{ backgroundImage: `url(${getImageResource(userTeamDetail.ava,true)})` }}>
                                    <img src="../../../img/avatar.png" alt="image" />
                                </div>
                            </div>
                            <div className="team-content">
                                <h3 className="title">{userTeamDetail.name}</h3>
                                <p className="team-type">
                                    {getTypeName(TEAM_TYPE, userTeamDetail.lgid)} -{' '}
                                    {getTypeName(SPORT_TYPE, userTeamDetail.sport)}
                                </p>
                                <div className="btn btn-primary action-view" onClick={() => goToProfile()}>
                                    Xem thông tin đội
                                    <i className="fa fa-chevron-right next" size="10"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row wrapper-achievements">
                            <div className="col-md-4 achievement">
                                <img src="../../../img/icon-member.png" className="image-icon" alt="image" />
                                <p className="number">{formatNumber(eventUserTeamStats.mem)}</p>
                                <p className="description">Vận động viên</p>
                            </div>

                            <div className="col-md-4 achievement">
                                <img src="../../../img/icon-km.svg" className="image-icon" alt="image" />
                                <p className="number">{formatDistanceKM(eventUserTeamStats.dis, true)} Km</p>
                                <p className="description">Số Km đạt được</p>
                            </div>

                            <div className="col-md-4 achievement">
                                <img src="../../../img/icon-rank.svg" className="image-icon" alt="image" />
                                <p className="number">{formatNumber(eventUserTeamStats.rank)}</p>
                                <p className="description">Xếp hạng</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        else return null;
    })
);

export default TeamInfo;
