import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EVENT_STATUS } from '../../../../helpers/ConstDefined';
import { toJS } from 'mobx';

const EventList = inject('teamStore')(
    observer((props) => {
        const { data, teamID } = props;

        useEffect(() => {}, []);
        const { t } = useTranslation();

        function ListEvent(status) {
            let list = [];
            list = _.filter(data, (item) => {
                return item.sts == status;
            });
            return list;
        }

        function EventItem(props) {
            const { data } = props;
            return (
                <div class="profile-bn">
                    <img src={data.cover} alt="" />
                    <Link to={`team-details?evid=${data.evid}&tmid=${teamID}`}>
                        <button class="btn-40 orange">{t('global.view_rank')}</button>
                    </Link>
                </div>
            );
        }
        return (
            <>
                <div class="profile-wbox bn">
                    <h2 class="profile-title">{t('global.event_join_now')}</h2>
                    {ListEvent(EVENT_STATUS.RUNNING).map((item) => {
                        return <EventItem data={item} key={item.evid} />;
                    })}
                </div>
                <div class="profile-wbox bn">
                    <h2 class="profile-title">{t('global.event_join')}</h2>
                    {ListEvent(EVENT_STATUS.FINISHED).map((item) => {
                        return <EventItem data={item} key={item.evid} />;
                    })}
                </div>
            </>
        );
    })
);

export default EventList;
