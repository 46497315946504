import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { stripedHtml } from '../../../../utils';
import UserSearchItem from './UserSearchItem';
import MemberItem from './MemberItem';
import { toJS } from 'mobx';
import { COMMON_ERROR } from '../../../../helpers/ConstDefined';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';

const SelectBox = styled.div`
    width: 200px;
`;

let limitMember, typeList;

const DialogEditLeaderboard = inject(
    'globalStore',
    'userLeaderboardStore',
    'userStore'
)(
    observer(
        forwardRef((props, ref) => {
            const { openDialogEditLeaderboard, eventID } = props;
            const { userProfile, doAddUserLeaderboard, doUpdateInfoUserLeaderboard } = props.userStore;
            let {
                loading,
                currentUserLeaderboard,
                listLeaderboard,
                listMemberTemp,
                doCreateUserLeaderboard,
                doAddMemberLeaderboard,
                doRemoveMemberLeaderboard,
                doClearAllMember,
                doUpdateUserLeaderboard,
                doDeleteUserLeaderboard
            } = props.userLeaderboardStore;
            const { appVersion, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
            const {
                loadingSearch,
                listUserSearch,
                totalUser,
                doSearchUser,
                pageSearchUser,
                doSetPageIndexSearchUser,
                doIncreasePageSearchUser
            } = props.userStore;

            const [name, setName] = React.useState('');
            const [nameUser, setNameUser] = React.useState('');
            const [team, setTeam] = React.useState(0); //type all

            const showClearName = name && name.length > 0;
            const isShowLoadMore = listUserSearch == null ? false : listUserSearch.length < totalUser;

            useImperativeHandle(ref, () => ({
                initData(isEdit) {
                    if (!isEdit) {
                        setName('');
                        doClearAllMember();
                    } else {
                        setName(currentUserLeaderboard.name);
                    }

                    setNameUser('');
                    let params = {
                        uid: userProfile.uid,
                        sort: 7,
                        target: 1,
                        name: ''
                    };
                    doSearchUser(params);
                }
            }));

            useEffect(() => {
                getListUser();
                initTeamDropdown();
                limitMember = appVersion ? appVersion.customRankingMaxMember : 100;
            }, []);

            function initTeamDropdown() {
                typeList = Object.keys(userProfile.tmls).map((key) => ({
                    value: userProfile.tmls[key].tmid,
                    label: userProfile.tmls[key].name
                }));
    
                typeList.unshift({value: 0, label: 'Tất cả'});
            }
           
            function getListUser() {
                let params = {
                    uid: userProfile.uid,
                    sort: 7,
                    target: 1,
                    name: nameUser
                };
                doSearchUser(params);
            }

            const handleChangeTeam = (team) => {
                setTeam(team);
                doSetPageIndexSearchUser(1); //reset page index when search
                let params = {
                    uid: userProfile.uid,
                    sort: 7,
                    target: 1,
                    name: nameUser
                };

                if(team != 0) params.tmid = team;
                doSearchUser(params);
            };

            const handleTeamChange = (selectedOption) => {
                setTeam(selectedOption.value);
                handleChangeTeam(selectedOption.value);
            };

            const handleChangeNameUser = (val) => {
                setNameUser(val);
            };

            function checkLimitMember(isAdd) {
                const maxMember = isAdd ? limitMember - 1 : limitMember;
                if (listMemberTemp.length <= maxMember) return true;
                else return false;
            }

            function searchMember() {
                doSetPageIndexSearchUser(1); //reset page index when search
                let params = {
                    uid: userProfile.uid,
                    sort: 7,
                    target: 1,
                    name: nameUser
                };
                
                if(team != 0) params.tmid = team;
                doSearchUser(params);
            }

            function searchMemberMore() {
                doIncreasePageSearchUser();
                let params = {
                    from: pageSearchUser * appVersion.pgsz,
                    size: appVersion.pgsz,
                    uid: userProfile.uid,
                    sort: 7,
                    target: 1,
                    name: nameUser
                };

                if(team != 0) params.tmid = team;
                doSearchUser(params, true);
            }

            const handleKeyDown = (e) => {
                if (e.key === 'Enter') {
                    searchMember();
                }
            };

            const onCloseDialog = () => {
                props.onClose();
            };

            const handleClearName = () => {
                setName('');
            };

            function validInput() {
                let hasError = false;
                if (!name || name.trim().length == 0) {
                    setMessageSnackbar('Nhập tên bảng xếp hạng của bạn');
                    setSnackbarType('error');
                    onOpenSnackbar();
                    hasError = true;
                }
                return hasError;
            }

            function handleAction() {
                if (currentUserLeaderboard != null) {
                    editUserLeaderboard();
                } else {
                    createUserLeaderboard();
                }
            }

            function showMessage(msg, type) {
                setMessageSnackbar(msg);
                setSnackbarType(type);
                onOpenSnackbar();
            }

            async function editUserLeaderboard() {
                try {
                    if (!validInput()) {
                        if (!checkLimitMember()) {
                            setMessageSnackbar('Số thành viên vượt quá cho phép.');
                            setSnackbarType('error');
                            onOpenSnackbar();
                            return;
                        }

                        let params = {
                            rkid: currentUserLeaderboard.rkid,
                            uid: userProfile.uid,
                            name
                        };
                        let result = await doUpdateUserLeaderboard(params);
                        if (result) {
                            //update store local
                            let leaderboard = {
                                rkid: currentUserLeaderboard.rkid,
                                name,
                                mem: listMemberTemp.length
                            };
                            doUpdateInfoUserLeaderboard(leaderboard);

                            let listRemove = toJS(listLeaderboard).filter(
                                ({ uid: id1 }) => !toJS(listMemberTemp).some(({ uid: id2 }) => id2 === id1)
                            );
                            let listAdd = toJS(listMemberTemp).filter(
                                ({ uid: id1 }) => !toJS(listLeaderboard).some(({ uid: id2 }) => id2 === id1)
                            );

                            console.log(toJS(listAdd));
                            console.log(toJS(listRemove));

                            if (listRemove.length > 0) {
                                let params = {
                                    uid: userProfile.uid,
                                    rkid: currentUserLeaderboard.rkid,
                                    mbls: getListIDMember(listRemove)
                                };
                                let result = await doRemoveMemberLeaderboard(params);
                                if (result) {
                                    if (listAdd.length > 0) {
                                        params = {
                                            uid: userProfile.uid,
                                            rkid: currentUserLeaderboard.rkid,
                                            mbls: getListIDMember(listAdd)
                                        };

                                        result = await doAddMemberLeaderboard(params);
                                        if (result && result.ercd == COMMON_ERROR.SUCCESS) {
                                            showMessage('Cập nhật bảng xếp hạng thành công.', 'success');
                                            props.onClose();
                                        } else if (result.ercd == COMMON_ERROR.OVER_QUOTA) {
                                            setMessageSnackbar('Số thành viên vượt quá cho phép.');
                                            setSnackbarType('error');
                                        } else {
                                            showMessage('Cập nhật bảng xếp hạng thất bại.', 'error');
                                            props.onClose();
                                        }
                                    } else {
                                        showMessage('Cập nhật bảng xếp hạng thành công.', 'success');
                                        props.onClose();
                                    }
                                } else {
                                    showMessage('Cập nhật bảng xếp hạng thất bại.', 'error');
                                    props.onClose();
                                }
                            } else {
                                if (listAdd.length > 0) {
                                    params = {
                                        uid: userProfile.uid,
                                        rkid: currentUserLeaderboard.rkid,
                                        mbls: getListIDMember(listAdd)
                                    };

                                    result = await doAddMemberLeaderboard(params);
                                    if (result && result.ercd == COMMON_ERROR.SUCCESS) {
                                        showMessage('Cập nhật bảng xếp hạng thành công.', 'success');
                                        props.onClose();
                                    } else if (result.ercd == COMMON_ERROR.OVER_QUOTA) {
                                        setMessageSnackbar('Số thành viên vượt quá cho phép.');
                                        setSnackbarType('error');
                                    } else {
                                        showMessage('Cập nhật bảng xếp hạng thất bại.', 'error');
                                        props.onClose();
                                    }
                                } else {
                                    showMessage('Cập nhật bảng xếp hạng thành công.', 'success');
                                    props.onClose();
                                }
                            }
                        } else {
                            showMessage('Cập nhật bảng xếp hạng thất bại.', 'error');
                            props.onClose();
                        }
                        onOpenSnackbar();
                    }
                } catch (err) {}
            }

            function getListIDMember(data) {
                let result = [];
                _.forEach(data, (item) => {
                    result.push(item.uid);
                });
                return result;
            }

            async function createUserLeaderboard() {
                if (!validInput()) {
                    if (!checkLimitMember(true)) {
                        setMessageSnackbar('Số thành viên vượt quá cho phép.');
                        setSnackbarType('error');
                        onOpenSnackbar();
                        return;
                    }

                    let params = {
                        uid: userProfile.uid,
                        rksrc: eventID,
                        name
                    };
                    let result = await doCreateUserLeaderboard(params);

                    if (result && result.ercd == COMMON_ERROR.SUCCESS) {
                        result.data.mem = listMemberTemp.length + 1;
                        doAddUserLeaderboard(result.data);

                        if (listMemberTemp.length > 0) {
                            params = {
                                uid: userProfile.uid,
                                rkid: result.data.rkid,
                                mbls: getListIDMember(listMemberTemp)
                            };
                            result = await doAddMemberLeaderboard(params); //add member
                            if (result && result.ercd == COMMON_ERROR.SUCCESS) {
                                setMessageSnackbar('Tạo bảng xếp hạng thành công.');
                                setSnackbarType('success');
                                props.onClose();
                            } else if (result.ercd == COMMON_ERROR.OVER_QUOTA) {
                                setMessageSnackbar('Số thành viên vượt quá cho phép.');
                                setSnackbarType('error');
                            } else {
                                setMessageSnackbar('Thêm thành viên vào bảng xếp hạng thất bại.');
                                setSnackbarType('error');
                            }
                        } else {
                            setMessageSnackbar('Tạo bảng xếp hạng thành công.');
                            setSnackbarType('success');
                            props.onClose();
                        }
                    } else if (result.ercd == COMMON_ERROR.OVER_QUOTA) {
                        setMessageSnackbar('Đã hết số lần cho phép tạo Bảng xếp hạng cá nhân trong ngày');
                        setSnackbarType('error');
                    } else {
                        setMessageSnackbar('Tạo bảng xếp hạng thất bại');
                        setSnackbarType('error');
                    }
                    onOpenSnackbar();
                }
            }

            function LoadMore() {
                if (isShowLoadMore) {
                    if (loadingSearch)
                        return (
                            <div className="load-more">
                                <CircularProgress disableShrink />
                            </div>
                        );
                    else
                        return (
                            <>
                                <div className="load-more">
                                    <button className="btn btn-primary" onClick={() => searchMemberMore()}>
                                        Xem thêm
                                    </button>
                                </div>
                            </>
                        );
                } else return null;
            }

            return (
                <Modal
                    show={openDialogEditLeaderboard}
                    className="parent-customize-leaderboard-dialog"
                    onHide={onCloseDialog}>
                    <div
                        className="custom-modal-complex custom-modal-sticky modal-form-customize-leaderboard"
                        role="dialog"
                        aria-hidden="true">
                        <div className="custom-modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="title">Bảng xếp hạng tự tạo</h3>

                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => onCloseDialog()}>
                                        <img src="../../../img/icon-close-2.png" alt="image" />
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h4 className="title">Tên bảng xếp hạng</h4>
                                            <div className="form-group-user">
                                                <div className="wrapper-input">
                                                    <div className="form-label-group form-control">
                                                        <input
                                                            type="text"
                                                            value={name}
                                                            onChange={(text) => setName(stripedHtml(text.target.value))}
                                                            className="form-control"
                                                            placeholder="Tên bảng xếp hạng"
                                                            required=""
                                                            autofocus=""
                                                        />

                                                        {showClearName ? (
                                                            <img
                                                                src="../../img/register/close.svg"
                                                                onClick={() => handleClearName()}
                                                                className="close-text"
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <h4 className="title">Thêm thành viên</h4>
                                            <div className="wrapper-search">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <img src="../../../img/icon-search.png" alt="image" />
                                                        </span>
                                                    </div>
                                                    <input
                                                        id="search"
                                                        type="text"
                                                        value={nameUser}
                                                        onKeyDown={handleKeyDown}
                                                        onChange={(text) =>
                                                            handleChangeNameUser(stripedHtml(text.target.value))
                                                        }
                                                        className="form-control form-search"
                                                        name="search"
                                                        placeholder="Tìm tên VĐV"
                                                    />

                                                    <div className="clearfix">
                                                        <SelectBox>
                                                            {typeList ? <Select
                                                                options={typeList}
                                                                defaultValue={typeList[0]}
                                                                onChange={handleTeamChange}
                                                            /> : null}
                                                        </SelectBox>
                                                    </div>

                                                    <button
                                                        className="btn btn-primary btn-search"
                                                        onClick={() => searchMember()}>
                                                        Tìm kiếm
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="table-responsive custom-table custom-table-list-member-2">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <p>Tên VĐV</p>
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {listUserSearch != null &&
                                                            listUserSearch.map((item) => (
                                                                <UserSearchItem
                                                                    key={item.uid}
                                                                    data={toJS(item)}
                                                                    navigation={props.navigation}></UserSearchItem>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {listUserSearch && listUserSearch.length == 0 ? (
                                                    <p style={{ textAlign: 'center' }}>
                                                        Không tìm thấy VĐV theo yêu cầu
                                                    </p>
                                                ) : null}
                                                <LoadMore />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group-user">
                                                <h4 className="title">Danh sách thành viên ({listMemberTemp.length})</h4>

                                                <div className="table-responsive custom-table custom-table-list-member-3">
                                                    <table className="table table-striped">
                                                        <tbody>
                                                            {listMemberTemp != null &&
                                                                listMemberTemp.map((item) => (
                                                                    <MemberItem
                                                                        key={item.uid}
                                                                        data={toJS(item)}
                                                                        navigation={props.navigation}></MemberItem>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="form-group" onClick={() => handleAction()}>
                                                    <button
                                                        className="btn btn-primary btn-confirm"
                                                        type="submit"
                                                        disabled={loading}
                                                        name="submit_reg">
                                                        Lưu
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        })
    )
);

export default DialogEditLeaderboard;
