import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { formatDistanceKMFloor, formatNumber, formatDistanceKM } from '../../../../../utils';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const Achievement = inject('eventStore')(
    observer((props) => {
        const { currentEvent } = props.eventStore;
        const [percentDisOld, setPercentDisOld] = useState(0);
        const [percentDis, setPercentDis] = useState(0);
        const [percentDisOver, setPercentDisOver] = useState(0);

        useEffect(() => {
            calcEventInfo();
        }, [currentEvent]);

        const { t } = useTranslation();

        function calcEventInfo() {
            if (!currentEvent) return;

            const target = currentEvent.evdis/1000,
                targetOld = currentEvent.pevdis/1000;
            const totalDis = Math.round(currentEvent.dis / 1000);

            const percentDisOld = (targetOld / target) * 100 > 100 ? 100 : (targetOld / target) * 100;
            const percentDis =
                (totalDis / target) * 100 > percentDisOld ? percentDisOld + 3 : (totalDis / target) * 100;
            let percentDisOver = 0;
            if (percentDis >= percentDisOld)
                percentDisOver = (totalDis / target) * 100 > 100 ? 100 : (totalDis / target) * 100;

            setPercentDisOld(percentDisOld);
            setPercentDis(percentDis);
            setPercentDisOver(percentDisOver);
        }

        if (!currentEvent) return null;

        return (
            <div class="info--achieve">
                <h2 class="info--title">{t('global.target_2021_text').replace('2021', '2023')}</h2>
                <div class="achieve-box">
                    <div class="km">
                        <span class="lb">{t('global.get_target')}</span>
                        <CountUp
                            start={0}
                            end={Math.floor(currentEvent.dis / 1000)}
                            formattingFn={(num) => num.toLocaleString()}
                            delay={0}
                            duration={2}>
                            {({ countUpRef }) => (
                                <span class="num" ref={countUpRef}>
                                    {formatDistanceKMFloor(currentEvent.dis, true)}
                                </span>
                            )}
                        </CountUp>

                        <span class="unit">km</span>
                    </div>

                    <div class="process">
                        {/* <p class="number">
                            <span class="num">{currentEvent.evdis/1000000000} {t('global.million')}</span>
                            <span class="unit"> km</span>
                        </p> */}
                        <span class="run-ico" style={{ display: 'none' }}></span>
                        <div class="performance" style={{ left: `${percentDisOld}%` }}>
                            <button class="circle white"></button>
                            <div class="process-tooltip">
                                <p class="txt">{t('global.target_2020_text').replace('2020', '2022')}</p>
                                <p class="num">
                                    {formatNumber(parseInt(currentEvent.pevdis/1000))} <span class="unit">km</span>
                                </p>
                            </div>
                        </div>
                        <div class="line grey"></div>
                        <div class="line orange" style={{ width: `${percentDisOver}%`, display: 'block' }}></div>
                        <div class="line blue" style={{ width: `${percentDis}%`, display: 'block' }}></div>
                    </div>

                    <ul class="joined-list">
                        <li class="team">
                            <div class="joined-list-box">
                                <span class="ico"></span>
                                <p class="num">{formatNumber(currentEvent.mem)}</p>
                                <p class="lb">{t('global.member_join')}</p>
                            </div>
                        </li>
                        <li class="group">
                            <div class="joined-list-box">
                                <span class="ico"></span>
                                <p class="num">{formatNumber(currentEvent.team)}</p>
                                <p class="lb">{t('global.team_join')}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    })
);

export default Achievement;
