import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ImageItem from './ImageItem';

const ImageList = inject(
    'authStore',
    'globalStore',
    'postStore'
)(
    observer((props) => {
        const { currentPost } = props.postStore;
        const {
            arrayImageFeedReal,
            doResetImageFeed,
            doRemoveImageFeed,
            doSetImageUpload,
            doSetImageUploadReal
        } = props.globalStore;

        const onRemoveImage = (url) => {
            doRemoveImageFeed(url);
        };

        useEffect(() => {
            getPhotoPost();
        }, [currentPost]);

        function getPhotoPost() {
            if (currentPost != null) {
                doResetImageFeed();
                currentPost.photos.map((url) => {
                    if (url != '') {
                        doSetImageUpload(url);
                        doSetImageUploadReal(url);
                    }
                });
            }
        }

        return (
            <div className="imgs-list">
                {arrayImageFeedReal.map((url) => (
                    <ImageItem key={url} removeImage={() => onRemoveImage(url)} imgPath={url} />
                ))}
            </div>
        );
    })
);

export default ImageList;
