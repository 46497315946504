import { observable, action, toJS } from 'mobx';
import { requestAPIV2 } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, API_STATUS } from '../helpers/api/APIDefined';
import { log } from '../utils';
import _ from 'lodash';
import { LIKE_TYPE, SOCIAL_TYPE } from 'helpers/ConstDefined';

export default class PostStore {
    @observable listPost = [];
    @observable currentPost = null;
    @observable pinPost = null;
    @observable loading = false;

    @action.bound
    doSetPinPostData(data) {
        this.pinPost = data;
    }

    @action.bound
    doSetCurrentPostData(data) {
        this.currentPost = data;
    }

    @action.bound
    doGetListPost(params) {
        let result = null;
        this.loading = true; //show progress
        requestAPIV2(API.POST.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    this.listPost = resp.data.list;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doLike(params, type) {
        let result = null;
        this.loading = true; //show progress

        const api = type == SOCIAL_TYPE.FEED ? API.FEED.DO_LIKE : API.POST.DO_LIKE;
        await requestAPIV2(api, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    result = resp.data;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetDetailPostInfo(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.BASIC, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    if (resp.data) this.currentPost = resp.data;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doMarkPost(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.PIN, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    result = true;
                    //update local
                    const obj = _.find(this.listPost, (item) => {
                        return item.psid == params.psid;
                    });
                    if (obj) {
                        const pin = params.action == 0 ? 1 : 0;
                        obj.pin = pin;
                    }
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doGetCurrentPinPost(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    if (resp.data.list.length > 0) this.pinPost = resp.data.list[0];
                    else this.pinPost = null;
                    console.log('111', toJS(this.pinPost));
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doCreatePost(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.CREATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    result = true;
                    this.pinPost = resp.data;
                    this.listPost.push(resp.data);
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doDeletePost(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.DELETE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    result = true;

                    //update local
                    _.remove(this.listPost, (item) => {
                        return item.psid == params.psid;
                    });
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    async doUpdatePost(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.POST.UPDATE, params, HTTP_METHOD.POST)
            .then((resp) => {
                this.loading = false;
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    result = false;
                } else {
                    result = true;

                    //update local
                    const obj = _.find(this.listPost, (item) => {
                        return item.psid == params.psid;
                    });
                    if (obj) {
                        obj.title = params.title;
                        obj.desc = params.desc;
                        obj.photos = params.photos;
                    }

                    //cập nhật data pinPost in local
                    if (this.pinPost && this.pinPost.psid == params.psid) {
                        this.pinPost.title = params.title;
                        this.pinPost.desc = params.desc;
                        this.pinPost.photos = params.photos;
                    }
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }
}
