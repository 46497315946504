import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Image from 'react-graceful-image';
import CircularProgress from '@material-ui/core/CircularProgress';

const ImgReview = inject('authStore')(
    observer((props) => {
        let { imgPath } = props;
        imgPath = imgPath.replace('.png', '@2x.png').replace('.jpeg', '@2x.jpeg');

        useEffect(() => {}, []);

        return (
            <div class="img">
                <div class="img-inside">
                    <button class="del-btn" onClick={props.removeImage}></button>
                    <Image src={imgPath} noLazyLoad={true} alt="" />
                </div>
            </div>
        );
    })
);

export default ImgReview;
