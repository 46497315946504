import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_HOME_TYPE } from '../../helpers/ConstDefined';
import Banner from './Banner';
import Toolbar from './Toolbar';
import { HomeEvent, ViewAllEvent, YourEvent, FilterEvent } from './components';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import moment from 'moment';

const EventHome = inject(
    'userEventStore',
    'eventStore',
    'userStore'
)(
    observer((props) => {
        const { tabEventHome, filterEventStatus } = props.userEventStore;
        const { t, i18n } = useTranslation();

        function initFilterCanvas() {
            let canvas = $('.offcanvas');
            const body = document.body;

            /*$('body,html').click(function(e) {
                if (canvas.length && canvas.hasClass('show')) {
                    $(canvas).removeClass('show');
                    $('.offcanvas-backdrop.show').remove();
                    $('html').css({ overflow: 'auto' });
                    body.classList.remove('lock-scrollbar');
                }
            });

            canvas.click(function(e) {
                e.stopPropagation();
            });*/

            $('[data-toggle="offcanvas"]').on('click', function(e) {
                e.stopPropagation();
                let canvas = $(this).attr('data-target');
                if (canvas) {
                    $(canvas).addClass('show');
                    $(canvas).after('<div class="offcanvas-backdrop show"></div>');
                    $('html').css({ overflow: 'hidden' });
                    body.classList.add('lock-scrollbar');
                    /*html.classList.add("lock-scrollbar");
                    body.classList.add("lock-scrollbar");*/
                }
            });

            $('[data-dismiss="offcanvas"]').on('click', function(e) {
                //e.stopPropagation();
                let canvas = $(this).closest('.offcanvas');

                if (canvas) {
                    $(canvas).removeClass('show');
                    $('.offcanvas-backdrop.show').remove();
                    $('html').css({ overflow: 'auto' });
                    body.classList.remove('lock-scrollbar');
                    /*html.classList.remove("lock-scrollbar");
                    body.classList.remove("lock-scrollbar");*/
                }
            });
        }

        useEffect(() => {
            initFilterCanvas();
        }, []);

        return (
            <section class="users-event-page">
                <div class="users-event-wrap">
                    <Banner />
                    <Toolbar navigation={props.history} />
                    {tabEventHome == EVENT_HOME_TYPE.HOME_EVENT && <HomeEvent navigation={props.history} />}
                    {tabEventHome == EVENT_HOME_TYPE.YOUR_EVENT && <YourEvent navigation={props.history} />}
                    {tabEventHome == EVENT_HOME_TYPE.ALL_EVENT && <ViewAllEvent navigation={props.history} />}
                </div>
                <FilterEvent />
            </section>
        );
    })
);

export default EventHome;
