import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';

const CancelCreateEventDialog = inject(
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { navigation, openDialogCancelCreateEvent } = props;

        const { t } = useTranslation();

        const onCancelCreateEvent = () => {
            navigation.push('/event');
        };

        return (
            <Dialog open={openDialogCancelCreateEvent}>
                <div
                    class="uprace-popup"
                    id="cancel-create-event"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content rounded">
                            <div class="popup-body">
                                <div class="icon-warning"></div>
                                <div>
                                    <h5 class="popup-title">{t('eu.cancel_create_event')}</h5>
                                    <div class="popup-text">{t('eu.cancel_create_event_desc')}</div>
                                    <div class="action">
                                        <button
                                            type="button"
                                            class="btn btn-outline-blue"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => onCancelCreateEvent()}>
                                            {t('global.destroy')}
                                        </button>
                                        <button type="button" class="btn btn-blue" data-dismiss="modal" onClick={props.onClose}>
                                            {t('eu.continous')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CancelCreateEventDialog;
