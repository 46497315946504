import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography, CardMedia } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    },
    qrcode: {
        width: 150,
        height: 150,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2)
    },
}));

const Profile = inject('authStore')(
    observer(props => {
    const { className } = props;

    const classes = useStyles();

    const imagePath = APP_BASENAME + "/images/logos/help.png";
    const imageQR = APP_BASENAME + "/images/qrcode_esale.jpg";

    return (
        <div className={clsx(classes.root, className)}>
            <CardMedia className={classes.qrcode} image={imageQR} title="Live from space album cover" />
            <Avatar
                alt="Person"
                className={classes.avatar}
                //component={RouterLink}
                src={imagePath}
                //to="/settings"
            />
            <Typography className={classes.name} variant="h5">
               Hỗ trợ khách hàng
            </Typography>
            <Typography variant="h6">
            Hotline: 1900.545.436
            </Typography>
            
        </div>
    );
}));

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
