/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors, Typography, ListItemText } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BlockIcon from '@material-ui/icons/Block';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toJS } from 'mobx';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemSelected: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#81D4FA',
        borderRadius: 20
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    buttonMaintain: {
        color: colors.blueGrey[800],
        padding: '0px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    primary: {
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        backgroundColor: '#336699',
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    },
    divider: {
        margin: theme.spacing(1, 0)
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

let tempCategory;

const SidebarNav = inject(
    'productStore',
    'supplierStore',
    'orderStore',
    'cartStore'
)(
    observer((props) => {
        const [open, setOpen] = React.useState(false);
        const { className } = props;
        const { listCategory, currentCategory, setCurrentCategory, getAllCategory, maintainInfo } = props.productStore;
        const { getAllSupplierByType, setCurrentSupplier } = props.supplierStore;
        const { setPaymentMethod, clearOrderData } = props.orderStore;
        const { listCart, deleteCart } = props.cartStore;

        const classes = useStyles();

        useEffect(() => {
            if (listCategory == null) {
                getAllCategory();
            }
        });

        const selectCategory = (category) => {
            if (category.id == currentCategory.id) return;

            tempCategory = category;
            if (listCart.length > 0) {
                handleOpenDialog();
                return;
            }

            setCurrentSupplier({});
            setCurrentCategory(category.id);
            getAllSupplierByType(category.type);
            setPaymentMethod(CHECKOUT_METHOD.QR_CODE);
        };

        const handleOpenDialog = () => {
            setOpen(true);
        };

        const handleCloseDialog = (type) => {
            setOpen(false);
            if (type == 2) {
                deleteCart();
                clearOrderData();
                setCurrentCategory(tempCategory.id);
                getAllSupplierByType(tempCategory.type);
                setPaymentMethod(CHECKOUT_METHOD.QR_CODE);
            }
        };

        function MenuItem(props) {
            const { category } = props;
            const cssSelected = category.id == currentCategory.id ? classes.itemSelected : classes.item;
            let name = category.name;

            if (_.includes(maintainInfo.listID, category.id))
                return (
                    <ListItem key={category.id} className={cssSelected} disableGutters>
                        <Button
                            className={classes.buttonMaintain}
                            onClick={() => selectCategory(category)}
                            component={CustomRouterLink}
                            to={category.link}>
                            <div className={classes.icon}>
                                <BlockIcon></BlockIcon>
                            </div>
                            <ListItemText
                                classes={{
                                    primary: classes.primary
                                    //secondary: classes.secondary,
                                }}
                                primary={name}
                                secondary="Đang bảo trì"
                            />
                        </Button>
                    </ListItem>
                );
            else
                return (
                    <ListItem key={category.id} className={cssSelected} disableGutters>
                        <Button
                            className={classes.button}
                            onClick={() => selectCategory(category)}
                            component={CustomRouterLink}
                            to={category.link}>
                            <div className={classes.icon}>
                                <FiberManualRecordIcon></FiberManualRecordIcon>
                            </div>
                            {name}
                        </Button>
                    </ListItem>
                );
        }

        if (listCategory != null)
            return (
                <div className={classes.root}>
                    <List className={clsx(classes.root, className)}>
                        {listCategory.map((cat) => (
                            <MenuItem key={cat.id} category={cat} />
                        ))}
                    </List>

                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{'Hủy bỏ đơn hàng'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Bạn đang có đơn hàng chưa hoàn thành vui lòng thanh toán đơn hàng hoặc hủy bỏ giao
                                dịch cũ
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseDialog(1)} color="primary">
                                Đóng
                            </Button>
                            <Button onClick={() => handleCloseDialog(2)} color="secondary" autoFocus>
                                Hủy đơn hàng cũ
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        else return null;
    })
);

SidebarNav.propTypes = {
    className: PropTypes.string
};

export default SidebarNav;
