export function Slider() {
    const slider = (function() {
        const slider = document.getElementById('sliderGroupChallenges');
        const sliderContent = document.querySelector('.slider-content');
        const sliderWrapper = document.querySelector('.slider-content-wrapper');
        const elements = document.querySelectorAll('.slider-content__item');
        const leftArrow = document.getElementById('prev-arrow');
        const rightArrow = document.getElementById('next-arrow');
        let lengthFix = elements.length;
        let intervalId = null;
        let mediaQuery = window.matchMedia('(max-width: 768px)');
    
        // data
        const itemsInfo = {
            offset: 0,
            position: {
                current: 0,
                min: 0,
                max: mediaQuery.matches ? lengthFix / 2 - 1 : lengthFix / 5 - 1
            },
            intervalSpeed: 2000,
    
            update: function(value) {
                this.position.current = value;
                this.offset = -value;
            },
            reset: function() {
                this.position.current = 0;
                this.offset = 0;
            }
        };
    
        const controlsInfo = {
            prevButtonDisabled: true,
            nextButtonDisabled: true
        };
    
        function init() {
            if (slider && sliderContent && sliderWrapper && elements) {
                _updateControlsInfo();
                _createControls();
                _render();
            } else {
                console.log(
                    "The slider layout is set incorrectly. Check for all required classes 'slider/slider-content/slider-wrapper/slider-content__item'"
                );
            }
        }
    
        function _updateControlsInfo() {
            const { current, min, max } = itemsInfo.position;
            controlsInfo.prevButtonDisabled = current <= min;
            controlsInfo.nextButtonDisabled = current >= max;
        }
    
        function _createControls() {
            createArrows();
    
            // Arrows function
            function createArrows() {
                leftArrow.addEventListener('click', () => updateItemsInfo(itemsInfo.position.current - 1));
                rightArrow.addEventListener('click', () => updateItemsInfo(itemsInfo.position.current + 1));
            }
        }
    
        function setClass(options) {
            if (options) {
                options.forEach(({ element, disabled }) => {
                    if (element) {
                        disabled ? (element.disabled = true) : (element.disabled = false);
                    } else {
                        console.log('Error: function setClass(): element = ', element);
                    }
                });
            }
        }
    
        function updateItemsInfo(value) {
            itemsInfo.update(value);
            _slideItem(true);
        }
    
        function _render() {
            const { prevButtonDisabled, nextButtonDisabled } = controlsInfo;
            let controlsArray = [
                { element: leftArrow, disabled: prevButtonDisabled },
                { element: rightArrow, disabled: nextButtonDisabled }
            ];
    
            setClass(controlsArray);
    
            sliderWrapper.style.transform = `translateX(${itemsInfo.offset * 100}%)`;
        }
    
        function _slideItem(autoMode = false) {
            if (autoMode && intervalId) {
                clearInterval(intervalId);
            }
            _updateControlsInfo();
            _render();
        }
    
        function createHTMLElement(tagName = 'div', className, innerHTML) {
            const element = document.createElement(tagName);
            className ? (element.className = className) : null;
            innerHTML ? (element.innerHTML = innerHTML) : null;
            return element;
        }
    
        return { init };
    })();
    
    slider.init({
        currentItem: 0
    });
}
