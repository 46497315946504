import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { TabHeader } from '..';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import MemberList from './MemberList';
import GroupList from './GroupList';
import ComplainList from './ComplainList';
import { GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';

const ManageTeam = inject(
    'userStore',
    'teamManageStore',
    'teamStore',
    'groupStore',
    'globalStore'
)(
    observer((props) => {
        useEffect(() => {
            getListMember();
            getListAdmin();
        }, []);

        const { t } = useTranslation();

        const { eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { appVersion } = props.globalStore;
        const { tab, search, doGetListMemberTeam, doGetListAdminTeam, doSetFunc } = props.teamManageStore;

        const { doGetListGroupRequest, doGetListGroupJoin } = props.groupStore;

        const getListMember = (isLoadMore) => {
            const params = {
                size: appVersion.pgsz,
                uid: userProfile.uid,
                tmid: teamID,
                evid: eventID,
                name: search,
                tmad: -teamID
            };
            doGetListMemberTeam(params, isLoadMore);
        };

        const onLoadMoreMember = () => {
            getListMember(true);
        };

        const onLoadMoreGroupJoin = () => {
            getListGroupJoin(true);
        };

        const onLoadMoreGroupRq = () => {
            getListGroupRequest(true);
        };

        const getListAdmin = () => {
            const params = {
                uid: userProfile.uid,
                tmid: teamID,
                evid: eventID,
                tmad: teamID,
                name: search
            };
            doGetListAdminTeam(params);
        };

        const getListGroupRequest = (isLoadMore) => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                name: search,
                gejsts: GROUP_JOIN_STATUS.REQUESTING //Đang chờ duyệt
            };
            doGetListGroupRequest(params, isLoadMore);
        };

        const getListGroupJoin = (isLoadMore) => {
            const params = {
                size: appVersion.pgsz,
                uid: userProfile && userProfile.uid,
                evid: eventID,
                tmid: teamID,
                name: search,
                gejsts: GROUP_JOIN_STATUS.JOINED //Đã tham gia
            };
            doGetListGroupJoin(params, isLoadMore);
        };

        const resetSearch = () => {
            if (tab == 0) {
                getListAdmin();
                getListMember();
            } else {
                getListGroupRequest();
                getListGroupJoin();
            }
        };

        function RenderContent() {
            if (tab == 0) return <MemberList onLoadMoreMember={onLoadMoreMember} eventID={eventID} teamID={teamID} />;
            else if (tab == 1)
                return (
                    <GroupList
                        onLoadMoreGroupJoin={onLoadMoreGroupJoin}
                        onLoadMoreGroupRq={onLoadMoreGroupRq}
                        eventID={eventID}
                        teamID={teamID}
                    />
                );
            else if (tab == 2) return <ComplainList eventID={eventID} teamID={teamID} />;
        }

        return (
            <div className="manage-page">
                <div className="manage-wrap">
                    <a href="javascript:void(0);" onClick={() => doSetFunc(0)} className="back-btn">
                        {t('global.back')}
                    </a>
                    <TabHeader resetSearch={resetSearch} />
                    <div className="tabs-content manage-tabs-content">
                        <ul>
                            <li className="active">
                                <RenderContent />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    })
);

export default ManageTeam;
