import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils';
import _ from 'lodash';

let soData;

const SOUserInfo = inject(
    'userProfileStore',
    'userStore'
)(
    observer((props) => {
        const { profileData, eventID } = props;
        const { eventUserStats, listSOUser, eventUserData, doGetEventUserData } = props.userProfileStore;
        const [fundUser, setFundUser] = React.useState(false);

        useEffect(() => {
            getUserEventInfo();
        }, [profileData]);

        useEffect(() => {
            getFundUserInfo();
        }, [listSOUser, eventUserData]);

        const { t } = useTranslation();

        function getUserEventInfo() {
            const params = {
                uid: profileData.id,
                evid: eventID
            };
            doGetEventUserData(params);
        }

        function getFundUserInfo() {
            if (!eventUserData || eventUserData.fuid == 0 || !listSOUser) return;
            const obj = _.find(listSOUser, (item) => {
                return item.fuid == eventUserData.fuid;
            });
            setFundUser(obj);
        }

        if (listSOUser != null && eventUserData && eventUserData.fuid > 0 && fundUser)
            return (
                <div class="yours--tcxh-chosen">
                    <p class="logo">
                        <img src={fundUser.ava} alt="" />
                    </p>
                    <p class="money">
                        <span class="num">{formatNumber(eventUserStats && eventUserStats.donate)}</span>
                        <span class="unit">đ</span>
                    </p>
                    <p class="txt">{t('global.money_you_donate')}</p>
                    <p class="name">{fundUser.name}</p>
                </div>
            );
        else return null;
    })
);

export default SOUserInfo;
