import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

const ShareEvent = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);
        const { t } = useTranslation();

        const post = {
            title: 'DucLM2',
            description: 'New Description',
            image:
                'https://vnn-imgs-a1.vgcloud.vn/icdn.dantri.com.vn/2021/05/26/ngo-ngang-voi-ve-dep-cua-hot-girl-anh-the-chua-tron-18-docx-1622043349706.jpeg'
        };
        return (
            <div>
                <Helmet
                    title={post.title}
                    meta={[
                        { name: 'twitter:image', content: post.image },

                        { property: 'og:title', content: post.title },
                        { property: 'og:image', content: post.image },
                        { property: 'og:description', content: post.description }
                    ]}
                />
            </div>
        );
    })
);

export default ShareEvent;
