import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DownloadApp = inject('authStore')(
    observer((props) => {
        const { data } = props;
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const { t } = useTranslation();

        return (
            <section className="section section--wrapper ">
                <div className="container">
                    <h1 className="text-center space-40">{t('landing_page.download.title')}</h1>
                    <p className="space-80">{t('landing_page.download.desc')}</p>
                    <h1 className="text-center space-30">{t('landing_page.download.func.title')}</h1>
                    <div className="features flex wrap align-item-center space-100">
                        <div className="feature flex column align-item-center">
                            <img className="space-10 lazyload" data-src="images/icons/feature-1.png" alt="" />
                            <p className="text-center">{t('landing_page.download.func.l2')}</p>
                        </div>
                        <div className="feature flex column align-item-center">
                            <img className="space-10 lazyload" data-src="images/icons/feature-2.png" alt="" />
                            <p className="text-center">{t('landing_page.download.func.l1')}</p>
                        </div>
                        <div className="feature flex column align-item-center">
                            <img className="space-10 lazyload" data-src="images/icons/feature-3.png" alt="" />
                            <p className="text-center">{t('landing_page.download.func.l3')}</p>
                        </div>
                        <div className="feature flex column align-item-center">
                            <img className="space-10 lazyload" data-src="images/icons/feature-4.png" alt="" />
                            <p className="text-center">{t('landing_page.download.func.l4')}</p>
                        </div>
                    </div>
                    <div className="download flex  align-item-center justify-content-space-between">
                        <div className="download--left">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('landing_page.download.invite.title')
                                }}></div>
                            <div className="flex align-item-center justify-content-center">
                                <img className="qr lazyload" data-src="../img/footer/qrcode.png" alt="" />
                                <div className="download-btn">
                                    <a className="download-ios" href="https://apps.apple.com/us/app/id1476173971">
                                        <img className="lazyload" data-src="images/download/download-ios.png" alt="" />
                                    </a>
                                    <a className="download-and" href="https://play.google.com/store/apps/details?id=vn.vng.uprace">
                                        <img className="lazyload" data-src="images/download/download-and.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="download--right">
                            <img className="app lazyload" data-src="images/download/phone1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        );
    })
);

export default DownloadApp;
