import React, { useEffect, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { convertTimestamptoDate, formatNumber, getImageResource, getImageCover } from '../../../utils';
import {
    EVENT_TYPE,
    EVENT_PUBlIC_TYPE,
    GLOBAL_CONFIG,
    GROUP_ACTION,
    GROUP_JOIN_STATUS,
    EVENT_STATUS_TYPE,
    EVENT_FORM_TYPE,
    EVENT_OFFICAL_TYPE
} from '../../../helpers/ConstDefined';
import { useTranslation } from 'react-i18next';
import { API } from '../../../helpers/api/APIDefined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { sportTypeImage } from 'helpers/ConvertHelper';

const EventItem = inject(
    'globalStore',
    'eventStore',
    'userEventStore',
    'userStore'
)(
    observer((props) => {
        const { data, navigation, isSuggest, finished } = props;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { filterEventStatus, doDismissEvent, doActionEvent } = props.userEventStore;
        const { userProfile } = props.userStore;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        async function viewEventDetail() {
            if (data.evtp == EVENT_TYPE.SUPPER_CHALLENGE) {
                if (data.evid == GLOBAL_CONFIG.EVENT_UPRACE_2021_ID) navigation.push(`/info?evid=${data.evid}`);
                if (data.evid == GLOBAL_CONFIG.EVENT_UPRACE_2022_ID) navigation.push(`/2022/info?evid=${data.evid}`);
            } else navigation.push(`/event-detail?evid=${data.evid}`);
        }

        const onCopySuccess = () => {
            setMessageSnackbar(t('global.copy_succ'));
            setSnackbarType('success');
            onOpenSnackbar();
        };

        const dismissEvent = () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: data.evid
            };
            doDismissEvent(params);
        };

        const joinEvent = async () => {
            if (!userProfile) {
                navigation.push('/login');
                return;
            }

            if (data.otp === 1) {
                global.EVENT_ID = data.evid;
                global.EMAIL_LIST = data.emls;
                global.SHOW_REQUEST_JOIN = data.jreq;

                navigation.push('/verify-email');
                return;
            }

            const params = {
                uid: userProfile.uid,
                evid: data.evid,
                action: GROUP_ACTION.JOIN
            };
            let result = await doActionEvent(params);
            if (result && result.jsts) data.jsts = result.jsts;
            else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
                onOpenSnackbar();
            }
        };

        const eventType = data.evtp == EVENT_TYPE.CHALLENGE ? t('ue.challen') : t('ue.offical');

        const shareLink =
            data.evtp != EVENT_TYPE.SUPPER_CHALLENGE
                ? API.GLOBAL.SITE_DOMAIN + `event-detail?evid=${data.evid}`
                : API.GLOBAL.SITE_DOMAIN + `info?evid=${data.evid}`;

        const eventPublicStatus = () => {
            if (data.jpri == EVENT_PUBlIC_TYPE.PRIVATE || data.jpri == EVENT_PUBlIC_TYPE.PROTECTED) {
                return (
                    <div class="privacy">
                        <img src="../../images/icons/private.svg" alt="private" />
                        &nbsp;{t('ue.private')}
                    </div>
                );
            } else
                return (
                    <div class="privacy">
                        <img src="../../images/icons/public.svg" alt="public" />
                        &nbsp;{t('ue.public')}
                    </div>
                );
        };

        const ButtonJoin = () => {
            const cssActive = finished || data.sts == 3 ? 'inactive' : '';

            if (data.jsts == GROUP_JOIN_STATUS.NONE)
                return (
                    <button class={`btn-32 btn-blue action-join ${cssActive}`} onClick={() => joinEvent()}>
                        {t('global.join')}
                    </button>
                );
            else if (data.jsts == GROUP_JOIN_STATUS.REQUESTING)
                return <button class={`btn-32 btn-blue-light action-join ${cssActive}`}>{t('ue.wait_approve')}</button>;
            else if (data.jsts == GROUP_JOIN_STATUS.JOINED || data.jsts == GROUP_JOIN_STATUS.ADMIN)
                return (
                    <button class={`btn-32 btn-outline-blue action-join ${cssActive}`}>{t('global.ready_join')}</button>
                );
        };

        function addDefaultSrc(e) {
            e.target.src = 'https://uprace2.vcdn.vn/user_event/user_event_banner@3x.png';
        }

        return (
            <div class="col">
                <div class="card mb-3">
                    <img
                        src={getImageResource(data.cover)}
                        onError={addDefaultSrc}
                        alt="event"
                        class="card-img-top event-thumbnail"
                        onClick={() => viewEventDetail()}
                    />
                    {data.offical == EVENT_OFFICAL_TYPE.OFFICAL ? <div class="official">Official</div> : null}

                    {isSuggest || filterEventStatus == EVENT_STATUS_TYPE.SUGGEST ? (
                        <div class="btn-close" onClick={() => dismissEvent()}></div>
                    ) : null}

                    <div class="card-body">
                        <div onClick={() => viewEventDetail()}>
                            <div class="calendar">
                                <span class="txt">
                                    {convertTimestamptoDate(data.betm, 'DD/MM/YYYY')} -{' '}
                                    {convertTimestamptoDate(data.entm, 'DD/MM/YYYY')}
                                </span>
                            </div>

                            <div class="title">{data.name}</div>
                            <div class="description">
                                <div class="badge">{eventType}</div>
                                {eventPublicStatus()}
                            </div>
                            <div class="description">
                                <div class="avatar-group flex-shrink-0">
                                    {isSuggest &&
                                        data.mbls &&
                                        data.mbls.map((item) => {
                                            return (
                                                <span class="avatar">
                                                    <img src={getImageResource(item.ava, true)} alt="avatar" />
                                                </span>
                                            );
                                        })}
                                </div>
                                {/* render icon bike, swim... if block not suggest */}
                                {!isSuggest && filterEventStatus != EVENT_STATUS_TYPE.SUGGEST ? (
                                    <div class="flex-shrink-0">
                                        <div class={`type ${sportTypeImage(data.sport)}`}></div>
                                    </div>
                                ) : null}

                                <div class="flex-grow-1">
                                    <span class="fw-600 text-ink-light">+ {formatNumber(data.mem)}</span>
                                    <span class="fw-500 text-ink-lighter"> {t('global.join').toLowerCase()}</span>
                                </div>
                            </div>
                        </div>
                        <div class="action">
                            {ButtonJoin()}
                            <CopyToClipboard text={shareLink} onCopy={() => onCopySuccess()}>
                                <button class="btn-32 btn-outline-blue action-share"></button>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default EventItem;
