import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { EVENT_COMPARE_TYPE } from '../../../../helpers/ConstDefined';
import ChartData from './ChartData';
import { CompareFriendDialog } from '..';
import MemberTable from './MemberTable';

const AchievementCompare = inject(
    'globalStore',
    'chartStore',
    'userStore'
)(
    observer((props) => {
        const { eventID, endDate } = props;
        const { userProfile } = props.userStore;
        const { listUserCompare, doGetListUserCompare } = props.chartStore;
        const { t } = useTranslation();

        const [openDialogCompareFriend, setOpenDialogCompareFriend] = React.useState(false);

        const getListUserCompare = () => {
            const params = {
                evid: eventID,
                uid: userProfile.uid,
                type: EVENT_COMPARE_TYPE.USER
            };
            doGetListUserCompare(params, endDate);
        };

        useEffect(() => {
            if(userProfile) getListUserCompare();
        }, [userProfile]);

        function RenderNotData() {
            return (
                <div class="chart-compare empty">
                    <button class="btn-44 blue add-friends" onClick={() => setOpenDialogCompareFriend(true)}>
                        {t('global.add_friend_for_compare2')}
                    </button>
                </div>
            );
        }

        function RenderDataCompare() {
            return (
                <div class="chart-compare">
                    <div class="l-box">
                        <div class="edit-row">
                            {t('global.edit_board_compare')}
                            <a
                                href="javascript:void(0);"
                                class="edit-btn"
                                onClick={() => setOpenDialogCompareFriend(true)}></a>
                        </div>
                        <div class="chart-block">{listUserCompare ? <ChartData /> : null}</div>
                    </div>
                    <div class="r-box">{listUserCompare ? <MemberTable /> : null}</div>
                </div>
            );
        }

        return (
            <div class="yours--compare">
                <h2 class="yours--title">{t('global.create_chart_compare')}</h2>
                <p class="txt">{t('global.add_friend_for_compare')}</p>
                <p class="txt bold">{t('global.add_friend_for_compare1')}</p>
                {listUserCompare && listUserCompare.length > 0 ? <RenderDataCompare /> : <RenderNotData />}
                {openDialogCompareFriend ? (
                    <CompareFriendDialog
                        openDialogCompareFriend={openDialogCompareFriend}
                        onClose={() => setOpenDialogCompareFriend(false)}
                        eventID={eventID}></CompareFriendDialog>
                ) : null}
            </div>
        );
    })
);

export default AchievementCompare;
