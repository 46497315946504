import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const RuleEvent2022VI = inject('globalStore')(
    observer((props) => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        function RuleUpraceVI() {
            return (
                <section class="fail-activity-list">
                    <div class="title">
                        <p>UPRACE 2022</p>
                        <p>ĐIỀU LỆ THAM GIA</p>
                    </div>
                    <ul class="list">
                        <li>
                            <h3 class="list-title">Định nghĩa:</h3>
                            <div class="list-desc">
                                UpRace 2022 (sau đây gọi tắt là “sự kiện") là một sự kiện chạy bộ cộng đồng MIỄN PHÍ
                                dành cho tất cả mọi người trên nền tảng web/mobile UpRace. Người tham gia (sau đây gọi
                                là vận động viên, viết tắt “VĐV”) được tự do chạy vào thời gian và địa điểm tuỳ ý và chủ
                                động ghi lại kết quả chạy trên các thiết bị di động (điện thoại di động, đồng hồ thể
                                thao…) với mục đích tích luỹ được quãng đường chạy càng nhiều càng tốt.
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Thời gian:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Thời gian mở đăng ký sự kiện:</strong> bắt đầu từ 0 giờ ngày 10/10/2022,
                                        VĐV vẫn có thể tiếp tục đăng ký và gia nhập Đội thi trong quá trình sự kiện diễn
                                        ra.
                                    </li>
                                    <li>
                                        <strong>Thời gian thi đấu:</strong> từ 0 giờ ngày 28/10/2022 tới 23 giờ 59 phút
                                        ngày 20/11/2022 (24 ngày).
                                    </li>
                                    <li>
                                        <strong>Giờ Việt Nam (GMT+7)</strong> được sử dụng làm múi giờ chuẩn cho sự kiện
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức đăng ký</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        VĐV tải ứng dụng UpRace trên điện thoại di động hoặc truy cập website:{' '}
                                        <a href="https://uprace.org">https://uprace.org</a> và đăng ký tài khoản. Các
                                        VĐV đã dự thi năm 2021 có thể đăng nhập vào tài khoản sẵn có của mình. Các VĐV
                                        sau khi đăng ký tài khoản thành công sẽ được tự động tham gia vào sự kiện UpRace
                                        2022.
                                    </li>
                                    <li>
                                        VĐV bấm tham gia vào một Đội (Team) mà mình lựa chọn. Mỗi tài khoản của VĐV chỉ
                                        được chọn một Đội để thi đấu trong sự kiện. Các VĐV không được phép thay đổi Đội
                                        kể từ thời điểm sự kiện bắt đầu cho tới lúc sự kiện kết thúc.
                                    </li>
                                    <li>
                                        VĐV bấm tham gia gây quỹ cho một Tổ chức xã hội mà mình lựa chọn. Mỗi tài khoản
                                        của VĐV chỉ được chọn một Tổ chức xã hội để gây quỹ. Các VĐV không được phép
                                        thay đổi Tổ chức xã hội mình đã chọn trong quá trình sự kiện diễn ra. Có 2 Tổ
                                        chức xã hội để VĐV lựa chọn bao gồm:
                                        <p>• Hội Bảo trợ người khuyết tật và trẻ mồ côi Việt Nam,</p>
                                        <p>
                                            • Trung tâm Bảo tồn Động vật hoang dã tại Việt Nam (Save Vietnam’s
                                            Wildlife),
                                        </p>
                                    </li>
                                    <li>
                                        VĐV tham gia vào sự kiện sau thời điểm sự kiện bắt đầu vẫn được thi đấu bình
                                        thường.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức thi đấu giữa các Đội</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>Các đội được xếp vào 3 bảng đấu gồm:</p>
                                        <p>- Bảng 1: gồm các Doanh nghiệp (Company)</p>
                                        <p>- Bảng 2: gồm các Câu lạc bộ thể thao (Sports Club)</p>
                                        <p>- Bảng 3: gồm các Trường học (Schools)</p>
                                    </li>
                                    <li>
                                        Thành tích của mỗi Đội là tổng thành tích hợp lệ của tất cả các VĐV tham gia thi
                                        đấu cho Đội trong sự kiện. Các Đội trong cùng bảng sẽ thi đấu với nhau.
                                    </li>
                                    <li>
                                        Các Đội được thành lập sau thời điểm sự kiện bắt đầu vẫn được tham gia thi đấu
                                        bình thường.
                                    </li>
                                    <li>
                                        Các Đội có thể thành lập các Nhóm để thi đấu nội bộ trong Đội. Các Nhóm trong
                                        Đội cũng sẽ thi đấu theo quy định của UpRace về hoạt động hợp lệ (tại mục 5).
                                        Bảng xếp hạng các Nhóm trong Đội không có giá trị cạnh tranh cho các giải thưởng
                                        đồng đội của UpRace. (Xem hướng dẫn tạo Nhóm và Bảng xếp hạng Nhóm tại ĐÂY)
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Cách thức thi đấu đối với các VĐV</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Cách thức ghi lại thành tích:</strong> Các VĐV tự ghi lại thành tích mỗi
                                        lần chạy của mình bằng thiết bị di động (điện thoại, đồng hồ có chức năng GPS…).
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Cuộc chạy hợp lệ:</strong> được tính khi đạt đủ các điều kiện sau:
                                        </p>
                                        <p>
                                            - Được ghi nhận hoàn tất trên tài khoản UpRace của VĐV trong vòng 72 giờ kể
                                            từ thời điểm cuộc chạy bắt đầu và không muộn hơn ngày cuối cùng của sự kiện.
                                        </p>
                                        <p>
                                            - Thể hiện bản đồ chạy rõ ràng, không có dấu hiệu sai số lớn do lỗi bắt sóng
                                            GPS của thiết bị di động. Các cuộc chạy được nhập kết quả thủ công bằng tay
                                            (manual input), hoặc được share bởi người khác, hoặc ghi nhận cho nhiều tài
                                            khoản khác nhau sẽ không được công nhận hợp lệ; Trong trường hợp cuộc chạy
                                            thực hiện trên máy chạy bộ cần được ghi nhận bằng thiết bị đo chuyên dụng
                                            như đồng hồ Garmin và cảm biến đeo chân.
                                        </p>
                                        <p>
                                            - Các cuộc chạy có thời gian trùng nhau do đo đồng thời trên nhiều app sẽ
                                            chỉ được ghi nhận là một cuộc chạy và theo thứ tự ưu tiên sau: UpRace -{'>'}{' '}
                                            Garmin -{'>'} Strava
                                        </p>
                                        <p>
                                            - Có cự li chạy (Distance) tối thiểu là 1km. Cự li tối đa không giới hạn.
                                            VĐV được bấm tạm dừng cuộc chạy trong khi chạy với mục đích nghỉ và phân
                                            phối sức
                                        </p>
                                        <p>
                                            - Có tốc độ di chuyển trung bình (Average Moving Pace) tính theo số phút
                                            hoàn thành 1 km chạy nhanh tối đa là 4:00 phút/km và chậm tối thiểu là 15:00
                                            phút/km. Có tốc độ di chuyển trung bình của tất cả các quãng 1km (Split)
                                            nhanh tối đa 3:30 phút/km.
                                        </p>
                                        <p>
                                            - Các cuộc chạy diễn ra trong những ngày Chủ nhật và ngày đặc biệt
                                            11/11/2022 trong thời gian diễn ra sự kiện phải được hoàn tất trong ngày
                                            (trước 24h00) và kết quả nhân 2 được tính vào thành tích của ngày đó (riêng
                                            ngày 11/11/2022 kết quả sẽ được nhân 2 khi VĐV sử dụng ứng dụng UpRace để
                                            ghi nhận thành tích).
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Ngày chạy hợp lệ (active days):</strong> là ngày có ít nhất một cuộc
                                            chạy hợp lệ theo điều trên.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Hoàn thành cuộc thi:</strong> Một VĐV sẽ được chứng nhận là hoàn
                                            thành cuộc thi khi đạt đồng thời hai điều kiện sau:
                                        </p>
                                        <p>- Có số ngày chạy hợp lệ tối thiểu là 12 ngày.</p>
                                        <p>
                                            - Có tổng số quãng đường chạy tối thiểu là 60 km (bao gồm cả thành tích nhân
                                            đôi trong các ngày Chủ nhật và ngày 11/11/2022)
                                        </p>
                                        <p>
                                            <strong>Lưu ý:</strong> Đối với vận động viên không chạy đủ 12 ngày và 60
                                            km, thì số km chạy hoàn thành vẫn được đóng góp cho đội và được nhà tài trợ
                                            quyên góp cho tổ chức xã hội.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Ngày ghi nhận thành tích:</strong> Thời điểm bắt đầu cuộc chạy thuộc
                                        ngày nào thì thành tích của cuộc chạy được tính cho ngày đó.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Giải thưởng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Giải thưởng thành tích cá nhân VĐV:</strong>
                                        </p>
                                        <p>
                                            Tất cả các VĐV đạt đủ điều kiện hoàn thành cuộc thi sẽ nhận được một giấy
                                            chứng nhận hoàn thành cuộc thi (bản điện tử) theo các mốc số km hoàn thành
                                            gồm: 60km, 150km, 300km tương ứng với các danh hiệu: Pro Runner, Hero Runner
                                            và Angel Runner.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Giải thưởng thành tích cho Đội:</strong>
                                        </p>
                                        <p>
                                            - 10 (mười) Đội có tổng thành tích tốt nhất trong mỗi Bảng sẽ nhận giải
                                            thưởng đồng đội.
                                        </p>
                                        <p>
                                            - <strong>Giải thưởng đồng đội:</strong> Áo UpRace sẽ trao cho vận động viên
                                            đủ điều kiện hoàn thành cuộc thi và có thành tích nằm trong top của các Đội
                                            đoạt giải theo bảng sau
                                        </p>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Doanh nghiệp</th>
                                                <th>Câu lạc bộ</th>
                                                <th>Trường học</th>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhất</td>
                                                <td>Top 300 runners</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng nhì</td>
                                                <td>Top 250 runners</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng ba</td>
                                                <td>Top 200 runners</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 4,5</td>
                                                <td>Top 150 runners</td>
                                                <td>Top 100 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                            <tr>
                                                <td>Đội hạng 6,7,8,9 và 10</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                                <td>Top 50 runners</td>
                                            </tr>
                                        </table>

                                        <p>
                                            <strong>- Giải thưởng cá nhân:</strong> Áo UpRace trao cho Top 100 VĐV
                                            nam/nữ có thành tích thi đấu cao nhất (không bao gồm các vđv đã nhận giải
                                            top 10 các bảng trên)
                                        </p>

                                        <p>
                                            <strong>- Hình thức nhận giải:</strong> Tất cả VĐV đạt giải đều được BTC gửi
                                            mail xác nhận có đình kèm Code để nhận thưởng. VĐV có thể nhận áo UpRace
                                            theo 2 cách:
                                            <p>
                                                (1) Cách 1: Trong khoảng thời gian từ 28/11/2022 – 12/12/2022 (trong giờ
                                                hành chính) VĐV show Code nhận giải để đến/ủy quyền cho người khác
                                                nhận áo tại 3 địa điểm: Hà Nội, TP. HCM, Đà Nẵng. Thông tin chi tiết BTC
                                                sẽ cập nhật trên Fanpage UpRace.
                                            </p>
                                            <p>
                                                (2) Cách 2: Sau thời gian trên, BTC sẽ gửi áo qua đường bưu điện theo
                                                địa chỉ đăng ký trên app UpRace.
                                            </p>
                                        </p>

                                        <p>
                                            <strong>
                                                - Giải thưởng dành cho đội có thành tích cao nhất mỗi bảng (Top 1):
                                            </strong>
                                            <p>
                                                (1) 100 triệu VNĐ dùng để quyên góp cho đơn vị thụ hưởng UpRace 2022 mà
                                                đội đó chỉ định,
                                            </p>
                                            <p>(2) Giấy chứng nhận thành tích đội thắng cuộc (bản điện tử),</p>
                                            <p>
                                                (3) Bài vinh danh đội Top 1 mỗi bảng trên fanpage UpRace và được nhắc
                                                tên trong bài PR.
                                            </p>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Gây quỹ cho cộng đồng:</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <p>
                                            <strong>Nguồn quỹ:</strong>
                                        </p>
                                        <p>
                                            - Từ các nhà tài trợ (Sponsors): các nhà tài trợ sẽ quyên góp tối thiểu
                                            1,000 đồng/1km chạy hợp lệ của VĐV. Để trở thành nhà tài trợ của UpRace, vui
                                            lòng liên hệ email:{' '}
                                            <a href="mailto:sponsor@uprace.org">sponsor@uprace.org</a> hoặc inbox về
                                            Fanpage UpRace.
                                        </p>
                                        <p>
                                            - Từ các VĐV: Các VĐV tham gia cuộc thi được khuyến khích quyên góp cho
                                            chính thành tích chạy của mình hoặc của VĐV khác. Mức quyên góp tối thiểu là
                                            1,000 đồng/1km chạy hợp lệ của mình hoặc của VĐV khác. VĐV cũng được khuyến
                                            khích kêu gọi các cổ động viên hoặc VĐV khác quyên góp cho thành tích chạy
                                            của mình. Lưu ý: đây KHÔNG phải là điều kiện bắt buộc đối với các VĐV.
                                        </p>
                                        <p>
                                            - Từ các cổ động viên: Các cổ động viên được khuyến khích tham gia quyên góp
                                            cho thành tích chạy của VĐV mà mình muốn cổ vũ. Mức quyên góp tối thiểu là
                                            1,000 đồng/1km.
                                        </p>
                                    </li>
                                    <li>
                                        <strong>Sử dụng quỹ:</strong> Toàn bộ tiền gây quỹ của UpRace sẽ được chuyển cho
                                        các Tổ chức xã hội.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3 class="list-title">Quyền của Ban tổ chức (BTC) UpRace và Ban quản trị (BQT) các Đội</h3>
                            <div class="list-desc">
                                <ul>
                                    <li>
                                        <strong>Quyền của BTC UpRace:</strong> BTC có toàn quyền quyết định một Đội và
                                        VĐV có đủ điều kiện thi đấu hay không, quyền dừng thi đấu và huỷ kết quả của Đội
                                        và VĐV trong trường hợp phát hiện hành vi gian lận hoặc không tuân thủ các quy
                                        định của cuộc thi và quyền phân xử đối với các khiếu nại. BTC có các phương án
                                        phòng chống và phát hiện gian lận nếu có, đồng thời kêu gọi các VĐV thể hiện
                                        tinh thần fairplay và tự giác cao nhất. Khi có bất kỳ hành vi gian lận nào bị
                                        phát hiện, VĐV sẽ bị loại khỏi cuộc thi và không được công nhận toàn bộ thành
                                        tích đã đạt được.
                                    </li>
                                    <li>
                                        <strong>Quyền của BQT Đội:</strong> Mỗi đội sẽ có BQL bao gồm Đội trưởng (Owner)
                                        và các Quản trị viên (Moderator) do Đội trưởng chỉ định. BQL đội sẽ có quyền
                                        quyết định VĐV trong đội của mình có đủ điều kiện thi đấu hay không, quyền dừng
                                        thi đấu và huỷ kết quả của VĐV trong Đội của mình trong trường hợp phát hiện các
                                        hành vi gian lận hoặc không tuân thủ các quy định của cuộc thi và quyền phân xử
                                        đối với các khiếu nại của VĐV trong Đội.
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </section>
            );
        }

        return <RuleUpraceVI />;
    })
);

export default RuleEvent2022VI;
