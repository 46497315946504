import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ListChallengeToolbar from './ListChallengeToolbar';
import { GLOBAL_CONFIG, FEED_TYPE, CHALLENGE_TYPE } from '../../../../helpers/ConstDefined';
import ChallengeItem from '../ChallengeItem';
import { KEY } from '../../../../helpers/api/APIDefined';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogChallengeUser from './DialogChallengeUser';

const ListChallenge = inject(
    'authStore',
    'challengeStore',
    'userStore',
    'globalStore',
    'teamStore',
    'userProfileStore'
)(
    observer((props) => {
        const { navigation, eventID } = props;
        const { userProfile, userProfileBasic, doGetProfileBasic } = props.userStore;
        const { doSetProfileUserID } = props.userProfileStore;
        const {
            listChallenge,
            loadingChallenge,
            totalRecord,
            tabIndex,
            pageIndex,
            doIncreasePageIndex,
            doSetPageIndex,
            doGetListChallenge
        } = props.challengeStore;
        const { appVersion } = props.globalStore;
        const { userTeam, currentMember, doSetCurrentMember } = props.teamStore;

        const [name, setName] = React.useState('');
        const [challengeDetail, setChallengeDetail] = React.useState(null);
        const [openDialogChallengeUser, setOpenDialogChallengeUser] = React.useState(false);

        useEffect(() => {
            getListChallenge();
        }, []);

        async function getProfileBasic(pfid) {
            const params = {
                uid: userProfile.uid,
                pfid
            }
            let result = await doGetProfileBasic(params);
            if(result != null) {
                doSetCurrentMember(result);
                handleOpenDialogChallengeUser(true);
            }
        }

        const isShowLoadMore = listChallenge == null ? false : listChallenge.length < totalRecord;

        const handleOpenDialogChallengeUser = (flag) => {
            setOpenDialogChallengeUser(flag);
        };

        function getListChallenge() {
            let data = {
                uid: userProfile.uid,
                value: userProfile.uid,
                feed: FEED_TYPE.ALL,
                evid: eventID
            };
            doGetListChallenge(data);
        }

        const onChangeTab = () => {
            //reset filter when switch tab
            setName('');
            doSetPageIndex(1); //reset page index
        };

        function getListChallengeMore() {
            doIncreasePageIndex();
            let params = {
                from: pageIndex * appVersion.pgsz,
                size: appVersion.pgsz,
                uid: userProfile.uid,
                feed: tabIndex,
                name,
                evid: eventID
            };
            if (tabIndex == CHALLENGE_TYPE.ALL) params.value = userProfile.uid;
            else if (tabIndex == CHALLENGE_TYPE.FOLLOWING) params.value = userProfile.uid;
            else params.value = userTeam.tmid;
            doGetListChallenge(params, true);
        }

        function onViewSocialLike(item) {
            props.onViewSocialLike(item);
        }

        function onViewSocialComment(item) {
            props.onViewSocialComment(item);
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        function onSearch() {
            doSetPageIndex(1);
            let params = {
                uid: userProfile.uid,
                feed: tabIndex,
                name,
                evid: eventID
            };
            if (tabIndex == CHALLENGE_TYPE.ALL) params.value = userProfile.uid;
            else if (tabIndex == CHALLENGE_TYPE.FOLLOWING) params.value = userProfile.uid;
            else params.value = userTeam.tmid;
            doGetListChallenge(params);
        }

        function onSearchReset() {
            doSetPageIndex(1);
            let params = {
                uid: userProfile.uid,
                feed: tabIndex,
                evid: eventID
            };
            if (tabIndex == CHALLENGE_TYPE.ALL) params.value = userProfile.uid;
            else if (tabIndex == CHALLENGE_TYPE.FOLLOWING) params.value = userProfile.uid;
            else params.value = userTeam.tmid;
            doGetListChallenge(params);
        }

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingChallenge)
                    return (
                        <div className="load-more">
                            <CircularProgress disableShrink />
                        </div>
                    );
                else
                    return (
                        <div className="load-more">
                            <button className="btn btn-primary" onClick={() => getListChallengeMore()}>
                                Xem thêm
                            </button>
                        </div>
                    );
            } else return null;
        }

        const handleViewProfileUser = (item) => {
            sessionStorage.setItem(KEY.ID_PROFILE_USER, item.uid);
            doSetProfileUserID(item.uid);
            navigation.push('/user-profile');
        };

        const handleViewChallengeDetail = (item) => {
            setChallengeDetail(item);
            getProfileBasic(item.uid);
        }

        return (
            <section className="main-content wrapper-challenges">
                <div className="container ">
                    <ListChallengeToolbar
                        eventID = {eventID}
                        handleChangeName={handleChangeName}
                        onChangeTab={onChangeTab}
                        nameSearch={name}
                        onSearch={onSearch}
                        onSearchReset={onSearchReset}
                    />

                    <div className="wrapper-list-user">
                        <div className="row ">
                            {listChallenge != null &&
                                listChallenge.map((item) => (
                                    <ChallengeItem
                                        key={item.tgid}
                                        data={item}
                                        onViewProfileUser={() => handleViewProfileUser(item)}
                                        onViewChallengeDetail = {() => handleViewChallengeDetail(item)}
                                        onViewSocialLike={() => onViewSocialLike(item)}
                                        onViewSocialComment={() => onViewSocialComment(item)}
                                    />
                                ))}
                        </div>
                        {listChallenge && listChallenge.length == 0 ? (
                            <p style={{ textAlign: 'center' }}>Không tìm thấy thử thách của VĐV theo yêu cầu</p>
                        ) : null}
                    </div>

                    <LoadMore />
                </div>
                {currentMember != null && challengeDetail != null  ? (
                        <DialogChallengeUser
                            openDialogChallengeMember={openDialogChallengeUser}
                            onViewProfileUser={() => {}}
                            challengeDetail={challengeDetail}
                            isViewProfile={true}
                            navigation={navigation}
                            onClose={() => handleOpenDialogChallengeUser(false)}
                        />
                    ) : null}
            </section>
        );
    })
);

export default ListChallenge;
