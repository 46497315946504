import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from '../../../../utils';
import { COMMON_ERROR, GROUP_ACTION } from '../../../../helpers/ConstDefined';

const DialogLeaveTeam = inject(
    'eventStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { openDialogLeaveTeam, teamData } = props;
        const { userTeam, doJoinLeaveTeam, doSetJoinTeamStt } = props.teamStore;
        const { userProfile } = props.userStore;
        const { doSetTeamJoinEvent } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();
        const [showResult, setShowResult] = React.useState(false);
        const [msgResult, setMsgResult] = React.useState(t('global.leave_team_confirm_msg'));

        useEffect(() => {
            renderMsg();
        }, [userTeam, openDialogLeaveTeam]);

        const renderMsg = () => {
            if (!userTeam) return;
            if (userTeam.tmid == teamData.tmid) setMsgResult(t('global.leave_team_confirm_msg_event'));
            else {
                setMsgResult(t('global.leave_team_confirm_msg'));
            }
        };

        async function joinLeaveTeam() {
            const params = {
                uid: userProfile.uid,
                tmid: teamData.tmid,
                action: GROUP_ACTION.LEAVE,
            };
            let result = await doJoinLeaveTeam(params);
            if (result == COMMON_ERROR.EVENT.EVENT_READY || result == COMMON_ERROR.TEAM.LEAVE_TEAM_EVENT_READY) {
                setShowResult(true);
                setMsgResult(t('global.leave_team_fail_event_ready'));
            } else if (result == COMMON_ERROR.TEAM.OWNER_CODE) {
                setShowResult(true);
                setMsgResult(t('global.leave_team_fail_owner'));
            } else if (result == true) {
                setMessageSnackbar(t('global.leave_team_succ'));
                setSnackbarType('success');
                onOpenSnackbar();
                //remove team in list team of user profile
                _.remove(userProfile.tmls, (item) => {
                    return item.tmid == teamData.tmid;
                });
                teamData.mem -= 1;
                doSetJoinTeamStt(0);
                onCloseDialog();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onCloseDialog = () => {
            setShowResult(false);
            setMsgResult(t('global.leave_team_confirm_msg'));
            props.onClose();
        };

        return (
            <Dialog open={openDialogLeaveTeam}>
                <div
                    className="uprace-popup has-title"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={() => onCloseDialog()}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('global.leave_team_confirm')}</h2>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <div className="logo-row">
                                        <p className="glogo">
                                            <img src={getImageResource(teamData.ava, true)} alt="" />
                                        </p>
                                    </div>
                                    <p className="name">{teamData.name}</p>
                                    <div className="txt">
                                        <p>{msgResult}</p>
                                    </div>
                                    {!showResult ? (
                                        <div className="popup-btns col-2 w140">
                                            <button className="btn-44 blue" onClick={() => onCloseDialog()}>
                                                {t('global.seaagain')}
                                            </button>
                                            <button className="btn-44 cancel" onClick={() => joinLeaveTeam()}>
                                                {t('global.leave_team1')}
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogLeaveTeam;
