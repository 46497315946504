import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const ENLang = inject('authStore')(
    observer((props) => {
        useEffect(() => {}, []);

        return (
            <section className="main-content">
                <div className="container">
                    <h3 className="title">COMPETITION RULES</h3>
                    <p>
                        <strong>Competition time: from 12:00am Sep 18, 2020 to 11:59pm Oct 11, 2020 (24 days).</strong>{' '}
                        Runners and teams, who participate in the event after the event starts, will be able to join
                        normally.
                    </p>

                    <p>
                        <strong> - Competition rules:</strong>
                    </p>

                    <ul className="dot-list">
                        <li>
                            {' '}
                            + Runners will automatically join the event by creating an UpRace acount. A runner can
                            either choose to compete under a team or compete individually.
                        </li>
                        <li>
                            + Recorded as completed and displayed on the runner's UpRace account within 72 hours from
                            the event starts and no later than the last day of the event.{' '}
                        </li>
                        <li>
                            + There is a minimum distance of any activity is 1km. Activity must display a clear route
                            map with no GPS errors. Activity on treadmill must be recorded by sport tracker device such
                            as Garmin with foot pod.
                        </li>
                        <li>
                            + Average Pace maximum is 4:00 minutes/km and minimum is 15 minutes/km. Split pace of each
                            1km should not be faster than 3:30 minutes/km
                        </li>
                        <li>
                            + Runs that take place on Sundays during the event period must be completed and displayed
                            will be counted on the achievement of that day.
                        </li>
                        <li>
                            + A runner will be certified to complete the contest when both of the following conditions
                            are reached
                            <ul className="dot-list">
                                <p>o Must achieve a minimum number of 12 valid days, and </p>
                                <p>
                                    o Must achieve a minimum distance of 60km (including double achievement on Sundays).
                                </p>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        );
    })
);

export default ENLang;
