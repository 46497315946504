import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml, formatNumber, formatDistanceKM, getTypeName } from '../../../../../utils';
import { GROUP_JOIN_STATUS, GROUP_ACTION } from '../../../../../helpers/ConstDefined';

const LeaveConfirmDialog = inject(
    'groupStore',
    'postStore',
    'userStore',
    'globalStore',
    'eventStore',
    'teamManageStore',
)(
    observer((props) => {
        let { data, isLeader, navigation } = props;

        useEffect(() => {}, []);

        const { doActionGroup, doSetStatusJoinGroup, doDeleteGroup } = props.groupStore;
        const { openDialogLeaveConfirm } = props;
        const { userProfile } = props.userStore;
        const { doSetFunc } = props.teamManageStore;
        const { currentEvent, doSetGroupRequestID, doSetGroupIDEvent } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        const msg =
            data.mem == 1 && isLeader
                ? t('team.groupdetail.leave_group_confirm_1member')
                : t('team.groupdetail.leave_group_confirm');

        const handleOpenChoiseLeader = () => {
            if (data.mem > 1 && isLeader) {
                props.onClose();
                props.choiseLeader();
            } else if (data.mem == 1 && isLeader) 
            {
                deleteGroup();
                props.onClose();
            }
            else onLeaveGroup();
        };

        async function onLeaveGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.grid,
                action: GROUP_ACTION.LEAVE
            };
            let result = await doActionGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                data.mem -= 1;
                data.jsts = GROUP_JOIN_STATUS.NONE;
                //check và remove grid in currentEvent nếu xóa chính nhóm đang join
                if (currentEvent.grid == data.grid) {
                    doSetGroupRequestID(0);
                    doSetGroupIDEvent(0);
                    doSetStatusJoinGroup(0);
                }
                doSetFunc(0); //back lại screen danh sách Group
                if(navigation) navigation.goBack();
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        async function deleteGroup() {
            const params = {
                uid: userProfile.uid,
                grid: data.grid
            };
            let result = await doDeleteGroup(params);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');

                //check và remove grid in currentEvent nếu xóa chính nhóm đang join
                if (currentEvent.grid == data.grid) {
                    doSetGroupRequestID(0);
                    doSetGroupIDEvent(0);
                    doSetStatusJoinGroup(0);
                    doSetFunc(0); //back lại screen danh sách Group
                }
                if(navigation) navigation.goBack();
                props.onClose();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
            }
            onOpenSnackbar();
        }

        return (
            <Dialog open={openDialogLeaveConfirm}>
                <div
                    className="uprace-popup"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={props.onClose}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <div className="logo-row">
                                        <p className="glogo">
                                            <img src={getImageResource(data.ava, true)} alt="" />
                                        </p>
                                    </div>
                                    <p className="name">{data.name}</p>
                                    <div className="owner-name-row">
                                        <p className="owner-name">{data.ownm}</p>
                                    </div>
                                    <div className="txt">
                                        <p>{msg}</p>
                                    </div>
                                    <div className="popup-btns col-2 w140">
                                        <button className="btn-44 blue" onClick={props.onClose}>
                                            {t('global.seaagain')}
                                        </button>
                                        <button className="btn-44 cancel" onClick={() => handleOpenChoiseLeader()}>
                                            {t('global.leave_group1')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default LeaveConfirmDialog;
