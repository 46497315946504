import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { EVENT_LOCK_STATUS, FOLLOW_STT } from '../../../../helpers/ConstDefined';
import { KEY } from '../../../../helpers/api/APIDefined';
import { getImageResource } from '../../../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MemberName } from 'components/MemberName';

const MemberItem = inject(
    'userProfileStore',
    'userStore',
    'challengeStore'
)(
    observer((props) => {
        const { data, eventID } = props;
        const { userProfile, doFollow } = props.userStore;

        const { t } = useTranslation();

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        return (
                <li>
                    {data.fists == 2 ? (
                        userProfile.uid != data.uid ? (
                            <button className="btn-32 blue gray" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                {t('global.unfollow')}
                            </button>
                        ) : null
                    ) : userProfile.uid != data.uid ? (
                        <button className="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                            {t('global.follow')}
                        </button>
                    ) : null}
                    <Link to={`user-profile?uid=${data.uid}`}>
                        <p class="avatar">
                            <img src={getImageResource(data.ava, true, true)} alt="" />
                        </p>
                        <div class="details">
                            <p class="name">{MemberName(data)}</p>
                            <p class="addr">
                                {data.city} - {data.ctry}
                            </p>
                        </div>
                    </Link>
                </li>
            );
    })
);

export default MemberItem;
