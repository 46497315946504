import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';

const InviteFriendItem = inject(
    'authStore',
    'inviteFriendStore',
    'userStore',
    'userProfileStore'
)(
    observer((props) => {
        useEffect(() => {}, []);
        const { data } = props;
        const { doAddMemberTemp, doAddMember, doRemoveMemberTemp, doRemoveMember } = props.inviteFriendStore;
        const { doChangeSelectedStatusUser } = props.userStore;
        const { doChangeSelectedStatusUserFollower } = props.userProfileStore;

        function addMember() {
            doChangeSelectedStatusUser(data.uid);
            doChangeSelectedStatusUserFollower(data.uid);

            if (data.selected) {
                doAddMemberTemp(data);
                doAddMember(data);
            } else {
                doRemoveMemberTemp(data);
                doRemoveMember(data);
            }
        }

        const css = data.selected && data.selected ? 'active' : '';

        return (
            <li className={css} onClick={() => addMember()}>
                <p className="check">
                    <span className="ico"></span>
                </p>
                <p className="avatar">
                    <img src={getImageResource(data.ava, true, true)} alt="" />
                </p>
                <div className="details">
                    <p className="name">{data.name}</p>
                    <p className="code">{data.code}</p>
                </div>
            </li>
        );
    })
);

export default InviteFriendItem;
