import { observable, action } from 'mobx';
import { requestAPI, requestAPIV2, requestAPIBody } from '../helpers/api/APIHelper';
import { API, HTTP_METHOD, KEY, API_STATUS } from '../helpers/api/APIDefined';
import { ACTIVITY_TYPE, SOCIAL_TYPE } from '../helpers/ConstDefined';
import { log } from '../utils';
import { toJS } from 'mobx';

export default class UserStore {
    @observable listFeedDialog = [];
    @observable loadingFeed = false;
    @observable feedDetail = null;
    @observable userProfile = null;
    @observable userProfileOther = null;
    @observable loadingProfileOther = null;

    @observable userProfileBasic = null; // handle user profile basic info
    @observable currentFeed = null; //handle current feed info
    @observable feedPolyline = null; //store list polyline of feed
    @observable joinStatus = null;
    @observable meRank = null;
    @observable userStatisticsInfo = null; //user statistics info
    @observable listTop3User = null;

    @observable listUserSearch = null;
    @observable totalUser = 0;
    @observable loadingSearch = false;
    @observable pageSearchUser = 1;

    // total record when fetch like, comment
    @observable totalLike = 0;
    @observable listUserLike = []; //list user like feed
    @observable pageLike;

    @observable listComment = []; //list comment of feed
    @observable totalComment = 0;
    @observable pageComment;

    @observable listFeed = [];
    @observable totalFeed = 0;
    @observable pageFeed;

    @observable listFeedInvalid = [];
    @observable totalFeedInvalid = 0;
    @observable pageFeedInvalid;

    @observable totalFeedDialog = 0;
    @observable func = 0; // 0 : main , 1 : invalid activity, 2 : list error desc

    @action.bound
    doSetFunc(val) {
        this.func = val;
    }

    //dùng cho show/hide checkbox
    @action.bound
    doChangeSelectedStatusUser(id) {
        const obj = _.find(this.listUserSearch, (item) => {
            return item.uid == id;
        });
        if (obj) {
            if (!obj.hasOwnProperty('selected')) obj.selected = true;
            else obj.selected = !obj.selected;
        }
    }

    @action.bound
    doChangeSelectedStatusUserMulti(list) {
        const obj = _.filter(this.listUserSearch, (item1) => {
            return _.some(list, (item2) => {
                return item2.uid == item1.uid;
            });
        });
        _.forEach(obj, (item) => {
            item.selected = true;
        });
    }

    @action.bound
    doSetRoleUser(role) {
        if (this.userProfile) this.userProfile.role = role;
    }

    @action.bound
    doSetJoinStatus(stt) {
        this.joinStatus = stt;
    }

    @action.bound
    doRemoveUserLeaderboard(rkid) {
        _.remove(this.userProfile.rkls, (item) => {
            return item.rkid == rkid;
        });
    }

    @action.bound
    doAddUserLeaderboard(leaderboard) {
        const obj = _.find(this.userProfile.rkls, (item) => {
            return item.rkid == leaderboard.rkid;
        });
        if (!obj) {
            this.userProfile.rkls.push(leaderboard);
        }
    }

    @action.bound
    doUpdateInfoUserLeaderboard(leaderboard) {
        const obj = _.find(this.userProfile.rkls, (item) => {
            return item.rkid == leaderboard.rkid;
        });
        if (obj) {
            obj.name = leaderboard.name;
            obj.mem = leaderboard.mem;
        }
    }

    @action.bound
    doPushUserTeamEvent(team) {
        const obj = _.find(this.userProfile.tmls, (item) => {
            return item.tmid == team.tmid;
        });

        if (!obj) {
            this.userProfile.tmls.push(team);
        }
    }

    //get list feed of user, friend or team
    @action.bound
    doGetListFeed(params, isLoadMore) {
        this.loadingFeed = true; //show progress

        if (isLoadMore) {
            this.pageFeed += 1;
            params.from = params.size * this.pageFeed;
        } else {
            this.pageFeed = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingFeed = false;
                } else {
                    
                    this.totalFeed = resp.data.total;
                    if (isLoadMore) this.listFeed = this.listFeed.concat(resp.data.list);
                    else this.listFeed = resp.data.list;
                    this.loadingFeed = false;
                }
            })
            .catch((error) => {
                
                this.loadingFeed = false;
            });
    }

    @action.bound
    doGetListFeedInvalid(params, isLoadMore) {
        this.loadingFeed = true; //show progress

        if (isLoadMore) {
            this.pageFeedInvalid += 1;
            params.from = params.size * this.pageFeedInvalid;
        } else {
            this.pageFeedInvalid = 0;
            params.from = 0;
        }

        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingFeed = false;
                } else {
                    
                    this.totalFeedInvalid = resp.data.total;
                    if (isLoadMore) this.listFeedInvalid = this.listFeedInvalid.concat(resp.data.list);
                    else this.listFeedInvalid = resp.data.list;
                    this.loadingFeed = false;
                }
            })
            .catch((error) => {
                
                this.loadingFeed = false;
            });
    }

    //get list feed of user, friend or team
    @action.bound
    doGetListFeedDialog(params, isLoadMore) {
        this.loadingFeedDialog = true; //show progress
        if (!isLoadMore) this.listFeedDialog = [];
        requestAPIV2(API.USER.FEED_LIST, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingFeedDialog = false;
                } else {
                    
                    this.totalFeedDialog = resp.data.total;
                    if (isLoadMore) this.listFeedDialog = this.listFeedDialog.concat(resp.data.list);
                    else this.listFeedDialog = resp.data.list;
                    this.loadingFeedDialog = false;
                }
            })
            .catch((error) => {
                
                this.loadingFeedDialog = false;
            });
    }

    //update profile
    @action.bound
    async doUpdateProfile(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.USER.UPDATE_PROFILE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    if (resp.ercd) result = resp.ercd;
                    else result = null;
                } else {
                    
                    result = true;
                    this.loading = false;

                    //update store local
                    if (this.userProfile) {
                        if (params.name) this.userProfile.name = params.name;
                        if (params.ava) this.userProfile.ava = params.ava;
                        if (params.sex) this.userProfile.sex = params.sex;
                        this.userProfile.wei = params.wei;
                        this.userProfile.hei = params.hei;
                        this.userProfile.phone = params.phone;
                        this.userProfile.addr = params.addr;
                        this.userProfile.city = params.city;
                        this.userProfile.ctry = params.ctry;
                        this.userProfile.shirt = params.shirt;
                        this.userProfile.bod = params.bod;
                    }
                }
            })
            .catch((error) => {
                
                result = null;
                this.loading = false;
            });
        return result;
    }

    //change pass
    @action.bound
    async doChangePass(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.USER.CHANGE_PASS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code == API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = true;
                } else {
                    this.loading = false;
                    result = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //clear me rank
    @action.bound
    doClearMeRank() {
        this.meRank = null;
    }
    //get me rank
    //get feed detail
    @action.bound
    async doGetMeRank(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.RANK_ME, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    this.meRank = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get list polyline
    @action.bound
    async doGetFeedPolyline(params) {
        let result = null;
        this.loading = true; //show progress
        this.feedPolyline = null;
        await requestAPI(API.USER.GET_POLYLINE, params, HTTP_METHOD.GET)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    this.feedPolyline = resp; //JSON.parse(resp.polylines);
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get feed detail
    @action.bound
    async doGetFeedDetail(params) {
        let result = null;
        this.feedDetail = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.FEED_DETAIL, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    this.feedDetail = resp.data;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    @action.bound
    doSetUserProfile(profile) {
        this.userProfile = profile;
    }

    //get profile user detail
    @action.bound
    async doGetProfileDetail(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.GET_PROFILE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    
                    this.userProfile = resp.data;
                    this.loading = false;
                    result = resp.data;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get profile user detail
    @action.bound
    async doGetProfileDetailOther(params) {
        let result = null;
        this.loadingProfileOther = true; //show progress
        this.userProfileOther = null;
        await requestAPIV2(API.USER.GET_PROFILE, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingProfileOther = false;
                    result = false;
                    this.userProfileOther = null; //account delete
                } else {
                    
                    this.userProfileOther = resp.data;
                    this.loadingProfileOther = false;
                    result = true;
                }
            })
            .catch((error) => {
                this.loadingProfileOther = false;
                result = false;
            });
        return result;
    }

    //search user
    @action.bound
    async doSearchUser(params, isLoadMore) {
        this.loadingSearch = true; //show progress

        if (isLoadMore) {
            this.pageSearchUser += 1;
            params.from = params.size * this.pageSearchUser;
        } else {
            this.pageSearchUser = 0;
            params.from = 0;
        }

        await requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loadingSearch = false;
                    result = false;
                } else {
                    if (!isLoadMore) this.listUserSearch = [];
                    this.totalUser = resp.data.total;
                    if (isLoadMore) this.listUserSearch = this.listUserSearch.concat(resp.data.list);
                    else this.listUserSearch = resp.data.list;
                    this.loadingSearch = false;
                }
            })
            .catch((error) => {
                
                this.loadingSearch = false;
            });
    }

    //search user
    @action.bound
    async doGetTop3User(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.SEARCH, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    
                    this.listTop3User = resp.data.list;
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //get profile basic
    @action.bound
    async doGetProfileBasic(params) {
        this.loading = true; //show progress
        let result = null;
        await requestAPIV2(API.USER.GET_PROFILE_BASIC, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    
                    this.userProfileBasic = resp.data;
                    result = resp.data;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get feet/activity detail
    @action.bound
    doSetCurrentFeed(feed) {
        this.currentFeed = feed;
    }

    @action.bound
    async doComment(params, type) {
        this.loading = true; //show progress
        let result = null;
        const api = type == SOCIAL_TYPE.POST ? API.POST.WRITE_COMMENT : API.FEED.WRITE_COMMENT;

        await requestAPIV2(api, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    this.loading = false;
                    result = true;
                    //update local
                    let data = resp.data.cmls;
                    let newCmt = data[data.length-1];
                    if(newCmt) {
                        this.listComment.push(newCmt);
                    }
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = null;
            });
        return result;
    }

    //get list comment of activity
    @action.bound
    async doGetListComment(params, type, isLoadMore) {
        this.loading = true; //show progress
        let result = null;
        if (isLoadMore) {
            this.pageComment += 1;
            params.from = params.size * this.pageComment;
        } else {
            this.pageComment = 0;
            params.from = 0;
        }
       
        if (!isLoadMore) this.listComment = [];
        const api = type == SOCIAL_TYPE.POST ? API.POST.LIST_COMMENT : API.USER.LIST_COMMENT;

        await requestAPIV2(api, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = null;
                } else {
                    result = true;
                    this.totalComment = resp.data.total;
                    if (isLoadMore) this.listComment = this.listComment.concat(resp.data.list);
                    else this.listComment = resp.data.list;
                    this.loading = false;

                    while(this.listComment.length < resp.data.total && !this.loading)
                    {
                        this.loading = true;
                        this.doGetListComment(params,type,true);
                    }
                }
            })
            .catch((error) => {
                
                result = null;
                this.loading = false;
            });
        return result;
    }

    //get list like of activity
    @action.bound
    async doGetListUserLike(params, type, isLoadMore) {
        this.loading = true; //show progress

        if (isLoadMore) {
            this.pageLike += 1;
            params.from = params.size * this.pageLike;
        } else {
            this.pageLike = 0;
            params.from = 0;
        }

        if (!isLoadMore) this.listUserLike = [];
        const api = type == SOCIAL_TYPE.POST ? API.POST.LIST_LIKE : API.USER.LIST_LIKE;

        await requestAPIV2(api, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.totalLike = resp.data.total;
                    if (isLoadMore) this.listUserLike = this.listUserLike.concat(resp.data.list);
                    else this.listUserLike = resp.data.list;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    //update user activity
    @action.bound
    async doUpdateActivity(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.USER.UPDATE_ACTIVITY, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = false;
                } else {
                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //delete user activity
    @action.bound
    async doDeleteActivity(params, type) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIBody(API.USER.DELETE_ACTIVITY, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.code != API_STATUS.FETCH_SUCCESS) {
                    this.loading = false;
                    result = resp;
                } else {
                    
                    this.loading = false;
                    result = true;
                    //update store

                    if (type == ACTIVITY_TYPE.VALID) {
                        _.remove(this.listFeed, (item) => {
                            return item.atid == params.atid;
                        });

                        this.totalFeed -= 1;
                    } else if (type == ACTIVITY_TYPE.INVALID) {
                        _.remove(this.listFeedInvalid, (item) => {
                            return item.atid == params.atid;
                        });

                        this.totalFeedInvalid -= 1;
                    }
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //do follow, unfollow
    @action.bound
    async doFollow(params) {
        let result = null;
        this.loading = true; //show progress
        await requestAPIV2(API.USER.DO_FOLLOW, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                    result = false;
                } else {
                    

                    //update local
                    const obj = _.find(this.listUserLike, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj) obj.fists = resp.data.fists;

                    //update local
                    const obj1 = _.find(this.listUserSearch, (user) => {
                        return user.uid == params.pfid;
                    });
                    if (obj1) obj1.fists = resp.data.fists;

                    if (this.userProfileOther) this.userProfileOther.fists = resp.data.fists;

                    this.loading = false;
                    result = true;
                }
            })
            .catch((error) => {
                
                this.loading = false;
                result = false;
            });
        return result;
    }

    //Get statistics user
    @action.bound
    doGetStatisticsUser(params) {
        this.loading = true; //show progress
        requestAPIV2(API.USER.STATISTICS, params, HTTP_METHOD.POST)
            .then((resp) => {
                if (resp.ercd != API_STATUS.NO_ERROR) {
                    this.loading = false;
                } else {
                    
                    this.userStatisticsInfo = resp.data;
                    this.loading = false;
                }
            })
            .catch((error) => {
                
                this.loading = false;
            });
    }

    @action.bound
    doClearUserProfile() {
        this.userProfile = null;
    }
}
