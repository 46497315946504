import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CreatePostDialog from 'views/TeamDetails/components/CreatePostDialog';

const PostEmpty = inject(
    'postStore',
    'userStore',
    'teamManageStore'
)(
    observer((props) => {
        const { isAdmin, eventID, teamID } = props;
        const { userProfile } = props.userStore;
        const { doGetListPost } = props.postStore;
        const { doSetFunc } = props.teamManageStore;

        const { t } = useTranslation();

        const [openDialogCreatePost, setOpenDialogCreatePost] = React.useState(false);

        const title = isAdmin ? t('global.no_post_title') : '';
        const desc = isAdmin ? t('global.no_post_desc') : t('global.no_post_title_user');

        const getListPost = () => {
            const params = {
                from: 0,
                feed: 2,
                uid: userProfile && userProfile.uid,
                sort: 7, //get bài post ghim lên đầu
                order: 1,
                value: teamID,
                evid: eventID
            };
            doGetListPost(params);
        };

        const onCloseDialog = () => {
            getListPost();
            setOpenDialogCreatePost(false);
        };

        return (
            <>
                <div class="tabs-content manage-tabs-content">
                    <div class="manage-wbox waiting ">
                        <div class="groups-block">
                            <div class="groups-empty">
                                <p class="img">
                                    <img src="../../images/team/imgs/1.png" alt="notification" />
                                </p>
                                <div class="groups-empty">
                                    <p class="title">{title}</p>
                                    <p class="note">{desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

export default PostEmpty;
