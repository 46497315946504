import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource } from 'utils';
import { GROUP_ACTION } from 'helpers/ConstDefined';

const RegainAdminDialog = inject(
    'eventStore',
    'userEventStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        let { userRegainAdmin, eventData } = props;
        const { openDialogRegainAdmin } = props;
        const { userProfile } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doActionEvent } = props.userEventStore;

        const { t } = useTranslation();

        const actionEvent = async (action) => {
            const params = {
                uid: userProfile.uid,
                pfid: userRegainAdmin.uid,
                evid: eventData.evid,
                action
            };
            let result = await doActionEvent(params);
            if (result != null) {
                if (action == GROUP_ACTION.OWNER_GIVE_ADMIN) props.assignAdminSucc(userRegainAdmin);
                else props.regainAdminSucc(userRegainAdmin);

                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                props.onClose();
            } else {
                setMessageSnackbar(t('global.action.fail'));
                setSnackbarType('error');
            }
            onOpenSnackbar();
        };

        return (
            <Dialog open={openDialogRegainAdmin}>
                <div
                    class="uprace-popup uprace-popup--v2"
                    id="recovery-admin"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div class="popup-dialog" role="document">
                        <div class="popup-content">
                            <div class="popup-header">
                                <h4 class="popup-header__title">{t('eu.regain_admin')}</h4>
                                <button
                                    type="button"
                                    class="popup-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props.onClose()}>
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="popup-body flex-column text-center">
                                <div class="avatar">
                                    <img src={getImageResource(userRegainAdmin.ava, true)} class="avatar-item" alt="avater" />
                                </div>
                                <div class="txt-title">
                                    {t('eu.regain_admin')} {userRegainAdmin.name}
                                </div>
                                <div class="txt">{t('eu.regain_admin_desc')}</div>
                            </div>
                            <div class="popup-footer justify-content-end">
                                <div class="action">
                                    <button
                                        type="button"
                                        class="btn btn-outline-blue"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => actionEvent(GROUP_ACTION.OWNER_UNGIVE_ADMIN)}>
                                        {t('eu.regain_admin1')}
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-blue"
                                        data-dismiss="modal"
                                        onClick={() => props.onClose()}>
                                        {t('global.seaagain')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default RegainAdminDialog;
