import styled from 'styled-components';
import { Row, Col } from 'antd';
import { device } from '../../../../../configs/MediaQuery';

export const UserInfoWrapper = styled.section``;

export const UserInfoContainer = styled(Row)`
    @media ${device.mobileS} {
        padding: 16px;
    }

    @media ${device.tablet} {
    }
`;

export const AvatarUser = styled.img`
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    margin-right: 32px;

    @media ${device.mobileS} {
        width: 48px;
        height: 48px;
    }
    @media ${device.laptop} {
        width: 160px;
        height: 160px;
    }
`;

export const UserName = styled.h1`
    color: #000;
    @media ${device.mobileS} {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    @media ${device.laptop} {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
    }
`;

export const UserTeam = styled.h2`
    color: #797979;
    padding-bottom: 28px;
    @media ${device.mobileS} {
        font-size: 13px;
        line-height: 19px;
    }
    @media ${device.laptop} {
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
    }
`;

export const CardWrapper = styled.div`
    background: #fbfbfb;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
`;

export const CardDistance = styled(CardWrapper)`
    height: 240px;
    padding-left: 30px;
    padding-top: 35px;

    @media ${device.mobileS} {
        width: 171px;
        margin-right: 15px;
    }
    @media ${device.tablet} {
        width: 230px;
        margin-right: 20px;
    }
`;

export const CardDayActive = styled(CardWrapper)`
    height: 110px;
    margin-bottom: 20px;

    @media ${device.mobileS} {
        width: 171px;
    }
    @media ${device.tablet} {
        width: 230px;
    }
`;
