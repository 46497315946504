import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { convertTimeFromSecond, getImageResource } from '../../../../utils';
import { REPORT_ACTION, ACTIVITY_TYPE } from '../../../../helpers/ConstDefined';
import { buildAvgPace, buildDistance } from '../../../../utils/paceCalc';
import { humanTime, srcTypeImage, sportTypeImage } from '../../../../helpers/ConvertHelper';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MemberName } from 'components/MemberName';

const ActivityItem = inject(
    'globalStore',
    'userStore',
    'newsStore',
    'reportStore'
)(
    observer((props) => {
        const { data, eventID, type } = props;
        const { userProfile } = props.userStore;
        const { doAction, doSetViewDetail } = props.reportStore;
        const { listErrorActivity } = props.newsStore;
        const { language, setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const [radioValue, setRadioValue] = useState(null);
        const isActive = radioValue != null ? 'btn-40 btn-blue btn-handle' : 'btn-40 btn-blue btn-handle inactive';

        const MENUS = [
            {
                id: 0,
                title: 'global.this_act_valid'
            },
            {
                id: 1,
                title: 'global.this_act_invalid'
            }
        ];

        const { t } = useTranslation();

        const imgMap = data.img.replace('.png', 'w.png').replace('.jpeg', 'w.jpeg');

        function addDefaultSrc(e) {
            e.target.src = '../../../img/feed_default.jpg';
        }

        async function processActivity() {
            let action;
            if (type == ACTIVITY_TYPE.COMPLAIN) {
                action = radioValue == 0 ? REPORT_ACTION.ADMIN_ACCEPT_COMPLAIN : REPORT_ACTION.ADMIN_REJECT_COMPLAIN;
            } else if (type == ACTIVITY_TYPE.REPORT)
                action = radioValue == 1 ? REPORT_ACTION.ADMIN_ACCEPT_REPORT : REPORT_ACTION.ADMIN_REJECT_REPORT;

            let params = {
                atid: data.atid,
                evid: eventID,
                uid: userProfile.uid,
                action
            };
            let result = await doAction(params, type);
            if (result == true) {
                setMessageSnackbar(t('global.action.success'));
                setSnackbarType('success');
                onOpenSnackbar();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        function ErrorDesc() {
            const obj = data.event[eventID];
            if (obj) {
                let err = _.find(listErrorActivity, (item) => {
                    return item.id == obj.sts;
                });
                if (err)
                    return (
                        <div class="fail-notify">
                            <span class="mess">{err[`desc_${language}`]}</span> {t('global.view_details_at')}{' '}
                            <Link to={`/rules`}>{t('global.rule_event')}</Link>
                        </div>
                    );
                else return null;
            } else return null;
        }

        function RadioItem(props) {
            const { item } = props;
            return (
                <div class="frm-radio" onClick={() => setRadioValue(item.id)}>
                    <input
                        type="radio"
                        id={`r_${item.id}${data.atid}`}
                        name={`radiolist${data.atid}`}
                        checked={radioValue === item.id}
                    />
                    <label htmlFor={`r_${item.id}${data.atid}`}>
                        <span class="radio"></span>
                        <span class="txt">{t(item.title)}</span>
                    </label>
                </div>
            );
        }

        function RenderInfoBox(props) {
            const { data } = props;
            if (!data) return null;
            if (type == ACTIVITY_TYPE.COMPLAIN)
                return (
                    <div class="complain-box">
                        <p class="lb">
                            {data.cpnm} {t('global.compalined_txt')}
                        </p>
                        <p class="note">
                            <strong>{t('global.reason')}:</strong> {data.reason}
                        </p>
                    </div>
                );
            else
                return (
                    <div class="complain-box">
                        <p class="lb">
                            {data.rpnm} {t('global.reported_txt')}
                        </p>
                        <p class="note">
                            <strong>{t('global.reason')}:</strong> {data.reason}
                        </p>
                    </div>
                );
        }

        const viewActivityDetail = () => {
            doSetViewDetail(type);
        };

        return (
            <li class="activity-list-item">
                <div class="box">
                    <RenderInfoBox data={data.evrp[eventID]} />
                    <div class="info">
                        <div class="info-avatar">
                            <img src={getImageResource(data.ava, true)} alt="avatar" class="info-avatar__item" />
                        </div>
                        <div class="info-details">
                            <div class="info-details__name">{MemberName(data)}</div>

                            <div class={`info-details__date ${sportTypeImage(data.sport)}`}>
                                <div class="txt">{humanTime(data.betm, language)}</div>
                                <div class="img">
                                    <img src={srcTypeImage(data.src)} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="time-desc">{data.title}</div>
                    <div class="mess">{data.desc}</div>
                    {type == ACTIVITY_TYPE.COMPLAIN ? <ErrorDesc /> : null}

                    <ul class="run-detail-list">
                        <li class="run-detail-item">
                            <div class="lb">{t('global.time')}</div>
                            <div class="txt">{convertTimeFromSecond(data.mvtm)}</div>
                        </li>
                        <li class="run-detail-item">
                            <div class="lb">{t('global.speed')}</div>
                            <div class="txt">{buildAvgPace(data.sport, data.mvtm, data.dis)}</div>
                        </li>
                        <li class="run-detail-item">
                            <div class="lb">{t('global.distince')}</div>
                            <div class="txt">{buildDistance(data.sport, data.dis, true)}</div>
                        </li>
                    </ul>
                    <div class="road-map" onClick={() => viewActivityDetail()}>
                        <Link to={`feed-detail?evid=${eventID}&atid=${data.atid}`}>
                            <img
                                src={imgMap}
                                onError={addDefaultSrc}
                                alt=""
                                class="road-map__img"
                                style={{ width: '100%', display: 'block' }}
                            />
                        </Link>
                    </div>
                    <div class="choose-list">
                        {MENUS.map((item) => {
                            return <RadioItem item={item} key={item.id} />;
                        })}
                    </div>
                </div>
                <div class="btn-group">
                    <div class="btn-group-item">
                        <button class={isActive} onClick={() => processActivity()}>
                            {t('global.process')}
                        </button>
                    </div>
                </div>
            </li>
        );
    })
);

export default ActivityItem;
