import React, { useEffect } from 'react';
import { Switch, Redirect, Route, BrowserRouter } from 'react-router-dom';

import { RouteWithLayout } from './components';
import {
    Uprace2021 as Uprace2021Layout,
    Uprace2022 as Uprace2022Layout,
    Uprace2023 as Uprace2023Layout,
    Uprace2021Smaller as Uprace2021SmallerLayout,
    Uprace2021Simple as Uprace2021SimpleLayout,
    UserEvent as UserEventLayout
} from './layouts';

import { useTranslation } from 'react-i18next';
import { KEY } from '../src/helpers/api/APIDefined';

import {
    Login as LoginView,
    SignUp as SignUpView,
    SignUpConfirm as SignUpConfirmView,
    UserTeam as UserTeamView,
    InfoEvent as InfoEventView,
    YourInfo as YourInfoView,
    TeamProfile as TeamProfileView,
    ForgetPass as ForgetPassView,
    FeedDetail as FeedDetailView,
    Settings as SettingView,
    Callback as CallbackView,
    UserProfile as UserProfileView,
    TermsOfUse as TermsOfUseView,
    RuleEvent as RuleEventView,
    PrivacyPolicy as PrivacyPolicyView,
    Common as CommonView,
    UpdateProfile as UpdateProfileView,
    FAQ as FAQView,
    EventHome as EventHomeView,
    LandingPage as LandingPageView,
    NewsDetail as NewsDetailView,
    JoinNow as JoinNowView,
    DownloadApp as DownloadAppView,
    ContactUs as ContactUsView,
    ListNews as ListNewsView,
    TeamDetails as TeamDetailsView,
    ComingSoon as CommingSoonView,
    ErrorEvent as ErrorEventView,
    PostDetail as PostDetailView,
    EventDetail as EventDetailView,
    CreateEvent as CreateEventView,
    UpdateEvent as UpdateEventView,
    GroupDetail as GroupDetailView,
    UserEventDetail as UserEventDetailView,
    Support as SupportView,
    VerifyEmail as VerifyEmailView,
    InfoEvent2022 as InfoEvent2022View,
    UserTeam2022 as UserTeam2022View,
    TeamDetails2022 as TeamDetails2022View,
    RuleEvent2022 as RuleEvent2022View,
    YourInfo2022 as YourInfo2022View,
    FundsInfo as FundsInfoView,
    ErrorEventList as ErrorEventListView,
    EventRuleBasic as EventRuleBasicView,
    EventRuleDetail as EventRuleDetailView,
    InfoEvent2023 as InfoEvent2023View,
} from './views';

const Routes = (props) => {
    //check and change language
    const { i18n } = useTranslation();
    const initLanguage = () => {
        const key = localStorage.getItem(KEY.USER_LANGUAGE);
        if (key) {
            i18n.changeLanguage(key);
        } else i18n.changeLanguage('vi');
    };
    useEffect(() => {
        initLanguage();
    }, []);

    return (
        <BrowserRouter>
            <Switch>
                {/* <RouteWithLayout component={EventHomeView} exact layout={UserEventLayout} path="/event" /> */}
                <RouteWithLayout component={EventDetailView} exact layout={UserEventLayout} path="/event-detail" />
                <RouteWithLayout component={VerifyEmailView} exact layout={UserEventLayout} path="/verify-email" />

                <RouteWithLayout component={CreateEventView} exact layout={UserEventLayout} path="/create-event" />
                <RouteWithLayout component={UpdateEventView} exact layout={UserEventLayout} path="/update-event" />
                <RouteWithLayout component={GroupDetailView} exact layout={UserEventLayout} path="/group-detail" />
                <RouteWithLayout component={UserEventDetailView} exact layout={UserEventLayout} path="/user-detail" />

                <Route component={CommonView} exact layout={CommonView} path="/common" />

                <RouteWithLayout
                    component={SupportView}
                    exact
                    layout={Uprace2021SimpleLayout}
                    path="/hotro/quyen-vi-tri"
                />
                
                <RouteWithLayout component={UserTeamView} exact layout={Uprace2021Layout} path="/team" />
                <RouteWithLayout component={TeamDetailsView} exact layout={Uprace2021Layout} path="/team-details" />
                <RouteWithLayout
                    component={TeamProfileView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/team-profile"
                />
                <RouteWithLayout
                    component={UserProfileView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/user-profile"
                />
                <RouteWithLayout
                    component={FeedDetailView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/feed-detail"
                />
                <RouteWithLayout
                    component={PostDetailView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/post-detail"
                />
                {/* <RouteWithLayout component={InfoEventView} exact layout={Uprace2021Layout} path="/info" login={false} />
                <RouteWithLayout component={YourInfoView} exact layout={Uprace2021Layout} path="/you" /> */}
                <RouteWithLayout component={ErrorEventView} exact layout={Uprace2021SimpleLayout} path="/rules" />
                <RouteWithLayout component={RuleEventView} exact layout={Uprace2021SmallerLayout} path="/rule" />
                <RouteWithLayout component={SettingView} exact layout={Uprace2021SmallerLayout} path="/settings" />
                <RouteWithLayout component={TermsOfUseView} exact layout={Uprace2021SimpleLayout} path="/terms" />
                <RouteWithLayout component={FAQView} exact layout={Uprace2021SmallerLayout} path="/faq" />
                <RouteWithLayout component={PrivacyPolicyView} exact layout={Uprace2021SimpleLayout} path="/policy" />
                <RouteWithLayout component={ErrorEventView} exact layout={Uprace2021SimpleLayout} path="/error" />

                <Route component={LoginView} exact layout={LoginView} path="/login" />
                <Route component={CallbackView} exact layout={CallbackView} path="/callback" />
                <Route component={SignUpView} exact layout={SignUpView} path="/register" />
                <Route component={UpdateProfileView} exact layout={UpdateProfileView} path="/update-profile" />
                <Route component={ForgetPassView} exact layout={ForgetPassView} path="/forgot-pass" />
                <Route component={SignUpConfirmView} exact layout={SignUpConfirmView} path="/register-confirm" />

                <RouteWithLayout
                    component={InfoEvent2022View}
                    exact
                    layout={Uprace2022Layout}
                    path="/2022/info"
                    login={false}
                />
                <RouteWithLayout
                    component={InfoEvent2023View}
                    exact
                    layout={Uprace2023Layout}
                    path="/2023/info"
                    login={false}
                />
                <RouteWithLayout
                    component={UserTeam2022View}
                    exact
                    layout={Uprace2022Layout}
                    path="/2022/team"
                    login={false}
                />
                <RouteWithLayout
                    component={YourInfo2022View}
                    exact
                    layout={Uprace2022Layout}
                    path="/2022/you"
                    login={false}
                />
                <RouteWithLayout
                    component={TeamDetails2022View}
                    exact
                    layout={Uprace2022Layout}
                    path="/2022/team-details"
                />
                <RouteWithLayout
                    component={TeamProfileView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/2022/team-profile"
                />
                <RouteWithLayout
                    component={UserProfileView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/2022/user-profile"
                />
                 <RouteWithLayout
                    component={FeedDetailView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/2022/feed-detail"
                />
                <RouteWithLayout
                    component={PostDetailView}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/2022/post-detail"
                />
                <RouteWithLayout
                    component={RuleEvent2022View}
                    exact
                    layout={Uprace2021SmallerLayout}
                    path="/2022/rule"
                />
                <Route component={FundsInfoView} exact layout={FundsInfoView} path="/fund/basic" />
                <RouteWithLayout
                    component={ErrorEventListView}
                    exact
                    layout={Uprace2021SimpleLayout}
                    path="/event/rule/error"
                />
                <RouteWithLayout
                    component={EventRuleBasicView}
                    exact
                    layout={Uprace2021SimpleLayout}
                    path="/event/rule/basic"
                />
                <RouteWithLayout
                    component={EventRuleDetailView}
                    exact
                    layout={Uprace2021SimpleLayout}
                    path="/event/rule/details"
                />

                <Redirect to={`/2023/info?evid=8`} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
