import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import NewsItem from './NewsItem';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { GLOBAL_CONFIG } from 'helpers/ConstDefined';

const News = inject(
    'authStore',
    'newsStore'
)(
    observer((props) => {
        const { listNews2021 } = props.newsStore;
        const { t, i18n } = useTranslation();

        useEffect(() => {}, []);

        function getTop5News() {
            const list = _.slice(listNews2021, 0, 5);
            return list;
        }

        return (
            <div className="container">
                <h1 className="space-60" dangerouslySetInnerHTML={{ __html: t('landing_page_hotnews.title') }}></h1>
                <div className="news-hot flex wrap">
                    {listNews2021 &&
                        getTop5News().map((item) => {
                            return <NewsItem data={item} key={item.id} />;
                        })}
                </div>
                <RouterLink className="btn-seemore flex justify-content-center bold" to={`/list-news?evid=${GLOBAL_CONFIG.EVENT_UPRACE_2021_ID}`}>
                    <span>{t('global.seamore')}</span> <i className="icon icon-more"></i>
                </RouterLink>
            </div>
        );
    })
);

export default News;
