import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../../../utils/cropImage';
import { dataURLtoFile } from '../../../../../utils';
import 'react-easy-crop/react-easy-crop.css';
import { useTranslation } from 'react-i18next';

const CropImageDialog = inject(
    'authStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const { imgSource, openDialogCropImage } = props;
        const [crop, setCrop] = useState({ x: 0, y: 0 });
        const [rotation, setRotation] = useState(0);
        const [zoom, setZoom] = useState(1);
        const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

        const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
        }, []);

        const { t } = useTranslation();
        useEffect(() => {}, []);

        const showCroppedImage = useCallback(async () => {
            try {
                const croppedImage = await getCroppedImg(imgSource, croppedAreaPixels, rotation);
                console.log('donee', { croppedImage });

                var file = new File([croppedImage], 'name.png', {
                    type: 'image/png'
                });

                var file1 = dataURLtoFile([croppedImage], 'avatar.png');
                props.onCropSuccess(file1);
                props.onClose();
            } catch (e) {
                console.error(e);
            }
        }, [croppedAreaPixels, rotation]);

        return (
            <Dialog open={openDialogCropImage}>
                <div
                    className="uprace-popup has-title in"
                    id="create-group-step2"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w600">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                z-index="999999"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('global.edit')}</h2>
                            <div className="popup-body">
                                <div className="popup-create-group">
                                    <div className="create-group-wrap">
                                        <button
                                            type="button"
                                            className="popup-close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={props.onClose}>
                                            <span aria-hidden="true"></span>
                                        </button>
                                        <div className="crop-img">
                                            <Cropper
                                                disableAutomaticStylesInjection={true}
                                                image={imgSource}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4 / 4}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                    </div>
                                    <div class="popup-btns col-2">
                                        <button class="btn-44 blue-light" onClick={props.onClose}>
                                            {t('global.destroy')}
                                        </button>
                                        <button class="btn-44 blue" onClick={() => showCroppedImage()}>
                                            {' '}
                                            {t('global.save_image')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default CropImageDialog;
