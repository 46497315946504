import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import TeamListToolbar from './TeamListToolbar';
import TeamItem from './TeamItem';
import Select from 'react-select';
import { stripedHtml, getTimestamp } from '../../../../utils';
import { TEAM_TYPE, SPORT_TYPE } from '../../../../data/master';
import YourTeam from '../YourTeams';
import DialogCreateTeam from '../DialogCreateTeam';
import { toJS } from 'mobx';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { DialogViewTeam } from '../../../UserTeam/components';

const typeList = Object.keys(TEAM_TYPE).map((key) => ({ value: TEAM_TYPE[key].id, label: TEAM_TYPE[key].name }));

const SelectBox = styled.div`
    width: 200px;
`;

const TeamSearch = inject(
    'authStore',
    'teamStore',
    'globalStore',
    'userStore'
)(
    observer((props) => {
        const [openDialogCreateTeam, setOpenDialogCreateTeam] = React.useState(false);
        const [openDialogViewTeam, setOpenDialogViewTeam] = React.useState(false);

        const { userProfile } = props.userStore;
        const {
            currentTeam,
            loadingTeam,
            listTeam,
            userTeam,
            tabTeamType,
            totalRecord,
            pageIndex,
            doIncreasePageIndex,
            doSetPageIndex,
            doGetListTeam,
            doGetTeamDetail
        } = props.teamStore;
        const { appVersion } = props.globalStore;

        const childRef = useRef();

        //filter params
        const [name, setName] = React.useState('');
        const [type, setType] = React.useState(0); //type all
        const isShowLoadMore = listTeam == null ? false : listTeam.length < totalRecord;
        const showJoinBtn = userTeam == null || userTeam.tmid == 0;
        const showClearName = name && name.length > 0;

        useEffect(() => {
            if (userProfile) getListTeam();
            onChangeTab();
        }, [userProfile]);

        const handleOpenDialogViewTeam = (flag) => {
            setOpenDialogViewTeam(flag);
        };

        const scrollTop = () => {
            window.scrollTo(0, 0);
        };

        function getListTeam() {
            let data = {
                uid: userProfile.uid
            };
            doGetListTeam(data);
        }

        const handleChangeName = (val) => {
            setName(val);
        };

        const onChangeTab = () => {
            //reset filter when switch tab
            setName('');
            doSetPageIndex(1); //reset page index
        };

        function handleResetSearch() {
            onChangeTab();
            let data = {
                uid: userProfile.uid
            };
            doGetListTeam(data);
        }

        const handleChangeTeamType = (type) => {
            setType(type);
            doSetPageIndex(1);
            const data = {
                uid: userProfile.uid,
                sport: tabTeamType,
                name,
                lgid: type
            };
            doGetListTeam(data);
        };

        const handleTeamTypeChange = (selectedOption) => {
            //console.log(`Option selected:`, selectedOption);
            setType(selectedOption.value);
            handleChangeTeamType(selectedOption.value);
        };

        function getListTeamMore() {
            doIncreasePageIndex();
            let data = {
                uid: userProfile.uid,
                sport: tabTeamType,
                name,
                lgid: type,
                from: pageIndex * appVersion.pgsz,
                size: appVersion.pgsz
            };
            doGetListTeam(data, true);
        }

        function searchTeam() {
            doSetPageIndex(1);
            const data = {
                uid: userProfile.uid,
                sport: tabTeamType,
                name,
                lgid: type
            };
            doGetListTeam(data);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                searchTeam();
            }
        };

        async function getTeamDetail(teamID, action) {
            let data = {
                uid: userProfile.uid,
                tmid: teamID
            };
            let result = await doGetTeamDetail(data);
            if (result) {
                if (action == 0) props.gotoTeamProfile();
                else {
                    handleOpenDialogViewTeam(true);
                    childRef.current.initData();
                }
            }
        }

        const onViewTeamProfile = (team) => {
            getTeamDetail(team.tmid, 0);
        };

        function LoadMore() {
            if (isShowLoadMore) {
                if (loadingTeam)
                    return (
                        <div className="load-more">
                            <CircularProgress disableShrink />
                        </div>
                    );
                else
                    return (
                        <>
                            <div className="load-more">
                                <button className="btn btn-primary" onClick={() => getListTeamMore()}>
                                    Xem thêm
                                </button>
                            </div>
                        </>
                    );
            } else return null;
        }

        return (
            <>
                <div className="col-lg-8">
                    <section className="main-content list-team-event">
                        <div className="clearfix">
                            <div className="pull-left">
                                {' '}
                                <h3 className="title-rank">Danh sách đội</h3>
                            </div>
                            <div className="pull-right">
                                <SelectBox>
                                    <Select
                                        options={typeList}
                                        defaultValue={typeList[0]}
                                        onChange={handleTeamTypeChange}
                                    />
                                </SelectBox>
                            </div>
                        </div>
                        <div className="wrapper-table">
                            <TeamListToolbar
                                onChangeTab={onChangeTab}
                                onChangeTeamType={handleChangeTeamType}></TeamListToolbar>
                            <div className="wrapper-search">
                                <div className="form-search-custom" id="search-form">
                                    <div className="input-group input-group-custom">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src="../../img/icon-search.png" alt="image" />
                                            </span>
                                        </div>
                                        <input
                                            id="search"
                                            type="text"
                                            className="form-control form-search"
                                            value={name}
                                            onKeyDown={handleKeyDown}
                                            onChange={(text) => handleChangeName(stripedHtml(text.target.value))}
                                            placeholder="Tìm tên đội"
                                        />
                                        {showClearName ? (
                                            <img
                                                src="../../../img/register/close.svg"
                                                className="close-text"
                                                onClick={() => handleResetSearch()}
                                            />
                                        ) : null}
                                    </div>
                                    <button
                                        className="btn btn-primary btn-search btn-search-custom"
                                        onClick={() => searchTeam()}>
                                        Tìm kiếm
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive custom-table custom-table-list-team  ">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Đội</th>
                                            <th scope="col">Loại đội</th>
                                            <th scope="col">Loại thể thao</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {listTeam != null &&
                                            listTeam.map((item) => (
                                                <TeamItem
                                                    key={item.tmid}
                                                    onSelect={() => handleOpenDialogSelectTeam(true, item)}
                                                    onViewDetail={() => getTeamDetail(item.tmid, 0)}
                                                    data={toJS(item)}></TeamItem>
                                            ))}
                                    </tbody>
                                </table>
                                {listTeam && listTeam.length == 0 ? (
                                    <p style={{ textAlign: 'center' }}>Không tìm thấy Đội theo yêu cầu</p>
                                ) : null}
                            </div>
                            <LoadMore />
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <YourTeam
                        userProfile={userProfile}
                        onCreateTeam={() => setOpenDialogCreateTeam(true)}
                        onViewTeamProfile={onViewTeamProfile}
                    />
                </div>
                <DialogCreateTeam
                    openDialogCreateTeam={openDialogCreateTeam}
                    onClose={() => setOpenDialogCreateTeam(false)}
                />
                {currentTeam != null ? (
                    <DialogViewTeam
                        openDialogViewTeam={openDialogViewTeam}
                        pageIndexTeam={0}
                        ref={childRef}
                        onJoinTeamSuccess={props.onJoinTeamSuccess}
                        showJoinTeam={showJoinBtn}
                        onClose={() => handleOpenDialogViewTeam(false)}></DialogViewTeam>
                ) : null}
            </>
        );
    })
);

export default TeamSearch;
