import React from 'react';
import { inject, observer } from 'mobx-react';
import { getImageResource } from '../../../../../utils';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

const DialogSelectTeam = inject(
    'globalStore',
    'teamStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { openDialogSelectTeam, teamData, eventID } = props;
        const { doRequestUpdateTeam, doSetUserTeam, doSetCurrentTeam } = props.teamStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;
        const { doSetTeamJoinEvent } = props.eventStore;

        let { userProfile, doPushUserTeamEvent } = props.userStore;

        const { t } = useTranslation();

        async function requestUpdateTeam() {
            let data = {
                tmid: teamData.tmid,
                evid: eventID,
                uid: userProfile.uid
            };
            let result = await doRequestUpdateTeam(data);
            if (result == true) {
                props.onJoinTeamSuccess();
                teamData.evid = eventID;
                teamData.jsts = 2;
                userProfile.tmnm = teamData.name;
                doSetUserTeam(_.clone(teamData));
                doSetCurrentTeam(_.clone(teamData));
                doPushUserTeamEvent(_.clone(teamData));
                doSetTeamJoinEvent(teamData.tmid);
            } else {
                setMessageSnackbar(result && result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
            props.onClose();
        }

        return (
            <Dialog open={openDialogSelectTeam}>
                <div
                    className="uprace-popup"
                    id="team-join"
                    tabIndex="1000"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={props.onClose}>
                                <span aria-hidden="true"></span>
                            </button>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <p className="logo">
                                        <img src={getImageResource(teamData && teamData.ava, true)} alt="" />
                                    </p>
                                    <p className="name">{teamData && teamData.name}</p>
                                    <div className="txt">
                                        <p>
                                            {t('team.join_team_event')}
                                        </p>
                                    </div>
                                    <div className="popup-btns col-2 w120">
                                        <button
                                            className="btn-44 blue"
                                            onClick={() => requestUpdateTeam(teamData && teamData.tmid)}>
                                            {t('global.join_team_now')}
                                        </button>
                                        <button className="btn-44 cancel" data-dismiss="modal" onClick={props.onClose}>
                                            {t('global.later')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default DialogSelectTeam;
