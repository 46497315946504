import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { TEAM_MSG } from '../../../../helpers/MsgDefined';

const NotJoin = inject(
    'authStore',
    'teamStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        let { currentTeam, doJoinLeaveTeam } = props.teamStore;
        const { userProfile, doPushUserTeamEvent } = props.userStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const handleActionJoin = () => {
            requestJoinTeam();
        };

        async function requestJoinTeam() {
            const params = {
                uid: userProfile.uid,
                tmid: currentTeam.tmid,
                action: 0
            };
            let result = await doJoinLeaveTeam(params);
            if (result) {
                setMessageSnackbar(TEAM_MSG.JOIN_TEAM_SUCCESS);
                setSnackbarType('success');
                onOpenSnackbar();
                _.remove(userProfile.tmls, (item) => {
                    return item.tmid == currentTeam.tmid;
                });
                currentTeam.jsts = 2;
                doPushUserTeamEvent(currentTeam);
            } else {
                setMessageSnackbar(TEAM_MSG.JOIN_TEAM_FAIL);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        return (
            <div className="wrapper-action-top">
                <div className="form-control custom-wrapper-btn" onClick={() => handleActionJoin()}>
                    <button id="btnJoinTeam" type="button" className="btn btn-danger btn-to-join btn-action-team">
                        Tham gia
                    </button>
                </div>
            </div>
        );
    })
);
export default NotJoin;
