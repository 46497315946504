import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getImageResource } from '../../../../../utils';
import { FOLLOW_STT, GROUP_ACTION } from '../../../../../helpers/ConstDefined';
import { MemberName, MemberCode } from 'components/MemberName';

const MemberJoinedItem = inject(
    'authStore',
    'groupStore',
    'userStore',
    'globalStore'
)(
    observer((props) => {
        const { data, owner } = props;
        const { userProfile } = props.userStore;
        const { doFollow } = props.groupStore;
        const { currentGroup, doActionGroup } = props.groupStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        useEffect(() => {}, []);

        const { t } = useTranslation();

        const css = owner ? 'user owner' : 'user';

        async function followUser(action) {
            const params = {
                uid: userProfile.uid,
                pfid: data.uid,
                action
            };
            let result = await doFollow(params);
        }

        async function deleteUser() {
            props.onDeleteMember(data);
        }

        if (!owner)
            return (
                <li>
                    <div className="item">
                        {data.fists == 2 ? (
                            userProfile.uid != data.uid ? (
                                <button className="btn-32 blue gray" onClick={() => followUser(FOLLOW_STT.UNFOLLOW)}>
                                    {t('global.unfollow')}
                                </button>
                            ) : null
                        ) : userProfile.uid != data.uid ? (
                            <button className="btn-32 blue" onClick={() => followUser(FOLLOW_STT.FOLLOW)}>
                                {t('global.follow')}
                            </button>
                        ) : null}

                        <button className="delete-btn" onClick={() => deleteUser()}></button>
                        <div className="user">
                            <p className="avatar">
                                <img src={getImageResource(data.ava, true, true)} alt="" />
                            </p>
                            <div className="details">
                                <p className="name">{MemberName(data)}</p>
                                <p className="code">{MemberCode(data)}</p>
                            </div>
                        </div>
                    </div>
                </li>
            );
        else
            return (
                <div className="user owner">
                    <p className="avatar">
                        <img src={getImageResource(data.ava, true, true)} alt="" />
                    </p>
                    <div className="details">
                        <p className="name">{MemberName(data)}</p>
                        <p className="code">{MemberCode(data)}</p>
                    </div>
                </div>
            );
    })
);

export default MemberJoinedItem;
