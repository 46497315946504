import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { stripedHtml } from 'utils';
import { Redirect } from 'react-router-dom';
import GroupHeader from './GroupHeader';
import GroupRankList from './GroupRankList';
import ManageMember from './ManageMember';
import EditGroupDialog from '../TeamDetails/components/EditGroupDialog';
import { LEADERBOARD_TYPE } from 'helpers/ConstDefined';

const queryString = require('query-string');

const GroupDetail = inject(
    'groupStore',
    'userStore',
    'eventStore'
)(
    observer((props) => {
        const { currentGroup, doGetGroupDetail } = props.groupStore;
        const { userProfile } = props.userStore;
        const { currentEvent, doGetEventData } = props.eventStore;

        const [tab, setTab] = React.useState(0);
        const [openDialogEditGroup, setOpenDialogEditGroup] = React.useState(false);

        let paramsQuery = queryString.parse(props.location.search);
        if (_.isEmpty(paramsQuery) || !paramsQuery.grid || !paramsQuery.evid) return <Redirect to="/"></Redirect>;

        let groupID = stripedHtml(paramsQuery.grid);
        let eventID = stripedHtml(paramsQuery.evid);

        useEffect(() => {
            getEventDetail();
            getGroupDetail();
            window.scrollTo({ top: 0 })
        }, [userProfile]);

        const { t } = useTranslation();

        const onEditGroup = () => {
            setOpenDialogEditGroup(true);
        };

        const onChangeTab = (tab) => {
            setTab(tab);
        }

        async function getGroupDetail() {
            const params = {
                uid: userProfile && userProfile.uid,
                grid: groupID
            };
            let result = await doGetGroupDetail(params);
            if (!result) {
                props.history.push('/');
            }
        }

        const getEventDetail = async () => {
            const params = {
                uid: userProfile && userProfile.uid,
                evid: eventID
            };
            let result = await doGetEventData(params);
            if (!result) props.history.push('/');
        };

        if (currentEvent && currentGroup)
            return (
                <section class="event-detail-page">
                    <div class="event-detail-wrap">
                        <div class="event-detail-header">
                            <div class="info">
                                <div class="info--banner none-background">
                                    <img
                                        src="../../../images/users-event/events/event2.png"
                                        alt="Banner"
                                        class="banner-img"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="group-detail-page">
                            <div class="group-detail-wrap">
                                {currentGroup ? (
                                    <GroupHeader
                                        onEditGroup={onEditGroup}
                                        groupData={currentGroup}
                                        eventData={currentEvent}
                                        navigation={props.history}
                                        onChangeTab={onChangeTab}
                                    />
                                ) : null}
                                {tab == 0 ? (
                                    <GroupRankList
                                        groupData={currentGroup}
                                        eventData={currentEvent}
                                        navigation={props.history}
                                    />
                                ) : (
                                    <ManageMember
                                        groupData={currentGroup}
                                        eventData={currentEvent}
                                        navigation={props.history}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {openDialogEditGroup ? (
                        <EditGroupDialog
                            openDialogEditGroup={openDialogEditGroup}
                            eventID={currentEvent.evid}
                            onClose={() => setOpenDialogEditGroup(false)}
                        />
                    ) : null}
                </section>
            );
        else return null;
    })
);

export default GroupDetail;
