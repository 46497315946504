import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import { getImageResource, stripedHtml, formatNumber, formatDistanceKM, getTypeName } from '../../../../utils';
import { COMMON_ERROR, GROUP_ACTION, GROUP_JOIN_STATUS } from '../../../../helpers/ConstDefined';

const LeaveTeamConfirmDialog = inject(
    'eventStore',
    'userStore',
    'globalStore',
    'teamStore'
)(
    observer((props) => {
        const { openDialogLeaveTeamConfirm, teamData, eventID } = props;
        const { doSetUserTeam, userTeam, doJoinLeaveTeam, doRequestUpdateTeam, doSetJoinTeamStt } = props.teamStore;
        const { userProfile, doClearMeRank } = props.userStore;
        const { doSetTeamJoinEvent, doSetGroupIDEvent, doSetGroupRequestID } = props.eventStore;
        const { setSnackbarType, setMessageSnackbar, onOpenSnackbar } = props.globalStore;

        const { t } = useTranslation();

        useEffect(() => {}, []);

        const [showResult, setShowResult] = React.useState(false);
        const [msgResult, setMsgResult] = React.useState(t('global.leave_team_confirm_msg_event'));

        const onLeaveTeamSuccess = () => {
            teamData.jsts = 0;
            doSetUserTeam(null);
            doSetGroupIDEvent(0);
            doSetGroupRequestID(0);
            userProfile.tmnm = '';
            if (userTeam && userTeam.tmid == 0) {
                //setMsgLeaveTeam(null);
                //doSetIsTeamSearch(true);
            }
        };

        async function joinLeaveTeam() {
            const params = {
                uid: userProfile.uid,
                tmid: 0,
                evid: eventID
            };
            let result = await doRequestUpdateTeam(params);

            const code = result && result.ercd;

            if (code == COMMON_ERROR.EVENT.EVENT_READY || code == COMMON_ERROR.TEAM.LEAVE_TEAM_EVENT_READY) {
                setShowResult(true);
                setMsgResult(t('global.leave_team_fail_event_ready'));
            } else if (code == COMMON_ERROR.TEAM.OWNER_CODE) {
                setShowResult(true);
                setMsgResult(t('global.leave_team_fail_owner'));
            } else if (result == true) {
                setMessageSnackbar(t('global.leave_team_succ'));
                setSnackbarType('success');

                //remove team of user in profile
                _.remove(userProfile.tmls, (item) => {
                    return item.tmid == teamData.tmid;
                });
                teamData.jsts = GROUP_JOIN_STATUS.NONE;
                doSetJoinTeamStt(GROUP_JOIN_STATUS.NONE);
                doSetTeamJoinEvent(0); //Rời khỏi đội trong event
                onLeaveTeamSuccess();
                doClearMeRank(); //clear me rank khi rời đội
                onOpenSnackbar();
                onCloseDialog();
            } else {
                setMessageSnackbar(result.desc);
                setSnackbarType('error');
                onOpenSnackbar();
            }
        }

        const onCloseDialog = () => {
            setShowResult(false);
            setMsgResult(t('global.leave_team_confirm_msg_event'));
            props.onClose();
        };

        return (
            <Dialog open={openDialogLeaveTeamConfirm}>
                <div
                    className="uprace-popup has-title"
                    id="leave-group-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ display: 'block' }}>
                    <div className="popup-dialog" role="document">
                        <div className="popup-content w420">
                            <button
                                type="button"
                                className="popup-close"
                                onClick={() => onCloseDialog()}
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"></span>
                            </button>
                            <h2 className="popup-title">{t('global.leave_team_confirm')}</h2>
                            <div className="popup-body">
                                <div className="popup-team-notify">
                                    <div className="logo-row">
                                        <p className="glogo">
                                            <img src={getImageResource(teamData.ava, true)} alt="" />
                                        </p>
                                    </div>
                                    <p className="name">{teamData.name}</p>
                                    <div className="txt">
                                        <p>{msgResult}</p>
                                    </div>
                                    {!showResult ? (
                                        <div className="popup-btns col-2 w140">
                                            <button className="btn-44 blue" onClick={() => onCloseDialog()}>
                                                {t('global.seaagain')}
                                            </button>
                                            <button className="btn-44 cancel" onClick={() => joinLeaveTeam()}>
                                                {t('global.leave_team1')}
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    })
);

export default LeaveTeamConfirmDialog;
