import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import ENLang from './ENLang';
import VNLang from './VNLang';
import ENLang2021 from './ENLang2021';
import VNLang2021 from './VNLang2021';

const RuleEvent = inject('authStore')(
    observer((props) => {
        const { lang, type } = props;

        useEffect(() => {}, []);

        const RenderContent = () => {
            if (lang == 'vi') return <VNLang />;
            else if (lang == 'en') return <ENLang />;
            else return <VNLang />;
        };

        const RenderContent2021 = () => {
            if (lang == 'vi') return <VNLang2021 />;
            else if (lang == 'en') return <ENLang2021 />;
            else return <VNLang2021 />;
        };

        const path = type == 2 ? '../../../img/banner/banner001.png' : '../../../img/banner/event2021.png';

        return (
            <div>
                <img src={path} alt="image" style={{ opacity: 1, width: '100%', objectFit: 'contain' }} />
                {type == 2 ? <RenderContent /> : <RenderContent2021 />}
            </div>
        );
    })
);

export default RuleEvent;
